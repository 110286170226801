/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RemittanceItem } from './RemittanceItem';
import {
    RemittanceItemFromJSON,
    RemittanceItemFromJSONTyped,
    RemittanceItemToJSON,
} from './RemittanceItem';
import type { RemittanceReference } from './RemittanceReference';
import {
    RemittanceReferenceFromJSON,
    RemittanceReferenceFromJSONTyped,
    RemittanceReferenceToJSON,
} from './RemittanceReference';

/**
 * 
 * @export
 * @interface GetOrderRemittanceItemsSuccessResponseItem
 */
export interface GetOrderRemittanceItemsSuccessResponseItem {
    /**
     * 
     * @type {RemittanceItem}
     * @memberof GetOrderRemittanceItemsSuccessResponseItem
     */
    item: RemittanceItem;
    /**
     * 
     * @type {RemittanceReference}
     * @memberof GetOrderRemittanceItemsSuccessResponseItem
     */
    remittance: RemittanceReference;
}

/**
 * Check if a given object implements the GetOrderRemittanceItemsSuccessResponseItem interface.
 */
export function instanceOfGetOrderRemittanceItemsSuccessResponseItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "item" in value;
    isInstance = isInstance && "remittance" in value;

    return isInstance;
}

export function GetOrderRemittanceItemsSuccessResponseItemFromJSON(json: any): GetOrderRemittanceItemsSuccessResponseItem {
    return GetOrderRemittanceItemsSuccessResponseItemFromJSONTyped(json, false);
}

export function GetOrderRemittanceItemsSuccessResponseItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrderRemittanceItemsSuccessResponseItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'item': RemittanceItemFromJSON(json['item']),
        'remittance': RemittanceReferenceFromJSON(json['remittance']),
    };
}

export function GetOrderRemittanceItemsSuccessResponseItemToJSON(value?: GetOrderRemittanceItemsSuccessResponseItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item': RemittanceItemToJSON(value.item),
        'remittance': RemittanceReferenceToJSON(value.remittance),
    };
}

