import {Badge} from "@aws-amplify/ui-react";
import React from "react";

export const InfoPill: React.FC<{label: string}> = ({label}) => (
  <Badge variation="info">{label}</Badge>
);

export const WarningPill: React.FC<{label: string}> = ({label}) => (
  <Badge variation="warning">{label}</Badge>
);

export const SuccessPill: React.FC<{label: string}> = ({label}) => (
  <Badge variation="success">{label}</Badge>
);

export const ErrorPill: React.FC<{label: string}> = ({label}) => (
  <Badge variation="error">{label}</Badge>
);
