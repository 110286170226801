/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetOrderUnderwritingAttachmentsSuccessResponseAttachment } from './GetOrderUnderwritingAttachmentsSuccessResponseAttachment';
import {
    GetOrderUnderwritingAttachmentsSuccessResponseAttachmentFromJSON,
    GetOrderUnderwritingAttachmentsSuccessResponseAttachmentFromJSONTyped,
    GetOrderUnderwritingAttachmentsSuccessResponseAttachmentToJSON,
} from './GetOrderUnderwritingAttachmentsSuccessResponseAttachment';

/**
 * 
 * @export
 * @interface GetOrderUnderwritingAttachmentsSuccessResponse
 */
export interface GetOrderUnderwritingAttachmentsSuccessResponse {
    /**
     * 
     * @type {Array<GetOrderUnderwritingAttachmentsSuccessResponseAttachment>}
     * @memberof GetOrderUnderwritingAttachmentsSuccessResponse
     */
    attachments: Array<GetOrderUnderwritingAttachmentsSuccessResponseAttachment>;
}

/**
 * Check if a given object implements the GetOrderUnderwritingAttachmentsSuccessResponse interface.
 */
export function instanceOfGetOrderUnderwritingAttachmentsSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "attachments" in value;

    return isInstance;
}

export function GetOrderUnderwritingAttachmentsSuccessResponseFromJSON(json: any): GetOrderUnderwritingAttachmentsSuccessResponse {
    return GetOrderUnderwritingAttachmentsSuccessResponseFromJSONTyped(json, false);
}

export function GetOrderUnderwritingAttachmentsSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrderUnderwritingAttachmentsSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attachments': ((json['attachments'] as Array<any>).map(GetOrderUnderwritingAttachmentsSuccessResponseAttachmentFromJSON)),
    };
}

export function GetOrderUnderwritingAttachmentsSuccessResponseToJSON(value?: GetOrderUnderwritingAttachmentsSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attachments': ((value.attachments as Array<any>).map(GetOrderUnderwritingAttachmentsSuccessResponseAttachmentToJSON)),
    };
}

