/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateAuditRequest
 */
export interface CreateAuditRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAuditRequest
     */
    agencyId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAuditRequest
     */
    agencyReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAuditRequest
     */
    auditScope?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateAuditRequest
     */
    completedOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateAuditRequest
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateAuditRequest
     */
    dueOn: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateAuditRequest
     */
    startingLetterSentOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateAuditRequest
     */
    state?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateAuditRequest
     */
    targetCompleteOn: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateAuditRequest
     */
    targetStartOn: Date;
}

/**
 * Check if a given object implements the CreateAuditRequest interface.
 */
export function instanceOfCreateAuditRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "agencyId" in value;
    isInstance = isInstance && "dueOn" in value;
    isInstance = isInstance && "targetCompleteOn" in value;
    isInstance = isInstance && "targetStartOn" in value;

    return isInstance;
}

export function CreateAuditRequestFromJSON(json: any): CreateAuditRequest {
    return CreateAuditRequestFromJSONTyped(json, false);
}

export function CreateAuditRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAuditRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agencyId': json['agencyId'],
        'agencyReferenceId': !exists(json, 'agencyReferenceId') ? undefined : json['agencyReferenceId'],
        'auditScope': !exists(json, 'auditScope') ? undefined : json['auditScope'],
        'completedOn': !exists(json, 'completedOn') ? undefined : (new Date(json['completedOn'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'dueOn': (new Date(json['dueOn'])),
        'startingLetterSentOn': !exists(json, 'startingLetterSentOn') ? undefined : (new Date(json['startingLetterSentOn'])),
        'state': !exists(json, 'state') ? undefined : json['state'],
        'targetCompleteOn': (new Date(json['targetCompleteOn'])),
        'targetStartOn': (new Date(json['targetStartOn'])),
    };
}

export function CreateAuditRequestToJSON(value?: CreateAuditRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agencyId': value.agencyId,
        'agencyReferenceId': value.agencyReferenceId,
        'auditScope': value.auditScope,
        'completedOn': value.completedOn === undefined ? undefined : (value.completedOn.toISOString().substr(0,10)),
        'description': value.description,
        'dueOn': (value.dueOn.toISOString().substr(0,10)),
        'startingLetterSentOn': value.startingLetterSentOn === undefined ? undefined : (value.startingLetterSentOn.toISOString().substr(0,10)),
        'state': value.state,
        'targetCompleteOn': (value.targetCompleteOn.toISOString().substr(0,10)),
        'targetStartOn': (value.targetStartOn.toISOString().substr(0,10)),
    };
}

