/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetProducersExpiringSuccessResponseItem } from './GetProducersExpiringSuccessResponseItem';
import {
    GetProducersExpiringSuccessResponseItemFromJSON,
    GetProducersExpiringSuccessResponseItemFromJSONTyped,
    GetProducersExpiringSuccessResponseItemToJSON,
} from './GetProducersExpiringSuccessResponseItem';
import type { GetProducersExpiringType } from './GetProducersExpiringType';
import {
    GetProducersExpiringTypeFromJSON,
    GetProducersExpiringTypeFromJSONTyped,
    GetProducersExpiringTypeToJSON,
} from './GetProducersExpiringType';
import type { ProducerAppointment } from './ProducerAppointment';
import {
    ProducerAppointmentFromJSON,
    ProducerAppointmentFromJSONTyped,
    ProducerAppointmentToJSON,
} from './ProducerAppointment';
import type { ProducerType } from './ProducerType';
import {
    ProducerTypeFromJSON,
    ProducerTypeFromJSONTyped,
    ProducerTypeToJSON,
} from './ProducerType';

/**
 * 
 * @export
 * @interface GetProducersExpiringSuccessResponseItemAppointment
 */
export interface GetProducersExpiringSuccessResponseItemAppointment extends GetProducersExpiringSuccessResponseItem {
    /**
     * 
     * @type {ProducerAppointment}
     * @memberof GetProducersExpiringSuccessResponseItemAppointment
     */
    appointment: ProducerAppointment;
}

/**
 * Check if a given object implements the GetProducersExpiringSuccessResponseItemAppointment interface.
 */
export function instanceOfGetProducersExpiringSuccessResponseItemAppointment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "appointment" in value;

    return isInstance;
}

export function GetProducersExpiringSuccessResponseItemAppointmentFromJSON(json: any): GetProducersExpiringSuccessResponseItemAppointment {
    return GetProducersExpiringSuccessResponseItemAppointmentFromJSONTyped(json, false);
}

export function GetProducersExpiringSuccessResponseItemAppointmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProducersExpiringSuccessResponseItemAppointment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...GetProducersExpiringSuccessResponseItemFromJSONTyped(json, ignoreDiscriminator),
        'appointment': ProducerAppointmentFromJSON(json['appointment']),
    };
}

export function GetProducersExpiringSuccessResponseItemAppointmentToJSON(value?: GetProducersExpiringSuccessResponseItemAppointment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...GetProducersExpiringSuccessResponseItemToJSON(value),
        'appointment': ProducerAppointmentToJSON(value.appointment),
    };
}

