import {Alert, Placeholder} from "@aws-amplify/ui-react";
import {QueryFunction, useQuery} from "@tanstack/react-query";
import {isLoadingNextQuery, Query} from "@title-service/react-query-utils";
import {DateTimeComponent} from "@title-service/ui";
import {enumValues, parseWholeNumber} from "@title-service/utils";
import React, {useCallback, useContext} from "react";
import {URLSearchParamsInit, useSearchParams} from "react-router-dom";

import {DependencyContext} from "../DependencyContext";
import {
  GetAuditExportsRequest,
  GetAuditExportsSortField,
  GetAuditExportsSuccessResponse,
  parseSortDirection,
  SortDirection,
} from "../shared/adminApi";
import {AuditExportStatusPill} from "../shared/audit/AuditExportStatusPill";
import {DownloadLink, RouterLink} from "../shared/components/Link";
import {LoadingOverlay} from "../shared/components/LoadingOverlay";
import {MaybeNotAvailable} from "../shared/components/Nullable";
import {PageNumbers} from "../shared/components/Pagination";
import {QuantityComponent} from "../shared/components/Quantity";
import {DownloadFolderIcon} from "../shared/components/icons";
import {
  AboveTableContainer,
  BodyRow,
  HeaderRow,
  Table,
  TBody,
  Td,
  Th,
  THead,
} from "../shared/components/tables/PrimaryTable";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
} from "../shared/layout";

type GetAuditExportsQueryKey = [
  "audits",
  "exports",
  "search",
  GetAuditExportsRequest,
];

export type GetAuditExportsQuery = Query<GetAuditExportsSuccessResponse>;

export const GET_AUDIT_EXPORTS_REQUEST_DEFAULTS: GetAuditExportsRequest = {
  sortField: GetAuditExportsSortField.CreatedAt,
  sortDirection: SortDirection.Desc,
  page: 0,
  pageSize: 10,
};

export const AuditExportsRoute: React.FC<{
  GetAuditExportsBodyContent?: typeof GetAuditExportsBodyContent;
}> = ({
  GetAuditExportsBodyContent:
    GetAuditExportsBodyContent_ = GetAuditExportsBodyContent,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const request = mapSearchParamsToGetAuditExportsRequest(searchParams);
  const {adminApi} = useContext(DependencyContext);
  const getAuditExportsQueryFn: QueryFunction<
    GetAuditExportsSuccessResponse,
    GetAuditExportsQueryKey
  > = useCallback(
    ({queryKey: [, , , _request], signal}) =>
      adminApi.getAuditExports(_request, signal),
    [adminApi],
  );
  const getAuditExportsQuery = useQuery<
    GetAuditExportsSuccessResponse,
    unknown,
    GetAuditExportsSuccessResponse,
    GetAuditExportsQueryKey
  >({
    keepPreviousData: true,
    queryKey: ["audits", "exports", "search", request],
    queryFn: getAuditExportsQueryFn,
  });
  const handleChangeRequestState = useCallback(
    (
      updateRequest: (
        currentRequest: GetAuditExportsRequest,
      ) => GetAuditExportsRequest,
    ) => {
      setSearchParams((currentSearchParams) => {
        const currentRequest =
          mapSearchParamsToGetAuditExportsRequest(currentSearchParams);
        const updatedRequest = updateRequest(currentRequest);
        return mapGetAuditExportsRequestToSearchParams(updatedRequest);
      });
    },
    [setSearchParams],
  );
  return (
    <>
      <BodyHeader>
        <PrimaryBodyHeaderContainer>
          <PrimaryBodyHeader value="Audit Exports" />
        </PrimaryBodyHeaderContainer>
      </BodyHeader>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <GetAuditExportsBodyContent_
        getAuditExportsQuery={getAuditExportsQuery}
        onChangeRequest={handleChangeRequestState}
      />
    </>
  );
};

export const GetAuditExportsBodyContent: React.FC<{
  getAuditExportsQuery: GetAuditExportsQuery;
  onChangeRequest: (
    updateRequest: (request: GetAuditExportsRequest) => GetAuditExportsRequest,
  ) => any;
  AuditExportsPageSuccessTableBody?: typeof AuditExportsPageSuccessTableBody;
  AuditExportsPagePlaceholderTableBody?: typeof AuditExportsPagePlaceholderTableBody;
  PageNumbers?: typeof PageNumbers;
}> = ({
  getAuditExportsQuery,
  getAuditExportsQuery: {data, error, isInitialLoading},
  onChangeRequest,
  AuditExportsPageSuccessTableBody:
    AuditExportsPageSuccessTableBody_ = AuditExportsPageSuccessTableBody,
  AuditExportsPagePlaceholderTableBody:
    AuditExportsPagePlaceholderTableBody_ = AuditExportsPagePlaceholderTableBody,
  PageNumbers: PageNumbers_ = PageNumbers,
}) => {
  const isLoading = isLoadingNextQuery(getAuditExportsQuery);
  const setPage = useCallback(
    (zeroIndexedCurrentPageNumber) => {
      onChangeRequest((currentRequest) => ({
        ...currentRequest,
        page: zeroIndexedCurrentPageNumber,
      }));
    },
    [onChangeRequest],
  );
  return (
    <BodyContent>
      <AboveTableContainer justifyContent="flex-start">
        {error ? (
          <Alert variation="error" isDismissible={false} hasIcon={true}>
            Failed to load Audit Exports
          </Alert>
        ) : data ? (
          <QuantityComponent
            quantity={data.pagination.totalRecords}
            label={
              data.pagination.totalRecords === 1
                ? "Audit Export"
                : "Audit Exports"
            }
          />
        ) : null}
      </AboveTableContainer>
      <LoadingOverlay isActive={isLoading}>
        <Table>
          <THead>
            <HeaderRow>
              <Th>Audit Export ID</Th>
              <Th>Status</Th>
              <Th>Created At</Th>
              <Th>Completed At</Th>
              <Th>Zip</Th>
            </HeaderRow>
          </THead>
          {data ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <AuditExportsPageSuccessTableBody_ response={data} />
          ) : error ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <AuditExportsPagePlaceholderTableBody_ isLoaded={true} />
          ) : (
            isInitialLoading && (
              // eslint-disable-next-line react/jsx-pascal-case
              <AuditExportsPagePlaceholderTableBody_ isLoaded={false} />
            )
          )}
        </Table>
      </LoadingOverlay>
      {data ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <PageNumbers_
          totalPages={data.pagination.totalPages}
          zeroIndexedCurrentPageNumber={data.pagination.currentPage}
          onChange={setPage}
        />
      ) : null}
    </BodyContent>
  );
};

export const AuditExportsPagePlaceholderTableBody: React.FC<{
  isLoaded: boolean;
}> = ({isLoaded}) => (
  <TBody>
    <BodyRow>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
    </BodyRow>
  </TBody>
);

export const AuditExportsPageSuccessTableBody: React.FC<{
  response: GetAuditExportsSuccessResponse;
}> = ({response: {auditExports}}) => {
  if (!auditExports.length) {
    return (
      <TBody>
        <BodyRow>
          <Td colSpan={5}>No Audit Exports found</Td>
        </BodyRow>
      </TBody>
    );
  }

  return (
    <TBody>
      {auditExports.map(({id, status, createdAt, completedAt, artifactUrl}) => (
        <BodyRow key={id}>
          <Td>
            <RouterLink to={`/audits/exports/${id}/`}>{id}</RouterLink>
          </Td>
          <Td>
            <AuditExportStatusPill status={status} />
          </Td>
          <Td>
            <DateTimeComponent value={createdAt} />
          </Td>
          <Td>
            <MaybeNotAvailable value={completedAt}>
              {(completedAt_) => <DateTimeComponent value={completedAt_} />}
            </MaybeNotAvailable>
          </Td>
          <Td>
            <MaybeNotAvailable value={artifactUrl}>
              {(artifactUrl_) => (
                <DownloadLink fontSize="2em" href={artifactUrl_}>
                  <DownloadFolderIcon />
                </DownloadLink>
              )}
            </MaybeNotAvailable>
          </Td>
        </BodyRow>
      ))}
    </TBody>
  );
};

export const mapSearchParamsToGetAuditExportsRequest = (
  searchParams: URLSearchParams,
  queryDefaults: GetAuditExportsRequest = GET_AUDIT_EXPORTS_REQUEST_DEFAULTS,
): GetAuditExportsRequest => ({
  sortField:
    mapSearchParamToGetAuditExportsSortField(searchParams.get("sortField")) ??
    queryDefaults.sortField,
  sortDirection:
    parseSortDirection(searchParams.get("sortDirection")) ??
    queryDefaults.sortDirection,
  page: parseWholeNumber(searchParams.get("page")) ?? queryDefaults.page,
  pageSize:
    parseWholeNumber(searchParams.get("pageSize")) ?? queryDefaults.pageSize,
});

const GET_AUDIT_EXPORTS_SORT_FIELD_VALUES = enumValues(
  GetAuditExportsSortField,
);

export const mapSearchParamToGetAuditExportsSortField = (
  getAuditExportsSortField: string | null,
): GetAuditExportsSortField | undefined => {
  const trimmed = getAuditExportsSortField?.trim();
  if (trimmed) {
    return GET_AUDIT_EXPORTS_SORT_FIELD_VALUES.find(
      (value) => value === trimmed,
    );
  }
  return undefined;
};

export const mapGetAuditExportsRequestToSearchParams = ({
  page,
  pageSize,
  sortField,
  sortDirection,
}: GetAuditExportsRequest): URLSearchParamsInit => ({
  page: page.toString(),
  pageSize: pageSize.toString(),
  sortField,
  sortDirection,
});
