/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RemittanceItem } from './RemittanceItem';
import {
    RemittanceItemFromJSON,
    RemittanceItemFromJSONTyped,
    RemittanceItemToJSON,
} from './RemittanceItem';

/**
 * 
 * @export
 * @interface CreateRemittanceRequest
 */
export interface CreateRemittanceRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRemittanceRequest
     */
    achId: string;
    /**
     * 
     * @type {Array<RemittanceItem>}
     * @memberof CreateRemittanceRequest
     */
    items: Array<RemittanceItem>;
    /**
     * 
     * @type {Date}
     * @memberof CreateRemittanceRequest
     */
    remittedOn: Date;
}

/**
 * Check if a given object implements the CreateRemittanceRequest interface.
 */
export function instanceOfCreateRemittanceRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "achId" in value;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "remittedOn" in value;

    return isInstance;
}

export function CreateRemittanceRequestFromJSON(json: any): CreateRemittanceRequest {
    return CreateRemittanceRequestFromJSONTyped(json, false);
}

export function CreateRemittanceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRemittanceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'achId': json['achId'],
        'items': ((json['items'] as Array<any>).map(RemittanceItemFromJSON)),
        'remittedOn': (new Date(json['remittedOn'])),
    };
}

export function CreateRemittanceRequestToJSON(value?: CreateRemittanceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'achId': value.achId,
        'items': ((value.items as Array<any>).map(RemittanceItemToJSON)),
        'remittedOn': (value.remittedOn.toISOString().substr(0,10)),
    };
}

