import {
  Alert,
  Flex,
  PhoneNumberField,
  SelectField,
  StyleToken,
  TextAreaField,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import {Form, useField, useFormikContext} from "formik";
import React from "react";

import {Button, Heading} from "./primatives";

export const FormikForm: React.FC<React.ComponentProps<typeof Flex>> = (
  props,
) => {
  const fieldsetGap = useFieldsetGap();
  const formProps: Pick<React.ComponentProps<typeof Form>, "noValidate"> = {
    noValidate: true,
  };
  return (
    <Flex
      as={Form}
      direction="column"
      alignItems="self-start"
      gap={fieldsetGap}
      {...props}
      {...formProps}
    />
  );
};

export const FormResultErrorMessage: React.FC<
  React.ComponentProps<typeof Alert>
> = (props) => <Alert variation="error" hasIcon={true} {...props} />;

export const FormResultSuccessMessage: React.FC<
  React.ComponentProps<typeof Alert>
> = (props) => <Alert variation="success" hasIcon={true} {...props} />;

export const useFieldsetGap: () => StyleToken<string> = () => {
  const theme = useTheme();
  return theme.tokens.space.large;
};

export const HorizontalFieldset: React.FC<React.ComponentProps<typeof Flex>> = (
  props,
) => (
  <Flex
    as="fieldset"
    direction="row"
    justifyContent="flex-start"
    alignItems="self-start"
    wrap="wrap"
    {...props}
  />
);

export const VerticalFieldset: React.FC<React.ComponentProps<typeof Flex>> = (
  props,
) => {
  const fieldsetGap = useFieldsetGap();
  return (
    <Flex
      as="fieldset"
      direction="column"
      alignItems="self-start"
      gap={fieldsetGap}
      {...props}
    />
  );
};

export const FieldsetHeading: React.FC<React.ComponentProps<typeof Heading>> = (
  props,
) => <Heading level={6} {...props} />;

type TextFieldProps = React.ComponentProps<typeof TextField>;

export const FormikConnectedAmplifyTextField: React.FC<
  Omit<TextFieldProps, "name"> & Required<Pick<TextFieldProps, "name">>
> = (textFieldProps) => {
  const [fieldInput, fieldMeta] = useField(textFieldProps.name);
  return (
    <TextField
      errorMessage={fieldMeta.error}
      hasError={!!fieldMeta.error && fieldMeta.touched}
      {...fieldInput}
      {...textFieldProps}
    />
  );
};

type FormikConnectedAmplifyTextFieldProps = React.ComponentProps<
  typeof FormikConnectedAmplifyTextField
>;

export const FormikConnectedAmplifyCurrencyField: React.FC<
  FormikConnectedAmplifyTextFieldProps
> = (textFieldProps) => (
  <FormikConnectedAmplifyTextField
    type="number"
    step="0.01"
    {...textFieldProps}
  />
);

export const FormikConnectedAmplifyDateField: React.FC<
  FormikConnectedAmplifyTextFieldProps
> = (textFieldProps) => (
  <FormikConnectedAmplifyTextField type="date" {...textFieldProps} />
);

type TextAreaFieldProps = React.ComponentProps<typeof TextAreaField>;

export const FormikConnectedAmplifyTextAreaField: React.FC<
  Omit<TextAreaFieldProps, "name"> & Required<Pick<TextAreaFieldProps, "name">>
> = (textAreaFieldProps) => {
  const [fieldInput, fieldMeta] = useField(textAreaFieldProps.name);
  return (
    <TextAreaField
      errorMessage={fieldMeta.error}
      hasError={!!fieldMeta.error && fieldMeta.touched}
      {...fieldInput}
      {...textAreaFieldProps}
    />
  );
};

type PhoneNumberFieldProps = React.ComponentProps<typeof PhoneNumberField>;

export const FormikConnectedAmplifyPhoneNumberField: React.FC<
  Omit<PhoneNumberFieldProps, "name"> &
    Required<Pick<PhoneNumberFieldProps, "name">>
> = (phoneNumberFieldProps) => {
  const [fieldInput, fieldMeta] = useField(phoneNumberFieldProps.name);
  return (
    <PhoneNumberField
      errorMessage={fieldMeta.error}
      hasError={!!fieldMeta.error && fieldMeta.touched}
      {...fieldInput}
      {...phoneNumberFieldProps}
    />
  );
};

type SelectFieldProps = React.ComponentProps<typeof SelectField>;

export const FormikConnectedAmplifySelectField: React.FC<
  Omit<SelectFieldProps, "name"> & Required<Pick<SelectFieldProps, "name">>
> = (selectFieldProps) => {
  const [fieldInput, fieldMeta] = useField(selectFieldProps.name);
  return (
    <SelectField
      errorMessage={fieldMeta.error}
      hasError={!!fieldMeta.error && fieldMeta.touched}
      {...fieldInput}
      {...selectFieldProps}
    />
  );
};

export const FormikConnectedAmplifySubmitButton: React.FC<
  React.ComponentProps<typeof SubmitButton>
> = ({isLoading, ...props}) => {
  const formik = useFormikContext();
  return (
    <SubmitButton isLoading={formik.isSubmitting || isLoading} {...props} />
  );
};

type ButtonProps = React.ComponentProps<typeof Button>;

export const SubmitButton: React.FC<
  Omit<ButtonProps, "value"> & Partial<Pick<ButtonProps, "value">>
> = (props) => (
  <Button
    type="submit"
    variation="primary"
    loadingText="Submitting..."
    value="Submit"
    {...props}
  />
);
