/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PolicyStatus = {
    Quoted: 'quoted',
    Issued: 'issued'
} as const;
export type PolicyStatus = typeof PolicyStatus[keyof typeof PolicyStatus];


export function PolicyStatusFromJSON(json: any): PolicyStatus {
    return PolicyStatusFromJSONTyped(json, false);
}

export function PolicyStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolicyStatus {
    return json as PolicyStatus;
}

export function PolicyStatusToJSON(value?: PolicyStatus | null): any {
    return value as any;
}

