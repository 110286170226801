export const isNull = <T>(value: T | null | undefined): value is null =>
  value === null;

export const isUndefined = <T>(
  value: T | null | undefined,
): value is undefined => value === void 0;

export const isNullOrUndefined = <T>(
  value: T | null | undefined,
): value is null | undefined => isNull(value) || isUndefined(value);

export const notNullOrUndefined = <T>(
  value: T | null | undefined,
): value is T => !isNullOrUndefined(value);

if (import.meta.vitest) {
  const {describe, expect, it} = import.meta.vitest;

  describe("isNull", () => {
    it("returns true if null", () => {
      expect(isNull(null)).toEqual(true);
    });

    it("returns false if undefined", () => {
      expect(isNull(undefined)).toEqual(false);
    });

    it("returns false if 0", () => {
      expect(isNull(0)).toEqual(false);
    });

    it("returns false if empty string", () => {
      expect(isNull("")).toEqual(false);
    });

    it("returns false if empty array", () => {
      expect(isNull([])).toEqual(false);
    });
  });

  describe("isUndefined", () => {
    it("returns true if undefined", () => {
      expect(isUndefined(undefined)).toEqual(true);
    });

    it("returns false if null", () => {
      expect(isUndefined(null)).toEqual(false);
    });

    it("returns false if 0", () => {
      expect(isUndefined(0)).toEqual(false);
    });

    it("returns false if empty string", () => {
      expect(isUndefined("")).toEqual(false);
    });

    it("returns false if empty array", () => {
      expect(isUndefined([])).toEqual(false);
    });
  });

  describe("isNullOrUndefined", () => {
    it("returns true if undefined", () => {
      expect(isNullOrUndefined(undefined)).toEqual(true);
    });

    it("returns false if null", () => {
      expect(isNullOrUndefined(null)).toEqual(true);
    });

    it("returns false if 0", () => {
      expect(isNullOrUndefined(0)).toEqual(false);
    });

    it("returns false if empty string", () => {
      expect(isNullOrUndefined("")).toEqual(false);
    });

    it("returns false if empty array", () => {
      expect(isNullOrUndefined([])).toEqual(false);
    });
  });

  describe("notNullOrUndefined", () => {
    it("returns false if null", () => {
      expect(notNullOrUndefined(null)).toEqual(false);
    });

    it("returns false if undefined", () => {
      expect(notNullOrUndefined(undefined)).toEqual(false);
    });

    it("returns true if present", () => {
      expect(notNullOrUndefined(0)).toEqual(true);
      expect(notNullOrUndefined("")).toEqual(true);
    });
  });
}
