import React from "react";

import {AuditStatus} from "../adminApi";
import {InfoPill, SuccessPill, WarningPill} from "../components/Pills";

export const AuditStatusPill: React.FC<{
  status: AuditStatus;
}> = ({status}) => {
  switch (status) {
    case AuditStatus.Completed:
      return <AuditStatusPillCompleted />;
    case AuditStatus.InProgress:
      return <AuditStatusPillInProgress />;
    default:
      return <AuditStatusPillNotStarted />;
  }
};

const AuditStatusPillNotStarted: React.FC = () => (
  <InfoPill label="Not Started" />
);

const AuditStatusPillInProgress: React.FC = () => (
  <WarningPill label="In Progress" />
);

const AuditStatusPillCompleted: React.FC = () => (
  <SuccessPill label="Completed" />
);
