import {ProducerType} from "../adminApi";

export type AgencyProducerId = {agencyProducerId: string};
export type IndividualProducerId = {individualProducerId: string};
export type ProducerId = IndividualProducerId | AgencyProducerId;

export const buildProducerId = (
  producerType: ProducerType,
  producerId: string,
): ProducerId => {
  switch (producerType) {
    case ProducerType.Agency:
      return {agencyProducerId: producerId};
    case ProducerType.Individual:
      return {individualProducerId: producerId};
  }
};
