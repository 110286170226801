/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttomAddress } from './AttomAddress';
import {
    AttomAddressFromJSON,
    AttomAddressFromJSONTyped,
    AttomAddressToJSON,
} from './AttomAddress';
import type { AttomPropertyTransfer } from './AttomPropertyTransfer';
import {
    AttomPropertyTransferFromJSON,
    AttomPropertyTransferFromJSONTyped,
    AttomPropertyTransferToJSON,
} from './AttomPropertyTransfer';
import type { GetPropertySuccessResponseLatestAssessment } from './GetPropertySuccessResponseLatestAssessment';
import {
    GetPropertySuccessResponseLatestAssessmentFromJSON,
    GetPropertySuccessResponseLatestAssessmentFromJSONTyped,
    GetPropertySuccessResponseLatestAssessmentToJSON,
} from './GetPropertySuccessResponseLatestAssessment';
import type { GetPropertySuccessResponseMortgage } from './GetPropertySuccessResponseMortgage';
import {
    GetPropertySuccessResponseMortgageFromJSON,
    GetPropertySuccessResponseMortgageFromJSONTyped,
    GetPropertySuccessResponseMortgageToJSON,
} from './GetPropertySuccessResponseMortgage';

/**
 * 
 * @export
 * @interface GetPropertySuccessResponse
 */
export interface GetPropertySuccessResponse {
    /**
     * 
     * @type {string}
     * @memberof GetPropertySuccessResponse
     */
    cbsaName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPropertySuccessResponse
     */
    deedOwners: Array<string>;
    /**
     * 
     * @type {GetPropertySuccessResponseLatestAssessment}
     * @memberof GetPropertySuccessResponse
     */
    latestAssessment?: GetPropertySuccessResponseLatestAssessment;
    /**
     * 
     * @type {AttomPropertyTransfer}
     * @memberof GetPropertySuccessResponse
     */
    latestForeclosure?: AttomPropertyTransfer;
    /**
     * 
     * @type {AttomPropertyTransfer}
     * @memberof GetPropertySuccessResponse
     */
    latestFullValueTransfer?: AttomPropertyTransfer;
    /**
     * 
     * @type {AttomPropertyTransfer}
     * @memberof GetPropertySuccessResponse
     */
    latestSale?: AttomPropertyTransfer;
    /**
     * 
     * @type {string}
     * @memberof GetPropertySuccessResponse
     */
    legalDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPropertySuccessResponse
     */
    legalTownship?: string;
    /**
     * 
     * @type {Array<GetPropertySuccessResponseMortgage>}
     * @memberof GetPropertySuccessResponse
     */
    mortgages: Array<GetPropertySuccessResponseMortgage>;
    /**
     * 
     * @type {string}
     * @memberof GetPropertySuccessResponse
     */
    msaName?: string;
    /**
     * 
     * @type {AttomAddress}
     * @memberof GetPropertySuccessResponse
     */
    ownerMailingAddress?: AttomAddress;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPropertySuccessResponse
     */
    ownerTypeDescriptions: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPropertySuccessResponse
     */
    partyOwners: Array<string>;
    /**
     * 
     * @type {AttomAddress}
     * @memberof GetPropertySuccessResponse
     */
    propertyAddress?: AttomAddress;
    /**
     * 
     * @type {string}
     * @memberof GetPropertySuccessResponse
     */
    titleCompany?: string;
}

/**
 * Check if a given object implements the GetPropertySuccessResponse interface.
 */
export function instanceOfGetPropertySuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deedOwners" in value;
    isInstance = isInstance && "mortgages" in value;
    isInstance = isInstance && "ownerTypeDescriptions" in value;
    isInstance = isInstance && "partyOwners" in value;

    return isInstance;
}

export function GetPropertySuccessResponseFromJSON(json: any): GetPropertySuccessResponse {
    return GetPropertySuccessResponseFromJSONTyped(json, false);
}

export function GetPropertySuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPropertySuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cbsaName': !exists(json, 'cbsaName') ? undefined : json['cbsaName'],
        'deedOwners': json['deedOwners'],
        'latestAssessment': !exists(json, 'latestAssessment') ? undefined : GetPropertySuccessResponseLatestAssessmentFromJSON(json['latestAssessment']),
        'latestForeclosure': !exists(json, 'latestForeclosure') ? undefined : AttomPropertyTransferFromJSON(json['latestForeclosure']),
        'latestFullValueTransfer': !exists(json, 'latestFullValueTransfer') ? undefined : AttomPropertyTransferFromJSON(json['latestFullValueTransfer']),
        'latestSale': !exists(json, 'latestSale') ? undefined : AttomPropertyTransferFromJSON(json['latestSale']),
        'legalDescription': !exists(json, 'legalDescription') ? undefined : json['legalDescription'],
        'legalTownship': !exists(json, 'legalTownship') ? undefined : json['legalTownship'],
        'mortgages': ((json['mortgages'] as Array<any>).map(GetPropertySuccessResponseMortgageFromJSON)),
        'msaName': !exists(json, 'msaName') ? undefined : json['msaName'],
        'ownerMailingAddress': !exists(json, 'ownerMailingAddress') ? undefined : AttomAddressFromJSON(json['ownerMailingAddress']),
        'ownerTypeDescriptions': json['ownerTypeDescriptions'],
        'partyOwners': json['partyOwners'],
        'propertyAddress': !exists(json, 'propertyAddress') ? undefined : AttomAddressFromJSON(json['propertyAddress']),
        'titleCompany': !exists(json, 'titleCompany') ? undefined : json['titleCompany'],
    };
}

export function GetPropertySuccessResponseToJSON(value?: GetPropertySuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cbsaName': value.cbsaName,
        'deedOwners': value.deedOwners,
        'latestAssessment': GetPropertySuccessResponseLatestAssessmentToJSON(value.latestAssessment),
        'latestForeclosure': AttomPropertyTransferToJSON(value.latestForeclosure),
        'latestFullValueTransfer': AttomPropertyTransferToJSON(value.latestFullValueTransfer),
        'latestSale': AttomPropertyTransferToJSON(value.latestSale),
        'legalDescription': value.legalDescription,
        'legalTownship': value.legalTownship,
        'mortgages': ((value.mortgages as Array<any>).map(GetPropertySuccessResponseMortgageToJSON)),
        'msaName': value.msaName,
        'ownerMailingAddress': AttomAddressToJSON(value.ownerMailingAddress),
        'ownerTypeDescriptions': value.ownerTypeDescriptions,
        'partyOwners': value.partyOwners,
        'propertyAddress': AttomAddressToJSON(value.propertyAddress),
        'titleCompany': value.titleCompany,
    };
}

