import {
  FormikConnectedAmplifyDateField,
  FormikConnectedAmplifyTextAreaField,
  FormikConnectedAmplifyTextField,
} from "@title-service/ui";
import {
  buildDateSchema,
  transformNullToUndefined,
} from "@title-service/yup-utils";
import React from "react";
import * as Yup from "yup";

export const AGENCY_REFERENCE_ID_SCHEMA: Yup.StringSchema = Yup.string()
  .optional()
  .trim()
  .transform(transformNullToUndefined);

export const AgencyReferenceIdField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = (props) => (
  <FormikConnectedAmplifyTextField
    testId="agency-reference-id-input"
    label="External Agency ID (optional)"
    {...props}
  />
);

export const AUDIT_SCOPE_SCHEMA: Yup.StringSchema = Yup.string()
  .optional()
  .trim()
  .transform(transformNullToUndefined);

export const AuditScopeField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = (props) => (
  <FormikConnectedAmplifyTextField
    testId="audit-scope-input"
    label="Audit Scope (optional)"
    {...props}
  />
);

export const COMPLETED_ON_SCHEMA: Yup.DateSchema<Date | undefined> =
  buildDateSchema().optional();

export const CompletedOnField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyDateField>, "label">
> = (props) => (
  <FormikConnectedAmplifyDateField
    testId="completed-on-input"
    label="Actual Completion Date (optional)"
    {...props}
  />
);

export const DESCRIPTION_SCHEMA: Yup.StringSchema = Yup.string()
  .optional()
  .trim()
  .transform(transformNullToUndefined);

export const DescriptionField: React.FC<
  Omit<
    React.ComponentProps<typeof FormikConnectedAmplifyTextAreaField>,
    "label"
  >
> = (props) => (
  <FormikConnectedAmplifyTextAreaField
    testId="description-input"
    label="Description (optional)"
    rows={5}
    {...props}
  />
);

export const DUE_ON_SCHEMA: Yup.DateSchema<Date> = buildDateSchema().required(
  "Due date is required",
);

export const DueOnField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyDateField>, "label">
> = (props) => (
  <FormikConnectedAmplifyDateField
    testId="due-on-input"
    label="Due Date"
    {...props}
  />
);

export const STARTING_LETTER_SENT_ON_SCHEMA: Yup.DateSchema<Date | undefined> =
  buildDateSchema().optional();

export const StartingLetterSentOnField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyDateField>, "label">
> = (props) => (
  <FormikConnectedAmplifyDateField
    testId="starting-letter-sent-on-input"
    label="Starting Letter Sent Date (optional)"
    {...props}
  />
);

const STATE_ABBREVIATION_LENGTH_ERROR_MESSAGE =
  "State abbreviation must be two letters";
export const STATE_SCHEMA: Yup.StringSchema = Yup.string()
  .uppercase()
  .trim()
  .transform(transformNullToUndefined)
  .matches(/^[a-zA-Z]+$/u, {
    message: STATE_ABBREVIATION_LENGTH_ERROR_MESSAGE,
    excludeEmptyString: true,
  })
  .length(2, STATE_ABBREVIATION_LENGTH_ERROR_MESSAGE);

export const StateField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = (props) => (
  <FormikConnectedAmplifyTextField
    testId="state-input"
    label="State (optional)"
    {...props}
  />
);

export const TARGET_COMPLETE_ON_SCHEMA: Yup.DateSchema<Date> =
  buildDateSchema().required("Target completion date is required");

export const TargetCompleteOnField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyDateField>, "label">
> = (props) => (
  <FormikConnectedAmplifyDateField
    testId="target-complete-on-input"
    label="Target Completion Date"
    {...props}
  />
);

export const TARGET_START_ON_SCHEMA: Yup.DateSchema<Date> =
  buildDateSchema().required("Target start date is required");

export const TargetStartOnField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyDateField>, "label">
> = (props) => (
  <FormikConnectedAmplifyDateField
    testId="target-start-on-input"
    label="Target Start Date"
    {...props}
  />
);
