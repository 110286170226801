import {Flex, useTheme} from "@aws-amplify/ui-react";
import React, {useCallback} from "react";

import {SortDirection} from "../../adminApi";
import {CaretDirection, CaretIcon, useIconTextSpacing} from "../icons";

import {Th} from "./PrimaryTable";

export type SortableThProps<T> = React.ComponentProps<typeof Th> & {
  field: T;
  currentSortField: T;
  currentSortDirection: SortDirection;
  fieldInitialSortDirection: SortDirection;
  onSort: (field: T, direction: SortDirection) => any;
};

const TH_STYLE: React.CSSProperties = {cursor: "pointer"};

export const SortableTh = <T,>({
  children,
  field,
  currentSortDirection,
  currentSortField,
  fieldInitialSortDirection,
  onSort,
  ...passthroughs
}: SortableThProps<T>) => {
  const handleClick = useCallback(() => {
    const newSortDirection = determineNewSortDirection(
      field,
      fieldInitialSortDirection,
      currentSortField,
      currentSortDirection,
    );
    onSort(field, newSortDirection);
  }, [
    currentSortDirection,
    currentSortField,
    field,
    fieldInitialSortDirection,
    onSort,
  ]);
  const iconSpacing = useIconTextSpacing();
  const fieldCurrentSortDirection =
    currentSortField === field ? currentSortDirection : undefined;
  return (
    <Th style={TH_STYLE} {...passthroughs} onClick={handleClick}>
      <Flex
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        gap={iconSpacing}
      >
        {children}
        <SortArrows activeSortDirection={fieldCurrentSortDirection} />
      </Flex>
    </Th>
  );
};

const SortArrows: React.FC<{activeSortDirection?: SortDirection}> = ({
  activeSortDirection,
}) => {
  const {
    tokens: {
      colors: {
        font: {
          disabled: inactiveSortDirectionColor,
          primary: activeSortDirectionColor,
        },
      },
    },
  } = useTheme();
  return (
    <Flex direction="column" justifyContent="center" gap="xxxs">
      <CaretIcon
        fontSize="xxxs"
        direction={CaretDirection.UP}
        color={
          activeSortDirection === SortDirection.Desc
            ? activeSortDirectionColor
            : inactiveSortDirectionColor
        }
      />
      <CaretIcon
        fontSize="xxxs"
        direction={CaretDirection.DOWN}
        color={
          activeSortDirection === SortDirection.Asc
            ? activeSortDirectionColor
            : inactiveSortDirectionColor
        }
      />
    </Flex>
  );
};

export const determineNewSortDirection = <T,>(
  field: T,
  fieldInitialSortDirection: SortDirection,
  currentSortField: T,
  currentSortDirection: SortDirection,
): SortDirection =>
  currentSortField === field
    ? toggleSortDirection(currentSortDirection)
    : fieldInitialSortDirection;

export const toggleSortDirection = (direction: SortDirection) =>
  direction === SortDirection.Desc ? SortDirection.Asc : SortDirection.Desc;
