import {NumberComponentRaw, Text, TextRaw} from "@title-service/ui";
import React from "react";

type QuantityComponentProps = {
  quantity: number;
  label: string;
};

export const QuantityComponentRaw: React.FC<QuantityComponentProps> = ({
  quantity,
  label,
}) => (
  <>
    <NumberComponentRaw value={quantity} />
    <TextRaw value=" " />
    <TextRaw value={label} />
  </>
);

export const QuantityComponent: React.FC<
  Omit<React.ComponentProps<typeof Text>, "value"> & {
    quantity: number;
    label: string;
  }
> = (props) => (
  <Text
    variation="secondary"
    {...props}
    value={<QuantityComponentRaw {...props} />}
  />
);
