import {Flex} from "@aws-amplify/ui-react";
import {
  DateComponent,
  DateTimeComponent,
  PercentComponent,
  Text,
  usePrimarySectionGap,
} from "@title-service/ui";
import React, {useCallback, useState} from "react";
import styled from "styled-components";

import {OrderScore, UnderwritingDecision} from "../../adminApi";
import {
  DataGrid,
  GridItem,
  GridItemLabel,
  GridItemLabelContainer,
} from "../../components/DataGrid";
import {MaybeDateAndAmount} from "../../components/MaybeDateAndAmount";
import {
  MaybeNotAvailable,
  StringMaybeBlankComponent,
} from "../../components/Nullable";
import {VerticalStringList} from "../../components/VerticalStringList";
import {YesNoComponent} from "../../components/YesNo";
import {CaretDirection, CaretIcon} from "../../components/icons";
import {
  BodyRow as PrimaryBodyRow,
  HeaderRow as PrimaryHeaderRow,
  Table as PrimaryTable,
  TBody as PrimaryTBody,
  Td as PrimaryTd,
  Th as PrimaryTh,
  THead as PrimaryTHead,
} from "../../components/tables/PrimaryTable";
import {
  BodyRow as SecondaryBodyRow,
  HeaderRow as SecondaryHeaderRow,
  Table as SecondaryTable,
  TBody as SecondaryTBody,
  Td as SecondaryTd,
  Th as SecondaryTh,
  THead as SecondaryTHead,
} from "../../components/tables/SecondaryTable";
import {
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../../layout";

export const UnderwritingDecisionsSection: React.FC<{
  scores: OrderScore[];
  underwritingDecisions: UnderwritingDecision[];
}> = ({scores, underwritingDecisions}) => {
  const scoreEntries = reconcileScoresAndUnderwritingDecisions(
    scores,
    underwritingDecisions,
  );
  return (
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Scores" />
      </SecondarySectionHeaderContainer>
      <SecondarySectionContent>
        {scoreEntries.length > 0 ? (
          <PrimaryTable>
            <PrimaryTHead>
              <PrimaryHeaderRow>
                <PrimaryTh>Score</PrimaryTh>
                <PrimaryTh>Scored At</PrimaryTh>
                <PrimaryTh />
              </PrimaryHeaderRow>
            </PrimaryTHead>
            <PrimaryTBody>
              {scoreEntries.map((scoreEntry) => (
                <UnderwritingDecisionRow
                  key={scoreEntry.scoreId}
                  scoreEntry={scoreEntry}
                />
              ))}
            </PrimaryTBody>
          </PrimaryTable>
        ) : (
          <span>No Scores</span>
        )}
      </SecondarySectionContent>
    </SecondarySection>
  );
};

const UnderwritingDecisionsCreatedAtTd = styled(PrimaryTd)({
  width: "100px",
});

const UnderwritingDecisionsScoreTd = styled(PrimaryTd)({
  width: "75px",
});

const CaretTd = styled(PrimaryTd)({
  width: "20px",
});

const UnderwritingDecisionRow: React.FC<{
  scoreEntry: ScoreEntry;
}> = ({
  scoreEntry: {createdAt, attomId, modelVersion, score, underwritingDecision},
}) => {
  const [displayDetails, setDisplayDetails] = useState(false);
  const toggleDetailsMenu = useCallback(() => {
    setDisplayDetails((previousDisplayDetails) => !previousDisplayDetails);
  }, []);
  const primarySectionGap = usePrimarySectionGap();

  return (
    <>
      <PrimaryBodyRow>
        <UnderwritingDecisionsScoreTd>
          <PercentComponent value={score} />
        </UnderwritingDecisionsScoreTd>
        <UnderwritingDecisionsCreatedAtTd>
          <DateTimeComponent value={createdAt} />
        </UnderwritingDecisionsCreatedAtTd>
        <CaretTd>
          <CaretIcon
            fontSize="xs"
            onClick={toggleDetailsMenu}
            direction={
              displayDetails ? CaretDirection.DOWN : CaretDirection.RIGHT
            }
          />
        </CaretTd>
      </PrimaryBodyRow>
      {displayDetails ? (
        <PrimaryBodyRow>
          <PrimaryTd colSpan={3}>
            <Flex direction="column" gap={primarySectionGap}>
              <SecondarySection>
                <DataGrid>
                  <GridItem>
                    <GridItemLabelContainer>
                      <GridItemLabel value="ATTOM ID" />
                    </GridItemLabelContainer>
                    <Text value={attomId} />
                  </GridItem>
                  <GridItem>
                    <GridItemLabelContainer>
                      <GridItemLabel value="Model Version" />
                    </GridItemLabelContainer>
                    <Text value={modelVersion} />
                  </GridItem>
                  {underwritingDecision ? (
                    <>
                      <GridItem>
                        <GridItemLabelContainer>
                          <GridItemLabel value="AUM" />
                        </GridItemLabelContainer>
                        <YesNoComponent
                          value={!underwritingDecision.traditionalSearch}
                        />
                      </GridItem>
                      <GridItem>
                        <GridItemLabelContainer>
                          <GridItemLabel value="Last FVT" />
                        </GridItemLabelContainer>
                        <MaybeNotAvailable
                          value={underwritingDecision.latestFullValueTransfer}
                        >
                          {(fvt) => (
                            <MaybeDateAndAmount
                              date={fvt.date}
                              amount={fvt.amount}
                            />
                          )}
                        </MaybeNotAvailable>
                      </GridItem>
                      <GridItem>
                        <GridItemLabelContainer>
                          <GridItemLabel value="Vesting Information" />
                        </GridItemLabelContainer>
                        <VerticalStringList
                          values={underwritingDecision.vestingInformation}
                        />
                      </GridItem>
                    </>
                  ) : null}
                </DataGrid>
              </SecondarySection>
              {underwritingDecision ? (
                <SecondarySection>
                  <SecondarySectionHeaderContainer>
                    <SecondarySectionHeader value="Transfer documents since last FVT" />
                  </SecondarySectionHeaderContainer>
                  <SecondarySectionContent>
                    {underwritingDecision.transferDocumentsSinceLastFullValueTransfer &&
                    underwritingDecision
                      .transferDocumentsSinceLastFullValueTransfer.length >
                      0 ? (
                      <SecondaryTable>
                        <SecondaryTHead>
                          <SecondaryHeaderRow>
                            <SecondaryTh>Document Number</SecondaryTh>
                            <SecondaryTh>Recording Date</SecondaryTh>
                            <SecondaryTh>Document Type</SecondaryTh>
                            <SecondaryTh>Instrument Date</SecondaryTh>
                          </SecondaryHeaderRow>
                        </SecondaryTHead>
                        <SecondaryTBody>
                          {underwritingDecision.transferDocumentsSinceLastFullValueTransfer.map(
                            (transferDocument) => (
                              <SecondaryBodyRow
                                key={transferDocument.documentNumber}
                              >
                                <SecondaryTd>
                                  {transferDocument.documentNumber}
                                </SecondaryTd>
                                <SecondaryTd>
                                  <DateComponent
                                    value={transferDocument.recordingDate}
                                  />
                                </SecondaryTd>
                                <SecondaryTd>
                                  <StringMaybeBlankComponent
                                    value={transferDocument.documentType}
                                  />
                                </SecondaryTd>
                                <SecondaryTd>
                                  <MaybeNotAvailable
                                    value={transferDocument.instrumentDate}
                                  >
                                    {(instrumentDate) => (
                                      <DateComponent value={instrumentDate} />
                                    )}
                                  </MaybeNotAvailable>
                                </SecondaryTd>
                              </SecondaryBodyRow>
                            ),
                          )}
                        </SecondaryTBody>
                      </SecondaryTable>
                    ) : (
                      <span>No documents</span>
                    )}
                  </SecondarySectionContent>
                </SecondarySection>
              ) : null}
            </Flex>
          </PrimaryTd>
        </PrimaryBodyRow>
      ) : null}
    </>
  );
};

type ScoreEntry = Omit<OrderScore, "id"> & {
  scoreId: string;
  underwritingDecision?: Omit<UnderwritingDecision, "scoreId" | "createdAt">;
};

export const reconcileScoresAndUnderwritingDecisions = (
  scores: OrderScore[],
  underwritingDecisions: UnderwritingDecision[],
): ScoreEntry[] => {
  const scoresById: Map<string, OrderScore> = new Map<string, OrderScore>();
  const remainingScoreIds: Map<string, boolean> = new Map<string, boolean>();

  for (const score of scores) {
    scoresById.set(score.id, score);
    remainingScoreIds.set(score.id, true);
  }

  const scoreEntries: ScoreEntry[] = [];

  for (const underwritingDecision of underwritingDecisions) {
    const {scoreId, createdAt, ...underwritingDecisionData} =
      underwritingDecision;

    const score = scoresById.get(scoreId);

    if (!score) {
      continue;
    }

    const {id: _, ...scoreData} = score;
    const scoreEntry: ScoreEntry = {
      ...scoreData,
      underwritingDecision: underwritingDecisionData,
      createdAt,
      scoreId,
    };

    scoreEntries.push(scoreEntry);
    remainingScoreIds.delete(scoreId);
  }

  for (const remainingScoreId of remainingScoreIds.keys()) {
    const score = scoresById.get(remainingScoreId);

    if (!score) {
      continue;
    }

    const {id: scoreId, ...scoreData} = score;
    scoreEntries.push({
      ...scoreData,
      scoreId,
    });
  }

  return scoreEntries.sort(
    (scoreEntry1, scoreEntry2) =>
      scoreEntry2.createdAt.getTime() - scoreEntry1.createdAt.getTime(),
  );
};
