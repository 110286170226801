/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AuditExportStatus = {
    Created: 'created',
    Started: 'started',
    Success: 'success',
    Failure: 'failure'
} as const;
export type AuditExportStatus = typeof AuditExportStatus[keyof typeof AuditExportStatus];


export function AuditExportStatusFromJSON(json: any): AuditExportStatus {
    return AuditExportStatusFromJSONTyped(json, false);
}

export function AuditExportStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditExportStatus {
    return json as AuditExportStatus;
}

export function AuditExportStatusToJSON(value?: AuditExportStatus | null): any {
    return value as any;
}

