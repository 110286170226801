import {
  enumValues,
  isNull,
  isNullOrUndefined,
  notNullOrUndefined,
  toUTCDate,
} from "@title-service/utils";
import * as Yup from "yup";

export const transformNullToUndefined = <
  TCurrentValue,
  TSchema extends Yup.Schema<any, any, any, "">,
>(
  currentValue: TCurrentValue | null | undefined,
  originalValue: any,
  context: TSchema,
): TCurrentValue | undefined => {
  if (context.isType(currentValue) || isNull(currentValue)) {
    return currentValue ? currentValue : undefined;
  }
  return currentValue;
};

export const buildEnumSchema = <Enum extends Record<string, string>>(
  enumObj: Enum,
  validValueErrorMessage?: string,
): Yup.StringSchema<Enum[keyof Enum] | undefined> =>
  Yup.string()
    .trim()
    .transform(transformNullToUndefined)
    .oneOf(enumValues(enumObj), validValueErrorMessage);

const transformInvalidDateToUndefined = <
  TSchema extends Yup.DateSchema<Date | undefined, any, any, "">,
>(
  currentValue: Date | undefined,
  _originalValue: any,
  _context: TSchema,
): Date | undefined => {
  if (currentValue instanceof Date && isNaN(currentValue.getTime())) {
    return undefined;
  }
  return currentValue;
};

const transformLocalDateToUTCDate = <
  TSchema extends Yup.DateSchema<Date | undefined, any, any, "">,
>(
  currentValue: Date | undefined,
  originalValue: any,
  context: TSchema,
): Date | undefined => {
  if (context.isType(currentValue) && notNullOrUndefined(currentValue)) {
    return toUTCDate(currentValue);
  }
  return currentValue;
};

export const buildDateSchema = (): Yup.DateSchema<Date | undefined> =>
  Yup.date()
    .transform(transformNullToUndefined)
    .transform(transformInvalidDateToUndefined)
    .transform(transformLocalDateToUTCDate);

const transformAbsentNumberToUndefined = <
  TSchema extends Yup.NumberSchema<number | undefined, any, any, "">,
>(
  currentValue: number | undefined,
  originalValue: any,
  _context: TSchema,
): number | undefined => {
  if (typeof originalValue === "string" && originalValue.trim() === "") {
    return undefined;
  }
  if (isNullOrUndefined(originalValue)) {
    return undefined;
  }
  return currentValue;
};

export const buildNumberSchema = (
  invalidNumberMessage: string = "Must be a number",
): Yup.NumberSchema =>
  Yup.number()
    .transform(transformAbsentNumberToUndefined)
    .typeError(invalidNumberMessage);

export const getNumberOfDecimalDigits = (value: number) => {
  const decimalPartOnly = value % 1;
  const decimalPartOnlyString = decimalPartOnly.toLocaleString("en-US", {
    minimumFractionDigits: 1,
  });
  const [, decimalDigitsString] = decimalPartOnlyString.split(".");
  return decimalDigitsString.length;
};

export const buildCurrencySchema = ({
  invalidNumberMessage,
  invalidPrecisionMessage = "Maximum 2 decimal digits",
}: {invalidNumberMessage?: string; invalidPrecisionMessage?: string} = {}) =>
  buildNumberSchema(invalidNumberMessage).test({
    skipAbsent: true,
    test: (value, context) => {
      if (value && !Number.isNaN(value)) {
        const numberOfDecimalDigits = getNumberOfDecimalDigits(value);
        if (numberOfDecimalDigits > 2) {
          return context.createError({message: invalidPrecisionMessage});
        }
      }

      return true;
    },
  });

export const buildEmptyObjectForSchema = <
  TObject extends Yup.AnyObject,
  TKeys extends keyof TObject,
>(
  schema: Yup.ObjectSchema<TObject>,
): {[Key in TKeys]: undefined} => {
  const keys: TKeys[] = Object.keys(schema.fields) as TKeys[];
  const entries: [TKeys, undefined][] = keys.map((key) => [key, undefined]);
  return Object.fromEntries(entries) as {[Key in TKeys]: undefined};
};
