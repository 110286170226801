/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemittanceReference
 */
export interface RemittanceReference {
    /**
     * 
     * @type {string}
     * @memberof RemittanceReference
     */
    achId: string;
    /**
     * 
     * @type {Date}
     * @memberof RemittanceReference
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof RemittanceReference
     */
    creatorFamilyName?: string;
    /**
     * 
     * @type {string}
     * @memberof RemittanceReference
     */
    creatorGivenName?: string;
    /**
     * 
     * @type {string}
     * @memberof RemittanceReference
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof RemittanceReference
     */
    remittedOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RemittanceReference
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the RemittanceReference interface.
 */
export function instanceOfRemittanceReference(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "achId" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function RemittanceReferenceFromJSON(json: any): RemittanceReference {
    return RemittanceReferenceFromJSONTyped(json, false);
}

export function RemittanceReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemittanceReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'achId': json['achId'],
        'createdAt': (new Date(json['createdAt'])),
        'creatorFamilyName': !exists(json, 'creatorFamilyName') ? undefined : json['creatorFamilyName'],
        'creatorGivenName': !exists(json, 'creatorGivenName') ? undefined : json['creatorGivenName'],
        'id': json['id'],
        'remittedOn': !exists(json, 'remittedOn') ? undefined : (new Date(json['remittedOn'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function RemittanceReferenceToJSON(value?: RemittanceReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'achId': value.achId,
        'createdAt': (value.createdAt.toISOString()),
        'creatorFamilyName': value.creatorFamilyName,
        'creatorGivenName': value.creatorGivenName,
        'id': value.id,
        'remittedOn': value.remittedOn === undefined ? undefined : (value.remittedOn.toISOString().substr(0,10)),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

