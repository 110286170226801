/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetIndividualProducersSuccessResponseProducer } from './GetIndividualProducersSuccessResponseProducer';
import {
    GetIndividualProducersSuccessResponseProducerFromJSON,
    GetIndividualProducersSuccessResponseProducerFromJSONTyped,
    GetIndividualProducersSuccessResponseProducerToJSON,
} from './GetIndividualProducersSuccessResponseProducer';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';

/**
 * 
 * @export
 * @interface GetIndividualProducersSuccessResponse
 */
export interface GetIndividualProducersSuccessResponse {
    /**
     * 
     * @type {Array<GetIndividualProducersSuccessResponseProducer>}
     * @memberof GetIndividualProducersSuccessResponse
     */
    individualProducers: Array<GetIndividualProducersSuccessResponseProducer>;
    /**
     * 
     * @type {Pagination}
     * @memberof GetIndividualProducersSuccessResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the GetIndividualProducersSuccessResponse interface.
 */
export function instanceOfGetIndividualProducersSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "individualProducers" in value;
    isInstance = isInstance && "pagination" in value;

    return isInstance;
}

export function GetIndividualProducersSuccessResponseFromJSON(json: any): GetIndividualProducersSuccessResponse {
    return GetIndividualProducersSuccessResponseFromJSONTyped(json, false);
}

export function GetIndividualProducersSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetIndividualProducersSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'individualProducers': ((json['individualProducers'] as Array<any>).map(GetIndividualProducersSuccessResponseProducerFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function GetIndividualProducersSuccessResponseToJSON(value?: GetIndividualProducersSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'individualProducers': ((value.individualProducers as Array<any>).map(GetIndividualProducersSuccessResponseProducerToJSON)),
        'pagination': PaginationToJSON(value.pagination),
    };
}

