import {Heading, Text, View} from "@aws-amplify/ui-react";
import {useMutation} from "@tanstack/react-query";
import {
  DateComponent,
  FormikConnectedAmplifyDateField,
  FormikConnectedAmplifySubmitButton,
  FormikForm,
  FormResultErrorMessage,
  FormResultSuccessMessage,
  VerticalFieldset,
} from "@title-service/ui";
import {isUndefined} from "@title-service/utils";
import {buildDateSchema} from "@title-service/yup-utils";
import {Formik} from "formik";
import React, {useCallback, useContext, useState} from "react";
import * as Yup from "yup";

import {DependencyContext} from "../../DependencyContext";
import {
  CreateAgencyProducerAppointmentRequest,
  CreateIndividualProducerAppointmentRequest,
  ProducerJurisdictionStatus,
} from "../adminApi";
import {ProducerAppointment} from "../adminApi/fetchClient";
import {Modal} from "../components/Modal";
import {PlusIcon, RenewIcon} from "../components/icons";

import {ProducerId} from "./ProducerId";
import {
  isAgencyProducerId,
  JurisdictionGridItem,
  JurisdictionLabel,
  JurisdictionTertiaryHeaderAndIconContainer,
  mapProducerIdToProducerTypeId,
} from "./ProducerJurisdictionsSection";
import {useSubmitHandler} from "./UseSubmitHandler";

const MODAL_CONTENT_STYLE: React.CSSProperties = {
  minWidth: "650px",
};

export const ProducerAppointmentSection: React.FC<{
  mostRecentAppointment?: ProducerAppointment;
  appointmentRequired: boolean;
  jurisdictionStatus: ProducerJurisdictionStatus;
  producerId: ProducerId;
  producerJurisdictionId: string;
  onReloadProducerJurisdictions: () => any;
}> = ({
  mostRecentAppointment,
  appointmentRequired,
  jurisdictionStatus,
  producerId,
  producerJurisdictionId,
  onReloadProducerJurisdictions,
}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);
  const appointmentType = isAgencyProducerId(producerId)
    ? "Agency Producer"
    : "Individual Producer";
  const hideIcon =
    !appointmentRequired ||
    jurisdictionStatus === ProducerJurisdictionStatus.Inactive;
  return (
    <View>
      <JurisdictionTertiaryHeaderAndIconContainer>
        <Heading level={6}>Appointment</Heading>
        {hideIcon ? null : jurisdictionStatus ===
          ProducerJurisdictionStatus.Active ? (
          <RenewIcon data-testid="renew-appointment-icon" onClick={openForm} />
        ) : (
          <PlusIcon data-testid="create-appointment-icon" onClick={openForm} />
        )}
      </JurisdictionTertiaryHeaderAndIconContainer>
      {appointmentRequired ? (
        jurisdictionStatus === ProducerJurisdictionStatus.Invalid ||
        isUndefined(mostRecentAppointment) ? null : (
          <View data-testid="appointment-details">
            <JurisdictionGridItem>
              <JurisdictionLabel>Effective Date</JurisdictionLabel>
              <DateComponent value={mostRecentAppointment.effectiveDate} />
            </JurisdictionGridItem>
            <JurisdictionGridItem>
              <JurisdictionLabel>Expiration Date</JurisdictionLabel>
              <DateComponent value={mostRecentAppointment.expirationDate} />
            </JurisdictionGridItem>
          </View>
        )
      ) : (
        <Text
          color="font.primary"
          paddingLeft="xs"
          paddingTop="medium"
          paddingBottom="medium"
          fontStyle="italic"
        >
          {appointmentType} appointment is not required in this jurisdiction.
        </Text>
      )}
      <Modal
        contentStyle={MODAL_CONTENT_STYLE}
        isOpen={displayForm}
        onClose={closeForm}
        title={mostRecentAppointment ? "Renew Appointment" : "Add Appointment"}
      >
        <CreateProducerAppointmentForm
          producerId={producerId}
          jurisdictionId={producerJurisdictionId}
          onClose={closeForm}
          onSubmitComplete={onReloadProducerJurisdictions}
        />
      </Modal>
    </View>
  );
};

export const EFFECTIVE_DATE_SCHEMA: Yup.DateSchema<Date> =
  buildDateSchema().required("Effective date is required");

export const EffectiveDateField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyDateField>, "label">
> = (props) => (
  <FormikConnectedAmplifyDateField
    testId="effective-date-input"
    label="Effective Date"
    {...props}
  />
);

export const EXPIRATION_DATE_SCHEMA: Yup.DateSchema<Date> =
  buildDateSchema().required("Expiration date is required");

export const ExpirationDateField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyDateField>, "label">
> = (props) => (
  <FormikConnectedAmplifyDateField
    testId="Expiration-date-input"
    label="Expiration Date"
    {...props}
  />
);

export type CreateProducerAppointmentFormData = Omit<
  CreateAgencyProducerAppointmentRequest,
  "agencyProducerId" | "jurisdictionId" | "effectiveDate" | "expirationDate"
> & {
  effectiveDate: string;
  expirationDate: string;
};

type CompleteCreateProducerAppointmentFormData = Omit<
  CreateAgencyProducerAppointmentRequest,
  "agencyProducerId" | "jurisdictionId"
>;

export const CREATE_PRODUCER_APPOINTMENT_FORM_SCHEMA: Yup.ObjectSchema<CompleteCreateProducerAppointmentFormData> =
  Yup.object({
    effectiveDate: EFFECTIVE_DATE_SCHEMA,
    expirationDate: EXPIRATION_DATE_SCHEMA,
  });

export const buildInitialProducerAppointmentFormData =
  (): CreateProducerAppointmentFormData => ({
    effectiveDate: "",
    expirationDate: "",
  });
export const mapProducerAppointmentFormDataToCreateAgencyProducerAppointmentRequest =
  (
    agencyProducerId: string,
    jurisdictionId: string,
    formData: CreateProducerAppointmentFormData,
  ): CreateAgencyProducerAppointmentRequest => {
    const completeFormData =
      CREATE_PRODUCER_APPOINTMENT_FORM_SCHEMA.validateSync(formData);
    return {
      ...completeFormData,
      agencyProducerId,
      jurisdictionId,
    };
  };

export const mapProducerAppointmentFormDataToCreateIndividualProducerAppointmentRequest =
  (
    individualProducerId: string,
    jurisdictionId: string,
    formData: CreateProducerAppointmentFormData,
  ): CreateIndividualProducerAppointmentRequest => {
    const completeFormData =
      CREATE_PRODUCER_APPOINTMENT_FORM_SCHEMA.validateSync(formData);
    return {
      ...completeFormData,
      individualProducerId,
      jurisdictionId,
    };
  };

export const CreateProducerAppointmentForm: React.FC<{
  producerId: ProducerId;
  jurisdictionId: string;
  onClose: () => any;
  onSubmitComplete: () => any;
}> = ({producerId, jurisdictionId, onClose, onSubmitComplete}) => {
  const {adminApi} = useContext(DependencyContext);
  const createAgencyProducerAppointmentMutation = useMutation({
    mutationFn: adminApi.createAgencyProducerAppointment,
    onSuccess: () => {
      onSubmitComplete();
    },
  });
  const createIndividualProducerAppointmentMutation = useMutation({
    mutationFn: adminApi.createIndividualProducerAppointment,
    onSuccess: () => {
      onSubmitComplete();
    },
  });
  const producerTypeId = mapProducerIdToProducerTypeId(producerId);
  const submitAgencyProducerAppointmentHandler = useSubmitHandler(
    createAgencyProducerAppointmentMutation,
    onClose,
    (formData: CreateProducerAppointmentFormData) =>
      mapProducerAppointmentFormDataToCreateAgencyProducerAppointmentRequest(
        producerTypeId,
        jurisdictionId,
        formData,
      ),
  );
  const submitIndividualProducerAppointmentHandler = useSubmitHandler(
    createIndividualProducerAppointmentMutation,
    onClose,
    (formData: CreateProducerAppointmentFormData) =>
      mapProducerAppointmentFormDataToCreateIndividualProducerAppointmentRequest(
        producerTypeId,
        jurisdictionId,
        formData,
      ),
  );
  return (
    <Formik<CreateProducerAppointmentFormData>
      initialValues={buildInitialProducerAppointmentFormData()}
      validationSchema={CREATE_PRODUCER_APPOINTMENT_FORM_SCHEMA as any}
      onSubmit={
        isAgencyProducerId(producerId)
          ? submitAgencyProducerAppointmentHandler
          : submitIndividualProducerAppointmentHandler
      }
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <EffectiveDateField
            name="effectiveDate"
            minWidth="300px"
            testId="appointment-effective-date-input"
          />
          <ExpirationDateField
            name="expirationDate"
            minWidth="300px"
            testId="appointment-expiration-date-input"
          />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton />

        {createAgencyProducerAppointmentMutation.isSuccess ||
        createIndividualProducerAppointmentMutation.isSuccess ? (
          <FormResultSuccessMessage data-testid="success-result">
            The appointment was successfully created.
          </FormResultSuccessMessage>
        ) : null}

        {createAgencyProducerAppointmentMutation.isError ||
        createIndividualProducerAppointmentMutation.isError ? (
          <FormResultErrorMessage data-testid="error-result">
            Request failed.
          </FormResultErrorMessage>
        ) : null}
      </FormikForm>
    </Formik>
  );
};
