import {Text} from "@title-service/ui";
import React from "react";

import {ColumnWrapper} from "./ColumnWrapper";
import {ArrayMaybeEmpty} from "./Nullable";

export const VerticalStringList: React.FC<{
  values: string[] | null | undefined;
}> = ({values}) => (
  <ColumnWrapper>
    <ArrayMaybeEmpty value={values}>
      {(v) => (
        <ColumnWrapper>
          {v.map((value, i) => (
            <Text key={i} value={value} />
          ))}
        </ColumnWrapper>
      )}
    </ArrayMaybeEmpty>
  </ColumnWrapper>
);
