import {Button, Flex} from "@aws-amplify/ui-react";
import {
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {Query} from "@title-service/react-query-utils";
import {Heading, useFieldsetGap} from "@title-service/ui";
import React, {useCallback, useContext, useMemo, useState} from "react";
import {useParams} from "react-router-dom";

import {DependencyContext} from "../DependencyContext";
import {ClaimStatus, GetClaimSuccessResponse} from "../shared/adminApi";
import {
  ClaimAttachmentsSection,
  GetClaimAttachmentsQuery,
  useGetClaimAttachments,
} from "../shared/claims/ClaimAttachmentsSection";
import {
  ClaimNotesSection,
  GetClaimNotesQuery,
  useGetClaimNotes,
} from "../shared/claims/ClaimNotesSection";
import {ClaimOverviewSection} from "../shared/claims/ClaimOverviewSection";
import {
  ClaimRecoveriesSection,
  GetClaimRecoveriesQuery,
  useGetClaimRecoveries,
} from "../shared/claims/ClaimRecoveriesSection";
import {
  CalculatedReserveState,
  ClaimReservesSection,
  GetClaimReservesQuery,
  useGetClaimReserves,
} from "../shared/claims/ClaimReservesSection";
import {
  ClaimTransactionsSection,
  GetClaimTransactionsQuery,
  useGetClaimTransactions,
} from "../shared/claims/ClaimTransactionsSection";
import {PolicyDetailsSection} from "../shared/claims/PolicyDetailsSection";
import {Modal} from "../shared/components/Modal";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
  PrimarySection,
} from "../shared/layout";
import {COLOR_ERROR_800} from "../shared/theme";

const MODAL_CONTENT_STYLE: React.CSSProperties = {
  minWidth: "750px",
};

type ClaimDetailPageParams = {
  claimId: string;
};

export const ClaimDetailRoute: React.FC = () => {
  const {claimId} = useParams<
    keyof ClaimDetailPageParams
  >() as ClaimDetailPageParams;
  return <ClaimDetailPage claimId={claimId} />;
};

type GetClaimSuccessQueryKey = ["claims", string, "detail"];
type GetClaimQuery = Query<GetClaimSuccessResponse>;

const useGetClaim = (
  claimId: string,
): {
  getClaimQuery: GetClaimQuery;
  onReloadClaim: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);
  const getClaimQueryKey: GetClaimSuccessQueryKey = useMemo(
    () => ["claims", claimId, "detail"],
    [claimId],
  );
  const getClaimQueryFn: QueryFunction<
    GetClaimSuccessResponse,
    GetClaimSuccessQueryKey
  > = useCallback(
    ({queryKey: [_, claimId_], signal}) =>
      adminApi.getClaim({claimId: claimId_}, signal),
    [adminApi],
  );
  const getClaimQuery = useQuery({
    queryKey: getClaimQueryKey,
    queryFn: getClaimQueryFn,
  });
  const onReloadClaim = useCallback(
    () => queryClient.invalidateQueries({queryKey: getClaimQueryKey}),
    [queryClient, getClaimQueryKey],
  );
  return {getClaimQuery, onReloadClaim};
};

export const ClaimDetailPage: React.FC<
  ClaimDetailPageParams & {
    ClaimDetailQueryResult?: typeof ClaimDetailQueryResult;
  }
> = ({
  claimId,
  ClaimDetailQueryResult: ClaimDetailQueryResult_ = ClaimDetailQueryResult,
}) => {
  const {getClaimQuery, onReloadClaim} = useGetClaim(claimId);

  const {getClaimTransactionsQuery, onReloadClaimTransactions} =
    useGetClaimTransactions(claimId);

  const {getClaimReservesQuery, onReloadClaimReserves} =
    useGetClaimReserves(claimId);

  const {getClaimRecoveriesQuery, onReloadClaimRecoveries} =
    useGetClaimRecoveries(claimId);

  const {getClaimAttachmentsQuery, onReloadClaimAttachments} =
    useGetClaimAttachments(claimId);

  const {getClaimNotesQuery, onReloadClaimNotes} = useGetClaimNotes(claimId);

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <ClaimDetailQueryResult_
      claimId={claimId}
      getClaimQuery={getClaimQuery}
      getClaimTransactionsQuery={getClaimTransactionsQuery}
      getClaimReservesQuery={getClaimReservesQuery}
      getClaimRecoveriesQuery={getClaimRecoveriesQuery}
      getClaimAttachmentsQuery={getClaimAttachmentsQuery}
      getClaimNotesQuery={getClaimNotesQuery}
      onReloadClaim={onReloadClaim}
      onReloadClaimTransactions={onReloadClaimTransactions}
      onReloadClaimReserves={onReloadClaimReserves}
      onReloadClaimRecoveries={onReloadClaimRecoveries}
      onReloadClaimAttachments={onReloadClaimAttachments}
      onReloadClaimNotes={onReloadClaimNotes}
    />
  );
};

export const ClaimDetailQueryResult: React.FC<{
  claimId: string;
  getClaimQuery: GetClaimQuery;
  getClaimTransactionsQuery: GetClaimTransactionsQuery;
  getClaimReservesQuery: GetClaimReservesQuery;
  getClaimRecoveriesQuery: GetClaimRecoveriesQuery;
  getClaimAttachmentsQuery: GetClaimAttachmentsQuery;
  getClaimNotesQuery: GetClaimNotesQuery;
  onReloadClaim: () => any;
  onReloadClaimTransactions: () => any;
  onReloadClaimReserves: () => any;
  onReloadClaimRecoveries: () => any;
  onReloadClaimAttachments: () => any;
  onReloadClaimNotes: () => any;
  ClaimDetailSuccessComponent?: typeof ClaimDetailSuccessComponent;
  ClaimDetailFailureComponent?: typeof ClaimDetailFailureComponent;
  ClaimDetailLoadingComponent?: typeof ClaimDetailLoadingComponent;
}> = ({
  claimId,
  getClaimQuery: {data: getClaimQueryData, error: getClaimQueryError},
  getClaimTransactionsQuery,
  getClaimReservesQuery,
  getClaimRecoveriesQuery,
  getClaimAttachmentsQuery,
  getClaimNotesQuery,
  onReloadClaim,
  onReloadClaimTransactions,
  onReloadClaimReserves,
  onReloadClaimRecoveries,
  onReloadClaimAttachments,
  onReloadClaimNotes,
  ClaimDetailSuccessComponent:
    ClaimDetailSuccessComponent_ = ClaimDetailSuccessComponent,
  ClaimDetailFailureComponent:
    ClaimDetailFailureComponent_ = ClaimDetailFailureComponent,
  ClaimDetailLoadingComponent:
    ClaimDetailLoadingComponent_ = ClaimDetailLoadingComponent,
}) => {
  if (getClaimQueryData) {
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <ClaimDetailSuccessComponent_
        claimId={claimId}
        claimStatus={getClaimQueryData.status}
        onReloadClaim={onReloadClaim}
        onReloadClaimTransactions={onReloadClaimTransactions}
        onReloadClaimReserves={onReloadClaimReserves}
        onReloadClaimRecoveries={onReloadClaimRecoveries}
        onReloadClaimAttachments={onReloadClaimAttachments}
        onReloadClaimNotes={onReloadClaimNotes}
        getClaimResponse={getClaimQueryData}
        getClaimTransactionsQuery={getClaimTransactionsQuery}
        getClaimReservesQuery={getClaimReservesQuery}
        getClaimRecoveriesQuery={getClaimRecoveriesQuery}
        getClaimAttachmentsQuery={getClaimAttachmentsQuery}
        getClaimNotesQuery={getClaimNotesQuery}
      />
    );
  } else if (getClaimQueryError) {
    // eslint-disable-next-line react/jsx-pascal-case
    return <ClaimDetailFailureComponent_ claimId={claimId} />;
  }
  // eslint-disable-next-line react/jsx-pascal-case
  return <ClaimDetailLoadingComponent_ claimId={claimId} />;
};

export const ClaimDetailLoadingComponent: React.FC<{claimId: string}> = ({
  claimId,
}) => (
  <BodyContent>
    <span>Loading Claim {claimId}...</span>
  </BodyContent>
);

export const ClaimDetailFailureComponent: React.FC<{claimId: string}> = ({
  claimId,
}) => (
  <BodyContent>
    <span>Failed to load Claim {claimId}.</span>
  </BodyContent>
);

export const ClaimDetailSuccessComponent: React.FC<{
  claimId: string;
  claimStatus: ClaimStatus;
  onReloadClaim: () => any;
  onReloadClaimTransactions: () => any;
  onReloadClaimReserves: () => any;
  onReloadClaimRecoveries: () => any;
  onReloadClaimAttachments: () => any;
  onReloadClaimNotes: () => any;
  getClaimResponse: GetClaimSuccessResponse;
  getClaimTransactionsQuery: GetClaimTransactionsQuery;
  getClaimReservesQuery: GetClaimReservesQuery;
  getClaimRecoveriesQuery: GetClaimRecoveriesQuery;
  getClaimAttachmentsQuery: GetClaimAttachmentsQuery;
  getClaimNotesQuery: GetClaimNotesQuery;
}> = ({
  claimId,
  claimStatus,
  onReloadClaim,
  onReloadClaimTransactions,
  onReloadClaimReserves,
  onReloadClaimRecoveries,
  onReloadClaimAttachments,
  onReloadClaimNotes,
  getClaimResponse,
  getClaimResponse: {id, userAuthorizedToCloseClaim},
  getClaimTransactionsQuery,
  getClaimReservesQuery,
  getClaimRecoveriesQuery,
  getClaimAttachmentsQuery,
  getClaimNotesQuery,
}) => {
  const [displayCloseClaimModal, setDisplayCloseClaimModal] = useState(false);
  const showCloseClaimModal = useCallback(() => {
    setDisplayCloseClaimModal(true);
  }, []);
  const hideCloseClaimModal = useCallback(() => {
    setDisplayCloseClaimModal(false);
  }, []);

  const [reserveState, setReserveState] = useState({
    expense: {amount: 0},
    loss: {amount: 0},
  });
  const onReserveStateChange = useCallback(
    (updatedReserveState: CalculatedReserveState) => {
      setReserveState(updatedReserveState);
    },
    [],
  );
  const showCloseClaimButton =
    claimStatus === ClaimStatus.InProgress && userAuthorizedToCloseClaim;
  return (
    <>
      <BodyHeader>
        <PrimaryBodyHeaderContainer>
          <PrimaryBodyHeader value={`Claim ${id}`} />
          {showCloseClaimButton ? (
            <>
              <Button
                variation="primary"
                onClick={showCloseClaimModal}
                loadingText="Closing..."
              >
                Close Claim
              </Button>
              <Modal
                contentStyle={MODAL_CONTENT_STYLE}
                isOpen={displayCloseClaimModal}
                onClose={hideCloseClaimModal}
              >
                <CloseClaimConfirmation
                  claimId={claimId}
                  reserveState={reserveState}
                  onSubmitComplete={onReloadClaim}
                  onClose={hideCloseClaimModal}
                />
              </Modal>
            </>
          ) : null}
        </PrimaryBodyHeaderContainer>
      </BodyHeader>
      <BodyContent>
        <PrimarySection>
          <ClaimOverviewSection
            claimStatus={claimStatus}
            onReloadClaim={onReloadClaim}
            response={getClaimResponse}
            editModalContentStyle={MODAL_CONTENT_STYLE}
          />
          <PolicyDetailsSection
            claimStatus={claimStatus}
            onReloadClaim={onReloadClaim}
            response={getClaimResponse}
            editModalContentStyle={MODAL_CONTENT_STYLE}
          />
          <ClaimTransactionsSection
            claimId={id}
            claimStatus={claimStatus}
            onReloadClaimTransactions={onReloadClaimTransactions}
            getClaimTransactionsQuery={getClaimTransactionsQuery}
            editModalContentStyle={MODAL_CONTENT_STYLE}
          />
          <ClaimReservesSection
            claimId={id}
            claimStatus={claimStatus}
            onReserveStateChange={onReserveStateChange}
            onReloadClaimReserves={onReloadClaimReserves}
            getClaimReservesQuery={getClaimReservesQuery}
            editModalContentStyle={MODAL_CONTENT_STYLE}
          />
          <ClaimRecoveriesSection
            claimId={id}
            claimStatus={claimStatus}
            onReloadClaimRecoveries={onReloadClaimRecoveries}
            getClaimRecoveriesQuery={getClaimRecoveriesQuery}
            editModalContentStyle={MODAL_CONTENT_STYLE}
          />
          <ClaimAttachmentsSection
            claimId={id}
            claimStatus={claimStatus}
            onReloadClaimAttachments={onReloadClaimAttachments}
            getClaimAttachmentsQuery={getClaimAttachmentsQuery}
            editModalContentStyle={MODAL_CONTENT_STYLE}
          />
          <ClaimNotesSection
            claimId={id}
            claimStatus={claimStatus}
            getClaimNotesQuery={getClaimNotesQuery}
            onReloadClaimNotes={onReloadClaimNotes}
            editModalContentStyle={MODAL_CONTENT_STYLE}
          />
        </PrimarySection>
      </BodyContent>
    </>
  );
};

export const CloseClaimConfirmation: React.FC<{
  claimId: string;
  reserveState: CalculatedReserveState;
  onClose: () => any;
  onSubmitComplete: () => any;
}> = ({claimId, reserveState, onClose, onSubmitComplete}) => {
  const fieldsetGap = useFieldsetGap();
  const {adminApi} = useContext(DependencyContext);

  const closeClaimMutation = useMutation({
    mutationFn: adminApi.closeClaim,
  });

  const submitHandler = useCallback(() => {
    closeClaimMutation.mutate(
      {claimId},
      {
        onSuccess: () => {
          onSubmitComplete();
          onClose();
        },
      },
    );
  }, [claimId, closeClaimMutation, onClose, onSubmitComplete]);

  return (
    <Flex direction="column" alignItems="self-start" gap={fieldsetGap}>
      {reserveBalanceIsZero(reserveState) ? (
        <Heading level={5} value="Are you sure you want to close this claim?" />
      ) : (
        <Heading
          level={5}
          color={COLOR_ERROR_800}
          data-testid="reserve-balance-error-message"
          value="Reserves must be zero to close this claim."
        />
      )}
      <Flex direction="row" justifyContent="flex-end" width="100%">
        <Button
          variation="link"
          onClick={onClose}
          isDisabled={closeClaimMutation.isLoading}
          testId="cancel-button"
        >
          Cancel
        </Button>
        <Button
          variation="destructive"
          onClick={submitHandler}
          testId="submit-button"
          isLoading={closeClaimMutation.isLoading}
          isDisabled={!reserveBalanceIsZero(reserveState)}
          loadingText="Closing..."
        >
          Close Claim
        </Button>
      </Flex>
    </Flex>
  );
};

const reserveBalanceIsZero = (reserveState: CalculatedReserveState) =>
  reserveState.expense.amount === 0 && reserveState.loss.amount === 0;
