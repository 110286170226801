/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JurisdictionReference } from './JurisdictionReference';
import {
    JurisdictionReferenceFromJSON,
    JurisdictionReferenceFromJSONTyped,
    JurisdictionReferenceToJSON,
} from './JurisdictionReference';

/**
 * 
 * @export
 * @interface GetJurisdictionsAutocompleteSuccessResponse
 */
export interface GetJurisdictionsAutocompleteSuccessResponse {
    /**
     * 
     * @type {Array<JurisdictionReference>}
     * @memberof GetJurisdictionsAutocompleteSuccessResponse
     */
    jurisdictions: Array<JurisdictionReference>;
}

/**
 * Check if a given object implements the GetJurisdictionsAutocompleteSuccessResponse interface.
 */
export function instanceOfGetJurisdictionsAutocompleteSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "jurisdictions" in value;

    return isInstance;
}

export function GetJurisdictionsAutocompleteSuccessResponseFromJSON(json: any): GetJurisdictionsAutocompleteSuccessResponse {
    return GetJurisdictionsAutocompleteSuccessResponseFromJSONTyped(json, false);
}

export function GetJurisdictionsAutocompleteSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetJurisdictionsAutocompleteSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jurisdictions': ((json['jurisdictions'] as Array<any>).map(JurisdictionReferenceFromJSON)),
    };
}

export function GetJurisdictionsAutocompleteSuccessResponseToJSON(value?: GetJurisdictionsAutocompleteSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jurisdictions': ((value.jurisdictions as Array<any>).map(JurisdictionReferenceToJSON)),
    };
}

