/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidationReportDetailComparisonStatistics
 */
export interface ValidationReportDetailComparisonStatistics {
    /**
     * 
     * @type {number}
     * @memberof ValidationReportDetailComparisonStatistics
     */
    commonIds: number;
    /**
     * 
     * @type {number}
     * @memberof ValidationReportDetailComparisonStatistics
     */
    correlation: number;
    /**
     * 
     * @type {number}
     * @memberof ValidationReportDetailComparisonStatistics
     */
    deletedIds: number;
    /**
     * 
     * @type {number}
     * @memberof ValidationReportDetailComparisonStatistics
     */
    gainedDefect: number;
    /**
     * 
     * @type {number}
     * @memberof ValidationReportDetailComparisonStatistics
     */
    lostDefect: number;
    /**
     * 
     * @type {number}
     * @memberof ValidationReportDetailComparisonStatistics
     */
    newIds: number;
    /**
     * 
     * @type {number}
     * @memberof ValidationReportDetailComparisonStatistics
     */
    newIdsWithDefect: number;
    /**
     * 
     * @type {number}
     * @memberof ValidationReportDetailComparisonStatistics
     */
    newIdsWithoutDefect: number;
    /**
     * 
     * @type {number}
     * @memberof ValidationReportDetailComparisonStatistics
     */
    sizeDiff: number;
}

/**
 * Check if a given object implements the ValidationReportDetailComparisonStatistics interface.
 */
export function instanceOfValidationReportDetailComparisonStatistics(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "commonIds" in value;
    isInstance = isInstance && "correlation" in value;
    isInstance = isInstance && "deletedIds" in value;
    isInstance = isInstance && "gainedDefect" in value;
    isInstance = isInstance && "lostDefect" in value;
    isInstance = isInstance && "newIds" in value;
    isInstance = isInstance && "newIdsWithDefect" in value;
    isInstance = isInstance && "newIdsWithoutDefect" in value;
    isInstance = isInstance && "sizeDiff" in value;

    return isInstance;
}

export function ValidationReportDetailComparisonStatisticsFromJSON(json: any): ValidationReportDetailComparisonStatistics {
    return ValidationReportDetailComparisonStatisticsFromJSONTyped(json, false);
}

export function ValidationReportDetailComparisonStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationReportDetailComparisonStatistics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commonIds': json['commonIds'],
        'correlation': json['correlation'],
        'deletedIds': json['deletedIds'],
        'gainedDefect': json['gainedDefect'],
        'lostDefect': json['lostDefect'],
        'newIds': json['newIds'],
        'newIdsWithDefect': json['newIdsWithDefect'],
        'newIdsWithoutDefect': json['newIdsWithoutDefect'],
        'sizeDiff': json['sizeDiff'],
    };
}

export function ValidationReportDetailComparisonStatisticsToJSON(value?: ValidationReportDetailComparisonStatistics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commonIds': value.commonIds,
        'correlation': value.correlation,
        'deletedIds': value.deletedIds,
        'gainedDefect': value.gainedDefect,
        'lostDefect': value.lostDefect,
        'newIds': value.newIds,
        'newIdsWithDefect': value.newIdsWithDefect,
        'newIdsWithoutDefect': value.newIdsWithoutDefect,
        'sizeDiff': value.sizeDiff,
    };
}

