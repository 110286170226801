import {transformNullToUndefined} from "@title-service/yup-utils";
import {useField, useFormikContext} from "formik";
import React, {useCallback} from "react";
import * as Yup from "yup";

import {AgencyProducerReference} from "../adminApi";

import {AgencyProducerAutocomplete} from "./AgencyProducerAutocomplete";

export type AgencyProducerFormData = AgencyProducerReference;

export const buildAgencyProducerFormInitialData = (
  data: Partial<AgencyProducerFormData> = {},
): AgencyProducerFormData => ({
  id: "",
  name: "",
  ...data,
});

export const buildRequiredAgencyProducerFormSchema =
  (): Yup.ObjectSchema<AgencyProducerReference> =>
    Yup.object({
      id: Yup.string()
        .trim()
        .transform(transformNullToUndefined)
        .required("Please select an Agency from the list"),
      name: Yup.string()
        .trim()
        .transform(transformNullToUndefined)
        .required("Name is a required field"),
    });

export const buildOptionalAgencyProducerFormSchema = (): Yup.ObjectSchema<
  Partial<AgencyProducerReference>
> =>
  Yup.object({
    id: Yup.string().trim().transform(transformNullToUndefined),
    name: Yup.string().trim().transform(transformNullToUndefined),
  });

type AgencyProducerAutocompleteProps = React.ComponentProps<
  typeof AgencyProducerAutocomplete
>;

export const AgencyProducerField = <
  TAgencyProducerName extends string,
  TFormData extends {[k in TAgencyProducerName]: AgencyProducerFormData},
>({
  name,
  AgencyProducerAutocomplete:
    AgencyProducerAutocomplete_ = AgencyProducerAutocomplete,
  ...agencyProducerAutocompleteProps
}: Omit<
  AgencyProducerAutocompleteProps,
  "name" | "value" | "onChange" | "onBlur" | "onAgencySelected" | "limit"
> &
  Partial<Pick<AgencyProducerAutocompleteProps, "limit" | "label">> & {
    name: TAgencyProducerName;
    AgencyProducerAutocomplete?: typeof AgencyProducerAutocomplete;
  }) => {
  const {setValues} = useFormikContext<TFormData>();
  const [, agencyProducerIdFieldMeta] = useField(`${name}.id`);
  const [
    {onChange: onChangeAgencyProducerName, ...agencyProducerNameFieldInput},
    agencyProducerNameFieldMeta,
  ] = useField(`${name}.name`);
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValues((currentFormValues) => ({
        ...currentFormValues,
        [name]: {
          ...currentFormValues[name],
          id: "",
        },
      }));
      onChangeAgencyProducerName(e);
    },
    [name, onChangeAgencyProducerName, setValues],
  );
  const handleAgencyProducerSelected = useCallback(
    (agencyProducerReference?: AgencyProducerReference) => {
      setValues((currentFormValues) => ({
        ...currentFormValues,
        [name]: {
          id: agencyProducerReference?.id ?? "",
          name: agencyProducerReference?.name ?? "",
        },
      }));
    },
    [name, setValues],
  );
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <AgencyProducerAutocomplete_
      labelHidden={false}
      autoComplete="off"
      hasSearchButton={false}
      hasSearchIcon={false}
      errorMessage={agencyProducerIdFieldMeta.error}
      hasError={
        !!agencyProducerIdFieldMeta.error && agencyProducerNameFieldMeta.touched
      }
      onAgencySelected={handleAgencyProducerSelected}
      onChange={handleChange}
      limit={5}
      {...agencyProducerNameFieldInput}
      {...agencyProducerAutocompleteProps}
    />
  );
};
