import {Auth} from "aws-amplify";

import {AuthenticationStrategy} from "./AuthenticationStrategy";

export class ProductionAuthenticationStrategy
  implements AuthenticationStrategy
{
  constructor(private readonly _customProvider: string) {}

  initiateSignIn = (): void => {
    void Auth.federatedSignIn({customProvider: this._customProvider});
  };

  requireAuthentication = (): boolean => true;
}
