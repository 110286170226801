import {useMutation} from "@tanstack/react-query";
import {
  DateComponent,
  FormikConnectedAmplifyCurrencyField,
  FormikConnectedAmplifyDateField,
  FormikConnectedAmplifySubmitButton,
  FormikConnectedAmplifyTextField,
  FormikForm,
  FormResultErrorMessage,
  VerticalFieldset,
} from "@title-service/ui";
import {
  buildCurrencySchema,
  buildDateSchema,
  buildEmptyObjectForSchema,
  buildEnumSchema,
  transformNullToUndefined,
} from "@title-service/yup-utils";
import {Formik, FormikHelpers} from "formik";
import React, {useCallback, useContext, useState} from "react";
import * as Yup from "yup";

import {DependencyContext} from "../../DependencyContext";
import {
  ClaimStatus,
  GetClaimSuccessResponse,
  PolicyType,
  TransactionType,
  UpdateClaimRequest,
} from "../adminApi";
import {CurrencyComponent} from "../components/Currency";
import {
  DataGrid,
  GridItem,
  GridItemLabel,
  GridItemLabelContainer,
} from "../components/DataGrid";
import {Modal} from "../components/Modal";
import {
  MaybeNotAvailable,
  StringMaybeBlankComponent,
} from "../components/Nullable";
import {EditIcon} from "../components/icons";
import {
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../layout";
import {
  TransactionTypeComponent,
  TransactionTypeField,
} from "../orderDetails/TransactionType";
import {PolicyTypeComponent, PolicyTypeField} from "../policy/PolicyType";

import {mapGetClaimSuccessResponseToUpdateClaimRequest} from "./ClaimOverviewSection";
import {IfClaimIsInProgress} from "./IfClaimIsInProgress";

export const PolicyDetailsSection: React.FC<{
  claimStatus: ClaimStatus;
  onReloadClaim: () => any;
  response: GetClaimSuccessResponse;
  editModalContentStyle: NonNullable<
    React.ComponentProps<typeof Modal>["contentStyle"]
  >;
}> = ({
  claimStatus,
  onReloadClaim,
  response,
  response: {
    policyId,
    policyAmountOfInsurance,
    policyIssuedOn,
    policyType,
    policyTransactionType,
  },
  editModalContentStyle,
}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);
  return (
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Policy Details" />
        <IfClaimIsInProgress status={claimStatus}>
          <EditIcon
            data-testid="edit-policy-details-button"
            onClick={openForm}
          />
        </IfClaimIsInProgress>
      </SecondarySectionHeaderContainer>
      <SecondarySectionContent>
        <DataGrid>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Policy ID" />
            </GridItemLabelContainer>
            <StringMaybeBlankComponent value={policyId} />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Policy Amount of Insurance" />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={policyAmountOfInsurance}>
              {(policyAmountOfInsurance_) => (
                <CurrencyComponent value={policyAmountOfInsurance_} />
              )}
            </MaybeNotAvailable>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Policy Issued On" />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={policyIssuedOn}>
              {(policyIssuedOn_) => <DateComponent value={policyIssuedOn_} />}
            </MaybeNotAvailable>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Policy Type" />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={policyType}>
              {(policyType_) => (
                <PolicyTypeComponent policyType={policyType_} />
              )}
            </MaybeNotAvailable>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Policy Transaction Type" />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={policyTransactionType}>
              {(policyTransactionType_) => (
                <TransactionTypeComponent
                  transactionType={policyTransactionType_}
                />
              )}
            </MaybeNotAvailable>
          </GridItem>
        </DataGrid>
        <Modal
          contentStyle={editModalContentStyle}
          isOpen={displayForm}
          onClose={closeForm}
          title="Edit Policy Details"
        >
          <PolicyInfoForm
            response={response}
            onClose={closeForm}
            onSubmitComplete={onReloadClaim}
          />
        </Modal>
      </SecondarySectionContent>
    </SecondarySection>
  );
};

type CompletePolicyInfoFormData = Pick<
  UpdateClaimRequest,
  | "policyId"
  | "policyAmountOfInsurance"
  | "policyIssuedOn"
  | "policyType"
  | "policyTransactionType"
>;

export const POLICY_ID_SCHEMA: Yup.StringSchema = Yup.string()
  .optional()
  .trim()
  .transform(transformNullToUndefined);

export const PolicyIdField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = (props) => (
  <FormikConnectedAmplifyTextField label="Policy ID (optional)" {...props} />
);

export const POLICY_AMOUNT_OF_INSURANCE_SCHEMA: Yup.NumberSchema =
  buildCurrencySchema()
    .optional()
    .positive("Policy Amount of Insurance must be greater than 0");

export const PolicyAmountOfInsuranceField: React.FC<
  Omit<
    React.ComponentProps<typeof FormikConnectedAmplifyCurrencyField>,
    "label"
  >
> = (props) => (
  <FormikConnectedAmplifyCurrencyField
    label="Policy Amount of Insurance (optional)"
    {...props}
  />
);

export const POLICY_ISSUED_ON_SCHEMA: Yup.DateSchema<Date | undefined> =
  buildDateSchema().optional();

export const PolicyIssuedOnField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyDateField>, "label">
> = (props) => (
  <FormikConnectedAmplifyDateField
    testId="policy-issued-on-input"
    label="Policy Issued On (optional)"
    {...props}
  />
);

export const POLICY_TYPE_SCHEMA: Yup.StringSchema<PolicyType | undefined> =
  buildEnumSchema(PolicyType, "Must be Lender or Owner");

export const POLICY_TRANSACTION_TYPE_SCHEMA: Yup.StringSchema<
  TransactionType | undefined
> = buildEnumSchema(TransactionType, "Must be Purchase or Refinance");

export const PolicyTransactionTypeField: React.FC<
  Omit<React.ComponentProps<typeof TransactionTypeField>, "label">
> = (props) => (
  <TransactionTypeField
    label="Policy Transaction Type (optional)"
    placeholder=""
    {...props}
  />
);

export const POLICY_INFO_FORM_SCHEMA: Yup.ObjectSchema<CompletePolicyInfoFormData> =
  Yup.object({
    policyId: POLICY_ID_SCHEMA,
    policyAmountOfInsurance: POLICY_AMOUNT_OF_INSURANCE_SCHEMA,
    policyIssuedOn: POLICY_ISSUED_ON_SCHEMA,
    policyType: POLICY_TYPE_SCHEMA,
    policyTransactionType: POLICY_TRANSACTION_TYPE_SCHEMA,
  });

export type PolicyInfoFormData = Required<
  Pick<UpdateClaimRequest, "policyId">
> & {
  policyAmountOfInsurance: string;
  policyIssuedOn: string;
  policyType: string;
  policyTransactionType: string;
};

export const buildInitialPolicyInfoFormData = ({
  policyId,
  policyAmountOfInsurance,
  policyIssuedOn,
  policyType,
  policyTransactionType,
}: GetClaimSuccessResponse): PolicyInfoFormData => ({
  policyId: policyId ?? "",
  policyAmountOfInsurance: policyAmountOfInsurance?.toString() ?? "",
  policyIssuedOn: policyIssuedOn?.toISOString().substring(0, 10) ?? "",
  policyType: policyType ?? "",
  policyTransactionType: policyTransactionType ?? "",
});

export const mapPolicyInfoFormDataToUpdateClaimRequest = (
  claim: GetClaimSuccessResponse,
  formData: PolicyInfoFormData,
): UpdateClaimRequest => ({
  ...mapGetClaimSuccessResponseToUpdateClaimRequest(claim),
  ...buildEmptyObjectForSchema(POLICY_INFO_FORM_SCHEMA),
  ...POLICY_INFO_FORM_SCHEMA.validateSync(formData),
});

const PolicyInfoForm: React.FC<{
  response: GetClaimSuccessResponse;
  onClose: () => any;
  onSubmitComplete: () => any;
}> = ({response, onClose, onSubmitComplete}) => {
  const {adminApi} = useContext(DependencyContext);
  const updateClaimMutation = useMutation({
    mutationFn: adminApi.updateClaim,
  });
  const submitHandler = useCallback(
    (
      formData: PolicyInfoFormData,
      {setSubmitting}: FormikHelpers<PolicyInfoFormData>,
    ) => {
      const updateClaimRequest = mapPolicyInfoFormDataToUpdateClaimRequest(
        response,
        formData,
      );
      updateClaimMutation.mutate(updateClaimRequest, {
        onSuccess: () => {
          onSubmitComplete();
          onClose();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [onClose, onSubmitComplete, response, updateClaimMutation],
  );
  return (
    <Formik<PolicyInfoFormData>
      initialValues={buildInitialPolicyInfoFormData(response)}
      validationSchema={POLICY_INFO_FORM_SCHEMA as any}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <PolicyIdField name="polciyId" />

          <PolicyAmountOfInsuranceField name="policyAmountOfInsurance" />

          <PolicyIssuedOnField name="policyIssuedOn" />

          <PolicyTypeField name="policyType" />

          <PolicyTransactionTypeField name="policyTransactionType" />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton />

        {updateClaimMutation.isError ? (
          <FormResultErrorMessage data-testid="error-state">
            Request failed.
          </FormResultErrorMessage>
        ) : null}
      </FormikForm>
    </Formik>
  );
};
