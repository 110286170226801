import {RemittanceItemCpl, RemittanceItemCplToJSON} from "./RemittanceItemCpl";
import {
  RemittanceItemEndorsement,
  RemittanceItemEndorsementToJSON,
} from "./RemittanceItemEndorsement";
import {RemittanceItemFee, RemittanceItemFeeToJSON} from "./RemittanceItemFee";
import {
  RemittanceItemPolicy,
  RemittanceItemPolicyToJSON,
} from "./RemittanceItemPolicy";
import {RemittanceType} from "./RemittanceType";

export type RemittanceItem =
  | RemittanceItemCpl
  | RemittanceItemPolicy
  | RemittanceItemEndorsement
  | RemittanceItemFee;

export const RemittanceItemToJSON = (remittanceItem: RemittanceItem): any => {
  switch (remittanceItem.type) {
    case RemittanceType.Cpl:
      return RemittanceItemCplToJSON(remittanceItem);
    case RemittanceType.Policy:
      return RemittanceItemPolicyToJSON(remittanceItem);
    case RemittanceType.Endorsement:
      return RemittanceItemEndorsementToJSON(remittanceItem);
    case RemittanceType.Fee:
      return RemittanceItemFeeToJSON(remittanceItem);
  }
};
