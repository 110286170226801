import * as Yup from "yup";

import {
  AddressAutocompleteApi,
  AddressSuggestion,
  GetAddressSuggestionsRequest,
} from "./AddressAutocompleteApi";

export const AUTOCOMPLETE_API_BASE_URL =
  "https://us-autocomplete-pro.api.smartystreets.com/lookup";

export type SmartyStreetsAutocompleteResponse = {
  suggestions?: SmartyStreetsAutocompleteResponseSuggestion[];
};

export type SmartyStreetsAutocompleteResponseSuggestion = {
  street_line: string;
  secondary: string;
  city: string;
  state: string;
  zipcode: string;
  entries: number;
};

export const RESPONSE_SCHEMA: Yup.ObjectSchema<SmartyStreetsAutocompleteResponse> =
  Yup.object({
    suggestions: Yup.array().of(
      Yup.object({
        // eslint-disable-next-line camelcase
        street_line: Yup.string().required(),
        secondary: Yup.string().required(),
        city: Yup.string().required(),
        state: Yup.string().required(),
        zipcode: Yup.string().required(),
        entries: Yup.number().required(),
      }),
    ),
  });

export class FetchSmartyStreetsAutocompleteApi
  implements AddressAutocompleteApi
{
  constructor(private readonly websiteKey: string) {}

  getAddressSuggestions = async (
    {searchTerm, includeOnlyStates}: GetAddressSuggestionsRequest,
    signal?: AbortSignal,
  ): Promise<AddressSuggestion[]> => {
    const requestUrl = new URL(AUTOCOMPLETE_API_BASE_URL);
    requestUrl.searchParams.append("key", this.websiteKey);
    requestUrl.searchParams.append("search", searchTerm);
    if (includeOnlyStates) {
      requestUrl.searchParams.append(
        "include_only_states",
        includeOnlyStates.join(";"),
      );
    }
    const response = await fetch(requestUrl.toString(), {
      method: "GET",
      mode: "cors",
      signal,
    });
    const responseBody = await response.json();
    const autocompleteResponse = RESPONSE_SCHEMA.cast(responseBody);
    return (autocompleteResponse.suggestions ?? []).map(
      (responseSuggestion) => {
        const addressSuggestion: AddressSuggestion = {
          street: responseSuggestion.street_line,
          city: responseSuggestion.city,
          state: responseSuggestion.state,
          zipCode: responseSuggestion.zipcode,
        };
        return addressSuggestion;
      },
    );
  };
}
