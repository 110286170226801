import {Icon} from "@title-service/ui";
import React from "react";

const VIEW_BOX: React.ComponentProps<typeof Icon>["viewBox"] = {
  width: 21,
  height: 21,
};

/*
 * Icon taken from: https://www.svgrepo.com/svg/343261/refresh
 * This work is licensed in the public domain (CCO 1.0) with no copyright and no requirement
 * to attribute the original author. For more information on this license,
 * see [here](https://creativecommons.org/publicdomain/zero/1.0/deed.en)
 */

export const RenewIcon: React.FC<React.ComponentProps<typeof Icon>> = (
  props,
) => (
  <Icon viewBox={VIEW_BOX} {...props}>
    <g fill="none" stroke="currentColor" transform="translate(2 2)">
      <path d="m4.5 1.5c-2.41169541 1.37786776-4 4.02354835-4 7 0 4.418278 3.581722 8 8 8m4-1c2.2866288-1.4081018 4-4.1175492 4-7 0-4.418278-3.581722-8-8-8" />{" "}
      <path d="m4.5 5.5v-4h-4" />
      <path d="m12.5 11.5v4h4" />
    </g>
  </Icon>
);
