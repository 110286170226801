/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  ApprovePropertyDocumentLegalDescriptionRequest,
  ApprovePropertyDocumentVestingInformationRequest,
  Cpl,
  CreateAgencyProducerRequest,
  CreateAgencyProducerSuccessResponse,
  CreateAuditAttachmentRequest,
  CreateAuditRequest,
  CreateAuditSuccessResponse,
  CreateClaimAttachmentRequest,
  CreateClaimNoteRequest,
  CreateClaimRecoveryRequest,
  CreateClaimRequest,
  CreateClaimReserveChangeRequest,
  CreateClaimSuccessResponse,
  CreateClaimTransactionRequest,
  CreateIndividualProducerRequest,
  CreateIndividualProducerSuccessResponse,
  CreateOrderCplRequest,
  CreateOrderCplSuccessResponse,
  CreateOrderUnderwritingAttachmentRequest,
  CreateOrderUnderwritingNoteRequest,
  CreateProducerAppointmentRequest,
  CreateProducerLicenseRequest,
  CreateProducerNoteRequest,
  CreateRemittanceRequest,
  CreateValidationReportAcceptedResponse,
  CreateValidationReportRequest,
  DeactivateProducerJurisdictionRequest,
  EditPropertyDocumentLegalDescriptionRequest,
  EditPropertyDocumentVestingInformationRequest,
  ExportAuditFinalReportsAcceptedResponse,
  ExportAuditFinalReportsRequest,
  FlagOrderRequest,
  FlagPropertyDocumentRequest,
  GetAgencyProducerSuccessResponse,
  GetAgencyProducersAutocompleteFilters,
  GetAgencyProducersAutocompleteSuccessResponse,
  GetAgencyProducersSortField,
  GetAgencyProducersSuccessResponse,
  GetAuditAttachmentsSuccessResponse,
  GetAuditExportItemsSortField,
  GetAuditExportItemsSuccessResponse,
  GetAuditExportSuccessResponse,
  GetAuditExportsSortField,
  GetAuditExportsSuccessResponse,
  GetAuditSuccessResponse,
  GetAuditsFilters,
  GetAuditsSortField,
  GetAuditsSuccessResponse,
  GetClaimAttachmentsSuccessResponse,
  GetClaimNotesSuccessResponse,
  GetClaimRecoveriesSuccessResponse,
  GetClaimReservesSuccessResponse,
  GetClaimSuccessResponse,
  GetClaimTransactionsSuccessResponse,
  GetClaimsSuccessResponse,
  GetCplsSortField,
  GetCplsSuccessResponse,
  GetIndividualProducerSuccessResponse,
  GetIndividualProducersSortField,
  GetIndividualProducersSuccessResponse,
  GetJurisdictionsAutocompleteSuccessResponse,
  GetOrderRemittanceItemsSuccessResponse,
  GetOrderSuccessResponse,
  GetOrderUnderwritingAttachmentsSuccessResponse,
  GetOrderUnderwritingNotesSuccessResponse,
  GetOrdersSortField,
  GetOrdersSuccessResponse,
  GetParentAgenciesSuccessResponse,
  GetPoliciesSortField,
  GetPoliciesSuccessResponse,
  GetPredictionSuccessResponse,
  GetProducerJurisdictionsSuccessResponse,
  GetProducerNotesSuccessResponse,
  GetProducersExpiringSuccessResponse,
  GetPropertyDocumentIdsSuccessResponse,
  GetPropertyDocumentSuccessResponse,
  GetPropertyDocumentsSortField,
  GetPropertyDocumentsSuccessResponse,
  GetPropertySuccessResponse,
  GetRemittanceItemsSuccessResponse,
  GetTrainedModelsSuccessResponse,
  GetValidationReportsSuccessResponse,
  InsurfulOrderResult,
  Policy,
  PropertyDocumentExtractionStatus,
  ReactivateProducerJurisdictionRequest,
  RejectPropertyDocumentLegalDescriptionRequest,
  RejectPropertyDocumentVestingInformationRequest,
  RenewProducerLicenseRequest,
  SearchPropertiesResponse,
  SortDirection,
  TrainModelAcceptedResponse,
  TrainModelRequest,
  UnApprovePropertyDocumentLegalDescriptionRequest,
  UnApprovePropertyDocumentVestingInformationRequest,
  UnRejectPropertyDocumentLegalDescriptionRequest,
  UnRejectPropertyDocumentVestingInformationRequest,
  UpdateAgencyProducerRequest,
  UpdateAuditAttachmentRequest,
  UpdateAuditRequest,
  UpdateClaimNoteRequest,
  UpdateClaimRequest,
  UpdateIndividualProducerRequest,
  UpdateOrderUnderwritingNoteRequest,
  UpdateProducerNoteRequest,
  UpdateRemittanceRequest,
  ValidationReportDetail,
} from '../models';
import {
    ApprovePropertyDocumentLegalDescriptionRequestFromJSON,
    ApprovePropertyDocumentLegalDescriptionRequestToJSON,
    ApprovePropertyDocumentVestingInformationRequestFromJSON,
    ApprovePropertyDocumentVestingInformationRequestToJSON,
    CplFromJSON,
    CplToJSON,
    CreateAgencyProducerRequestFromJSON,
    CreateAgencyProducerRequestToJSON,
    CreateAgencyProducerSuccessResponseFromJSON,
    CreateAgencyProducerSuccessResponseToJSON,
    CreateAuditAttachmentRequestFromJSON,
    CreateAuditAttachmentRequestToJSON,
    CreateAuditRequestFromJSON,
    CreateAuditRequestToJSON,
    CreateAuditSuccessResponseFromJSON,
    CreateAuditSuccessResponseToJSON,
    CreateClaimAttachmentRequestFromJSON,
    CreateClaimAttachmentRequestToJSON,
    CreateClaimNoteRequestFromJSON,
    CreateClaimNoteRequestToJSON,
    CreateClaimRecoveryRequestFromJSON,
    CreateClaimRecoveryRequestToJSON,
    CreateClaimRequestFromJSON,
    CreateClaimRequestToJSON,
    CreateClaimReserveChangeRequestFromJSON,
    CreateClaimReserveChangeRequestToJSON,
    CreateClaimSuccessResponseFromJSON,
    CreateClaimSuccessResponseToJSON,
    CreateClaimTransactionRequestFromJSON,
    CreateClaimTransactionRequestToJSON,
    CreateIndividualProducerRequestFromJSON,
    CreateIndividualProducerRequestToJSON,
    CreateIndividualProducerSuccessResponseFromJSON,
    CreateIndividualProducerSuccessResponseToJSON,
    CreateOrderCplRequestFromJSON,
    CreateOrderCplRequestToJSON,
    CreateOrderCplSuccessResponseFromJSON,
    CreateOrderCplSuccessResponseToJSON,
    CreateOrderUnderwritingAttachmentRequestFromJSON,
    CreateOrderUnderwritingAttachmentRequestToJSON,
    CreateOrderUnderwritingNoteRequestFromJSON,
    CreateOrderUnderwritingNoteRequestToJSON,
    CreateProducerAppointmentRequestFromJSON,
    CreateProducerAppointmentRequestToJSON,
    CreateProducerLicenseRequestFromJSON,
    CreateProducerLicenseRequestToJSON,
    CreateProducerNoteRequestFromJSON,
    CreateProducerNoteRequestToJSON,
    CreateRemittanceRequestFromJSON,
    CreateRemittanceRequestToJSON,
    CreateValidationReportAcceptedResponseFromJSON,
    CreateValidationReportAcceptedResponseToJSON,
    CreateValidationReportRequestFromJSON,
    CreateValidationReportRequestToJSON,
    DeactivateProducerJurisdictionRequestFromJSON,
    DeactivateProducerJurisdictionRequestToJSON,
    EditPropertyDocumentLegalDescriptionRequestFromJSON,
    EditPropertyDocumentLegalDescriptionRequestToJSON,
    EditPropertyDocumentVestingInformationRequestFromJSON,
    EditPropertyDocumentVestingInformationRequestToJSON,
    ExportAuditFinalReportsAcceptedResponseFromJSON,
    ExportAuditFinalReportsAcceptedResponseToJSON,
    ExportAuditFinalReportsRequestFromJSON,
    ExportAuditFinalReportsRequestToJSON,
    FlagOrderRequestFromJSON,
    FlagOrderRequestToJSON,
    FlagPropertyDocumentRequestFromJSON,
    FlagPropertyDocumentRequestToJSON,
    GetAgencyProducerSuccessResponseFromJSON,
    GetAgencyProducerSuccessResponseToJSON,
    GetAgencyProducersAutocompleteFiltersFromJSON,
    GetAgencyProducersAutocompleteFiltersToJSON,
    GetAgencyProducersAutocompleteSuccessResponseFromJSON,
    GetAgencyProducersAutocompleteSuccessResponseToJSON,
    GetAgencyProducersSortFieldFromJSON,
    GetAgencyProducersSortFieldToJSON,
    GetAgencyProducersSuccessResponseFromJSON,
    GetAgencyProducersSuccessResponseToJSON,
    GetAuditAttachmentsSuccessResponseFromJSON,
    GetAuditAttachmentsSuccessResponseToJSON,
    GetAuditExportItemsSortFieldFromJSON,
    GetAuditExportItemsSortFieldToJSON,
    GetAuditExportItemsSuccessResponseFromJSON,
    GetAuditExportItemsSuccessResponseToJSON,
    GetAuditExportSuccessResponseFromJSON,
    GetAuditExportSuccessResponseToJSON,
    GetAuditExportsSortFieldFromJSON,
    GetAuditExportsSortFieldToJSON,
    GetAuditExportsSuccessResponseFromJSON,
    GetAuditExportsSuccessResponseToJSON,
    GetAuditSuccessResponseFromJSON,
    GetAuditSuccessResponseToJSON,
    GetAuditsFiltersFromJSON,
    GetAuditsFiltersToJSON,
    GetAuditsSortFieldFromJSON,
    GetAuditsSortFieldToJSON,
    GetAuditsSuccessResponseFromJSON,
    GetAuditsSuccessResponseToJSON,
    GetClaimAttachmentsSuccessResponseFromJSON,
    GetClaimAttachmentsSuccessResponseToJSON,
    GetClaimNotesSuccessResponseFromJSON,
    GetClaimNotesSuccessResponseToJSON,
    GetClaimRecoveriesSuccessResponseFromJSON,
    GetClaimRecoveriesSuccessResponseToJSON,
    GetClaimReservesSuccessResponseFromJSON,
    GetClaimReservesSuccessResponseToJSON,
    GetClaimSuccessResponseFromJSON,
    GetClaimSuccessResponseToJSON,
    GetClaimTransactionsSuccessResponseFromJSON,
    GetClaimTransactionsSuccessResponseToJSON,
    GetClaimsSuccessResponseFromJSON,
    GetClaimsSuccessResponseToJSON,
    GetCplsSortFieldFromJSON,
    GetCplsSortFieldToJSON,
    GetCplsSuccessResponseFromJSON,
    GetCplsSuccessResponseToJSON,
    GetIndividualProducerSuccessResponseFromJSON,
    GetIndividualProducerSuccessResponseToJSON,
    GetIndividualProducersSortFieldFromJSON,
    GetIndividualProducersSortFieldToJSON,
    GetIndividualProducersSuccessResponseFromJSON,
    GetIndividualProducersSuccessResponseToJSON,
    GetJurisdictionsAutocompleteSuccessResponseFromJSON,
    GetJurisdictionsAutocompleteSuccessResponseToJSON,
    GetOrderRemittanceItemsSuccessResponseFromJSON,
    GetOrderRemittanceItemsSuccessResponseToJSON,
    GetOrderSuccessResponseFromJSON,
    GetOrderSuccessResponseToJSON,
    GetOrderUnderwritingAttachmentsSuccessResponseFromJSON,
    GetOrderUnderwritingAttachmentsSuccessResponseToJSON,
    GetOrderUnderwritingNotesSuccessResponseFromJSON,
    GetOrderUnderwritingNotesSuccessResponseToJSON,
    GetOrdersSortFieldFromJSON,
    GetOrdersSortFieldToJSON,
    GetOrdersSuccessResponseFromJSON,
    GetOrdersSuccessResponseToJSON,
    GetParentAgenciesSuccessResponseFromJSON,
    GetParentAgenciesSuccessResponseToJSON,
    GetPoliciesSortFieldFromJSON,
    GetPoliciesSortFieldToJSON,
    GetPoliciesSuccessResponseFromJSON,
    GetPoliciesSuccessResponseToJSON,
    GetPredictionSuccessResponseFromJSON,
    GetPredictionSuccessResponseToJSON,
    GetProducerJurisdictionsSuccessResponseFromJSON,
    GetProducerJurisdictionsSuccessResponseToJSON,
    GetProducerNotesSuccessResponseFromJSON,
    GetProducerNotesSuccessResponseToJSON,
    GetProducersExpiringSuccessResponseFromJSON,
    GetProducersExpiringSuccessResponseToJSON,
    GetPropertyDocumentIdsSuccessResponseFromJSON,
    GetPropertyDocumentIdsSuccessResponseToJSON,
    GetPropertyDocumentSuccessResponseFromJSON,
    GetPropertyDocumentSuccessResponseToJSON,
    GetPropertyDocumentsSortFieldFromJSON,
    GetPropertyDocumentsSortFieldToJSON,
    GetPropertyDocumentsSuccessResponseFromJSON,
    GetPropertyDocumentsSuccessResponseToJSON,
    GetPropertySuccessResponseFromJSON,
    GetPropertySuccessResponseToJSON,
    GetRemittanceItemsSuccessResponseFromJSON,
    GetRemittanceItemsSuccessResponseToJSON,
    GetTrainedModelsSuccessResponseFromJSON,
    GetTrainedModelsSuccessResponseToJSON,
    GetValidationReportsSuccessResponseFromJSON,
    GetValidationReportsSuccessResponseToJSON,
    InsurfulOrderResultFromJSON,
    InsurfulOrderResultToJSON,
    PolicyFromJSON,
    PolicyToJSON,
    PropertyDocumentExtractionStatusFromJSON,
    PropertyDocumentExtractionStatusToJSON,
    ReactivateProducerJurisdictionRequestFromJSON,
    ReactivateProducerJurisdictionRequestToJSON,
    RejectPropertyDocumentLegalDescriptionRequestFromJSON,
    RejectPropertyDocumentLegalDescriptionRequestToJSON,
    RejectPropertyDocumentVestingInformationRequestFromJSON,
    RejectPropertyDocumentVestingInformationRequestToJSON,
    RenewProducerLicenseRequestFromJSON,
    RenewProducerLicenseRequestToJSON,
    SearchPropertiesResponseFromJSON,
    SearchPropertiesResponseToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    TrainModelAcceptedResponseFromJSON,
    TrainModelAcceptedResponseToJSON,
    TrainModelRequestFromJSON,
    TrainModelRequestToJSON,
    UnApprovePropertyDocumentLegalDescriptionRequestFromJSON,
    UnApprovePropertyDocumentLegalDescriptionRequestToJSON,
    UnApprovePropertyDocumentVestingInformationRequestFromJSON,
    UnApprovePropertyDocumentVestingInformationRequestToJSON,
    UnRejectPropertyDocumentLegalDescriptionRequestFromJSON,
    UnRejectPropertyDocumentLegalDescriptionRequestToJSON,
    UnRejectPropertyDocumentVestingInformationRequestFromJSON,
    UnRejectPropertyDocumentVestingInformationRequestToJSON,
    UpdateAgencyProducerRequestFromJSON,
    UpdateAgencyProducerRequestToJSON,
    UpdateAuditAttachmentRequestFromJSON,
    UpdateAuditAttachmentRequestToJSON,
    UpdateAuditRequestFromJSON,
    UpdateAuditRequestToJSON,
    UpdateClaimNoteRequestFromJSON,
    UpdateClaimNoteRequestToJSON,
    UpdateClaimRequestFromJSON,
    UpdateClaimRequestToJSON,
    UpdateIndividualProducerRequestFromJSON,
    UpdateIndividualProducerRequestToJSON,
    UpdateOrderUnderwritingNoteRequestFromJSON,
    UpdateOrderUnderwritingNoteRequestToJSON,
    UpdateProducerNoteRequestFromJSON,
    UpdateProducerNoteRequestToJSON,
    UpdateRemittanceRequestFromJSON,
    UpdateRemittanceRequestToJSON,
    ValidationReportDetailFromJSON,
    ValidationReportDetailToJSON,
} from '../models';

export interface ApprovePropertyDocumentLegalDescriptionOperationRequest {
    propertyDocumentId: string;
    approvePropertyDocumentLegalDescriptionRequest: ApprovePropertyDocumentLegalDescriptionRequest;
}

export interface ApprovePropertyDocumentVestingInformationOperationRequest {
    propertyDocumentId: string;
    approvePropertyDocumentVestingInformationRequest: ApprovePropertyDocumentVestingInformationRequest;
}

export interface CloseClaimRequest {
    claimId: string;
}

export interface CreateAgencyProducerOperationRequest {
    createAgencyProducerRequest: CreateAgencyProducerRequest;
}

export interface CreateAgencyProducerAppointmentRequest {
    agencyProducerId: string;
    jurisdictionId: string;
    createProducerAppointmentRequest: CreateProducerAppointmentRequest;
}

export interface CreateAgencyProducerLicenseRequest {
    agencyProducerId: string;
    createProducerLicenseRequest: CreateProducerLicenseRequest;
}

export interface CreateAgencyProducerNoteRequest {
    agencyProducerId: string;
    createProducerNoteRequest: CreateProducerNoteRequest;
}

export interface CreateAuditOperationRequest {
    createAuditRequest: CreateAuditRequest;
}

export interface CreateAuditAttachmentOperationRequest {
    auditId: string;
    createAuditAttachmentRequest: CreateAuditAttachmentRequest;
}

export interface CreateClaimOperationRequest {
    createClaimRequest: CreateClaimRequest;
}

export interface CreateClaimAttachmentOperationRequest {
    claimId: string;
    createClaimAttachmentRequest: CreateClaimAttachmentRequest;
}

export interface CreateClaimNoteOperationRequest {
    claimId: string;
    createClaimNoteRequest: CreateClaimNoteRequest;
}

export interface CreateClaimRecoveryOperationRequest {
    claimId: string;
    createClaimRecoveryRequest: CreateClaimRecoveryRequest;
}

export interface CreateClaimReserveRequest {
    claimId: string;
    createClaimReserveChangeRequest: CreateClaimReserveChangeRequest;
    ifMatch?: string;
}

export interface CreateClaimTransactionOperationRequest {
    claimId: string;
    createClaimTransactionRequest: CreateClaimTransactionRequest;
}

export interface CreateIndividualProducerOperationRequest {
    createIndividualProducerRequest: CreateIndividualProducerRequest;
}

export interface CreateIndividualProducerAppointmentRequest {
    individualProducerId: string;
    jurisdictionId: string;
    createProducerAppointmentRequest: CreateProducerAppointmentRequest;
}

export interface CreateIndividualProducerLicenseRequest {
    individualProducerId: string;
    createProducerLicenseRequest: CreateProducerLicenseRequest;
}

export interface CreateIndividualProducerNoteRequest {
    individualProducerId: string;
    createProducerNoteRequest: CreateProducerNoteRequest;
}

export interface CreateOrderCplOperationRequest {
    orderId: string;
    createOrderCplRequest: CreateOrderCplRequest;
}

export interface CreateOrderUnderwritingAttachmentOperationRequest {
    orderId: string;
    createOrderUnderwritingAttachmentRequest: CreateOrderUnderwritingAttachmentRequest;
}

export interface CreateOrderUnderwritingNoteOperationRequest {
    orderId: string;
    createOrderUnderwritingNoteRequest: CreateOrderUnderwritingNoteRequest;
}

export interface CreateRemittanceOperationRequest {
    createRemittanceRequest: CreateRemittanceRequest;
}

export interface CreateValidationReportOperationRequest {
    createValidationReportRequest: CreateValidationReportRequest;
}

export interface DeactivateAgencyProducerRequest {
    agencyProducerId: string;
    deactivateProducerJurisdictionRequest: DeactivateProducerJurisdictionRequest;
}

export interface DeactivateIndividualProducerRequest {
    individualProducerId: string;
    deactivateProducerJurisdictionRequest: DeactivateProducerJurisdictionRequest;
}

export interface DeactivateProducerJurisdictionOperationRequest {
    producerJurisdictionId: string;
    deactivateProducerJurisdictionRequest: DeactivateProducerJurisdictionRequest;
}

export interface EditPropertyDocumentLegalDescriptionOperationRequest {
    propertyDocumentId: string;
    editPropertyDocumentLegalDescriptionRequest: EditPropertyDocumentLegalDescriptionRequest;
}

export interface EditPropertyDocumentVestingInformationOperationRequest {
    propertyDocumentId: string;
    editPropertyDocumentVestingInformationRequest: EditPropertyDocumentVestingInformationRequest;
}

export interface ExportAuditFinalReportsOperationRequest {
    exportAuditFinalReportsRequest: ExportAuditFinalReportsRequest;
}

export interface FlagOrderOperationRequest {
    orderId: string;
    flagOrderRequest: FlagOrderRequest;
}

export interface FlagPropertyDocumentOperationRequest {
    propertyDocumentId: string;
    flagPropertyDocumentRequest: FlagPropertyDocumentRequest;
}

export interface GetAgencyProducerRequest {
    agencyProducerId: string;
}

export interface GetAgencyProducerJurisdictionsRequest {
    agencyProducerId: string;
}

export interface GetAgencyProducerNotesRequest {
    producerId: string;
}

export interface GetAgencyProducersRequest {
    sortField: GetAgencyProducersSortField;
    sortDirection: SortDirection;
    page: number;
    pageSize: number;
}

export interface GetAgencyProducersAutocompleteRequest {
    limit: number;
    filters?: GetAgencyProducersAutocompleteFilters;
}

export interface GetAuditRequest {
    auditId: string;
}

export interface GetAuditAttachmentsRequest {
    auditId: string;
}

export interface GetAuditExportRequest {
    auditExportId: string;
}

export interface GetAuditExportItemsRequest {
    auditExportId: string;
    sortField: GetAuditExportItemsSortField;
    sortDirection: SortDirection;
    page: number;
    pageSize: number;
}

export interface GetAuditExportsRequest {
    sortField: GetAuditExportsSortField;
    sortDirection: SortDirection;
    page: number;
    pageSize: number;
}

export interface GetAuditsRequest {
    sortField: GetAuditsSortField;
    sortDirection: SortDirection;
    page: number;
    pageSize: number;
    filters?: GetAuditsFilters;
}

export interface GetClaimRequest {
    claimId: string;
}

export interface GetClaimAttachmentsRequest {
    claimId: string;
}

export interface GetClaimNotesRequest {
    claimId: string;
}

export interface GetClaimRecoveriesRequest {
    claimId: string;
}

export interface GetClaimReservesRequest {
    claimId: string;
}

export interface GetClaimTransactionsRequest {
    claimId: string;
}

export interface GetCplRequest {
    cplId: string;
}

export interface GetCplsRequest {
    sortField: GetCplsSortField;
    sortDirection: SortDirection;
    page: number;
    pageSize: number;
}

export interface GetIndividualProducerRequest {
    individualProducerId: string;
}

export interface GetIndividualProducerJurisdictionsRequest {
    individualProducerId: string;
}

export interface GetIndividualProducerNotesRequest {
    producerId: string;
}

export interface GetIndividualProducersRequest {
    sortField: GetIndividualProducersSortField;
    sortDirection: SortDirection;
    page: number;
    pageSize: number;
}

export interface GetInsurfulOrderResultRequest {
    insurfulOrderId: string;
}

export interface GetJurisdictionsAutocompleteRequest {
    jurisdiction: string;
    limit: number;
}

export interface GetOrderRequest {
    orderId: string;
}

export interface GetOrderRemittanceItemsRequest {
    orderId: string;
}

export interface GetOrderUnderwritingAttachmentsRequest {
    orderId: string;
}

export interface GetOrderUnderwritingNotesRequest {
    orderId: string;
}

export interface GetOrdersRequest {
    sortField: GetOrdersSortField;
    sortDirection: SortDirection;
    page: number;
    pageSize: number;
    agencyId?: string;
    ids?: string;
    flagged?: boolean;
}

export interface GetPoliciesRequest {
    sortField: GetPoliciesSortField;
    sortDirection: SortDirection;
    page: number;
    pageSize: number;
}

export interface GetPolicyRequest {
    policyId: string;
}

export interface GetPredictionRequest {
    attomId: string;
    state: string;
}

export interface GetPropertyRequest {
    attomId: string;
}

export interface GetPropertyDocumentRequest {
    propertyDocumentId: string;
}

export interface GetPropertyDocumentIdsRequest {
    sortField: GetPropertyDocumentsSortField;
    sortDirection: SortDirection;
    addressState?: string;
    flagged?: boolean;
    legalDescriptionStatus?: PropertyDocumentExtractionStatus;
    vestingInformationStatus?: PropertyDocumentExtractionStatus;
}

export interface GetPropertyDocumentsRequest {
    sortField: GetPropertyDocumentsSortField;
    sortDirection: SortDirection;
    page: number;
    pageSize: number;
    addressState?: string;
    flagged?: boolean;
    legalDescriptionStatus?: PropertyDocumentExtractionStatus;
    vestingInformationStatus?: PropertyDocumentExtractionStatus;
}

export interface GetRemittanceItemsRequest {
    remittanceId: string;
}

export interface GetValidationReportRequest {
    reportId: string;
}

export interface ReactivateProducerJurisdictionOperationRequest {
    producerJurisdictionId: string;
    reactivateProducerJurisdictionRequest: ReactivateProducerJurisdictionRequest;
}

export interface RejectPropertyDocumentLegalDescriptionOperationRequest {
    propertyDocumentId: string;
    rejectPropertyDocumentLegalDescriptionRequest: RejectPropertyDocumentLegalDescriptionRequest;
}

export interface RejectPropertyDocumentVestingInformationOperationRequest {
    propertyDocumentId: string;
    rejectPropertyDocumentVestingInformationRequest: RejectPropertyDocumentVestingInformationRequest;
}

export interface RenewAgencyProducerLicenseRequest {
    agencyProducerId: string;
    licenseId: string;
    renewProducerLicenseRequest: RenewProducerLicenseRequest;
}

export interface RenewIndividualProducerLicenseRequest {
    individualProducerId: string;
    licenseId: string;
    renewProducerLicenseRequest: RenewProducerLicenseRequest;
}

export interface SearchPropertiesRequest {
    street: string;
    unitValue?: string;
    city?: string;
    state?: string;
}

export interface TrainModelOperationRequest {
    trainModelRequest: TrainModelRequest;
}

export interface UnApprovePropertyDocumentLegalDescriptionOperationRequest {
    propertyDocumentId: string;
    unApprovePropertyDocumentLegalDescriptionRequest: UnApprovePropertyDocumentLegalDescriptionRequest;
}

export interface UnApprovePropertyDocumentVestingInformationOperationRequest {
    propertyDocumentId: string;
    unApprovePropertyDocumentVestingInformationRequest: UnApprovePropertyDocumentVestingInformationRequest;
}

export interface UnRejectPropertyDocumentLegalDescriptionOperationRequest {
    propertyDocumentId: string;
    unRejectPropertyDocumentLegalDescriptionRequest: UnRejectPropertyDocumentLegalDescriptionRequest;
}

export interface UnRejectPropertyDocumentVestingInformationOperationRequest {
    propertyDocumentId: string;
    unRejectPropertyDocumentVestingInformationRequest: UnRejectPropertyDocumentVestingInformationRequest;
}

export interface UnflagOrderRequest {
    orderId: string;
}

export interface UnflagPropertyDocumentRequest {
    propertyDocumentId: string;
}

export interface UpdateAgencyProducerOperationRequest {
    agencyProducerId: string;
    updateAgencyProducerRequest: UpdateAgencyProducerRequest;
}

export interface UpdateAuditOperationRequest {
    auditId: string;
    updateAuditRequest: UpdateAuditRequest;
}

export interface UpdateAuditAttachmentOperationRequest {
    auditId: string;
    auditAttachmentId: string;
    updateAuditAttachmentRequest: UpdateAuditAttachmentRequest;
}

export interface UpdateClaimOperationRequest {
    claimId: string;
    updateClaimRequest: UpdateClaimRequest;
}

export interface UpdateClaimNoteOperationRequest {
    claimId: string;
    claimNoteId: string;
    updateClaimNoteRequest: UpdateClaimNoteRequest;
}

export interface UpdateIndividualProducerOperationRequest {
    individualProducerId: string;
    updateIndividualProducerRequest: UpdateIndividualProducerRequest;
}

export interface UpdateOrderUnderwritingNoteOperationRequest {
    orderId: string;
    orderUnderwritingNoteId: string;
    updateOrderUnderwritingNoteRequest: UpdateOrderUnderwritingNoteRequest;
}

export interface UpdateProducerNoteOperationRequest {
    producerId: string;
    producerNoteId: string;
    updateProducerNoteRequest: UpdateProducerNoteRequest;
}

export interface UpdateRemittanceOperationRequest {
    remittanceId: string;
    updateRemittanceRequest: UpdateRemittanceRequest;
}

export interface VoidClaimTransactionRequest {
    claimId: string;
    claimTransactionId: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Approve the Legal Description of a Property Document
     */
    async approvePropertyDocumentLegalDescriptionRaw(requestParameters: ApprovePropertyDocumentLegalDescriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.propertyDocumentId === null || requestParameters.propertyDocumentId === undefined) {
            throw new runtime.RequiredError('propertyDocumentId','Required parameter requestParameters.propertyDocumentId was null or undefined when calling approvePropertyDocumentLegalDescription.');
        }

        if (requestParameters.approvePropertyDocumentLegalDescriptionRequest === null || requestParameters.approvePropertyDocumentLegalDescriptionRequest === undefined) {
            throw new runtime.RequiredError('approvePropertyDocumentLegalDescriptionRequest','Required parameter requestParameters.approvePropertyDocumentLegalDescriptionRequest was null or undefined when calling approvePropertyDocumentLegalDescription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/propertyDocuments/{propertyDocumentId}/legalDescription/approve`.replace(`{${"propertyDocumentId"}}`, encodeURIComponent(String(requestParameters.propertyDocumentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApprovePropertyDocumentLegalDescriptionRequestToJSON(requestParameters.approvePropertyDocumentLegalDescriptionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Approve the Legal Description of a Property Document
     */
    async approvePropertyDocumentLegalDescription(requestParameters: ApprovePropertyDocumentLegalDescriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.approvePropertyDocumentLegalDescriptionRaw(requestParameters, initOverrides);
    }

    /**
     * Approve the Vesting Information of a Property Document
     */
    async approvePropertyDocumentVestingInformationRaw(requestParameters: ApprovePropertyDocumentVestingInformationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.propertyDocumentId === null || requestParameters.propertyDocumentId === undefined) {
            throw new runtime.RequiredError('propertyDocumentId','Required parameter requestParameters.propertyDocumentId was null or undefined when calling approvePropertyDocumentVestingInformation.');
        }

        if (requestParameters.approvePropertyDocumentVestingInformationRequest === null || requestParameters.approvePropertyDocumentVestingInformationRequest === undefined) {
            throw new runtime.RequiredError('approvePropertyDocumentVestingInformationRequest','Required parameter requestParameters.approvePropertyDocumentVestingInformationRequest was null or undefined when calling approvePropertyDocumentVestingInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/propertyDocuments/{propertyDocumentId}/vestingInformation/approve`.replace(`{${"propertyDocumentId"}}`, encodeURIComponent(String(requestParameters.propertyDocumentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApprovePropertyDocumentVestingInformationRequestToJSON(requestParameters.approvePropertyDocumentVestingInformationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Approve the Vesting Information of a Property Document
     */
    async approvePropertyDocumentVestingInformation(requestParameters: ApprovePropertyDocumentVestingInformationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.approvePropertyDocumentVestingInformationRaw(requestParameters, initOverrides);
    }

    /**
     * Close a Claim
     */
    async closeClaimRaw(requestParameters: CloseClaimRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling closeClaim.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/claims/{claimId}/close`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Close a Claim
     */
    async closeClaim(requestParameters: CloseClaimRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.closeClaimRaw(requestParameters, initOverrides);
    }

    /**
     * Create an Agency Producer
     */
    async createAgencyProducerRaw(requestParameters: CreateAgencyProducerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAgencyProducerSuccessResponse>> {
        if (requestParameters.createAgencyProducerRequest === null || requestParameters.createAgencyProducerRequest === undefined) {
            throw new runtime.RequiredError('createAgencyProducerRequest','Required parameter requestParameters.createAgencyProducerRequest was null or undefined when calling createAgencyProducer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/producers/agencies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAgencyProducerRequestToJSON(requestParameters.createAgencyProducerRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAgencyProducerSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Create an Agency Producer
     */
    async createAgencyProducer(requestParameters: CreateAgencyProducerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAgencyProducerSuccessResponse> {
        const response = await this.createAgencyProducerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an Agency Producer Appointment
     */
    async createAgencyProducerAppointmentRaw(requestParameters: CreateAgencyProducerAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agencyProducerId === null || requestParameters.agencyProducerId === undefined) {
            throw new runtime.RequiredError('agencyProducerId','Required parameter requestParameters.agencyProducerId was null or undefined when calling createAgencyProducerAppointment.');
        }

        if (requestParameters.jurisdictionId === null || requestParameters.jurisdictionId === undefined) {
            throw new runtime.RequiredError('jurisdictionId','Required parameter requestParameters.jurisdictionId was null or undefined when calling createAgencyProducerAppointment.');
        }

        if (requestParameters.createProducerAppointmentRequest === null || requestParameters.createProducerAppointmentRequest === undefined) {
            throw new runtime.RequiredError('createProducerAppointmentRequest','Required parameter requestParameters.createProducerAppointmentRequest was null or undefined when calling createAgencyProducerAppointment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/producers/agencies/{agencyProducerId}/jurisdictions/{jurisdictionId}/appointments`.replace(`{${"agencyProducerId"}}`, encodeURIComponent(String(requestParameters.agencyProducerId))).replace(`{${"jurisdictionId"}}`, encodeURIComponent(String(requestParameters.jurisdictionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProducerAppointmentRequestToJSON(requestParameters.createProducerAppointmentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create an Agency Producer Appointment
     */
    async createAgencyProducerAppointment(requestParameters: CreateAgencyProducerAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createAgencyProducerAppointmentRaw(requestParameters, initOverrides);
    }

    /**
     * Create an Agency Producer License
     */
    async createAgencyProducerLicenseRaw(requestParameters: CreateAgencyProducerLicenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agencyProducerId === null || requestParameters.agencyProducerId === undefined) {
            throw new runtime.RequiredError('agencyProducerId','Required parameter requestParameters.agencyProducerId was null or undefined when calling createAgencyProducerLicense.');
        }

        if (requestParameters.createProducerLicenseRequest === null || requestParameters.createProducerLicenseRequest === undefined) {
            throw new runtime.RequiredError('createProducerLicenseRequest','Required parameter requestParameters.createProducerLicenseRequest was null or undefined when calling createAgencyProducerLicense.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/producers/agencies/{agencyProducerId}/licenses`.replace(`{${"agencyProducerId"}}`, encodeURIComponent(String(requestParameters.agencyProducerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProducerLicenseRequestToJSON(requestParameters.createProducerLicenseRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create an Agency Producer License
     */
    async createAgencyProducerLicense(requestParameters: CreateAgencyProducerLicenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createAgencyProducerLicenseRaw(requestParameters, initOverrides);
    }

    /**
     * Create an Agency Producer Note
     */
    async createAgencyProducerNoteRaw(requestParameters: CreateAgencyProducerNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agencyProducerId === null || requestParameters.agencyProducerId === undefined) {
            throw new runtime.RequiredError('agencyProducerId','Required parameter requestParameters.agencyProducerId was null or undefined when calling createAgencyProducerNote.');
        }

        if (requestParameters.createProducerNoteRequest === null || requestParameters.createProducerNoteRequest === undefined) {
            throw new runtime.RequiredError('createProducerNoteRequest','Required parameter requestParameters.createProducerNoteRequest was null or undefined when calling createAgencyProducerNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/producers/agencies/{agencyProducerId}/notes`.replace(`{${"agencyProducerId"}}`, encodeURIComponent(String(requestParameters.agencyProducerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProducerNoteRequestToJSON(requestParameters.createProducerNoteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create an Agency Producer Note
     */
    async createAgencyProducerNote(requestParameters: CreateAgencyProducerNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createAgencyProducerNoteRaw(requestParameters, initOverrides);
    }

    /**
     * Create an Audit
     */
    async createAuditRaw(requestParameters: CreateAuditOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAuditSuccessResponse>> {
        if (requestParameters.createAuditRequest === null || requestParameters.createAuditRequest === undefined) {
            throw new runtime.RequiredError('createAuditRequest','Required parameter requestParameters.createAuditRequest was null or undefined when calling createAudit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/audits`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAuditRequestToJSON(requestParameters.createAuditRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAuditSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Create an Audit
     */
    async createAudit(requestParameters: CreateAuditOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAuditSuccessResponse> {
        const response = await this.createAuditRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an Audit Attachment
     */
    async createAuditAttachmentRaw(requestParameters: CreateAuditAttachmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.auditId === null || requestParameters.auditId === undefined) {
            throw new runtime.RequiredError('auditId','Required parameter requestParameters.auditId was null or undefined when calling createAuditAttachment.');
        }

        if (requestParameters.createAuditAttachmentRequest === null || requestParameters.createAuditAttachmentRequest === undefined) {
            throw new runtime.RequiredError('createAuditAttachmentRequest','Required parameter requestParameters.createAuditAttachmentRequest was null or undefined when calling createAuditAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/audits/{auditId}/attachments`.replace(`{${"auditId"}}`, encodeURIComponent(String(requestParameters.auditId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAuditAttachmentRequestToJSON(requestParameters.createAuditAttachmentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create an Audit Attachment
     */
    async createAuditAttachment(requestParameters: CreateAuditAttachmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createAuditAttachmentRaw(requestParameters, initOverrides);
    }

    /**
     * Create a Claim
     */
    async createClaimRaw(requestParameters: CreateClaimOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateClaimSuccessResponse>> {
        if (requestParameters.createClaimRequest === null || requestParameters.createClaimRequest === undefined) {
            throw new runtime.RequiredError('createClaimRequest','Required parameter requestParameters.createClaimRequest was null or undefined when calling createClaim.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/claims`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateClaimRequestToJSON(requestParameters.createClaimRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateClaimSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Create a Claim
     */
    async createClaim(requestParameters: CreateClaimOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateClaimSuccessResponse> {
        const response = await this.createClaimRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a Claim Attachment
     */
    async createClaimAttachmentRaw(requestParameters: CreateClaimAttachmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling createClaimAttachment.');
        }

        if (requestParameters.createClaimAttachmentRequest === null || requestParameters.createClaimAttachmentRequest === undefined) {
            throw new runtime.RequiredError('createClaimAttachmentRequest','Required parameter requestParameters.createClaimAttachmentRequest was null or undefined when calling createClaimAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/claims/{claimId}/attachments`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateClaimAttachmentRequestToJSON(requestParameters.createClaimAttachmentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a Claim Attachment
     */
    async createClaimAttachment(requestParameters: CreateClaimAttachmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createClaimAttachmentRaw(requestParameters, initOverrides);
    }

    /**
     * Create a Claim Note
     */
    async createClaimNoteRaw(requestParameters: CreateClaimNoteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling createClaimNote.');
        }

        if (requestParameters.createClaimNoteRequest === null || requestParameters.createClaimNoteRequest === undefined) {
            throw new runtime.RequiredError('createClaimNoteRequest','Required parameter requestParameters.createClaimNoteRequest was null or undefined when calling createClaimNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/claims/{claimId}/notes`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateClaimNoteRequestToJSON(requestParameters.createClaimNoteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a Claim Note
     */
    async createClaimNote(requestParameters: CreateClaimNoteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createClaimNoteRaw(requestParameters, initOverrides);
    }

    /**
     * Create a Claim Recovery
     */
    async createClaimRecoveryRaw(requestParameters: CreateClaimRecoveryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling createClaimRecovery.');
        }

        if (requestParameters.createClaimRecoveryRequest === null || requestParameters.createClaimRecoveryRequest === undefined) {
            throw new runtime.RequiredError('createClaimRecoveryRequest','Required parameter requestParameters.createClaimRecoveryRequest was null or undefined when calling createClaimRecovery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/claims/{claimId}/recoveries`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateClaimRecoveryRequestToJSON(requestParameters.createClaimRecoveryRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a Claim Recovery
     */
    async createClaimRecovery(requestParameters: CreateClaimRecoveryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createClaimRecoveryRaw(requestParameters, initOverrides);
    }

    /**
     * Create a Claim Reserve
     */
    async createClaimReserveRaw(requestParameters: CreateClaimReserveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling createClaimReserve.');
        }

        if (requestParameters.createClaimReserveChangeRequest === null || requestParameters.createClaimReserveChangeRequest === undefined) {
            throw new runtime.RequiredError('createClaimReserveChangeRequest','Required parameter requestParameters.createClaimReserveChangeRequest was null or undefined when calling createClaimReserve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.ifMatch !== undefined && requestParameters.ifMatch !== null) {
            headerParameters['If-Match'] = String(requestParameters.ifMatch);
        }

        const response = await this.request({
            path: `/api/v1/claims/{claimId}/reserves`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateClaimReserveChangeRequestToJSON(requestParameters.createClaimReserveChangeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a Claim Reserve
     */
    async createClaimReserve(requestParameters: CreateClaimReserveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createClaimReserveRaw(requestParameters, initOverrides);
    }

    /**
     * Create a Claim Transaction
     */
    async createClaimTransactionRaw(requestParameters: CreateClaimTransactionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling createClaimTransaction.');
        }

        if (requestParameters.createClaimTransactionRequest === null || requestParameters.createClaimTransactionRequest === undefined) {
            throw new runtime.RequiredError('createClaimTransactionRequest','Required parameter requestParameters.createClaimTransactionRequest was null or undefined when calling createClaimTransaction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/claims/{claimId}/transactions`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateClaimTransactionRequestToJSON(requestParameters.createClaimTransactionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a Claim Transaction
     */
    async createClaimTransaction(requestParameters: CreateClaimTransactionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createClaimTransactionRaw(requestParameters, initOverrides);
    }

    /**
     * Create an Individual Producer
     */
    async createIndividualProducerRaw(requestParameters: CreateIndividualProducerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateIndividualProducerSuccessResponse>> {
        if (requestParameters.createIndividualProducerRequest === null || requestParameters.createIndividualProducerRequest === undefined) {
            throw new runtime.RequiredError('createIndividualProducerRequest','Required parameter requestParameters.createIndividualProducerRequest was null or undefined when calling createIndividualProducer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/producers/individuals`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateIndividualProducerRequestToJSON(requestParameters.createIndividualProducerRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateIndividualProducerSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Create an Individual Producer
     */
    async createIndividualProducer(requestParameters: CreateIndividualProducerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateIndividualProducerSuccessResponse> {
        const response = await this.createIndividualProducerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an Individual Producer Appointment
     */
    async createIndividualProducerAppointmentRaw(requestParameters: CreateIndividualProducerAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.individualProducerId === null || requestParameters.individualProducerId === undefined) {
            throw new runtime.RequiredError('individualProducerId','Required parameter requestParameters.individualProducerId was null or undefined when calling createIndividualProducerAppointment.');
        }

        if (requestParameters.jurisdictionId === null || requestParameters.jurisdictionId === undefined) {
            throw new runtime.RequiredError('jurisdictionId','Required parameter requestParameters.jurisdictionId was null or undefined when calling createIndividualProducerAppointment.');
        }

        if (requestParameters.createProducerAppointmentRequest === null || requestParameters.createProducerAppointmentRequest === undefined) {
            throw new runtime.RequiredError('createProducerAppointmentRequest','Required parameter requestParameters.createProducerAppointmentRequest was null or undefined when calling createIndividualProducerAppointment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/producers/individuals/{individualProducerId}/jurisdictions/{jurisdictionId}/appointments`.replace(`{${"individualProducerId"}}`, encodeURIComponent(String(requestParameters.individualProducerId))).replace(`{${"jurisdictionId"}}`, encodeURIComponent(String(requestParameters.jurisdictionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProducerAppointmentRequestToJSON(requestParameters.createProducerAppointmentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create an Individual Producer Appointment
     */
    async createIndividualProducerAppointment(requestParameters: CreateIndividualProducerAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createIndividualProducerAppointmentRaw(requestParameters, initOverrides);
    }

    /**
     * Create an Individual Producer License
     */
    async createIndividualProducerLicenseRaw(requestParameters: CreateIndividualProducerLicenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.individualProducerId === null || requestParameters.individualProducerId === undefined) {
            throw new runtime.RequiredError('individualProducerId','Required parameter requestParameters.individualProducerId was null or undefined when calling createIndividualProducerLicense.');
        }

        if (requestParameters.createProducerLicenseRequest === null || requestParameters.createProducerLicenseRequest === undefined) {
            throw new runtime.RequiredError('createProducerLicenseRequest','Required parameter requestParameters.createProducerLicenseRequest was null or undefined when calling createIndividualProducerLicense.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/producers/individuals/{individualProducerId}/licenses`.replace(`{${"individualProducerId"}}`, encodeURIComponent(String(requestParameters.individualProducerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProducerLicenseRequestToJSON(requestParameters.createProducerLicenseRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create an Individual Producer License
     */
    async createIndividualProducerLicense(requestParameters: CreateIndividualProducerLicenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createIndividualProducerLicenseRaw(requestParameters, initOverrides);
    }

    /**
     * Create an Individual Producer Note
     */
    async createIndividualProducerNoteRaw(requestParameters: CreateIndividualProducerNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.individualProducerId === null || requestParameters.individualProducerId === undefined) {
            throw new runtime.RequiredError('individualProducerId','Required parameter requestParameters.individualProducerId was null or undefined when calling createIndividualProducerNote.');
        }

        if (requestParameters.createProducerNoteRequest === null || requestParameters.createProducerNoteRequest === undefined) {
            throw new runtime.RequiredError('createProducerNoteRequest','Required parameter requestParameters.createProducerNoteRequest was null or undefined when calling createIndividualProducerNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/producers/individuals/{individualProducerId}/notes`.replace(`{${"individualProducerId"}}`, encodeURIComponent(String(requestParameters.individualProducerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProducerNoteRequestToJSON(requestParameters.createProducerNoteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create an Individual Producer Note
     */
    async createIndividualProducerNote(requestParameters: CreateIndividualProducerNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createIndividualProducerNoteRaw(requestParameters, initOverrides);
    }

    /**
     * Create CPL
     */
    async createOrderCplRaw(requestParameters: CreateOrderCplOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateOrderCplSuccessResponse>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling createOrderCpl.');
        }

        if (requestParameters.createOrderCplRequest === null || requestParameters.createOrderCplRequest === undefined) {
            throw new runtime.RequiredError('createOrderCplRequest','Required parameter requestParameters.createOrderCplRequest was null or undefined when calling createOrderCpl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/orders/{orderId}/cpls`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrderCplRequestToJSON(requestParameters.createOrderCplRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOrderCplSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Create CPL
     */
    async createOrderCpl(requestParameters: CreateOrderCplOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateOrderCplSuccessResponse> {
        const response = await this.createOrderCplRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an Order Underwriting Attachment
     */
    async createOrderUnderwritingAttachmentRaw(requestParameters: CreateOrderUnderwritingAttachmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling createOrderUnderwritingAttachment.');
        }

        if (requestParameters.createOrderUnderwritingAttachmentRequest === null || requestParameters.createOrderUnderwritingAttachmentRequest === undefined) {
            throw new runtime.RequiredError('createOrderUnderwritingAttachmentRequest','Required parameter requestParameters.createOrderUnderwritingAttachmentRequest was null or undefined when calling createOrderUnderwritingAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/orders/{orderId}/underwritingAttachments`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrderUnderwritingAttachmentRequestToJSON(requestParameters.createOrderUnderwritingAttachmentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create an Order Underwriting Attachment
     */
    async createOrderUnderwritingAttachment(requestParameters: CreateOrderUnderwritingAttachmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createOrderUnderwritingAttachmentRaw(requestParameters, initOverrides);
    }

    /**
     * Create an Order Underwriting Note
     */
    async createOrderUnderwritingNoteRaw(requestParameters: CreateOrderUnderwritingNoteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling createOrderUnderwritingNote.');
        }

        if (requestParameters.createOrderUnderwritingNoteRequest === null || requestParameters.createOrderUnderwritingNoteRequest === undefined) {
            throw new runtime.RequiredError('createOrderUnderwritingNoteRequest','Required parameter requestParameters.createOrderUnderwritingNoteRequest was null or undefined when calling createOrderUnderwritingNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/orders/{orderId}/underwritingNotes`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrderUnderwritingNoteRequestToJSON(requestParameters.createOrderUnderwritingNoteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create an Order Underwriting Note
     */
    async createOrderUnderwritingNote(requestParameters: CreateOrderUnderwritingNoteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createOrderUnderwritingNoteRaw(requestParameters, initOverrides);
    }

    /**
     * Create Remittance
     */
    async createRemittanceRaw(requestParameters: CreateRemittanceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createRemittanceRequest === null || requestParameters.createRemittanceRequest === undefined) {
            throw new runtime.RequiredError('createRemittanceRequest','Required parameter requestParameters.createRemittanceRequest was null or undefined when calling createRemittance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/remittances`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRemittanceRequestToJSON(requestParameters.createRemittanceRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create Remittance
     */
    async createRemittance(requestParameters: CreateRemittanceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createRemittanceRaw(requestParameters, initOverrides);
    }

    /**
     * Create Validation Reports via the Scoring Service
     */
    async createValidationReportRaw(requestParameters: CreateValidationReportOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateValidationReportAcceptedResponse>> {
        if (requestParameters.createValidationReportRequest === null || requestParameters.createValidationReportRequest === undefined) {
            throw new runtime.RequiredError('createValidationReportRequest','Required parameter requestParameters.createValidationReportRequest was null or undefined when calling createValidationReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/validationReports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateValidationReportRequestToJSON(requestParameters.createValidationReportRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateValidationReportAcceptedResponseFromJSON(jsonValue));
    }

    /**
     * Create Validation Reports via the Scoring Service
     */
    async createValidationReport(requestParameters: CreateValidationReportOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateValidationReportAcceptedResponse> {
        const response = await this.createValidationReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deactivates an Agency Producer
     */
    async deactivateAgencyProducerRaw(requestParameters: DeactivateAgencyProducerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agencyProducerId === null || requestParameters.agencyProducerId === undefined) {
            throw new runtime.RequiredError('agencyProducerId','Required parameter requestParameters.agencyProducerId was null or undefined when calling deactivateAgencyProducer.');
        }

        if (requestParameters.deactivateProducerJurisdictionRequest === null || requestParameters.deactivateProducerJurisdictionRequest === undefined) {
            throw new runtime.RequiredError('deactivateProducerJurisdictionRequest','Required parameter requestParameters.deactivateProducerJurisdictionRequest was null or undefined when calling deactivateAgencyProducer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/producers/agencies/{agencyProducerId}/deactivate`.replace(`{${"agencyProducerId"}}`, encodeURIComponent(String(requestParameters.agencyProducerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeactivateProducerJurisdictionRequestToJSON(requestParameters.deactivateProducerJurisdictionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deactivates an Agency Producer
     */
    async deactivateAgencyProducer(requestParameters: DeactivateAgencyProducerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deactivateAgencyProducerRaw(requestParameters, initOverrides);
    }

    /**
     * Deactivates an Individual Producer
     */
    async deactivateIndividualProducerRaw(requestParameters: DeactivateIndividualProducerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.individualProducerId === null || requestParameters.individualProducerId === undefined) {
            throw new runtime.RequiredError('individualProducerId','Required parameter requestParameters.individualProducerId was null or undefined when calling deactivateIndividualProducer.');
        }

        if (requestParameters.deactivateProducerJurisdictionRequest === null || requestParameters.deactivateProducerJurisdictionRequest === undefined) {
            throw new runtime.RequiredError('deactivateProducerJurisdictionRequest','Required parameter requestParameters.deactivateProducerJurisdictionRequest was null or undefined when calling deactivateIndividualProducer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/producers/individuals/{individualProducerId}/deactivate`.replace(`{${"individualProducerId"}}`, encodeURIComponent(String(requestParameters.individualProducerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeactivateProducerJurisdictionRequestToJSON(requestParameters.deactivateProducerJurisdictionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deactivates an Individual Producer
     */
    async deactivateIndividualProducer(requestParameters: DeactivateIndividualProducerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deactivateIndividualProducerRaw(requestParameters, initOverrides);
    }

    /**
     * Deactivate a Producer Jurisdiction
     */
    async deactivateProducerJurisdictionRaw(requestParameters: DeactivateProducerJurisdictionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.producerJurisdictionId === null || requestParameters.producerJurisdictionId === undefined) {
            throw new runtime.RequiredError('producerJurisdictionId','Required parameter requestParameters.producerJurisdictionId was null or undefined when calling deactivateProducerJurisdiction.');
        }

        if (requestParameters.deactivateProducerJurisdictionRequest === null || requestParameters.deactivateProducerJurisdictionRequest === undefined) {
            throw new runtime.RequiredError('deactivateProducerJurisdictionRequest','Required parameter requestParameters.deactivateProducerJurisdictionRequest was null or undefined when calling deactivateProducerJurisdiction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/producers/jurisdictions/{producerJurisdictionId}/deactivate`.replace(`{${"producerJurisdictionId"}}`, encodeURIComponent(String(requestParameters.producerJurisdictionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeactivateProducerJurisdictionRequestToJSON(requestParameters.deactivateProducerJurisdictionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deactivate a Producer Jurisdiction
     */
    async deactivateProducerJurisdiction(requestParameters: DeactivateProducerJurisdictionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deactivateProducerJurisdictionRaw(requestParameters, initOverrides);
    }

    /**
     * Edit the Legal Description of a Property Document
     */
    async editPropertyDocumentLegalDescriptionRaw(requestParameters: EditPropertyDocumentLegalDescriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.propertyDocumentId === null || requestParameters.propertyDocumentId === undefined) {
            throw new runtime.RequiredError('propertyDocumentId','Required parameter requestParameters.propertyDocumentId was null or undefined when calling editPropertyDocumentLegalDescription.');
        }

        if (requestParameters.editPropertyDocumentLegalDescriptionRequest === null || requestParameters.editPropertyDocumentLegalDescriptionRequest === undefined) {
            throw new runtime.RequiredError('editPropertyDocumentLegalDescriptionRequest','Required parameter requestParameters.editPropertyDocumentLegalDescriptionRequest was null or undefined when calling editPropertyDocumentLegalDescription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/propertyDocuments/{propertyDocumentId}/legalDescription`.replace(`{${"propertyDocumentId"}}`, encodeURIComponent(String(requestParameters.propertyDocumentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditPropertyDocumentLegalDescriptionRequestToJSON(requestParameters.editPropertyDocumentLegalDescriptionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Edit the Legal Description of a Property Document
     */
    async editPropertyDocumentLegalDescription(requestParameters: EditPropertyDocumentLegalDescriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.editPropertyDocumentLegalDescriptionRaw(requestParameters, initOverrides);
    }

    /**
     * Edit the Vesting Information of a Property Document
     */
    async editPropertyDocumentVestingInformationRaw(requestParameters: EditPropertyDocumentVestingInformationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.propertyDocumentId === null || requestParameters.propertyDocumentId === undefined) {
            throw new runtime.RequiredError('propertyDocumentId','Required parameter requestParameters.propertyDocumentId was null or undefined when calling editPropertyDocumentVestingInformation.');
        }

        if (requestParameters.editPropertyDocumentVestingInformationRequest === null || requestParameters.editPropertyDocumentVestingInformationRequest === undefined) {
            throw new runtime.RequiredError('editPropertyDocumentVestingInformationRequest','Required parameter requestParameters.editPropertyDocumentVestingInformationRequest was null or undefined when calling editPropertyDocumentVestingInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/propertyDocuments/{propertyDocumentId}/vestingInformation`.replace(`{${"propertyDocumentId"}}`, encodeURIComponent(String(requestParameters.propertyDocumentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditPropertyDocumentVestingInformationRequestToJSON(requestParameters.editPropertyDocumentVestingInformationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Edit the Vesting Information of a Property Document
     */
    async editPropertyDocumentVestingInformation(requestParameters: EditPropertyDocumentVestingInformationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.editPropertyDocumentVestingInformationRaw(requestParameters, initOverrides);
    }

    /**
     * Export Audit Final Reports
     */
    async exportAuditFinalReportsRaw(requestParameters: ExportAuditFinalReportsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExportAuditFinalReportsAcceptedResponse>> {
        if (requestParameters.exportAuditFinalReportsRequest === null || requestParameters.exportAuditFinalReportsRequest === undefined) {
            throw new runtime.RequiredError('exportAuditFinalReportsRequest','Required parameter requestParameters.exportAuditFinalReportsRequest was null or undefined when calling exportAuditFinalReports.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/audits/exports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportAuditFinalReportsRequestToJSON(requestParameters.exportAuditFinalReportsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportAuditFinalReportsAcceptedResponseFromJSON(jsonValue));
    }

    /**
     * Export Audit Final Reports
     */
    async exportAuditFinalReports(requestParameters: ExportAuditFinalReportsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExportAuditFinalReportsAcceptedResponse> {
        const response = await this.exportAuditFinalReportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Flags the Order
     */
    async flagOrderRaw(requestParameters: FlagOrderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling flagOrder.');
        }

        if (requestParameters.flagOrderRequest === null || requestParameters.flagOrderRequest === undefined) {
            throw new runtime.RequiredError('flagOrderRequest','Required parameter requestParameters.flagOrderRequest was null or undefined when calling flagOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/orders/{orderId}/flag`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FlagOrderRequestToJSON(requestParameters.flagOrderRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Flags the Order
     */
    async flagOrder(requestParameters: FlagOrderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.flagOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Flag the Property Document
     */
    async flagPropertyDocumentRaw(requestParameters: FlagPropertyDocumentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.propertyDocumentId === null || requestParameters.propertyDocumentId === undefined) {
            throw new runtime.RequiredError('propertyDocumentId','Required parameter requestParameters.propertyDocumentId was null or undefined when calling flagPropertyDocument.');
        }

        if (requestParameters.flagPropertyDocumentRequest === null || requestParameters.flagPropertyDocumentRequest === undefined) {
            throw new runtime.RequiredError('flagPropertyDocumentRequest','Required parameter requestParameters.flagPropertyDocumentRequest was null or undefined when calling flagPropertyDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/propertyDocuments/{propertyDocumentId}/flag`.replace(`{${"propertyDocumentId"}}`, encodeURIComponent(String(requestParameters.propertyDocumentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FlagPropertyDocumentRequestToJSON(requestParameters.flagPropertyDocumentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Flag the Property Document
     */
    async flagPropertyDocument(requestParameters: FlagPropertyDocumentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.flagPropertyDocumentRaw(requestParameters, initOverrides);
    }

    /**
     * Get an agency producer
     */
    async getAgencyProducerRaw(requestParameters: GetAgencyProducerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAgencyProducerSuccessResponse>> {
        if (requestParameters.agencyProducerId === null || requestParameters.agencyProducerId === undefined) {
            throw new runtime.RequiredError('agencyProducerId','Required parameter requestParameters.agencyProducerId was null or undefined when calling getAgencyProducer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/producers/agencies/{agencyProducerId}`.replace(`{${"agencyProducerId"}}`, encodeURIComponent(String(requestParameters.agencyProducerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAgencyProducerSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get an agency producer
     */
    async getAgencyProducer(requestParameters: GetAgencyProducerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAgencyProducerSuccessResponse> {
        const response = await this.getAgencyProducerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all Jurisdictions for an Agency Producer
     */
    async getAgencyProducerJurisdictionsRaw(requestParameters: GetAgencyProducerJurisdictionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProducerJurisdictionsSuccessResponse>> {
        if (requestParameters.agencyProducerId === null || requestParameters.agencyProducerId === undefined) {
            throw new runtime.RequiredError('agencyProducerId','Required parameter requestParameters.agencyProducerId was null or undefined when calling getAgencyProducerJurisdictions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/producers/agencies/{agencyProducerId}/jurisdictions`.replace(`{${"agencyProducerId"}}`, encodeURIComponent(String(requestParameters.agencyProducerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProducerJurisdictionsSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get all Jurisdictions for an Agency Producer
     */
    async getAgencyProducerJurisdictions(requestParameters: GetAgencyProducerJurisdictionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProducerJurisdictionsSuccessResponse> {
        const response = await this.getAgencyProducerJurisdictionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all notes for an Agency Producer
     */
    async getAgencyProducerNotesRaw(requestParameters: GetAgencyProducerNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProducerNotesSuccessResponse>> {
        if (requestParameters.producerId === null || requestParameters.producerId === undefined) {
            throw new runtime.RequiredError('producerId','Required parameter requestParameters.producerId was null or undefined when calling getAgencyProducerNotes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/producers/agencies/{producerId}/notes`.replace(`{${"producerId"}}`, encodeURIComponent(String(requestParameters.producerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProducerNotesSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get all notes for an Agency Producer
     */
    async getAgencyProducerNotes(requestParameters: GetAgencyProducerNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProducerNotesSuccessResponse> {
        const response = await this.getAgencyProducerNotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all agency producers
     */
    async getAgencyProducersRaw(requestParameters: GetAgencyProducersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAgencyProducersSuccessResponse>> {
        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getAgencyProducers.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getAgencyProducers.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getAgencyProducers.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getAgencyProducers.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/producers/agencies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAgencyProducersSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get all agency producers
     */
    async getAgencyProducers(requestParameters: GetAgencyProducersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAgencyProducersSuccessResponse> {
        const response = await this.getAgencyProducersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Autocomplete search agency producers
     */
    async getAgencyProducersAutocompleteRaw(requestParameters: GetAgencyProducersAutocompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAgencyProducersAutocompleteSuccessResponse>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getAgencyProducersAutocomplete.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/producers/agencies/autocomplete`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAgencyProducersAutocompleteSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Autocomplete search agency producers
     */
    async getAgencyProducersAutocomplete(requestParameters: GetAgencyProducersAutocompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAgencyProducersAutocompleteSuccessResponse> {
        const response = await this.getAgencyProducersAutocompleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an audit
     */
    async getAuditRaw(requestParameters: GetAuditRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAuditSuccessResponse>> {
        if (requestParameters.auditId === null || requestParameters.auditId === undefined) {
            throw new runtime.RequiredError('auditId','Required parameter requestParameters.auditId was null or undefined when calling getAudit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/audits/{auditId}`.replace(`{${"auditId"}}`, encodeURIComponent(String(requestParameters.auditId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAuditSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get an audit
     */
    async getAudit(requestParameters: GetAuditRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAuditSuccessResponse> {
        const response = await this.getAuditRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all attachments for an audit
     */
    async getAuditAttachmentsRaw(requestParameters: GetAuditAttachmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAuditAttachmentsSuccessResponse>> {
        if (requestParameters.auditId === null || requestParameters.auditId === undefined) {
            throw new runtime.RequiredError('auditId','Required parameter requestParameters.auditId was null or undefined when calling getAuditAttachments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/audits/{auditId}/attachments`.replace(`{${"auditId"}}`, encodeURIComponent(String(requestParameters.auditId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAuditAttachmentsSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get all attachments for an audit
     */
    async getAuditAttachments(requestParameters: GetAuditAttachmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAuditAttachmentsSuccessResponse> {
        const response = await this.getAuditAttachmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an Audit Export
     */
    async getAuditExportRaw(requestParameters: GetAuditExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAuditExportSuccessResponse>> {
        if (requestParameters.auditExportId === null || requestParameters.auditExportId === undefined) {
            throw new runtime.RequiredError('auditExportId','Required parameter requestParameters.auditExportId was null or undefined when calling getAuditExport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/audits/exports/{auditExportId}`.replace(`{${"auditExportId"}}`, encodeURIComponent(String(requestParameters.auditExportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAuditExportSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get an Audit Export
     */
    async getAuditExport(requestParameters: GetAuditExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAuditExportSuccessResponse> {
        const response = await this.getAuditExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Audit Export Items
     */
    async getAuditExportItemsRaw(requestParameters: GetAuditExportItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAuditExportItemsSuccessResponse>> {
        if (requestParameters.auditExportId === null || requestParameters.auditExportId === undefined) {
            throw new runtime.RequiredError('auditExportId','Required parameter requestParameters.auditExportId was null or undefined when calling getAuditExportItems.');
        }

        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getAuditExportItems.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getAuditExportItems.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getAuditExportItems.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getAuditExportItems.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/audits/exports/{auditExportId}/items`.replace(`{${"auditExportId"}}`, encodeURIComponent(String(requestParameters.auditExportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAuditExportItemsSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get Audit Export Items
     */
    async getAuditExportItems(requestParameters: GetAuditExportItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAuditExportItemsSuccessResponse> {
        const response = await this.getAuditExportItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Audit Exports
     */
    async getAuditExportsRaw(requestParameters: GetAuditExportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAuditExportsSuccessResponse>> {
        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getAuditExports.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getAuditExports.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getAuditExports.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getAuditExports.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/audits/exports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAuditExportsSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get Audit Exports
     */
    async getAuditExports(requestParameters: GetAuditExportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAuditExportsSuccessResponse> {
        const response = await this.getAuditExportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all audits
     */
    async getAuditsRaw(requestParameters: GetAuditsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAuditsSuccessResponse>> {
        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getAudits.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getAudits.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getAudits.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getAudits.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/audits`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAuditsSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get all audits
     */
    async getAudits(requestParameters: GetAuditsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAuditsSuccessResponse> {
        const response = await this.getAuditsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a claim
     */
    async getClaimRaw(requestParameters: GetClaimRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetClaimSuccessResponse>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling getClaim.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/claims/{claimId}`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetClaimSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get a claim
     */
    async getClaim(requestParameters: GetClaimRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetClaimSuccessResponse> {
        const response = await this.getClaimRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all attachments for a claim
     */
    async getClaimAttachmentsRaw(requestParameters: GetClaimAttachmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetClaimAttachmentsSuccessResponse>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling getClaimAttachments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/claims/{claimId}/attachments`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetClaimAttachmentsSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get all attachments for a claim
     */
    async getClaimAttachments(requestParameters: GetClaimAttachmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetClaimAttachmentsSuccessResponse> {
        const response = await this.getClaimAttachmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all notes for a claim
     */
    async getClaimNotesRaw(requestParameters: GetClaimNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetClaimNotesSuccessResponse>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling getClaimNotes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/claims/{claimId}/notes`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetClaimNotesSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get all notes for a claim
     */
    async getClaimNotes(requestParameters: GetClaimNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetClaimNotesSuccessResponse> {
        const response = await this.getClaimNotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all recoveries for a claim
     */
    async getClaimRecoveriesRaw(requestParameters: GetClaimRecoveriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetClaimRecoveriesSuccessResponse>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling getClaimRecoveries.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/claims/{claimId}/recoveries`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetClaimRecoveriesSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get all recoveries for a claim
     */
    async getClaimRecoveries(requestParameters: GetClaimRecoveriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetClaimRecoveriesSuccessResponse> {
        const response = await this.getClaimRecoveriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all reserves for a claim
     */
    async getClaimReservesRaw(requestParameters: GetClaimReservesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetClaimReservesSuccessResponse>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling getClaimReserves.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/claims/{claimId}/reserves`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetClaimReservesSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get all reserves for a claim
     */
    async getClaimReserves(requestParameters: GetClaimReservesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetClaimReservesSuccessResponse> {
        const response = await this.getClaimReservesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all transactions for a claim
     */
    async getClaimTransactionsRaw(requestParameters: GetClaimTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetClaimTransactionsSuccessResponse>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling getClaimTransactions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/claims/{claimId}/transactions`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetClaimTransactionsSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get all transactions for a claim
     */
    async getClaimTransactions(requestParameters: GetClaimTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetClaimTransactionsSuccessResponse> {
        const response = await this.getClaimTransactionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all claims
     */
    async getClaimsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetClaimsSuccessResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/claims`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetClaimsSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get all claims
     */
    async getClaims(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetClaimsSuccessResponse> {
        const response = await this.getClaimsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a CPL
     */
    async getCplRaw(requestParameters: GetCplRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Cpl>> {
        if (requestParameters.cplId === null || requestParameters.cplId === undefined) {
            throw new runtime.RequiredError('cplId','Required parameter requestParameters.cplId was null or undefined when calling getCpl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/cpls/{cplId}`.replace(`{${"cplId"}}`, encodeURIComponent(String(requestParameters.cplId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CplFromJSON(jsonValue));
    }

    /**
     * Get a CPL
     */
    async getCpl(requestParameters: GetCplRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Cpl> {
        const response = await this.getCplRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all CPLs
     */
    async getCplsRaw(requestParameters: GetCplsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCplsSuccessResponse>> {
        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getCpls.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getCpls.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getCpls.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getCpls.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/cpls`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCplsSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get all CPLs
     */
    async getCpls(requestParameters: GetCplsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCplsSuccessResponse> {
        const response = await this.getCplsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an Individual Producer
     */
    async getIndividualProducerRaw(requestParameters: GetIndividualProducerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetIndividualProducerSuccessResponse>> {
        if (requestParameters.individualProducerId === null || requestParameters.individualProducerId === undefined) {
            throw new runtime.RequiredError('individualProducerId','Required parameter requestParameters.individualProducerId was null or undefined when calling getIndividualProducer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/producers/individuals/{individualProducerId}`.replace(`{${"individualProducerId"}}`, encodeURIComponent(String(requestParameters.individualProducerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetIndividualProducerSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get an Individual Producer
     */
    async getIndividualProducer(requestParameters: GetIndividualProducerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetIndividualProducerSuccessResponse> {
        const response = await this.getIndividualProducerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all Jurisdictions for an Individual Producer
     */
    async getIndividualProducerJurisdictionsRaw(requestParameters: GetIndividualProducerJurisdictionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProducerJurisdictionsSuccessResponse>> {
        if (requestParameters.individualProducerId === null || requestParameters.individualProducerId === undefined) {
            throw new runtime.RequiredError('individualProducerId','Required parameter requestParameters.individualProducerId was null or undefined when calling getIndividualProducerJurisdictions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/producers/individuals/{individualProducerId}/jurisdictions`.replace(`{${"individualProducerId"}}`, encodeURIComponent(String(requestParameters.individualProducerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProducerJurisdictionsSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get all Jurisdictions for an Individual Producer
     */
    async getIndividualProducerJurisdictions(requestParameters: GetIndividualProducerJurisdictionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProducerJurisdictionsSuccessResponse> {
        const response = await this.getIndividualProducerJurisdictionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all notes for an Individual Producer
     */
    async getIndividualProducerNotesRaw(requestParameters: GetIndividualProducerNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProducerNotesSuccessResponse>> {
        if (requestParameters.producerId === null || requestParameters.producerId === undefined) {
            throw new runtime.RequiredError('producerId','Required parameter requestParameters.producerId was null or undefined when calling getIndividualProducerNotes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/producers/individuals/{producerId}/notes`.replace(`{${"producerId"}}`, encodeURIComponent(String(requestParameters.producerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProducerNotesSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get all notes for an Individual Producer
     */
    async getIndividualProducerNotes(requestParameters: GetIndividualProducerNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProducerNotesSuccessResponse> {
        const response = await this.getIndividualProducerNotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all Individual Producers
     */
    async getIndividualProducersRaw(requestParameters: GetIndividualProducersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetIndividualProducersSuccessResponse>> {
        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getIndividualProducers.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getIndividualProducers.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getIndividualProducers.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getIndividualProducers.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/producers/individuals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetIndividualProducersSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get all Individual Producers
     */
    async getIndividualProducers(requestParameters: GetIndividualProducersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetIndividualProducersSuccessResponse> {
        const response = await this.getIndividualProducersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Insurful Order Result
     */
    async getInsurfulOrderResultRaw(requestParameters: GetInsurfulOrderResultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InsurfulOrderResult>> {
        if (requestParameters.insurfulOrderId === null || requestParameters.insurfulOrderId === undefined) {
            throw new runtime.RequiredError('insurfulOrderId','Required parameter requestParameters.insurfulOrderId was null or undefined when calling getInsurfulOrderResult.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/insurful/orders/{insurfulOrderId}`.replace(`{${"insurfulOrderId"}}`, encodeURIComponent(String(requestParameters.insurfulOrderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsurfulOrderResultFromJSON(jsonValue));
    }

    /**
     * Get Insurful Order Result
     */
    async getInsurfulOrderResult(requestParameters: GetInsurfulOrderResultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InsurfulOrderResult> {
        const response = await this.getInsurfulOrderResultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Autocomplete search jurisdictions
     */
    async getJurisdictionsAutocompleteRaw(requestParameters: GetJurisdictionsAutocompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetJurisdictionsAutocompleteSuccessResponse>> {
        if (requestParameters.jurisdiction === null || requestParameters.jurisdiction === undefined) {
            throw new runtime.RequiredError('jurisdiction','Required parameter requestParameters.jurisdiction was null or undefined when calling getJurisdictionsAutocomplete.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getJurisdictionsAutocomplete.');
        }

        const queryParameters: any = {};

        if (requestParameters.jurisdiction !== undefined) {
            queryParameters['jurisdiction'] = requestParameters.jurisdiction;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/jurisdictions/autocomplete`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetJurisdictionsAutocompleteSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Autocomplete search jurisdictions
     */
    async getJurisdictionsAutocomplete(requestParameters: GetJurisdictionsAutocompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetJurisdictionsAutocompleteSuccessResponse> {
        const response = await this.getJurisdictionsAutocompleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all Jurisdictions with Unfulfilled Appointments
     */
    async getJurisdictionsWithUnfulfilledAppointmentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProducerJurisdictionsSuccessResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/producers/jurisdictions/unfulfilledAppointments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProducerJurisdictionsSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get all Jurisdictions with Unfulfilled Appointments
     */
    async getJurisdictionsWithUnfulfilledAppointments(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProducerJurisdictionsSuccessResponse> {
        const response = await this.getJurisdictionsWithUnfulfilledAppointmentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get an order
     */
    async getOrderRaw(requestParameters: GetOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrderSuccessResponse>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling getOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/orders/{orderId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrderSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get an order
     */
    async getOrder(requestParameters: GetOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrderSuccessResponse> {
        const response = await this.getOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Order Remittance Items
     */
    async getOrderRemittanceItemsRaw(requestParameters: GetOrderRemittanceItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrderRemittanceItemsSuccessResponse>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling getOrderRemittanceItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/orders/{orderId}/remittances`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrderRemittanceItemsSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get Order Remittance Items
     */
    async getOrderRemittanceItems(requestParameters: GetOrderRemittanceItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrderRemittanceItemsSuccessResponse> {
        const response = await this.getOrderRemittanceItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all underwriting attachments for an order
     */
    async getOrderUnderwritingAttachmentsRaw(requestParameters: GetOrderUnderwritingAttachmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrderUnderwritingAttachmentsSuccessResponse>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling getOrderUnderwritingAttachments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/orders/{orderId}/underwritingAttachments`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrderUnderwritingAttachmentsSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get all underwriting attachments for an order
     */
    async getOrderUnderwritingAttachments(requestParameters: GetOrderUnderwritingAttachmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrderUnderwritingAttachmentsSuccessResponse> {
        const response = await this.getOrderUnderwritingAttachmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all underwriting notes for an order
     */
    async getOrderUnderwritingNotesRaw(requestParameters: GetOrderUnderwritingNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrderUnderwritingNotesSuccessResponse>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling getOrderUnderwritingNotes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/orders/{orderId}/underwritingNotes`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrderUnderwritingNotesSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get all underwriting notes for an order
     */
    async getOrderUnderwritingNotes(requestParameters: GetOrderUnderwritingNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrderUnderwritingNotesSuccessResponse> {
        const response = await this.getOrderUnderwritingNotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get paginated orders
     */
    async getOrdersRaw(requestParameters: GetOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrdersSuccessResponse>> {
        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getOrders.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getOrders.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getOrders.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getOrders.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.agencyId !== undefined) {
            queryParameters['agencyId'] = requestParameters.agencyId;
        }

        if (requestParameters.ids !== undefined) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.flagged !== undefined) {
            queryParameters['flagged'] = requestParameters.flagged;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/orders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrdersSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get paginated orders
     */
    async getOrders(requestParameters: GetOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrdersSuccessResponse> {
        const response = await this.getOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Parent Agencies and their respective Producers
     */
    async getParentAgenciesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetParentAgenciesSuccessResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/producers/agencies/parent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetParentAgenciesSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get Parent Agencies and their respective Producers
     */
    async getParentAgencies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetParentAgenciesSuccessResponse> {
        const response = await this.getParentAgenciesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get paginated policies
     */
    async getPoliciesRaw(requestParameters: GetPoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPoliciesSuccessResponse>> {
        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getPolicies.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getPolicies.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getPolicies.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getPolicies.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/policies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPoliciesSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get paginated policies
     */
    async getPolicies(requestParameters: GetPoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPoliciesSuccessResponse> {
        const response = await this.getPoliciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a Policy
     */
    async getPolicyRaw(requestParameters: GetPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Policy>> {
        if (requestParameters.policyId === null || requestParameters.policyId === undefined) {
            throw new runtime.RequiredError('policyId','Required parameter requestParameters.policyId was null or undefined when calling getPolicy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/policies/{policyId}`.replace(`{${"policyId"}}`, encodeURIComponent(String(requestParameters.policyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolicyFromJSON(jsonValue));
    }

    /**
     * Get a Policy
     */
    async getPolicy(requestParameters: GetPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Policy> {
        const response = await this.getPolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a prediction from the Scoring Service
     */
    async getPredictionRaw(requestParameters: GetPredictionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPredictionSuccessResponse>> {
        if (requestParameters.attomId === null || requestParameters.attomId === undefined) {
            throw new runtime.RequiredError('attomId','Required parameter requestParameters.attomId was null or undefined when calling getPrediction.');
        }

        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError('state','Required parameter requestParameters.state was null or undefined when calling getPrediction.');
        }

        const queryParameters: any = {};

        if (requestParameters.attomId !== undefined) {
            queryParameters['attomId'] = requestParameters.attomId;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/prediction`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPredictionSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get a prediction from the Scoring Service
     */
    async getPrediction(requestParameters: GetPredictionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPredictionSuccessResponse> {
        const response = await this.getPredictionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Producer Licenses or Appointments expiring soon
     */
    async getProducersExpiringRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProducersExpiringSuccessResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/producers/expiring`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProducersExpiringSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get Producer Licenses or Appointments expiring soon
     */
    async getProducersExpiring(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProducersExpiringSuccessResponse> {
        const response = await this.getProducersExpiringRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get ATTOM data for the given Property from the Scoring Service
     */
    async getPropertyRaw(requestParameters: GetPropertyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPropertySuccessResponse>> {
        if (requestParameters.attomId === null || requestParameters.attomId === undefined) {
            throw new runtime.RequiredError('attomId','Required parameter requestParameters.attomId was null or undefined when calling getProperty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/properties/{attomId}`.replace(`{${"attomId"}}`, encodeURIComponent(String(requestParameters.attomId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPropertySuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get ATTOM data for the given Property from the Scoring Service
     */
    async getProperty(requestParameters: GetPropertyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPropertySuccessResponse> {
        const response = await this.getPropertyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a Property Document
     */
    async getPropertyDocumentRaw(requestParameters: GetPropertyDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPropertyDocumentSuccessResponse>> {
        if (requestParameters.propertyDocumentId === null || requestParameters.propertyDocumentId === undefined) {
            throw new runtime.RequiredError('propertyDocumentId','Required parameter requestParameters.propertyDocumentId was null or undefined when calling getPropertyDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/propertyDocuments/{propertyDocumentId}`.replace(`{${"propertyDocumentId"}}`, encodeURIComponent(String(requestParameters.propertyDocumentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPropertyDocumentSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get a Property Document
     */
    async getPropertyDocument(requestParameters: GetPropertyDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPropertyDocumentSuccessResponse> {
        const response = await this.getPropertyDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search Property Documents IDs
     */
    async getPropertyDocumentIdsRaw(requestParameters: GetPropertyDocumentIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPropertyDocumentIdsSuccessResponse>> {
        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getPropertyDocumentIds.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getPropertyDocumentIds.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.addressState !== undefined) {
            queryParameters['addressState'] = requestParameters.addressState;
        }

        if (requestParameters.flagged !== undefined) {
            queryParameters['flagged'] = requestParameters.flagged;
        }

        if (requestParameters.legalDescriptionStatus !== undefined) {
            queryParameters['legalDescriptionStatus'] = requestParameters.legalDescriptionStatus;
        }

        if (requestParameters.vestingInformationStatus !== undefined) {
            queryParameters['vestingInformationStatus'] = requestParameters.vestingInformationStatus;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/propertyDocuments/searchIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPropertyDocumentIdsSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Search Property Documents IDs
     */
    async getPropertyDocumentIds(requestParameters: GetPropertyDocumentIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPropertyDocumentIdsSuccessResponse> {
        const response = await this.getPropertyDocumentIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Property Documents
     */
    async getPropertyDocumentsRaw(requestParameters: GetPropertyDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPropertyDocumentsSuccessResponse>> {
        if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
            throw new runtime.RequiredError('sortField','Required parameter requestParameters.sortField was null or undefined when calling getPropertyDocuments.');
        }

        if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
            throw new runtime.RequiredError('sortDirection','Required parameter requestParameters.sortDirection was null or undefined when calling getPropertyDocuments.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getPropertyDocuments.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getPropertyDocuments.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.addressState !== undefined) {
            queryParameters['addressState'] = requestParameters.addressState;
        }

        if (requestParameters.flagged !== undefined) {
            queryParameters['flagged'] = requestParameters.flagged;
        }

        if (requestParameters.legalDescriptionStatus !== undefined) {
            queryParameters['legalDescriptionStatus'] = requestParameters.legalDescriptionStatus;
        }

        if (requestParameters.vestingInformationStatus !== undefined) {
            queryParameters['vestingInformationStatus'] = requestParameters.vestingInformationStatus;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/propertyDocuments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPropertyDocumentsSuccessResponseFromJSON(jsonValue));
    }

    /**
     * List Property Documents
     */
    async getPropertyDocuments(requestParameters: GetPropertyDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPropertyDocumentsSuccessResponse> {
        const response = await this.getPropertyDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Remittance Items
     */
    async getRemittanceItemsRaw(requestParameters: GetRemittanceItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetRemittanceItemsSuccessResponse>> {
        if (requestParameters.remittanceId === null || requestParameters.remittanceId === undefined) {
            throw new runtime.RequiredError('remittanceId','Required parameter requestParameters.remittanceId was null or undefined when calling getRemittanceItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/remittances/{remittanceId}/items`.replace(`{${"remittanceId"}}`, encodeURIComponent(String(requestParameters.remittanceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRemittanceItemsSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Get Remittance Items
     */
    async getRemittanceItems(requestParameters: GetRemittanceItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetRemittanceItemsSuccessResponse> {
        const response = await this.getRemittanceItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Trained Scoring Models from the Scoring Service
     */
    async getTrainedModelsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTrainedModelsSuccessResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/trainedModels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTrainedModelsSuccessResponseFromJSON(jsonValue));
    }

    /**
     * List Trained Scoring Models from the Scoring Service
     */
    async getTrainedModels(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTrainedModelsSuccessResponse> {
        const response = await this.getTrainedModelsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Validation Report from the Scoring Service
     */
    async getValidationReportRaw(requestParameters: GetValidationReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationReportDetail>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling getValidationReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/validationReports/{reportId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationReportDetailFromJSON(jsonValue));
    }

    /**
     * Get Validation Report from the Scoring Service
     */
    async getValidationReport(requestParameters: GetValidationReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationReportDetail> {
        const response = await this.getValidationReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Validation Reports from the Scoring Service
     */
    async getValidationReportsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetValidationReportsSuccessResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/validationReports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetValidationReportsSuccessResponseFromJSON(jsonValue));
    }

    /**
     * List Validation Reports from the Scoring Service
     */
    async getValidationReports(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetValidationReportsSuccessResponse> {
        const response = await this.getValidationReportsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Reactivate a Producer Jurisdiction
     */
    async reactivateProducerJurisdictionRaw(requestParameters: ReactivateProducerJurisdictionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.producerJurisdictionId === null || requestParameters.producerJurisdictionId === undefined) {
            throw new runtime.RequiredError('producerJurisdictionId','Required parameter requestParameters.producerJurisdictionId was null or undefined when calling reactivateProducerJurisdiction.');
        }

        if (requestParameters.reactivateProducerJurisdictionRequest === null || requestParameters.reactivateProducerJurisdictionRequest === undefined) {
            throw new runtime.RequiredError('reactivateProducerJurisdictionRequest','Required parameter requestParameters.reactivateProducerJurisdictionRequest was null or undefined when calling reactivateProducerJurisdiction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/producers/jurisdictions/{producerJurisdictionId}/reactivate`.replace(`{${"producerJurisdictionId"}}`, encodeURIComponent(String(requestParameters.producerJurisdictionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReactivateProducerJurisdictionRequestToJSON(requestParameters.reactivateProducerJurisdictionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reactivate a Producer Jurisdiction
     */
    async reactivateProducerJurisdiction(requestParameters: ReactivateProducerJurisdictionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reactivateProducerJurisdictionRaw(requestParameters, initOverrides);
    }

    /**
     * Reject the Legal Description of a Property Document
     */
    async rejectPropertyDocumentLegalDescriptionRaw(requestParameters: RejectPropertyDocumentLegalDescriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.propertyDocumentId === null || requestParameters.propertyDocumentId === undefined) {
            throw new runtime.RequiredError('propertyDocumentId','Required parameter requestParameters.propertyDocumentId was null or undefined when calling rejectPropertyDocumentLegalDescription.');
        }

        if (requestParameters.rejectPropertyDocumentLegalDescriptionRequest === null || requestParameters.rejectPropertyDocumentLegalDescriptionRequest === undefined) {
            throw new runtime.RequiredError('rejectPropertyDocumentLegalDescriptionRequest','Required parameter requestParameters.rejectPropertyDocumentLegalDescriptionRequest was null or undefined when calling rejectPropertyDocumentLegalDescription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/propertyDocuments/{propertyDocumentId}/legalDescription/reject`.replace(`{${"propertyDocumentId"}}`, encodeURIComponent(String(requestParameters.propertyDocumentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RejectPropertyDocumentLegalDescriptionRequestToJSON(requestParameters.rejectPropertyDocumentLegalDescriptionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reject the Legal Description of a Property Document
     */
    async rejectPropertyDocumentLegalDescription(requestParameters: RejectPropertyDocumentLegalDescriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.rejectPropertyDocumentLegalDescriptionRaw(requestParameters, initOverrides);
    }

    /**
     * Reject the Vesting Information of a Property Document
     */
    async rejectPropertyDocumentVestingInformationRaw(requestParameters: RejectPropertyDocumentVestingInformationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.propertyDocumentId === null || requestParameters.propertyDocumentId === undefined) {
            throw new runtime.RequiredError('propertyDocumentId','Required parameter requestParameters.propertyDocumentId was null or undefined when calling rejectPropertyDocumentVestingInformation.');
        }

        if (requestParameters.rejectPropertyDocumentVestingInformationRequest === null || requestParameters.rejectPropertyDocumentVestingInformationRequest === undefined) {
            throw new runtime.RequiredError('rejectPropertyDocumentVestingInformationRequest','Required parameter requestParameters.rejectPropertyDocumentVestingInformationRequest was null or undefined when calling rejectPropertyDocumentVestingInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/propertyDocuments/{propertyDocumentId}/vestingInformation/reject`.replace(`{${"propertyDocumentId"}}`, encodeURIComponent(String(requestParameters.propertyDocumentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RejectPropertyDocumentVestingInformationRequestToJSON(requestParameters.rejectPropertyDocumentVestingInformationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reject the Vesting Information of a Property Document
     */
    async rejectPropertyDocumentVestingInformation(requestParameters: RejectPropertyDocumentVestingInformationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.rejectPropertyDocumentVestingInformationRaw(requestParameters, initOverrides);
    }

    /**
     * Renew an Agency Producer License
     */
    async renewAgencyProducerLicenseRaw(requestParameters: RenewAgencyProducerLicenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agencyProducerId === null || requestParameters.agencyProducerId === undefined) {
            throw new runtime.RequiredError('agencyProducerId','Required parameter requestParameters.agencyProducerId was null or undefined when calling renewAgencyProducerLicense.');
        }

        if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
            throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling renewAgencyProducerLicense.');
        }

        if (requestParameters.renewProducerLicenseRequest === null || requestParameters.renewProducerLicenseRequest === undefined) {
            throw new runtime.RequiredError('renewProducerLicenseRequest','Required parameter requestParameters.renewProducerLicenseRequest was null or undefined when calling renewAgencyProducerLicense.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/producers/agencies/{agencyProducerId}/licenses/{licenseId}`.replace(`{${"agencyProducerId"}}`, encodeURIComponent(String(requestParameters.agencyProducerId))).replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RenewProducerLicenseRequestToJSON(requestParameters.renewProducerLicenseRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Renew an Agency Producer License
     */
    async renewAgencyProducerLicense(requestParameters: RenewAgencyProducerLicenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.renewAgencyProducerLicenseRaw(requestParameters, initOverrides);
    }

    /**
     * Renew an Individual Producer License
     */
    async renewIndividualProducerLicenseRaw(requestParameters: RenewIndividualProducerLicenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.individualProducerId === null || requestParameters.individualProducerId === undefined) {
            throw new runtime.RequiredError('individualProducerId','Required parameter requestParameters.individualProducerId was null or undefined when calling renewIndividualProducerLicense.');
        }

        if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
            throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling renewIndividualProducerLicense.');
        }

        if (requestParameters.renewProducerLicenseRequest === null || requestParameters.renewProducerLicenseRequest === undefined) {
            throw new runtime.RequiredError('renewProducerLicenseRequest','Required parameter requestParameters.renewProducerLicenseRequest was null or undefined when calling renewIndividualProducerLicense.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/producers/individuals/{individualProducerId}/licenses/{licenseId}`.replace(`{${"individualProducerId"}}`, encodeURIComponent(String(requestParameters.individualProducerId))).replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RenewProducerLicenseRequestToJSON(requestParameters.renewProducerLicenseRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Renew an Individual Producer License
     */
    async renewIndividualProducerLicense(requestParameters: RenewIndividualProducerLicenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.renewIndividualProducerLicenseRaw(requestParameters, initOverrides);
    }

    /**
     * Search ATTOM Properties in the Scoring Service
     */
    async searchPropertiesRaw(requestParameters: SearchPropertiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchPropertiesResponse>> {
        if (requestParameters.street === null || requestParameters.street === undefined) {
            throw new runtime.RequiredError('street','Required parameter requestParameters.street was null or undefined when calling searchProperties.');
        }

        const queryParameters: any = {};

        if (requestParameters.street !== undefined) {
            queryParameters['street'] = requestParameters.street;
        }

        if (requestParameters.unitValue !== undefined) {
            queryParameters['unitValue'] = requestParameters.unitValue;
        }

        if (requestParameters.city !== undefined) {
            queryParameters['city'] = requestParameters.city;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/properties/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchPropertiesResponseFromJSON(jsonValue));
    }

    /**
     * Search ATTOM Properties in the Scoring Service
     */
    async searchProperties(requestParameters: SearchPropertiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchPropertiesResponse> {
        const response = await this.searchPropertiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Train new Scoring Model via the Scoring Service
     */
    async trainModelRaw(requestParameters: TrainModelOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrainModelAcceptedResponse>> {
        if (requestParameters.trainModelRequest === null || requestParameters.trainModelRequest === undefined) {
            throw new runtime.RequiredError('trainModelRequest','Required parameter requestParameters.trainModelRequest was null or undefined when calling trainModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/trainedModels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TrainModelRequestToJSON(requestParameters.trainModelRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainModelAcceptedResponseFromJSON(jsonValue));
    }

    /**
     * Train new Scoring Model via the Scoring Service
     */
    async trainModel(requestParameters: TrainModelOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrainModelAcceptedResponse> {
        const response = await this.trainModelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unapprove the Legal Description of a Property Document
     */
    async unApprovePropertyDocumentLegalDescriptionRaw(requestParameters: UnApprovePropertyDocumentLegalDescriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.propertyDocumentId === null || requestParameters.propertyDocumentId === undefined) {
            throw new runtime.RequiredError('propertyDocumentId','Required parameter requestParameters.propertyDocumentId was null or undefined when calling unApprovePropertyDocumentLegalDescription.');
        }

        if (requestParameters.unApprovePropertyDocumentLegalDescriptionRequest === null || requestParameters.unApprovePropertyDocumentLegalDescriptionRequest === undefined) {
            throw new runtime.RequiredError('unApprovePropertyDocumentLegalDescriptionRequest','Required parameter requestParameters.unApprovePropertyDocumentLegalDescriptionRequest was null or undefined when calling unApprovePropertyDocumentLegalDescription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/propertyDocuments/{propertyDocumentId}/legalDescription/unapprove`.replace(`{${"propertyDocumentId"}}`, encodeURIComponent(String(requestParameters.propertyDocumentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnApprovePropertyDocumentLegalDescriptionRequestToJSON(requestParameters.unApprovePropertyDocumentLegalDescriptionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unapprove the Legal Description of a Property Document
     */
    async unApprovePropertyDocumentLegalDescription(requestParameters: UnApprovePropertyDocumentLegalDescriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unApprovePropertyDocumentLegalDescriptionRaw(requestParameters, initOverrides);
    }

    /**
     * Unapprove the Vesting Information of a Property Document
     */
    async unApprovePropertyDocumentVestingInformationRaw(requestParameters: UnApprovePropertyDocumentVestingInformationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.propertyDocumentId === null || requestParameters.propertyDocumentId === undefined) {
            throw new runtime.RequiredError('propertyDocumentId','Required parameter requestParameters.propertyDocumentId was null or undefined when calling unApprovePropertyDocumentVestingInformation.');
        }

        if (requestParameters.unApprovePropertyDocumentVestingInformationRequest === null || requestParameters.unApprovePropertyDocumentVestingInformationRequest === undefined) {
            throw new runtime.RequiredError('unApprovePropertyDocumentVestingInformationRequest','Required parameter requestParameters.unApprovePropertyDocumentVestingInformationRequest was null or undefined when calling unApprovePropertyDocumentVestingInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/propertyDocuments/{propertyDocumentId}/vestingInformation/unapprove`.replace(`{${"propertyDocumentId"}}`, encodeURIComponent(String(requestParameters.propertyDocumentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnApprovePropertyDocumentVestingInformationRequestToJSON(requestParameters.unApprovePropertyDocumentVestingInformationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unapprove the Vesting Information of a Property Document
     */
    async unApprovePropertyDocumentVestingInformation(requestParameters: UnApprovePropertyDocumentVestingInformationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unApprovePropertyDocumentVestingInformationRaw(requestParameters, initOverrides);
    }

    /**
     * Unreject the Legal Description of a Property Document
     */
    async unRejectPropertyDocumentLegalDescriptionRaw(requestParameters: UnRejectPropertyDocumentLegalDescriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.propertyDocumentId === null || requestParameters.propertyDocumentId === undefined) {
            throw new runtime.RequiredError('propertyDocumentId','Required parameter requestParameters.propertyDocumentId was null or undefined when calling unRejectPropertyDocumentLegalDescription.');
        }

        if (requestParameters.unRejectPropertyDocumentLegalDescriptionRequest === null || requestParameters.unRejectPropertyDocumentLegalDescriptionRequest === undefined) {
            throw new runtime.RequiredError('unRejectPropertyDocumentLegalDescriptionRequest','Required parameter requestParameters.unRejectPropertyDocumentLegalDescriptionRequest was null or undefined when calling unRejectPropertyDocumentLegalDescription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/propertyDocuments/{propertyDocumentId}/legalDescription/unreject`.replace(`{${"propertyDocumentId"}}`, encodeURIComponent(String(requestParameters.propertyDocumentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnRejectPropertyDocumentLegalDescriptionRequestToJSON(requestParameters.unRejectPropertyDocumentLegalDescriptionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unreject the Legal Description of a Property Document
     */
    async unRejectPropertyDocumentLegalDescription(requestParameters: UnRejectPropertyDocumentLegalDescriptionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unRejectPropertyDocumentLegalDescriptionRaw(requestParameters, initOverrides);
    }

    /**
     * Unreject the Vesting Information of a Property Document
     */
    async unRejectPropertyDocumentVestingInformationRaw(requestParameters: UnRejectPropertyDocumentVestingInformationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.propertyDocumentId === null || requestParameters.propertyDocumentId === undefined) {
            throw new runtime.RequiredError('propertyDocumentId','Required parameter requestParameters.propertyDocumentId was null or undefined when calling unRejectPropertyDocumentVestingInformation.');
        }

        if (requestParameters.unRejectPropertyDocumentVestingInformationRequest === null || requestParameters.unRejectPropertyDocumentVestingInformationRequest === undefined) {
            throw new runtime.RequiredError('unRejectPropertyDocumentVestingInformationRequest','Required parameter requestParameters.unRejectPropertyDocumentVestingInformationRequest was null or undefined when calling unRejectPropertyDocumentVestingInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/propertyDocuments/{propertyDocumentId}/vestingInformation/unreject`.replace(`{${"propertyDocumentId"}}`, encodeURIComponent(String(requestParameters.propertyDocumentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnRejectPropertyDocumentVestingInformationRequestToJSON(requestParameters.unRejectPropertyDocumentVestingInformationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unreject the Vesting Information of a Property Document
     */
    async unRejectPropertyDocumentVestingInformation(requestParameters: UnRejectPropertyDocumentVestingInformationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unRejectPropertyDocumentVestingInformationRaw(requestParameters, initOverrides);
    }

    /**
     * Unflags the Order
     */
    async unflagOrderRaw(requestParameters: UnflagOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling unflagOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/orders/{orderId}/unflag`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unflags the Order
     */
    async unflagOrder(requestParameters: UnflagOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unflagOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Unflag the Property Document
     */
    async unflagPropertyDocumentRaw(requestParameters: UnflagPropertyDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.propertyDocumentId === null || requestParameters.propertyDocumentId === undefined) {
            throw new runtime.RequiredError('propertyDocumentId','Required parameter requestParameters.propertyDocumentId was null or undefined when calling unflagPropertyDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/propertyDocuments/{propertyDocumentId}/unflag`.replace(`{${"propertyDocumentId"}}`, encodeURIComponent(String(requestParameters.propertyDocumentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unflag the Property Document
     */
    async unflagPropertyDocument(requestParameters: UnflagPropertyDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unflagPropertyDocumentRaw(requestParameters, initOverrides);
    }

    /**
     * Update an Agency Producer
     */
    async updateAgencyProducerRaw(requestParameters: UpdateAgencyProducerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agencyProducerId === null || requestParameters.agencyProducerId === undefined) {
            throw new runtime.RequiredError('agencyProducerId','Required parameter requestParameters.agencyProducerId was null or undefined when calling updateAgencyProducer.');
        }

        if (requestParameters.updateAgencyProducerRequest === null || requestParameters.updateAgencyProducerRequest === undefined) {
            throw new runtime.RequiredError('updateAgencyProducerRequest','Required parameter requestParameters.updateAgencyProducerRequest was null or undefined when calling updateAgencyProducer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/producers/agencies/{agencyProducerId}`.replace(`{${"agencyProducerId"}}`, encodeURIComponent(String(requestParameters.agencyProducerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAgencyProducerRequestToJSON(requestParameters.updateAgencyProducerRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an Agency Producer
     */
    async updateAgencyProducer(requestParameters: UpdateAgencyProducerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAgencyProducerRaw(requestParameters, initOverrides);
    }

    /**
     * Update an Audit
     */
    async updateAuditRaw(requestParameters: UpdateAuditOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.auditId === null || requestParameters.auditId === undefined) {
            throw new runtime.RequiredError('auditId','Required parameter requestParameters.auditId was null or undefined when calling updateAudit.');
        }

        if (requestParameters.updateAuditRequest === null || requestParameters.updateAuditRequest === undefined) {
            throw new runtime.RequiredError('updateAuditRequest','Required parameter requestParameters.updateAuditRequest was null or undefined when calling updateAudit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/audits/{auditId}`.replace(`{${"auditId"}}`, encodeURIComponent(String(requestParameters.auditId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAuditRequestToJSON(requestParameters.updateAuditRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an Audit
     */
    async updateAudit(requestParameters: UpdateAuditOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAuditRaw(requestParameters, initOverrides);
    }

    /**
     * Updates an Audit Attachment
     */
    async updateAuditAttachmentRaw(requestParameters: UpdateAuditAttachmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.auditId === null || requestParameters.auditId === undefined) {
            throw new runtime.RequiredError('auditId','Required parameter requestParameters.auditId was null or undefined when calling updateAuditAttachment.');
        }

        if (requestParameters.auditAttachmentId === null || requestParameters.auditAttachmentId === undefined) {
            throw new runtime.RequiredError('auditAttachmentId','Required parameter requestParameters.auditAttachmentId was null or undefined when calling updateAuditAttachment.');
        }

        if (requestParameters.updateAuditAttachmentRequest === null || requestParameters.updateAuditAttachmentRequest === undefined) {
            throw new runtime.RequiredError('updateAuditAttachmentRequest','Required parameter requestParameters.updateAuditAttachmentRequest was null or undefined when calling updateAuditAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/audits/{auditId}/attachments/{auditAttachmentId}`.replace(`{${"auditId"}}`, encodeURIComponent(String(requestParameters.auditId))).replace(`{${"auditAttachmentId"}}`, encodeURIComponent(String(requestParameters.auditAttachmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAuditAttachmentRequestToJSON(requestParameters.updateAuditAttachmentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates an Audit Attachment
     */
    async updateAuditAttachment(requestParameters: UpdateAuditAttachmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAuditAttachmentRaw(requestParameters, initOverrides);
    }

    /**
     * Update a Claim
     */
    async updateClaimRaw(requestParameters: UpdateClaimOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling updateClaim.');
        }

        if (requestParameters.updateClaimRequest === null || requestParameters.updateClaimRequest === undefined) {
            throw new runtime.RequiredError('updateClaimRequest','Required parameter requestParameters.updateClaimRequest was null or undefined when calling updateClaim.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/claims/{claimId}`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateClaimRequestToJSON(requestParameters.updateClaimRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a Claim
     */
    async updateClaim(requestParameters: UpdateClaimOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateClaimRaw(requestParameters, initOverrides);
    }

    /**
     * Updates a Claim Note
     */
    async updateClaimNoteRaw(requestParameters: UpdateClaimNoteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling updateClaimNote.');
        }

        if (requestParameters.claimNoteId === null || requestParameters.claimNoteId === undefined) {
            throw new runtime.RequiredError('claimNoteId','Required parameter requestParameters.claimNoteId was null or undefined when calling updateClaimNote.');
        }

        if (requestParameters.updateClaimNoteRequest === null || requestParameters.updateClaimNoteRequest === undefined) {
            throw new runtime.RequiredError('updateClaimNoteRequest','Required parameter requestParameters.updateClaimNoteRequest was null or undefined when calling updateClaimNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/claims/{claimId}/notes/{claimNoteId}`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))).replace(`{${"claimNoteId"}}`, encodeURIComponent(String(requestParameters.claimNoteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateClaimNoteRequestToJSON(requestParameters.updateClaimNoteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a Claim Note
     */
    async updateClaimNote(requestParameters: UpdateClaimNoteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateClaimNoteRaw(requestParameters, initOverrides);
    }

    /**
     * Update an Individual Producer
     */
    async updateIndividualProducerRaw(requestParameters: UpdateIndividualProducerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.individualProducerId === null || requestParameters.individualProducerId === undefined) {
            throw new runtime.RequiredError('individualProducerId','Required parameter requestParameters.individualProducerId was null or undefined when calling updateIndividualProducer.');
        }

        if (requestParameters.updateIndividualProducerRequest === null || requestParameters.updateIndividualProducerRequest === undefined) {
            throw new runtime.RequiredError('updateIndividualProducerRequest','Required parameter requestParameters.updateIndividualProducerRequest was null or undefined when calling updateIndividualProducer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/producers/individuals/{individualProducerId}`.replace(`{${"individualProducerId"}}`, encodeURIComponent(String(requestParameters.individualProducerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateIndividualProducerRequestToJSON(requestParameters.updateIndividualProducerRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an Individual Producer
     */
    async updateIndividualProducer(requestParameters: UpdateIndividualProducerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateIndividualProducerRaw(requestParameters, initOverrides);
    }

    /**
     * Updates an Order Underwriting Note
     */
    async updateOrderUnderwritingNoteRaw(requestParameters: UpdateOrderUnderwritingNoteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling updateOrderUnderwritingNote.');
        }

        if (requestParameters.orderUnderwritingNoteId === null || requestParameters.orderUnderwritingNoteId === undefined) {
            throw new runtime.RequiredError('orderUnderwritingNoteId','Required parameter requestParameters.orderUnderwritingNoteId was null or undefined when calling updateOrderUnderwritingNote.');
        }

        if (requestParameters.updateOrderUnderwritingNoteRequest === null || requestParameters.updateOrderUnderwritingNoteRequest === undefined) {
            throw new runtime.RequiredError('updateOrderUnderwritingNoteRequest','Required parameter requestParameters.updateOrderUnderwritingNoteRequest was null or undefined when calling updateOrderUnderwritingNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/orders/{orderId}/underwritingNotes/{orderUnderwritingNoteId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))).replace(`{${"orderUnderwritingNoteId"}}`, encodeURIComponent(String(requestParameters.orderUnderwritingNoteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrderUnderwritingNoteRequestToJSON(requestParameters.updateOrderUnderwritingNoteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates an Order Underwriting Note
     */
    async updateOrderUnderwritingNote(requestParameters: UpdateOrderUnderwritingNoteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateOrderUnderwritingNoteRaw(requestParameters, initOverrides);
    }

    /**
     * Updates a Producer Note
     */
    async updateProducerNoteRaw(requestParameters: UpdateProducerNoteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.producerId === null || requestParameters.producerId === undefined) {
            throw new runtime.RequiredError('producerId','Required parameter requestParameters.producerId was null or undefined when calling updateProducerNote.');
        }

        if (requestParameters.producerNoteId === null || requestParameters.producerNoteId === undefined) {
            throw new runtime.RequiredError('producerNoteId','Required parameter requestParameters.producerNoteId was null or undefined when calling updateProducerNote.');
        }

        if (requestParameters.updateProducerNoteRequest === null || requestParameters.updateProducerNoteRequest === undefined) {
            throw new runtime.RequiredError('updateProducerNoteRequest','Required parameter requestParameters.updateProducerNoteRequest was null or undefined when calling updateProducerNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/producers/{producerId}/notes/{producerNoteId}`.replace(`{${"producerId"}}`, encodeURIComponent(String(requestParameters.producerId))).replace(`{${"producerNoteId"}}`, encodeURIComponent(String(requestParameters.producerNoteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProducerNoteRequestToJSON(requestParameters.updateProducerNoteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a Producer Note
     */
    async updateProducerNote(requestParameters: UpdateProducerNoteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProducerNoteRaw(requestParameters, initOverrides);
    }

    /**
     * Update Remittance
     */
    async updateRemittanceRaw(requestParameters: UpdateRemittanceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.remittanceId === null || requestParameters.remittanceId === undefined) {
            throw new runtime.RequiredError('remittanceId','Required parameter requestParameters.remittanceId was null or undefined when calling updateRemittance.');
        }

        if (requestParameters.updateRemittanceRequest === null || requestParameters.updateRemittanceRequest === undefined) {
            throw new runtime.RequiredError('updateRemittanceRequest','Required parameter requestParameters.updateRemittanceRequest was null or undefined when calling updateRemittance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/remittances/{remittanceId}`.replace(`{${"remittanceId"}}`, encodeURIComponent(String(requestParameters.remittanceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRemittanceRequestToJSON(requestParameters.updateRemittanceRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Remittance
     */
    async updateRemittance(requestParameters: UpdateRemittanceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRemittanceRaw(requestParameters, initOverrides);
    }

    /**
     * Void a Claim Transaction
     */
    async voidClaimTransactionRaw(requestParameters: VoidClaimTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling voidClaimTransaction.');
        }

        if (requestParameters.claimTransactionId === null || requestParameters.claimTransactionId === undefined) {
            throw new runtime.RequiredError('claimTransactionId','Required parameter requestParameters.claimTransactionId was null or undefined when calling voidClaimTransaction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/claims/{claimId}/transactions/{claimTransactionId}/void`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))).replace(`{${"claimTransactionId"}}`, encodeURIComponent(String(requestParameters.claimTransactionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Void a Claim Transaction
     */
    async voidClaimTransaction(requestParameters: VoidClaimTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.voidClaimTransactionRaw(requestParameters, initOverrides);
    }

}
