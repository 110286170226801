import {
  Flex as AmplifyFlex,
  StyleToken as AmplifyStyleToken,
  Table as AmplifyTable,
  TableBody as AmplifyTableBody,
  TableCell as AmplifyTableCell,
  TableHead as AmplifyTableHead,
  TableRow as AmplifyTableRow,
  useTheme,
} from "@aws-amplify/ui-react";
import React from "react";

export const Table: React.FC<React.ComponentProps<typeof AmplifyTable>> = (
  props,
) => <AmplifyTable {...props} />;

export const THead = AmplifyTableHead;

export const HeaderRow = AmplifyTableRow;

export const Th: React.FC<React.ComponentProps<typeof AmplifyTableCell>> = (
  props,
) => <AmplifyTableCell as="th" {...props} />;

export const TBody: React.FC<React.ComponentProps<typeof AmplifyTableBody>> = (
  props,
) => {
  const theme = useTheme();
  return (
    <AmplifyTableBody
      backgroundColor={theme.tokens.colors.background.primary}
      {...props}
    />
  );
};

export const BodyRow = AmplifyTableRow;

export const Td: React.FC<React.ComponentProps<typeof AmplifyTableCell>> = (
  props,
) => <AmplifyTableCell as="td" {...props} />;

export const useTableMargin = (): AmplifyStyleToken<string> => {
  const theme = useTheme();
  return theme.tokens.space.xxl;
};

type AmplifyFlexProps = React.ComponentProps<typeof AmplifyFlex>;

export const AboveTableContainer: React.FC<
  Omit<AmplifyFlexProps, "justifyContent"> &
    Required<Pick<AmplifyFlexProps, "justifyContent">>
> = (props) => <AmplifyFlex direction="row" alignItems="center" {...props} />;
