import {Alert, Placeholder} from "@aws-amplify/ui-react";
import {QueryFunction, useQuery} from "@tanstack/react-query";
import {isLoadingNextQuery, Query} from "@title-service/react-query-utils";
import {DateTimeComponent} from "@title-service/ui";
import {enumValues, parseWholeNumber} from "@title-service/utils";
import React, {useCallback, useContext} from "react";
import {URLSearchParamsInit, useSearchParams} from "react-router-dom";

import {DependencyContext} from "../DependencyContext";
import {
  GetCplsRequest,
  GetCplsSortField,
  GetCplsSuccessResponse,
  parseSortDirection,
  SortDirection,
} from "../shared/adminApi";
import {CurrencyComponent} from "../shared/components/Currency";
import {RouterLink} from "../shared/components/Link";
import {LoadingOverlay} from "../shared/components/LoadingOverlay";
import {PageNumbers} from "../shared/components/Pagination";
import {QuantityComponent} from "../shared/components/Quantity";
import {
  AboveTableContainer,
  BodyRow,
  HeaderRow,
  Table,
  TBody,
  Td,
  Th,
  THead,
} from "../shared/components/tables/PrimaryTable";
import {SortableTh} from "../shared/components/tables/SortableTh";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
} from "../shared/layout";
import {TransactionPartyTypeComponent} from "../shared/orderDetails/TransactionPartyType";

type GetCplsQueryKey = ["cpls", "search", GetCplsRequest];
export type GetCplsQuery = Query<GetCplsSuccessResponse>;

export const CplsRoute: React.FC<{
  GetCplsBodyContent?: typeof GetCplsBodyContent;
}> = ({GetCplsBodyContent: GetCplsBodyContent_ = GetCplsBodyContent}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const request = mapSearchParamsToGetCplsRequest(searchParams);
  const {adminApi} = useContext(DependencyContext);
  const getCplsQueryFn: QueryFunction<GetCplsSuccessResponse, GetCplsQueryKey> =
    useCallback(
      ({queryKey: [_1, _2, _request], signal}) =>
        adminApi.getCpls(_request, signal),
      [adminApi],
    );
  const getCplsQuery = useQuery({
    queryKey: ["cpls", "search", request],
    keepPreviousData: true,
    queryFn: getCplsQueryFn,
  });
  const handleChangeRequestState = useCallback(
    (updateRequest: (currentRequest: GetCplsRequest) => GetCplsRequest) => {
      setSearchParams((currentSearchParams) => {
        const currentRequest =
          mapSearchParamsToGetCplsRequest(currentSearchParams);
        const updatedRequest = updateRequest(currentRequest);
        return mapGetCplsRequestToSearchParams(updatedRequest);
      });
    },
    [setSearchParams],
  );

  return (
    <>
      <BodyHeader>
        <PrimaryBodyHeaderContainer>
          <PrimaryBodyHeader value="Closing Protection Letters" />
        </PrimaryBodyHeaderContainer>
      </BodyHeader>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <GetCplsBodyContent_
        request={request}
        getCplsQuery={getCplsQuery}
        onChangeRequest={handleChangeRequestState}
      />
    </>
  );
};
export const GetCplsBodyContent: React.FC<{
  request: GetCplsRequest;
  getCplsQuery: GetCplsQuery;
  onChangeRequest: (
    updateRequest: (request: GetCplsRequest) => GetCplsRequest,
  ) => any;
  CplsPageSuccessTableBody?: typeof CplsPageSuccessTableBody;
  CplsPagePlaceholderTableBody?: typeof CplsPagePlaceholderTableBody;
  PageNumbers?: typeof PageNumbers;
}> = ({
  request,
  getCplsQuery,
  getCplsQuery: {data, error, isInitialLoading},
  onChangeRequest,
  CplsPageSuccessTableBody:
    CplsPageSuccessTableBody_ = CplsPageSuccessTableBody,
  CplsPagePlaceholderTableBody:
    CplsPagePlaceholderTableBody_ = CplsPagePlaceholderTableBody,
  PageNumbers: PageNumbers_ = PageNumbers,
}) => {
  const isLoading = isLoadingNextQuery(getCplsQuery);
  const setSort = useCallback(
    (sortField: GetCplsSortField, sortDirection: SortDirection) => {
      onChangeRequest((currentRequest) => ({
        ...currentRequest,
        sortField,
        sortDirection,
        page: 0,
      }));
    },
    [onChangeRequest],
  );
  const setPage = useCallback(
    (zeroIndexedCurrentPageNumber) => {
      onChangeRequest((currentRequest) => ({
        ...currentRequest,
        page: zeroIndexedCurrentPageNumber,
      }));
    },
    [onChangeRequest],
  );

  return (
    <BodyContent>
      <AboveTableContainer justifyContent="flex-start">
        {error ? (
          <Alert variation="error" isDismissible={false} hasIcon={true}>
            Failed to load Cpls
          </Alert>
        ) : data ? (
          <QuantityComponent
            quantity={data.pagination.totalRecords}
            label={data.pagination.totalRecords === 1 ? "CPL" : "CPLs"}
          />
        ) : null}
      </AboveTableContainer>
      <LoadingOverlay isActive={isLoading}>
        <Table>
          <THead>
            <HeaderRow>
              <Th>CPL ID</Th>
              <Th>Order Reference ID</Th>
              <Th>CPL type</Th>
              <SortableTh
                testId="state-th"
                field={GetCplsSortField.State}
                fieldInitialSortDirection={SortDirection.Desc}
                currentSortDirection={request.sortDirection}
                currentSortField={request.sortField}
                onSort={setSort}
              >
                State
              </SortableTh>
              <SortableTh
                testId="created-th"
                field={GetCplsSortField.Created}
                fieldInitialSortDirection={SortDirection.Desc}
                currentSortDirection={request.sortDirection}
                currentSortField={request.sortField}
                onSort={setSort}
              >
                Created
              </SortableTh>
              <SortableTh
                testId="cost-th"
                field={GetCplsSortField.Cost}
                fieldInitialSortDirection={SortDirection.Desc}
                currentSortDirection={request.sortDirection}
                currentSortField={request.sortField}
                onSort={setSort}
              >
                Cost
              </SortableTh>
            </HeaderRow>
          </THead>
          {data ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <CplsPageSuccessTableBody_ response={data} />
          ) : error ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <CplsPagePlaceholderTableBody_ isLoaded={true} />
          ) : (
            isInitialLoading && (
              // eslint-disable-next-line react/jsx-pascal-case
              <CplsPagePlaceholderTableBody_ isLoaded={false} />
            )
          )}
        </Table>
      </LoadingOverlay>
      {data ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <PageNumbers_
          totalPages={data.pagination.totalPages}
          zeroIndexedCurrentPageNumber={data.pagination.currentPage}
          onChange={setPage}
        />
      ) : null}
    </BodyContent>
  );
};

export const CplsPagePlaceholderTableBody: React.FC<{isLoaded: boolean}> = ({
  isLoaded,
}) => (
  <TBody>
    <BodyRow>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
    </BodyRow>
  </TBody>
);

export const CplsPageSuccessTableBody: React.FC<{
  response: GetCplsSuccessResponse;
}> = ({response: {cpls}}) => {
  if (!cpls.length) {
    return (
      <TBody>
        <BodyRow>
          <Td colSpan={6}>No CPLs found</Td>
        </BodyRow>
      </TBody>
    );
  }

  return (
    <TBody>
      {cpls.map(({cpl, order}) => (
        <BodyRow key={cpl.id}>
          <Td>{cpl.id}</Td>
          <Td>
            <RouterLink to={`/orders/${order.id}/`}>
              {order.referenceId}
            </RouterLink>
          </Td>
          <Td>
            <TransactionPartyTypeComponent transactionPartyType={cpl.type} />
          </Td>
          <Td>{cpl.state}</Td>
          <Td>
            <DateTimeComponent value={cpl.createdAt} />
          </Td>
          <Td>
            <CurrencyComponent value={cpl.cost} />
          </Td>
        </BodyRow>
      ))}
    </TBody>
  );
};

export type GetCplsRequestDefaults = Pick<
  GetCplsRequest,
  "page" | "pageSize" | "sortField" | "sortDirection"
>;

export const GET_CPLS_REQUEST_QUERY_DEFAULTS: GetCplsRequestDefaults = {
  sortField: GetCplsSortField.Created,
  sortDirection: SortDirection.Desc,
  page: 0,
  pageSize: 10,
};

const GET_CPLS_SORT_FIELD_VALUES = enumValues(GetCplsSortField);

export const mapSearchParamToGetCplsSortField = (
  getCplsSortField: string | null,
): GetCplsSortField | undefined => {
  const trimmed = getCplsSortField?.trim();
  if (trimmed) {
    return GET_CPLS_SORT_FIELD_VALUES.find((value) => value === trimmed);
  }
  return undefined;
};

export const mapSearchParamsToGetCplsRequest = (
  searchParams: URLSearchParams,
  queryDefaults: GetCplsRequestDefaults = GET_CPLS_REQUEST_QUERY_DEFAULTS,
): GetCplsRequest => ({
  sortField:
    mapSearchParamToGetCplsSortField(searchParams.get("sortField")) ??
    queryDefaults.sortField,
  sortDirection:
    parseSortDirection(searchParams.get("sortDirection")) ??
    queryDefaults.sortDirection,
  page: parseWholeNumber(searchParams.get("page")) ?? queryDefaults.page,
  pageSize:
    parseWholeNumber(searchParams.get("pageSize")) ?? queryDefaults.pageSize,
});

export const mapGetCplsRequestToSearchParams = ({
  page,
  pageSize,
  ...request
}: GetCplsRequest): URLSearchParamsInit => ({
  ...request,
  page: page.toString(10),
  pageSize: pageSize.toString(10),
});
