/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateIndividualProducerRequest
 */
export interface UpdateIndividualProducerRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateIndividualProducerRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIndividualProducerRequest
     */
    niprId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIndividualProducerRequest
     */
    parentAgencyProducerId: string;
}

/**
 * Check if a given object implements the UpdateIndividualProducerRequest interface.
 */
export function instanceOfUpdateIndividualProducerRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "parentAgencyProducerId" in value;

    return isInstance;
}

export function UpdateIndividualProducerRequestFromJSON(json: any): UpdateIndividualProducerRequest {
    return UpdateIndividualProducerRequestFromJSONTyped(json, false);
}

export function UpdateIndividualProducerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateIndividualProducerRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'niprId': !exists(json, 'niprId') ? undefined : json['niprId'],
        'parentAgencyProducerId': json['parentAgencyProducerId'],
    };
}

export function UpdateIndividualProducerRequestToJSON(value?: UpdateIndividualProducerRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'niprId': value.niprId,
        'parentAgencyProducerId': value.parentAgencyProducerId,
    };
}

