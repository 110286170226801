/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AuditAttachmentType } from './AuditAttachmentType';
import {
    AuditAttachmentTypeFromJSON,
    AuditAttachmentTypeFromJSONTyped,
    AuditAttachmentTypeToJSON,
} from './AuditAttachmentType';

/**
 * 
 * @export
 * @interface GetAuditAttachmentsSuccessResponseAttachment
 */
export interface GetAuditAttachmentsSuccessResponseAttachment {
    /**
     * 
     * @type {string}
     * @memberof GetAuditAttachmentsSuccessResponseAttachment
     */
    artifactUrl: string;
    /**
     * 
     * @type {Date}
     * @memberof GetAuditAttachmentsSuccessResponseAttachment
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof GetAuditAttachmentsSuccessResponseAttachment
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditAttachmentsSuccessResponseAttachment
     */
    id: string;
    /**
     * 
     * @type {AuditAttachmentType}
     * @memberof GetAuditAttachmentsSuccessResponseAttachment
     */
    type?: AuditAttachmentType;
}

/**
 * Check if a given object implements the GetAuditAttachmentsSuccessResponseAttachment interface.
 */
export function instanceOfGetAuditAttachmentsSuccessResponseAttachment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "artifactUrl" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function GetAuditAttachmentsSuccessResponseAttachmentFromJSON(json: any): GetAuditAttachmentsSuccessResponseAttachment {
    return GetAuditAttachmentsSuccessResponseAttachmentFromJSONTyped(json, false);
}

export function GetAuditAttachmentsSuccessResponseAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAuditAttachmentsSuccessResponseAttachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'artifactUrl': json['artifactUrl'],
        'createdAt': (new Date(json['createdAt'])),
        'filename': json['filename'],
        'id': json['id'],
        'type': !exists(json, 'type') ? undefined : AuditAttachmentTypeFromJSON(json['type']),
    };
}

export function GetAuditAttachmentsSuccessResponseAttachmentToJSON(value?: GetAuditAttachmentsSuccessResponseAttachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'artifactUrl': value.artifactUrl,
        'createdAt': (value.createdAt.toISOString()),
        'filename': value.filename,
        'id': value.id,
        'type': AuditAttachmentTypeToJSON(value.type),
    };
}

