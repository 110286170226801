/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetClaimRecoveriesSuccessResponseRecovery } from './GetClaimRecoveriesSuccessResponseRecovery';
import {
    GetClaimRecoveriesSuccessResponseRecoveryFromJSON,
    GetClaimRecoveriesSuccessResponseRecoveryFromJSONTyped,
    GetClaimRecoveriesSuccessResponseRecoveryToJSON,
} from './GetClaimRecoveriesSuccessResponseRecovery';

/**
 * 
 * @export
 * @interface GetClaimRecoveriesSuccessResponse
 */
export interface GetClaimRecoveriesSuccessResponse {
    /**
     * 
     * @type {Array<GetClaimRecoveriesSuccessResponseRecovery>}
     * @memberof GetClaimRecoveriesSuccessResponse
     */
    recoveries: Array<GetClaimRecoveriesSuccessResponseRecovery>;
}

/**
 * Check if a given object implements the GetClaimRecoveriesSuccessResponse interface.
 */
export function instanceOfGetClaimRecoveriesSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recoveries" in value;

    return isInstance;
}

export function GetClaimRecoveriesSuccessResponseFromJSON(json: any): GetClaimRecoveriesSuccessResponse {
    return GetClaimRecoveriesSuccessResponseFromJSONTyped(json, false);
}

export function GetClaimRecoveriesSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetClaimRecoveriesSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recoveries': ((json['recoveries'] as Array<any>).map(GetClaimRecoveriesSuccessResponseRecoveryFromJSON)),
    };
}

export function GetClaimRecoveriesSuccessResponseToJSON(value?: GetClaimRecoveriesSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recoveries': ((value.recoveries as Array<any>).map(GetClaimRecoveriesSuccessResponseRecoveryToJSON)),
    };
}

