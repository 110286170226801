import {Icon} from "@title-service/ui";
import React from "react";

const VIEW_BOX: React.ComponentProps<typeof Icon>["viewBox"] = {
  width: 14,
  height: 18,
};

export const FlagIcon: React.FC<React.ComponentProps<typeof Icon>> = (
  props,
) => (
  <Icon viewBox={VIEW_BOX} {...props}>
    <path
      d="M0.998535 2.5L3.15238 1.60256C5.61392 0.576923 8.38315 0.576923 10.8447 1.60256L12.9985 2.5L12.9985 17.5L6.99854 13.4985L0.998535 17.5L0.998535 2.5Z"
      fill="currentColor"
    />
  </Icon>
);
