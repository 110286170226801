import {
  ApprovePropertyDocumentLegalDescriptionRequest,
  ApprovePropertyDocumentVestingInformationRequest,
  CloseClaimRequest,
  Cpl,
  CreateAgencyProducerAppointmentRequest,
  CreateAgencyProducerLicenseRequest,
  CreateAgencyProducerNoteRequest,
  CreateAgencyProducerRequest,
  CreateAgencyProducerSuccessResponse,
  CreateAuditAttachmentRequest,
  CreateAuditRequest,
  CreateAuditSuccessResponse,
  CreateClaimAttachmentRequest,
  CreateClaimNoteRequest,
  CreateClaimRecoveryRequest,
  CreateClaimRequest,
  CreateClaimReserveRequest,
  CreateClaimSuccessResponse,
  CreateClaimTransactionRequest,
  CreateIndividualProducerAppointmentRequest,
  CreateIndividualProducerLicenseRequest,
  CreateIndividualProducerNoteRequest,
  CreateIndividualProducerRequest,
  CreateIndividualProducerSuccessResponse,
  CreateOrderCplRequest,
  CreateOrderCplSuccessResponse,
  CreateOrderUnderwritingAttachmentRequest,
  CreateOrderUnderwritingNoteRequest,
  CreateRemittanceRequest,
  CreateValidationReportAcceptedResponse,
  CreateValidationReportRequest,
  DeactivateAgencyProducerRequest,
  DeactivateIndividualProducerRequest,
  DeactivateProducerJurisdictionRequest,
  EditPropertyDocumentLegalDescriptionRequest,
  EditPropertyDocumentVestingInformationRequest,
  ExportAuditFinalReportsAcceptedResponse,
  ExportAuditFinalReportsRequest,
  FlagOrderRequest,
  FlagPropertyDocumentRequest,
  GetAgencyProducerJurisdictionsRequest,
  GetAgencyProducerNotesRequest,
  GetAgencyProducerRequest,
  GetAgencyProducersAutocompleteRequest,
  GetAgencyProducersAutocompleteSuccessResponse,
  GetAgencyProducersRequest,
  GetAgencyProducersSuccessResponse,
  GetAgencyProducerSuccessResponse,
  GetAuditAttachmentsRequest,
  GetAuditAttachmentsSuccessResponse,
  GetAuditExportItemsRequest,
  GetAuditExportItemsSuccessResponse,
  GetAuditExportRequest,
  GetAuditExportsRequest,
  GetAuditExportsSuccessResponse,
  GetAuditExportSuccessResponse,
  GetAuditRequest,
  GetAuditsRequest,
  GetAuditsSuccessResponse,
  GetAuditSuccessResponse,
  GetClaimAttachmentsRequest,
  GetClaimAttachmentsSuccessResponse,
  GetClaimNotesRequest,
  GetClaimNotesSuccessResponse,
  GetClaimRecoveriesRequest,
  GetClaimRecoveriesSuccessResponse,
  GetClaimRequest,
  GetClaimReservesRequest,
  GetClaimReservesSuccessResponse,
  GetClaimsSuccessResponse,
  GetClaimSuccessResponse,
  GetClaimTransactionsRequest,
  GetClaimTransactionsSuccessResponse,
  GetCplRequest,
  GetCplsRequest,
  GetCplsSuccessResponse,
  GetIndividualProducerJurisdictionsRequest,
  GetIndividualProducerNotesRequest,
  GetIndividualProducerRequest,
  GetIndividualProducersRequest,
  GetIndividualProducersSuccessResponse,
  GetIndividualProducerSuccessResponse,
  GetInsurfulOrderResultRequest,
  GetJurisdictionsAutocompleteRequest,
  GetJurisdictionsAutocompleteSuccessResponse,
  GetOrderRemittanceItemsRequest,
  GetOrderRemittanceItemsSuccessResponse,
  GetOrderRequest,
  GetOrdersRequest,
  GetOrdersSuccessResponse,
  GetOrderSuccessResponse,
  GetOrderUnderwritingAttachmentsRequest,
  GetOrderUnderwritingAttachmentsSuccessResponse,
  GetOrderUnderwritingNotesRequest,
  GetOrderUnderwritingNotesSuccessResponse,
  GetParentAgenciesSuccessResponse,
  GetPoliciesRequest,
  GetPoliciesSuccessResponse,
  GetPolicyRequest,
  GetPredictionRequest,
  GetPredictionSuccessResponse,
  GetProducerJurisdictionsSuccessResponse,
  GetProducerNotesSuccessResponse,
  GetProducersExpiringSuccessResponse,
  GetPropertyDocumentIdsRequest,
  GetPropertyDocumentIdsSuccessResponse,
  GetPropertyDocumentRequest,
  GetPropertyDocumentsRequest,
  GetPropertyDocumentsSuccessResponse,
  GetPropertyDocumentSuccessResponse,
  GetPropertyRequest,
  GetPropertySuccessResponse,
  GetRemittanceItemsRequest,
  GetRemittanceItemsSuccessResponse,
  GetTrainedModelsSuccessResponse,
  GetValidationReportRequest,
  GetValidationReportsSuccessResponse,
  InsurfulOrderResult,
  Policy,
  ReactivateProducerJurisdictionRequest,
  RejectPropertyDocumentLegalDescriptionRequest,
  RejectPropertyDocumentVestingInformationRequest,
  RenewAgencyProducerLicenseRequest,
  RenewIndividualProducerLicenseRequest,
  SearchPropertiesRequest,
  SearchPropertiesResponse,
  TrainModelAcceptedResponse,
  TrainModelRequest,
  UnApprovePropertyDocumentLegalDescriptionRequest,
  UnApprovePropertyDocumentVestingInformationRequest,
  UnflagOrderRequest,
  UnflagPropertyDocumentRequest,
  UnRejectPropertyDocumentLegalDescriptionRequest,
  UnRejectPropertyDocumentVestingInformationRequest,
  UpdateAgencyProducerRequest,
  UpdateAuditAttachmentRequest,
  UpdateAuditRequest,
  UpdateClaimNoteRequest,
  UpdateClaimRequest,
  UpdateIndividualProducerRequest,
  UpdateOrderUnderwritingNoteRequest,
  UpdateProducerNoteRequest,
  UpdateRemittanceRequest,
  ValidationReportDetail,
  VoidClaimTransactionRequest,
} from "./models";

export class DeactivateAgencyProducerRequestUnprocessableResponseError extends Error {}

export class DeactivateIndividualProducerRequestUnprocessableResponseError extends Error {}

export interface AdminApi {
  getOrders: (
    request: GetOrdersRequest,
    signal?: AbortSignal,
  ) => Promise<GetOrdersSuccessResponse>;

  getOrder: (
    request: GetOrderRequest,
    signal?: AbortSignal,
  ) => Promise<GetOrderSuccessResponse>;

  getOrderRemittanceItems: (
    request: GetOrderRemittanceItemsRequest,
    signal?: AbortSignal,
  ) => Promise<GetOrderRemittanceItemsSuccessResponse>;

  createRemittance: (request: CreateRemittanceRequest) => Promise<void>;

  updateRemittance: (request: UpdateRemittanceRequest) => Promise<void>;

  getRemittanceItems: (
    request: GetRemittanceItemsRequest,
    signal?: AbortSignal,
  ) => Promise<GetRemittanceItemsSuccessResponse>;

  getCpls: (
    request: GetCplsRequest,
    signal?: AbortSignal,
  ) => Promise<GetCplsSuccessResponse>;

  getCpl: (request: GetCplRequest, signal?: AbortSignal) => Promise<Cpl>;

  createOrderCpl: (
    request: CreateOrderCplRequest,
  ) => Promise<CreateOrderCplSuccessResponse>;

  getPolicies: (
    request: GetPoliciesRequest,
    signal?: AbortSignal,
  ) => Promise<GetPoliciesSuccessResponse>;

  getPolicy: (
    request: GetPolicyRequest,
    signal?: AbortSignal,
  ) => Promise<Policy>;

  flagOrder: (request: FlagOrderRequest) => Promise<void>;

  unflagOrder: (request: UnflagOrderRequest) => Promise<void>;

  getPrediction: (
    request: GetPredictionRequest,
    signal?: AbortSignal,
  ) => Promise<GetPredictionSuccessResponse>;

  searchProperties: (
    request: SearchPropertiesRequest,
    signal?: AbortSignal,
  ) => Promise<SearchPropertiesResponse>;

  getProperty: (
    request: GetPropertyRequest,
    signal?: AbortSignal,
  ) => Promise<GetPropertySuccessResponse>;

  getInsurfulOrderResult: (
    request: GetInsurfulOrderResultRequest,
    signal?: AbortSignal,
  ) => Promise<InsurfulOrderResult>;

  getValidationReports: (
    signal?: AbortSignal,
  ) => Promise<GetValidationReportsSuccessResponse>;

  getValidationReport: (
    request: GetValidationReportRequest,
    signal?: AbortSignal,
  ) => Promise<ValidationReportDetail>;

  createValidationReport: (
    request: CreateValidationReportRequest,
  ) => Promise<CreateValidationReportAcceptedResponse>;

  getTrainedModels: (
    signal?: AbortSignal,
  ) => Promise<GetTrainedModelsSuccessResponse>;

  trainModel: (
    request: TrainModelRequest,
  ) => Promise<TrainModelAcceptedResponse>;

  getPropertyDocuments: (
    request: GetPropertyDocumentsRequest,
    signal?: AbortSignal,
  ) => Promise<GetPropertyDocumentsSuccessResponse>;

  getPropertyDocumentIds: (
    request: GetPropertyDocumentIdsRequest,
    signal?: AbortSignal,
  ) => Promise<GetPropertyDocumentIdsSuccessResponse>;

  getPropertyDocument: (
    request: GetPropertyDocumentRequest,
    signal?: AbortSignal,
  ) => Promise<GetPropertyDocumentSuccessResponse>;

  editPropertyDocumentLegalDescription: (
    request: EditPropertyDocumentLegalDescriptionRequest,
  ) => Promise<void>;

  editPropertyDocumentVestingInformation: (
    request: EditPropertyDocumentVestingInformationRequest,
  ) => Promise<void>;

  approvePropertyDocumentLegalDescription: (
    request: ApprovePropertyDocumentLegalDescriptionRequest,
  ) => Promise<void>;

  unApprovePropertyDocumentLegalDescription: (
    request: UnApprovePropertyDocumentLegalDescriptionRequest,
  ) => Promise<void>;

  rejectPropertyDocumentLegalDescription: (
    request: RejectPropertyDocumentLegalDescriptionRequest,
  ) => Promise<void>;

  unRejectPropertyDocumentLegalDescription: (
    request: UnRejectPropertyDocumentLegalDescriptionRequest,
  ) => Promise<void>;

  approvePropertyDocumentVestingInformation: (
    request: ApprovePropertyDocumentVestingInformationRequest,
  ) => Promise<void>;

  unApprovePropertyDocumentVestingInformation: (
    request: UnApprovePropertyDocumentVestingInformationRequest,
  ) => Promise<void>;

  rejectPropertyDocumentVestingInformation: (
    request: RejectPropertyDocumentVestingInformationRequest,
  ) => Promise<void>;

  unRejectPropertyDocumentVestingInformation: (
    request: UnRejectPropertyDocumentVestingInformationRequest,
  ) => Promise<void>;

  flagPropertyDocument: (request: FlagPropertyDocumentRequest) => Promise<void>;

  unflagPropertyDocument: (
    request: UnflagPropertyDocumentRequest,
  ) => Promise<void>;

  createClaim: (
    createClaimRequest: CreateClaimRequest,
  ) => Promise<CreateClaimSuccessResponse>;

  updateClaim: (updateClaimRequest: UpdateClaimRequest) => Promise<void>;

  closeClaim: (
    closeClaimRequest: CloseClaimRequest,
    signal?: AbortSignal,
  ) => Promise<void>;

  getClaims: (signal?: AbortSignal) => Promise<GetClaimsSuccessResponse>;

  getClaim: (
    getClaimRequest: GetClaimRequest,
    signal?: AbortSignal,
  ) => Promise<GetClaimSuccessResponse>;

  getClaimTransactions: (
    getClaimTransactionsRequest: GetClaimTransactionsRequest,
    signal?: AbortSignal,
  ) => Promise<GetClaimTransactionsSuccessResponse>;

  createClaimTransaction: (
    createClaimTransactionRequest: CreateClaimTransactionRequest,
  ) => Promise<void>;

  voidClaimTransaction: (
    voidClaimTransactionRequest: VoidClaimTransactionRequest,
    signal?: AbortSignal,
  ) => Promise<void>;

  getClaimReserves: (
    getClaimReservesRequest: GetClaimReservesRequest,
    signal?: AbortSignal,
  ) => Promise<GetClaimReservesSuccessResponse>;

  createClaimReserve: (
    createClaimReserveRequest: CreateClaimReserveRequest,
  ) => Promise<void>;

  getClaimRecoveries: (
    getClaimRecoveriesRequest: GetClaimRecoveriesRequest,
    signal?: AbortSignal,
  ) => Promise<GetClaimRecoveriesSuccessResponse>;

  createClaimRecovery: (
    createClaimRecoveryRequest: CreateClaimRecoveryRequest,
  ) => Promise<void>;

  getClaimAttachments: (
    getClaimAttachmentsRequest: GetClaimAttachmentsRequest,
    signal?: AbortSignal,
  ) => Promise<GetClaimAttachmentsSuccessResponse>;

  createClaimAttachment: (
    createClaimAttachmentRequest: CreateClaimAttachmentRequest,
    signal?: AbortSignal,
  ) => Promise<void>;

  getClaimNotes: (
    getClaimNotesRequest: GetClaimNotesRequest,
    signal?: AbortSignal,
  ) => Promise<GetClaimNotesSuccessResponse>;

  createClaimNote: (
    createClaimNoteRequest: CreateClaimNoteRequest,
  ) => Promise<void>;

  updateClaimNote: (
    updateClaimNoteRequest: UpdateClaimNoteRequest,
  ) => Promise<void>;

  createAudit: (
    createAuditRequest: CreateAuditRequest,
  ) => Promise<CreateAuditSuccessResponse>;

  getAudits: (
    getAuditsRequest: GetAuditsRequest,
    signal?: AbortSignal,
  ) => Promise<GetAuditsSuccessResponse>;

  updateAudit: (updateAuditRequest: UpdateAuditRequest) => Promise<void>;

  getAudit: (
    getAuditRequest: GetAuditRequest,
    signal?: AbortSignal,
  ) => Promise<GetAuditSuccessResponse>;

  getAuditAttachments: (
    getAuditAttachmentsRequest: GetAuditAttachmentsRequest,
    signal?: AbortSignal,
  ) => Promise<GetAuditAttachmentsSuccessResponse>;

  createAuditAttachment: (
    createAuditAttachmentRequest: CreateAuditAttachmentRequest,
  ) => Promise<void>;

  updateAuditAttachment: (
    updateAuditAttachmentRequest: UpdateAuditAttachmentRequest,
  ) => Promise<void>;

  exportAuditFinalReports: (
    exportAuditFinalReportsRequest: ExportAuditFinalReportsRequest,
  ) => Promise<ExportAuditFinalReportsAcceptedResponse>;

  getAuditExports: (
    getAuditExportsRequest: GetAuditExportsRequest,
    signal?: AbortSignal,
  ) => Promise<GetAuditExportsSuccessResponse>;

  getAuditExport: (
    getAuditExportRequest: GetAuditExportRequest,
    signal?: AbortSignal,
  ) => Promise<GetAuditExportSuccessResponse>;

  getAuditExportItems: (
    getAuditExportItemsRequest: GetAuditExportItemsRequest,
    signal?: AbortSignal,
  ) => Promise<GetAuditExportItemsSuccessResponse>;

  getAgencyProducers: (
    getAgencyProducersRequest: GetAgencyProducersRequest,
    signal?: AbortSignal,
  ) => Promise<GetAgencyProducersSuccessResponse>;

  getParentAgencies: (
    signal?: AbortSignal,
  ) => Promise<GetParentAgenciesSuccessResponse>;

  getAgencyProducer: (
    getAgencyProducerRequest: GetAgencyProducerRequest,
    signal?: AbortSignal,
  ) => Promise<GetAgencyProducerSuccessResponse>;

  getProducersExpiring: (
    signal?: AbortSignal,
  ) => Promise<GetProducersExpiringSuccessResponse>;

  getAgencyProducersAutocomplete: (
    getAgencyProducersAutocompleteRequest: GetAgencyProducersAutocompleteRequest,
    signal?: AbortSignal,
  ) => Promise<GetAgencyProducersAutocompleteSuccessResponse>;

  createAgencyProducer: (
    createAgencyProducerRequest: CreateAgencyProducerRequest,
  ) => Promise<CreateAgencyProducerSuccessResponse>;

  updateAgencyProducer: (
    updateAgencyProducerRequest: UpdateAgencyProducerRequest,
  ) => Promise<void>;

  getIndividualProducers: (
    getIndividualProducersRequest: GetIndividualProducersRequest,
    signal?: AbortSignal,
  ) => Promise<GetIndividualProducersSuccessResponse>;

  getIndividualProducer: (
    getIndividualProducerRequest: GetIndividualProducerRequest,
    signal?: AbortSignal,
  ) => Promise<GetIndividualProducerSuccessResponse>;

  createIndividualProducer: (
    createIndividualProducerRequest: CreateIndividualProducerRequest,
  ) => Promise<CreateIndividualProducerSuccessResponse>;

  updateIndividualProducer: (
    updateIndividualProducerRequest: UpdateIndividualProducerRequest,
  ) => Promise<void>;

  getAgencyProducerJurisdictions: (
    getAgencyProducerJurisdictionsRequest: GetAgencyProducerJurisdictionsRequest,
    signal?: AbortSignal,
  ) => Promise<GetProducerJurisdictionsSuccessResponse>;

  getIndividualProducerJurisdictions: (
    getIndividualProducerJurisdictionsRequest: GetIndividualProducerJurisdictionsRequest,
    signal?: AbortSignal,
  ) => Promise<GetProducerJurisdictionsSuccessResponse>;

  getJurisdictionsAutocomplete: (
    getJurisdictionsAutocompleteRequest: GetJurisdictionsAutocompleteRequest,
    signal?: AbortSignal,
  ) => Promise<GetJurisdictionsAutocompleteSuccessResponse>;

  getJurisdictionsWithUnfulfilledAppointments: (
    signal?: AbortSignal,
  ) => Promise<GetProducerJurisdictionsSuccessResponse>;

  deactivateProducerJurisdiction: (
    deactivateProducerJurisdictionRequest: DeactivateProducerJurisdictionRequest,
  ) => Promise<void>;

  reactivateProducerJurisdiction: (
    reactivateProducerJurisdictionRequest: ReactivateProducerJurisdictionRequest,
  ) => Promise<void>;

  deactivateIndividualProducer: (
    deactivateIndividualProducerRequest: DeactivateIndividualProducerRequest,
  ) => Promise<void>;

  deactivateAgencyProducer: (
    deactivateAgencyProducerRequest: DeactivateAgencyProducerRequest,
  ) => Promise<void>;

  createAgencyProducerLicense: (
    createAgencyProducerLicenseRequest: CreateAgencyProducerLicenseRequest,
  ) => Promise<void>;

  createIndividualProducerLicense: (
    createIndividualProducerLicenseRequest: CreateIndividualProducerLicenseRequest,
  ) => Promise<void>;

  renewAgencyProducerLicense: (
    renewAgencyProducerLicenseRequest: RenewAgencyProducerLicenseRequest,
  ) => Promise<void>;

  renewIndividualProducerLicense: (
    renewIndividualProducerLicenseRequest: RenewIndividualProducerLicenseRequest,
  ) => Promise<void>;

  createAgencyProducerAppointment: (
    createAgencyProducerAppointmentRequest: CreateAgencyProducerAppointmentRequest,
  ) => Promise<void>;

  createIndividualProducerAppointment: (
    createIndividualProducerAppointmentRequest: CreateIndividualProducerAppointmentRequest,
  ) => Promise<void>;

  getOrderUnderwritingNotes: (
    getOrderUnderwritingNotesRequest: GetOrderUnderwritingNotesRequest,
    signal?: AbortSignal,
  ) => Promise<GetOrderUnderwritingNotesSuccessResponse>;

  createOrderUnderwritingNote: (
    createOrderUnderwritingNoteRequest: CreateOrderUnderwritingNoteRequest,
  ) => Promise<void>;

  updateOrderUnderwritingNote: (
    updateOrderUnderwritingNoteRequest: UpdateOrderUnderwritingNoteRequest,
  ) => Promise<void>;

  getOrderUnderwritingAttachments: (
    getOrderUnderwritingAttachmentsRequest: GetOrderUnderwritingAttachmentsRequest,
    signal?: AbortSignal,
  ) => Promise<GetOrderUnderwritingAttachmentsSuccessResponse>;

  createOrderUnderwritingAttachment: (
    createOrderUnderwritingAttachmentRequest: CreateOrderUnderwritingAttachmentRequest,
  ) => Promise<void>;

  getAgencyProducerNotes: (
    getAgencyProducerNotesRequest: GetAgencyProducerNotesRequest,
    signal?: AbortSignal,
  ) => Promise<GetProducerNotesSuccessResponse>;
  getIndividualProducerNotes: (
    getIndividualProducerNotesRequest: GetIndividualProducerNotesRequest,
    signal?: AbortSignal,
  ) => Promise<GetProducerNotesSuccessResponse>;

  createAgencyProducerNote: (
    createAgencyProducerNoteRequest: CreateAgencyProducerNoteRequest,
  ) => Promise<void>;

  createIndividualProducerNote: (
    createIndividualProducerNoteRequest: CreateIndividualProducerNoteRequest,
  ) => Promise<void>;

  updateProducerNote: (
    updateProducerNoteRequest: UpdateProducerNoteRequest,
  ) => Promise<void>;
}
