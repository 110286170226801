/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetProducersExpiringSuccessResponseItem } from './GetProducersExpiringSuccessResponseItem';
import {
    GetProducersExpiringSuccessResponseItemFromJSON,
    GetProducersExpiringSuccessResponseItemFromJSONTyped,
    GetProducersExpiringSuccessResponseItemToJSON,
} from './GetProducersExpiringSuccessResponseItem';

/**
 * 
 * @export
 * @interface GetProducersExpiringSuccessResponse
 */
export interface GetProducersExpiringSuccessResponse {
    /**
     * 
     * @type {Array<GetProducersExpiringSuccessResponseItem>}
     * @memberof GetProducersExpiringSuccessResponse
     */
    items: Array<GetProducersExpiringSuccessResponseItem>;
}

/**
 * Check if a given object implements the GetProducersExpiringSuccessResponse interface.
 */
export function instanceOfGetProducersExpiringSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function GetProducersExpiringSuccessResponseFromJSON(json: any): GetProducersExpiringSuccessResponse {
    return GetProducersExpiringSuccessResponseFromJSONTyped(json, false);
}

export function GetProducersExpiringSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProducersExpiringSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(GetProducersExpiringSuccessResponseItemFromJSON)),
    };
}

export function GetProducersExpiringSuccessResponseToJSON(value?: GetProducersExpiringSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(GetProducersExpiringSuccessResponseItemToJSON)),
    };
}

