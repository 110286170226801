import React from "react";

import {Text, TextRaw} from "./Text";

type DateTimeComponentProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  locale?: Intl.LocalesArgument;
  // eslint-disable-next-line react/no-unused-prop-types
  timeZone?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  value: Date;
  // eslint-disable-next-line react/no-unused-prop-types
  children?: undefined;
};

const dateTimeToLocaleString = ({
  value,
  locale,
  timeZone,
}: DateTimeComponentProps): string =>
  value.toLocaleString(locale, {
    timeZone,
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  });

export const DateTimeComponentRaw: React.FC<DateTimeComponentProps> = (
  props,
) => <TextRaw value={dateTimeToLocaleString(props)} />;

export const DateTimeComponent: React.FC<
  Omit<React.ComponentType<typeof Text>, "value"> & DateTimeComponentProps
> = (props) => <Text {...props} value={dateTimeToLocaleString(props)} />;

if (import.meta.vitest) {
  const {describe, expect, it} = import.meta.vitest;
  const {render} = await import("@testing-library/react");

  describe("DateTimeComponent", () => {
    it("displays a locale date object as an expanded date", () => {
      const date = new Date("2019-09-10T01:01:15.1234Z");

      const {container} = render(
        <DateTimeComponentRaw
          value={date}
          locale="en-US"
          timeZone="America/Chicago"
        />,
      );

      expect(container).toHaveTextContent("Sep 9, 2019, 8:01 PM");
    });
  });
}
