/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderPermissions
 */
export interface OrderPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof OrderPermissions
     */
    createCpl: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPermissions
     */
    reportRemittance: boolean;
}

/**
 * Check if a given object implements the OrderPermissions interface.
 */
export function instanceOfOrderPermissions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createCpl" in value;
    isInstance = isInstance && "reportRemittance" in value;

    return isInstance;
}

export function OrderPermissionsFromJSON(json: any): OrderPermissions {
    return OrderPermissionsFromJSONTyped(json, false);
}

export function OrderPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderPermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createCpl': json['createCpl'],
        'reportRemittance': json['reportRemittance'],
    };
}

export function OrderPermissionsToJSON(value?: OrderPermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createCpl': value.createCpl,
        'reportRemittance': value.reportRemittance,
    };
}

