import {QueryFunction, useQuery, useQueryClient} from "@tanstack/react-query";
import {isLoadingNextQuery, Query} from "@title-service/react-query-utils";
import {
  DateComponent,
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
  Text,
} from "@title-service/ui";
import React, {useCallback, useContext, useMemo, useState} from "react";

import {DependencyContext} from "../../DependencyContext";
import {
  GetProducersExpiringSuccessResponse,
  GetProducersExpiringSuccessResponseItemAppointment,
  GetProducersExpiringSuccessResponseItemLicense,
  GetProducersExpiringType,
} from "../adminApi";
import {LoadingOverlay} from "../components/LoadingOverlay";
import {Modal} from "../components/Modal";
import {RenewIcon} from "../components/icons";
import {
  BodyRow,
  HeaderRow,
  Table,
  TBody,
  Td,
  Th,
  THead,
} from "../components/tables/PrimaryTable";

import {CreateProducerAppointmentForm} from "./ProducerAppointmentSection";
import {buildProducerId} from "./ProducerId";
import {RenewProducerLicenseForm} from "./ProducerLicenseSection";
import {ProducerLink, ProducerTypeComponent} from "./ProducerType";

const MODAL_CONTENT_STYLE: React.CSSProperties = {
  minWidth: "650px",
};

type GetProducersExpiringQueryKey = ["producers", "expiring"];
export type GetProducersExpiringQuery =
  Query<GetProducersExpiringSuccessResponse>;

export const useGetProducersExpiring = (): {
  getProducersExpiringQuery: GetProducersExpiringQuery;
  onReloadProducersExpiring: () => any;
} => {
  const {adminApi} = useContext(DependencyContext);
  const queryClient = useQueryClient();
  const getProducersExpiringQueryKey: GetProducersExpiringQueryKey = useMemo(
    () => ["producers", "expiring"],
    [],
  );
  const getProducersExpiringQueryFn: QueryFunction<
    GetProducersExpiringSuccessResponse,
    GetProducersExpiringQueryKey
  > = useCallback(
    ({signal}) => adminApi.getProducersExpiring(signal),
    [adminApi],
  );
  const getProducersExpiringQuery = useQuery({
    keepPreviousData: true,
    queryKey: getProducersExpiringQueryKey,
    queryFn: getProducersExpiringQueryFn,
  });
  const onReloadProducersExpiring = useCallback(
    () =>
      queryClient.invalidateQueries({queryKey: getProducersExpiringQueryKey}),
    [queryClient, getProducersExpiringQueryKey],
  );
  return {
    getProducersExpiringQuery,
    onReloadProducersExpiring,
  };
};

export const ExpiringSoonSection: React.FC<{
  ExpiringSoonSectionQueryResult?: typeof ExpiringSoonSectionQueryResult;
}> = ({
  ExpiringSoonSectionQueryResult:
    ExpiringSoonSectionQueryResult_ = ExpiringSoonSectionQueryResult,
}) => {
  const {getProducersExpiringQuery, onReloadProducersExpiring} =
    useGetProducersExpiring();
  return (
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Expiring Soon" />
      </SecondarySectionHeaderContainer>
      <SecondarySectionContent>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <ExpiringSoonSectionQueryResult_
          getProducersExpiringQuery={getProducersExpiringQuery}
          onReloadProducersExpiring={onReloadProducersExpiring}
        />
      </SecondarySectionContent>
    </SecondarySection>
  );
};

export const ExpiringSoonSectionQueryResult: React.FC<{
  getProducersExpiringQuery: GetProducersExpiringQuery;
  onReloadProducersExpiring: () => any;
  ExpiringSoonSectionSuccessComponent?: typeof ExpiringSoonSectionSuccessComponent;
  ExpiringSoonSectionFailureComponent?: typeof ExpiringSoonSectionFailureComponent;
  ExpiringSoonSectionLoadingComponent?: typeof ExpiringSoonSectionLoadingComponent;
}> = ({
  getProducersExpiringQuery,
  getProducersExpiringQuery: {data, error, isInitialLoading},
  onReloadProducersExpiring,
  ExpiringSoonSectionSuccessComponent:
    ExpiringSoonSectionSuccessComponent_ = ExpiringSoonSectionSuccessComponent,
  ExpiringSoonSectionFailureComponent:
    ExpiringSoonSectionFailureComponent_ = ExpiringSoonSectionFailureComponent,
  ExpiringSoonSectionLoadingComponent:
    ExpiringSoonSectionLoadingComponent_ = ExpiringSoonSectionLoadingComponent,
}) => {
  const isLoading = isLoadingNextQuery(getProducersExpiringQuery);
  return (
    <LoadingOverlay isActive={isLoading}>
      {data ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <ExpiringSoonSectionSuccessComponent_
          response={data}
          onReloadProducersExpiring={onReloadProducersExpiring}
        />
      ) : error ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <ExpiringSoonSectionFailureComponent_ />
      ) : (
        // eslint-disable-next-line react/jsx-pascal-case
        isInitialLoading && <ExpiringSoonSectionLoadingComponent_ />
      )}
    </LoadingOverlay>
  );
};

export const ExpiringSoonSectionLoadingComponent: React.FC = () => (
  <Text value="Loading ..." />
);

export const ExpiringSoonSectionFailureComponent: React.FC = () => (
  <Text value="Failed to load." />
);

export const ExpiringSoonSectionSuccessComponent: React.FC<{
  response: GetProducersExpiringSuccessResponse;
  onReloadProducersExpiring: () => any;
}> = ({response: {items}, onReloadProducersExpiring}) => {
  if (!items.length) {
    return <Text value="Nothing expiring soon" />;
  }

  return (
    <Table width="100%">
      <THead>
        <HeaderRow>
          <Th>Producer Type</Th>
          <Th>Name</Th>
          <Th>Item Expiring</Th>
          <Th>Jurisdiction</Th>
          <Th>Expiration Date</Th>
          <Th />
        </HeaderRow>
      </THead>
      <TBody>
        {items.map((item, i) => {
          switch (item.type) {
            case GetProducersExpiringType.Appointment:
              return (
                <ExpiringSoonSectionItemRowAppointment
                  key={i}
                  item={item}
                  onReloadProducersExpiring={onReloadProducersExpiring}
                />
              );
            case GetProducersExpiringType.License:
              return (
                <ExpiringSoonSectionItemRowLicense
                  key={i}
                  item={item}
                  onReloadProducersExpiring={onReloadProducersExpiring}
                />
              );
            default:
              return null;
          }
        })}
      </TBody>
    </Table>
  );
};

export const ExpiringSoonSectionItemRowLicense: React.FC<{
  ariaHideApp?: boolean;
  item: GetProducersExpiringSuccessResponseItemLicense;
  onReloadProducersExpiring: () => any;
  RenewProducerLicenseForm?: typeof RenewProducerLicenseForm;
}> = ({
  ariaHideApp,
  item,
  onReloadProducersExpiring,
  RenewProducerLicenseForm:
    RenewProducerLicenseForm_ = RenewProducerLicenseForm,
}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);
  const producerId = useMemo(
    () => buildProducerId(item.producerType, item.producerId),
    [item.producerType, item.producerId],
  );
  return (
    <BodyRow>
      <Td>
        <ProducerTypeComponent producerType={item.producerType} />
      </Td>
      <Td>
        <ProducerLink
          producerType={item.producerType}
          producerId={item.producerId}
          producerName={item.producerName}
        />
      </Td>
      <Td>
        <Text value="License" />
      </Td>
      <Td>
        <Text value={item.jurisdictionName} />
      </Td>
      <Td>
        <DateComponent value={item.license.expirationDate} />
      </Td>
      <Td>
        <RenewIcon data-testid="renew-license-button" onClick={openForm} />
        <Modal
          ariaHideApp={ariaHideApp}
          contentStyle={MODAL_CONTENT_STYLE}
          isOpen={displayForm}
          onClose={closeForm}
          title="Renew License"
        >
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <RenewProducerLicenseForm_
            producerId={producerId}
            mostRecentLicense={item.license}
            onClose={closeForm}
            onSubmitComplete={onReloadProducersExpiring}
          />
        </Modal>
      </Td>
    </BodyRow>
  );
};

export const ExpiringSoonSectionItemRowAppointment: React.FC<{
  ariaHideApp?: boolean;
  item: GetProducersExpiringSuccessResponseItemAppointment;
  onReloadProducersExpiring: () => any;
  CreateProducerAppointmentForm?: typeof CreateProducerAppointmentForm;
}> = ({
  ariaHideApp,
  item,
  onReloadProducersExpiring,
  CreateProducerAppointmentForm:
    CreateProducerAppointmentForm_ = CreateProducerAppointmentForm,
}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);
  const producerId = useMemo(
    () => buildProducerId(item.producerType, item.producerId),
    [item.producerType, item.producerId],
  );
  return (
    <BodyRow>
      <Td>
        <ProducerTypeComponent producerType={item.producerType} />
      </Td>
      <Td>
        <ProducerLink
          producerType={item.producerType}
          producerId={item.producerId}
          producerName={item.producerName}
        />
      </Td>
      <Td>
        <Text value="Appointment" />
      </Td>
      <Td>
        <Text value={item.jurisdictionName} />
      </Td>
      <Td>
        <DateComponent value={item.appointment.expirationDate} />
      </Td>
      <Td>
        <RenewIcon data-testid="renew-appointment-button" onClick={openForm} />
        <Modal
          ariaHideApp={ariaHideApp}
          contentStyle={MODAL_CONTENT_STYLE}
          isOpen={displayForm}
          onClose={closeForm}
          title="Renew Appointment"
        >
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <CreateProducerAppointmentForm_
            producerId={producerId}
            jurisdictionId={item.jurisdictionId}
            onClose={closeForm}
            onSubmitComplete={onReloadProducersExpiring}
          />
        </Modal>
      </Td>
    </BodyRow>
  );
};
