/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetProducersExpiringType } from './GetProducersExpiringType';
import {
    GetProducersExpiringTypeFromJSON,
    GetProducersExpiringTypeFromJSONTyped,
    GetProducersExpiringTypeToJSON,
} from './GetProducersExpiringType';
import type { ProducerType } from './ProducerType';
import {
    ProducerTypeFromJSON,
    ProducerTypeFromJSONTyped,
    ProducerTypeToJSON,
} from './ProducerType';

import {
     GetProducersExpiringSuccessResponseItemAppointmentFromJSONTyped,
     GetProducersExpiringSuccessResponseItemLicenseFromJSONTyped
} from './';

/**
 * 
 * @export
 * @interface GetProducersExpiringSuccessResponseItem
 */
export interface GetProducersExpiringSuccessResponseItem {
    /**
     * 
     * @type {string}
     * @memberof GetProducersExpiringSuccessResponseItem
     */
    jurisdictionId: string;
    /**
     * 
     * @type {string}
     * @memberof GetProducersExpiringSuccessResponseItem
     */
    jurisdictionName: string;
    /**
     * 
     * @type {string}
     * @memberof GetProducersExpiringSuccessResponseItem
     */
    producerId: string;
    /**
     * 
     * @type {string}
     * @memberof GetProducersExpiringSuccessResponseItem
     */
    producerName: string;
    /**
     * 
     * @type {ProducerType}
     * @memberof GetProducersExpiringSuccessResponseItem
     */
    producerType: ProducerType;
    /**
     * 
     * @type {GetProducersExpiringType}
     * @memberof GetProducersExpiringSuccessResponseItem
     */
    type: GetProducersExpiringType;
}

/**
 * Check if a given object implements the GetProducersExpiringSuccessResponseItem interface.
 */
export function instanceOfGetProducersExpiringSuccessResponseItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "jurisdictionId" in value;
    isInstance = isInstance && "jurisdictionName" in value;
    isInstance = isInstance && "producerId" in value;
    isInstance = isInstance && "producerName" in value;
    isInstance = isInstance && "producerType" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function GetProducersExpiringSuccessResponseItemFromJSON(json: any): GetProducersExpiringSuccessResponseItem {
    return GetProducersExpiringSuccessResponseItemFromJSONTyped(json, false);
}

export function GetProducersExpiringSuccessResponseItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProducersExpiringSuccessResponseItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['type'] === 'appointment') {
            return GetProducersExpiringSuccessResponseItemAppointmentFromJSONTyped(json, true);
        }
        if (json['type'] === 'license') {
            return GetProducersExpiringSuccessResponseItemLicenseFromJSONTyped(json, true);
        }
    }
    return {
        
        'jurisdictionId': json['jurisdictionId'],
        'jurisdictionName': json['jurisdictionName'],
        'producerId': json['producerId'],
        'producerName': json['producerName'],
        'producerType': ProducerTypeFromJSON(json['producerType']),
        'type': GetProducersExpiringTypeFromJSON(json['type']),
    };
}

export function GetProducersExpiringSuccessResponseItemToJSON(value?: GetProducersExpiringSuccessResponseItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jurisdictionId': value.jurisdictionId,
        'jurisdictionName': value.jurisdictionName,
        'producerId': value.producerId,
        'producerName': value.producerName,
        'producerType': ProducerTypeToJSON(value.producerType),
        'type': GetProducersExpiringTypeToJSON(value.type),
    };
}

