/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface Parcel
 */
export interface Parcel {
    /**
     * 
     * @type {Address}
     * @memberof Parcel
     */
    address: Address;
    /**
     * 
     * @type {number}
     * @memberof Parcel
     */
    amountOfInsurance: number;
}

/**
 * Check if a given object implements the Parcel interface.
 */
export function instanceOfParcel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "amountOfInsurance" in value;

    return isInstance;
}

export function ParcelFromJSON(json: any): Parcel {
    return ParcelFromJSONTyped(json, false);
}

export function ParcelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Parcel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': AddressFromJSON(json['address']),
        'amountOfInsurance': json['amountOfInsurance'],
    };
}

export function ParcelToJSON(value?: Parcel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': AddressToJSON(value.address),
        'amountOfInsurance': value.amountOfInsurance,
    };
}

