import {UseMutationResult} from "@tanstack/react-query";
import {FormikHelpers} from "formik";
import {useCallback} from "react";

export const useSubmitHandler = <TFormData, TResponse, TRequest>(
  mutation: UseMutationResult<TResponse, unknown, TRequest>,
  onClose: () => any,
  mapper: (formData: TFormData) => TRequest,
) =>
  useCallback(
    (formData: TFormData, {setSubmitting}: FormikHelpers<TFormData>) => {
      const request = mapper(formData);
      mutation.mutate(request, {
        onSuccess: () => {
          onClose();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [mapper, mutation, onClose],
  );
