import {Alert, Button, Flex, Placeholder} from "@aws-amplify/ui-react";
import {
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {isLoadingNextQuery, Query} from "@title-service/react-query-utils";
import {
  DateComponent,
  DateTimeComponent,
  FormikConnectedAmplifySelectField,
  FormikConnectedAmplifySubmitButton,
  FormikForm,
  HorizontalFieldset,
} from "@title-service/ui";
import {transformNullToUndefined} from "@title-service/yup-utils";
import {Formik, FormikHelpers, useFormikContext} from "formik";
import React, {useCallback, useContext, useState} from "react";
import * as Yup from "yup";

import {DependencyContext} from "../DependencyContext";
import {
  CreateValidationReportRequest,
  GetValidationReportsSuccessResponse,
  TrainedScoringModel,
} from "../shared/adminApi";
import {RouterLink} from "../shared/components/Link";
import {LoadingOverlay} from "../shared/components/LoadingOverlay";
import {Modal} from "../shared/components/Modal";
import {QuantityComponent} from "../shared/components/Quantity";
import {
  AboveTableContainer,
  BodyRow,
  HeaderRow,
  Table,
  TBody,
  Td,
  Th,
  THead,
} from "../shared/components/tables/PrimaryTable";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
} from "../shared/layout";

import {GetTrainedModelsQuery, useTrainedModelsQuery} from "./TrainedModels";

const MODAL_CONTENT_STYLE: React.CSSProperties = {
  minWidth: "650px",
};

type GetValidationReportsQueryKey = ["validationReports", "search"];
const GET_VALIDATION_REPORTS_QUERY_KEY: GetValidationReportsQueryKey = [
  "validationReports",
  "search",
];
type GetValidationReportsQuery = Query<GetValidationReportsSuccessResponse>;

export const ValidationReportsRoute: React.FC<{
  GetValidationReportsBodyContent?: typeof GetValidationReportsBodyContent;
}> = ({
  GetValidationReportsBodyContent:
    GetValidationReportsBodyContent_ = GetValidationReportsBodyContent,
}) => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);
  const getValidationReportsQueryFn: QueryFunction<
    GetValidationReportsSuccessResponse,
    GetValidationReportsQueryKey
  > = useCallback(
    ({signal}) => adminApi.getValidationReports(signal),
    [adminApi],
  );
  const getValidationReportsQuery = useQuery({
    queryKey: GET_VALIDATION_REPORTS_QUERY_KEY,
    keepPreviousData: true,
    queryFn: getValidationReportsQueryFn,
  });
  const onReloadValidationReports = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: GET_VALIDATION_REPORTS_QUERY_KEY,
      }),
    [queryClient],
  );
  return (
    <>
      <BodyHeader>
        <PrimaryBodyHeaderContainer>
          <PrimaryBodyHeader value="Scoring Model Validation Reports" />
        </PrimaryBodyHeaderContainer>
      </BodyHeader>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <GetValidationReportsBodyContent_
        getValidationReportsQuery={getValidationReportsQuery}
        onReloadValidationReports={onReloadValidationReports}
      />
    </>
  );
};

export const GetValidationReportsBodyContent: React.FC<{
  getValidationReportsQuery: GetValidationReportsQuery;
  onReloadValidationReports: () => any;
  ValidationReportsPagePlaceholderTableBody?: typeof ValidationReportsPagePlaceholderTableBody;
  ValidationReportsPageSuccessTableBody?: typeof ValidationReportsPageSuccessTableBody;
  CreateValidationReportFormLoader?: typeof CreateValidationReportFormLoader;
}> = ({
  getValidationReportsQuery,
  onReloadValidationReports,
  getValidationReportsQuery: {data, error, isInitialLoading},
  ValidationReportsPagePlaceholderTableBody:
    ValidationReportsPagePlaceholderTableBody_ = ValidationReportsPagePlaceholderTableBody,
  ValidationReportsPageSuccessTableBody:
    ValidationReportsPageSuccessTableBody_ = ValidationReportsPageSuccessTableBody,
  CreateValidationReportFormLoader:
    CreateValidationReportFormLoader_ = CreateValidationReportFormLoader,
}) => {
  const isLoading = isLoadingNextQuery(getValidationReportsQuery);
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);
  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);
  return (
    <BodyContent>
      {getValidationReportsQuery.data ? (
        <Modal
          isOpen={modalOpen}
          onClose={closeModal}
          contentStyle={MODAL_CONTENT_STYLE}
          title="Create Validation Report"
        >
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <CreateValidationReportFormLoader_
            onSubmitComplete={onReloadValidationReports}
          />
        </Modal>
      ) : null}
      <AboveTableContainer
        justifyContent={error || data ? "space-between" : "flex-end"}
      >
        {error ? (
          <Alert variation="error" isDismissible={false} hasIcon={true}>
            Failed to load Validation Reports
          </Alert>
        ) : data ? (
          <QuantityComponent
            quantity={data.reports.length}
            label={
              data.reports.length === 1
                ? "Validation Report"
                : "Validation Reports"
            }
          />
        ) : null}
        <Button
          variation="link"
          onClick={openModal}
          isDisabled={!getValidationReportsQuery.data}
        >
          Create Validation Report
        </Button>
      </AboveTableContainer>
      <LoadingOverlay isActive={isLoading}>
        <Table>
          <THead>
            <HeaderRow>
              <Th>Report ID</Th>
              <Th>State</Th>
              <Th>Trained At</Th>
              <Th>Validated On</Th>
            </HeaderRow>
          </THead>
          {data ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <ValidationReportsPageSuccessTableBody_ response={data} />
          ) : error ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <ValidationReportsPagePlaceholderTableBody_ isLoaded={true} />
          ) : (
            isInitialLoading && (
              // eslint-disable-next-line react/jsx-pascal-case
              <ValidationReportsPagePlaceholderTableBody_ isLoaded={false} />
            )
          )}
        </Table>
      </LoadingOverlay>
    </BodyContent>
  );
};

export const ValidationReportsPagePlaceholderTableBody: React.FC<{
  isLoaded: boolean;
}> = ({isLoaded}) => (
  <TBody>
    <BodyRow>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
    </BodyRow>
  </TBody>
);

export const ValidationReportsPageSuccessTableBody: React.FC<{
  response: GetValidationReportsSuccessResponse;
}> = ({response: {reports}}) => {
  if (!reports.length) {
    return (
      <TBody>
        <BodyRow>
          <Td colSpan={4}>No Reports found</Td>
        </BodyRow>
      </TBody>
    );
  }

  return (
    <TBody>
      {reports.map(({id, validatedOn, state, trainedAt}) => (
        <BodyRow key={id}>
          <Td>
            <RouterLink to={`/validationReports/${id}/`}>{id}</RouterLink>
          </Td>
          <Td>{state}</Td>
          <Td>
            <DateTimeComponent value={trainedAt} />
          </Td>
          <Td>
            <DateComponent value={validatedOn} />
          </Td>
        </BodyRow>
      ))}
    </TBody>
  );
};

type CreateValidationReportFormData = Required<CreateValidationReportRequest>;

const INITIAL_FORM_DATA: CreateValidationReportFormData = {
  state: "",
  modelVersion: "",
  baselineModelVersion: "",
};

export const FORM_SCHEMA: Yup.ObjectSchema<CreateValidationReportRequest> =
  Yup.object({
    state: Yup.string().trim().transform(transformNullToUndefined).required(),
    modelVersion: Yup.string()
      .trim()
      .transform(transformNullToUndefined)
      .required(),
    baselineModelVersion: Yup.string()
      .trim()
      .transform(transformNullToUndefined)
      .optional(),
  });

export const CreateValidationReportFormLoader: React.FC<
  Omit<
    React.ComponentProps<typeof CreateValidationReportForm>,
    "getTrainedModelsQuery"
  >
> = (props) => {
  const {getTrainedModelsQuery} = useTrainedModelsQuery();
  return (
    <CreateValidationReportForm
      getTrainedModelsQuery={getTrainedModelsQuery}
      {...props}
    />
  );
};

export const CreateValidationReportForm: React.FC<{
  onSubmitComplete: () => any;
  getTrainedModelsQuery: GetTrainedModelsQuery;
}> = ({getTrainedModelsQuery, onSubmitComplete}) => {
  const {adminApi} = useContext(DependencyContext);
  const createValidationReportMutation = useMutation({
    mutationFn: adminApi.createValidationReport,
  });
  const submitHandler = useCallback(
    (
      formData: CreateValidationReportFormData,
      {setSubmitting}: FormikHelpers<CreateValidationReportFormData>,
    ) => {
      const createValidationReportRequest = FORM_SCHEMA.validateSync(formData);
      createValidationReportMutation.mutate(createValidationReportRequest, {
        onSuccess: () => {
          onSubmitComplete();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [createValidationReportMutation, onSubmitComplete],
  );
  const models = getTrainedModelsQuery.data?.models ?? [];
  const states = Array.from(new Set(models.map((m) => m.state))).sort();
  return (
    <LoadingOverlay isActive={getTrainedModelsQuery.isInitialLoading}>
      <Formik<CreateValidationReportFormData>
        initialValues={INITIAL_FORM_DATA}
        validationSchema={FORM_SCHEMA as any}
        onSubmit={submitHandler}
      >
        <FormikForm>
          {getTrainedModelsQuery.isError ? (
            <Alert testId="models-error" variation="error">
              Failed to load Trained Models.
            </Alert>
          ) : null}
          <HorizontalFieldset>
            <FormikConnectedAmplifySelectField
              testId="state-dropdown"
              label="State"
              placeholder="Select State"
              name="state"
              isDisabled={!getTrainedModelsQuery.data}
            >
              {states.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </FormikConnectedAmplifySelectField>

            <ModelVersionInput
              testId="model-version-dropdown"
              label="Model"
              placeholder="Select Model"
              name="modelVersion"
              getTrainedModelsQuery={getTrainedModelsQuery}
            />

            <ModelVersionInput
              testId="baseline-model-version-dropdown"
              label="Baseline Model"
              placeholder="Select Baseline Model"
              name="baselineModelVersion"
              getTrainedModelsQuery={getTrainedModelsQuery}
            />
          </HorizontalFieldset>
          <Flex direction="row" justifyContent="flex-end" width="100%">
            <FormikConnectedAmplifySubmitButton />
          </Flex>
          {createValidationReportMutation.isSuccess ? (
            <Alert testId="result-success" variation="success">
              Validation Report in progress at{" "}
              {createValidationReportMutation.data.executionArn}
            </Alert>
          ) : null}
          {createValidationReportMutation.isError ? (
            <Alert testId="result-error" variation="error">
              Validation Report submission failed.
            </Alert>
          ) : null}
        </FormikForm>
      </Formik>
    </LoadingOverlay>
  );
};

const filterModelForState = (
  models: TrainedScoringModel[],
  selectedState: string,
): TrainedScoringModel[] =>
  models
    .filter(({state}) => state === selectedState)
    .sort(
      (model1, model2) =>
        model2.trainedAt.getTime() - model1.trainedAt.getTime(),
    );

const ModelVersionInput: React.FC<{
  testId: string;
  label: string;
  name: string;
  placeholder: string;
  getTrainedModelsQuery: GetTrainedModelsQuery;
}> = ({testId, label, name, placeholder, getTrainedModelsQuery}) => {
  const {
    values: {state: selectedState},
  } = useFormikContext<CreateValidationReportFormData>();

  const models = getTrainedModelsQuery.data?.models ?? [];
  const filteredModels: TrainedScoringModel[] = selectedState
    ? filterModelForState(models, selectedState)
    : [];

  return (
    <FormikConnectedAmplifySelectField
      testId={testId}
      label={label}
      placeholder={placeholder}
      name={name}
      isDisabled={!(selectedState && getTrainedModelsQuery.data)}
    >
      {filteredModels.map(({version}) => (
        <option key={version} value={version}>
          {version}
        </option>
      ))}
    </FormikConnectedAmplifySelectField>
  );
};
