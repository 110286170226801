/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClaimStatus = {
    InProgress: 'in_progress',
    Closed: 'closed'
} as const;
export type ClaimStatus = typeof ClaimStatus[keyof typeof ClaimStatus];


export function ClaimStatusFromJSON(json: any): ClaimStatus {
    return ClaimStatusFromJSONTyped(json, false);
}

export function ClaimStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaimStatus {
    return json as ClaimStatus;
}

export function ClaimStatusToJSON(value?: ClaimStatus | null): any {
    return value as any;
}

