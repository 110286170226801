/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPredictionSuccessResponse
 */
export interface GetPredictionSuccessResponse {
    /**
     * 
     * @type {number}
     * @memberof GetPredictionSuccessResponse
     */
    score: number;
}

/**
 * Check if a given object implements the GetPredictionSuccessResponse interface.
 */
export function instanceOfGetPredictionSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "score" in value;

    return isInstance;
}

export function GetPredictionSuccessResponseFromJSON(json: any): GetPredictionSuccessResponse {
    return GetPredictionSuccessResponseFromJSONTyped(json, false);
}

export function GetPredictionSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPredictionSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'score': json['score'],
    };
}

export function GetPredictionSuccessResponseToJSON(value?: GetPredictionSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'score': value.score,
    };
}

