import React from "react";

import {OrderPropertyDocumentIngestionStatus} from "../adminApi";
import {ErrorPill, SuccessPill, WarningPill} from "../components/Pills";

export const OrderPropertyDocumentIngestionStatusPill: React.FC<{
  status: OrderPropertyDocumentIngestionStatus;
}> = ({status}) => {
  switch (status) {
    case OrderPropertyDocumentIngestionStatus.Started:
      return <OrderPropertyDocumentIngestionStatusPillStarted />;
    case OrderPropertyDocumentIngestionStatus.Success:
      return <OrderPropertyDocumentIngestionStatusPillSuccess />;
    default:
      return <OrderPropertyDocumentIngestionStatusPillFailure />;
  }
};

const OrderPropertyDocumentIngestionStatusPillStarted: React.FC = () => (
  <WarningPill label="Started" />
);

const OrderPropertyDocumentIngestionStatusPillSuccess: React.FC = () => (
  <SuccessPill label="Success" />
);

const OrderPropertyDocumentIngestionStatusPillFailure: React.FC = () => (
  <ErrorPill label="Failed" />
);
