import {Authenticator} from "@aws-amplify/ui-react";
import {useAuth} from "@aws-amplify/ui-react/internal";
import React, {useContext, useEffect, useState} from "react";

import {DependencyContext} from "../../DependencyContext";

import {AuthenticationStateContext} from "./AuthenticationContext";
import {useAutomaticallyRefreshSession} from "./AutomaticallyRefreshSession";
import {synchronizeExternalAuthenticationState} from "./amplifyHelpers";

export const SynchronizeExternalAuthenticationState: React.FC = ({
  children,
}) => {
  const {authenticationStateHolder} = useContext(DependencyContext);
  const [authenticationState, setAuthenticationState] = useState(
    authenticationStateHolder.getState(),
  );
  const {user, isLoading} = useAuth();
  useAutomaticallyRefreshSession(authenticationState);

  useEffect(() => {
    synchronizeExternalAuthenticationState(
      authenticationStateHolder,
      isLoading,
      user,
    );
    setAuthenticationState(authenticationStateHolder.getState());
  }, [authenticationStateHolder, isLoading, user]);

  return (
    <AuthenticationStateContext.Provider value={authenticationState}>
      {children}
    </AuthenticationStateContext.Provider>
  );
};

export const AuthenticationContextProvider: React.FC = ({children}) => (
  <Authenticator.Provider>
    <SynchronizeExternalAuthenticationState>
      {children}
    </SynchronizeExternalAuthenticationState>
  </Authenticator.Provider>
);
