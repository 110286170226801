/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgencyProducerReference } from './AgencyProducerReference';
import {
    AgencyProducerReferenceFromJSON,
    AgencyProducerReferenceFromJSONTyped,
    AgencyProducerReferenceToJSON,
} from './AgencyProducerReference';
import type { Order } from './Order';
import {
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './Order';

/**
 * 
 * @export
 * @interface GetOrdersSuccessResponseOrder
 */
export interface GetOrdersSuccessResponseOrder {
    /**
     * 
     * @type {AgencyProducerReference}
     * @memberof GetOrdersSuccessResponseOrder
     */
    agency: AgencyProducerReference;
    /**
     * 
     * @type {Order}
     * @memberof GetOrdersSuccessResponseOrder
     */
    order: Order;
}

/**
 * Check if a given object implements the GetOrdersSuccessResponseOrder interface.
 */
export function instanceOfGetOrdersSuccessResponseOrder(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "agency" in value;
    isInstance = isInstance && "order" in value;

    return isInstance;
}

export function GetOrdersSuccessResponseOrderFromJSON(json: any): GetOrdersSuccessResponseOrder {
    return GetOrdersSuccessResponseOrderFromJSONTyped(json, false);
}

export function GetOrdersSuccessResponseOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrdersSuccessResponseOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agency': AgencyProducerReferenceFromJSON(json['agency']),
        'order': OrderFromJSON(json['order']),
    };
}

export function GetOrdersSuccessResponseOrderToJSON(value?: GetOrdersSuccessResponseOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agency': AgencyProducerReferenceToJSON(value.agency),
        'order': OrderToJSON(value.order),
    };
}

