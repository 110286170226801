import {Icon} from "@title-service/ui";
import React from "react";
import * as uuid from "uuid";

const VIEW_BOX: React.ComponentProps<typeof Icon>["viewBox"] = {
  width: 33,
  height: 29,
};

const PATH_1_ID = uuid.v4();
const PATH_2_ID = uuid.v4();
const MASK_1_ID = uuid.v4();
const MASK_2_ID = uuid.v4();

export const WarningIcon: React.FC<React.ComponentProps<typeof Icon>> = (
  props,
) => (
  <Icon viewBox={VIEW_BOX} {...props}>
    <defs>
      <polygon
        id={PATH_1_ID}
        points="-1.74971149e-13 24.1426957 14.0094783 2.66453526e-15 28.0189565 24.1426957"
      />
      <path
        id={PATH_2_ID}
        d="M2.99052174,8.27530435 L2.99052174,10.8391304 L0.48773913,10.8391304 L0.48773913,8.27530435 L2.99052174,8.27530435 Z M2.99052174,0.644869565 L2.99052174,5.77252174 L0.48773913,5.77252174 L0.48773913,0.644869565 L2.99052174,0.644869565 Z"
      />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-922.000000, -1941.000000)">
        <g transform="translate(272.000000, 1937.000000)">
          <g transform="translate(648.000000, 0.000000)">
            <g transform="translate(4.500000, 6.750000)">
              <mask id={MASK_1_ID} fill="white">
                <use href={`#${PATH_1_ID}`} />
              </mask>
              <use
                stroke="currentColor"
                strokeWidth="2.54347826"
                fillRule="nonzero"
                href={`#${PATH_1_ID}`}
              />
              <g mask={`url(#${MASK_1_ID})`}>
                <g transform="translate(12.250000, 9.000000)">
                  <mask id={MASK_2_ID} fill="white">
                    <use href={`#${PATH_2_ID}`} />
                  </mask>
                  <use
                    stroke="none"
                    fill="currentColor"
                    fillRule="nonzero"
                    href={`#${PATH_2_ID}`}
                  />
                  <g stroke="none" fill="none" mask={`url(#${MASK_2_ID})`}>
                    <g transform="translate(-16.750000, -15.750000)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
