import {Heading, ThemeProvider} from "@title-service/ui";
import React from "react";
import ReactModal from "react-modal";

import {COLOR_N_000, SPACING_2, SPACING_5} from "../theme";

const DEFAULT_CONTENT_STYLE: React.CSSProperties = {};

export const Modal: React.FC<{
  ariaHideApp?: boolean;
  isOpen?: boolean;
  onClose?: () => any;
  title?: React.ComponentProps<typeof ModalTitle>["value"];
  contentStyle?: React.CSSProperties;
}> = ({
  ariaHideApp,
  onClose,
  isOpen = true,
  contentStyle = DEFAULT_CONTENT_STYLE,
  title,
  children,
}) => (
  <ReactModal
    ariaHideApp={ariaHideApp}
    isOpen={isOpen}
    onRequestClose={onClose}
    shouldFocusAfterRender={true}
    shouldCloseOnOverlayClick={true}
    shouldCloseOnEsc={false}
    preventScroll={true}
    style={{
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgb(67, 83, 99, 0.5)",

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      },
      content: {
        backgroundColor: COLOR_N_000,
        borderRadius: SPACING_2,
        outline: "none",
        padding: SPACING_5,
        maxHeight: "90%",
        overflow: "auto",

        ...contentStyle,
      },
    }}
  >
    <ThemeProvider>
      {title ? <ModalTitle value={title} /> : null}
      {children}
    </ThemeProvider>
  </ReactModal>
);

export const ModalTitle: React.FC<React.ComponentProps<typeof Heading>> = (
  props,
) => <Heading level={5} marginBottom="medium" {...props} />;
