/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetAgencyProducersSuccessResponseProducer } from './GetAgencyProducersSuccessResponseProducer';
import {
    GetAgencyProducersSuccessResponseProducerFromJSON,
    GetAgencyProducersSuccessResponseProducerFromJSONTyped,
    GetAgencyProducersSuccessResponseProducerToJSON,
} from './GetAgencyProducersSuccessResponseProducer';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';

/**
 * 
 * @export
 * @interface GetAgencyProducersSuccessResponse
 */
export interface GetAgencyProducersSuccessResponse {
    /**
     * 
     * @type {Array<GetAgencyProducersSuccessResponseProducer>}
     * @memberof GetAgencyProducersSuccessResponse
     */
    agencyProducers: Array<GetAgencyProducersSuccessResponseProducer>;
    /**
     * 
     * @type {Pagination}
     * @memberof GetAgencyProducersSuccessResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the GetAgencyProducersSuccessResponse interface.
 */
export function instanceOfGetAgencyProducersSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "agencyProducers" in value;
    isInstance = isInstance && "pagination" in value;

    return isInstance;
}

export function GetAgencyProducersSuccessResponseFromJSON(json: any): GetAgencyProducersSuccessResponse {
    return GetAgencyProducersSuccessResponseFromJSONTyped(json, false);
}

export function GetAgencyProducersSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAgencyProducersSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agencyProducers': ((json['agencyProducers'] as Array<any>).map(GetAgencyProducersSuccessResponseProducerFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function GetAgencyProducersSuccessResponseToJSON(value?: GetAgencyProducersSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agencyProducers': ((value.agencyProducers as Array<any>).map(GetAgencyProducersSuccessResponseProducerToJSON)),
        'pagination': PaginationToJSON(value.pagination),
    };
}

