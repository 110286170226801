/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClaimRequestAddress } from './ClaimRequestAddress';
import {
    ClaimRequestAddressFromJSON,
    ClaimRequestAddressFromJSONTyped,
    ClaimRequestAddressToJSON,
} from './ClaimRequestAddress';

/**
 * 
 * @export
 * @interface CreateClaimRequest
 */
export interface CreateClaimRequest {
    /**
     * 
     * @type {ClaimRequestAddress}
     * @memberof CreateClaimRequest
     */
    address: ClaimRequestAddress;
    /**
     * 
     * @type {string}
     * @memberof CreateClaimRequest
     */
    agencyId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClaimRequest
     */
    contactEmail: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClaimRequest
     */
    contactPhone: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClaimRequest
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClaimRequest
     */
    insuredName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClaimRequest
     */
    policyId?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateClaimRequest
     */
    reportedOn: Date;
}

/**
 * Check if a given object implements the CreateClaimRequest interface.
 */
export function instanceOfCreateClaimRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "agencyId" in value;
    isInstance = isInstance && "contactEmail" in value;
    isInstance = isInstance && "contactPhone" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "insuredName" in value;
    isInstance = isInstance && "reportedOn" in value;

    return isInstance;
}

export function CreateClaimRequestFromJSON(json: any): CreateClaimRequest {
    return CreateClaimRequestFromJSONTyped(json, false);
}

export function CreateClaimRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateClaimRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': ClaimRequestAddressFromJSON(json['address']),
        'agencyId': json['agencyId'],
        'contactEmail': json['contactEmail'],
        'contactPhone': json['contactPhone'],
        'description': json['description'],
        'insuredName': json['insuredName'],
        'policyId': !exists(json, 'policyId') ? undefined : json['policyId'],
        'reportedOn': (new Date(json['reportedOn'])),
    };
}

export function CreateClaimRequestToJSON(value?: CreateClaimRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': ClaimRequestAddressToJSON(value.address),
        'agencyId': value.agencyId,
        'contactEmail': value.contactEmail,
        'contactPhone': value.contactPhone,
        'description': value.description,
        'insuredName': value.insuredName,
        'policyId': value.policyId,
        'reportedOn': (value.reportedOn.toISOString().substr(0,10)),
    };
}

