export const enumKeys = <
  Enum extends object,
  K extends keyof Enum = keyof Enum,
>(
  obj: Enum,
): K[] => Object.keys(obj).filter((k) => Number.isNaN(Number(k))) as K[];

export const enumValues = <
  Enum extends object,
  K extends keyof Enum = keyof Enum,
>(
  obj: Enum,
): Enum[K][] => {
  const keys = enumKeys<Enum, K>(obj);
  return keys.map((k) => obj[k]);
};

if (import.meta.vitest) {
  const {describe, expect, it} = import.meta.vitest;

  describe("enumKeys", () => {
    enum TestEnumKeysString {
      One = "one",
      Two = "two",
    }

    enum TestEnumKeysNumber {
      One,
      Two,
    }

    enum TestEnumKeysEmpty {}

    it("returns enum keys for string enum", () => {
      const keys = enumKeys(TestEnumKeysString);

      expect(keys).toEqual(["One", "Two"]);
    });

    it("returns enum keys for number enum", () => {
      const keys = enumKeys(TestEnumKeysNumber);

      expect(keys).toEqual(["One", "Two"]);
    });

    it("returns empty for empty enum", () => {
      const keys = enumKeys(TestEnumKeysEmpty);

      expect(keys).toHaveLength(0);
    });
  });

  describe("enumValues", () => {
    enum TestEnumValuesString {
      One = "the key for one",
      Two = "the key for two",
    }

    enum TestEnumValuesNumber {
      One,
      Two,
    }

    enum TestEnumValuesEmpty {}

    it("returns enum values for string enum", () => {
      const values = enumValues(TestEnumValuesString);

      expect(values).toEqual(["the key for one", "the key for two"]);
    });

    it("returns enum keys for number enum", () => {
      const values = enumValues(TestEnumValuesNumber);

      expect(values).toEqual([0, 1]);
    });

    it("returns empty for empty enum", () => {
      const values = enumKeys(TestEnumValuesEmpty);

      expect(values).toHaveLength(0);
    });
  });
}
