import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
} from "@title-service/ui";
import React from "react";

import {ExpiringSoonSection} from "../shared/producers/ExpiringSoonSection";
import {ProducersSection} from "../shared/producers/ProducersSection";
import {UnfulfilledAppointmentsSection} from "../shared/producers/UnfulfilledAppointmentsSection";

export const ProducersRoute: React.FC<{
  ExpiringSoonSection?: typeof ExpiringSoonSection;
  ProducersSection?: typeof ProducersSection;
  UnfulfilledAppointmentsSection?: typeof UnfulfilledAppointmentsSection;
}> = ({
  ExpiringSoonSection: ExpiringSoonSection_ = ExpiringSoonSection,
  ProducersSection: ProducersSection_ = ProducersSection,
  UnfulfilledAppointmentsSection:
    UnfulfilledAppointmentsSection_ = UnfulfilledAppointmentsSection,
}) => (
  <>
    <BodyHeader>
      <PrimaryBodyHeaderContainer>
        <PrimaryBodyHeader value="Producer Licenses and Appointments" />
      </PrimaryBodyHeaderContainer>
    </BodyHeader>
    <BodyContent>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <ExpiringSoonSection_ />
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <UnfulfilledAppointmentsSection_ />
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <ProducersSection_ />
    </BodyContent>
  </>
);
