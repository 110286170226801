import {Text, TextRaw} from "@title-service/ui";
import React from "react";

import {OrderVestingInformationNotAvailableReason} from "../adminApi";

export const OrderVestingInformationNotAvailableReasonComponentRaw: React.FC<{
  notAvailableReason: OrderVestingInformationNotAvailableReason;
}> = ({notAvailableReason}) => {
  switch (notAvailableReason) {
    case OrderVestingInformationNotAvailableReason.PropertyDocumentRejected:
      return <TextRaw value="Vesting Information Rejected" />;
    case OrderVestingInformationNotAvailableReason.QuitClaimAfterLfvt:
      return <TextRaw value="Quit Claim After Last Full Value Transfer Date" />;
    default:
      return <TextRaw value={notAvailableReason} />;
  }
};

export const OrderVestingInformationNotAvailableReasonComponent: React.FC<
  Omit<React.ComponentProps<typeof Text>, "value"> & {
    notAvailableReason: OrderVestingInformationNotAvailableReason;
  }
> = ({notAvailableReason, ...props}) => (
  <Text
    value={
      <OrderVestingInformationNotAvailableReasonComponentRaw
        notAvailableReason={notAvailableReason}
      />
    }
  />
);
