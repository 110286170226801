/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgencyProducerReference } from './AgencyProducerReference';
import {
    AgencyProducerReferenceFromJSON,
    AgencyProducerReferenceFromJSONTyped,
    AgencyProducerReferenceToJSON,
} from './AgencyProducerReference';
import type { Cpl } from './Cpl';
import {
    CplFromJSON,
    CplFromJSONTyped,
    CplToJSON,
} from './Cpl';
import type { InsurfulOrderSummary } from './InsurfulOrderSummary';
import {
    InsurfulOrderSummaryFromJSON,
    InsurfulOrderSummaryFromJSONTyped,
    InsurfulOrderSummaryToJSON,
} from './InsurfulOrderSummary';
import type { Order } from './Order';
import {
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './Order';
import type { OrderFlag } from './OrderFlag';
import {
    OrderFlagFromJSON,
    OrderFlagFromJSONTyped,
    OrderFlagToJSON,
} from './OrderFlag';
import type { OrderLegalDescription } from './OrderLegalDescription';
import {
    OrderLegalDescriptionFromJSON,
    OrderLegalDescriptionFromJSONTyped,
    OrderLegalDescriptionToJSON,
} from './OrderLegalDescription';
import type { OrderPermissions } from './OrderPermissions';
import {
    OrderPermissionsFromJSON,
    OrderPermissionsFromJSONTyped,
    OrderPermissionsToJSON,
} from './OrderPermissions';
import type { OrderPropertyDocument } from './OrderPropertyDocument';
import {
    OrderPropertyDocumentFromJSON,
    OrderPropertyDocumentFromJSONTyped,
    OrderPropertyDocumentToJSON,
} from './OrderPropertyDocument';
import type { OrderScore } from './OrderScore';
import {
    OrderScoreFromJSON,
    OrderScoreFromJSONTyped,
    OrderScoreToJSON,
} from './OrderScore';
import type { OrderVestingInformation } from './OrderVestingInformation';
import {
    OrderVestingInformationFromJSON,
    OrderVestingInformationFromJSONTyped,
    OrderVestingInformationToJSON,
} from './OrderVestingInformation';
import type { Policy } from './Policy';
import {
    PolicyFromJSON,
    PolicyFromJSONTyped,
    PolicyToJSON,
} from './Policy';
import type { UnderwritingDecision } from './UnderwritingDecision';
import {
    UnderwritingDecisionFromJSON,
    UnderwritingDecisionFromJSONTyped,
    UnderwritingDecisionToJSON,
} from './UnderwritingDecision';

/**
 * 
 * @export
 * @interface GetOrderSuccessResponse
 */
export interface GetOrderSuccessResponse {
    /**
     * 
     * @type {AgencyProducerReference}
     * @memberof GetOrderSuccessResponse
     */
    agency: AgencyProducerReference;
    /**
     * 
     * @type {Array<Cpl>}
     * @memberof GetOrderSuccessResponse
     */
    cpls: Array<Cpl>;
    /**
     * 
     * @type {OrderFlag}
     * @memberof GetOrderSuccessResponse
     */
    flag?: OrderFlag;
    /**
     * 
     * @type {Array<InsurfulOrderSummary>}
     * @memberof GetOrderSuccessResponse
     */
    insurfulOrders: Array<InsurfulOrderSummary>;
    /**
     * 
     * @type {Array<OrderLegalDescription>}
     * @memberof GetOrderSuccessResponse
     */
    legalDescriptions: Array<OrderLegalDescription>;
    /**
     * 
     * @type {Order}
     * @memberof GetOrderSuccessResponse
     */
    order: Order;
    /**
     * 
     * @type {OrderPermissions}
     * @memberof GetOrderSuccessResponse
     */
    orderPermissions: OrderPermissions;
    /**
     * 
     * @type {Array<Policy>}
     * @memberof GetOrderSuccessResponse
     */
    policies: Array<Policy>;
    /**
     * 
     * @type {Array<OrderPropertyDocument>}
     * @memberof GetOrderSuccessResponse
     */
    propertyDocuments: Array<OrderPropertyDocument>;
    /**
     * 
     * @type {Array<OrderScore>}
     * @memberof GetOrderSuccessResponse
     */
    scores: Array<OrderScore>;
    /**
     * 
     * @type {Array<UnderwritingDecision>}
     * @memberof GetOrderSuccessResponse
     */
    underwritingDecisions: Array<UnderwritingDecision>;
    /**
     * 
     * @type {Array<OrderVestingInformation>}
     * @memberof GetOrderSuccessResponse
     */
    vestingInformation: Array<OrderVestingInformation>;
}

/**
 * Check if a given object implements the GetOrderSuccessResponse interface.
 */
export function instanceOfGetOrderSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "agency" in value;
    isInstance = isInstance && "cpls" in value;
    isInstance = isInstance && "insurfulOrders" in value;
    isInstance = isInstance && "legalDescriptions" in value;
    isInstance = isInstance && "order" in value;
    isInstance = isInstance && "orderPermissions" in value;
    isInstance = isInstance && "policies" in value;
    isInstance = isInstance && "propertyDocuments" in value;
    isInstance = isInstance && "scores" in value;
    isInstance = isInstance && "underwritingDecisions" in value;
    isInstance = isInstance && "vestingInformation" in value;

    return isInstance;
}

export function GetOrderSuccessResponseFromJSON(json: any): GetOrderSuccessResponse {
    return GetOrderSuccessResponseFromJSONTyped(json, false);
}

export function GetOrderSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrderSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agency': AgencyProducerReferenceFromJSON(json['agency']),
        'cpls': ((json['cpls'] as Array<any>).map(CplFromJSON)),
        'flag': !exists(json, 'flag') ? undefined : OrderFlagFromJSON(json['flag']),
        'insurfulOrders': ((json['insurfulOrders'] as Array<any>).map(InsurfulOrderSummaryFromJSON)),
        'legalDescriptions': ((json['legalDescriptions'] as Array<any>).map(OrderLegalDescriptionFromJSON)),
        'order': OrderFromJSON(json['order']),
        'orderPermissions': OrderPermissionsFromJSON(json['orderPermissions']),
        'policies': ((json['policies'] as Array<any>).map(PolicyFromJSON)),
        'propertyDocuments': ((json['propertyDocuments'] as Array<any>).map(OrderPropertyDocumentFromJSON)),
        'scores': ((json['scores'] as Array<any>).map(OrderScoreFromJSON)),
        'underwritingDecisions': ((json['underwritingDecisions'] as Array<any>).map(UnderwritingDecisionFromJSON)),
        'vestingInformation': ((json['vestingInformation'] as Array<any>).map(OrderVestingInformationFromJSON)),
    };
}

export function GetOrderSuccessResponseToJSON(value?: GetOrderSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agency': AgencyProducerReferenceToJSON(value.agency),
        'cpls': ((value.cpls as Array<any>).map(CplToJSON)),
        'flag': OrderFlagToJSON(value.flag),
        'insurfulOrders': ((value.insurfulOrders as Array<any>).map(InsurfulOrderSummaryToJSON)),
        'legalDescriptions': ((value.legalDescriptions as Array<any>).map(OrderLegalDescriptionToJSON)),
        'order': OrderToJSON(value.order),
        'orderPermissions': OrderPermissionsToJSON(value.orderPermissions),
        'policies': ((value.policies as Array<any>).map(PolicyToJSON)),
        'propertyDocuments': ((value.propertyDocuments as Array<any>).map(OrderPropertyDocumentToJSON)),
        'scores': ((value.scores as Array<any>).map(OrderScoreToJSON)),
        'underwritingDecisions': ((value.underwritingDecisions as Array<any>).map(UnderwritingDecisionToJSON)),
        'vestingInformation': ((value.vestingInformation as Array<any>).map(OrderVestingInformationToJSON)),
    };
}

