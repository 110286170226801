/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgencyProducerReference } from './AgencyProducerReference';
import {
    AgencyProducerReferenceFromJSON,
    AgencyProducerReferenceFromJSONTyped,
    AgencyProducerReferenceToJSON,
} from './AgencyProducerReference';

/**
 * 
 * @export
 * @interface GetAgencyProducerSuccessResponse
 */
export interface GetAgencyProducerSuccessResponse {
    /**
     * 
     * @type {Date}
     * @memberof GetAgencyProducerSuccessResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof GetAgencyProducerSuccessResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgencyProducerSuccessResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgencyProducerSuccessResponse
     */
    niprId?: string;
    /**
     * 
     * @type {AgencyProducerReference}
     * @memberof GetAgencyProducerSuccessResponse
     */
    parentAgencyProducer?: AgencyProducerReference;
    /**
     * 
     * @type {Date}
     * @memberof GetAgencyProducerSuccessResponse
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the GetAgencyProducerSuccessResponse interface.
 */
export function instanceOfGetAgencyProducerSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function GetAgencyProducerSuccessResponseFromJSON(json: any): GetAgencyProducerSuccessResponse {
    return GetAgencyProducerSuccessResponseFromJSONTyped(json, false);
}

export function GetAgencyProducerSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAgencyProducerSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'id': json['id'],
        'name': json['name'],
        'niprId': !exists(json, 'niprId') ? undefined : json['niprId'],
        'parentAgencyProducer': !exists(json, 'parentAgencyProducer') ? undefined : AgencyProducerReferenceFromJSON(json['parentAgencyProducer']),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function GetAgencyProducerSuccessResponseToJSON(value?: GetAgencyProducerSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.createdAt.toISOString()),
        'id': value.id,
        'name': value.name,
        'niprId': value.niprId,
        'parentAgencyProducer': AgencyProducerReferenceToJSON(value.parentAgencyProducer),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

