/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidationReportDetailStatistics
 */
export interface ValidationReportDetailStatistics {
    /**
     * 
     * @type {number}
     * @memberof ValidationReportDetailStatistics
     */
    accuracy50: number;
    /**
     * 
     * @type {number}
     * @memberof ValidationReportDetailStatistics
     */
    falseNegativeRateMedian: number;
    /**
     * 
     * @type {number}
     * @memberof ValidationReportDetailStatistics
     */
    falsePositiveRateMedian: number;
    /**
     * 
     * @type {number}
     * @memberof ValidationReportDetailStatistics
     */
    medianScore: number;
}

/**
 * Check if a given object implements the ValidationReportDetailStatistics interface.
 */
export function instanceOfValidationReportDetailStatistics(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accuracy50" in value;
    isInstance = isInstance && "falseNegativeRateMedian" in value;
    isInstance = isInstance && "falsePositiveRateMedian" in value;
    isInstance = isInstance && "medianScore" in value;

    return isInstance;
}

export function ValidationReportDetailStatisticsFromJSON(json: any): ValidationReportDetailStatistics {
    return ValidationReportDetailStatisticsFromJSONTyped(json, false);
}

export function ValidationReportDetailStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationReportDetailStatistics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accuracy50': json['accuracy50'],
        'falseNegativeRateMedian': json['falseNegativeRateMedian'],
        'falsePositiveRateMedian': json['falsePositiveRateMedian'],
        'medianScore': json['medianScore'],
    };
}

export function ValidationReportDetailStatisticsToJSON(value?: ValidationReportDetailStatistics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accuracy50': value.accuracy50,
        'falseNegativeRateMedian': value.falseNegativeRateMedian,
        'falsePositiveRateMedian': value.falsePositiveRateMedian,
        'medianScore': value.medianScore,
    };
}

