import {useMutation} from "@tanstack/react-query";
import {
  DateComponent,
  FormikConnectedAmplifySubmitButton,
  FormikConnectedAmplifyTextAreaField,
  FormikForm,
  FormResultErrorMessage,
  FormResultSuccessMessage,
  VerticalFieldset,
} from "@title-service/ui";
import {Formik} from "formik";
import React, {useCallback, useContext, useState} from "react";
import styled from "styled-components";
import * as Yup from "yup";

import {DependencyContext} from "../../DependencyContext";
import {GetAgencyProducerNotesQuery} from "../../pages/AgencyProducerDetail";
import {GetIndividualProducerNotesQuery} from "../../pages/IndividualProducerDetail";
import {
  CreateAgencyProducerNoteRequest,
  CreateIndividualProducerNoteRequest,
  GetProducerNotesSuccessResponse,
  GetProducerNotesSuccessResponseNote,
  ProducerType,
  UpdateProducerNoteRequest,
} from "../adminApi";
import {CreateProducerNoteRequest} from "../adminApi/fetchClient";
import {Modal} from "../components/Modal";
import {EditIcon, PlusIcon} from "../components/icons";
import {
  BodyRow,
  HeaderRow,
  Table,
  TBody,
  Td,
  Th,
  THead,
} from "../components/tables/SecondaryTable";
import {
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../layout";
import {useSubmitHandler} from "../producers/UseSubmitHandler";
import {COLOR_N_500, FONT_SIZE_H7} from "../theme";

const MODAL_CONTENT_STYLE: React.CSSProperties = {
  minWidth: "650px",
};

export const ProducerNotesSection: React.FC<{
  producerId: string;
  producerType: ProducerType;
  onReloadProducerNotes: () => any;
  getProducerNotesQuery:
    | GetAgencyProducerNotesQuery
    | GetIndividualProducerNotesQuery;
  createNoteMutationFn: (formData: CreateProducerNoteFormData) => Promise<void>;
  ProducerNotesSuccessComponent?: typeof ProducerNotesSuccessComponent;
  ProducerNotesFailureComponent?: typeof ProducerNotesFailureComponent;
  ProducerNotesLoadingComponent?: typeof ProducerNotesLoadingComponent;
}> = ({
  producerId,
  producerType,
  onReloadProducerNotes,
  getProducerNotesQuery: {data, error},
  createNoteMutationFn,
  ProducerNotesSuccessComponent:
    ProducerNotesSuccessComponent_ = ProducerNotesSuccessComponent,
  ProducerNotesFailureComponent:
    ProducerNotesFailureComponent_ = ProducerNotesFailureComponent,
  ProducerNotesLoadingComponent:
    ProducerNotesLoadingComponent_ = ProducerNotesLoadingComponent,
}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);
  return (
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Notes" />
        {data ? <PlusIcon onClick={openForm} /> : null}
      </SecondarySectionHeaderContainer>
      <SecondarySectionContent>
        {data ? (
          <>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <ProducerNotesSuccessComponent_
              producerId={producerId}
              producerType={producerType}
              getProducerNotesResponse={data}
              onReloadProducerNotes={onReloadProducerNotes}
            />
            <Modal
              contentStyle={MODAL_CONTENT_STYLE}
              isOpen={displayForm}
              onClose={closeForm}
              title="Add Note"
            >
              <CreateProducerNoteForm
                producerId={producerId}
                producerType={producerType}
                createNoteMutationFn={createNoteMutationFn}
                onSubmitComplete={onReloadProducerNotes}
              />
            </Modal>
          </>
        ) : error ? (
          // eslint-disable-next-line react/jsx-pascal-case
          <ProducerNotesFailureComponent_ />
        ) : (
          // eslint-disable-next-line react/jsx-pascal-case
          <ProducerNotesLoadingComponent_ />
        )}
      </SecondarySectionContent>
    </SecondarySection>
  );
};

export const ProducerNotesLoadingComponent: React.FC = () => (
  <span>Loading Notes ...</span>
);

export const ProducerNotesFailureComponent: React.FC = () => (
  <span>Failed to load Notes.</span>
);

export const ProducerNotesSuccessComponent: React.FC<{
  producerId: string;
  producerType: ProducerType;
  getProducerNotesResponse: GetProducerNotesSuccessResponse;
  onReloadProducerNotes: () => any;
}> = ({
  producerId,
  producerType,
  getProducerNotesResponse: {notes},
  onReloadProducerNotes,
}) => (
  <Table>
    <THead>
      <HeaderRow>
        {notes.length > 0 && <Th width="50px" />}
        {/* edit producer note button column */}
        <Th width="110px">Created At</Th>
        <Th width="150px">Author</Th>
        <Th>Note</Th>
      </HeaderRow>
    </THead>
    <TBody>
      {notes.length === 0 ? (
        <BodyRow>
          <Td colSpan={6}>No Notes Recorded</Td>
        </BodyRow>
      ) : (
        notes.map((note) => (
          <ProducerNoteRow
            key={note.id}
            producerId={producerId}
            producerType={producerType}
            note={note}
            onReloadProducerNotes={onReloadProducerNotes}
          />
        ))
      )}
    </TBody>
  </Table>
);

const ProducerNoteRow: React.FC<{
  producerId: string;
  producerType: ProducerType;
  note: GetProducerNotesSuccessResponseNote;
  onReloadProducerNotes: () => any;
}> = ({
  producerId,
  producerType,
  note: {id, givenName, familyName, note, createdAt, updatedAt, editable},
  onReloadProducerNotes,
}) => {
  const {adminApi} = useContext(DependencyContext);
  const [displayUpdateForm, setDisplayUpdateForm] = useState(false);
  const openUpdateForm = useCallback(() => {
    setDisplayUpdateForm(true);
  }, []);
  const closeUpdateForm = useCallback(() => {
    setDisplayUpdateForm(false);
  }, []);
  const updateNoteMutationFn = useCallback(
    (formData: UpdateProducerNoteFormData) =>
      adminApi.updateProducerNote({
        ...formData,
        producerId,
        producerNoteId: id,
      }),
    [adminApi, producerId, id],
  );
  return (
    <BodyRow>
      <Td>
        {editable ? (
          <EditIcon data-testid="edit-note-button" onClick={openUpdateForm} />
        ) : null}
      </Td>
      <Td>
        <DateComponent value={createdAt} />
      </Td>
      <Td>{`${givenName} ${familyName}`}</Td>
      <Td>
        {note}
        <MaybeProducerNoteEditedFlag
          createdAt={createdAt}
          updatedAt={updatedAt}
        />
      </Td>
      <Modal
        contentStyle={MODAL_CONTENT_STYLE}
        isOpen={displayUpdateForm}
        onClose={closeUpdateForm}
        title="Edit Note"
      >
        <UpdateProducerNoteForm
          producerType={producerType}
          producerId={producerId}
          producerNoteId={id}
          producerNoteTextToEdit={note}
          updateNoteMutationFn={updateNoteMutationFn}
          onSubmitComplete={onReloadProducerNotes}
        />
      </Modal>
    </BodyRow>
  );
};

export const NOTE_SCHEMA: Yup.StringSchema<string> = Yup.string()
  .required("Note is required")
  .trim();

export const NoteField: React.FC<
  Omit<
    React.ComponentProps<typeof FormikConnectedAmplifyTextAreaField>,
    "label"
  >
> = (props) => (
  // testId prop is broken for TextAreaField
  <FormikConnectedAmplifyTextAreaField
    data-testid="note-input"
    label="Note"
    rows={5}
    {...props}
  />
);

export const CREATE_PRODUCER_NOTE_FORM_SCHEMA: Yup.ObjectSchema<CreateProducerNoteFormData> =
  Yup.object({
    note: NOTE_SCHEMA,
  });

export type CreateProducerNoteFormData = CreateProducerNoteRequest;

export const buildInitialCreateProducerNoteFormData =
  (): CreateProducerNoteFormData => ({
    note: "",
  });

export const mapProducerNoteFormDataToCreateProducerNoteRequest = (
  producerId: string,
  producerType: ProducerType,
  formData: CreateProducerNoteFormData,
): CreateAgencyProducerNoteRequest | CreateIndividualProducerNoteRequest => {
  const completeFormData =
    CREATE_PRODUCER_NOTE_FORM_SCHEMA.validateSync(formData);
  const agencyProducerRequest = {
    agencyProducerId: producerId,
    ...completeFormData,
  };
  const individualProducerRequest = {
    individualProducerId: producerId,
    ...completeFormData,
  };

  return producerType === ProducerType.Agency
    ? agencyProducerRequest
    : individualProducerRequest;
};

export const CreateProducerNoteForm: React.FC<{
  producerType: ProducerType;
  producerId: string;
  createNoteMutationFn: (formData: CreateProducerNoteFormData) => Promise<void>;
  onSubmitComplete: () => any;
}> = ({producerId, producerType, createNoteMutationFn, onSubmitComplete}) => {
  const mutation = useMutation({mutationFn: createNoteMutationFn});
  const submitHandler = useSubmitHandler(
    mutation,
    onSubmitComplete,
    (formData: CreateProducerNoteFormData) =>
      mapProducerNoteFormDataToCreateProducerNoteRequest(
        producerId,
        producerType,
        formData,
      ),
  );
  return (
    <Formik<CreateProducerNoteFormData>
      initialValues={buildInitialCreateProducerNoteFormData()}
      validationSchema={CREATE_PRODUCER_NOTE_FORM_SCHEMA as any}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <NoteField name="note" />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton />

        {mutation.isSuccess ? (
          <FormResultSuccessMessage data-testid="success-result">
            The producer note was successfully created
          </FormResultSuccessMessage>
        ) : null}

        {mutation.isError ? (
          <FormResultErrorMessage data-testid="error-result">
            Request failed
          </FormResultErrorMessage>
        ) : null}
      </FormikForm>
    </Formik>
  );
};

export const UPDATE_PRODUCER_NOTE_FORM_SCHEMA: Yup.ObjectSchema<CreateProducerNoteFormData> =
  Yup.object({
    note: NOTE_SCHEMA,
  });

export type UpdateProducerNoteFormData = Omit<
  UpdateProducerNoteRequest,
  "producerId" | "producerNoteId"
>;

export const buildInitialUpdateProducerNoteFormData = (
  producerNoteTextToEdit: string,
): UpdateProducerNoteFormData => ({
  note: producerNoteTextToEdit,
});

export const mapProducerNoteFormDataToUpdateProducerNoteRequest = (
  producerId: string,
  producerNoteId: string,
  producerType: ProducerType,
  formData: UpdateProducerNoteFormData,
): UpdateProducerNoteRequest => {
  const completeFormData =
    UPDATE_PRODUCER_NOTE_FORM_SCHEMA.validateSync(formData);
  return {
    producerId,
    producerNoteId,
    ...completeFormData,
  };
};

export const producerNoteWasEdited = (createdAt: Date, updatedAt: Date) =>
  createdAt.getTime() !== updatedAt.getTime();

export const MaybeProducerNoteEditedFlag: React.FC<{
  createdAt: Date;
  updatedAt: Date;
}> = ({createdAt, updatedAt}) => (
  <>
    {producerNoteWasEdited(createdAt, updatedAt) ? (
      <EditedFlagText>(edited)</EditedFlagText>
    ) : null}
  </>
);

const EditedFlagText = styled.p({
  color: COLOR_N_500,
  fontWeight: "bold",
  ...FONT_SIZE_H7,
});

export const UpdateProducerNoteForm: React.FC<{
  producerType: ProducerType;
  producerId: string;
  producerNoteId: string;
  producerNoteTextToEdit: string;
  updateNoteMutationFn: (formData: UpdateProducerNoteFormData) => Promise<void>;
  onSubmitComplete: () => any;
}> = ({
  producerType,
  producerId,
  producerNoteId,
  producerNoteTextToEdit,
  updateNoteMutationFn,
  onSubmitComplete,
}) => {
  const mutation = useMutation({mutationFn: updateNoteMutationFn});
  const submitHandler = useSubmitHandler(
    mutation,
    onSubmitComplete,
    (formData: UpdateProducerNoteFormData) =>
      mapProducerNoteFormDataToUpdateProducerNoteRequest(
        producerId,
        producerNoteId,
        producerType,
        formData,
      ),
  );
  return (
    <Formik<UpdateProducerNoteFormData>
      initialValues={buildInitialUpdateProducerNoteFormData(
        producerNoteTextToEdit,
      )}
      validationSchema={UPDATE_PRODUCER_NOTE_FORM_SCHEMA as any}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <NoteField name="note" />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton />

        {mutation.isSuccess ? (
          <FormResultSuccessMessage data-testid="success-result">
            The producer note was successfully updated
          </FormResultSuccessMessage>
        ) : null}

        {mutation.isError ? (
          <FormResultErrorMessage data-testid="error-result">
            Request failed
          </FormResultErrorMessage>
        ) : null}
      </FormikForm>
    </Formik>
  );
};
