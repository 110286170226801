import {Icon} from "@title-service/ui";
import React from "react";

const VIEW_BOX: React.ComponentProps<typeof Icon>["viewBox"] = {
  width: 24,
  height: 24,
};

/*
 * Icon taken from: https://www.svgrepo.com/svg/489635/trash
 * This work is licensed in the public domain (CC0 1.0) with no copyright and no requirement
 * to attribute the original author. For more information on this license,
 * see [here](https://creativecommons.org/publicdomain/zero/1.0/deed.en)
 */
export const TrashIcon: React.FC<React.ComponentProps<typeof Icon>> = (
  props,
) => (
  <Icon viewBox={VIEW_BOX} {...props}>
    <g width="24" height="24" fill="transparent">
      <path
        d="M14 11V17"
        stroke="#292929"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 11V17"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 7V19C6 20.1046 6.89543 21 8 21H16C17.1046 21 18 20.1046 18 19V7"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 7H20"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7L9 3H15L17 7"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </Icon>
);
