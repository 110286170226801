import {Flex, useTheme} from "@aws-amplify/ui-react";
import {
  DateTimeComponent,
  PrimarySection,
  Text,
  TextRaw,
  usePrimarySectionGap,
} from "@title-service/ui";
import React, {useCallback, useState} from "react";

import {Policy} from "../adminApi";
import {singleLineAddress, SingleLineAddressStyle} from "../components/Address";
import {CurrencyComponent, CurrencyWrapper} from "../components/Currency";
import {
  DataGrid,
  GridItem,
  GridItemLabel,
  GridItemLabelContainer,
  GridItemLabelSubText,
} from "../components/DataGrid";
import {StringMaybeBlankComponent} from "../components/Nullable";
import {
  buildSideBySideChildProps,
  SideBySideContainer,
} from "../components/SideBySide";
import {CaretDirection, CaretIcon} from "../components/icons";
import {
  BodyRow as PrimaryBodyRow,
  HeaderRow as PrimaryHeaderRow,
  Table as PrimaryTable,
  TBody as PrimaryTBody,
  Td as PrimaryTd,
  Th as PrimaryTh,
  THead as PrimaryTHead,
} from "../components/tables/PrimaryTable";
import {
  BodyRow as SecondaryBodyRow,
  FooterRow as SecondaryFooterRow,
  HeaderRow as SecondaryHeaderRow,
  Table as SecondaryTable,
  TBody as SecondaryTBody,
  Td as SecondaryTd,
  TFoot as SecondaryTFoot,
  Th as SecondaryTh,
  THead as SecondaryTHead,
} from "../components/tables/SecondaryTable";
import {
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../layout";
import {PolicyStatusPill} from "../policy/PolicyStatusPill";
import {PolicyTypeComponent} from "../policy/PolicyType";

import {CoverageTypeComponent} from "./CoverageType";

export const PoliciesTab: React.FC<{policies: Policy[]}> = ({policies}) => (
  <PrimarySection>
    {policies.length > 0 ? (
      <SecondarySection>
        <SecondarySectionContent>
          <PrimaryTable width="100%">
            <PrimaryTHead>
              <PrimaryHeaderRow>
                <PrimaryTh>Policy ID</PrimaryTh>
                <PrimaryTh>Sequence No.</PrimaryTh>
                <PrimaryTh>State</PrimaryTh>
                <PrimaryTh>Policy type</PrimaryTh>
                <PrimaryTh>Policy status</PrimaryTh>
                <PrimaryTh>Created</PrimaryTh>
                <PrimaryTh>Updated</PrimaryTh>
                <PrimaryTh />
              </PrimaryHeaderRow>
            </PrimaryTHead>
            <PrimaryTBody>
              {policies.map((policy) => (
                <PolicyRow key={policy.id} policy={policy} />
              ))}
            </PrimaryTBody>
          </PrimaryTable>
        </SecondarySectionContent>
      </SecondarySection>
    ) : (
      <Text value="No Policies" />
    )}
  </PrimarySection>
);

const PolicyRow: React.FC<{policy: Policy}> = ({
  policy: {
    id,
    type,
    coverage,
    endorsements,
    insured,
    insuredMortgagee,
    fees,
    parcels,
    premium,
    state,
    status,
    sequenceNumber,
    createdAt,
    updatedAt,
  },
}) => {
  const [detailsDropdownOpen, setDetailsDropdownOpen] = useState(false);

  const toggleDetailsDropdown = useCallback(() => {
    setDetailsDropdownOpen((v) => !v);
  }, []);

  const primarySectionGap = usePrimarySectionGap();

  return (
    <>
      <PrimaryBodyRow>
        <PrimaryTd>{id}</PrimaryTd>
        <PrimaryTd>{sequenceNumber}</PrimaryTd>
        <PrimaryTd>{state}</PrimaryTd>
        <PrimaryTd>
          <PolicyTypeComponent policyType={type} />
        </PrimaryTd>
        <PrimaryTd>
          <PolicyStatusPill status={status} />
        </PrimaryTd>
        <PrimaryTd>
          <DateTimeComponent value={createdAt} />
        </PrimaryTd>
        <PrimaryTd>
          <DateTimeComponent value={updatedAt} />
        </PrimaryTd>
        <PrimaryTd>
          <CaretIcon
            fontSize="xs"
            onClick={toggleDetailsDropdown}
            direction={
              detailsDropdownOpen ? CaretDirection.DOWN : CaretDirection.RIGHT
            }
          />
        </PrimaryTd>
      </PrimaryBodyRow>
      {detailsDropdownOpen ? (
        <PrimaryBodyRow>
          <PrimaryTd colSpan={8}>
            <Flex direction="column" gap={primarySectionGap}>
              <SecondarySection>
                <SecondarySectionHeaderContainer>
                  <SecondarySectionHeader value="Details" />
                </SecondarySectionHeaderContainer>
                <SecondarySectionContent>
                  <DataGrid>
                    <GridItem>
                      <GridItemLabelContainer>
                        <GridItemLabel value="Sub type" />
                      </GridItemLabelContainer>
                      <CoverageTypeComponent coverageType={coverage} />
                    </GridItem>
                    <GridItem>
                      <GridItemLabelContainer>
                        <GridItemLabel value="Policy Premium" />
                      </GridItemLabelContainer>
                      <CurrencyComponent value={premium} />
                    </GridItem>
                    <GridItem>
                      <GridItemLabelContainer>
                        <GridItemLabel
                          value={
                            <>
                              <TextRaw value="Total Premium " />
                              <GridItemLabelSubText value="(Premium + Endorsements)" />
                            </>
                          }
                        />
                      </GridItemLabelContainer>
                      <CurrencyComponent
                        value={premium + sum(endorsements.map((e) => e.cost))}
                      />
                    </GridItem>
                    <GridItem>
                      <GridItemLabel value="Insured" />
                      <Text value={insured} />
                    </GridItem>
                    <GridItem>
                      <GridItemLabel value="Insured Mortgagee" />
                      <StringMaybeBlankComponent value={insuredMortgagee} />
                    </GridItem>
                  </DataGrid>
                </SecondarySectionContent>
              </SecondarySection>
              <SecondarySection>
                <SecondarySectionHeaderContainer>
                  <SecondarySectionHeader value="Parcels" />
                </SecondarySectionHeaderContainer>
                <SecondarySectionContent>
                  <SecondaryTable width="100%">
                    <SecondaryTHead>
                      <SecondaryHeaderRow>
                        <SecondaryTh />
                        <SecondaryTh>Amount of Insurance</SecondaryTh>
                        <SecondaryTh>Address</SecondaryTh>
                      </SecondaryHeaderRow>
                    </SecondaryTHead>
                    <SecondaryTBody>
                      {parcels.map(({amountOfInsurance, address}) => {
                        const addressFormatted = singleLineAddress(address);
                        const key = `${amountOfInsurance}_${addressFormatted}`;
                        return (
                          <SecondaryBodyRow key={key}>
                            <SecondaryTd />
                            <SecondaryTd>
                              <CurrencyComponent value={amountOfInsurance} />
                            </SecondaryTd>
                            <SecondaryTd>
                              <SingleLineAddressStyle>
                                {addressFormatted}
                              </SingleLineAddressStyle>
                            </SecondaryTd>
                          </SecondaryBodyRow>
                        );
                      })}
                    </SecondaryTBody>
                    <SecondaryTFoot>
                      <SummaryRow>
                        <SecondaryTh scope="row">
                          <CurrencyWrapper value="Total" />
                        </SecondaryTh>
                        <SecondaryTd>
                          <CurrencyComponent
                            value={sum(parcels.map((p) => p.amountOfInsurance))}
                          />
                        </SecondaryTd>
                        <SecondaryTd />
                      </SummaryRow>
                    </SecondaryTFoot>
                  </SecondaryTable>
                </SecondarySectionContent>
              </SecondarySection>
              <SideBySideContainer>
                <SideBySideSecondarySection>
                  <SecondarySectionHeaderContainer>
                    <SecondarySectionHeader value="Endorsements" />
                  </SecondarySectionHeaderContainer>
                  <SecondarySectionContent>
                    <SecondaryTable>
                      <SecondaryTHead>
                        <SecondaryHeaderRow>
                          <SecondaryTh>Code</SecondaryTh>
                          <SecondaryTh>Cost</SecondaryTh>
                        </SecondaryHeaderRow>
                      </SecondaryTHead>
                      <SecondaryTBody>
                        {endorsements.map((endorsement) => (
                          <SecondaryBodyRow key={endorsement.code}>
                            <SecondaryTd>{endorsement.code}</SecondaryTd>
                            <SecondaryTd>
                              <CurrencyComponent value={endorsement.cost} />
                            </SecondaryTd>
                          </SecondaryBodyRow>
                        ))}
                      </SecondaryTBody>
                      <SecondaryTFoot>
                        <SummaryRow>
                          <SecondaryTd>
                            <CurrencyWrapper value="Total" />
                          </SecondaryTd>
                          <SecondaryTd>
                            <CurrencyComponent
                              value={sum(endorsements.map((e) => e.cost))}
                            />
                          </SecondaryTd>
                        </SummaryRow>
                      </SecondaryTFoot>
                    </SecondaryTable>
                  </SecondarySectionContent>
                </SideBySideSecondarySection>

                <SideBySideSecondarySection>
                  <SecondarySectionHeaderContainer>
                    <SecondarySectionHeader value="Fees" />
                  </SecondarySectionHeaderContainer>
                  <SecondarySectionContent>
                    <SecondaryTable>
                      <SecondaryTHead>
                        <SecondaryHeaderRow>
                          <SecondaryTh>Type</SecondaryTh>
                          <SecondaryTh>Fee</SecondaryTh>
                        </SecondaryHeaderRow>
                      </SecondaryTHead>
                      <SecondaryTBody>
                        {fees.map((fee) => (
                          <SecondaryBodyRow key={fee.code}>
                            <SecondaryTd>{fee.code}</SecondaryTd>
                            <SecondaryTd>
                              <CurrencyComponent value={fee.cost} />
                            </SecondaryTd>
                          </SecondaryBodyRow>
                        ))}
                      </SecondaryTBody>
                      <SecondaryTFoot>
                        <SummaryRow>
                          <SecondaryTd>
                            <CurrencyWrapper value="Total" />
                          </SecondaryTd>
                          <SecondaryTd>
                            <CurrencyComponent
                              value={sum(fees.map((f) => f.cost))}
                            />
                          </SecondaryTd>
                        </SummaryRow>
                      </SecondaryTFoot>
                    </SecondaryTable>
                  </SecondarySectionContent>
                </SideBySideSecondarySection>
              </SideBySideContainer>
            </Flex>
          </PrimaryTd>
        </PrimaryBodyRow>
      ) : null}
    </>
  );
};

const SideBySideSecondarySection: React.FC<
  React.ComponentProps<typeof SecondarySection>
> = (props) => <SecondarySection {...buildSideBySideChildProps()} {...props} />;

const sum = (numbers: number[]) => numbers.reduce((acc, n) => acc + n, 0);

const SummaryRow: React.FC<React.ComponentProps<typeof SecondaryFooterRow>> = (
  props,
) => {
  const {
    tokens: {
      colors: {
        background: {success},
      },
    },
  } = useTheme();
  return <SecondaryFooterRow backgroundColor={success} {...props} />;
};
