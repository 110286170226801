/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetAuditAttachmentsSuccessResponseAttachment } from './GetAuditAttachmentsSuccessResponseAttachment';
import {
    GetAuditAttachmentsSuccessResponseAttachmentFromJSON,
    GetAuditAttachmentsSuccessResponseAttachmentFromJSONTyped,
    GetAuditAttachmentsSuccessResponseAttachmentToJSON,
} from './GetAuditAttachmentsSuccessResponseAttachment';

/**
 * 
 * @export
 * @interface GetAuditAttachmentsSuccessResponse
 */
export interface GetAuditAttachmentsSuccessResponse {
    /**
     * 
     * @type {Array<GetAuditAttachmentsSuccessResponseAttachment>}
     * @memberof GetAuditAttachmentsSuccessResponse
     */
    attachments: Array<GetAuditAttachmentsSuccessResponseAttachment>;
}

/**
 * Check if a given object implements the GetAuditAttachmentsSuccessResponse interface.
 */
export function instanceOfGetAuditAttachmentsSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "attachments" in value;

    return isInstance;
}

export function GetAuditAttachmentsSuccessResponseFromJSON(json: any): GetAuditAttachmentsSuccessResponse {
    return GetAuditAttachmentsSuccessResponseFromJSONTyped(json, false);
}

export function GetAuditAttachmentsSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAuditAttachmentsSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attachments': ((json['attachments'] as Array<any>).map(GetAuditAttachmentsSuccessResponseAttachmentFromJSON)),
    };
}

export function GetAuditAttachmentsSuccessResponseToJSON(value?: GetAuditAttachmentsSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attachments': ((value.attachments as Array<any>).map(GetAuditAttachmentsSuccessResponseAttachmentToJSON)),
    };
}

