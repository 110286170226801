import {
  FormikConnectedAmplifySelectField,
  Text,
  VerticalFieldset,
} from "@title-service/ui";
import {enumValues, notNullOrUndefined} from "@title-service/utils";
import {
  buildEnumSchema,
  transformNullToUndefined,
} from "@title-service/yup-utils";
import {useFormikContext} from "formik";
import React, {useEffect, useMemo} from "react";
import * as Yup from "yup";

import {ClaimResponsiblePartyType} from "../adminApi";
import {safeDisplayEnumFn} from "../util/enum";

export enum ResponsiblePartyGroup {
  R = "R",
  S = "S",
  T = "T",
  U = "U",
}

export const unsafeDisplayResponsiblePartyGroup = (
  responsiblePartyGroup: ResponsiblePartyGroup,
): string => {
  switch (responsiblePartyGroup) {
    case ResponsiblePartyGroup.R:
      return "ADTIC";
    case ResponsiblePartyGroup.S:
      return "Unauthorized Risk";
    case ResponsiblePartyGroup.T:
      return "Irregularity or Omission";
    case ResponsiblePartyGroup.U:
      return "Company Practice Risk";
    default:
      throw new Error(
        `Unknown Responsible Party Group: ${responsiblePartyGroup}`,
      );
  }
};

export const displayResponsiblePartyGroup = safeDisplayEnumFn(
  unsafeDisplayResponsiblePartyGroup,
);

const BY_INDIVIDUAL_PRODUCER = "By Individual Producer";
const BY_APPROVED_ATTORNEY = "By Approved Attorney";
const BY_INDEPENDENT_CONTRACTOR = "By Independent Contractor";
const BY_EMPLOYEE = "By Employee";

export const unsafeDisplayResponsiblePartyType = (
  responsiblePartyType: ClaimResponsiblePartyType,
): string => {
  switch (responsiblePartyType) {
    case ClaimResponsiblePartyType.R1:
      return BY_INDIVIDUAL_PRODUCER;
    case ClaimResponsiblePartyType.R2:
      return BY_APPROVED_ATTORNEY;
    case ClaimResponsiblePartyType.R3:
      return BY_INDEPENDENT_CONTRACTOR;
    case ClaimResponsiblePartyType.R4:
      return BY_EMPLOYEE;
    case ClaimResponsiblePartyType.S1:
      return BY_INDIVIDUAL_PRODUCER;
    case ClaimResponsiblePartyType.S2:
      return BY_APPROVED_ATTORNEY;
    case ClaimResponsiblePartyType.S3:
      return BY_INDEPENDENT_CONTRACTOR;
    case ClaimResponsiblePartyType.S4:
      return BY_EMPLOYEE;
    case ClaimResponsiblePartyType.T1:
      return BY_INDIVIDUAL_PRODUCER;
    case ClaimResponsiblePartyType.T2:
      return BY_APPROVED_ATTORNEY;
    case ClaimResponsiblePartyType.T3:
      return BY_INDEPENDENT_CONTRACTOR;
    case ClaimResponsiblePartyType.T4:
      return BY_EMPLOYEE;
    case ClaimResponsiblePartyType.U:
      return "Company Practice Risk";
    default:
      throw new Error(
        `Unknown Responsible Party Type: ${responsiblePartyType}`,
      );
  }
};

export const displayResponsiblePartyType = safeDisplayEnumFn(
  unsafeDisplayResponsiblePartyType,
);

export const RESPONSIBLE_PARTY_FORM_HIERARCHY: Map<
  ResponsiblePartyGroup,
  ClaimResponsiblePartyType[]
> = new Map<ResponsiblePartyGroup, ClaimResponsiblePartyType[]>([
  [
    ResponsiblePartyGroup.R,
    [
      ClaimResponsiblePartyType.R1,
      ClaimResponsiblePartyType.R2,
      ClaimResponsiblePartyType.R3,
      ClaimResponsiblePartyType.R4,
    ],
  ],
  [
    ResponsiblePartyGroup.S,
    [
      ClaimResponsiblePartyType.S1,
      ClaimResponsiblePartyType.S2,
      ClaimResponsiblePartyType.S3,
      ClaimResponsiblePartyType.S4,
    ],
  ],
  [
    ResponsiblePartyGroup.T,
    [
      ClaimResponsiblePartyType.T1,
      ClaimResponsiblePartyType.T2,
      ClaimResponsiblePartyType.T3,
      ClaimResponsiblePartyType.T4,
    ],
  ],
  [ResponsiblePartyGroup.U, [ClaimResponsiblePartyType.U]],
]);

const findGroup = (
  responsibleParty: ClaimResponsiblePartyType,
): ResponsiblePartyGroup | undefined => {
  for (const [
    responsiblePartyGroup,
    responsiblePartyGroupCodes,
  ] of RESPONSIBLE_PARTY_FORM_HIERARCHY) {
    if (responsiblePartyGroupCodes.includes(responsibleParty)) {
      return responsiblePartyGroup;
    }
  }
  return undefined;
};

export type ResponsiblePartyFormData = {
  group: string;
  code: string;
};

export type CompleteResponsiblePartyFormData = Partial<{
  group: ResponsiblePartyGroup;
  code: ClaimResponsiblePartyType;
}>;

export const RESPONSIBLE_PARTY_GROUP_SCHEMA: Yup.StringSchema<
  ResponsiblePartyGroup | undefined
> = buildEnumSchema(
  ResponsiblePartyGroup,
  "Must be a valid Responsible Party code group",
);

const RESPONSIBLE_PARTY_CODE_SCHEMA: Yup.StringSchema<
  ClaimResponsiblePartyType | undefined
> = Yup.string()
  .optional()
  .trim()
  .transform(transformNullToUndefined)
  .when("group", ([group], builder) => {
    const validCodesForGroup: ClaimResponsiblePartyType[] | undefined =
      RESPONSIBLE_PARTY_FORM_HIERARCHY.get(group as ResponsiblePartyGroup);
    if (notNullOrUndefined(validCodesForGroup)) {
      if (validCodesForGroup.length === 1) {
        return builder.transform(() => validCodesForGroup[0]);
      }
      return builder.oneOf(
        validCodesForGroup,
        "Must be a valid Responsible Party code",
      );
    }
    return builder.transform(() => undefined);
  }) as Yup.StringSchema<ClaimResponsiblePartyType | undefined>;

export const RESPONSIBLE_PARTY_FORM_SCHEMA: Yup.ObjectSchema<CompleteResponsiblePartyFormData> =
  Yup.object({
    group: RESPONSIBLE_PARTY_GROUP_SCHEMA,
    code: RESPONSIBLE_PARTY_CODE_SCHEMA,
  });

export const mapCompleteResponsiblePartyFormDataToResponsiblePartyCode = ({
  code,
}: CompleteResponsiblePartyFormData): ClaimResponsiblePartyType | undefined =>
  code;

export const buildInitialResponsiblePartyFormData = (
  responsibleParty: ClaimResponsiblePartyType | undefined,
): ResponsiblePartyFormData => {
  const group = responsibleParty && findGroup(responsibleParty);
  if (notNullOrUndefined(group)) {
    return {
      group,
      code: responsibleParty ?? "",
    };
  }
  return {
    group: "",
    code: "",
  };
};

export const ResponsiblePartyField: React.FC<
  React.ComponentProps<typeof VerticalFieldset> & {propertyName?: string}
> = ({propertyName, ...props}) => {
  const propertyNamePrefix = useMemo(
    () => (propertyName ? `${propertyName}.` : ``),
    [propertyName],
  );
  const groupFieldName = useMemo(
    () => `${propertyNamePrefix}group`,
    [propertyNamePrefix],
  );
  const codeFieldName = useMemo(
    () => `${propertyNamePrefix}code`,
    [propertyNamePrefix],
  );
  const {getFieldMeta, setFieldValue} = useFormikContext();
  const groupFieldMetadata = getFieldMeta<ResponsiblePartyGroup | undefined>(
    groupFieldName,
  );
  const selectedGroup = groupFieldMetadata.value;
  const codeOptions = useMemo(
    () =>
      (selectedGroup && RESPONSIBLE_PARTY_FORM_HIERARCHY.get(selectedGroup)) ??
      [],
    [selectedGroup],
  );
  const codeFieldMetadata = getFieldMeta<ClaimResponsiblePartyType | undefined>(
    codeFieldName,
  );
  const selectedCode = codeFieldMetadata.value;
  useEffect(() => {
    if (!selectedCode && codeOptions.length === 1) {
      setFieldValue(codeFieldName, codeOptions[0]);
    }
  }, [codeFieldName, codeOptions, selectedCode, setFieldValue]);
  return (
    <VerticalFieldset gap="small" {...props}>
      <FormikConnectedAmplifySelectField
        testId="responsible-party-group-input"
        label="Responsible Party (optional)"
        placeholder=""
        name={groupFieldName}
      >
        <option value="" />
        {enumValues(ResponsiblePartyGroup).map((responsiblePartyGroup) => (
          <option key={responsiblePartyGroup} value={responsiblePartyGroup}>
            {displayResponsiblePartyGroup(responsiblePartyGroup)}
          </option>
        ))}
      </FormikConnectedAmplifySelectField>
      {codeOptions.length > 1 && (
        <FormikConnectedAmplifySelectField
          testId="responsible-party-code-input"
          label="Responsible Party Code"
          labelHidden={true}
          placeholder=""
          name={codeFieldName}
        >
          <option value="" />
          {codeOptions.map((responsiblePartyType) => (
            <option key={responsiblePartyType} value={responsiblePartyType}>
              {displayResponsiblePartyType(responsiblePartyType)}
            </option>
          ))}
        </FormikConnectedAmplifySelectField>
      )}
    </VerticalFieldset>
  );
};

export const ResponsiblePartyComponentRaw: React.FC<{
  responsibleParty: ClaimResponsiblePartyType;
}> = ({responsibleParty}) => {
  const group = findGroup(responsibleParty);
  const groupDisplay = group && displayResponsiblePartyGroup(group);
  const codeDisplay = displayResponsiblePartyType(responsibleParty);
  return (
    <>
      {groupDisplay === codeDisplay
        ? codeDisplay
        : `${groupDisplay ?? ""} – ${codeDisplay}`}
    </>
  );
};

export const ResponsiblePartyComponent: React.FC<
  Omit<React.ComponentProps<typeof Text>, "value"> & {
    responsibleParty: ClaimResponsiblePartyType;
  }
> = ({responsibleParty, ...props}) => (
  <Text
    {...props}
    value={<ResponsiblePartyComponentRaw responsibleParty={responsibleParty} />}
  />
);
