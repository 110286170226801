/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgencyProducerReference } from './AgencyProducerReference';
import {
    AgencyProducerReferenceFromJSON,
    AgencyProducerReferenceFromJSONTyped,
    AgencyProducerReferenceToJSON,
} from './AgencyProducerReference';
import type { GetAuditsSuccessResponseAudit } from './GetAuditsSuccessResponseAudit';
import {
    GetAuditsSuccessResponseAuditFromJSON,
    GetAuditsSuccessResponseAuditFromJSONTyped,
    GetAuditsSuccessResponseAuditToJSON,
} from './GetAuditsSuccessResponseAudit';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';

/**
 * 
 * @export
 * @interface GetAuditsSuccessResponse
 */
export interface GetAuditsSuccessResponse {
    /**
     * 
     * @type {Set<AgencyProducerReference>}
     * @memberof GetAuditsSuccessResponse
     */
    agencyFilterOptions: Set<AgencyProducerReference>;
    /**
     * 
     * @type {Array<GetAuditsSuccessResponseAudit>}
     * @memberof GetAuditsSuccessResponse
     */
    audits: Array<GetAuditsSuccessResponseAudit>;
    /**
     * 
     * @type {Pagination}
     * @memberof GetAuditsSuccessResponse
     */
    pagination: Pagination;
    /**
     * 
     * @type {Set<string>}
     * @memberof GetAuditsSuccessResponse
     */
    stateFilterOptions: Set<string>;
    /**
     * 
     * @type {Set<number>}
     * @memberof GetAuditsSuccessResponse
     */
    yearFilterOptions: Set<number>;
}

/**
 * Check if a given object implements the GetAuditsSuccessResponse interface.
 */
export function instanceOfGetAuditsSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "agencyFilterOptions" in value;
    isInstance = isInstance && "audits" in value;
    isInstance = isInstance && "pagination" in value;
    isInstance = isInstance && "stateFilterOptions" in value;
    isInstance = isInstance && "yearFilterOptions" in value;

    return isInstance;
}

export function GetAuditsSuccessResponseFromJSON(json: any): GetAuditsSuccessResponse {
    return GetAuditsSuccessResponseFromJSONTyped(json, false);
}

export function GetAuditsSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAuditsSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agencyFilterOptions': (new Set((json['agencyFilterOptions'] as Array<any>).map(AgencyProducerReferenceFromJSON))),
        'audits': ((json['audits'] as Array<any>).map(GetAuditsSuccessResponseAuditFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
        'stateFilterOptions': json['stateFilterOptions'],
        'yearFilterOptions': json['yearFilterOptions'],
    };
}

export function GetAuditsSuccessResponseToJSON(value?: GetAuditsSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agencyFilterOptions': (Array.from(value.agencyFilterOptions as Set<any>).map(AgencyProducerReferenceToJSON)),
        'audits': ((value.audits as Array<any>).map(GetAuditsSuccessResponseAuditToJSON)),
        'pagination': PaginationToJSON(value.pagination),
        'stateFilterOptions': Array.from(value.stateFilterOptions as Set<any>),
        'yearFilterOptions': Array.from(value.yearFilterOptions as Set<any>),
    };
}

