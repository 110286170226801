import {useMutation} from "@tanstack/react-query";
import {
  FieldsetHeading,
  FormikConnectedAmplifySubmitButton,
  FormikForm,
  FormResultErrorMessage,
  FormResultSuccessMessage,
  VerticalFieldset,
} from "@title-service/ui";
import {Formik, FormikHelpers} from "formik";
import React, {useCallback, useContext} from "react";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";

import {DependencyContext} from "../DependencyContext";
import {AgencyProducerReference, CreateAuditRequest} from "../shared/adminApi";
import {
  AGENCY_REFERENCE_ID_SCHEMA,
  AgencyReferenceIdField,
  AUDIT_SCOPE_SCHEMA,
  AuditScopeField,
  COMPLETED_ON_SCHEMA,
  CompletedOnField,
  DESCRIPTION_SCHEMA,
  DescriptionField,
  DUE_ON_SCHEMA,
  DueOnField,
  STARTING_LETTER_SENT_ON_SCHEMA,
  StartingLetterSentOnField,
  STATE_SCHEMA,
  StateField,
  TARGET_COMPLETE_ON_SCHEMA,
  TARGET_START_ON_SCHEMA,
  TargetCompleteOnField,
  TargetStartOnField,
} from "../shared/audit/AuditDetailFormFields";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
  PrimarySection,
} from "../shared/layout";
import {
  AgencyProducerField,
  AgencyProducerFormData,
  buildAgencyProducerFormInitialData,
  buildRequiredAgencyProducerFormSchema,
} from "../shared/producers/AgencyProducerField";

export type CreateAuditFormData = Required<
  Omit<
    CreateAuditRequest,
    | "agencyId"
    | "startingLetterSentOn"
    | "completedOn"
    | "dueOn"
    | "targetCompleteOn"
    | "targetStartOn"
  >
> & {
  agency: AgencyProducerFormData;
  startingLetterSentOn: string;
  completedOn: string;
  dueOn: string;
  targetCompleteOn: string;
  targetStartOn: string;
};

export type CompleteCreateAuditFormData = Omit<
  CreateAuditRequest,
  "agencyId"
> & {
  agency: AgencyProducerReference;
};

export const CREATED_AUDIT_FORM_SCHEMA: Yup.ObjectSchema<CompleteCreateAuditFormData> =
  Yup.object({
    agency: buildRequiredAgencyProducerFormSchema(),
    agencyReferenceId: AGENCY_REFERENCE_ID_SCHEMA,
    auditScope: AUDIT_SCOPE_SCHEMA,
    completedOn: COMPLETED_ON_SCHEMA,
    description: DESCRIPTION_SCHEMA,
    dueOn: DUE_ON_SCHEMA,
    startingLetterSentOn: STARTING_LETTER_SENT_ON_SCHEMA,
    state: STATE_SCHEMA,
    targetCompleteOn: TARGET_COMPLETE_ON_SCHEMA,
    targetStartOn: TARGET_START_ON_SCHEMA,
  });

const buildCreateAuditFormInitialValues = (): CreateAuditFormData => ({
  agency: buildAgencyProducerFormInitialData(),
  agencyReferenceId: "",
  auditScope: "",
  state: "",
  description: "",
  startingLetterSentOn: "",
  completedOn: "",
  dueOn: "",
  targetCompleteOn: "",
  targetStartOn: "",
});

export const mapFormDataToCreateAuditRequest = (
  formData: CreateAuditFormData,
): CreateAuditRequest => {
  const {agency, ...completeFormData} = CREATED_AUDIT_FORM_SCHEMA.validateSync(
    formData,
    {
      stripUnknown: true,
    },
  );
  return {
    ...completeFormData,
    agencyId: agency.id,
  };
};

export const AuditCreateRoute: React.FC = () => {
  const {adminApi} = useContext(DependencyContext);
  const createAuditMutation = useMutation({
    mutationFn: adminApi.createAudit,
  });
  const navigate = useNavigate();
  const submitHandler = useCallback(
    (
      formData: CreateAuditFormData,
      {setSubmitting}: FormikHelpers<CreateAuditFormData>,
    ) => {
      const createAuditRequest = mapFormDataToCreateAuditRequest(formData);
      createAuditMutation.mutate(createAuditRequest, {
        onSuccess: ({id}) => {
          navigate(`/audits/${id}/`, {replace: false});
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [createAuditMutation, navigate],
  );
  return (
    <>
      <BodyHeader>
        <PrimaryBodyHeaderContainer>
          <PrimaryBodyHeader value="Create Audit" />
        </PrimaryBodyHeaderContainer>
      </BodyHeader>
      <BodyContent>
        <PrimarySection>
          <Formik<CreateAuditFormData>
            initialValues={buildCreateAuditFormInitialValues() as any}
            validationSchema={CREATED_AUDIT_FORM_SCHEMA as any}
            onSubmit={submitHandler}
          >
            <FormikForm>
              <FieldsetHeading value="Audit Details" />

              <VerticalFieldset>
                <AgencyProducerField
                  label="Agency"
                  name="agency"
                  minWidth="450px"
                />

                <AgencyReferenceIdField
                  name="agencyReferenceId"
                  minWidth="450px"
                />

                <StateField name="state" minWidth="150px" />

                <AuditScopeField name="auditScope" minWidth="450px" />

                <StartingLetterSentOnField
                  name="startingLetterSentOn"
                  minWidth="300px"
                />

                <TargetStartOnField name="targetStartOn" minWidth="300px" />

                <TargetCompleteOnField
                  name="targetCompleteOn"
                  minWidth="300px"
                />

                <DueOnField name="dueOn" minWidth="300px" />

                <CompletedOnField name="completedOn" minWidth="300px" />

                <DescriptionField name="description" minWidth="450px" />
              </VerticalFieldset>

              <FormikConnectedAmplifySubmitButton />

              {createAuditMutation.isError ? (
                <FormResultErrorMessage>Request failed.</FormResultErrorMessage>
              ) : null}
              {createAuditMutation.isSuccess ? (
                <FormResultSuccessMessage>
                  Audit successfully created. Redirecting to Audit Detail...
                </FormResultSuccessMessage>
              ) : null}
            </FormikForm>
          </Formik>
        </PrimarySection>
      </BodyContent>
    </>
  );
};
