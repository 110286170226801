/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PropertyDocumentFlag } from './PropertyDocumentFlag';
import {
    PropertyDocumentFlagFromJSON,
    PropertyDocumentFlagFromJSONTyped,
    PropertyDocumentFlagToJSON,
} from './PropertyDocumentFlag';

/**
 * 
 * @export
 * @interface PropertyDocumentFlagActive
 */
export interface PropertyDocumentFlagActive extends PropertyDocumentFlag {
    /**
     * 
     * @type {string}
     * @memberof PropertyDocumentFlagActive
     */
    comment: string;
}

/**
 * Check if a given object implements the PropertyDocumentFlagActive interface.
 */
export function instanceOfPropertyDocumentFlagActive(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "comment" in value;

    return isInstance;
}

export function PropertyDocumentFlagActiveFromJSON(json: any): PropertyDocumentFlagActive {
    return PropertyDocumentFlagActiveFromJSONTyped(json, false);
}

export function PropertyDocumentFlagActiveFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyDocumentFlagActive {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PropertyDocumentFlagFromJSONTyped(json, ignoreDiscriminator),
        'comment': json['comment'],
    };
}

export function PropertyDocumentFlagActiveToJSON(value?: PropertyDocumentFlagActive | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PropertyDocumentFlagToJSON(value),
        'comment': value.comment,
    };
}

