export const parseBoolean = (
  value: string | null | undefined,
): boolean | undefined => {
  const trimmed = value?.trim();
  if (trimmed === "true") {
    return true;
  } else if (trimmed === "false") {
    return false;
  }
  return undefined;
};

if (import.meta.vitest) {
  const {describe, expect, it} = import.meta.vitest;

  describe("parseBoolean", () => {
    it("returns true when true", () => {
      expect(parseBoolean("true")).toBe(true);
    });

    it("returns true when true with whitespace", () => {
      expect(parseBoolean("\ntrue ")).toBe(true);
    });

    it("returns false when false", () => {
      expect(parseBoolean("false")).toBe(false);
    });

    it("returns false when true with whitespace", () => {
      expect(parseBoolean("\nfalse ")).toBe(false);
    });

    it("returns undefined when null", () => {
      expect(parseBoolean(null)).toBeUndefined();
    });

    it("returns undefined when undefined", () => {
      expect(parseBoolean(undefined)).toBeUndefined();
    });

    it("returns undefined when unknown value", () => {
      expect(parseBoolean("unknown")).toBeUndefined();
    });
  });
}
