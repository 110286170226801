/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetOrderUnderwritingNotesSuccessResponseNote } from './GetOrderUnderwritingNotesSuccessResponseNote';
import {
    GetOrderUnderwritingNotesSuccessResponseNoteFromJSON,
    GetOrderUnderwritingNotesSuccessResponseNoteFromJSONTyped,
    GetOrderUnderwritingNotesSuccessResponseNoteToJSON,
} from './GetOrderUnderwritingNotesSuccessResponseNote';

/**
 * 
 * @export
 * @interface GetOrderUnderwritingNotesSuccessResponse
 */
export interface GetOrderUnderwritingNotesSuccessResponse {
    /**
     * 
     * @type {Array<GetOrderUnderwritingNotesSuccessResponseNote>}
     * @memberof GetOrderUnderwritingNotesSuccessResponse
     */
    notes: Array<GetOrderUnderwritingNotesSuccessResponseNote>;
}

/**
 * Check if a given object implements the GetOrderUnderwritingNotesSuccessResponse interface.
 */
export function instanceOfGetOrderUnderwritingNotesSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "notes" in value;

    return isInstance;
}

export function GetOrderUnderwritingNotesSuccessResponseFromJSON(json: any): GetOrderUnderwritingNotesSuccessResponse {
    return GetOrderUnderwritingNotesSuccessResponseFromJSONTyped(json, false);
}

export function GetOrderUnderwritingNotesSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrderUnderwritingNotesSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notes': ((json['notes'] as Array<any>).map(GetOrderUnderwritingNotesSuccessResponseNoteFromJSON)),
    };
}

export function GetOrderUnderwritingNotesSuccessResponseToJSON(value?: GetOrderUnderwritingNotesSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notes': ((value.notes as Array<any>).map(GetOrderUnderwritingNotesSuccessResponseNoteToJSON)),
    };
}

