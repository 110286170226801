/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InsurfulOrderDocument
 */
export interface InsurfulOrderDocument {
    /**
     * 
     * @type {string}
     * @memberof InsurfulOrderDocument
     */
    documentName: string;
    /**
     * 
     * @type {string}
     * @memberof InsurfulOrderDocument
     */
    documentNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurfulOrderDocument
     */
    documentTypeDescription: string;
    /**
     * 
     * @type {string}
     * @memberof InsurfulOrderDocument
     */
    documentUrl: string;
    /**
     * 
     * @type {Date}
     * @memberof InsurfulOrderDocument
     */
    recordingDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof InsurfulOrderDocument
     */
    rulesetId: number;
}

/**
 * Check if a given object implements the InsurfulOrderDocument interface.
 */
export function instanceOfInsurfulOrderDocument(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "documentName" in value;
    isInstance = isInstance && "documentTypeDescription" in value;
    isInstance = isInstance && "documentUrl" in value;
    isInstance = isInstance && "rulesetId" in value;

    return isInstance;
}

export function InsurfulOrderDocumentFromJSON(json: any): InsurfulOrderDocument {
    return InsurfulOrderDocumentFromJSONTyped(json, false);
}

export function InsurfulOrderDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsurfulOrderDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentName': json['documentName'],
        'documentNumber': !exists(json, 'documentNumber') ? undefined : json['documentNumber'],
        'documentTypeDescription': json['documentTypeDescription'],
        'documentUrl': json['documentUrl'],
        'recordingDate': !exists(json, 'recordingDate') ? undefined : (new Date(json['recordingDate'])),
        'rulesetId': json['rulesetId'],
    };
}

export function InsurfulOrderDocumentToJSON(value?: InsurfulOrderDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentName': value.documentName,
        'documentNumber': value.documentNumber,
        'documentTypeDescription': value.documentTypeDescription,
        'documentUrl': value.documentUrl,
        'recordingDate': value.recordingDate === undefined ? undefined : (value.recordingDate.toISOString().substr(0,10)),
        'rulesetId': value.rulesetId,
    };
}

