import {Heading, View} from "@aws-amplify/ui-react";
import {useMutation} from "@tanstack/react-query";
import {
  DateComponent,
  FormikConnectedAmplifyDateField,
  FormikConnectedAmplifySubmitButton,
  FormikConnectedAmplifyTextField,
  FormikForm,
  FormResultErrorMessage,
  FormResultSuccessMessage,
  VerticalFieldset,
} from "@title-service/ui";
import {buildDateSchema} from "@title-service/yup-utils";
import {Formik} from "formik";
import React, {useCallback, useContext, useState} from "react";
import * as Yup from "yup";

import {DependencyContext} from "../../DependencyContext";
import {
  CreateAgencyProducerLicenseRequest,
  CreateIndividualProducerLicenseRequest,
  JurisdictionReference,
  ProducerJurisdictionStatus,
  RenewAgencyProducerLicenseRequest,
  RenewIndividualProducerLicenseRequest,
} from "../adminApi";
import {ProducerLicense} from "../adminApi/fetchClient";
import {Modal} from "../components/Modal";
import {RenewIcon} from "../components/icons";

import {JurisdictionAutocomplete} from "./JurisdictionAutocomplete";
import {
  buildJurisdictionFormInitialData,
  buildRequiredJurisdictionFormSchema,
  JurisdictionField,
  JurisdictionFormData,
} from "./JurisdictionField";
import {ProducerId} from "./ProducerId";
import {
  isAgencyProducerId,
  JurisdictionGridItem,
  JurisdictionLabel,
  JurisdictionTertiaryHeaderAndIconContainer,
  mapProducerIdToProducerTypeId,
} from "./ProducerJurisdictionsSection";
import {useSubmitHandler} from "./UseSubmitHandler";

const MODAL_CONTENT_STYLE: React.CSSProperties = {
  minWidth: "650px",
};
export const ProducerLicenseSection: React.FC<{
  mostRecentLicense?: ProducerLicense;
  producerId: ProducerId;
  jurisdictionStatus: ProducerJurisdictionStatus;
  onReloadProducerJurisdictions: () => any;
}> = ({
  mostRecentLicense,
  producerId,
  jurisdictionStatus,
  onReloadProducerJurisdictions,
}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);
  return (
    <View>
      <JurisdictionTertiaryHeaderAndIconContainer>
        <Heading level={6}>
          License{" "}
          {mostRecentLicense ? `No. ${mostRecentLicense.licenseNumber}` : null}
        </Heading>
        {jurisdictionStatus === ProducerJurisdictionStatus.Active ? (
          <RenewIcon onClick={openForm}>Renew</RenewIcon>
        ) : null}
      </JurisdictionTertiaryHeaderAndIconContainer>
      {mostRecentLicense ? (
        <>
          <JurisdictionGridItem>
            <JurisdictionLabel>Effective Date</JurisdictionLabel>
            <DateComponent value={mostRecentLicense.effectiveDate} />
          </JurisdictionGridItem>
          <JurisdictionGridItem>
            <JurisdictionLabel>Expiration Date</JurisdictionLabel>
            <DateComponent value={mostRecentLicense.expirationDate} />
          </JurisdictionGridItem>
          <Modal
            contentStyle={MODAL_CONTENT_STYLE}
            isOpen={displayForm}
            onClose={closeForm}
            title="Renew License"
          >
            <RenewProducerLicenseForm
              producerId={producerId}
              mostRecentLicense={mostRecentLicense}
              onClose={closeForm}
              onSubmitComplete={onReloadProducerJurisdictions}
            />
          </Modal>
        </>
      ) : null}
    </View>
  );
};

export const LICENSE_NUMBER_SCHEMA: Yup.StringSchema<string> = Yup.string()
  .required("License number is required")
  .trim();

export const LicenseNumberField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = (props) => (
  <FormikConnectedAmplifyTextField
    testId="license-number-input"
    label="License Number"
    {...props}
  />
);

export const EFFECTIVE_DATE_SCHEMA: Yup.DateSchema<Date> =
  buildDateSchema().required("Effective date is required");

export const EffectiveDateField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyDateField>, "label">
> = (props) => (
  <FormikConnectedAmplifyDateField
    testId="effective-date-input"
    label="Effective Date"
    {...props}
  />
);

export const EXPIRATION_DATE_SCHEMA: Yup.DateSchema<Date> =
  buildDateSchema().required("Expiration date is required");

export const ExpirationDateField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyDateField>, "label">
> = (props) => (
  <FormikConnectedAmplifyDateField
    testId="Expiration-date-input"
    label="Expiration Date"
    {...props}
  />
);

export type CreateProducerLicenseAndJurisdictionFormData = Omit<
  CreateAgencyProducerLicenseRequest,
  | "agencyProducerId"
  | "jurisdiction"
  | "licenseNumber"
  | "effectiveDate"
  | "expirationDate"
> & {
  jurisdiction: JurisdictionFormData;
  licenseNumber: string;
  effectiveDate: string;
  expirationDate: string;
};

type CompleteCreateProducerLicenseAndJurisdictionFormData = Omit<
  CreateAgencyProducerLicenseRequest,
  "agencyProducerId" | "jurisdiction"
> & {jurisdiction: JurisdictionReference};

export const CREATE_PRODUCER_LICENSE_AND_JURISDICTION_FORM_SCHEMA: Yup.ObjectSchema<CompleteCreateProducerLicenseAndJurisdictionFormData> =
  Yup.object({
    jurisdiction: buildRequiredJurisdictionFormSchema(),
    licenseNumber: LICENSE_NUMBER_SCHEMA,
    effectiveDate: EFFECTIVE_DATE_SCHEMA,
    expirationDate: EXPIRATION_DATE_SCHEMA,
  });

export const buildInitialCreateProducerLicenseAndJurisdictionFormData =
  (): CreateProducerLicenseAndJurisdictionFormData => ({
    jurisdiction: buildJurisdictionFormInitialData(),
    licenseNumber: "",
    effectiveDate: "",
    expirationDate: "",
  });
export const mapProducerLicenseAndJurisdictionFormDataToCreateAgencyProducerLicenseRequest =
  (
    agencyProducerId: string,
    formData: CreateProducerLicenseAndJurisdictionFormData,
  ): CreateAgencyProducerLicenseRequest => {
    const {jurisdiction, ...completeFormData} =
      CREATE_PRODUCER_LICENSE_AND_JURISDICTION_FORM_SCHEMA.validateSync(
        formData,
        {stripUnknown: true},
      );
    return {
      ...completeFormData,
      agencyProducerId,
      jurisdiction: jurisdiction.name,
    };
  };

export const mapProducerLicenseAndJurisdictionFormDataToCreateIndividualProducerLicenseRequest =
  (
    individualProducerId: string,
    formData: CreateProducerLicenseAndJurisdictionFormData,
  ): CreateIndividualProducerLicenseRequest => {
    const {jurisdiction, ...completeFormData} =
      CREATE_PRODUCER_LICENSE_AND_JURISDICTION_FORM_SCHEMA.validateSync(
        formData,
        {stripUnknown: true},
      );
    return {
      ...completeFormData,
      individualProducerId,
      jurisdiction: jurisdiction.name,
    };
  };

export const CreateProducerLicenseAndJurisdictionForm: React.FC<{
  JurisdictionAutocomplete?: typeof JurisdictionAutocomplete;
  producerId: ProducerId;
  onClose: () => any;
  onSubmitComplete: () => any;
}> = ({
  JurisdictionAutocomplete:
    JurisdictionAutocomplete_ = JurisdictionAutocomplete,
  producerId,
  onClose,
  onSubmitComplete,
}) => {
  const {adminApi} = useContext(DependencyContext);
  const createAgencyProducerLicenseMutation = useMutation({
    mutationFn: adminApi.createAgencyProducerLicense,
    onSuccess: () => {
      onSubmitComplete();
    },
  });
  const createIndividualProducerLicenseMutation = useMutation({
    mutationFn: adminApi.createIndividualProducerLicense,
    onSuccess: () => {
      onSubmitComplete();
    },
  });
  const producerTypeId = mapProducerIdToProducerTypeId(producerId);
  const agencyProducerSubmitHandler = useSubmitHandler(
    createAgencyProducerLicenseMutation,
    onClose,
    (formData: CreateProducerLicenseAndJurisdictionFormData) =>
      mapProducerLicenseAndJurisdictionFormDataToCreateAgencyProducerLicenseRequest(
        producerTypeId,
        formData,
      ),
  );
  const individualProducerSubmitHandler = useSubmitHandler(
    createIndividualProducerLicenseMutation,
    onClose,
    (formData: CreateProducerLicenseAndJurisdictionFormData) =>
      mapProducerLicenseAndJurisdictionFormDataToCreateIndividualProducerLicenseRequest(
        producerTypeId,
        formData,
      ),
  );

  return (
    <Formik<CreateProducerLicenseAndJurisdictionFormData>
      initialValues={buildInitialCreateProducerLicenseAndJurisdictionFormData()}
      validationSchema={
        CREATE_PRODUCER_LICENSE_AND_JURISDICTION_FORM_SCHEMA as any
      }
      onSubmit={
        isAgencyProducerId(producerId)
          ? agencyProducerSubmitHandler
          : individualProducerSubmitHandler
      }
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <JurisdictionField
            label="Jurisdiction"
            name="jurisdiction"
            JurisdictionAutocomplete={JurisdictionAutocomplete_}
            minWidth="150px"
            testId="jurisdiction-autocomplete-input"
          />
          <LicenseNumberField
            name="licenseNumber"
            minWidth="450px"
            testId="license-number-input"
          />
          <EffectiveDateField
            name="effectiveDate"
            minWidth="300px"
            testId="effective-date-input"
          />
          <ExpirationDateField
            name="expirationDate"
            minWidth="300px"
            testId="expiration-date-input"
          />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton />

        {createAgencyProducerLicenseMutation.isSuccess ||
        createIndividualProducerLicenseMutation.isSuccess ? (
          <FormResultSuccessMessage data-testid="success-result">
            The license was successfully recorded.
          </FormResultSuccessMessage>
        ) : null}

        {createAgencyProducerLicenseMutation.isError ||
        createIndividualProducerLicenseMutation.isError ? (
          <FormResultErrorMessage data-testid="error-result">
            Request failed.
          </FormResultErrorMessage>
        ) : null}
      </FormikForm>
    </Formik>
  );
};

export type RenewProducerLicenseFormData = Omit<
  RenewAgencyProducerLicenseRequest,
  | "agencyProducerId"
  | "licenseId"
  | "licenseNumber"
  | "effectiveDate"
  | "expirationDate"
> & {
  licenseNumber: string;
  effectiveDate: string;
  expirationDate: string;
};

type CompleteRenewProducerLicenseFormData = Omit<
  RenewAgencyProducerLicenseRequest,
  "agencyProducerId" | "licenseId"
>;

export const RENEW_PRODUCER_LICENSE_FORM_SCHEMA: Yup.ObjectSchema<CompleteRenewProducerLicenseFormData> =
  Yup.object({
    licenseNumber: LICENSE_NUMBER_SCHEMA,
    effectiveDate: EFFECTIVE_DATE_SCHEMA,
    expirationDate: EXPIRATION_DATE_SCHEMA,
  });

export const buildInitialRenewProducerLicenseFormData = (
  mostRecentLicense: ProducerLicense,
): RenewProducerLicenseFormData => ({
  licenseNumber: mostRecentLicense.licenseNumber,
  effectiveDate: "",
  expirationDate: "",
});

export const mapRenewProducerLicenseFormDataToRenewAgencyProducerLicenseRequest =
  (
    agencyProducerId: string,
    licenseId: string,
    formData: RenewProducerLicenseFormData,
  ): RenewAgencyProducerLicenseRequest => {
    const completeFormData =
      RENEW_PRODUCER_LICENSE_FORM_SCHEMA.validateSync(formData);
    return {
      ...completeFormData,
      agencyProducerId,
      licenseId,
    };
  };

export const mapRenewProducerLicenseFormDataToRenewIndividualProducerLicenseRequest =
  (
    individualProducerId: string,
    licenseId: string,
    formData: RenewProducerLicenseFormData,
  ): RenewIndividualProducerLicenseRequest => {
    const completeFormData =
      RENEW_PRODUCER_LICENSE_FORM_SCHEMA.validateSync(formData);
    return {
      ...completeFormData,
      individualProducerId,
      licenseId,
    };
  };

export const RenewProducerLicenseForm: React.FC<{
  producerId: ProducerId;
  mostRecentLicense: ProducerLicense;
  onClose: () => any;
  onSubmitComplete: () => any;
}> = ({producerId, mostRecentLicense, onClose, onSubmitComplete}) => {
  const {adminApi} = useContext(DependencyContext);
  const renewAgencyProducerLicenseMutation = useMutation({
    mutationFn: adminApi.renewAgencyProducerLicense,
    onSuccess: () => {
      onSubmitComplete();
    },
  });
  const renewIndividualProducerLicenseMutation = useMutation({
    mutationFn: adminApi.renewIndividualProducerLicense,
    onSuccess: () => {
      onSubmitComplete();
    },
  });
  const producerTypeId = mapProducerIdToProducerTypeId(producerId);
  const agencyProducerSubmitHandler = useSubmitHandler(
    renewAgencyProducerLicenseMutation,
    onClose,
    (formData: RenewProducerLicenseFormData) =>
      mapRenewProducerLicenseFormDataToRenewAgencyProducerLicenseRequest(
        producerTypeId,
        mostRecentLicense.id,
        formData,
      ),
  );
  const individualProducerSubmitHandler = useSubmitHandler(
    renewIndividualProducerLicenseMutation,
    onClose,
    (formData: RenewProducerLicenseFormData) =>
      mapRenewProducerLicenseFormDataToRenewIndividualProducerLicenseRequest(
        producerTypeId,
        mostRecentLicense.id,
        formData,
      ),
  );
  return (
    <Formik<RenewProducerLicenseFormData>
      initialValues={buildInitialRenewProducerLicenseFormData(
        mostRecentLicense,
      )}
      validationSchema={RENEW_PRODUCER_LICENSE_FORM_SCHEMA as any}
      onSubmit={
        isAgencyProducerId(producerId)
          ? agencyProducerSubmitHandler
          : individualProducerSubmitHandler
      }
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <LicenseNumberField
            name="licenseNumber"
            minWidth="450px"
            testId="license-number-input"
          />
          <EffectiveDateField
            name="effectiveDate"
            minWidth="300px"
            testId="effective-date-input"
          />
          <ExpirationDateField
            name="expirationDate"
            minWidth="300px"
            testId="expiration-date-input"
          />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton />

        {renewAgencyProducerLicenseMutation.isSuccess ||
        renewIndividualProducerLicenseMutation.isSuccess ? (
          <FormResultSuccessMessage data-testid="success-result">
            The license renewal was successfully recorded.
          </FormResultSuccessMessage>
        ) : null}

        {renewAgencyProducerLicenseMutation.isError ||
        renewIndividualProducerLicenseMutation.isError ? (
          <FormResultErrorMessage data-testid="error-result">
            Request failed.
          </FormResultErrorMessage>
        ) : null}
      </FormikForm>
    </Formik>
  );
};
