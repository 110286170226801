import {Alert, Placeholder} from "@aws-amplify/ui-react";
import {QueryFunction, useQuery} from "@tanstack/react-query";
import {isLoadingNextQuery, Query} from "@title-service/react-query-utils";
import {
  DateComponentRaw,
  DateTimeComponent,
  Text,
  TextRaw,
} from "@title-service/ui";
import {enumValues, parseWholeNumber} from "@title-service/utils";
import React, {useCallback, useContext, useMemo} from "react";
import {
  URLSearchParamsInit,
  useParams,
  useSearchParams,
} from "react-router-dom";

import {DependencyContext} from "../DependencyContext";
import {
  GetAuditExportItemsRequest,
  GetAuditExportItemsSortField,
  GetAuditExportItemsSuccessResponse,
  GetAuditExportSuccessResponse,
  parseSortDirection,
  SortDirection,
} from "../shared/adminApi";
import {AuditAttachmentTypeComponentRaw} from "../shared/audit/AuditAttachmentType";
import {AuditExportStatusPill} from "../shared/audit/AuditExportStatusPill";
import {
  DataGrid,
  GridItem,
  GridItemLabel,
  GridItemLabelContainer,
} from "../shared/components/DataGrid";
import {DownloadLink, RouterLink} from "../shared/components/Link";
import {LoadingOverlay} from "../shared/components/LoadingOverlay";
import {MaybeNotAvailable} from "../shared/components/Nullable";
import {PageNumbers} from "../shared/components/Pagination";
import {QuantityComponent} from "../shared/components/Quantity";
import {DownloadFolderIcon} from "../shared/components/icons";
import {
  AboveTableContainer,
  BodyRow,
  HeaderRow,
  Table,
  TBody,
  Td,
  Th,
  THead,
} from "../shared/components/tables/PrimaryTable";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
} from "../shared/layout";

type GetAuditExportQueryKey = ["audits", "exports", string, "detail"];
type GetAuditExportQuery = Query<GetAuditExportSuccessResponse>;

const useGetAuditExport = (auditExportId: string): GetAuditExportQuery => {
  const {adminApi} = useContext(DependencyContext);

  const getAuditExportQueryKey: GetAuditExportQueryKey = useMemo(
    () => ["audits", "exports", auditExportId, "detail"],
    [auditExportId],
  );
  const getAuditExportQueryFn: QueryFunction<
    GetAuditExportSuccessResponse,
    GetAuditExportQueryKey
  > = useCallback(
    ({queryKey: [, , auditExportId_], signal}) =>
      adminApi.getAuditExport({auditExportId: auditExportId_}, signal),
    [adminApi],
  );
  return useQuery({
    queryKey: getAuditExportQueryKey,
    queryFn: getAuditExportQueryFn,
  });
};

type GetAuditExportItemsQueryKey = [
  "audits",
  "exports",
  "items",
  "search",
  GetAuditExportItemsRequest,
];

export type GetAuditExportItemsQuery =
  Query<GetAuditExportItemsSuccessResponse>;

const useGetAuditExportExportItems = (
  getAuditExportItemsRequest: GetAuditExportItemsRequest,
): GetAuditExportItemsQuery => {
  const {adminApi} = useContext(DependencyContext);
  const getAuditExportItemsQueryFn: QueryFunction<
    GetAuditExportItemsSuccessResponse,
    GetAuditExportItemsQueryKey
  > = useCallback(
    ({queryKey: [, , , , _request], signal}) =>
      adminApi.getAuditExportItems(_request, signal),
    [adminApi],
  );
  return useQuery<
    GetAuditExportItemsSuccessResponse,
    unknown,
    GetAuditExportItemsSuccessResponse,
    GetAuditExportItemsQueryKey
  >({
    keepPreviousData: true,
    queryKey: [
      "audits",
      "exports",
      "items",
      "search",
      getAuditExportItemsRequest,
    ],
    queryFn: getAuditExportItemsQueryFn,
  });
};

export type GetAuditExportItemsRequestDefaults = Pick<
  GetAuditExportItemsRequest,
  "page" | "pageSize" | "sortField" | "sortDirection"
>;

export const GET_AUDIT_EXPORT_ITEMS_REQUEST_DEFAULTS: GetAuditExportItemsRequestDefaults =
  {
    sortField: GetAuditExportItemsSortField.Sequence,
    sortDirection: SortDirection.Asc,
    page: 0,
    pageSize: 10,
  };

type AuditExportDetailPathParams = {
  auditExportId: string;
};

export const AuditExportDetailRoute: React.FC<{
  AuditExportDetailQueryResult?: typeof AuditExportDetailQueryResult;
}> = ({
  AuditExportDetailQueryResult:
    AuditExportDetailQueryResult_ = AuditExportDetailQueryResult,
}) => {
  const {auditExportId} = useParams<
    keyof AuditExportDetailPathParams
  >() as AuditExportDetailPathParams;
  const [searchParams, setSearchParams] = useSearchParams();
  const getAuditExportItemsRequest =
    mapSearchParamsToGetAuditExportItemsRequest(auditExportId, searchParams);

  const getAuditExportQuery = useGetAuditExport(auditExportId);
  const getAuditExportItemsQuery = useGetAuditExportExportItems(
    getAuditExportItemsRequest,
  );

  const handleChangeRequestState = useCallback(
    (
      updateRequest: (
        currentRequest: GetAuditExportItemsRequest,
      ) => GetAuditExportItemsRequest,
    ) => {
      setSearchParams((currentSearchParams) => {
        const currentRequest = mapSearchParamsToGetAuditExportItemsRequest(
          auditExportId,
          currentSearchParams,
        );
        const updatedRequest = updateRequest(currentRequest);
        return mapGetAuditExportItemsRequestToSearchParams(updatedRequest);
      });
    },
    [auditExportId, setSearchParams],
  );
  return (
    <>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <AuditExportDetailQueryResult_
        auditExportId={auditExportId}
        getAuditExportQuery={getAuditExportQuery}
        getAuditExportItemsQuery={getAuditExportItemsQuery}
        onChangeRequest={handleChangeRequestState}
      />
    </>
  );
};

type OnChangeRequest = (
  updateRequest: (
    request: GetAuditExportItemsRequest,
  ) => GetAuditExportItemsRequest,
) => any;

export const AuditExportDetailQueryResult: React.FC<{
  auditExportId: string;
  getAuditExportQuery: GetAuditExportQuery;
  getAuditExportItemsQuery: GetAuditExportItemsQuery;
  onChangeRequest: OnChangeRequest;
  AuditExportDetailSuccessComponent?: typeof AuditExportDetailSuccessComponent;
  AuditExportDetailFailureComponent?: typeof AuditExportDetailFailureComponent;
  AuditExportDetailLoadingComponent?: typeof AuditExportDetailLoadingComponent;
}> = ({
  auditExportId,
  getAuditExportQuery: {data: getAuditQueryData, error: getAuditQueryError},
  getAuditExportItemsQuery,
  onChangeRequest,
  AuditExportDetailSuccessComponent:
    AuditExportDetailSuccessComponent_ = AuditExportDetailSuccessComponent,
  AuditExportDetailFailureComponent:
    AuditExportDetailFailureComponent_ = AuditExportDetailFailureComponent,
  AuditExportDetailLoadingComponent:
    AuditExportDetailLoadingComponent_ = AuditExportDetailLoadingComponent,
}) => {
  if (getAuditQueryData) {
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <AuditExportDetailSuccessComponent_
        getAuditExportResponse={getAuditQueryData}
        getAuditExportItemsQuery={getAuditExportItemsQuery}
        onChangeRequest={onChangeRequest}
      />
    );
  } else if (getAuditQueryError) {
    // eslint-disable-next-line react/jsx-pascal-case
    return <AuditExportDetailFailureComponent_ auditExportId={auditExportId} />;
  }
  // eslint-disable-next-line react/jsx-pascal-case
  return <AuditExportDetailLoadingComponent_ auditExportId={auditExportId} />;
};

export const AuditExportDetailLoadingComponent: React.FC<{
  auditExportId: string;
}> = ({auditExportId}) => (
  <BodyContent>
    <span>Loading Audit Export {auditExportId}...</span>
  </BodyContent>
);

export const AuditExportDetailFailureComponent: React.FC<{
  auditExportId: string;
}> = ({auditExportId}) => (
  <BodyContent>
    <span>Failed to load Audit Export {auditExportId}.</span>
  </BodyContent>
);

export const AuditExportDetailSuccessComponent: React.FC<{
  getAuditExportResponse: GetAuditExportSuccessResponse;
  getAuditExportItemsQuery: GetAuditExportItemsQuery;
  onChangeRequest: OnChangeRequest;
  AuditExportItemsPageSuccessTableBody?: typeof AuditExportItemsPageSuccessTableBody;
  AuditExportItemsPagePlaceholderTableBody?: typeof AuditExportItemsPagePlaceholderTableBody;
  PageNumbers?: typeof PageNumbers;
}> = ({
  getAuditExportResponse: {
    id: auditExportId,
    agency,
    completedAt,
    createdAt,
    lowerBoundDate,
    state,
    status,
    upperBoundDate,
    artifactUrl,
  },
  getAuditExportItemsQuery,
  getAuditExportItemsQuery: {data, error, isInitialLoading},
  onChangeRequest,
  AuditExportItemsPageSuccessTableBody:
    AuditExportItemsPageSuccessTableBody_ = AuditExportItemsPageSuccessTableBody,
  AuditExportItemsPagePlaceholderTableBody:
    AuditExportItemsPagePlaceholderTableBody_ = AuditExportItemsPagePlaceholderTableBody,
  PageNumbers: PageNumbers_ = PageNumbers,
}) => {
  const isLoading = isLoadingNextQuery(getAuditExportItemsQuery);
  const setPage = useCallback(
    (zeroIndexedCurrentPageNumber) => {
      onChangeRequest((currentRequest) => ({
        ...currentRequest,
        page: zeroIndexedCurrentPageNumber,
      }));
    },
    [onChangeRequest],
  );
  return (
    <>
      <BodyHeader>
        <PrimaryBodyHeaderContainer>
          <PrimaryBodyHeader value={`Audit Export ${auditExportId}`} />
          {artifactUrl ? (
            <DownloadLink fontSize="3em" href={artifactUrl}>
              <DownloadFolderIcon />
            </DownloadLink>
          ) : null}
        </PrimaryBodyHeaderContainer>
        <DataGrid>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Status" />
            </GridItemLabelContainer>
            <AuditExportStatusPill status={status} />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Created At" />
            </GridItemLabelContainer>
            <DateTimeComponent value={createdAt} />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Completed At" />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={completedAt}>
              {(completedAt_) => <DateTimeComponent value={completedAt_} />}
            </MaybeNotAvailable>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Agency" />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={agency?.name}>
              {(agencyName) => <Text value={agencyName} />}
            </MaybeNotAvailable>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="State" />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={state}>
              {(state_) => <Text value={state_} />}
            </MaybeNotAvailable>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Date Range" />
            </GridItemLabelContainer>
            <Text
              value={
                <>
                  <MaybeNotAvailable
                    value={lowerBoundDate}
                    fallback={<TextRaw value="All" />}
                  >
                    {(lowerBoundDate_) => (
                      <DateComponentRaw value={lowerBoundDate_} />
                    )}
                  </MaybeNotAvailable>
                  <TextRaw value=" - " />
                  <MaybeNotAvailable
                    value={upperBoundDate}
                    fallback={<TextRaw value="All" />}
                  >
                    {(upperBoundDate_) => (
                      <DateComponentRaw value={upperBoundDate_} />
                    )}
                  </MaybeNotAvailable>
                </>
              }
            />
          </GridItem>
        </DataGrid>
      </BodyHeader>

      <BodyContent>
        <AboveTableContainer justifyContent="flex-start">
          {error ? (
            <Alert variation="error" isDismissible={false} hasIcon={true}>
              Failed to load Export manifest
            </Alert>
          ) : data ? (
            <QuantityComponent
              quantity={data.pagination.totalRecords}
              label={data.pagination.totalRecords === 1 ? "File" : "Files"}
            />
          ) : null}
        </AboveTableContainer>
        <LoadingOverlay isActive={isLoading}>
          <Table>
            <THead>
              <HeaderRow>
                <Th>Audit ID</Th>
                <Th>Export Item #</Th>
                <Th>File</Th>
                <Th>Type</Th>
              </HeaderRow>
            </THead>
            {data ? (
              // eslint-disable-next-line react/jsx-pascal-case
              <AuditExportItemsPageSuccessTableBody_ response={data} />
            ) : error ? (
              // eslint-disable-next-line react/jsx-pascal-case
              <AuditExportItemsPagePlaceholderTableBody_ isLoaded={true} />
            ) : (
              isInitialLoading && (
                // eslint-disable-next-line react/jsx-pascal-case
                <AuditExportItemsPagePlaceholderTableBody_ isLoaded={false} />
              )
            )}
          </Table>
        </LoadingOverlay>
        {data ? (
          // eslint-disable-next-line react/jsx-pascal-case
          <PageNumbers_
            totalPages={data.pagination.totalPages}
            zeroIndexedCurrentPageNumber={data.pagination.currentPage}
            onChange={setPage}
          />
        ) : null}
      </BodyContent>
    </>
  );
};

export const AuditExportItemsPagePlaceholderTableBody: React.FC<{
  isLoaded: boolean;
}> = ({isLoaded}) => (
  <TBody>
    <BodyRow>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
    </BodyRow>
  </TBody>
);

export const AuditExportItemsPageSuccessTableBody: React.FC<{
  response: GetAuditExportItemsSuccessResponse;
}> = ({response: {items}}) => {
  if (!items.length) {
    return (
      <TBody>
        <BodyRow>
          <Td colSpan={6}>No Files found</Td>
        </BodyRow>
      </TBody>
    );
  }

  return (
    <TBody>
      {items.map(({sequenceNumber, auditId, filename, artifactUrl, type}) => (
        <BodyRow key={sequenceNumber}>
          <Td>
            <RouterLink to={`/audits/${auditId}/`}>{auditId}</RouterLink>
          </Td>
          <Td>
            <Text value={sequenceNumber} />
          </Td>
          <Td>
            <DownloadLink href={artifactUrl}>{filename}</DownloadLink>
          </Td>
          <Td>
            <MaybeNotAvailable value={type}>
              {(type_) => (
                <Text
                  value={
                    <AuditAttachmentTypeComponentRaw
                      auditAttachmentType={type_}
                    />
                  }
                />
              )}
            </MaybeNotAvailable>
          </Td>
        </BodyRow>
      ))}
    </TBody>
  );
};

export const mapSearchParamsToGetAuditExportItemsRequest = (
  auditExportId: string,
  searchParams: URLSearchParams,
  queryDefaults: GetAuditExportItemsRequestDefaults = GET_AUDIT_EXPORT_ITEMS_REQUEST_DEFAULTS,
): GetAuditExportItemsRequest => ({
  auditExportId,
  sortField:
    mapSearchParamToGetAuditExportItemsSortField(
      searchParams.get("sortField"),
    ) ?? queryDefaults.sortField,
  sortDirection:
    parseSortDirection(searchParams.get("sortDirection")) ??
    queryDefaults.sortDirection,
  page: parseWholeNumber(searchParams.get("page")) ?? queryDefaults.page,
  pageSize:
    parseWholeNumber(searchParams.get("pageSize")) ?? queryDefaults.pageSize,
});

const GET_AUDIT_EXPORT_ITEMS_SORT_FIELD_VALUES = enumValues(
  GetAuditExportItemsSortField,
);

export const mapSearchParamToGetAuditExportItemsSortField = (
  getAuditExportsSortField: string | null,
): GetAuditExportItemsSortField | undefined => {
  const trimmed = getAuditExportsSortField?.trim();
  if (trimmed) {
    return GET_AUDIT_EXPORT_ITEMS_SORT_FIELD_VALUES.find(
      (value) => value === trimmed,
    );
  }
  return undefined;
};

export const mapGetAuditExportItemsRequestToSearchParams = ({
  page,
  pageSize,
  sortField,
  sortDirection,
}: GetAuditExportItemsRequest): URLSearchParamsInit => ({
  page: page.toString(),
  pageSize: pageSize.toString(),
  sortField,
  sortDirection,
});
