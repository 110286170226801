/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PropertyDocumentAutomatedAddressVerification } from './PropertyDocumentAutomatedAddressVerification';
import {
    PropertyDocumentAutomatedAddressVerificationFromJSON,
    PropertyDocumentAutomatedAddressVerificationFromJSONTyped,
    PropertyDocumentAutomatedAddressVerificationToJSON,
} from './PropertyDocumentAutomatedAddressVerification';

/**
 * 
 * @export
 * @interface PropertyDocumentAutomatedVerification
 */
export interface PropertyDocumentAutomatedVerification {
    /**
     * 
     * @type {PropertyDocumentAutomatedAddressVerification}
     * @memberof PropertyDocumentAutomatedVerification
     */
    addressVerification: PropertyDocumentAutomatedAddressVerification;
    /**
     * 
     * @type {string}
     * @memberof PropertyDocumentAutomatedVerification
     */
    modelVersion: string;
}

/**
 * Check if a given object implements the PropertyDocumentAutomatedVerification interface.
 */
export function instanceOfPropertyDocumentAutomatedVerification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "addressVerification" in value;
    isInstance = isInstance && "modelVersion" in value;

    return isInstance;
}

export function PropertyDocumentAutomatedVerificationFromJSON(json: any): PropertyDocumentAutomatedVerification {
    return PropertyDocumentAutomatedVerificationFromJSONTyped(json, false);
}

export function PropertyDocumentAutomatedVerificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyDocumentAutomatedVerification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addressVerification': PropertyDocumentAutomatedAddressVerificationFromJSON(json['addressVerification']),
        'modelVersion': json['modelVersion'],
    };
}

export function PropertyDocumentAutomatedVerificationToJSON(value?: PropertyDocumentAutomatedVerification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addressVerification': PropertyDocumentAutomatedAddressVerificationToJSON(value.addressVerification),
        'modelVersion': value.modelVersion,
    };
}

