/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProducerJurisdictionStatus = {
    Invalid: 'invalid',
    Active: 'active',
    Inactive: 'inactive'
} as const;
export type ProducerJurisdictionStatus = typeof ProducerJurisdictionStatus[keyof typeof ProducerJurisdictionStatus];


export function ProducerJurisdictionStatusFromJSON(json: any): ProducerJurisdictionStatus {
    return ProducerJurisdictionStatusFromJSONTyped(json, false);
}

export function ProducerJurisdictionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProducerJurisdictionStatus {
    return json as ProducerJurisdictionStatus;
}

export function ProducerJurisdictionStatusToJSON(value?: ProducerJurisdictionStatus | null): any {
    return value as any;
}

