import {FormikConnectedAmplifySelectField} from "@title-service/ui";
import {enumKeys} from "@title-service/utils";
import React from "react";

import {ClaimExpenseType} from "../adminApi";
import {safeDisplayEnumFn} from "../util/enum";

export const unsafeDisplayClaimExpenseType = (
  claimExpenseType: ClaimExpenseType,
): string => {
  switch (claimExpenseType) {
    case ClaimExpenseType.AttorneyFee:
      return "Attorney Fee";
    case ClaimExpenseType.Other:
      return "Other";
    default:
      throw new Error(`Unknown ClaimExpense Type: ${claimExpenseType}`);
  }
};

export const displayClaimExpenseType = safeDisplayEnumFn(
  unsafeDisplayClaimExpenseType,
);

export const ExpenseTypeField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifySelectField>, "label">
> = (props) => (
  <FormikConnectedAmplifySelectField
    testId="expense-type-input"
    label="Expense Type"
    placeholder=""
    {...props}
  >
    <option value="" />
    {enumKeys(ClaimExpenseType).map((claimExpenseTypeKey) => {
      const claimExpenseType: ClaimExpenseType =
        ClaimExpenseType[claimExpenseTypeKey];
      return (
        <option key={claimExpenseType} value={claimExpenseType}>
          {displayClaimExpenseType(claimExpenseType)}
        </option>
      );
    })}
  </FormikConnectedAmplifySelectField>
);
