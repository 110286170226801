/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TransactionPartyType } from './TransactionPartyType';
import {
    TransactionPartyTypeFromJSON,
    TransactionPartyTypeFromJSONTyped,
    TransactionPartyTypeToJSON,
} from './TransactionPartyType';

/**
 * 
 * @export
 * @interface Cpl
 */
export interface Cpl {
    /**
     * 
     * @type {number}
     * @memberof Cpl
     */
    cost: number;
    /**
     * 
     * @type {Date}
     * @memberof Cpl
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Cpl
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Cpl
     */
    state: string;
    /**
     * 
     * @type {TransactionPartyType}
     * @memberof Cpl
     */
    type: TransactionPartyType;
}

/**
 * Check if a given object implements the Cpl interface.
 */
export function instanceOfCpl(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cost" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function CplFromJSON(json: any): Cpl {
    return CplFromJSONTyped(json, false);
}

export function CplFromJSONTyped(json: any, ignoreDiscriminator: boolean): Cpl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cost': json['cost'],
        'createdAt': (new Date(json['createdAt'])),
        'id': json['id'],
        'state': json['state'],
        'type': TransactionPartyTypeFromJSON(json['type']),
    };
}

export function CplToJSON(value?: Cpl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cost': value.cost,
        'createdAt': (value.createdAt.toISOString()),
        'id': value.id,
        'state': value.state,
        'type': TransactionPartyTypeToJSON(value.type),
    };
}

