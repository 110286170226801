import {CognitoUser} from "amazon-cognito-identity-js";

import {
  AuthenticationState,
  AuthenticationStateHolder,
  buildAuthenticationStateAuthenticated,
  buildAuthenticationStateLoading,
  buildAuthenticationStateUnauthenticated,
} from "./AuthenticationState";

export const buildAuthenticationState = (
  user?: CognitoUser,
): AuthenticationState => {
  if (!user) {
    return buildAuthenticationStateUnauthenticated();
  }

  const signInUserSession = user.getSignInUserSession();

  if (!signInUserSession) {
    return buildAuthenticationStateUnauthenticated();
  }

  const idToken = signInUserSession.getIdToken();
  const payload = idToken.decodePayload();
  return buildAuthenticationStateAuthenticated({
    jwtToken: idToken.getJwtToken(),
    expiresAt: new Date(idToken.getExpiration() * 1000),
    refreshSession: () => {
      user.refreshSession(signInUserSession.getRefreshToken(), () => {});
    },
    familyName: payload.family_name,
    givenName: payload.given_name,
  });
};

export const synchronizeExternalAuthenticationState = (
  authenticationStateHolder: AuthenticationStateHolder,
  isLoading: boolean,
  user?: CognitoUser,
) => {
  if (isLoading) {
    authenticationStateHolder.setState(buildAuthenticationStateLoading());
  } else {
    authenticationStateHolder.setState(buildAuthenticationState(user));
  }
};
