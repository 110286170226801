/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchPropertiesResult
 */
export interface SearchPropertiesResult {
    /**
     * 
     * @type {string}
     * @memberof SearchPropertiesResult
     */
    attomId: string;
    /**
     * 
     * @type {string}
     * @memberof SearchPropertiesResult
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof SearchPropertiesResult
     */
    county: string;
    /**
     * 
     * @type {string}
     * @memberof SearchPropertiesResult
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof SearchPropertiesResult
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof SearchPropertiesResult
     */
    unitValue?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchPropertiesResult
     */
    zipCode: string;
}

/**
 * Check if a given object implements the SearchPropertiesResult interface.
 */
export function instanceOfSearchPropertiesResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "attomId" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "county" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "zipCode" in value;

    return isInstance;
}

export function SearchPropertiesResultFromJSON(json: any): SearchPropertiesResult {
    return SearchPropertiesResultFromJSONTyped(json, false);
}

export function SearchPropertiesResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchPropertiesResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attomId': json['attomId'],
        'city': json['city'],
        'county': json['county'],
        'state': json['state'],
        'street': json['street'],
        'unitValue': !exists(json, 'unitValue') ? undefined : json['unitValue'],
        'zipCode': json['zipCode'],
    };
}

export function SearchPropertiesResultToJSON(value?: SearchPropertiesResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attomId': value.attomId,
        'city': value.city,
        'county': value.county,
        'state': value.state,
        'street': value.street,
        'unitValue': value.unitValue,
        'zipCode': value.zipCode,
    };
}

