import {Flex} from "@aws-amplify/ui-react";
import {Text} from "@title-service/ui";
import React from "react";
import styled from "styled-components";

import {SPACING_8} from "../theme";

export const DataGrid = styled.div({
  display: "grid",
  gridGap: SPACING_8,
  gridTemplateColumns: "repeat(auto-fit, minmax(250px , 1fr))",
});

export const GridItem: React.FC<React.ComponentProps<typeof Flex>> = (
  props,
) => <Flex direction="column" alignItems="self-start" gap="xs" {...props} />;

export const GridItemLabelContainer: React.FC<
  React.ComponentProps<typeof Flex>
> = (props) => <Flex direction="row" alignItems="center" gap="xs" {...props} />;

export const GridItemLabel: React.FC<React.ComponentProps<typeof Text>> = (
  props,
) => (
  <Text variation="secondary" fontWeight="bold" fontSize="small" {...props} />
);

export const GridItemLabelSubText: React.FC<
  React.ComponentProps<typeof Text>
> = (props) => <Text as="strong" variation="tertiary" {...props} />;
