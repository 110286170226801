import React, {useCallback} from "react";
import styled from "styled-components";

import {COLOR_P_100, FONT_SIZE_H5, SPACING_3} from "../../theme";

type DropdownItemStyleProps = {
  active: boolean;
};

const DropdownItemStyle = styled.li<DropdownItemStyleProps>(
  {
    padding: SPACING_3,
    ...FONT_SIZE_H5,
  },
  ({active}) => (active ? {background: COLOR_P_100} : {}),
);

export type DropdownItemProps = {
  active: boolean;
  onSelected: () => any;
  onActivated: () => any;
  onDeactivated: () => any;
};

export const DropdownItem: React.FC<DropdownItemProps> = ({
  active,
  onSelected,
  onActivated,
  onDeactivated,
  children,
}) => {
  const handleMousedown = useCallback(
    (event: React.MouseEvent<HTMLLIElement>) => {
      // Prevent the dropdown item from stealing focus on click
      event.preventDefault();
    },
    [],
  );

  return (
    <DropdownItemStyle
      data-testid="dropdown-item"
      active={active}
      onMouseDown={handleMousedown}
      onClick={onSelected}
      onMouseEnter={onActivated}
      onMouseLeave={onDeactivated}
    >
      {children}
    </DropdownItemStyle>
  );
};
