/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClaimExpenseType = {
    AttorneyFee: 'attorney_fee',
    Other: 'other'
} as const;
export type ClaimExpenseType = typeof ClaimExpenseType[keyof typeof ClaimExpenseType];


export function ClaimExpenseTypeFromJSON(json: any): ClaimExpenseType {
    return ClaimExpenseTypeFromJSONTyped(json, false);
}

export function ClaimExpenseTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaimExpenseType {
    return json as ClaimExpenseType;
}

export function ClaimExpenseTypeToJSON(value?: ClaimExpenseType | null): any {
    return value as any;
}

