/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GetAuditExportsSortField = {
    CreatedAt: 'CREATED_AT'
} as const;
export type GetAuditExportsSortField = typeof GetAuditExportsSortField[keyof typeof GetAuditExportsSortField];


export function GetAuditExportsSortFieldFromJSON(json: any): GetAuditExportsSortField {
    return GetAuditExportsSortFieldFromJSONTyped(json, false);
}

export function GetAuditExportsSortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAuditExportsSortField {
    return json as GetAuditExportsSortField;
}

export function GetAuditExportsSortFieldToJSON(value?: GetAuditExportsSortField | null): any {
    return value as any;
}

