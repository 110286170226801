/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClaimRecoverySource = {
    Subrogation: 'subrogation',
    Salvage: 'salvage',
    Deductible: 'deductible'
} as const;
export type ClaimRecoverySource = typeof ClaimRecoverySource[keyof typeof ClaimRecoverySource];


export function ClaimRecoverySourceFromJSON(json: any): ClaimRecoverySource {
    return ClaimRecoverySourceFromJSONTyped(json, false);
}

export function ClaimRecoverySourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaimRecoverySource {
    return json as ClaimRecoverySource;
}

export function ClaimRecoverySourceToJSON(value?: ClaimRecoverySource | null): any {
    return value as any;
}

