import React from "react";

import {PropertyDocumentExtractionStatus} from "../adminApi";
import {
  ErrorPill,
  InfoPill,
  SuccessPill,
  WarningPill,
} from "../components/Pills";

export const PropertyDocumentStatusPill: React.FC<{
  status: PropertyDocumentExtractionStatus;
}> = ({status}) => {
  switch (status) {
    case PropertyDocumentExtractionStatus.Approved:
      return <PropertyDocumentStatusPillApproved />;
    case PropertyDocumentExtractionStatus.InProgress:
      return <PropertyDocumentStatusPillInProgress />;
    case PropertyDocumentExtractionStatus.Rejected:
      return <PropertyDocumentStatusPillRejected />;
    default:
      return <PropertyDocumentStatusPillNotStarted />;
  }
};

const PropertyDocumentStatusPillNotStarted: React.FC = () => (
  <InfoPill label="Not Started" />
);

const PropertyDocumentStatusPillInProgress: React.FC = () => (
  <WarningPill label="In Progress" />
);

const PropertyDocumentStatusPillApproved: React.FC = () => (
  <SuccessPill label="Approved" />
);

const PropertyDocumentStatusPillRejected: React.FC = () => (
  <ErrorPill label="Rejected" />
);
