import {Text} from "@title-service/ui";
import {
  arrayNotEmpty,
  notNullOrUndefined,
  stringIsNotBlank,
} from "@title-service/utils";
import React, {ReactNode} from "react";

type TextProps = React.ComponentProps<typeof Text>;

export const NotAvailable: React.FC<
  Omit<TextProps, "value"> & Partial<Pick<TextProps, "value">>
> = (props) => <Text value="N/A" {...props} />;

type MaybeNotAvailableProps<T> = {
  value: T | null | undefined;
  children: (value: T) => ReactNode;
  present?: (value: T | null | undefined) => value is T;
  fallback?: React.ReactElement;
};

export const MaybeNotAvailable = <T,>({
  value,
  children,
  present = notNullOrUndefined,
  fallback,
}: MaybeNotAvailableProps<T>) => (
  <>{present(value) ? children(value) : fallback ?? <NotAvailable />}</>
);

export const StringMaybeBlankComponent: React.FC<
  Omit<React.ComponentProps<typeof Text>, "value"> & {
    value: string | null | undefined;
    children?: undefined;
  }
> = ({value, ...props}) => (
  <MaybeNotAvailable<string>
    value={value}
    present={stringIsNotBlank}
    fallback={<NotAvailable {...props} />}
  >
    {(s: string) => <Text {...props} value={s} />}
  </MaybeNotAvailable>
);

export const ArrayMaybeEmpty = <T,>(
  props: Omit<React.ComponentProps<typeof MaybeNotAvailable<T[]>>, "present">,
) => <MaybeNotAvailable<T[]> {...props} present={arrayNotEmpty} />;
