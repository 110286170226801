/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
     OrderFlagActiveFromJSONTyped,
     OrderFlagInactiveFromJSONTyped
} from './';

/**
 * 
 * @export
 * @interface OrderFlag
 */
export interface OrderFlag {
    /**
     * 
     * @type {string}
     * @memberof OrderFlag
     */
    status: string;
}

/**
 * Check if a given object implements the OrderFlag interface.
 */
export function instanceOfOrderFlag(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function OrderFlagFromJSON(json: any): OrderFlag {
    return OrderFlagFromJSONTyped(json, false);
}

export function OrderFlagFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderFlag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['status'] === 'active') {
            return OrderFlagActiveFromJSONTyped(json, true);
        }
        if (json['status'] === 'inactive') {
            return OrderFlagInactiveFromJSONTyped(json, true);
        }
    }
    return {
        
        'status': json['status'],
    };
}

export function OrderFlagToJSON(value?: OrderFlag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
    };
}

