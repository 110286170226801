import React from "react";
import onClickOutside from "react-onclickoutside";
import styled, {CSSObject} from "styled-components";

import {COLOR_N_000, COLOR_N_300, SPACING_1, SPACING_3} from "../theme";

/*
 * Implementation derived from here:
 * https://paladini.dev/posts/how-to-make-an-extremely-reusable-tooltip-component-with-react--and-nothing-else/
 * https://codesandbox.io/s/how-to-make-an-extremely-reusable-tooltip-component-with-react-and-nothing-else-7opo3?from-embed=&file=/src/Tooltip.css
 */

class OutsideClickHandlerImpl extends React.Component<{
  onOutsideClick: () => any;
}> {
  render() {
    return this.props.children;
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleClickOutside = () => {
    this.props.onOutsideClick();
  };
}

export const OutsideClickHandler = onClickOutside(OutsideClickHandlerImpl);

const TooltipContainerStyle = styled.div({
  display: "inline-block",
  position: "relative",
});

export const TooltipContainer: React.FC<{
  onClose: () => any;
}> = ({children, onClose}) => (
  <OutsideClickHandler onOutsideClick={onClose}>
    <TooltipContainerStyle>{children}</TooltipContainerStyle>
  </OutsideClickHandler>
);

export const Tooltip: React.FC = ({children}) => (
  <TooltipStyle>
    {children}
    <TooltipTriangle />
  </TooltipStyle>
);

const TOOLTIP_MARGIN = SPACING_3;
const TOOLTIP_BACKGROUND_COLOR = COLOR_N_000;
const TOOLTIP_BORDER_WIDTH = "0.25px";
const TOOLTIP_BORDER_STYLE: CSSObject["borderStyle"] = "solid";
const TOOLTIP_BORDER_COLOR = COLOR_N_300;
const TOOLTIP_BOX_SHADOW_OFFSET_X_PX = 2;
const TOOLTIP_BOX_SHADOW_OFFSET_Y_PX = 2;
const TOOLTIP_BOX_SHADOW_BLUR_RADIUS_PX = 6;
const TOOLTIP_BOX_SHADOW_SPREAD_RADIUS_PX = 6;
const TOOLTIP_BOX_SHADOW_COLOR = "rgba(0, 0, 0, 0.05)";

const TooltipStyle = styled.div({
  backgroundColor: TOOLTIP_BACKGROUND_COLOR,
  borderRadius: SPACING_1,
  borderWidth: TOOLTIP_BORDER_WIDTH,
  borderStyle: TOOLTIP_BORDER_STYLE,
  borderColor: TOOLTIP_BORDER_COLOR,
  boxShadow: `${TOOLTIP_BOX_SHADOW_OFFSET_X_PX}px ${TOOLTIP_BOX_SHADOW_OFFSET_Y_PX}px ${TOOLTIP_BOX_SHADOW_BLUR_RADIUS_PX}px ${TOOLTIP_BOX_SHADOW_SPREAD_RADIUS_PX}px ${TOOLTIP_BOX_SHADOW_COLOR}`,
  position: "absolute",
  zIndex: 1,

  // styles specific to bottom positioning
  left: "50%",
  marginTop: TOOLTIP_MARGIN,
  transform: "translateX(-50%)",
});

const TRIANGLE_HEIGHT_PX = 6;
const TRIANGLE_WIDTH_PX = 18;

const TooltipTriangle = styled.i(
  {
    position: "absolute",
    bottom: "100%",
    left: "50%",
    marginLeft: `${((TRIANGLE_WIDTH_PX / 2) * -1).toString(10)}px`,
    width: `${TRIANGLE_WIDTH_PX}px`,
    height: `${TRIANGLE_HEIGHT_PX}px`,
    overflow: "hidden",
  },
  `
  ::after {
    background-color: ${TOOLTIP_BACKGROUND_COLOR};
    border-color: ${TOOLTIP_BORDER_COLOR};
    border-style: ${TOOLTIP_BORDER_STYLE};
    border-width: ${TOOLTIP_BORDER_WIDTH};
    box-shadow: 0 ${TOOLTIP_BOX_SHADOW_OFFSET_Y_PX}px ${TOOLTIP_BOX_SHADOW_BLUR_RADIUS_PX}px 0 ${TOOLTIP_BOX_SHADOW_COLOR};
    content: '';
    height: ${TRIANGLE_HEIGHT_PX}px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 50%) rotate(45deg);
    width: ${TRIANGLE_HEIGHT_PX}px;
  }
  `,
);
