import {FileUploader} from "@aws-amplify/ui-react";
import {
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {Query} from "@title-service/react-query-utils";
import {DateTimeComponent} from "@title-service/ui";
import React, {useCallback, useContext, useMemo, useState} from "react";

import {DependencyContext} from "../../DependencyContext";
import {ClaimStatus, GetClaimAttachmentsSuccessResponse} from "../adminApi";
import {DownloadLink} from "../components/Link";
import {Modal} from "../components/Modal";
import {PlusIcon} from "../components/icons";
import {
  BodyRow as SecondaryBodyRow,
  HeaderRow as SecondaryHeaderRow,
  Table as SecondaryTable,
  TBody as SecondaryTBody,
  Td as SecondaryTd,
  Th as SecondaryTh,
  THead as SecondaryTHead,
} from "../components/tables/SecondaryTable";
import {
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../layout";
import {AmplifyStorageAccessLevel, getFullObjectKey} from "../util/storage";

import {IfClaimIsInProgress} from "./IfClaimIsInProgress";

type GetClaimAttachmentsQueryKey = ["claims", string, "attachments"];
export type GetClaimAttachmentsQuery =
  Query<GetClaimAttachmentsSuccessResponse>;

export const useGetClaimAttachments = (
  claimId: string,
): {
  getClaimAttachmentsQuery: GetClaimAttachmentsQuery;
  onReloadClaimAttachments: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);
  const getClaimAttachmentsQueryKey: GetClaimAttachmentsQueryKey = useMemo(
    () => ["claims", claimId, "attachments"],
    [claimId],
  );
  const getClaimAttachmentsQueryFn: QueryFunction<
    GetClaimAttachmentsSuccessResponse,
    GetClaimAttachmentsQueryKey
  > = useCallback(
    ({queryKey: [_, claimId_], signal}) =>
      adminApi.getClaimAttachments({claimId: claimId_}, signal),
    [adminApi],
  );
  const getClaimAttachmentsQuery = useQuery({
    queryKey: getClaimAttachmentsQueryKey,
    queryFn: getClaimAttachmentsQueryFn,
  });
  const onReloadClaimAttachments = useCallback(
    () =>
      queryClient.invalidateQueries({queryKey: getClaimAttachmentsQueryKey}),
    [queryClient, getClaimAttachmentsQueryKey],
  );

  return {
    getClaimAttachmentsQuery,
    onReloadClaimAttachments,
  };
};

const UPLOAD_ACCESS_LEVEL: AmplifyStorageAccessLevel = "private";

export const ClaimAttachmentsSection: React.FC<{
  claimId: string;
  claimStatus: ClaimStatus;
  onReloadClaimAttachments: () => any;
  getClaimAttachmentsQuery: GetClaimAttachmentsQuery;
  editModalContentStyle: NonNullable<
    React.ComponentProps<typeof Modal>["contentStyle"]
  >;
  ClaimAttachmentsSuccessComponent?: typeof ClaimAttachmentsSuccessComponent;
  ClaimAttachmentsFailureComponent?: typeof ClaimAttachmentsFailureComponent;
  ClaimAttachmentsLoadingComponent?: typeof ClaimAttachmentsLoadingComponent;
}> = ({
  claimId,
  claimStatus,
  getClaimAttachmentsQuery: {data, error},
  onReloadClaimAttachments,
  editModalContentStyle,
  ClaimAttachmentsSuccessComponent:
    ClaimAttachmentsSuccessComponent_ = ClaimAttachmentsSuccessComponent,
  ClaimAttachmentsFailureComponent:
    ClaimAttachmentsFailureComponent_ = ClaimAttachmentsFailureComponent,
  ClaimAttachmentsLoadingComponent:
    ClaimAttachmentsLoadingComponent_ = ClaimAttachmentsLoadingComponent,
}) => {
  const {adminApi} = useContext(DependencyContext);
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);
  const createClaimAttachmentMutationFn = useCallback(
    async (filename: string) => {
      const objectKey = await getFullObjectKey(filename, UPLOAD_ACCESS_LEVEL);
      await adminApi.createClaimAttachment({
        claimId,
        filename,
        fileUploadDropBoxObjectKey: objectKey,
      });
    },
    [adminApi, claimId],
  );
  const createClaimAttachmentMutation = useMutation({
    mutationFn: createClaimAttachmentMutationFn,
  });
  const onFileUploadSuccess: React.ComponentProps<
    typeof FileUploader
  >["onSuccess"] = useCallback(
    ({key: filename}: {key: string}) => {
      createClaimAttachmentMutation.mutate(filename, {
        onSuccess: () => {
          onReloadClaimAttachments();
        },
      });
    },
    [createClaimAttachmentMutation, onReloadClaimAttachments],
  );
  return (
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Attachments" />
        <IfClaimIsInProgress status={claimStatus}>
          {data ? (
            <PlusIcon
              data-testid="create-claim-attachment-button"
              onClick={openForm}
            />
          ) : null}
        </IfClaimIsInProgress>
      </SecondarySectionHeaderContainer>
      <SecondarySectionContent>
        {data ? (
          <>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <ClaimAttachmentsSuccessComponent_
              getClaimAttachmentsResponse={data}
            />
            <Modal
              contentStyle={editModalContentStyle}
              isOpen={displayForm}
              onClose={closeForm}
              title="Add Attachments"
            >
              <FileUploader
                onSuccess={onFileUploadSuccess}
                acceptedFileTypes={["application/pdf"]}
                accessLevel={UPLOAD_ACCESS_LEVEL}
                hasMultipleFiles={true}
                isPreviewerVisible={true}
                isResumable={false}
                shouldAutoProceed={false}
                showImages={true}
                variation="drop"
              />
            </Modal>
          </>
        ) : error ? (
          // eslint-disable-next-line react/jsx-pascal-case
          <ClaimAttachmentsFailureComponent_ />
        ) : (
          // eslint-disable-next-line react/jsx-pascal-case
          <ClaimAttachmentsLoadingComponent_ />
        )}
      </SecondarySectionContent>
    </SecondarySection>
  );
};

export const ClaimAttachmentsLoadingComponent: React.FC = () => (
  <span>Loading Claim Attachments ...</span>
);

export const ClaimAttachmentsFailureComponent: React.FC = () => (
  <span>Failed to load Claim Attachments.</span>
);

export const ClaimAttachmentsSuccessComponent: React.FC<{
  getClaimAttachmentsResponse: GetClaimAttachmentsSuccessResponse;
}> = ({getClaimAttachmentsResponse: {attachments}}) => (
  <SecondaryTable>
    <SecondaryTHead>
      <SecondaryHeaderRow>
        <SecondaryTh>File</SecondaryTh>
        <SecondaryTh>Created At</SecondaryTh>
      </SecondaryHeaderRow>
    </SecondaryTHead>
    <SecondaryTBody>
      {attachments.length === 0 ? (
        <SecondaryBodyRow>
          <SecondaryTd colSpan={5}>No Attachments Uploaded</SecondaryTd>
        </SecondaryBodyRow>
      ) : (
        attachments.map(({id, artifactUrl, filename, createdAt}) => (
          <SecondaryBodyRow key={id}>
            <SecondaryTd>
              <DownloadLink href={artifactUrl}>{filename}</DownloadLink>
            </SecondaryTd>
            <SecondaryTd>
              <DateTimeComponent value={createdAt} />
            </SecondaryTd>
          </SecondaryBodyRow>
        ))
      )}
    </SecondaryTBody>
  </SecondaryTable>
);
