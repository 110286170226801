/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClaimCostType } from './ClaimCostType';
import {
    ClaimCostTypeFromJSON,
    ClaimCostTypeFromJSONTyped,
    ClaimCostTypeToJSON,
} from './ClaimCostType';
import type { ClaimExpenseType } from './ClaimExpenseType';
import {
    ClaimExpenseTypeFromJSON,
    ClaimExpenseTypeFromJSONTyped,
    ClaimExpenseTypeToJSON,
} from './ClaimExpenseType';

/**
 * 
 * @export
 * @interface CreateClaimTransactionRequest
 */
export interface CreateClaimTransactionRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateClaimTransactionRequest
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CreateClaimTransactionRequest
     */
    checkNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClaimTransactionRequest
     */
    description: string;
    /**
     * 
     * @type {ClaimExpenseType}
     * @memberof CreateClaimTransactionRequest
     */
    expenseType?: ClaimExpenseType;
    /**
     * 
     * @type {string}
     * @memberof CreateClaimTransactionRequest
     */
    payee: string;
    /**
     * 
     * @type {ClaimCostType}
     * @memberof CreateClaimTransactionRequest
     */
    transactionType: ClaimCostType;
}

/**
 * Check if a given object implements the CreateClaimTransactionRequest interface.
 */
export function instanceOfCreateClaimTransactionRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "checkNumber" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "payee" in value;
    isInstance = isInstance && "transactionType" in value;

    return isInstance;
}

export function CreateClaimTransactionRequestFromJSON(json: any): CreateClaimTransactionRequest {
    return CreateClaimTransactionRequestFromJSONTyped(json, false);
}

export function CreateClaimTransactionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateClaimTransactionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'checkNumber': json['checkNumber'],
        'description': json['description'],
        'expenseType': !exists(json, 'expenseType') ? undefined : ClaimExpenseTypeFromJSON(json['expenseType']),
        'payee': json['payee'],
        'transactionType': ClaimCostTypeFromJSON(json['transactionType']),
    };
}

export function CreateClaimTransactionRequestToJSON(value?: CreateClaimTransactionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'checkNumber': value.checkNumber,
        'description': value.description,
        'expenseType': ClaimExpenseTypeToJSON(value.expenseType),
        'payee': value.payee,
        'transactionType': ClaimCostTypeToJSON(value.transactionType),
    };
}

