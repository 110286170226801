import {useTheme} from "@aws-amplify/ui-react";
import {Text} from "@title-service/ui";
import React from "react";

export const CurrencyWrapper: React.FC<React.ComponentProps<typeof Text>> = (
  props,
) => {
  const theme = useTheme();
  return (
    <Text
      variation="success"
      fontWeight={theme.tokens.fontWeights.bold}
      {...props}
    />
  );
};

type CurrencyComponentProps = {
  locale?: Intl.LocalesArgument;
  value: number;
  children?: undefined;
};

const currencyToLocaleString = ({value, locale}: CurrencyComponentProps) =>
  value.toLocaleString(locale, {
    style: "currency",
    currency: "USD",
  });

export const CurrencyComponent: React.FC<
  React.ComponentProps<typeof Text> & CurrencyComponentProps
> = (props) => (
  <CurrencyWrapper {...props} value={currencyToLocaleString(props)} />
);
