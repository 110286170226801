import {
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {Query} from "@title-service/react-query-utils";
import {
  DateTimeComponent,
  FormikConnectedAmplifyCurrencyField,
  FormikConnectedAmplifySubmitButton,
  FormikForm,
  FormResultErrorMessage,
  Text,
  TextRaw,
  VerticalFieldset,
} from "@title-service/ui";
import {enumValues} from "@title-service/utils";
import {buildCurrencySchema} from "@title-service/yup-utils";
import {Formik, FormikHelpers} from "formik";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as Yup from "yup";

import {DependencyContext} from "../../DependencyContext";
import {
  ClaimCostType,
  ClaimStatus,
  CreateClaimReserveRequest,
  GetClaimReservesSuccessResponse,
} from "../adminApi";
import {CurrencyComponent} from "../components/Currency";
import {
  DataGrid,
  GridItem,
  GridItemLabel,
  GridItemLabelContainer,
} from "../components/DataGrid";
import {Modal} from "../components/Modal";
import {EditIcon} from "../components/icons";
import {
  BodyRow as SecondaryBodyRow,
  HeaderRow as SecondaryHeaderRow,
  Table as SecondaryTable,
  TBody as SecondaryTBody,
  Td as SecondaryTd,
  Th as SecondaryTh,
  THead as SecondaryTHead,
  TableCaption,
} from "../components/tables/SecondaryTable";
import {
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../layout";

import {
  ClaimCostTypeComponentRaw,
  displayClaimCostType,
} from "./ClaimCostTypeField";
import {DESCRIPTION_SCHEMA, DescriptionField} from "./DescriptionField";
import {IfClaimIsInProgress} from "./IfClaimIsInProgress";

type GetClaimReservesQueryKey = ["claims", string, "reserves"];
export type GetClaimReservesQuery = Query<GetClaimReservesSuccessResponse>;

export const useGetClaimReserves = (
  claimId: string,
): {
  getClaimReservesQuery: GetClaimReservesQuery;
  onReloadClaimReserves: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);
  const getClaimReservesQueryKey: GetClaimReservesQueryKey = useMemo(
    () => ["claims", claimId, "reserves"],
    [claimId],
  );
  const getClaimReservesQueryFn: QueryFunction<
    GetClaimReservesSuccessResponse,
    GetClaimReservesQueryKey
  > = useCallback(
    ({queryKey: [_, claimId_], signal}) =>
      adminApi.getClaimReserves({claimId: claimId_}, signal),
    [adminApi],
  );
  const getClaimReservesQuery = useQuery({
    queryKey: getClaimReservesQueryKey,
    queryFn: getClaimReservesQueryFn,
  });
  const onReloadClaimReserves = useCallback(
    () => queryClient.invalidateQueries({queryKey: getClaimReservesQueryKey}),
    [queryClient, getClaimReservesQueryKey],
  );

  return {
    getClaimReservesQuery,
    onReloadClaimReserves,
  };
};

export const ClaimReservesSection: React.FC<{
  claimId: string;
  claimStatus: ClaimStatus;
  onReloadClaimReserves: () => any;
  onReserveStateChange: (calculatedReserveState: CalculatedReserveState) => any;
  getClaimReservesQuery: GetClaimReservesQuery;
  editModalContentStyle: NonNullable<
    React.ComponentProps<typeof Modal>["contentStyle"]
  >;
  ClaimReservesSuccessComponent?: typeof ClaimReservesSuccessComponent;
  ClaimReservesFailureComponent?: typeof ClaimReservesFailureComponent;
  ClaimReservesLoadingComponent?: typeof ClaimReservesLoadingComponent;
}> = ({
  claimId,
  claimStatus,
  getClaimReservesQuery: {data, error},
  onReloadClaimReserves,
  onReserveStateChange,
  editModalContentStyle,
  ClaimReservesSuccessComponent:
    ClaimReservesSuccessComponent_ = ClaimReservesSuccessComponent,
  ClaimReservesFailureComponent:
    ClaimReservesFailureComponent_ = ClaimReservesFailureComponent,
  ClaimReservesLoadingComponent:
    ClaimReservesLoadingComponent_ = ClaimReservesLoadingComponent,
}) => (
  <SecondarySection>
    <SecondarySectionHeaderContainer>
      <SecondarySectionHeader value="Reserves" />
    </SecondarySectionHeaderContainer>
    <SecondarySectionContent>
      {data ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <ClaimReservesSuccessComponent_
          claimId={claimId}
          claimStatus={claimStatus}
          onReserveStateChange={onReserveStateChange}
          getClaimReservesResponse={data}
          onReloadClaimReserves={onReloadClaimReserves}
          editModalContentStyle={editModalContentStyle}
        />
      ) : error ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <ClaimReservesFailureComponent_ />
      ) : (
        // eslint-disable-next-line react/jsx-pascal-case
        <ClaimReservesLoadingComponent_ />
      )}
    </SecondarySectionContent>
  </SecondarySection>
);

export const ClaimReservesLoadingComponent: React.FC = () => (
  <span>Loading Claim Reserves ...</span>
);

export const ClaimReservesFailureComponent: React.FC = () => (
  <span>Failed to load Claim Reserves.</span>
);

export const ClaimReservesSuccessComponent: React.FC<{
  claimId: string;
  claimStatus: ClaimStatus;
  onReserveStateChange: (calculatedReserveState: CalculatedReserveState) => any;
  getClaimReservesResponse: GetClaimReservesSuccessResponse;
  onReloadClaimReserves: () => any;
  editModalContentStyle: NonNullable<
    React.ComponentProps<typeof Modal>["contentStyle"]
  >;
}> = ({
  claimId,
  claimStatus,
  onReserveStateChange,
  getClaimReservesResponse: {reserves},
  onReloadClaimReserves,
  editModalContentStyle,
}) => {
  const calculatedReserveState = useMemo(
    () => calculateReserveState(reserves),
    [reserves],
  );

  useEffect(() => {
    onReserveStateChange(calculatedReserveState);
  }, [calculatedReserveState, onReserveStateChange]);

  return (
    <>
      <DataGrid>
        <ReserveGridItem
          claimId={claimId}
          claimStatus={claimStatus}
          reserveType={ClaimCostType.Expense}
          reserveState={calculatedReserveState.expense}
          onReloadClaimReserves={onReloadClaimReserves}
          editModalContentStyle={editModalContentStyle}
        />
        <ReserveGridItem
          claimId={claimId}
          claimStatus={claimStatus}
          reserveType={ClaimCostType.Loss}
          reserveState={calculatedReserveState.loss}
          onReloadClaimReserves={onReloadClaimReserves}
          editModalContentStyle={editModalContentStyle}
        />
      </DataGrid>
      <SecondaryTable>
        <TableCaption>Reserve History</TableCaption>
        <SecondaryTHead>
          <SecondaryHeaderRow>
            <SecondaryTh>Type</SecondaryTh>
            <SecondaryTh>Change Amount</SecondaryTh>
            <SecondaryTh>Description</SecondaryTh>
            <SecondaryTh>Created At</SecondaryTh>
          </SecondaryHeaderRow>
        </SecondaryTHead>
        <SecondaryTBody>
          {reserves.length === 0 ? (
            <SecondaryBodyRow>
              <SecondaryTd colSpan={6}>No Reserve Changes</SecondaryTd>
            </SecondaryBodyRow>
          ) : (
            sortReservesByMostRecent(reserves).map(
              ({id, reserveType, changeAmount, description, createdAt}) => (
                <SecondaryBodyRow key={id}>
                  <SecondaryTd>
                    <Text
                      value={
                        <ClaimCostTypeComponentRaw
                          claimCostType={reserveType}
                        />
                      }
                    />
                  </SecondaryTd>
                  <SecondaryTd>
                    <CurrencyComponent value={changeAmount} />
                  </SecondaryTd>
                  <SecondaryTd>{description}</SecondaryTd>
                  <SecondaryTd>
                    <DateTimeComponent value={createdAt} />
                  </SecondaryTd>
                </SecondaryBodyRow>
              ),
            )
          )}
        </SecondaryTBody>
      </SecondaryTable>
    </>
  );
};

export const ReserveGridItem: React.FC<{
  claimId: string;
  claimStatus: ClaimStatus;
  reserveState: CalculatedReserveTypeState;
  reserveType: ClaimCostType;
  onReloadClaimReserves: () => any;
  editModalContentStyle: NonNullable<
    React.ComponentProps<typeof Modal>["contentStyle"]
  >;
}> = ({
  claimId,
  claimStatus,
  onReloadClaimReserves,
  reserveState,
  reserveType,
  editModalContentStyle,
}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);
  return (
    <GridItem>
      <GridItemLabelContainer>
        <GridItemLabel
          value={
            <>
              <ClaimCostTypeComponentRaw claimCostType={reserveType} />
              <TextRaw value=" Reserve" />
            </>
          }
        />
        <IfClaimIsInProgress status={claimStatus}>
          <EditIcon
            data-testid="edit-reserve-grid-item-button"
            onClick={openForm}
          />
        </IfClaimIsInProgress>
      </GridItemLabelContainer>
      <CurrencyComponent value={reserveState.amount} />
      <Modal
        contentStyle={editModalContentStyle}
        isOpen={displayForm}
        onClose={closeForm}
        title={
          <>
            <TextRaw value="Edit " />
            <ClaimCostTypeComponentRaw claimCostType={reserveType} />
            <TextRaw value=" Reserve" />
          </>
        }
      >
        <ClaimReservesForm
          claimId={claimId}
          onClose={closeForm}
          reserveType={reserveType}
          reserveState={reserveState}
          onSubmitComplete={onReloadClaimReserves}
        />
      </Modal>
    </GridItem>
  );
};

export const AMOUNT_SCHEMA: Yup.NumberSchema<number> = buildCurrencySchema()
  .required("Amount is required")
  .min(0, "Amount must be positive");

export const AmountField: React.FC<
  Omit<
    React.ComponentProps<typeof FormikConnectedAmplifyCurrencyField>,
    "label"
  >
> = (props) => (
  <FormikConnectedAmplifyCurrencyField
    testId="amount-input"
    label="New Reserve Amount"
    {...props}
  />
);

type CompleteClaimReserveFormData = {
  amount: number;
  description: string;
};

export const CLAIM_RESERVE_FORM_SCHEMA: Yup.ObjectSchema<CompleteClaimReserveFormData> =
  Yup.object({
    amount: AMOUNT_SCHEMA,
    description: DESCRIPTION_SCHEMA,
  });

type CalculatedReserveTypeState = {
  amount: number;
  mostRecentReserveId?: string;
};

export type CalculatedReserveState = {
  [key in ClaimCostType]: CalculatedReserveTypeState;
};

const sortReservesByMostRecent = (
  reserves: GetClaimReservesSuccessResponse["reserves"],
) =>
  reserves
    .slice()
    .sort(
      (reserve1, reserve2) =>
        reserve2.createdAt.getTime() - reserve1.createdAt.getTime(),
    );

const roundToTwoDecimalPlaces = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100;

export const calculateReserveState = (
  reserves: GetClaimReservesSuccessResponse["reserves"],
): CalculatedReserveState => {
  const calculatedReserveState: Partial<CalculatedReserveState> = {};
  enumValues(ClaimCostType).forEach((reserveType) => {
    const reservesForType = reserves.filter(
      (r) => r.reserveType === reserveType,
    );

    const amount = reservesForType
      .map((r) => r.changeAmount)
      .reduce((accumulator, changeAmount) => accumulator + changeAmount, 0);

    calculatedReserveState[reserveType] = {
      amount: roundToTwoDecimalPlaces(amount),
      mostRecentReserveId: sortReservesByMostRecent(reservesForType)[0]?.id,
    };
  });

  return calculatedReserveState as CalculatedReserveState;
};

export type ClaimReserveFormData = {
  amount: string;
  description: string;
  reserveType: ClaimCostType;
  reserveState: CalculatedReserveTypeState;
};

export const buildInitialClaimReserveFormData = (
  reserveType: ClaimCostType,
  reserveState: CalculatedReserveTypeState,
): ClaimReserveFormData => ({
  amount: `${reserveState.amount}`,
  description: "",
  reserveType,
  reserveState,
});

export const mapClaimReserveFormDataToCreateClaimReserveRequest = (
  claimId: string,
  {
    reserveType,
    reserveState: {amount, mostRecentReserveId},
    ...formData
  }: ClaimReserveFormData,
): CreateClaimReserveRequest => {
  const {amount: newAmount, description} =
    CLAIM_RESERVE_FORM_SCHEMA.validateSync(formData);
  return {
    claimId,
    reserveType,
    description,
    changeAmount: newAmount - amount,
    ifMatch: mostRecentReserveId,
  };
};

const ClaimReservesForm: React.FC<{
  claimId: string;
  reserveType: ClaimCostType;
  reserveState: CalculatedReserveTypeState;
  onClose: () => any;
  onSubmitComplete: () => any;
}> = ({claimId, reserveType, reserveState, onClose, onSubmitComplete}) => {
  const {adminApi} = useContext(DependencyContext);
  const createClaimReserveMutation = useMutation({
    mutationFn: adminApi.createClaimReserve,
  });
  const submitHandler = useCallback(
    (
      formData: ClaimReserveFormData,
      {setSubmitting}: FormikHelpers<ClaimReserveFormData>,
    ) => {
      const request = mapClaimReserveFormDataToCreateClaimReserveRequest(
        claimId,
        formData,
      );
      createClaimReserveMutation.mutate(request, {
        onSuccess: () => {
          onSubmitComplete();
          onClose();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [claimId, createClaimReserveMutation, onClose, onSubmitComplete],
  );
  return (
    <Formik<ClaimReserveFormData>
      initialValues={buildInitialClaimReserveFormData(
        reserveType,
        reserveState,
      )}
      validationSchema={CLAIM_RESERVE_FORM_SCHEMA as any}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <AmountField name="amount" />

          <DescriptionField name="description" />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton />

        {createClaimReserveMutation.isError ? (
          <FormResultErrorMessage data-testid="error-state">
            Request failed. This is most likely because the{" "}
            {displayClaimCostType(reserveType)} Reserve has changed since you
            loaded the page. Please reload the page and review any changes
            before trying again.
          </FormResultErrorMessage>
        ) : null}
      </FormikForm>
    </Formik>
  );
};
