import React from "react";

import {AuditExportStatus} from "../adminApi";
import {
  ErrorPill,
  InfoPill,
  SuccessPill,
  WarningPill,
} from "../components/Pills";

export const AuditExportStatusPill: React.FC<{
  status: AuditExportStatus;
}> = ({status}) => {
  switch (status) {
    case AuditExportStatus.Started:
      return <AuditExportStatusPIllStarted />;
    case AuditExportStatus.Success:
      return <AuditExportStatusPIllSuccess />;
    case AuditExportStatus.Failure:
      return <AuditExportStatusPIllFailure />;
    default:
      return <AuditExportStatusPIllCreated />;
  }
};

const AuditExportStatusPIllCreated: React.FC = () => (
  <InfoPill label="Created" />
);

const AuditExportStatusPIllStarted: React.FC = () => (
  <WarningPill label="Started" />
);

const AuditExportStatusPIllSuccess: React.FC = () => (
  <SuccessPill label="Success" />
);

const AuditExportStatusPIllFailure: React.FC = () => (
  <ErrorPill label="Failure" />
);
