import {
  InsurfulOrderResultFailed as FetchInsurfulOrderResultFailed,
  InsurfulOrderResultPending as FetchInsurfulOrderResultPending,
  InsurfulOrderResultSuccessful as FetchInsurfulOrderResultSuccessful,
} from "../fetchClient";

const INSURFUL_ORDER_RESULT_SUCCESSFUL_STATUS = "successful" as const;

export type InsurfulOrderResultSuccessful =
  FetchInsurfulOrderResultSuccessful & {
    status: typeof INSURFUL_ORDER_RESULT_SUCCESSFUL_STATUS;
  };

const INSURFUL_ORDER_RESULT_FAILED_STATUS = "failed" as const;

export type InsurfulOrderResultFailed = FetchInsurfulOrderResultFailed & {
  status: typeof INSURFUL_ORDER_RESULT_FAILED_STATUS;
};

const INSURFUL_ORDER_RESULT_PENDING_STATUS = "pending" as const;

export type InsurfulOrderResultPending = FetchInsurfulOrderResultPending & {
  status: typeof INSURFUL_ORDER_RESULT_PENDING_STATUS;
};

export type InsurfulOrderResult =
  | InsurfulOrderResultSuccessful
  | InsurfulOrderResultFailed
  | InsurfulOrderResultPending;

export const isInsurfulOrderResultSuccessful = (
  result: InsurfulOrderResult,
): result is InsurfulOrderResultSuccessful =>
  result.status === INSURFUL_ORDER_RESULT_SUCCESSFUL_STATUS;

export const isInsurfulOrderResultPending = (
  result: InsurfulOrderResult,
): result is InsurfulOrderResultPending =>
  result.status === INSURFUL_ORDER_RESULT_PENDING_STATUS;

export const isInsurfulOrderResultFailed = (
  result: InsurfulOrderResult,
): result is InsurfulOrderResultFailed =>
  result.status === INSURFUL_ORDER_RESULT_FAILED_STATUS;
