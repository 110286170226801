/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttomPropertyDocumentReference
 */
export interface AttomPropertyDocumentReference {
    /**
     * 
     * @type {string}
     * @memberof AttomPropertyDocumentReference
     */
    documentNumber: string;
    /**
     * 
     * @type {string}
     * @memberof AttomPropertyDocumentReference
     */
    documentType?: string;
    /**
     * 
     * @type {Date}
     * @memberof AttomPropertyDocumentReference
     */
    instrumentDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AttomPropertyDocumentReference
     */
    recordingDate: Date;
}

/**
 * Check if a given object implements the AttomPropertyDocumentReference interface.
 */
export function instanceOfAttomPropertyDocumentReference(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "documentNumber" in value;
    isInstance = isInstance && "recordingDate" in value;

    return isInstance;
}

export function AttomPropertyDocumentReferenceFromJSON(json: any): AttomPropertyDocumentReference {
    return AttomPropertyDocumentReferenceFromJSONTyped(json, false);
}

export function AttomPropertyDocumentReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttomPropertyDocumentReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentNumber': json['documentNumber'],
        'documentType': !exists(json, 'documentType') ? undefined : json['documentType'],
        'instrumentDate': !exists(json, 'instrumentDate') ? undefined : (new Date(json['instrumentDate'])),
        'recordingDate': (new Date(json['recordingDate'])),
    };
}

export function AttomPropertyDocumentReferenceToJSON(value?: AttomPropertyDocumentReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentNumber': value.documentNumber,
        'documentType': value.documentType,
        'instrumentDate': value.instrumentDate === undefined ? undefined : (value.instrumentDate.toISOString().substr(0,10)),
        'recordingDate': (value.recordingDate.toISOString().substr(0,10)),
    };
}

