/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderFlag } from './OrderFlag';
import {
    OrderFlagFromJSON,
    OrderFlagFromJSONTyped,
    OrderFlagToJSON,
} from './OrderFlag';

/**
 * 
 * @export
 * @interface OrderFlagActive
 */
export interface OrderFlagActive extends OrderFlag {
    /**
     * 
     * @type {string}
     * @memberof OrderFlagActive
     */
    comment: string;
}

/**
 * Check if a given object implements the OrderFlagActive interface.
 */
export function instanceOfOrderFlagActive(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "comment" in value;

    return isInstance;
}

export function OrderFlagActiveFromJSON(json: any): OrderFlagActive {
    return OrderFlagActiveFromJSONTyped(json, false);
}

export function OrderFlagActiveFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderFlagActive {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...OrderFlagFromJSONTyped(json, ignoreDiscriminator),
        'comment': json['comment'],
    };
}

export function OrderFlagActiveToJSON(value?: OrderFlagActive | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...OrderFlagToJSON(value),
        'comment': value.comment,
    };
}

