import {
  ThemeProvider as AmplifyThemeProvider,
  defaultDarkModeOverride,
  Theme,
} from "@aws-amplify/ui-react";
import React from "react";

const AMPLIFY_THEME: Theme = {
  name: "title-service",
  overrides: [defaultDarkModeOverride],
  tokens: {
    fonts: {
      default: {
        variable: "'Munich Re Sans', sans-serif",
        static: "'Munich Re Sans', sans-serif",
      },
    },
    colors: {
      brand: {
        primary: {
          10: {value: "{colors.blue.10}"},
          20: {value: "{colors.blue.20}"},
          40: {value: "{colors.blue.40}"},
          60: {value: "{colors.blue.60}"},
          80: {value: "{colors.blue.80}"},
          90: {value: "{colors.blue.90}"},
          100: {value: "{colors.blue.100}"},
        },
        secondary: {
          10: {value: "{colors.teal.10}"},
          20: {value: "{colors.teal.20}"},
          40: {value: "{colors.teal.40}"},
          60: {value: "{colors.teal.60}"},
          80: {value: "{colors.teal.80}"},
          90: {value: "{colors.teal.90}"},
          100: {value: "{colors.teal.100}"},
        },
      },
    },
  },
};

export const ThemeProvider: React.FC<
  React.ComponentProps<typeof AmplifyThemeProvider>
> = (props) => (
  <AmplifyThemeProvider colorMode="light" theme={AMPLIFY_THEME} {...props} />
);
