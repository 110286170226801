/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValidationReportDetailStatistics } from './ValidationReportDetailStatistics';
import {
    ValidationReportDetailStatisticsFromJSON,
    ValidationReportDetailStatisticsFromJSONTyped,
    ValidationReportDetailStatisticsToJSON,
} from './ValidationReportDetailStatistics';
import type { ValidationReportDetailUpdateBaselineMetadata } from './ValidationReportDetailUpdateBaselineMetadata';
import {
    ValidationReportDetailUpdateBaselineMetadataFromJSON,
    ValidationReportDetailUpdateBaselineMetadataFromJSONTyped,
    ValidationReportDetailUpdateBaselineMetadataToJSON,
} from './ValidationReportDetailUpdateBaselineMetadata';

/**
 * 
 * @export
 * @interface ValidationReportDetailUpdateBaseline
 */
export interface ValidationReportDetailUpdateBaseline {
    /**
     * 
     * @type {ValidationReportDetailUpdateBaselineMetadata}
     * @memberof ValidationReportDetailUpdateBaseline
     */
    metadata: ValidationReportDetailUpdateBaselineMetadata;
    /**
     * 
     * @type {ValidationReportDetailStatistics}
     * @memberof ValidationReportDetailUpdateBaseline
     */
    statistics: ValidationReportDetailStatistics;
}

/**
 * Check if a given object implements the ValidationReportDetailUpdateBaseline interface.
 */
export function instanceOfValidationReportDetailUpdateBaseline(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "statistics" in value;

    return isInstance;
}

export function ValidationReportDetailUpdateBaselineFromJSON(json: any): ValidationReportDetailUpdateBaseline {
    return ValidationReportDetailUpdateBaselineFromJSONTyped(json, false);
}

export function ValidationReportDetailUpdateBaselineFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationReportDetailUpdateBaseline {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': ValidationReportDetailUpdateBaselineMetadataFromJSON(json['metadata']),
        'statistics': ValidationReportDetailStatisticsFromJSON(json['statistics']),
    };
}

export function ValidationReportDetailUpdateBaselineToJSON(value?: ValidationReportDetailUpdateBaseline | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': ValidationReportDetailUpdateBaselineMetadataToJSON(value.metadata),
        'statistics': ValidationReportDetailStatisticsToJSON(value.statistics),
    };
}

