import {Expander, ExpanderItem, Flex} from "@aws-amplify/ui-react";
import {
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {isLoadingNextQuery, Query} from "@title-service/react-query-utils";
import {
  FormikConnectedAmplifySelectField,
  FormikConnectedAmplifySubmitButton,
  FormikConnectedAmplifyTextField,
  FormikForm,
  SecondarySection,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
  Text,
  VerticalFieldset,
} from "@title-service/ui";
import {enumValues} from "@title-service/utils";
import {
  buildEnumSchema,
  transformNullToUndefined,
} from "@title-service/yup-utils";
import {
  Formik,
  FormikErrors,
  FormikHelpers,
  useFormikContext,
  yupToFormErrors,
} from "formik";
import React, {useCallback, useContext, useMemo, useState} from "react";
import * as Yup from "yup";

import {DependencyContext} from "../../DependencyContext";
import {
  AgencyProducerReference,
  CreateAgencyProducerRequest,
  CreateIndividualProducerRequest,
  GetAgencyProducersAutocompleteType,
  GetParentAgenciesSuccessResponse,
} from "../adminApi";
import {RouterLink} from "../components/Link";
import {LoadingOverlay} from "../components/LoadingOverlay";
import {Modal} from "../components/Modal";
import {PlusIcon} from "../components/icons";
import {safeDisplayEnumFn} from "../util/enum";

import {AgencyProducerAutocomplete} from "./AgencyProducerAutocomplete";
import {
  AgencyProducerField,
  buildAgencyProducerFormInitialData,
} from "./AgencyProducerField";
import {AmplifySecondaryContent} from "./ProducerJurisdictionsSection";

type GetParentAgenciesQueryKey = ["parentAgencies"];
export type GetParentAgenciesQuery = Query<GetParentAgenciesSuccessResponse>;

export const useGetParentAgencies = (): {
  getParentAgenciesQuery: GetParentAgenciesQuery;
  onReloadParentAgencies: () => any;
} => {
  const {adminApi} = useContext(DependencyContext);
  const queryClient = useQueryClient();
  const getParentAgenciesQueryKey: GetParentAgenciesQueryKey = useMemo(
    () => ["parentAgencies"],
    [],
  );
  const getParentAgenciesQueryFn: QueryFunction<
    GetParentAgenciesSuccessResponse,
    GetParentAgenciesQueryKey
  > = useCallback(
    ({queryKey: [_1], signal}) => adminApi.getParentAgencies(signal),
    [adminApi],
  );
  const getParentAgenciesQuery = useQuery({
    keepPreviousData: true,
    queryKey: getParentAgenciesQueryKey,
    queryFn: getParentAgenciesQueryFn,
  });
  const onReloadParentAgencies = useCallback(
    () => queryClient.invalidateQueries({queryKey: getParentAgenciesQueryKey}),
    [queryClient, getParentAgenciesQueryKey],
  );
  return {
    getParentAgenciesQuery,
    onReloadParentAgencies,
  };
};

const MODAL_CONTENT_STYLE: React.CSSProperties = {
  minWidth: "650px",
};

export const ProducersSection: React.FC<{
  ProducersSectionQueryResult?: typeof ProducersSectionQueryResult;
}> = ({
  ProducersSectionQueryResult:
    ProducersSectionQueryResult_ = ProducersSectionQueryResult,
}) => {
  const {getParentAgenciesQuery, onReloadParentAgencies} =
    useGetParentAgencies();
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <ProducersSectionQueryResult_
      getParentAgenciesQuery={getParentAgenciesQuery}
      onReloadParentAgencies={onReloadParentAgencies}
    />
  );
};

export const ProducersSectionQueryResult: React.FC<{
  ariaHideApp?: boolean;
  getParentAgenciesQuery: GetParentAgenciesQuery;
  onReloadParentAgencies: () => any;
  ProducersSectionSuccessComponent?: typeof ProducersSectionSuccessComponent;
  ProducersSectionFailureComponent?: typeof ProducersSectionFailureComponent;
  ProducersSectionLoadingComponent?: typeof ProducersSectionLoadingComponent;
}> = ({
  ariaHideApp,
  getParentAgenciesQuery,
  getParentAgenciesQuery: {data, error, isInitialLoading},
  onReloadParentAgencies,
  ProducersSectionSuccessComponent:
    ProducersSectionSuccessComponent_ = ProducersSectionSuccessComponent,
  ProducersSectionFailureComponent:
    ProducersSectionFailureComponent_ = ProducersSectionFailureComponent,
  ProducersSectionLoadingComponent:
    ProducersSectionLoadingComponent_ = ProducersSectionLoadingComponent,
}) => {
  const isLoading = isLoadingNextQuery(getParentAgenciesQuery);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const openCreateModal = useCallback(() => {
    setCreateModalOpen(true);
  }, [setCreateModalOpen]);
  const closeCreateModal = useCallback(() => {
    setCreateModalOpen(false);
  }, [setCreateModalOpen]);
  const handleProducerCreated = useCallback(() => {
    onReloadParentAgencies();
    closeCreateModal();
  }, [closeCreateModal, onReloadParentAgencies]);

  return (
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Producers" />
        <PlusIcon onClick={openCreateModal} />
        <Modal
          ariaHideApp={ariaHideApp}
          isOpen={createModalOpen}
          onClose={closeCreateModal}
          contentStyle={MODAL_CONTENT_STYLE}
          title="Create Producer"
        >
          <CreateProducerForm
            onSubmitted={handleProducerCreated}
            onClose={closeCreateModal}
          />
        </Modal>
      </SecondarySectionHeaderContainer>
      <AmplifySecondaryContent>
        <LoadingOverlay isActive={isLoading}>
          {data ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <ProducersSectionSuccessComponent_ response={data} />
          ) : error ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <ProducersSectionFailureComponent_ />
          ) : (
            // eslint-disable-next-line react/jsx-pascal-case
            isInitialLoading && <ProducersSectionLoadingComponent_ />
          )}
        </LoadingOverlay>
      </AmplifySecondaryContent>
    </SecondarySection>
  );
};

export const ProducersSectionLoadingComponent: React.FC = () => (
  <Text value="Loading Producers..." />
);

export const ProducersSectionFailureComponent: React.FC = () => (
  <Text value="Failed to Load Producers..." />
);

export const ProducersSectionSuccessComponent: React.FC<{
  response: GetParentAgenciesSuccessResponse;
}> = ({response: {parentAgencies}}) => {
  if (!parentAgencies.length) {
    return <Text value="No Producers found" />;
  }
  return (
    <Expander isCollapsible={true} type="multiple">
      {parentAgencies.map(
        ({id, name, individualProducers, agencyProducers}) => (
          <ExpanderItem key={id} value={id} title={name}>
            <Expander
              isCollapsible={true}
              type="multiple"
              defaultValue={["Agency Producers", "Individual Producers"]}
            >
              <ExpanderItem
                key="Agency Producers"
                value="Agency Producers"
                title="Agency Producers"
              >
                <Flex direction="column" paddingLeft="small">
                  {agencyProducers.length > 0 ? (
                    agencyProducers.map((agencyProducer) => (
                      <RouterLink
                        key={agencyProducer.id}
                        to={`/producers/agencies/${agencyProducer.id}/`}
                      >
                        {agencyProducer.name}
                      </RouterLink>
                    ))
                  ) : (
                    <Text value="No Agency Producers found" />
                  )}
                </Flex>
              </ExpanderItem>
              <ExpanderItem
                key="Individual Producers"
                value="Individual Producers"
                title="Individual Producers"
              >
                <Flex direction="column" paddingLeft="small">
                  {individualProducers.length > 0 ? (
                    individualProducers.map((individualProducer) => (
                      <RouterLink
                        key={individualProducer.id}
                        to={`/producers/individuals/${individualProducer.id}/`}
                      >
                        {individualProducer.name}
                      </RouterLink>
                    ))
                  ) : (
                    <Text value="No Individual Producers found" />
                  )}
                </Flex>
              </ExpanderItem>
            </Expander>
          </ExpanderItem>
        ),
      )}
    </Expander>
  );
};

export enum ProducerType {
  ParentAgency = "Parent Agency",
  AgencyProducer = "Agency Producer",
  IndividualProducer = "Individual Producer",
}

export type CreateProducerFormData =
  | {
      producerType: string;
      name: string;
      niprId: string;
      parentAgencyProducer: AgencyProducerReference;
    }
  | {
      producerType: string;
      name: string;
    };

export const buildCreateProducerFormInitialData =
  (): CreateProducerFormData => ({
    producerType: "",
    name: "",
    niprId: "",
    parentAgencyProducer: buildAgencyProducerFormInitialData(),
  });

export type CompleteCreateParentAgencyFormData = {
  producerType: ProducerType;
  name: string;
};

export type CompleteCreateProducerFormData = {
  producerType: ProducerType;
  name: string;
  niprId?: string;
  parentAgencyProducer: AgencyProducerReference;
};

export const PRODUCER_TYPE_SCHEMA: Yup.StringSchema<ProducerType> =
  buildEnumSchema(
    ProducerType,
    "Must be Parent Agency, Agency Producer, or Individual Producer",
  ).required("Producer Type is required");

export const NAME_SCHEMA: Yup.StringSchema<string> = Yup.string()
  .required("Name is required")
  .trim();

export const NIPR_ID_SCHEMA: Yup.StringSchema<string | undefined> = Yup.string()
  .trim()
  .transform(transformNullToUndefined)
  .optional();

export const PARENT_AGENCY_SCHEMA: Yup.ObjectSchema<AgencyProducerReference> =
  Yup.object({
    id: Yup.string()
      .trim()
      .transform(transformNullToUndefined)
      .required("Please select a Parent Agency from the list"),
    name: NAME_SCHEMA,
  });

export const CREATE_PARENT_AGENCY_FORM_SCHEMA: Yup.ObjectSchema<CompleteCreateParentAgencyFormData> =
  Yup.object({
    producerType: PRODUCER_TYPE_SCHEMA,
    name: NAME_SCHEMA,
  });

export const CREATE_PRODUCER_FORM_SCHEMA: Yup.ObjectSchema<CompleteCreateProducerFormData> =
  Yup.object({
    producerType: PRODUCER_TYPE_SCHEMA,
    name: NAME_SCHEMA,
    niprId: NIPR_ID_SCHEMA,
    parentAgencyProducer: PARENT_AGENCY_SCHEMA,
  });

export const getSchemaForProducerType = (producerType: string) => {
  switch (producerType) {
    case ProducerType.ParentAgency:
      return CREATE_PARENT_AGENCY_FORM_SCHEMA;
    case ProducerType.AgencyProducer:
      return CREATE_PRODUCER_FORM_SCHEMA;
    case ProducerType.IndividualProducer:
      return CREATE_PRODUCER_FORM_SCHEMA;
    default:
      return Yup.object({
        producerType: PRODUCER_TYPE_SCHEMA,
      });
  }
};

export const validateFormData = (
  values: CreateProducerFormData,
): FormikErrors<CreateProducerFormData> => {
  const schema = getSchemaForProducerType(values.producerType);
  try {
    schema.validateSync(values, {
      strict: false,
      abortEarly: false,
      stripUnknown: true,
      recursive: true,
    });
    return {};
  } catch (e) {
    if (e instanceof Yup.ValidationError) {
      return yupToFormErrors(e);
    }
    throw e;
  }
};

export const unsafeDisplayProducerType = (
  producerType: ProducerType,
): string => {
  switch (producerType) {
    case ProducerType.ParentAgency:
      return "Parent Agency";
    case ProducerType.AgencyProducer:
      return "Agency Producer";
    case ProducerType.IndividualProducer:
      return "Individual Producer";
    default:
      throw new Error(`Unknown Producer Type: ${producerType}`);
  }
};

const displayProducerType = safeDisplayEnumFn(unsafeDisplayProducerType);

export const CreateProducerForm: React.FC<{
  ParentAgencyAutocomplete?: typeof AgencyProducerAutocomplete;
  onClose: () => any;
  onSubmitted: () => any;
}> = ({
  ParentAgencyAutocomplete = AgencyProducerAutocomplete,
  onClose,
  onSubmitted,
}) => {
  const {adminApi} = useContext(DependencyContext);
  const createAgencyMutation = useMutation({
    mutationFn: adminApi.createAgencyProducer,
  });
  const createIndividualProducerMutation = useMutation({
    mutationFn: adminApi.createIndividualProducer,
  });
  const submitHandler = useCallback(
    (
      formData: CreateProducerFormData,
      {setSubmitting}: FormikHelpers<CreateProducerFormData>,
    ) => {
      const mutationOptions = {
        onSuccess: () => {
          onSubmitted();
          onClose();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      };
      const validationOptions = {
        strict: false,
        abortEarly: false,
        stripUnknown: true,
        recursive: true,
      };
      switch (formData.producerType) {
        case ProducerType.ParentAgency: {
          const request = CREATE_PARENT_AGENCY_FORM_SCHEMA.validateSync(
            formData,
            validationOptions,
          );
          createAgencyMutation.mutate(request, mutationOptions);
          break;
        }
        case ProducerType.AgencyProducer: {
          const {parentAgencyProducer, ...completeFormData} =
            CREATE_PRODUCER_FORM_SCHEMA.validateSync(
              formData,
              validationOptions,
            );
          const request: CreateAgencyProducerRequest = {
            ...completeFormData,
            parentAgencyProducerId: parentAgencyProducer.id,
          };
          createAgencyMutation.mutate(request, mutationOptions);
          break;
        }
        case ProducerType.IndividualProducer: {
          const {parentAgencyProducer, ...completeFormData} =
            CREATE_PRODUCER_FORM_SCHEMA.validateSync(
              formData,
              validationOptions,
            );
          const request: CreateIndividualProducerRequest = {
            ...completeFormData,
            parentAgencyProducerId: parentAgencyProducer.id,
          };
          createIndividualProducerMutation.mutate(request, mutationOptions);
          break;
        }
      }
    },
    [
      createAgencyMutation,
      createIndividualProducerMutation,
      onClose,
      onSubmitted,
    ],
  );
  return (
    <Formik<CreateProducerFormData>
      initialValues={buildCreateProducerFormInitialData()}
      validate={validateFormData}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <CreateProducerFormFields
            ParentAgencyAutocomplete={ParentAgencyAutocomplete}
          />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton />
      </FormikForm>
    </Formik>
  );
};

export const CreateProducerFormFields: React.FC<{
  ParentAgencyAutocomplete: typeof AgencyProducerAutocomplete;
}> = ({ParentAgencyAutocomplete}) => {
  const {values} = useFormikContext<CreateProducerFormData>();
  return (
    <VerticalFieldset alignItems="stretch" width="100%">
      <FormikConnectedAmplifySelectField
        testId="producer-type-select"
        label="Producer Type"
        name="producerType"
        placeholder="Select a Producer Type"
      >
        {enumValues(ProducerType).map((producerType) => (
          <option key={producerType} value={producerType}>
            {displayProducerType(producerType)}
          </option>
        ))}
      </FormikConnectedAmplifySelectField>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison */}
      {(values.producerType === ProducerType.AgencyProducer ||
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        values.producerType === ProducerType.IndividualProducer) && (
        <AgencyProducerField
          testId="parent-agency-autocomplete"
          label="Parent Agency"
          name="parentAgencyProducer"
          AgencyProducerAutocomplete={ParentAgencyAutocomplete}
          type={GetAgencyProducersAutocompleteType.Parent}
        />
      )}
      <FormikConnectedAmplifyTextField
        testId="name-input"
        label="Name"
        name="name"
      />
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison */}
      {(values.producerType === ProducerType.AgencyProducer ||
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        values.producerType === ProducerType.IndividualProducer) && (
        <FormikConnectedAmplifyTextField
          testId="nipr-id-input"
          label="NIPR Number (optional)"
          name="niprId"
        />
      )}
    </VerticalFieldset>
  );
};
