import {
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {Query} from "@title-service/react-query-utils";
import {
  DateTimeComponent,
  FormikConnectedAmplifyCurrencyField,
  FormikConnectedAmplifySelectField,
  FormikConnectedAmplifySubmitButton,
  FormikForm,
  FormResultErrorMessage,
  Text,
  TextRaw,
  VerticalFieldset,
} from "@title-service/ui";
import {enumKeys} from "@title-service/utils";
import {buildCurrencySchema, buildEnumSchema} from "@title-service/yup-utils";
import {Formik, FormikHelpers} from "formik";
import React, {useCallback, useContext, useMemo, useState} from "react";
import * as Yup from "yup";

import {DependencyContext} from "../../DependencyContext";
import {
  ClaimCostType,
  ClaimRecoverySource,
  ClaimStatus,
  CreateClaimRecoveryRequest,
  GetClaimRecoveriesSuccessResponse,
} from "../adminApi";
import {CurrencyComponent} from "../components/Currency";
import {Modal} from "../components/Modal";
import {PlusIcon} from "../components/icons";
import {
  BodyRow as SecondaryBodyRow,
  HeaderRow as SecondaryHeaderRow,
  Table as SecondaryTable,
  TBody as SecondaryTBody,
  Td as SecondaryTd,
  Th as SecondaryTh,
  THead as SecondaryTHead,
} from "../components/tables/SecondaryTable";
import {
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../layout";
import {safeDisplayEnumFn} from "../util/enum";

import {
  buildClaimCostTypeSchema,
  ClaimCostTypeComponent,
  ClaimCostTypeField,
} from "./ClaimCostTypeField";
import {DESCRIPTION_SCHEMA, DescriptionField} from "./DescriptionField";
import {IfClaimIsInProgress} from "./IfClaimIsInProgress";

type GetClaimRecoveriesQueryKey = ["claims", string, "recoveries"];
export type GetClaimRecoveriesQuery = Query<GetClaimRecoveriesSuccessResponse>;

export const useGetClaimRecoveries = (
  claimId: string,
): {
  getClaimRecoveriesQuery: GetClaimRecoveriesQuery;
  onReloadClaimRecoveries: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);
  const getClaimRecoveriesQueryKey: GetClaimRecoveriesQueryKey = useMemo(
    () => ["claims", claimId, "recoveries"],
    [claimId],
  );
  const getClaimRecoveriesQueryFn: QueryFunction<
    GetClaimRecoveriesSuccessResponse,
    GetClaimRecoveriesQueryKey
  > = useCallback(
    ({queryKey: [_, claimId_], signal}) =>
      adminApi.getClaimRecoveries({claimId: claimId_}, signal),
    [adminApi],
  );
  const getClaimRecoveriesQuery = useQuery({
    queryKey: getClaimRecoveriesQueryKey,
    queryFn: getClaimRecoveriesQueryFn,
  });
  const onReloadClaimRecoveries = useCallback(
    () => queryClient.invalidateQueries({queryKey: getClaimRecoveriesQueryKey}),
    [queryClient, getClaimRecoveriesQueryKey],
  );

  return {
    getClaimRecoveriesQuery,
    onReloadClaimRecoveries,
  };
};

export const ClaimRecoveriesSection: React.FC<{
  claimId: string;
  claimStatus: ClaimStatus;
  onReloadClaimRecoveries: () => any;
  getClaimRecoveriesQuery: GetClaimRecoveriesQuery;
  editModalContentStyle: NonNullable<
    React.ComponentProps<typeof Modal>["contentStyle"]
  >;
  ClaimRecoveriesSuccessComponent?: typeof ClaimRecoveriesSuccessComponent;
  ClaimRecoveriesFailureComponent?: typeof ClaimRecoveriesFailureComponent;
  ClaimRecoveriesLoadingComponent?: typeof ClaimRecoveriesLoadingComponent;
}> = ({
  claimId,
  claimStatus,
  getClaimRecoveriesQuery: {data, error},
  onReloadClaimRecoveries,
  editModalContentStyle,
  ClaimRecoveriesSuccessComponent:
    ClaimRecoveriesSuccessComponent_ = ClaimRecoveriesSuccessComponent,
  ClaimRecoveriesFailureComponent:
    ClaimRecoveriesFailureComponent_ = ClaimRecoveriesFailureComponent,
  ClaimRecoveriesLoadingComponent:
    ClaimRecoveriesLoadingComponent_ = ClaimRecoveriesLoadingComponent,
}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);
  return (
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Recoveries" />
        <IfClaimIsInProgress status={claimStatus}>
          {data ? (
            <PlusIcon data-testid="create-recovery-button" onClick={openForm} />
          ) : null}
        </IfClaimIsInProgress>
      </SecondarySectionHeaderContainer>
      <SecondarySectionContent>
        {data ? (
          <>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <ClaimRecoveriesSuccessComponent_
              getClaimRecoveriesResponse={data}
            />
            <Modal
              contentStyle={editModalContentStyle}
              isOpen={displayForm}
              onClose={closeForm}
              title="Add Recovery"
            >
              <ClaimRecoveryForm
                claimId={claimId}
                onClose={closeForm}
                onSubmitComplete={onReloadClaimRecoveries}
              />
            </Modal>
          </>
        ) : error ? (
          // eslint-disable-next-line react/jsx-pascal-case
          <ClaimRecoveriesFailureComponent_ />
        ) : (
          // eslint-disable-next-line react/jsx-pascal-case
          <ClaimRecoveriesLoadingComponent_ />
        )}
      </SecondarySectionContent>
    </SecondarySection>
  );
};

export const ClaimRecoveriesLoadingComponent: React.FC = () => (
  <span>Loading Claim Recoveries ...</span>
);

export const ClaimRecoveriesFailureComponent: React.FC = () => (
  <span>Failed to load Claim Recoveries.</span>
);

export const ClaimRecoveriesSuccessComponent: React.FC<{
  getClaimRecoveriesResponse: GetClaimRecoveriesSuccessResponse;
}> = ({getClaimRecoveriesResponse: {recoveries}}) => (
  <SecondaryTable>
    <SecondaryTHead>
      <SecondaryHeaderRow>
        <SecondaryTh>Type</SecondaryTh>
        <SecondaryTh>Source</SecondaryTh>
        <SecondaryTh>Amount</SecondaryTh>
        <SecondaryTh>Description</SecondaryTh>
        <SecondaryTh>Created At</SecondaryTh>
      </SecondaryHeaderRow>
    </SecondaryTHead>
    <SecondaryTBody>
      {recoveries.length === 0 ? (
        <SecondaryBodyRow>
          <SecondaryTd colSpan={5}>No Recoveries Recorded</SecondaryTd>
        </SecondaryBodyRow>
      ) : (
        recoveries.map(
          ({
            id,
            recoveryType,
            recoverySource,
            amount,
            description,
            createdAt,
          }) => (
            <SecondaryBodyRow key={id}>
              <SecondaryTd>
                <ClaimCostTypeComponent claimCostType={recoveryType} />
              </SecondaryTd>
              <SecondaryTd>
                <Text
                  value={
                    <ClaimRecoverySourceComponentRaw
                      claimRecoverySource={recoverySource}
                    />
                  }
                />
              </SecondaryTd>
              <SecondaryTd>
                <CurrencyComponent value={amount} />
              </SecondaryTd>
              <SecondaryTd>{description}</SecondaryTd>
              <SecondaryTd>
                <DateTimeComponent value={createdAt} />
              </SecondaryTd>
            </SecondaryBodyRow>
          ),
        )
      )}
    </SecondaryTBody>
  </SecondaryTable>
);

export const AMOUNT_SCHEMA: Yup.NumberSchema<number> = buildCurrencySchema()
  .required("Amount is required")
  .positive("Amount must be greater than 0");

export const AmountField: React.FC<
  Omit<
    React.ComponentProps<typeof FormikConnectedAmplifyCurrencyField>,
    "label"
  >
> = (props) => (
  <FormikConnectedAmplifyCurrencyField
    testId="amount-input"
    label="Amount"
    {...props}
  />
);

export const RECOVERY_TYPE_SCHEMA: Yup.StringSchema<ClaimCostType> =
  buildClaimCostTypeSchema().required("Recovery Type is required");

export const unsafeDisplayClaimRecoverySource = (
  claimRecoverySource: ClaimRecoverySource,
): string => {
  switch (claimRecoverySource) {
    case ClaimRecoverySource.Salvage:
      return "Salvage";
    case ClaimRecoverySource.Subrogation:
      return "Subrogation";
    case ClaimRecoverySource.Deductible:
      return "Deductible";
    default:
      throw new Error(`Unknown ClaimCost Type: ${claimRecoverySource}`);
  }
};

export const displayClaimRecoverySource = safeDisplayEnumFn(
  unsafeDisplayClaimRecoverySource,
);

export const ClaimRecoverySourceComponentRaw: React.FC<{
  claimRecoverySource: ClaimRecoverySource;
}> = ({claimRecoverySource}) => (
  <TextRaw value={displayClaimRecoverySource(claimRecoverySource)} />
);

export const RECOVERY_SOURCE_SCHEMA: Yup.StringSchema<ClaimRecoverySource> =
  buildEnumSchema(
    ClaimRecoverySource,
    "Must be Salvage, Subrogation or Deductible",
  ).required("Recovery Source is required");

export const RecoverySourceField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifySelectField>, "label">
> = (props) => (
  <FormikConnectedAmplifySelectField
    testId="recovery-source-input"
    label="Recovery Source"
    placeholder=""
    {...props}
  >
    <option value="" />
    {enumKeys(ClaimRecoverySource).map((claimRecoverySourceKey) => {
      const claimRecoverySource: ClaimRecoverySource =
        ClaimRecoverySource[claimRecoverySourceKey];
      return (
        <option key={claimRecoverySource} value={claimRecoverySource}>
          {displayClaimRecoverySource(claimRecoverySource)}
        </option>
      );
    })}
  </FormikConnectedAmplifySelectField>
);

type CompleteClaimRecoveryFormData = Omit<
  CreateClaimRecoveryRequest,
  "claimId"
>;

export const CLAIM_RECOVERY_FORM_SCHEMA: Yup.ObjectSchema<CompleteClaimRecoveryFormData> =
  Yup.object({
    amount: AMOUNT_SCHEMA,
    description: DESCRIPTION_SCHEMA,
    recoveryType: RECOVERY_TYPE_SCHEMA,
    recoverySource: RECOVERY_SOURCE_SCHEMA,
  });

export type ClaimRecoveryFormData = Omit<
  CreateClaimRecoveryRequest,
  "claimId" | "amount" | "recoveryType" | "recoverySource"
> & {
  amount: string;
  recoveryType: string;
  recoverySource: string;
};

export const buildInitialClaimRecoveryFormData = (): ClaimRecoveryFormData => ({
  amount: "",
  description: "",
  recoveryType: "",
  recoverySource: "",
});

export const mapClaimRecoveryFormDataToCreateClaimRecoveryRequest = (
  claimId: string,
  formData: ClaimRecoveryFormData,
): CreateClaimRecoveryRequest => {
  const completeFormData = CLAIM_RECOVERY_FORM_SCHEMA.validateSync(formData);
  return {
    ...completeFormData,
    claimId,
  };
};

const ClaimRecoveryForm: React.FC<{
  claimId: string;
  onClose: () => any;
  onSubmitComplete: () => any;
}> = ({claimId, onClose, onSubmitComplete}) => {
  const {adminApi} = useContext(DependencyContext);
  const createClaimRecoveryMutation = useMutation({
    mutationFn: adminApi.createClaimRecovery,
  });
  const submitHandler = useCallback(
    (
      formData: ClaimRecoveryFormData,
      {setSubmitting}: FormikHelpers<ClaimRecoveryFormData>,
    ) => {
      const request = mapClaimRecoveryFormDataToCreateClaimRecoveryRequest(
        claimId,
        formData,
      );
      createClaimRecoveryMutation.mutate(request, {
        onSuccess: () => {
          onSubmitComplete();
          onClose();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [claimId, createClaimRecoveryMutation, onClose, onSubmitComplete],
  );
  return (
    <Formik<ClaimRecoveryFormData>
      initialValues={buildInitialClaimRecoveryFormData()}
      validationSchema={CLAIM_RECOVERY_FORM_SCHEMA as any}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <ClaimCostTypeField label="Recovery Type" name="recoveryType" />

          <RecoverySourceField name="recoverySource" />

          <AmountField name="amount" />

          <DescriptionField name="description" />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton />

        {createClaimRecoveryMutation.isError ? (
          <FormResultErrorMessage data-testid="error-state">
            Request failed.
          </FormResultErrorMessage>
        ) : null}
      </FormikForm>
    </Formik>
  );
};
