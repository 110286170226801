/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAuditsFilters
 */
export interface GetAuditsFilters {
    /**
     * 
     * @type {string}
     * @memberof GetAuditsFilters
     */
    agencyId?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetAuditsFilters
     */
    lowerBoundDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetAuditsFilters
     */
    state?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetAuditsFilters
     */
    upperBoundDate?: Date;
}

/**
 * Check if a given object implements the GetAuditsFilters interface.
 */
export function instanceOfGetAuditsFilters(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetAuditsFiltersFromJSON(json: any): GetAuditsFilters {
    return GetAuditsFiltersFromJSONTyped(json, false);
}

export function GetAuditsFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAuditsFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agencyId': !exists(json, 'agencyId') ? undefined : json['agencyId'],
        'lowerBoundDate': !exists(json, 'lowerBoundDate') ? undefined : (new Date(json['lowerBoundDate'])),
        'state': !exists(json, 'state') ? undefined : json['state'],
        'upperBoundDate': !exists(json, 'upperBoundDate') ? undefined : (new Date(json['upperBoundDate'])),
    };
}

export function GetAuditsFiltersToJSON(value?: GetAuditsFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agencyId': value.agencyId,
        'lowerBoundDate': value.lowerBoundDate === undefined ? undefined : (value.lowerBoundDate.toISOString().substr(0,10)),
        'state': value.state,
        'upperBoundDate': value.upperBoundDate === undefined ? undefined : (value.upperBoundDate.toISOString().substr(0,10)),
    };
}

