import {Alert, Button, Flex, Loader, Placeholder} from "@aws-amplify/ui-react";
import {
  QueryFunction,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import {isLoadingNextQuery, Query} from "@title-service/react-query-utils";
import {
  DateComponent,
  DateTimeComponent,
  FieldsetHeading,
  FormikConnectedAmplifyCurrencyField,
  FormikConnectedAmplifyDateField,
  FormikConnectedAmplifySelectField,
  FormikConnectedAmplifySubmitButton,
  FormikConnectedAmplifyTextField,
  FormikForm,
  Heading,
  PrimarySection,
  Text,
  TextRaw,
  VerticalFieldset,
} from "@title-service/ui";
import {
  displayAsIsoLocalDate,
  enumValues,
  notNullOrUndefined,
} from "@title-service/utils";
import {
  buildCurrencySchema,
  buildDateSchema,
  buildEnumSchema,
  transformNullToUndefined,
} from "@title-service/yup-utils";
import {Formik, FormikHelpers, useFormikContext} from "formik";
import React, {useCallback, useContext, useMemo, useState} from "react";
import * as Yup from "yup";

import {DependencyContext} from "../../DependencyContext";
import {
  Cpl,
  CreateRemittanceRequest,
  GetOrderRemittanceItemsSuccessResponse,
  GetOrderRemittanceItemsSuccessResponseItem,
  GetRemittanceItemsSuccessResponse,
  OrderPermissions,
  Policy,
  PolicyType,
  RemittanceItem,
  RemittanceItemCpl,
  RemittanceItemEndorsement,
  RemittanceItemFee,
  RemittanceItemPolicy,
  RemittanceReference,
  RemittanceType,
  TransactionPartyType,
} from "../adminApi";
import {CurrencyComponent} from "../components/Currency";
import {LoadingOverlay} from "../components/LoadingOverlay";
import {Modal} from "../components/Modal";
import {MaybeNotAvailable} from "../components/Nullable";
import {EditIcon, TrashIcon} from "../components/icons";
import {
  AboveTableContainer,
  BodyRow,
  HeaderRow,
  Table,
  TBody,
  Td,
  Th,
  THead,
} from "../components/tables/PrimaryTable";
import {SecondarySection, SecondarySectionContent} from "../layout";
import {displayPolicyType} from "../policy/PolicyType";

import {
  displayRemittanceType,
  RemittanceTypeComponent,
  RemittanceTypeComponentRaw,
} from "./RemittanceType";
import {displayTransactionPartyType} from "./TransactionPartyType";

type GetOrderRemittanceItemsQueryKey = ["orders", string, "remittances"];
export type GetOrderRemittanceItemsQuery =
  Query<GetOrderRemittanceItemsSuccessResponse>;

export const useGetOrderRemittanceItems = (
  orderId: string,
): {
  getOrderRemittanceItemsQuery: GetOrderRemittanceItemsQuery;
  onReloadOrderRemittanceItems: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);
  const getOrderRemittanceItemsQueryKey: GetOrderRemittanceItemsQueryKey =
    useMemo(() => ["orders", orderId, "remittances"], [orderId]);
  const getOrderRemittanceItemsQueryFn: QueryFunction<
    GetOrderRemittanceItemsSuccessResponse,
    GetOrderRemittanceItemsQueryKey
  > = useCallback(
    ({queryKey: [_, orderId_], signal}) =>
      adminApi.getOrderRemittanceItems({orderId: orderId_}, signal),
    [adminApi],
  );
  const getOrderRemittanceItemsQuery = useQuery({
    queryKey: getOrderRemittanceItemsQueryKey,
    queryFn: getOrderRemittanceItemsQueryFn,
    keepPreviousData: true,
  });
  const onReloadOrderRemittanceItems = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: getOrderRemittanceItemsQueryKey,
      }),
    [queryClient, getOrderRemittanceItemsQueryKey],
  );

  return {
    getOrderRemittanceItemsQuery,
    onReloadOrderRemittanceItems,
  };
};

export const RemittancesTab: React.FC<{
  orderId: string;
  cpls: Cpl[];
  policies: Policy[];
  orderPermissions: OrderPermissions;
  RemittancesTabContent?: typeof RemittancesTabContent;
}> = ({
  orderId,
  cpls,
  policies,
  orderPermissions,
  RemittancesTabContent: RemittancesTabContent_ = RemittancesTabContent,
}) => {
  const {getOrderRemittanceItemsQuery, onReloadOrderRemittanceItems} =
    useGetOrderRemittanceItems(orderId);

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <RemittancesTabContent_
      cpls={cpls}
      policies={policies}
      orderPermissions={orderPermissions}
      getOrderRemittanceItemsQuery={getOrderRemittanceItemsQuery}
      onReloadOrderRemittanceItems={onReloadOrderRemittanceItems}
    />
  );
};

const MODAL_CONTENT_STYLE: React.CSSProperties = {
  minWidth: "650px",
};

export const RemittancesTabContent: React.FC<{
  cpls: Cpl[];
  policies: Policy[];
  orderPermissions: OrderPermissions;
  getOrderRemittanceItemsQuery: GetOrderRemittanceItemsQuery;
  onReloadOrderRemittanceItems: () => any;
  OrderRemittancesSuccessTableBody?: typeof OrderRemittancesSuccessTableBody;
  OrderRemittancesPlaceholderTableBody?: typeof OrderRemittancesPlaceholderTableBody;
  RemittanceForm?: typeof RemittanceForm;
  ariaHideApp?: boolean;
}> = ({
  ariaHideApp,
  cpls,
  policies,
  orderPermissions,
  getOrderRemittanceItemsQuery,
  getOrderRemittanceItemsQuery: {data, error, isInitialLoading},
  onReloadOrderRemittanceItems,
  OrderRemittancesSuccessTableBody:
    OrderRemittancesSuccessTableBody_ = OrderRemittancesSuccessTableBody,
  OrderRemittancesPlaceholderTableBody:
    OrderRemittancesPlaceholderTableBody_ = OrderRemittancesPlaceholderTableBody,
  RemittanceForm: RemittanceForm_ = RemittanceForm,
}) => {
  const {adminApi} = useContext(DependencyContext);
  const isLoading = isLoadingNextQuery(getOrderRemittanceItemsQuery);
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);
  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);
  const showReportRemittanceButton = orderPermissions.reportRemittance;
  return (
    <PrimarySection>
      <SecondarySection>
        <SecondarySectionContent>
          <Modal
            ariaHideApp={ariaHideApp}
            isOpen={modalOpen}
            onClose={closeModal}
            contentStyle={MODAL_CONTENT_STYLE}
            title="Report Remittance"
          >
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <RemittanceForm_
              mutationFn={adminApi.createRemittance}
              cpls={cpls}
              policies={policies}
              onSubmitComplete={onReloadOrderRemittanceItems}
            />
          </Modal>
          {error || showReportRemittanceButton ? (
            <AboveTableContainer
              justifyContent={determineAboveTableContainerContentJustification(
                !!error,
                showReportRemittanceButton,
              )}
            >
              {error ? (
                <Alert variation="error" isDismissible={false} hasIcon={true}>
                  Failed to load Remittances
                </Alert>
              ) : null}
              {showReportRemittanceButton ? (
                <Button
                  testId="report-remittance-button"
                  variation="link"
                  onClick={openModal}
                >
                  Report Remittance
                </Button>
              ) : null}
            </AboveTableContainer>
          ) : null}
          <LoadingOverlay isActive={isLoading}>
            <Table width="100%">
              <THead>
                <HeaderRow>
                  <Th>ACH ID</Th>
                  <Th>Type</Th>
                  <Th>Remitted ID</Th>
                  <Th>Amount</Th>
                  <Th>Created On</Th>
                  <Th>Remittance Date</Th>
                  <Th>Created By</Th>
                  {showReportRemittanceButton ? <Th /> : null}
                </HeaderRow>
              </THead>
              {data ? (
                // eslint-disable-next-line react/jsx-pascal-case
                <OrderRemittancesSuccessTableBody_
                  cpls={cpls}
                  policies={policies}
                  orderPermissions={orderPermissions}
                  response={data}
                  onReloadOrderRemittances={onReloadOrderRemittanceItems}
                />
              ) : error ? (
                // eslint-disable-next-line react/jsx-pascal-case
                <OrderRemittancesPlaceholderTableBody_ isLoaded={true} />
              ) : (
                isInitialLoading && (
                  // eslint-disable-next-line react/jsx-pascal-case
                  <OrderRemittancesPlaceholderTableBody_ isLoaded={false} />
                )
              )}
            </Table>
          </LoadingOverlay>
        </SecondarySectionContent>
      </SecondarySection>
    </PrimarySection>
  );
};

const determineAboveTableContainerContentJustification = (
  hasError: boolean,
  showReportRemittanceButton: boolean,
): NonNullable<React.CSSProperties["justifyContent"]> => {
  if (hasError && showReportRemittanceButton) {
    return "space-between";
  } else if (hasError && !showReportRemittanceButton) {
    return "flex-start";
  } else if (!hasError && showReportRemittanceButton) {
    return "flex-end";
  }
  return "flex-start";
};

export const OrderRemittancesPlaceholderTableBody: React.FC<{
  isLoaded: boolean;
}> = ({isLoaded}) => (
  <TBody>
    <BodyRow>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
    </BodyRow>
  </TBody>
);

export const sortOrderRemittanceItemsByCreatedAtAsc = (
  items: GetOrderRemittanceItemsSuccessResponseItem[],
): GetOrderRemittanceItemsSuccessResponseItem[] =>
  items
    .slice()
    .sort(
      (item1, item2) =>
        item1.remittance.createdAt.getTime() -
        item2.remittance.createdAt.getTime(),
    );

export const OrderRemittancesSuccessTableBody: React.FC<{
  cpls: Cpl[];
  policies: Policy[];
  orderPermissions: OrderPermissions;
  response: GetOrderRemittanceItemsSuccessResponse;
  onReloadOrderRemittances: () => any;
}> = ({
  cpls,
  policies,
  orderPermissions,
  onReloadOrderRemittances,
  response: {items},
}) => {
  if (!items.length) {
    return (
      <TBody>
        <BodyRow>
          <Td colSpan={orderPermissions.reportRemittance ? 8 : 7}>
            No Remittances found
          </Td>
        </BodyRow>
      </TBody>
    );
  }

  return (
    <TBody>
      {sortOrderRemittanceItemsByCreatedAtAsc(items).map(
        (orderRemittanceItem, i) => (
          <OrderRemittancesSuccessTableBodyRow
            key={i}
            cpls={cpls}
            policies={policies}
            orderPermissions={orderPermissions}
            orderRemittanceItem={orderRemittanceItem}
            onReloadOrderRemittances={onReloadOrderRemittances}
          />
        ),
      )}
    </TBody>
  );
};

export const OrderRemittancesSuccessTableBodyRow: React.FC<{
  cpls: Cpl[];
  policies: Policy[];
  orderPermissions: OrderPermissions;
  orderRemittanceItem: GetOrderRemittanceItemsSuccessResponseItem;
  onReloadOrderRemittances: () => any;
  ariaHideApp?: boolean;
  RefundRemittanceForm?: typeof RefundRemittanceForm;
  EditRemittanceForm?: typeof EditRemittanceForm;
}> = ({
  ariaHideApp,
  cpls,
  policies,
  orderPermissions,
  orderRemittanceItem,
  orderRemittanceItem: {
    remittance: {
      achId,
      createdAt,
      remittedOn,
      creatorGivenName,
      creatorFamilyName,
    },
    item: {type, amount},
  },
  onReloadOrderRemittances,
  RefundRemittanceForm: RefundRemittanceForm_ = RefundRemittanceForm,
  EditRemittanceForm: EditRemittanceForm_ = EditRemittanceForm,
}) => {
  const remittedIdDisplay = displayRemittedId(orderRemittanceItem.item);
  const [refundModalOpen, setRefundModalOpen] = useState(false);
  const openRefundModal = useCallback(() => {
    setRefundModalOpen(true);
  }, [setRefundModalOpen]);
  const closeRefundModal = useCallback(() => {
    setRefundModalOpen(false);
  }, [setRefundModalOpen]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const openEditModal = useCallback(() => {
    setEditModalOpen(true);
  }, [setEditModalOpen]);
  const closeEditModal = useCallback(() => {
    setEditModalOpen(false);
  }, [setEditModalOpen]);
  return (
    <BodyRow>
      <Td>
        <Text value={achId} />
      </Td>
      <Td>
        <RemittanceTypeComponent remittanceType={type} />
      </Td>
      <Td>
        <Text value={remittedIdDisplay} />
      </Td>
      <Td>
        <CurrencyComponent value={amount} />
      </Td>
      <Td>
        <DateTimeComponent value={createdAt} />
      </Td>
      <Td>
        <MaybeNotAvailable value={remittedOn}>
          {(remittedOn_) => <DateComponent value={remittedOn_} />}
        </MaybeNotAvailable>
      </Td>
      <Td>
        <MaybeNotAvailable
          fallback={<Text value="API" />}
          value={creatorGivenName}
        >
          {(creatorGivenName_) => (
            <MaybeNotAvailable
              fallback={<Text value="API" />}
              value={creatorFamilyName}
            >
              {(creatorFamilyName_) => (
                <Text value={`${creatorGivenName_} ${creatorFamilyName_}`} />
              )}
            </MaybeNotAvailable>
          )}
        </MaybeNotAvailable>
      </Td>
      {orderPermissions.reportRemittance ? (
        <Td>
          <Flex direction="row" alignItems="center" justifyContent="flex-start">
            <Button
              testId="edit-remittance-button"
              variation="link"
              onClick={openEditModal}
            >
              <EditIcon />
            </Button>

            <Button
              testId="refund-remittance-button"
              variation="link"
              onClick={openRefundModal}
            >
              Report Refund
            </Button>
          </Flex>
          <Modal
            ariaHideApp={ariaHideApp}
            isOpen={editModalOpen}
            onClose={closeEditModal}
            contentStyle={MODAL_CONTENT_STYLE}
            title="Edit Remittance"
          >
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <EditRemittanceForm_
              remittance={orderRemittanceItem.remittance}
              cpls={cpls}
              policies={policies}
              onSubmitComplete={onReloadOrderRemittances}
            />
          </Modal>
          <Modal
            ariaHideApp={ariaHideApp}
            isOpen={refundModalOpen}
            onClose={closeRefundModal}
            contentStyle={MODAL_CONTENT_STYLE}
            title="Refund Remittance"
          >
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <RefundRemittanceForm_
              remittedIdDisplay={remittedIdDisplay}
              orderRemittanceItem={orderRemittanceItem}
              onSubmitComplete={onReloadOrderRemittances}
            />
          </Modal>
        </Td>
      ) : null}
    </BodyRow>
  );
};

const displayRemittedId = (item: RemittanceItem): string => {
  switch (item.type) {
    case RemittanceType.Cpl: {
      return item.cplId;
    }
    case RemittanceType.Policy: {
      return item.policyId;
    }
    case RemittanceType.Endorsement: {
      return `${item.policyId} - ${item.endorsementCode}`;
    }
    case RemittanceType.Fee: {
      return `${item.policyId} - ${item.feeCode}`;
    }
  }
};

type RemittanceFormCplOption = {
  id: string;
  state: string;
  type: TransactionPartyType;
};

type RemittanceFormPolicyOption = {
  id: string;
  state: string;
  type: PolicyType;
  endorsements: RemittanceFormEndorsementOption[];
  fees: RemittanceFormFeeOption[];
};

type RemittanceFormEndorsementOption = {
  code: string;
};

type RemittanceFormFeeOption = {
  code: string;
};

type RemittanceItemAssociationData =
  | Pick<RemittanceItemCpl, "type" | "cplId">
  | Pick<RemittanceItemPolicy, "type" | "policyId">
  | Pick<RemittanceItemEndorsement, "type" | "policyId" | "endorsementCode">
  | Pick<RemittanceItemFee, "type" | "policyId" | "feeCode">;

type EmptyRemittanceInsertItemAssociationData = {
  type: "";
  cplId: "";
  policyId: "";
  endorsementCode: "";
  feeCode: "";
};

export type RemittanceItemFormData = (
  | RemittanceItemAssociationData
  | EmptyRemittanceInsertItemAssociationData
) & {
  amount: string;
};

export type CompleteRemittanceItemFormData = RemittanceItemAssociationData &
  Pick<RemittanceItem, "amount">;

export const buildRemittanceItemFormSchema = ({
  cpls,
  policies,
}: {
  cpls: RemittanceFormCplOption[];
  policies: RemittanceFormPolicyOption[];
}): Yup.ObjectSchema<CompleteRemittanceItemFormData> =>
  Yup.object({
    type: buildEnumSchema(
      RemittanceType,
      "Must be a valid Remittance type",
    ).required("Remittance type is required"),
    amount: buildCurrencySchema().required("Remittance amount is required"),
    cplId: Yup.string()
      .trim()
      .transform(transformNullToUndefined)
      .oneOf(
        cpls.map((cpl) => cpl.id),
        "Must select a valid CPL",
      )
      .when("type", {
        is: (type: RemittanceType) => type === RemittanceType.Cpl,
        then: (schema) => schema.required("CPL is required"),
        otherwise: (schema) => schema.optional(),
      }),
    policyId: Yup.string()
      .trim()
      .transform(transformNullToUndefined)
      .oneOf(
        policies.map((policy) => policy.id),
        "Must select a valid Policy",
      )
      .when("type", {
        is: (type: RemittanceType) =>
          type === RemittanceType.Policy ||
          type === RemittanceType.Endorsement ||
          type === RemittanceType.Fee,
        then: (schema) => schema.required("Policy is required"),
        otherwise: (schema) => schema.optional(),
      }),
    endorsementCode: Yup.string()
      .trim()
      .transform(transformNullToUndefined)
      .when(
        ["type", "policyId"],
        buildPolicyComponentValidator(
          RemittanceType.Endorsement,
          policies,
          (policy) =>
            policy.endorsements.map((endorsement) => endorsement.code),
          "Endorsement is required",
          "Must select a valid Endorsement",
        ),
      ),
    feeCode: Yup.string()
      .trim()
      .transform(transformNullToUndefined)
      .when(
        ["type", "policyId"],
        buildPolicyComponentValidator(
          RemittanceType.Fee,
          policies,
          (policy) => policy.fees.map((fee) => fee.code),
          "Fee is required",
          "Must select a valid Fee",
        ),
      ),
  }) as Yup.ObjectSchema<CompleteRemittanceItemFormData>;

const buildPolicyComponentValidator =
  (
    remittanceType: RemittanceType,
    policies: RemittanceFormPolicyOption[],
    selectValidValues: (policy: RemittanceFormPolicyOption) => string[],
    requiredErrorMessage: string,
    invalidErrorMessage: string,
  ) =>
  ([type, policyId]: any[], schema: Yup.StringSchema): Yup.StringSchema => {
    if (type !== remittanceType) {
      return schema;
    }
    const foundPolicy = policies.find((policy) => policy.id === policyId);
    if (!foundPolicy) {
      return schema;
    }
    return schema
      .required(requiredErrorMessage)
      .oneOf(selectValidValues(foundPolicy), invalidErrorMessage);
  };

const buildRemittanceItemFormInitialValues = (): RemittanceItemFormData => ({
  type: "",
  cplId: "",
  policyId: "",
  endorsementCode: "",
  feeCode: "",
  amount: "",
});

export type RemittanceFormData = Pick<CreateRemittanceRequest, "achId"> & {
  remittedOn: string;
} & {
  items: RemittanceItemFormData[];
};

export type CompleteRemittanceFormData = Pick<
  CreateRemittanceRequest,
  "achId" | "remittedOn"
> & {
  items: CompleteRemittanceItemFormData[];
};

const buildRemittanceFormInitialValues = (): RemittanceFormData => ({
  achId: "",
  remittedOn: "",
  items: [buildRemittanceItemFormInitialValues()],
});

export const buildRemittanceFormSchema = ({
  cpls,
  policies,
}: {
  cpls: RemittanceFormCplOption[];
  policies: RemittanceFormPolicyOption[];
}): Yup.ObjectSchema<CompleteRemittanceFormData> =>
  Yup.object({
    achId: Yup.string().trim().required("ACH ID is required"),
    remittedOn: buildDateSchema().required("Remitted date is required"),
    items: Yup.array()
      .of(buildRemittanceItemFormSchema({cpls, policies}))
      .ensure()
      .min(1, "Remittance must have at least 1 line item"),
  }) as Yup.ObjectSchema<CompleteRemittanceFormData>;

export const RemittanceForm: React.FC<{
  initialData?: RemittanceFormData;
  cpls: RemittanceFormCplOption[];
  policies: RemittanceFormPolicyOption[];
  mutationFn: (completeFormData: CompleteRemittanceFormData) => Promise<void>;
  onSubmitComplete: () => any;
}> = ({
  initialData = buildRemittanceFormInitialValues(),
  mutationFn,
  cpls,
  policies,
  onSubmitComplete,
}) => {
  const mutation = useMutation({
    mutationFn,
  });
  const formSchema = useMemo(
    () => buildRemittanceFormSchema({cpls, policies}),
    [cpls, policies],
  );
  const submitHandler = useCallback(
    (
      formData: RemittanceFormData,
      {setSubmitting}: FormikHelpers<RemittanceFormData>,
    ) => {
      const completeRemittanceFormData = formSchema.validateSync(formData);
      mutation.mutate(completeRemittanceFormData, {
        onSuccess: () => {
          onSubmitComplete();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [mutation, formSchema, onSubmitComplete],
  );

  return (
    <Formik<RemittanceFormData>
      initialValues={initialData}
      validationSchema={formSchema as any}
      enableReinitialize={true}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <FormikConnectedAmplifyTextField
            testId="ach-id-input"
            label="ACH ID"
            name="achId"
          />

          <FormikConnectedAmplifyDateField
            testId="remitted-on-input"
            label="Remitted Date"
            name="remittedOn"
          />
        </VerticalFieldset>

        <RemittanceFormRemittanceItems cpls={cpls} policies={policies} />

        <AddRemittanceItemButton />

        <Flex
          direction="row"
          justifyContent={
            mutation.isError || mutation.isSuccess
              ? "space-between"
              : "flex-end"
          }
          alignItems="center"
          width="100%"
        >
          {mutation.isSuccess ? (
            <Alert testId="result-success" variation="success">
              Successfully created Remittance record
            </Alert>
          ) : null}

          {mutation.isError ? (
            <Alert testId="result-error" variation="error">
              Failed to create Remittance record
            </Alert>
          ) : null}

          <FormikConnectedAmplifySubmitButton />
        </Flex>
      </FormikForm>
    </Formik>
  );
};

const AddRemittanceItemButton: React.FC = () => {
  const {values, setValues} = useFormikContext<RemittanceFormData>();
  const handleClick = useCallback(() => {
    setValues({
      ...values,
      items: [...values.items, buildRemittanceItemFormInitialValues()],
    });
  }, [setValues, values]);

  return (
    <Button
      testId="add-remittance-item"
      variation="primary"
      onClick={handleClick}
    >
      Add Line Item
    </Button>
  );
};

const RemittanceFormRemittanceItems: React.FC<{
  cpls: RemittanceFormCplOption[];
  policies: RemittanceFormPolicyOption[];
}> = ({cpls, policies}) => {
  const {values} = useFormikContext<RemittanceFormData>();

  return (
    <>
      {values.items.map((item, i) => (
        <RemittanceFormRemittanceItem
          key={i}
          index={i}
          cpls={cpls}
          policies={policies}
        />
      ))}
    </>
  );
};

const RemittanceFormRemittanceItem: React.FC<{
  cpls: RemittanceFormCplOption[];
  policies: RemittanceFormPolicyOption[];
  index: number;
}> = ({index, cpls, policies}) => {
  const {setValues, values} = useFormikContext<RemittanceFormData>();
  const itemFormData = values.items[index];
  const itemKey = `items[${index}]`;

  const handleRemoveRemittanceItem = useCallback(() => {
    const newItems = values.items.filter((_, itemIndex) => itemIndex !== index);
    setValues({
      ...values,
      items: newItems,
    });
  }, [index, setValues, values]);
  return (
    <>
      <Flex direction="row" alignItems="center" justifyContent="flex-start">
        <FieldsetHeading value={`Line item ${index + 1}`} />
        {values.items.length > 1 && (
          <TrashIcon
            testId={`remove-remittance-item-${index}`}
            onClick={handleRemoveRemittanceItem}
          />
        )}
      </Flex>

      <VerticalFieldset alignItems="stretch" width="100%">
        <FormikConnectedAmplifySelectField
          label="Remittance Type"
          name={`${itemKey}.type`}
          placeholder="Select a Remittance Type"
        >
          {enumValues(RemittanceType).map((remittanceType) => (
            <option key={remittanceType} value={remittanceType}>
              {displayRemittanceType(remittanceType)}
            </option>
          ))}
        </FormikConnectedAmplifySelectField>

        {itemFormData.type === RemittanceType.Cpl && (
          <CplIdSelect name={`${itemKey}.cplId`} cpls={cpls} />
        )}

        {itemFormData.type === RemittanceType.Policy && (
          <PolicyIdSelect name={`${itemKey}.policyId`} policies={policies} />
        )}

        {itemFormData.type === RemittanceType.Endorsement && (
          <>
            <PolicyIdSelect name={`${itemKey}.policyId`} policies={policies} />

            <EndorsementCodeSelect
              name={`${itemKey}.endorsementCode`}
              policyId={itemFormData.policyId}
              policies={policies}
            />
          </>
        )}

        {itemFormData.type === RemittanceType.Fee && (
          <>
            <PolicyIdSelect name={`${itemKey}.policyId`} policies={policies} />

            <FeeCodeSelect
              name={`${itemKey}.feeCode`}
              policyId={itemFormData.policyId}
              policies={policies}
            />
          </>
        )}

        <FormikConnectedAmplifyCurrencyField
          label="Amount"
          name={`${itemKey}.amount`}
        />
      </VerticalFieldset>
    </>
  );
};

const CplIdSelect: React.FC<{
  name: string;
  cpls: RemittanceFormCplOption[];
}> = ({name, cpls}) => (
  <FormikConnectedAmplifySelectField
    label="CPL"
    name={name}
    placeholder="Select a CPL"
  >
    {cpls.map(({id, type, state}) => (
      <option key={id} value={id}>
        {`${displayTransactionPartyType(type)} - ${state} (${id})`}
      </option>
    ))}
  </FormikConnectedAmplifySelectField>
);

const PolicyIdSelect: React.FC<{
  name: string;
  policies: RemittanceFormPolicyOption[];
}> = ({name, policies}) => (
  <FormikConnectedAmplifySelectField
    label="Policy"
    name={name}
    placeholder="Select a Policy"
  >
    {policies.map(({id, type, state}) => (
      <option key={id} value={id}>
        {`${displayPolicyType(type)} - ${state} (${id})`}
      </option>
    ))}
  </FormikConnectedAmplifySelectField>
);

const EndorsementCodeSelect: React.FC<{
  name: string;
  policyId: string;
  policies: RemittanceFormPolicyOption[];
}> = ({name, policyId, policies}) => {
  const policy = policies.find(({id}) => id === policyId);
  const endorsements = policy?.endorsements ?? [];

  return (
    <FormikConnectedAmplifySelectField
      label="Endorsement"
      name={name}
      placeholder="Select an Endorsement"
      isDisabled={!policy}
    >
      {endorsements.map(({code}) => (
        <option key={code} value={code}>
          {code}
        </option>
      ))}
    </FormikConnectedAmplifySelectField>
  );
};

const FeeCodeSelect: React.FC<{
  name: string;
  policyId: string;
  policies: RemittanceFormPolicyOption[];
}> = ({name, policyId, policies}) => {
  const policy = policies.find(({id}) => id === policyId);
  const fees = policy?.fees ?? [];

  return (
    <FormikConnectedAmplifySelectField
      label="Fee"
      name={name}
      placeholder="Select a Fee"
      isDisabled={!policy}
    >
      {fees.map(({code}) => (
        <option key={code} value={code}>
          {code}
        </option>
      ))}
    </FormikConnectedAmplifySelectField>
  );
};

export type RefundRemittanceFormData = Pick<
  CreateRemittanceRequest,
  "achId"
> & {
  amount: string;
  remittedOn: string;
};

export const buildRefundRemittanceFormInitialValues = ({
  amount,
}: RemittanceItem): RefundRemittanceFormData => ({
  achId: "",
  amount: amount.toString(10),
  remittedOn: "",
});

export type CompletedRefundRemittanceFormData = Pick<
  CreateRemittanceRequest,
  "achId" | "remittedOn"
> &
  Pick<RemittanceItem, "amount">;

export const buildRefundRemittanceFormSchema =
  (): Yup.ObjectSchema<CompletedRefundRemittanceFormData> =>
    Yup.object({
      achId: Yup.string().trim().required("ACH ID is required"),
      amount: buildCurrencySchema().required("Remittance amount is required"),
      remittedOn: buildDateSchema().required("Remitted date is required"),
    });

const getRemittanceInsertItemAssociationData = ({
  amount,
  ...associationData
}: RemittanceItem): RemittanceItemAssociationData => associationData;

export const RefundRemittanceForm: React.FC<{
  remittedIdDisplay: string;
  orderRemittanceItem: GetOrderRemittanceItemsSuccessResponseItem;
  onSubmitComplete: () => any;
}> = ({remittedIdDisplay, orderRemittanceItem, onSubmitComplete}) => {
  const {adminApi} = useContext(DependencyContext);
  const createRemittanceMutation = useMutation({
    mutationFn: adminApi.createRemittance,
  });
  const formSchema = useMemo(() => buildRefundRemittanceFormSchema(), []);
  const initialData = useMemo(
    () => buildRefundRemittanceFormInitialValues(orderRemittanceItem.item),
    [orderRemittanceItem],
  );
  const submitHandler = useCallback(
    (
      formData: RefundRemittanceFormData,
      {setSubmitting}: FormikHelpers<RefundRemittanceFormData>,
    ) => {
      const completedRefundRemittanceFormData =
        formSchema.validateSync(formData);
      const createRemittanceRequest: CreateRemittanceRequest = {
        achId: completedRefundRemittanceFormData.achId,
        remittedOn: completedRefundRemittanceFormData.remittedOn,
        items: [
          {
            amount: -1 * completedRefundRemittanceFormData.amount,
            ...getRemittanceInsertItemAssociationData(orderRemittanceItem.item),
          },
        ],
      };
      createRemittanceMutation.mutate(createRemittanceRequest, {
        onSuccess: () => {
          onSubmitComplete();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [
      createRemittanceMutation,
      formSchema,
      onSubmitComplete,
      orderRemittanceItem,
    ],
  );

  return (
    <Formik<RefundRemittanceFormData>
      initialValues={initialData}
      validationSchema={formSchema as any}
      enableReinitialize={true}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <Heading
            level={6}
            value={
              <>
                <RemittanceTypeComponentRaw
                  remittanceType={orderRemittanceItem.item.type}
                />
                <TextRaw value=": " />
                {remittedIdDisplay}
              </>
            }
          />

          <FormikConnectedAmplifyCurrencyField
            testId="amount-input"
            label="Refund Amount"
            name="amount"
          />

          <FormikConnectedAmplifyTextField
            testId="ach-id-input"
            label="ACH ID"
            name="achId"
          />

          <FormikConnectedAmplifyDateField
            testId="remitted-on-input"
            label="Remitted Date"
            name="remittedOn"
          />
        </VerticalFieldset>

        <Flex
          direction="row"
          justifyContent={
            createRemittanceMutation.isError ||
            createRemittanceMutation.isSuccess
              ? "space-between"
              : "flex-end"
          }
          alignItems="center"
          width="100%"
        >
          {createRemittanceMutation.isSuccess ? (
            <Alert testId="result-success" variation="success">
              Successfully created Remittance Refund record
            </Alert>
          ) : null}

          {createRemittanceMutation.isError ? (
            <Alert testId="result-error" variation="error">
              Failed to create Remittance Refund record
            </Alert>
          ) : null}

          <FormikConnectedAmplifySubmitButton />
        </Flex>
      </FormikForm>
    </Formik>
  );
};

type GetRemittanceItemsQueryKey = ["remittances", string, "items"];
export type GetRemittanceItemsQuery = Query<GetRemittanceItemsSuccessResponse>;

export const useGetRemittanceItems = (
  remittanceId: string,
): {
  getRemittanceItemsQuery: GetRemittanceItemsQuery;
  onReloadRemittanceItems: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);
  const getRemittanceItemsQueryKey: GetRemittanceItemsQueryKey = useMemo(
    () => ["remittances", remittanceId, "items"],
    [remittanceId],
  );
  const getRemittanceItemsQueryFn: QueryFunction<
    GetRemittanceItemsSuccessResponse,
    GetRemittanceItemsQueryKey
  > = useCallback(
    ({queryKey: [_, remittanceId_], signal}) =>
      adminApi.getRemittanceItems({remittanceId: remittanceId_}, signal),
    [adminApi],
  );
  const getRemittanceItemsQuery = useQuery({
    queryKey: getRemittanceItemsQueryKey,
    queryFn: getRemittanceItemsQueryFn,
    keepPreviousData: true,
  });
  const onReloadRemittanceItems = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: getRemittanceItemsQueryKey,
      }),
    [queryClient, getRemittanceItemsQueryKey],
  );
  return {getRemittanceItemsQuery, onReloadRemittanceItems};
};

export const EditRemittanceForm: React.FC<{
  remittance: RemittanceReference;
  cpls: Cpl[];
  policies: Policy[];
  onSubmitComplete: () => any;
  EditRemittanceFormRemittanceItemsQueryStatus?: typeof EditRemittanceFormGetRemittanceItemsQueryStatus;
}> = ({
  remittance,
  cpls,
  policies,
  onSubmitComplete,
  EditRemittanceFormRemittanceItemsQueryStatus:
    EditRemittanceFormRemittanceItemsQueryStatus_ = EditRemittanceFormGetRemittanceItemsQueryStatus,
}) => {
  const {getRemittanceItemsQuery, onReloadRemittanceItems} =
    useGetRemittanceItems(remittance.id);

  const handleSubmitComplete = useCallback(() => {
    onReloadRemittanceItems();
    onSubmitComplete();
  }, [onReloadRemittanceItems, onSubmitComplete]);

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <EditRemittanceFormRemittanceItemsQueryStatus_
      remittance={remittance}
      cpls={cpls}
      policies={policies}
      onSubmitComplete={handleSubmitComplete}
      getRemittanceItemsQuery={getRemittanceItemsQuery}
    />
  );
};

type GetCplQueryKey = ["cpls", string];
export type GetCplQuery = Query<Cpl>;

type GetPolicyQueryKey = ["policies", string];
export type GetPolicyQuery = Query<Policy>;

export const EditRemittanceFormGetRemittanceItemsQueryStatus: React.FC<{
  remittance: RemittanceReference;
  cpls: Cpl[];
  policies: Policy[];
  onSubmitComplete: () => any;
  getRemittanceItemsQuery: GetRemittanceItemsQuery;
  EditRemittanceFormGetRemittanceItemAssociationsQueries?: typeof EditRemittanceFormGetRemittanceItemAssociationsQueries;
}> = ({
  remittance,
  cpls,
  policies,
  onSubmitComplete,
  getRemittanceItemsQuery: {data, error},
  EditRemittanceFormGetRemittanceItemAssociationsQueries:
    EditRemittanceFormGetRemittanceItemAssociationsQueries_ = EditRemittanceFormGetRemittanceItemAssociationsQueries,
}) => {
  if (data) {
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <EditRemittanceFormGetRemittanceItemAssociationsQueries_
        remittance={remittance}
        cpls={cpls}
        policies={policies}
        onSubmitComplete={onSubmitComplete}
        getRemittanceItemsSuccessResponse={data}
      />
    );
  } else if (error) {
    return <EditRemittanceFormLoadErrorMessage />;
  }

  return <EditRemittanceFormLoading />;
};

export const EditRemittanceFormGetRemittanceItemAssociationsQueries: React.FC<{
  remittance: RemittanceReference;
  cpls: Cpl[];
  policies: Policy[];
  onSubmitComplete: () => any;
  getRemittanceItemsSuccessResponse: GetRemittanceItemsSuccessResponse;
  EditRemittanceFormGetRemittanceItemAssociationsQueryStatus?: typeof EditRemittanceFormGetRemittanceItemAssociationsQueryStatus;
}> = ({
  remittance,
  cpls,
  policies,
  onSubmitComplete,
  getRemittanceItemsSuccessResponse,
  getRemittanceItemsSuccessResponse: {items},
  EditRemittanceFormGetRemittanceItemAssociationsQueryStatus:
    EditRemittanceFormGetRemittanceItemAssociationsQueryStatus_ = EditRemittanceFormGetRemittanceItemAssociationsQueryStatus,
}) => {
  const {adminApi} = useContext(DependencyContext);
  const getCplQueryFn: QueryFunction<Cpl, GetCplQueryKey> = useCallback(
    ({queryKey: [_, cplId_], signal}) =>
      adminApi.getCpl({cplId: cplId_}, signal),
    [adminApi],
  );
  const getPolicyQueryFn: QueryFunction<Policy, GetPolicyQueryKey> =
    useCallback(
      ({queryKey: [_, policyId_], signal}) =>
        adminApi.getPolicy({policyId: policyId_}, signal),
      [adminApi],
    );
  const cplQueriesAlreadyLoaded = useQueries({
    queries: cpls.map((cpl) => {
      const queryOptions: UseQueryOptions<Cpl, unknown, Cpl, GetCplQueryKey> = {
        queryKey: ["cpls", cpl.id],
        queryFn: getCplQueryFn,
        initialData: cpl,
        refetchOnMount: false,
      };
      return queryOptions;
    }),
  });
  const cplIdsNotLoaded = items
    .flatMap((item) => ("cplId" in item && item.cplId ? [item.cplId] : []))
    .filter(
      (cplId) => cpls.findIndex((existingCpl) => existingCpl.id === cplId) < 0,
    );
  const cplQueriesNotLoaded = useQueries({
    queries: cplIdsNotLoaded.map((cplId) => {
      const queryOptions: UseQueryOptions<Cpl, unknown, Cpl, GetCplQueryKey> = {
        queryKey: ["cpls", cplId],
        queryFn: getCplQueryFn,
      };
      return queryOptions;
    }),
  });
  const cplQueries = cplQueriesAlreadyLoaded.concat(cplQueriesNotLoaded);
  const policyQueriesAlreadyLoaded = useQueries({
    queries: policies.map((policy) => {
      const queryOptions: UseQueryOptions<
        Policy,
        unknown,
        Policy,
        GetPolicyQueryKey
      > = {
        queryKey: ["policies", policy.id],
        queryFn: getPolicyQueryFn,
        initialData: policy,
        refetchOnMount: false,
      };
      return queryOptions;
    }),
  });
  const policyIdsNotLoaded = items
    .flatMap((item) =>
      "policyId" in item && item.policyId ? [item.policyId] : [],
    )
    .filter(
      (policyId) =>
        policies.findIndex((existingPolicy) => existingPolicy.id === policyId) <
        0,
    );
  const policyQueriesNotLoaded = useQueries({
    queries: policyIdsNotLoaded.map((policyId) => {
      const queryOptions: UseQueryOptions<
        Policy,
        unknown,
        Policy,
        GetPolicyQueryKey
      > = {
        queryKey: ["policies", policyId],
        queryFn: getPolicyQueryFn,
      };
      return queryOptions;
    }),
  });
  const policyQueries = policyQueriesAlreadyLoaded.concat(
    policyQueriesNotLoaded,
  );

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <EditRemittanceFormGetRemittanceItemAssociationsQueryStatus_
      remittance={remittance}
      cpls={cplQueries}
      policies={policyQueries}
      getRemittanceItemsSuccessResponse={getRemittanceItemsSuccessResponse}
      onSubmitComplete={onSubmitComplete}
    />
  );
};

export const EditRemittanceFormGetRemittanceItemAssociationsQueryStatus: React.FC<{
  remittance: RemittanceReference;
  cpls: GetCplQuery[];
  policies: GetPolicyQuery[];
  getRemittanceItemsSuccessResponse: GetRemittanceItemsSuccessResponse;
  onSubmitComplete: () => any;
  RemittanceForm?: typeof RemittanceForm;
}> = ({
  remittance: {id: remittanceId, achId, remittedOn},
  cpls,
  policies,
  getRemittanceItemsSuccessResponse: {items},
  onSubmitComplete,
  RemittanceForm: RemittanceForm_ = RemittanceForm,
}) => {
  const {adminApi} = useContext(DependencyContext);
  const initialData: RemittanceFormData = useMemo(
    () => ({
      achId,
      remittedOn: remittedOn ? displayAsIsoLocalDate(remittedOn) : "",
      items: items.map(({amount, ...associationData}) => ({
        amount: amount.toString(10),
        ...associationData,
      })),
    }),
    [achId, items, remittedOn],
  );
  const editRemittanceMutationFn = useCallback(
    (completeRemittanceFormData: CompleteRemittanceFormData) =>
      adminApi.updateRemittance({
        ...completeRemittanceFormData,
        remittanceId,
      }),
    [adminApi, remittanceId],
  );
  const cplOptions: RemittanceFormCplOption[] = useMemo(
    () => filterCompletedQueries(cpls),
    [cpls],
  );
  const policyOptions: RemittanceFormPolicyOption[] = useMemo(
    () =>
      filterCompletedQueries(policies).map((policy) => {
        const itemEndorsementCodesForPolicy = items.flatMap((item) =>
          item.type === RemittanceType.Endorsement &&
          item.policyId === policy.id
            ? [item.endorsementCode]
            : [],
        );
        const missingEndorsementCodes = itemEndorsementCodesForPolicy.filter(
          (endorsementCode) =>
            !policy.endorsements.some(
              (endorsement) => endorsement.code === endorsementCode,
            ),
        );
        const itemFeeCodesForPolicy = items.flatMap((item) =>
          item.type === RemittanceType.Fee && item.policyId === policy.id
            ? [item.feeCode]
            : [],
        );
        const missingFeeCodes = itemFeeCodesForPolicy.filter(
          (feeCode) => !policy.fees.some((fee) => fee.code === feeCode),
        );
        return {
          ...policy,
          endorsements: [
            ...policy.endorsements,
            ...missingEndorsementCodes.map((code) => ({code})),
          ],
          fees: [...policy.fees, ...missingFeeCodes.map((code) => ({code}))],
        };
      }),
    [items, policies],
  );

  if (
    cplOptions.length === cpls.length &&
    policyOptions.length === policies.length
  ) {
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <RemittanceForm_
        initialData={initialData}
        cpls={cplOptions}
        policies={policyOptions}
        mutationFn={editRemittanceMutationFn}
        onSubmitComplete={onSubmitComplete}
      />
    );
  }

  if (
    cpls.some((cpl) => cpl.isError) ||
    policies.some((policy) => policy.isError)
  ) {
    return <EditRemittanceFormLoadErrorMessage />;
  }

  return <EditRemittanceFormLoading />;
};

const filterCompletedQueries = <TData,>(queries: Query<TData>[]): TData[] =>
  queries.flatMap(({data}: Query<TData>) =>
    notNullOrUndefined(data) ? [data] : [],
  );

const EditRemittanceFormLoading: React.FC = () => (
  <Flex
    testId="edit-remittance-form-loading"
    height="100%"
    width="100%"
    justifyContent="center"
    alignItems="center"
  >
    <Loader width="7em" height="7em" />
  </Flex>
);

const EditRemittanceFormLoadErrorMessage: React.FC = () => (
  <Flex
    testId="edit-remittance-form-load-error-message"
    height="100%"
    width="100%"
    justifyContent="center"
    alignItems="center"
  >
    <Alert variation="error" isDismissible={false} hasIcon={true}>
      Failed to load Remittance
    </Alert>
  </Flex>
);
