import {FileUploader} from "@aws-amplify/ui-react";
import {
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {Query} from "@title-service/react-query-utils";
import {DateTimeComponent} from "@title-service/ui";
import React, {useCallback, useContext, useMemo, useState} from "react";

import {DependencyContext} from "../../DependencyContext";
import {GetOrderUnderwritingAttachmentsSuccessResponse} from "../adminApi";
import {Modal} from "../components/Modal";
import {PlusIcon} from "../components/icons";
import {
  BodyRow as SecondaryBodyRow,
  HeaderRow as SecondaryHeaderRow,
  Table as SecondaryTable,
  TBody as SecondaryTBody,
  Td as SecondaryTd,
  Th as SecondaryTh,
  THead as SecondaryTHead,
} from "../components/tables/SecondaryTable";
import {
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../layout";
import {AmplifyStorageAccessLevel, getFullObjectKey} from "../util/storage";

type GetOrderUnderwritingAttachmentsQueryKey = [
  "orders",
  string,
  "attachments",
];
export type GetOrderUnderwritingAttachmentsQuery =
  Query<GetOrderUnderwritingAttachmentsSuccessResponse>;

export const useGetOrderUnderwritingAttachments = (
  orderId: string,
): {
  getOrderUnderwritingAttachmentsQuery: GetOrderUnderwritingAttachmentsQuery;
  onReloadOrderUnderwritingAttachments: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);
  const getOrderUnderwritingAttachmentsQueryKey: GetOrderUnderwritingAttachmentsQueryKey =
    useMemo(() => ["orders", orderId, "attachments"], [orderId]);
  const getOrderUnderwritingAttachmentsQueryFn: QueryFunction<
    GetOrderUnderwritingAttachmentsSuccessResponse,
    GetOrderUnderwritingAttachmentsQueryKey
  > = useCallback(
    ({queryKey: [_, orderId_], signal}) =>
      adminApi.getOrderUnderwritingAttachments({orderId: orderId_}, signal),
    [adminApi],
  );
  const getOrderUnderwritingAttachmentsQuery = useQuery({
    queryKey: getOrderUnderwritingAttachmentsQueryKey,
    queryFn: getOrderUnderwritingAttachmentsQueryFn,
  });
  const onReloadOrderUnderwritingAttachments = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: getOrderUnderwritingAttachmentsQueryKey,
      }),
    [queryClient, getOrderUnderwritingAttachmentsQueryKey],
  );

  return {
    getOrderUnderwritingAttachmentsQuery,
    onReloadOrderUnderwritingAttachments,
  };
};

const UPLOAD_ACCESS_LEVEL: AmplifyStorageAccessLevel = "private";

export const UnderwritingAttachmentsSection: React.FC<{
  orderId: string;
  getOrderUnderwritingAttachmentsQuery: GetOrderUnderwritingAttachmentsQuery;
  onReloadOrderUnderwritingAttachments: () => any;
  OrderUnderwritingAttachmentsSuccessComponent?: typeof OrderUnderwritingAttachmentsSuccessComponent;
  OrderUnderwritingAttachmentsFailureComponent?: typeof OrderUnderwritingAttachmentsFailureComponent;
  OrderUnderwritingAttachmentsLoadingComponent?: typeof OrderUnderwritingAttachmentsLoadingComponent;
}> = ({
  orderId,
  getOrderUnderwritingAttachmentsQuery: {data, error},
  onReloadOrderUnderwritingAttachments,
  OrderUnderwritingAttachmentsSuccessComponent:
    OrderUnderwritingAttachmentsSuccessComponent_ = OrderUnderwritingAttachmentsSuccessComponent,
  OrderUnderwritingAttachmentsFailureComponent:
    OrderUnderwritingAttachmentsFailureComponent_ = OrderUnderwritingAttachmentsFailureComponent,
  OrderUnderwritingAttachmentsLoadingComponent:
    OrderUnderwritingAttachmentsLoadingComponent_ = OrderUnderwritingAttachmentsLoadingComponent,
}) => {
  const {adminApi} = useContext(DependencyContext);
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);
  const createOrderUnderwritingAttachmentMutationFn = useCallback(
    async (filename: string) => {
      const objectKey = await getFullObjectKey(filename, UPLOAD_ACCESS_LEVEL);
      await adminApi.createOrderUnderwritingAttachment({
        orderId,
        filename,
        fileUploadDropBoxObjectKey: objectKey,
      });
    },
    [adminApi, orderId],
  );
  const createOrderUnderwritingAttachmentMutation = useMutation({
    mutationFn: createOrderUnderwritingAttachmentMutationFn,
  });
  const onFileUploadSuccess: React.ComponentProps<
    typeof FileUploader
  >["onSuccess"] = useCallback(
    ({key: filename}: {key: string}) => {
      createOrderUnderwritingAttachmentMutation.mutate(filename, {
        onSuccess: () => {
          onReloadOrderUnderwritingAttachments();
        },
      });
    },
    [
      createOrderUnderwritingAttachmentMutation,
      onReloadOrderUnderwritingAttachments,
    ],
  );
  return (
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Attachments" />
        {data ? <PlusIcon onClick={openForm} /> : null}
      </SecondarySectionHeaderContainer>
      <SecondarySectionContent>
        {data ? (
          <>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <OrderUnderwritingAttachmentsSuccessComponent_
              getOrderUnderwritingAttachmentsResponse={data}
            />
            <Modal
              contentStyle={modalContentStyle}
              isOpen={displayForm}
              onClose={closeForm}
              title="Add Attachment"
            >
              <FileUploader
                onSuccess={onFileUploadSuccess}
                acceptedFileTypes={["application/pdf"]}
                accessLevel={UPLOAD_ACCESS_LEVEL}
                hasMultipleFiles={true}
                isPreviewerVisible={true}
                isResumable={false}
                shouldAutoProceed={false}
                showImages={true}
                variation="drop"
              />
            </Modal>
          </>
        ) : error ? (
          // eslint-disable-next-line react/jsx-pascal-case
          <OrderUnderwritingAttachmentsFailureComponent_ />
        ) : (
          // eslint-disable-next-line react/jsx-pascal-case
          <OrderUnderwritingAttachmentsLoadingComponent_ />
        )}
      </SecondarySectionContent>
    </SecondarySection>
  );
};

const modalContentStyle: React.CSSProperties = {
  minWidth: "750px",
};

export const OrderUnderwritingAttachmentsLoadingComponent: React.FC = () => (
  <span>Loading Attachments ...</span>
);

export const OrderUnderwritingAttachmentsFailureComponent: React.FC = () => (
  <span>Failed to load Attachments.</span>
);

export const OrderUnderwritingAttachmentsSuccessComponent: React.FC<{
  getOrderUnderwritingAttachmentsResponse: GetOrderUnderwritingAttachmentsSuccessResponse;
}> = ({getOrderUnderwritingAttachmentsResponse: {attachments}}) => (
  <SecondaryTable>
    <SecondaryTHead>
      <SecondaryHeaderRow>
        <SecondaryTh>File</SecondaryTh>
        <SecondaryTh>Created At</SecondaryTh>
      </SecondaryHeaderRow>
    </SecondaryTHead>
    <SecondaryTBody>
      {attachments.length === 0 ? (
        <SecondaryBodyRow>
          <SecondaryTd colSpan={6}>No Attachments Uploaded</SecondaryTd>
        </SecondaryBodyRow>
      ) : (
        attachments.map(({id, artifactUrl, filename, createdAt}) => (
          <SecondaryBodyRow key={id}>
            <SecondaryTd>
              <a target="_blank" rel="noopener noreferrer" href={artifactUrl}>
                {filename}
              </a>
            </SecondaryTd>
            <SecondaryTd>
              <DateTimeComponent value={createdAt} />
            </SecondaryTd>
          </SecondaryBodyRow>
        ))
      )}
    </SecondaryTBody>
  </SecondaryTable>
);
