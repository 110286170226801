/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateValidationReportRequest
 */
export interface CreateValidationReportRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateValidationReportRequest
     */
    baselineModelVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateValidationReportRequest
     */
    modelVersion: string;
    /**
     * 
     * @type {string}
     * @memberof CreateValidationReportRequest
     */
    state: string;
}

/**
 * Check if a given object implements the CreateValidationReportRequest interface.
 */
export function instanceOfCreateValidationReportRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "modelVersion" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function CreateValidationReportRequestFromJSON(json: any): CreateValidationReportRequest {
    return CreateValidationReportRequestFromJSONTyped(json, false);
}

export function CreateValidationReportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateValidationReportRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'baselineModelVersion': !exists(json, 'baselineModelVersion') ? undefined : json['baselineModelVersion'],
        'modelVersion': json['modelVersion'],
        'state': json['state'],
    };
}

export function CreateValidationReportRequestToJSON(value?: CreateValidationReportRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'baselineModelVersion': value.baselineModelVersion,
        'modelVersion': value.modelVersion,
        'state': value.state,
    };
}

