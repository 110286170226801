import {TextRaw} from "@title-service/ui";
import React from "react";

import {AuditAttachmentType} from "../adminApi";
import {safeDisplayEnumFn} from "../util/enum";

export const unsafeDisplayAuditAttachmentType = (
  auditAttachmentType: AuditAttachmentType,
): string => {
  switch (auditAttachmentType) {
    case AuditAttachmentType.FinalReport:
      return "Final Report";
    case AuditAttachmentType.StartingLetter:
      return "Starting Letter";
    default:
      throw new Error(`Unknown AuditAttachment Type: ${auditAttachmentType}`);
  }
};

export const displayAuditAttachmentType = safeDisplayEnumFn(
  unsafeDisplayAuditAttachmentType,
);

export const AuditAttachmentTypeComponentRaw: React.FC<{
  auditAttachmentType: AuditAttachmentType;
}> = ({auditAttachmentType}) => (
  <TextRaw value={displayAuditAttachmentType(auditAttachmentType)} />
);
