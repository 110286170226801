import {Text, TextRaw} from "@title-service/ui";
import React from "react";

import {TransactionPartyType} from "../adminApi";
import {safeDisplayEnumFn} from "../util/enum";

export const unsafeDisplayTransactionPartyType = (
  transactionPartyType: TransactionPartyType,
): string => {
  switch (transactionPartyType) {
    case TransactionPartyType.Lender:
      return "Lender";
    case TransactionPartyType.Borrower:
      return "Borrower";
    case TransactionPartyType.Buyer:
      return "Buyer";
    case TransactionPartyType.Seller:
      return "Seller";
    default:
      throw new Error(`Unknown TransactionParty Type: ${transactionPartyType}`);
  }
};

export const displayTransactionPartyType = safeDisplayEnumFn(
  unsafeDisplayTransactionPartyType,
);

export const TransactionPartyTypeComponentRaw: React.FC<{
  transactionPartyType: TransactionPartyType;
}> = ({transactionPartyType}) => (
  <TextRaw value={displayTransactionPartyType(transactionPartyType)} />
);

export const TransactionPartyTypeComponent: React.FC<
  Omit<React.ComponentProps<typeof Text>, "value"> & {
    transactionPartyType: TransactionPartyType;
  }
> = ({transactionPartyType, ...props}) => (
  <Text {...props} value={displayTransactionPartyType(transactionPartyType)} />
);
