import {useMutation} from "@tanstack/react-query";
import {
  DateComponent,
  DateComponentRaw,
  DateTimeComponent,
  FieldsetHeading,
  FormikConnectedAmplifyDateField,
  FormikConnectedAmplifyPhoneNumberField,
  FormikConnectedAmplifySubmitButton,
  FormikConnectedAmplifyTextField,
  FormikForm,
  FormResultErrorMessage,
  HorizontalFieldset,
  Text,
  VerticalFieldset,
} from "@title-service/ui";
import {notNullOrUndefined} from "@title-service/utils";
import {
  buildDateSchema,
  buildEmptyObjectForSchema,
  transformNullToUndefined,
} from "@title-service/yup-utils";
import {Formik, FormikHelpers} from "formik";
import React, {useCallback, useContext, useMemo, useState} from "react";
import styled from "styled-components";
import * as Yup from "yup";

import {DependencyContext} from "../../DependencyContext";
import {
  Address,
  AgencyProducerReference,
  ClaimRequestAddressNew,
  ClaimStatus,
  GetClaimSuccessResponse,
  UpdateClaimRequest,
} from "../adminApi";
import {MultiLineAddressComponent} from "../components/Address";
import {ColumnWrapper} from "../components/ColumnWrapper";
import {
  DataGrid,
  GridItem,
  GridItemLabel,
  GridItemLabelContainer,
} from "../components/DataGrid";
import {Modal} from "../components/Modal";
import {MaybeNotAvailable} from "../components/Nullable";
import {VerticalStringList} from "../components/VerticalStringList";
import {EditIcon} from "../components/icons";
import {
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../layout";
import {
  AgencyProducerField,
  AgencyProducerFormData,
  buildAgencyProducerFormInitialData,
  buildRequiredAgencyProducerFormSchema,
} from "../producers/AgencyProducerField";
import {COLOR_N_500, FONT_SIZE_H7} from "../theme";

import {
  buildInitialCauseOfLossFormData,
  CAUSE_OF_LOSS_FORM_SCHEMA,
  CauseOfLossComponent,
  CauseOfLossField,
  CauseOfLossFormData,
  CompleteCauseOfLossFormData,
  mapCompleteCauseOfLossFormDataToCauseOfLossCode,
} from "./CauseOfLossField";
import {ClaimStatusPill} from "./ClaimStatusPill";
import {DESCRIPTION_SCHEMA, DescriptionField} from "./DescriptionField";
import {IfClaimIsInProgress} from "./IfClaimIsInProgress";
import {PolicyIdField} from "./PolicyDetailsSection";
import {
  buildInitialResponsiblePartyFormData,
  CompleteResponsiblePartyFormData,
  mapCompleteResponsiblePartyFormDataToResponsiblePartyCode,
  RESPONSIBLE_PARTY_FORM_SCHEMA,
  ResponsiblePartyComponent,
  ResponsiblePartyField,
  ResponsiblePartyFormData,
} from "./ResponsiblePartyField";

export const ClaimOverviewSection: React.FC<{
  claimStatus: ClaimStatus;
  onReloadClaim: () => any;
  response: GetClaimSuccessResponse;
  editModalContentStyle: NonNullable<
    React.ComponentProps<typeof Modal>["contentStyle"]
  >;
}> = ({
  claimStatus,
  onReloadClaim,
  editModalContentStyle,
  response,
  response: {
    agency: {name: agencyName},
    insuredName,
    contactEmail,
    contactPhone,
    address,
    description,
    reportedOn,
    status,
    responsibleParty,
    causeOfLoss,
    createdAt,
    updatedAt,
    insuredAssignedAttorneyEmail,
    insuredAssignedAttorneyName,
    insuredAssignedAttorneyPhone,
    insuredChosenAttorneyEmail,
    insuredChosenAttorneyName,
    insuredChosenAttorneyPhone,
    underwriterAttorneyEmail,
    underwriterAttorneyName,
    underwriterAttorneyPhone,
    closedByName,
  },
}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);
  return (
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Claim Overview" />
        <IfClaimIsInProgress status={claimStatus}>
          <EditIcon
            data-testid="edit-claim-overview-button"
            onClick={openForm}
          />
        </IfClaimIsInProgress>
      </SecondarySectionHeaderContainer>
      <SecondarySectionContent>
        <DataGrid>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Claim Status" />
            </GridItemLabelContainer>
            <ColumnWrapper gap="xs">
              <ClaimStatusPill status={status} />
              <MaybeClaimClosedFlag
                claimStatus={claimStatus}
                maybeClosedOn={updatedAt}
                maybeClosedBy={closedByName}
              />
            </ColumnWrapper>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Agency" />
            </GridItemLabelContainer>
            <Text value={agencyName} />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Insured Name" />
            </GridItemLabelContainer>
            <Text value={insuredName} />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Reported Date" />
            </GridItemLabelContainer>
            <DateComponent value={reportedOn} />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Contact Info" />
            </GridItemLabelContainer>
            <ColumnWrapper>
              <Text value={contactPhone} />
              <Text value={contactEmail} />
            </ColumnWrapper>
          </GridItem>
          <AttorneyContactGridItem
            label="ADTIC Attorney"
            name={underwriterAttorneyName}
            email={underwriterAttorneyEmail}
            phone={underwriterAttorneyPhone}
          />
          <AttorneyContactGridItem
            label="Insured Assigned Attorney"
            name={insuredAssignedAttorneyName}
            email={insuredAssignedAttorneyEmail}
            phone={insuredAssignedAttorneyPhone}
          />
          <AttorneyContactGridItem
            label="Insured Chosen Attorney"
            name={insuredChosenAttorneyName}
            email={insuredChosenAttorneyEmail}
            phone={insuredChosenAttorneyPhone}
          />
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Property Address" />
            </GridItemLabelContainer>
            <MultiLineAddressComponent address={address} />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Responsible Party" />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={responsibleParty}>
              {(responsibleParty_) => (
                <ResponsiblePartyComponent
                  responsibleParty={responsibleParty_}
                />
              )}
            </MaybeNotAvailable>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Cause of Loss" />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={causeOfLoss}>
              {(causeOfLoss_) => (
                <CauseOfLossComponent causeOfLoss={causeOfLoss_} />
              )}
            </MaybeNotAvailable>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Created Time" />
            </GridItemLabelContainer>
            <DateTimeComponent value={createdAt} />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Last Updated Time" />
            </GridItemLabelContainer>
            <DateTimeComponent value={updatedAt} />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Description" />
            </GridItemLabelContainer>
            <Text value={description} />
          </GridItem>
        </DataGrid>
        <Modal
          contentStyle={editModalContentStyle}
          isOpen={displayForm}
          onClose={closeForm}
          title="Edit Claim Details"
        >
          <ClaimOverviewForm
            response={response}
            onClose={closeForm}
            onSubmitComplete={onReloadClaim}
          />
        </Modal>
      </SecondarySectionContent>
    </SecondarySection>
  );
};

export const MaybeClaimClosedFlag: React.FC<{
  claimStatus: ClaimStatus;
  maybeClosedOn: Date;
  maybeClosedBy: string | undefined;
}> = ({claimStatus, maybeClosedOn, maybeClosedBy}) => (
  <>
    {claimStatus === ClaimStatus.Closed ? (
      <ClaimClosedFlagText>
        Closed on <DateComponentRaw value={maybeClosedOn} />{" "}
        {maybeClosedBy ? `by ${maybeClosedBy}` : ""}
      </ClaimClosedFlagText>
    ) : null}
  </>
);

const ClaimClosedFlagText = styled.span({
  color: COLOR_N_500,
  fontStyle: "italic",
  ...FONT_SIZE_H7,
});

const AttorneyContactGridItem: React.FC<{
  label: string;
  name?: string;
  email?: string;
  phone?: string;
}> = ({label, name, email, phone}) => {
  const presentItems = useMemo(
    () => [name, email, phone].filter(notNullOrUndefined),
    [email, name, phone],
  );
  return (
    <GridItem>
      <GridItemLabelContainer>
        <GridItemLabel value={label} />
      </GridItemLabelContainer>
      <VerticalStringList values={presentItems} />
    </GridItem>
  );
};

export const INSURED_NAME_SCHEMA: Yup.StringSchema<string> = Yup.string()
  .required("Insured name is required")
  .trim();

export const InsuredNameField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = (props) => (
  <FormikConnectedAmplifyTextField
    testId="insured-name-input"
    label="Insured Name"
    {...props}
  />
);

export const CONTACT_EMAIL_SCHEMA: Yup.StringSchema<string> = Yup.string()
  .required("Contact email is required")
  .trim()
  .email("Contact email must be a valid email address");

export const ContactEmailField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = (props) => (
  <FormikConnectedAmplifyTextField
    testId="contact-email-input"
    label="Contact Email Address"
    {...props}
  />
);

export const buildPhoneNumberValidator = (
  minimumDigitsErrorMessage: string,
): Yup.StringSchema =>
  Yup.string()
    .trim()
    .transform(transformNullToUndefined)
    .transform((currentValue, originalValue, context) => {
      if (context.isType(currentValue)) {
        return currentValue?.replace(/\D/gu, "");
      }
      return currentValue;
    })
    .min(10, minimumDigitsErrorMessage);

export const CONTACT_PHONE_SCHEMA: Yup.StringSchema<string> =
  buildPhoneNumberValidator(
    "Contact phone number must be a minimum of ten digits",
  ).required("Contact phone number is required");

const US_DIAL_CODE = "+1";
const ALLOWED_DIAL_CODES = [US_DIAL_CODE];

export const ContactPhoneField: React.FC<
  Omit<
    React.ComponentProps<typeof FormikConnectedAmplifyPhoneNumberField>,
    "label"
  >
> = (props) => (
  <FormikConnectedAmplifyPhoneNumberField
    testId="contact-phone-input"
    label="Contact Phone Number"
    defaultDialCode={US_DIAL_CODE}
    dialCodeList={ALLOWED_DIAL_CODES}
    dialCodeLabel="Dial Code"
    {...props}
  />
);

export const REPORTED_ON_SCHEMA: Yup.DateSchema<Date> =
  buildDateSchema().required("Reported date is required");

export const ReportedOnField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyDateField>, "label">
> = (props) => (
  <FormikConnectedAmplifyDateField
    testId="reported-on-input"
    label="Reported Date"
    {...props}
  />
);

export const STREET_SCHEMA: Yup.StringSchema<string> = Yup.string()
  .required("Street is required")
  .trim();

export const AddressStreetField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = (props) => (
  <FormikConnectedAmplifyTextField
    testId="address-street-input"
    label="Street"
    {...props}
  />
);

export const UNIT_SCHEMA: Yup.StringSchema = Yup.string()
  .optional()
  .trim()
  .transform(transformNullToUndefined);

export const AddressUnitField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = (props) => (
  <FormikConnectedAmplifyTextField
    testId="address-unit-input"
    label="Unit (optional)"
    {...props}
  />
);

export const CITY_SCHEMA: Yup.StringSchema<string> = Yup.string()
  .required("City is required")
  .trim();

export const AddressCityField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = (props) => (
  <FormikConnectedAmplifyTextField
    testId="address-city-input"
    label="City"
    {...props}
  />
);

export const COUNTY_SCHEMA: Yup.StringSchema<string> = Yup.string()
  .required("County is required")
  .trim();

export const AddressCountyField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = (props) => (
  <FormikConnectedAmplifyTextField
    testId="address-county-input"
    label="County"
    {...props}
  />
);

const STATE_ABBREVIATION_LENGTH_ERROR_MESSAGE =
  "State abbreviation must be two letters";

export const STATE_SCHEMA: Yup.StringSchema<string> = Yup.string()
  .required("State is required")
  .uppercase()
  .trim()
  .matches(/^[a-zA-Z]+$/u, {
    message: STATE_ABBREVIATION_LENGTH_ERROR_MESSAGE,
    excludeEmptyString: true,
  })
  .length(2, STATE_ABBREVIATION_LENGTH_ERROR_MESSAGE);

export const AddressStateField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = (props) => (
  <FormikConnectedAmplifyTextField
    testId="address-state-input"
    label="State"
    {...props}
  />
);

const ZIP_CODE_LENGTH_ERROR_MESSAGE = "Zip Code must be five numbers";
export const ZIP_CODE_SCHEMA: Yup.StringSchema<string> = Yup.string()
  .required("Zip Code is required")
  .trim()
  .matches(/^[0-9]+$/u, {
    message: ZIP_CODE_LENGTH_ERROR_MESSAGE,
    excludeEmptyString: true,
  })
  .length(5, ZIP_CODE_LENGTH_ERROR_MESSAGE);

export const AddressZipCodeField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = (props) => (
  <FormikConnectedAmplifyTextField
    testId="address-zipcode-input"
    label="Zip Code"
    {...props}
  />
);

export const ADDRESS_SCHEMA: Yup.ObjectSchema<Address> = Yup.object({
  street: STREET_SCHEMA,
  unit: UNIT_SCHEMA,
  city: CITY_SCHEMA,
  county: COUNTY_SCHEMA,
  state: STATE_SCHEMA,
  zipCode: ZIP_CODE_SCHEMA,
});

export const ATTORNEY_NAME_SCHEMA: Yup.StringSchema = Yup.string()
  .optional()
  .trim()
  .transform(transformNullToUndefined);

export const AttorneyNameField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = ({name, ...props}) => (
  <FormikConnectedAmplifyTextField
    testId={`${name}-input`}
    label="Full Name (optional)"
    name={name}
    {...props}
  />
);

export const buildAttorneyEmailSchema = (
  emailAddressFormatErrorMessage: string,
): Yup.StringSchema =>
  Yup.string()
    .optional()
    .trim()
    .transform(transformNullToUndefined)
    .email(emailAddressFormatErrorMessage);

export const ATTORNEY_EMAIL_SCHEMA = buildAttorneyEmailSchema(
  "Must be a valid email address",
);
export const AttorneyEmailField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = ({name, ...props}) => (
  <FormikConnectedAmplifyTextField
    testId={`${name}-input`}
    label="Email Address (optional)"
    name={name}
    type="email"
    {...props}
  />
);

export const ATTORNEY_PHONE_SCHEMA = buildPhoneNumberValidator(
  "Must be a minimum of ten digits",
);

export const AttorneyPhoneField: React.FC<
  Omit<
    React.ComponentProps<typeof FormikConnectedAmplifyPhoneNumberField>,
    "label"
  >
> = ({name, ...props}) => (
  <FormikConnectedAmplifyPhoneNumberField
    testId={`${name}-input`}
    label="Phone Number (optional)"
    name={name}
    defaultDialCode={US_DIAL_CODE}
    dialCodeList={ALLOWED_DIAL_CODES}
    dialCodeLabel="Dial Code"
    {...props}
  />
);

const ClaimOverviewForm: React.FC<{
  response: GetClaimSuccessResponse;
  onClose: () => any;
  onSubmitComplete: () => any;
}> = ({response, onClose, onSubmitComplete}) => {
  const {adminApi} = useContext(DependencyContext);
  const updateClaimMutation = useMutation({
    mutationFn: adminApi.updateClaim,
  });
  const submitHandler = useCallback(
    (
      formData: ClaimInfoFormData,
      {setSubmitting}: FormikHelpers<ClaimInfoFormData>,
    ) => {
      const updateClaimRequest = mapClaimInfoFormDataToUpdateClaimRequest(
        response,
        formData,
      );
      updateClaimMutation.mutate(updateClaimRequest, {
        onSuccess: () => {
          onSubmitComplete();
          onClose();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [onClose, onSubmitComplete, response, updateClaimMutation],
  );

  return (
    <Formik<ClaimInfoFormData>
      initialValues={buildInitialClaimInfoFormData(response)}
      validationSchema={CLAIM_INFO_FORM_SCHEMA as any}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <AgencyProducerField label="Agency" name="agency" />

          <InsuredNameField name="insuredName" />

          <ContactEmailField name="contactEmail" />

          <ContactPhoneField name="contactPhone" />

          <PolicyIdField name="policyId" />

          <ReportedOnField name="reportedOn" />
        </VerticalFieldset>

        <FieldsetHeading value="ADTIC Attorney" />

        <VerticalFieldset alignItems="stretch" width="100%">
          <AttorneyNameField name="underwriterAttorneyName" />

          <AttorneyEmailField name="underwriterAttorneyEmail" />

          <AttorneyPhoneField name="underwriterAttorneyPhone" />
        </VerticalFieldset>

        <FieldsetHeading value="Insured Assigned Attorney" />

        <VerticalFieldset alignItems="stretch" width="100%">
          <AttorneyNameField name="insuredAssignedAttorneyName" />

          <AttorneyEmailField name="insuredAssignedAttorneyEmail" />

          <AttorneyPhoneField name="insuredAssignedAttorneyPhone" />
        </VerticalFieldset>

        <FieldsetHeading value="Insured Chosen Attorney" />

        <VerticalFieldset alignItems="stretch" width="100%">
          <AttorneyNameField name="insuredChosenAttorneyName" />

          <AttorneyEmailField name="insuredChosenAttorneyEmail" />

          <AttorneyPhoneField name="insuredChosenAttorneyPhone" />
        </VerticalFieldset>

        <FieldsetHeading value="Property Address" />

        <HorizontalFieldset>
          <AddressStreetField name="address.street" minWidth="375px" />

          <AddressUnitField name="address.unit" minWidth="150px" />
        </HorizontalFieldset>

        <HorizontalFieldset>
          <AddressCityField name="address.city" minWidth="300px" />

          <AddressCountyField name="address.county" minWidth="300px" />
        </HorizontalFieldset>

        <HorizontalFieldset>
          <AddressStateField name="address.state" minWidth="150px" />

          <AddressZipCodeField name="address.zipCode" minWidth="225px" />
        </HorizontalFieldset>

        <ResponsiblePartyField
          alignItems="stretch"
          width="100%"
          propertyName="responsibleParty"
        />

        <CauseOfLossField
          alignItems="stretch"
          width="100%"
          propertyName="causeOfLoss"
        />

        <VerticalFieldset alignItems="stretch" width="100%">
          <DescriptionField name="description" />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton />

        {updateClaimMutation.isError ? (
          <FormResultErrorMessage data-testid="error-state">
            Request failed.
          </FormResultErrorMessage>
        ) : null}
      </FormikForm>
    </Formik>
  );
};

export const mapGetClaimSuccessResponseToUpdateClaimRequest = ({
  id,
  address,
  agency: {id: agencyId},
  contactEmail,
  contactPhone,
  insuredName,
  reportedOn,
  description,
  causeOfLoss,
  responsibleParty,
  policyId,
  policyAmountOfInsurance,
  policyIssuedOn,
  policyType,
  policyTransactionType,
  insuredAssignedAttorneyEmail,
  insuredAssignedAttorneyName,
  insuredAssignedAttorneyPhone,
  insuredChosenAttorneyEmail,
  insuredChosenAttorneyName,
  insuredChosenAttorneyPhone,
  underwriterAttorneyEmail,
  underwriterAttorneyName,
  underwriterAttorneyPhone,
}: GetClaimSuccessResponse): UpdateClaimRequest => {
  const addressNew: ClaimRequestAddressNew = {
    type: "new",
    data: address,
  };
  return {
    claimId: id,
    address: addressNew,
    agencyId,
    contactEmail,
    contactPhone,
    insuredName,
    reportedOn,
    description,
    causeOfLoss,
    responsibleParty,
    policyId,
    policyAmountOfInsurance,
    policyIssuedOn,
    policyType,
    policyTransactionType,
    insuredAssignedAttorneyEmail,
    insuredAssignedAttorneyName,
    insuredAssignedAttorneyPhone,
    insuredChosenAttorneyEmail,
    insuredChosenAttorneyName,
    insuredChosenAttorneyPhone,
    underwriterAttorneyEmail,
    underwriterAttorneyName,
    underwriterAttorneyPhone,
  };
};

type CompleteClaimInfoFormData = Omit<
  UpdateClaimRequest,
  | "claimId"
  | "agencyId"
  | "address"
  | "responsibleParty"
  | "causeOfLoss"
  | "policyId"
  | "policyAmountOfInsurance"
  | "policyIssuedOn"
  | "policyType"
  | "policyTransactionType"
> & {
  address: Address;
  agency: AgencyProducerReference;
  responsibleParty: CompleteResponsiblePartyFormData;
  causeOfLoss: CompleteCauseOfLossFormData;
};

export const CLAIM_INFO_FORM_SCHEMA: Yup.ObjectSchema<CompleteClaimInfoFormData> =
  Yup.object({
    address: ADDRESS_SCHEMA,
    agency: buildRequiredAgencyProducerFormSchema(),
    contactEmail: CONTACT_EMAIL_SCHEMA,
    contactPhone: CONTACT_PHONE_SCHEMA,
    insuredName: INSURED_NAME_SCHEMA,
    reportedOn: REPORTED_ON_SCHEMA,
    description: DESCRIPTION_SCHEMA,
    causeOfLoss: CAUSE_OF_LOSS_FORM_SCHEMA,
    responsibleParty: RESPONSIBLE_PARTY_FORM_SCHEMA,
    underwriterAttorneyName: ATTORNEY_NAME_SCHEMA,
    underwriterAttorneyEmail: ATTORNEY_EMAIL_SCHEMA,
    underwriterAttorneyPhone: ATTORNEY_PHONE_SCHEMA,
    insuredAssignedAttorneyName: ATTORNEY_NAME_SCHEMA,
    insuredAssignedAttorneyEmail: ATTORNEY_EMAIL_SCHEMA,
    insuredAssignedAttorneyPhone: ATTORNEY_PHONE_SCHEMA,
    insuredChosenAttorneyName: ATTORNEY_NAME_SCHEMA,
    insuredChosenAttorneyEmail: ATTORNEY_EMAIL_SCHEMA,
    insuredChosenAttorneyPhone: ATTORNEY_PHONE_SCHEMA,
  });

export type ClaimInfoFormData = Required<
  Pick<
    UpdateClaimRequest,
    "contactEmail" | "contactPhone" | "description" | "insuredName"
  >
> & {
  agency: AgencyProducerFormData;
  address: Required<Address>;
  reportedOn: string;
  responsibleParty: ResponsiblePartyFormData;
  causeOfLoss: CauseOfLossFormData;
  insuredAssignedAttorneyEmail: string;
  insuredAssignedAttorneyName: string;
  insuredAssignedAttorneyPhone: string;
  insuredChosenAttorneyEmail: string;
  insuredChosenAttorneyName: string;
  insuredChosenAttorneyPhone: string;
  underwriterAttorneyEmail: string;
  underwriterAttorneyName: string;
  underwriterAttorneyPhone: string;
};

export const buildInitialClaimInfoFormData = ({
  address: {street, unit, city, county, state, zipCode},
  agency,
  contactEmail,
  contactPhone,
  insuredName,
  reportedOn,
  description,
  causeOfLoss,
  responsibleParty,
  insuredAssignedAttorneyEmail,
  insuredAssignedAttorneyName,
  insuredAssignedAttorneyPhone,
  insuredChosenAttorneyEmail,
  insuredChosenAttorneyName,
  insuredChosenAttorneyPhone,
  underwriterAttorneyEmail,
  underwriterAttorneyName,
  underwriterAttorneyPhone,
}: GetClaimSuccessResponse): ClaimInfoFormData => ({
  agency: buildAgencyProducerFormInitialData(agency),
  insuredName,
  contactPhone,
  contactEmail,
  address: {
    street,
    unit: unit ?? "",
    city,
    county,
    state,
    zipCode,
  },
  reportedOn: reportedOn.toISOString().substring(0, 10),
  description,
  causeOfLoss: buildInitialCauseOfLossFormData(causeOfLoss),
  responsibleParty: buildInitialResponsiblePartyFormData(responsibleParty),
  underwriterAttorneyName: underwriterAttorneyName ?? "",
  underwriterAttorneyEmail: underwriterAttorneyEmail ?? "",
  underwriterAttorneyPhone: underwriterAttorneyPhone ?? "",
  insuredAssignedAttorneyName: insuredAssignedAttorneyName ?? "",
  insuredAssignedAttorneyEmail: insuredAssignedAttorneyEmail ?? "",
  insuredAssignedAttorneyPhone: insuredAssignedAttorneyPhone ?? "",
  insuredChosenAttorneyName: insuredChosenAttorneyName ?? "",
  insuredChosenAttorneyEmail: insuredChosenAttorneyEmail ?? "",
  insuredChosenAttorneyPhone: insuredChosenAttorneyPhone ?? "",
});

export const mapClaimInfoFormDataToUpdateClaimRequest = (
  claim: GetClaimSuccessResponse,
  formData: ClaimInfoFormData,
): UpdateClaimRequest => {
  const {address, agency, responsibleParty, causeOfLoss, ...completeFormData} =
    CLAIM_INFO_FORM_SCHEMA.validateSync(formData, {stripUnknown: true});
  const addressNew: ClaimRequestAddressNew = {
    type: "new",
    data: address,
  };
  const {agency: emptyAgencyFormData, ...emptyFormData} =
    buildEmptyObjectForSchema(CLAIM_INFO_FORM_SCHEMA);
  return {
    ...mapGetClaimSuccessResponseToUpdateClaimRequest(claim),
    ...emptyFormData,
    ...completeFormData,
    causeOfLoss: mapCompleteCauseOfLossFormDataToCauseOfLossCode(causeOfLoss),
    responsibleParty:
      mapCompleteResponsiblePartyFormDataToResponsiblePartyCode(
        responsibleParty,
      ),
    address: addressNew,
    agencyId: agency.id,
  };
};
