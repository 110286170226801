export enum AuthenticationStateType {
  AUTHENTICATED,
  UNAUTHENTICATED,
  LOADING,
}

export type AuthenticationStateAuthenticatedData = {
  jwtToken: string;
  expiresAt: Date;
  refreshSession: () => void;
  familyName: string;
  givenName: string;
};

export type AuthenticationStateAuthenticated = {
  type: AuthenticationStateType.AUTHENTICATED;
} & AuthenticationStateAuthenticatedData;

export const buildAuthenticationStateAuthenticated = (
  data: AuthenticationStateAuthenticatedData,
): AuthenticationStateAuthenticated => ({
  type: AuthenticationStateType.AUTHENTICATED,
  ...data,
});

export type AuthenticationStateUnauthenticated = {
  type: AuthenticationStateType.UNAUTHENTICATED;
};

export const buildAuthenticationStateUnauthenticated =
  (): AuthenticationStateUnauthenticated => ({
    type: AuthenticationStateType.UNAUTHENTICATED,
  });

export type AuthenticationStateLoading = {
  type: AuthenticationStateType.LOADING;
};

export const buildAuthenticationStateLoading =
  (): AuthenticationStateLoading => ({
    type: AuthenticationStateType.LOADING,
  });

export type AuthenticationState =
  | AuthenticationStateAuthenticated
  | AuthenticationStateUnauthenticated
  | AuthenticationStateLoading;

export class AuthenticationStateHolder {
  constructor(
    private _authenticationState: AuthenticationState = buildAuthenticationStateLoading(),
  ) {}

  getState = (): AuthenticationState => this._authenticationState;

  getJwtToken = (): string | undefined => {
    if (
      this._authenticationState.type === AuthenticationStateType.AUTHENTICATED
    ) {
      return this._authenticationState.jwtToken;
    }
    return undefined;
  };

  setState = (state: AuthenticationState): void => {
    this._authenticationState = state;
  };

  setLoading = (): void => {
    this._authenticationState = buildAuthenticationStateLoading();
  };

  setAuthenticated = (data: AuthenticationStateAuthenticatedData): void => {
    this._authenticationState = buildAuthenticationStateAuthenticated(data);
  };

  setUnauthenticated = (): void => {
    this._authenticationState = buildAuthenticationStateUnauthenticated();
  };
}
