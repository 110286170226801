/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PropertyDocumentAutomatedExtraction } from './PropertyDocumentAutomatedExtraction';
import {
    PropertyDocumentAutomatedExtractionFromJSON,
    PropertyDocumentAutomatedExtractionFromJSONTyped,
    PropertyDocumentAutomatedExtractionToJSON,
} from './PropertyDocumentAutomatedExtraction';

/**
 * 
 * @export
 * @interface PropertyDocumentVestingInformation
 */
export interface PropertyDocumentVestingInformation {
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDocumentVestingInformation
     */
    approved: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDocumentVestingInformation
     */
    autoApproved: boolean;
    /**
     * 
     * @type {PropertyDocumentAutomatedExtraction}
     * @memberof PropertyDocumentVestingInformation
     */
    automatedExtraction?: PropertyDocumentAutomatedExtraction;
    /**
     * 
     * @type {number}
     * @memberof PropertyDocumentVestingInformation
     */
    extractionThreshold?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyDocumentVestingInformation
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDocumentVestingInformation
     */
    rejected: boolean;
    /**
     * 
     * @type {string}
     * @memberof PropertyDocumentVestingInformation
     */
    text?: string;
}

/**
 * Check if a given object implements the PropertyDocumentVestingInformation interface.
 */
export function instanceOfPropertyDocumentVestingInformation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "approved" in value;
    isInstance = isInstance && "autoApproved" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "rejected" in value;

    return isInstance;
}

export function PropertyDocumentVestingInformationFromJSON(json: any): PropertyDocumentVestingInformation {
    return PropertyDocumentVestingInformationFromJSONTyped(json, false);
}

export function PropertyDocumentVestingInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyDocumentVestingInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'approved': json['approved'],
        'autoApproved': json['autoApproved'],
        'automatedExtraction': !exists(json, 'automatedExtraction') ? undefined : PropertyDocumentAutomatedExtractionFromJSON(json['automatedExtraction']),
        'extractionThreshold': !exists(json, 'extractionThreshold') ? undefined : json['extractionThreshold'],
        'id': json['id'],
        'rejected': json['rejected'],
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function PropertyDocumentVestingInformationToJSON(value?: PropertyDocumentVestingInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'approved': value.approved,
        'autoApproved': value.autoApproved,
        'automatedExtraction': PropertyDocumentAutomatedExtractionToJSON(value.automatedExtraction),
        'extractionThreshold': value.extractionThreshold,
        'id': value.id,
        'rejected': value.rejected,
        'text': value.text,
    };
}

