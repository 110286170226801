/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgencyProducerReference } from './AgencyProducerReference';
import {
    AgencyProducerReferenceFromJSON,
    AgencyProducerReferenceFromJSONTyped,
    AgencyProducerReferenceToJSON,
} from './AgencyProducerReference';

/**
 * 
 * @export
 * @interface GetAgencyProducersAutocompleteSuccessResponse
 */
export interface GetAgencyProducersAutocompleteSuccessResponse {
    /**
     * 
     * @type {Array<AgencyProducerReference>}
     * @memberof GetAgencyProducersAutocompleteSuccessResponse
     */
    agencyProducers: Array<AgencyProducerReference>;
}

/**
 * Check if a given object implements the GetAgencyProducersAutocompleteSuccessResponse interface.
 */
export function instanceOfGetAgencyProducersAutocompleteSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "agencyProducers" in value;

    return isInstance;
}

export function GetAgencyProducersAutocompleteSuccessResponseFromJSON(json: any): GetAgencyProducersAutocompleteSuccessResponse {
    return GetAgencyProducersAutocompleteSuccessResponseFromJSONTyped(json, false);
}

export function GetAgencyProducersAutocompleteSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAgencyProducersAutocompleteSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agencyProducers': ((json['agencyProducers'] as Array<any>).map(AgencyProducerReferenceFromJSON)),
    };
}

export function GetAgencyProducersAutocompleteSuccessResponseToJSON(value?: GetAgencyProducersAutocompleteSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agencyProducers': ((value.agencyProducers as Array<any>).map(AgencyProducerReferenceToJSON)),
    };
}

