/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderLegalDescription } from './OrderLegalDescription';
import {
    OrderLegalDescriptionFromJSON,
    OrderLegalDescriptionFromJSONTyped,
    OrderLegalDescriptionToJSON,
} from './OrderLegalDescription';
import type { OrderSourcePropertyDocumentReference } from './OrderSourcePropertyDocumentReference';
import {
    OrderSourcePropertyDocumentReferenceFromJSON,
    OrderSourcePropertyDocumentReferenceFromJSONTyped,
    OrderSourcePropertyDocumentReferenceToJSON,
} from './OrderSourcePropertyDocumentReference';

/**
 * 
 * @export
 * @interface OrderLegalDescriptionSuccess
 */
export interface OrderLegalDescriptionSuccess extends OrderLegalDescription {
    /**
     * 
     * @type {string}
     * @memberof OrderLegalDescriptionSuccess
     */
    legalDescription: string;
    /**
     * 
     * @type {OrderSourcePropertyDocumentReference}
     * @memberof OrderLegalDescriptionSuccess
     */
    sourceDocument: OrderSourcePropertyDocumentReference;
}

/**
 * Check if a given object implements the OrderLegalDescriptionSuccess interface.
 */
export function instanceOfOrderLegalDescriptionSuccess(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "legalDescription" in value;
    isInstance = isInstance && "sourceDocument" in value;

    return isInstance;
}

export function OrderLegalDescriptionSuccessFromJSON(json: any): OrderLegalDescriptionSuccess {
    return OrderLegalDescriptionSuccessFromJSONTyped(json, false);
}

export function OrderLegalDescriptionSuccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderLegalDescriptionSuccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...OrderLegalDescriptionFromJSONTyped(json, ignoreDiscriminator),
        'legalDescription': json['legalDescription'],
        'sourceDocument': OrderSourcePropertyDocumentReferenceFromJSON(json['sourceDocument']),
    };
}

export function OrderLegalDescriptionSuccessToJSON(value?: OrderLegalDescriptionSuccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...OrderLegalDescriptionToJSON(value),
        'legalDescription': value.legalDescription,
        'sourceDocument': OrderSourcePropertyDocumentReferenceToJSON(value.sourceDocument),
    };
}

