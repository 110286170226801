import {Text as AmplifyText} from "@aws-amplify/ui-react";
import React from "react";

export const TextRaw: React.FC<{
  value: string | null | undefined;
  // eslint-disable-next-line react/no-unused-prop-types
  children?: undefined;
}> = ({value}) => <>{value}</>;

export const Text: React.FC<
  Omit<React.ComponentProps<typeof AmplifyText>, "children"> & {
    value: React.ReactNode;
    children?: undefined;
  }
> = ({value, ...props}) => <AmplifyText {...props}>{value}</AmplifyText>;

export const StrongText: React.FC<
  Omit<React.ComponentProps<typeof AmplifyText>, "children"> & {
    value: React.ReactNode;
    children?: undefined;
  }
> = ({value, ...props}) => (
  <AmplifyText as="strong" fontWeight="bold" {...props}>
    {value}
  </AmplifyText>
);
