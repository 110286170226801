import {Alert, Placeholder} from "@aws-amplify/ui-react";
import {QueryFunction, useQuery} from "@tanstack/react-query";
import {isLoadingNextQuery, Query} from "@title-service/react-query-utils";
import {DateTimeComponent} from "@title-service/ui";
import React, {useCallback, useContext} from "react";

import {DependencyContext} from "../DependencyContext";
import {GetClaimsSuccessResponse} from "../shared/adminApi";
import {ClaimStatusPill} from "../shared/claims/ClaimStatusPill";
import {MultiLineAddressComponent} from "../shared/components/Address";
import {RouterButtonLink, RouterLink} from "../shared/components/Link";
import {LoadingOverlay} from "../shared/components/LoadingOverlay";
import {QuantityComponent} from "../shared/components/Quantity";
import {
  AboveTableContainer,
  BodyRow,
  HeaderRow,
  Table,
  TBody,
  Td,
  Th,
  THead,
} from "../shared/components/tables/PrimaryTable";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
} from "../shared/layout";

type GetClaimsQueryKey = ["claims", "search"];
type GetClaimsQuery = Query<GetClaimsSuccessResponse>;

export const ClaimsRoute: React.FC<{
  GetClaimsBodyContent?: typeof GetClaimsBodyContent;
}> = ({GetClaimsBodyContent: GetClaimsBodyContent_ = GetClaimsBodyContent}) => {
  const {adminApi} = useContext(DependencyContext);
  const getClaimsQueryFn: QueryFunction<
    GetClaimsSuccessResponse,
    GetClaimsQueryKey
  > = useCallback(({signal}) => adminApi.getClaims(signal), [adminApi]);
  const getClaimsQuery = useQuery({
    queryKey: ["claims", "search"],
    keepPreviousData: true,
    queryFn: getClaimsQueryFn,
  });
  return (
    <>
      <BodyHeader>
        <PrimaryBodyHeaderContainer>
          <PrimaryBodyHeader value="Claims" />
          <RouterButtonLink to="/claims/new">Create Claim</RouterButtonLink>
        </PrimaryBodyHeaderContainer>
      </BodyHeader>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <GetClaimsBodyContent_ getClaimsQuery={getClaimsQuery} />
    </>
  );
};

export const GetClaimsBodyContent: React.FC<{
  getClaimsQuery: GetClaimsQuery;
  ClaimsPagePlaceholderTableBody?: typeof ClaimsPagePlaceholderTableBody;
  ClaimsPageSuccessTableBody?: typeof ClaimsPageSuccessTableBody;
}> = ({
  getClaimsQuery,
  getClaimsQuery: {data, error, isInitialLoading},
  ClaimsPagePlaceholderTableBody:
    ClaimsPagePlaceholderTableBody_ = ClaimsPagePlaceholderTableBody,
  ClaimsPageSuccessTableBody:
    ClaimsPageSuccessTableBody_ = ClaimsPageSuccessTableBody,
}) => {
  const isLoading = isLoadingNextQuery(getClaimsQuery);
  return (
    <BodyContent>
      <AboveTableContainer justifyContent="space-between">
        {error ? (
          <Alert variation="error" isDismissible={false} hasIcon={true}>
            Failed to load Claims
          </Alert>
        ) : data ? (
          <QuantityComponent
            quantity={data.claims.length}
            label={data.claims.length === 1 ? "Claim" : "Claims"}
          />
        ) : null}
      </AboveTableContainer>
      <LoadingOverlay isActive={isLoading}>
        <Table>
          <THead>
            <HeaderRow>
              <Th>Claim ID</Th>
              <Th>Agency</Th>
              <Th>Insured</Th>
              <Th>Property Address</Th>
              <Th>Status</Th>
              <Th>Last Updated</Th>
            </HeaderRow>
          </THead>
          {data ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <ClaimsPageSuccessTableBody_ response={data} />
          ) : error ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <ClaimsPagePlaceholderTableBody_ isLoaded={true} />
          ) : (
            isInitialLoading && (
              // eslint-disable-next-line react/jsx-pascal-case
              <ClaimsPagePlaceholderTableBody_ isLoaded={false} />
            )
          )}
        </Table>
      </LoadingOverlay>
    </BodyContent>
  );
};

export const ClaimsPagePlaceholderTableBody: React.FC<{isLoaded: boolean}> = ({
  isLoaded,
}) => (
  <TBody>
    <BodyRow>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
    </BodyRow>
  </TBody>
);

export const ClaimsPageSuccessTableBody: React.FC<{
  response: GetClaimsSuccessResponse;
}> = ({response: {claims}}) => {
  if (!claims.length) {
    return (
      <TBody>
        <BodyRow>
          <Td colSpan={5}>No Claims found</Td>
        </BodyRow>
      </TBody>
    );
  }

  return (
    <TBody>
      {claims.map(
        ({id, agencyName, insuredName, address, status, updatedAt}) => (
          <BodyRow key={id}>
            <Td>
              <RouterLink to={`/claims/${id}/`}>{id}</RouterLink>
            </Td>
            <Td>{agencyName}</Td>
            <Td>{insuredName}</Td>
            <Td>
              <MultiLineAddressComponent address={address} />
            </Td>
            <Td>
              <ClaimStatusPill status={status} />
            </Td>
            <Td>
              <DateTimeComponent value={updatedAt} />
            </Td>
          </BodyRow>
        ),
      )}
    </TBody>
  );
};
