import {Flex} from "@aws-amplify/ui-react";
import {DateTimeComponent, Text, usePrimarySectionGap} from "@title-service/ui";
import React, {useCallback, useState} from "react";
import styled from "styled-components";

import {
  isOrderVestingInformationNotAvailable,
  isOrderVestingInformationSuccess,
  OrderVestingInformation,
} from "../../adminApi";
import {
  DataGrid,
  GridItem,
  GridItemLabel,
  GridItemLabelContainer,
} from "../../components/DataGrid";
import {RouterLink} from "../../components/Link";
import {ArrayMaybeEmpty, MaybeNotAvailable} from "../../components/Nullable";
import {CaretDirection, CaretIcon} from "../../components/icons";
import {
  BodyRow as PrimaryBodyRow,
  HeaderRow as PrimaryHeaderRow,
  Table as PrimaryTable,
  TBody as PrimaryTBody,
  Td as PrimaryTd,
  Th as PrimaryTh,
  THead as PrimaryTHead,
} from "../../components/tables/PrimaryTable";
import {
  BodyRow as SecondaryBodyRow,
  Table as SecondaryTable,
  TBody as SecondaryTBody,
  Td as SecondaryTd,
} from "../../components/tables/SecondaryTable";
import {
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../../layout";
import {OrderPropertyDocumentExtractionStatusPill} from "../OrderPropertyDocumentExtractionStatusPill";
import {OrderVestingInformationNotAvailableReasonComponent} from "../OrderVestingInformationNotAvailableReasonComponent";

export const VestingInformationSection: React.FC<{
  vestingInformation: OrderVestingInformation[];
}> = ({vestingInformation}) => (
  <SecondarySection>
    <SecondarySectionHeaderContainer>
      <SecondarySectionHeader value="Vesting Information" />
    </SecondarySectionHeaderContainer>
    <SecondarySectionContent>
      {vestingInformation.length > 0 ? (
        <PrimaryTable>
          <PrimaryTHead>
            <PrimaryHeaderRow>
              <PrimaryTh>ATTOM ID</PrimaryTh>
              <PrimaryTh>Status</PrimaryTh>
              <PrimaryTh>Created At</PrimaryTh>
              <PrimaryTh />
            </PrimaryHeaderRow>
          </PrimaryTHead>
          <PrimaryTBody>
            {vestingInformation.map((vestingInformationItem) => (
              <OrderVestingInformationRow
                key={vestingInformationItem.id}
                vestingInformation={vestingInformationItem}
              />
            ))}
          </PrimaryTBody>
        </PrimaryTable>
      ) : (
        <span>No Vesting Information</span>
      )}
    </SecondarySectionContent>
  </SecondarySection>
);

const AttomIdTd = styled(PrimaryTd)({
  width: "75px",
});

const DocumentLinkTd = styled(SecondaryTd)({
  padding: "0px",
  width: "50px",
});

const StatusTd = styled(PrimaryTd)({
  width: "50px",
});

const CreatedAtTd = styled(PrimaryTd)({
  width: "100px",
});

const CaretTd = styled(PrimaryTd)({
  width: "20px",
});

type OrderVestingInformationRowProps = {
  vestingInformation: OrderVestingInformation;
};

const OrderVestingInformationRow: React.FC<OrderVestingInformationRowProps> = ({
  vestingInformation,
  vestingInformation: {createdAt, attomId, status, sourceDocuments},
}) => {
  const [displayDetails, setDisplayDetails] = useState(false);
  const toggleDetailsMenu = useCallback(() => {
    setDisplayDetails((previousDisplayDetails) => !previousDisplayDetails);
  }, []);
  const primarySectionGap = usePrimarySectionGap();

  return (
    <>
      <PrimaryBodyRow>
        <AttomIdTd>{attomId}</AttomIdTd>
        <StatusTd>
          <OrderPropertyDocumentExtractionStatusPill status={status} />
        </StatusTd>
        <CreatedAtTd>
          <DateTimeComponent value={createdAt} />
        </CreatedAtTd>
        <CaretTd>
          <CaretIcon
            fontSize="xs"
            onClick={toggleDetailsMenu}
            direction={
              displayDetails ? CaretDirection.DOWN : CaretDirection.RIGHT
            }
          />
        </CaretTd>
      </PrimaryBodyRow>
      {displayDetails ? (
        <PrimaryBodyRow>
          <PrimaryTd colSpan={4}>
            <Flex direction="column" gap={primarySectionGap}>
              <SecondarySection>
                <DataGrid>
                  {isOrderVestingInformationSuccess(vestingInformation) && (
                    <GridItem>
                      <GridItemLabelContainer>
                        <GridItemLabel value="Vested Owner(s)" />
                      </GridItemLabelContainer>
                      <Text value={vestingInformation.vestingInformation} />
                    </GridItem>
                  )}
                  {isOrderVestingInformationNotAvailable(
                    vestingInformation,
                  ) && (
                    <GridItem>
                      <GridItemLabelContainer>
                        <GridItemLabel value="Not Available Reason" />
                      </GridItemLabelContainer>
                      <OrderVestingInformationNotAvailableReasonComponent
                        notAvailableReason={
                          vestingInformation.notAvailableReason
                        }
                      />
                    </GridItem>
                  )}
                </DataGrid>
              </SecondarySection>
              <SecondarySection>
                <DataGrid>
                  <GridItem>
                    <GridItemLabelContainer>
                      <GridItemLabel value="Source Documents" />
                    </GridItemLabelContainer>
                    <ArrayMaybeEmpty
                      value={sourceDocuments}
                      fallback={<Text value="No Source Documents" />}
                    >
                      {(sourceDocuments_) => (
                        <SecondaryTable>
                          <SecondaryTBody>
                            {sourceDocuments_.map((sourceDocument) => (
                              <SecondaryBodyRow
                                key={
                                  sourceDocument.sourceOrderPropertyDocumentId
                                }
                              >
                                <DocumentLinkTd>
                                  <MaybeNotAvailable
                                    value={
                                      sourceDocument.sourcePropertyDocumentId
                                    }
                                  >
                                    {(sourcePropertyDocumentId) => (
                                      <RouterLink
                                        to={`/documents/${sourcePropertyDocumentId}/`}
                                      >
                                        Document
                                      </RouterLink>
                                    )}
                                  </MaybeNotAvailable>
                                </DocumentLinkTd>
                              </SecondaryBodyRow>
                            ))}
                          </SecondaryTBody>
                        </SecondaryTable>
                      )}
                    </ArrayMaybeEmpty>
                  </GridItem>
                </DataGrid>
              </SecondarySection>
            </Flex>
          </PrimaryTd>
        </PrimaryBodyRow>
      ) : null}
    </>
  );
};
