/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValidationReportDetail } from './ValidationReportDetail';
import {
    ValidationReportDetailFromJSON,
    ValidationReportDetailFromJSONTyped,
    ValidationReportDetailToJSON,
} from './ValidationReportDetail';
import type { ValidationReportDetailComparison } from './ValidationReportDetailComparison';
import {
    ValidationReportDetailComparisonFromJSON,
    ValidationReportDetailComparisonFromJSONTyped,
    ValidationReportDetailComparisonToJSON,
} from './ValidationReportDetailComparison';
import type { ValidationReportDetailData } from './ValidationReportDetailData';
import {
    ValidationReportDetailDataFromJSON,
    ValidationReportDetailDataFromJSONTyped,
    ValidationReportDetailDataToJSON,
} from './ValidationReportDetailData';
import type { ValidationReportDetailUpdateBaseline } from './ValidationReportDetailUpdateBaseline';
import {
    ValidationReportDetailUpdateBaselineFromJSON,
    ValidationReportDetailUpdateBaselineFromJSONTyped,
    ValidationReportDetailUpdateBaselineToJSON,
} from './ValidationReportDetailUpdateBaseline';

/**
 * 
 * @export
 * @interface ValidationReportDetailUpdate
 */
export interface ValidationReportDetailUpdate extends ValidationReportDetail {
    /**
     * 
     * @type {ValidationReportDetailUpdateBaseline}
     * @memberof ValidationReportDetailUpdate
     */
    baseline: ValidationReportDetailUpdateBaseline;
    /**
     * 
     * @type {ValidationReportDetailComparison}
     * @memberof ValidationReportDetailUpdate
     */
    comparison: ValidationReportDetailComparison;
    /**
     * 
     * @type {ValidationReportDetailData}
     * @memberof ValidationReportDetailUpdate
     */
    report: ValidationReportDetailData;
}

/**
 * Check if a given object implements the ValidationReportDetailUpdate interface.
 */
export function instanceOfValidationReportDetailUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "baseline" in value;
    isInstance = isInstance && "comparison" in value;
    isInstance = isInstance && "report" in value;

    return isInstance;
}

export function ValidationReportDetailUpdateFromJSON(json: any): ValidationReportDetailUpdate {
    return ValidationReportDetailUpdateFromJSONTyped(json, false);
}

export function ValidationReportDetailUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationReportDetailUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...ValidationReportDetailFromJSONTyped(json, ignoreDiscriminator),
        'baseline': ValidationReportDetailUpdateBaselineFromJSON(json['baseline']),
        'comparison': ValidationReportDetailComparisonFromJSON(json['comparison']),
        'report': ValidationReportDetailDataFromJSON(json['report']),
    };
}

export function ValidationReportDetailUpdateToJSON(value?: ValidationReportDetailUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...ValidationReportDetailToJSON(value),
        'baseline': ValidationReportDetailUpdateBaselineToJSON(value.baseline),
        'comparison': ValidationReportDetailComparisonToJSON(value.comparison),
        'report': ValidationReportDetailDataToJSON(value.report),
    };
}

