/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClaimResponsiblePartyType = {
    R1: 'R.1',
    R2: 'R.2',
    R3: 'R.3',
    R4: 'R.4',
    S1: 'S.1',
    S2: 'S.2',
    S3: 'S.3',
    S4: 'S.4',
    T1: 'T.1',
    T2: 'T.2',
    T3: 'T.3',
    T4: 'T.4',
    U: 'U'
} as const;
export type ClaimResponsiblePartyType = typeof ClaimResponsiblePartyType[keyof typeof ClaimResponsiblePartyType];


export function ClaimResponsiblePartyTypeFromJSON(json: any): ClaimResponsiblePartyType {
    return ClaimResponsiblePartyTypeFromJSONTyped(json, false);
}

export function ClaimResponsiblePartyTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaimResponsiblePartyType {
    return json as ClaimResponsiblePartyType;
}

export function ClaimResponsiblePartyTypeToJSON(value?: ClaimResponsiblePartyType | null): any {
    return value as any;
}

