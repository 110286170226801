/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateProducerAppointmentRequest
 */
export interface CreateProducerAppointmentRequest {
    /**
     * 
     * @type {Date}
     * @memberof CreateProducerAppointmentRequest
     */
    effectiveDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateProducerAppointmentRequest
     */
    expirationDate: Date;
}

/**
 * Check if a given object implements the CreateProducerAppointmentRequest interface.
 */
export function instanceOfCreateProducerAppointmentRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "effectiveDate" in value;
    isInstance = isInstance && "expirationDate" in value;

    return isInstance;
}

export function CreateProducerAppointmentRequestFromJSON(json: any): CreateProducerAppointmentRequest {
    return CreateProducerAppointmentRequestFromJSONTyped(json, false);
}

export function CreateProducerAppointmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProducerAppointmentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'effectiveDate': (new Date(json['effectiveDate'])),
        'expirationDate': (new Date(json['expirationDate'])),
    };
}

export function CreateProducerAppointmentRequestToJSON(value?: CreateProducerAppointmentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'effectiveDate': (value.effectiveDate.toISOString().substr(0,10)),
        'expirationDate': (value.expirationDate.toISOString().substr(0,10)),
    };
}

