/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetPropertyDocumentsSuccessResponseDocument } from './GetPropertyDocumentsSuccessResponseDocument';
import {
    GetPropertyDocumentsSuccessResponseDocumentFromJSON,
    GetPropertyDocumentsSuccessResponseDocumentFromJSONTyped,
    GetPropertyDocumentsSuccessResponseDocumentToJSON,
} from './GetPropertyDocumentsSuccessResponseDocument';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';

/**
 * 
 * @export
 * @interface GetPropertyDocumentsSuccessResponse
 */
export interface GetPropertyDocumentsSuccessResponse {
    /**
     * 
     * @type {Array<GetPropertyDocumentsSuccessResponseDocument>}
     * @memberof GetPropertyDocumentsSuccessResponse
     */
    documents: Array<GetPropertyDocumentsSuccessResponseDocument>;
    /**
     * 
     * @type {Pagination}
     * @memberof GetPropertyDocumentsSuccessResponse
     */
    pagination: Pagination;
    /**
     * 
     * @type {Set<string>}
     * @memberof GetPropertyDocumentsSuccessResponse
     */
    stateFilterOptions: Set<string>;
}

/**
 * Check if a given object implements the GetPropertyDocumentsSuccessResponse interface.
 */
export function instanceOfGetPropertyDocumentsSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "documents" in value;
    isInstance = isInstance && "pagination" in value;
    isInstance = isInstance && "stateFilterOptions" in value;

    return isInstance;
}

export function GetPropertyDocumentsSuccessResponseFromJSON(json: any): GetPropertyDocumentsSuccessResponse {
    return GetPropertyDocumentsSuccessResponseFromJSONTyped(json, false);
}

export function GetPropertyDocumentsSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPropertyDocumentsSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documents': ((json['documents'] as Array<any>).map(GetPropertyDocumentsSuccessResponseDocumentFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
        'stateFilterOptions': json['stateFilterOptions'],
    };
}

export function GetPropertyDocumentsSuccessResponseToJSON(value?: GetPropertyDocumentsSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documents': ((value.documents as Array<any>).map(GetPropertyDocumentsSuccessResponseDocumentToJSON)),
        'pagination': PaginationToJSON(value.pagination),
        'stateFilterOptions': Array.from(value.stateFilterOptions as Set<any>),
    };
}

