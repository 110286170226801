/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgencyProducerReference } from './AgencyProducerReference';
import {
    AgencyProducerReferenceFromJSON,
    AgencyProducerReferenceFromJSONTyped,
    AgencyProducerReferenceToJSON,
} from './AgencyProducerReference';
import type { AuditStatus } from './AuditStatus';
import {
    AuditStatusFromJSON,
    AuditStatusFromJSONTyped,
    AuditStatusToJSON,
} from './AuditStatus';

/**
 * 
 * @export
 * @interface GetAuditSuccessResponse
 */
export interface GetAuditSuccessResponse {
    /**
     * 
     * @type {AgencyProducerReference}
     * @memberof GetAuditSuccessResponse
     */
    agency: AgencyProducerReference;
    /**
     * 
     * @type {string}
     * @memberof GetAuditSuccessResponse
     */
    agencyReferenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditSuccessResponse
     */
    auditScope?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetAuditSuccessResponse
     */
    completedOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetAuditSuccessResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof GetAuditSuccessResponse
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetAuditSuccessResponse
     */
    dueOn: Date;
    /**
     * 
     * @type {string}
     * @memberof GetAuditSuccessResponse
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof GetAuditSuccessResponse
     */
    startingLetterSentOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetAuditSuccessResponse
     */
    state?: string;
    /**
     * 
     * @type {AuditStatus}
     * @memberof GetAuditSuccessResponse
     */
    status: AuditStatus;
    /**
     * 
     * @type {Date}
     * @memberof GetAuditSuccessResponse
     */
    targetCompleteOn: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetAuditSuccessResponse
     */
    targetStartOn: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetAuditSuccessResponse
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the GetAuditSuccessResponse interface.
 */
export function instanceOfGetAuditSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "agency" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "dueOn" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "targetCompleteOn" in value;
    isInstance = isInstance && "targetStartOn" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function GetAuditSuccessResponseFromJSON(json: any): GetAuditSuccessResponse {
    return GetAuditSuccessResponseFromJSONTyped(json, false);
}

export function GetAuditSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAuditSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agency': AgencyProducerReferenceFromJSON(json['agency']),
        'agencyReferenceId': !exists(json, 'agencyReferenceId') ? undefined : json['agencyReferenceId'],
        'auditScope': !exists(json, 'auditScope') ? undefined : json['auditScope'],
        'completedOn': !exists(json, 'completedOn') ? undefined : (new Date(json['completedOn'])),
        'createdAt': (new Date(json['createdAt'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'dueOn': (new Date(json['dueOn'])),
        'id': json['id'],
        'startingLetterSentOn': !exists(json, 'startingLetterSentOn') ? undefined : (new Date(json['startingLetterSentOn'])),
        'state': !exists(json, 'state') ? undefined : json['state'],
        'status': AuditStatusFromJSON(json['status']),
        'targetCompleteOn': (new Date(json['targetCompleteOn'])),
        'targetStartOn': (new Date(json['targetStartOn'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function GetAuditSuccessResponseToJSON(value?: GetAuditSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agency': AgencyProducerReferenceToJSON(value.agency),
        'agencyReferenceId': value.agencyReferenceId,
        'auditScope': value.auditScope,
        'completedOn': value.completedOn === undefined ? undefined : (value.completedOn.toISOString().substr(0,10)),
        'createdAt': (value.createdAt.toISOString()),
        'description': value.description,
        'dueOn': (value.dueOn.toISOString().substr(0,10)),
        'id': value.id,
        'startingLetterSentOn': value.startingLetterSentOn === undefined ? undefined : (value.startingLetterSentOn.toISOString().substr(0,10)),
        'state': value.state,
        'status': AuditStatusToJSON(value.status),
        'targetCompleteOn': (value.targetCompleteOn.toISOString().substr(0,10)),
        'targetStartOn': (value.targetStartOn.toISOString().substr(0,10)),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

