import {TabItem, Tabs} from "@aws-amplify/ui-react";
import {QueryFunction, useQuery} from "@tanstack/react-query";
import {Query} from "@title-service/react-query-utils";
import {
  DateComponent,
  PercentComponent,
  PrimarySection,
  Text,
} from "@title-service/ui";
import {stringIsNotBlank} from "@title-service/utils";
import React, {useCallback, useContext, useMemo} from "react";
import {useParams} from "react-router-dom";

import {DependencyContext} from "../DependencyContext";
import {
  AttomAddress,
  GetPredictionRequest,
  GetPredictionSuccessResponse,
  GetPropertySuccessResponse,
} from "../shared/adminApi";
import {ColumnWrapper} from "../shared/components/ColumnWrapper";
import {CurrencyComponent} from "../shared/components/Currency";
import {
  DataGrid,
  GridItem,
  GridItemLabel,
  GridItemLabelContainer,
} from "../shared/components/DataGrid";
import {MaybeDateAndAmount} from "../shared/components/MaybeDateAndAmount";
import {
  MaybeNotAvailable,
  StringMaybeBlankComponent,
} from "../shared/components/Nullable";
import {VerticalStringList} from "../shared/components/VerticalStringList";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../shared/layout";

type PropertyDetailPageParams = {
  attomId: string;
};

export const PropertyDetailRoute: React.FC = () => {
  const {attomId} = useParams<
    keyof PropertyDetailPageParams
  >() as PropertyDetailPageParams;
  return <PropertyDetailQuery attomId={attomId} />;
};

type GetPropertyQueryKey = ["properties", string];
type GetPropertyQuery = Query<GetPropertySuccessResponse>;

const useGetProperty = (attomId: string): GetPropertyQuery => {
  const {adminApi} = useContext(DependencyContext);

  const getPropertyQueryKey: GetPropertyQueryKey = useMemo(
    () => ["properties", attomId],
    [attomId],
  );
  const getPropertyQueryFn: QueryFunction<
    GetPropertySuccessResponse,
    GetPropertyQueryKey
  > = useCallback(
    ({queryKey: [_, attomId_], signal}) =>
      adminApi.getProperty({attomId: attomId_}, signal),
    [adminApi],
  );
  return useQuery({
    queryKey: getPropertyQueryKey,
    queryFn: getPropertyQueryFn,
  });
};

export const PropertyDetailQuery: React.FC<
  PropertyDetailPageParams & {
    PropertyDetailQueryResult?: typeof PropertyDetailQueryResult;
  }
> = ({
  attomId,
  PropertyDetailQueryResult:
    PropertyDetailQueryResult_ = PropertyDetailQueryResult,
}) => {
  const getPropertyQuery = useGetProperty(attomId);
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <PropertyDetailQueryResult_
      attomId={attomId}
      getPropertyQuery={getPropertyQuery}
    />
  );
};

export const PropertyDetailQueryResult: React.FC<{
  attomId: string;
  getPropertyQuery: GetPropertyQuery;
  PropertyDetailQuerySuccessComponent?: typeof PropertyDetailQuerySuccessComponent;
  PropertyDetailQueryFailureComponent?: typeof PropertyDetailQueryFailureComponent;
  PropertyDetailQueryLoadingComponent?: typeof PropertyDetailQueryLoadingComponent;
}> = ({
  attomId,
  getPropertyQuery: {data, error},
  PropertyDetailQuerySuccessComponent:
    PropertyDetailQuerySuccessComponent_ = PropertyDetailQuerySuccessComponent,
  PropertyDetailQueryFailureComponent:
    PropertyDetailQueryFailureComponent_ = PropertyDetailQueryFailureComponent,
  PropertyDetailQueryLoadingComponent:
    PropertyDetailQueryLoadingComponent_ = PropertyDetailQueryLoadingComponent,
}) => {
  if (data) {
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <PropertyDetailQuerySuccessComponent_ attomId={attomId} response={data} />
    );
  } else if (error) {
    // eslint-disable-next-line react/jsx-pascal-case
    return <PropertyDetailQueryFailureComponent_ id={attomId} />;
  }
  // eslint-disable-next-line react/jsx-pascal-case
  return <PropertyDetailQueryLoadingComponent_ id={attomId} />;
};

export const PropertyDetailQueryLoadingComponent: React.FC<{id: string}> = ({
  id,
}) => (
  <BodyContent>
    <span>Loading ATTOM Property {id}...</span>
  </BodyContent>
);
export const PropertyDetailQueryFailureComponent: React.FC<{id: string}> = ({
  id,
}) => (
  <BodyContent>
    <span>Failed to load ATTOM Property {id}.</span>
  </BodyContent>
);

export const PropertyDetailQuerySuccessComponent: React.FC<{
  attomId: string;
  response: GetPropertySuccessResponse;
}> = ({
  attomId,
  response,
  response: {
    deedOwners,
    latestFullValueTransfer,
    latestSale,
    legalDescription,
    ownerTypeDescriptions,
    partyOwners,
    propertyAddress,
  },
}) => (
  <>
    <BodyHeader>
      <PrimaryBodyHeaderContainer>
        <PrimaryBodyHeader
          value={
            propertyAddress
              ? getSingleLineAttomAddress(propertyAddress)
              : `ATTOM Property ${attomId}`
          }
        />
      </PrimaryBodyHeaderContainer>
      <DataGrid>
        <GridItem>
          <GridItemLabelContainer>
            <GridItemLabel value="Party owner(s)" />
          </GridItemLabelContainer>
          <VerticalStringList values={partyOwners} />
        </GridItem>
        <GridItem>
          <GridItemLabelContainer>
            <GridItemLabel value="Deed owner(s)" />
          </GridItemLabelContainer>
          <VerticalStringList values={deedOwners} />
        </GridItem>
        <GridItem>
          <GridItemLabelContainer>
            <GridItemLabel value="Owner type" />
          </GridItemLabelContainer>
          <VerticalStringList values={ownerTypeDescriptions} />
        </GridItem>
        <GridItem>
          <GridItemLabelContainer>
            <GridItemLabel value="Last FVT" />
          </GridItemLabelContainer>
          <MaybeNotAvailable value={latestFullValueTransfer}>
            {(fvt) => (
              <MaybeDateAndAmount date={fvt.date} amount={fvt.amount} />
            )}
          </MaybeNotAvailable>
        </GridItem>
        <GridItem>
          <GridItemLabelContainer>
            <GridItemLabel value="Last sale" />
          </GridItemLabelContainer>
          <MaybeNotAvailable value={latestSale}>
            {(sale) => (
              <MaybeDateAndAmount date={sale.date} amount={sale.amount} />
            )}
          </MaybeNotAvailable>
        </GridItem>
        <GridItem>
          <GridItemLabelContainer>
            <GridItemLabel value="Legal description" />
          </GridItemLabelContainer>
          <StringMaybeBlankComponent value={legalDescription} />
        </GridItem>
      </DataGrid>
    </BodyHeader>
    <BodyContent>
      <Tabs>
        <TabItem title="Property info">
          <PropertyInfoTabContent attomId={attomId} response={response} />
        </TabItem>
        <TabItem title="Tax info">
          <TaxInfoTabContent response={response} />
        </TabItem>
      </Tabs>
    </BodyContent>
  </>
);

const PropertyInfoTabContent: React.FC<{
  attomId: string;
  response: GetPropertySuccessResponse;
}> = ({
  attomId,
  response: {titleCompany, propertyAddress, latestForeclosure, mortgages},
}) => (
  <PrimarySection>
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Details" />
      </SecondarySectionHeaderContainer>
      <SecondarySectionContent>
        <DataGrid>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Title Company" />
            </GridItemLabelContainer>
            <StringMaybeBlankComponent value={titleCompany} />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Score" />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={propertyAddress?.state}>
              {(state) => <PredictionQuery attomId={attomId} state={state} />}
            </MaybeNotAvailable>
          </GridItem>
        </DataGrid>
      </SecondarySectionContent>
    </SecondarySection>
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Foreclosure" />
      </SecondarySectionHeaderContainer>
      <SecondarySectionContent>
        <DataGrid>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Liens date" />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={latestForeclosure?.date}>
              {(latestForeclosureDate) => (
                <DateComponent value={latestForeclosureDate} />
              )}
            </MaybeNotAvailable>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Liens amt." />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={latestForeclosure?.amount}>
              {(latestForeclosureAmount) => (
                <CurrencyComponent value={latestForeclosureAmount} />
              )}
            </MaybeNotAvailable>
          </GridItem>
        </DataGrid>
      </SecondarySectionContent>
    </SecondarySection>
    {mortgages.map((mortgage, i) => (
      <React.Fragment key={i}>
        <SecondarySection>
          <SecondarySectionHeaderContainer>
            <SecondarySectionHeader value={`Mortgage ${i + 1}`} />
          </SecondarySectionHeaderContainer>
          <SecondarySectionContent>
            <DataGrid>
              <GridItem>
                <GridItemLabelContainer>
                  <GridItemLabel value="Instrument no." />
                </GridItemLabelContainer>
                <StringMaybeBlankComponent value={mortgage.instrumentNumber} />
              </GridItem>
              <GridItem>
                <GridItemLabelContainer>
                  <GridItemLabel value="Term" />
                </GridItemLabelContainer>
                <MaybeNotAvailable value={mortgage.termLength}>
                  {(mortgageTermLength) => <Text value={mortgageTermLength} />}
                </MaybeNotAvailable>
              </GridItem>
              <GridItem>
                <GridItemLabelContainer>
                  <GridItemLabel value="Term type" />
                </GridItemLabelContainer>
                <StringMaybeBlankComponent value={mortgage.termType} />
              </GridItem>
              <GridItem>
                <GridItemLabelContainer>
                  <GridItemLabel value="Term date" />
                </GridItemLabelContainer>
                <MaybeNotAvailable value={mortgage.termDate}>
                  {(mortgageTermDate) => (
                    <DateComponent value={mortgageTermDate} />
                  )}
                </MaybeNotAvailable>
              </GridItem>
              <GridItem>
                <GridItemLabelContainer>
                  <GridItemLabel value="Lender name" />
                </GridItemLabelContainer>
                <StringMaybeBlankComponent value={mortgage.lenderName} />
              </GridItem>
            </DataGrid>
          </SecondarySectionContent>
        </SecondarySection>
      </React.Fragment>
    ))}
  </PrimarySection>
);

const TaxInfoTabContent: React.FC<{
  response: GetPropertySuccessResponse;
}> = ({
  response: {
    cbsaName,
    latestAssessment,
    latestSale,
    legalTownship,
    msaName,
    ownerMailingAddress,
  },
}) => (
  <PrimarySection>
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Tax owner details" />
      </SecondarySectionHeaderContainer>
      <SecondarySectionContent>
        <DataGrid>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Owner mailing address" />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={ownerMailingAddress}>
              {(address) => <AttomAddressComponent address={address} />}
            </MaybeNotAvailable>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="CBSA Name" />
            </GridItemLabelContainer>
            <StringMaybeBlankComponent value={cbsaName} />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="MSA Name" />
            </GridItemLabelContainer>
            <StringMaybeBlankComponent value={msaName} />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Legal township" />
            </GridItemLabelContainer>
            <StringMaybeBlankComponent value={legalTownship} />
          </GridItem>
        </DataGrid>
      </SecondarySectionContent>
    </SecondarySection>
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Tax assessment" />
      </SecondarySectionHeaderContainer>
      <SecondarySectionContent>
        <DataGrid>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Assessed land value" />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={latestAssessment?.assessedValueLand}>
              {(latestAssessedValueLand) => (
                <CurrencyComponent value={latestAssessedValueLand} />
              )}
            </MaybeNotAvailable>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Assessed value improvements" />
            </GridItemLabelContainer>
            <MaybeNotAvailable
              value={latestAssessment?.assessedValueImprovements}
            >
              {(latestAssessedValueImprovements) => (
                <CurrencyComponent value={latestAssessedValueImprovements} />
              )}
            </MaybeNotAvailable>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Assessed value total" />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={latestAssessment?.assessedValueTotal}>
              {(latestAssessedValueTotal) => (
                <CurrencyComponent value={latestAssessedValueTotal} />
              )}
            </MaybeNotAvailable>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Assessor last sale amt." />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={latestSale?.amount}>
              {(latestSaleAmount) => (
                <CurrencyComponent value={latestSaleAmount} />
              )}
            </MaybeNotAvailable>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Assessor last sale date" />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={latestSale?.date}>
              {(latestSaleDate) => <DateComponent value={latestSaleDate} />}
            </MaybeNotAvailable>
          </GridItem>
        </DataGrid>
      </SecondarySectionContent>
    </SecondarySection>
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Tax market" />
      </SecondarySectionHeaderContainer>
      <SecondarySectionContent>
        <DataGrid>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Market value land" />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={latestAssessment?.marketValueLand}>
              {(latestAssessedMarketValueLand) => (
                <CurrencyComponent value={latestAssessedMarketValueLand} />
              )}
            </MaybeNotAvailable>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Market value improvements" />
            </GridItemLabelContainer>
            <MaybeNotAvailable
              value={latestAssessment?.marketValueImprovements}
            >
              {(latestAssessedMarketValueImprovements) => (
                <CurrencyComponent
                  value={latestAssessedMarketValueImprovements}
                />
              )}
            </MaybeNotAvailable>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Market value total" />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={latestAssessment?.marketValueTotal}>
              {(latestAssessedMarketValueTotal) => (
                <CurrencyComponent value={latestAssessedMarketValueTotal} />
              )}
            </MaybeNotAvailable>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Tax billed amt." />
            </GridItemLabelContainer>
            <MaybeNotAvailable value={latestAssessment?.taxBilledAmount}>
              {(latestTaxBilledAmount) => (
                <CurrencyComponent value={latestTaxBilledAmount} />
              )}
            </MaybeNotAvailable>
          </GridItem>
        </DataGrid>
      </SecondarySectionContent>
    </SecondarySection>
  </PrimarySection>
);

const getSingleLineAttomAddress = ({
  fullStreetAddress,
  city,
  county,
  state,
  zipCode,
}: AttomAddress): string => {
  const stateAndZip = [state, zipCode].filter(stringIsNotBlank).join(" ");
  return [fullStreetAddress, city, county, stateAndZip]
    .filter(stringIsNotBlank)
    .join(", ");
};

const AttomAddressComponent: React.FC<{address: AttomAddress}> = ({
  address: {fullStreetAddress, city, county, state, zipCode},
}) => {
  const secondLinePart1 = [city, county, state]
    .filter(stringIsNotBlank)
    .join(", ");
  const secondLine = [secondLinePart1, zipCode]
    .filter(stringIsNotBlank)
    .join(" ");
  return (
    <ColumnWrapper>
      {stringIsNotBlank(fullStreetAddress) && <div>{fullStreetAddress}</div>}
      <div>{secondLine}</div>
    </ColumnWrapper>
  );
};

type GetPredictionQueryKey = ["properties", GetPredictionRequest, "prediction"];
type GetPredictionQuery = Query<GetPredictionSuccessResponse>;

const useGetPrediction = (
  request: GetPredictionRequest,
): GetPredictionQuery => {
  const {adminApi} = useContext(DependencyContext);

  const getPredictionQueryKey: GetPredictionQueryKey = useMemo(
    () => ["properties", request, "prediction"],
    [request],
  );
  const getPredictionQueryFn: QueryFunction<
    GetPredictionSuccessResponse,
    GetPredictionQueryKey
  > = useCallback(
    ({queryKey: [_, request_], signal}) =>
      adminApi.getPrediction(request_, signal),
    [adminApi],
  );
  return useQuery({
    queryKey: getPredictionQueryKey,
    queryFn: getPredictionQueryFn,
  });
};

export const PredictionQuery: React.FC<{
  attomId: string;
  state: string;
  PredictionQueryResult?: typeof PredictionQueryResult;
}> = ({
  attomId,
  state,
  PredictionQueryResult: PredictionQueryResult_ = PredictionQueryResult,
}) => {
  const getPredictionQuery = useGetPrediction({attomId, state});
  // eslint-disable-next-line react/jsx-pascal-case
  return <PredictionQueryResult_ getPredictionQuery={getPredictionQuery} />;
};

export const PredictionQueryResult: React.FC<{
  getPredictionQuery: GetPredictionQuery;
  PredictionQuerySuccessComponent?: typeof PredictionQuerySuccessComponent;
  PredictionQueryFailureComponent?: typeof PredictionQueryFailureComponent;
  PredictionQueryLoadingComponent?: typeof PredictionQueryLoadingComponent;
}> = ({
  getPredictionQuery: {data, error},
  PredictionQuerySuccessComponent:
    PredictionQuerySuccessComponent_ = PredictionQuerySuccessComponent,
  PredictionQueryFailureComponent:
    PredictionQueryFailureComponent_ = PredictionQueryFailureComponent,
  PredictionQueryLoadingComponent:
    PredictionQueryLoadingComponent_ = PredictionQueryLoadingComponent,
}) => {
  if (data) {
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <PredictionQuerySuccessComponent_ response={data} />
    );
  } else if (error) {
    // eslint-disable-next-line react/jsx-pascal-case
    return <PredictionQueryFailureComponent_ />;
  }
  // eslint-disable-next-line react/jsx-pascal-case
  return <PredictionQueryLoadingComponent_ />;
};

export const PredictionQueryLoadingComponent: React.FC = () => (
  <>Loading Score...</>
);
export const PredictionQueryFailureComponent: React.FC = () => (
  <>Failed to load Score.</>
);

export const PredictionQuerySuccessComponent: React.FC<{
  response: GetPredictionSuccessResponse;
}> = ({response: {score}}) => <PercentComponent value={score} />;
