import {Pagination} from "@aws-amplify/ui-react";
import React, {useCallback} from "react";

export const PageNumbers: React.FC<{
  totalPages: number;
  zeroIndexedCurrentPageNumber: number;
  onChange: (zeroIndexedPageNumber: number) => any;
  isDisabled?: boolean;
  Pagination?: typeof Pagination;
}> = ({
  onChange,
  totalPages,
  zeroIndexedCurrentPageNumber,
  isDisabled,
  Pagination: Pagination_ = Pagination,
}) => {
  const handleNextPage = useCallback(() => {
    onChange(zeroIndexedCurrentPageNumber + 1);
  }, [onChange, zeroIndexedCurrentPageNumber]);

  const handlePreviousPage = useCallback(() => {
    onChange(zeroIndexedCurrentPageNumber - 1);
  }, [onChange, zeroIndexedCurrentPageNumber]);

  const setPage = useCallback(
    (oneIndexedPageIndex) => {
      onChange(oneIndexedPageIndex - 1);
    },
    [onChange],
  );

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <Pagination_
      isDisabled={isDisabled}
      currentPage={zeroIndexedCurrentPageNumber + 1}
      totalPages={totalPages}
      onNext={handleNextPage}
      onPrevious={handlePreviousPage}
      onChange={setPage}
    />
  );
};
