/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderScore
 */
export interface OrderScore {
    /**
     * 
     * @type {string}
     * @memberof OrderScore
     */
    attomId: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderScore
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderScore
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrderScore
     */
    modelVersion: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderScore
     */
    requestedByIndividualProducer: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderScore
     */
    score: number;
    /**
     * 
     * @type {string}
     * @memberof OrderScore
     */
    state: string;
}

/**
 * Check if a given object implements the OrderScore interface.
 */
export function instanceOfOrderScore(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "attomId" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "modelVersion" in value;
    isInstance = isInstance && "requestedByIndividualProducer" in value;
    isInstance = isInstance && "score" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function OrderScoreFromJSON(json: any): OrderScore {
    return OrderScoreFromJSONTyped(json, false);
}

export function OrderScoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderScore {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attomId': json['attomId'],
        'createdAt': (new Date(json['createdAt'])),
        'id': json['id'],
        'modelVersion': json['modelVersion'],
        'requestedByIndividualProducer': json['requestedByIndividualProducer'],
        'score': json['score'],
        'state': json['state'],
    };
}

export function OrderScoreToJSON(value?: OrderScore | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attomId': value.attomId,
        'createdAt': (value.createdAt.toISOString()),
        'id': value.id,
        'modelVersion': value.modelVersion,
        'requestedByIndividualProducer': value.requestedByIndividualProducer,
        'score': value.score,
        'state': value.state,
    };
}

