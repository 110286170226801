/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetClaimReservesSuccessResponseReserve } from './GetClaimReservesSuccessResponseReserve';
import {
    GetClaimReservesSuccessResponseReserveFromJSON,
    GetClaimReservesSuccessResponseReserveFromJSONTyped,
    GetClaimReservesSuccessResponseReserveToJSON,
} from './GetClaimReservesSuccessResponseReserve';

/**
 * 
 * @export
 * @interface GetClaimReservesSuccessResponse
 */
export interface GetClaimReservesSuccessResponse {
    /**
     * 
     * @type {Array<GetClaimReservesSuccessResponseReserve>}
     * @memberof GetClaimReservesSuccessResponse
     */
    reserves: Array<GetClaimReservesSuccessResponseReserve>;
}

/**
 * Check if a given object implements the GetClaimReservesSuccessResponse interface.
 */
export function instanceOfGetClaimReservesSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reserves" in value;

    return isInstance;
}

export function GetClaimReservesSuccessResponseFromJSON(json: any): GetClaimReservesSuccessResponse {
    return GetClaimReservesSuccessResponseFromJSONTyped(json, false);
}

export function GetClaimReservesSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetClaimReservesSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reserves': ((json['reserves'] as Array<any>).map(GetClaimReservesSuccessResponseReserveFromJSON)),
    };
}

export function GetClaimReservesSuccessResponseToJSON(value?: GetClaimReservesSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reserves': ((value.reserves as Array<any>).map(GetClaimReservesSuccessResponseReserveToJSON)),
    };
}

