import {
  ClaimRequestAddressExistent,
  ClaimRequestAddressExistentToJSON,
  CREATE_CLAIM_REQUEST_ADDRESS_EXISTENT_TYPE,
} from "./ClaimRequestAddressExistent";
import {
  ClaimRequestAddressNew,
  ClaimRequestAddressNewToJSON,
  CREATE_CLAIM_REQUEST_ADDRESS_NEW_TYPE,
} from "./ClaimRequestAddressNew";

export type ClaimRequestAddress =
  | ClaimRequestAddressExistent
  | ClaimRequestAddressNew;

export const ClaimRequestAddressToJSON = (
  claimRequestAddress: ClaimRequestAddress,
): any => {
  switch (claimRequestAddress.type) {
    case CREATE_CLAIM_REQUEST_ADDRESS_EXISTENT_TYPE:
      return ClaimRequestAddressExistentToJSON(claimRequestAddress);
    case CREATE_CLAIM_REQUEST_ADDRESS_NEW_TYPE:
      return ClaimRequestAddressNewToJSON(claimRequestAddress);
  }
};
