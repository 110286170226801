/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Order } from './Order';
import {
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './Order';
import type { Policy } from './Policy';
import {
    PolicyFromJSON,
    PolicyFromJSONTyped,
    PolicyToJSON,
} from './Policy';

/**
 * 
 * @export
 * @interface GetPoliciesSuccessResponsePolicy
 */
export interface GetPoliciesSuccessResponsePolicy {
    /**
     * 
     * @type {Order}
     * @memberof GetPoliciesSuccessResponsePolicy
     */
    order: Order;
    /**
     * 
     * @type {Policy}
     * @memberof GetPoliciesSuccessResponsePolicy
     */
    policy: Policy;
}

/**
 * Check if a given object implements the GetPoliciesSuccessResponsePolicy interface.
 */
export function instanceOfGetPoliciesSuccessResponsePolicy(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "order" in value;
    isInstance = isInstance && "policy" in value;

    return isInstance;
}

export function GetPoliciesSuccessResponsePolicyFromJSON(json: any): GetPoliciesSuccessResponsePolicy {
    return GetPoliciesSuccessResponsePolicyFromJSONTyped(json, false);
}

export function GetPoliciesSuccessResponsePolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPoliciesSuccessResponsePolicy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'order': OrderFromJSON(json['order']),
        'policy': PolicyFromJSON(json['policy']),
    };
}

export function GetPoliciesSuccessResponsePolicyToJSON(value?: GetPoliciesSuccessResponsePolicy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'order': OrderToJSON(value.order),
        'policy': PolicyToJSON(value.policy),
    };
}

