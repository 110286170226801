/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InsurfulOrderDocument } from './InsurfulOrderDocument';
import {
    InsurfulOrderDocumentFromJSON,
    InsurfulOrderDocumentFromJSONTyped,
    InsurfulOrderDocumentToJSON,
} from './InsurfulOrderDocument';
import type { InsurfulOrderResult } from './InsurfulOrderResult';
import {
    InsurfulOrderResultFromJSON,
    InsurfulOrderResultFromJSONTyped,
    InsurfulOrderResultToJSON,
} from './InsurfulOrderResult';

/**
 * 
 * @export
 * @interface InsurfulOrderResultSuccessful
 */
export interface InsurfulOrderResultSuccessful extends InsurfulOrderResult {
    /**
     * 
     * @type {Array<InsurfulOrderDocument>}
     * @memberof InsurfulOrderResultSuccessful
     */
    documents: Array<InsurfulOrderDocument>;
    /**
     * 
     * @type {string}
     * @memberof InsurfulOrderResultSuccessful
     */
    rawResponseUrl: string;
}

/**
 * Check if a given object implements the InsurfulOrderResultSuccessful interface.
 */
export function instanceOfInsurfulOrderResultSuccessful(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "documents" in value;
    isInstance = isInstance && "rawResponseUrl" in value;

    return isInstance;
}

export function InsurfulOrderResultSuccessfulFromJSON(json: any): InsurfulOrderResultSuccessful {
    return InsurfulOrderResultSuccessfulFromJSONTyped(json, false);
}

export function InsurfulOrderResultSuccessfulFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsurfulOrderResultSuccessful {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...InsurfulOrderResultFromJSONTyped(json, ignoreDiscriminator),
        'documents': ((json['documents'] as Array<any>).map(InsurfulOrderDocumentFromJSON)),
        'rawResponseUrl': json['rawResponseUrl'],
    };
}

export function InsurfulOrderResultSuccessfulToJSON(value?: InsurfulOrderResultSuccessful | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...InsurfulOrderResultToJSON(value),
        'documents': ((value.documents as Array<any>).map(InsurfulOrderDocumentToJSON)),
        'rawResponseUrl': value.rawResponseUrl,
    };
}

