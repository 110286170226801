/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PropertyDocumentExtractionStatus = {
    NotStarted: 'NOT_STARTED',
    InProgress: 'IN_PROGRESS',
    Approved: 'APPROVED',
    Rejected: 'REJECTED'
} as const;
export type PropertyDocumentExtractionStatus = typeof PropertyDocumentExtractionStatus[keyof typeof PropertyDocumentExtractionStatus];


export function PropertyDocumentExtractionStatusFromJSON(json: any): PropertyDocumentExtractionStatus {
    return PropertyDocumentExtractionStatusFromJSONTyped(json, false);
}

export function PropertyDocumentExtractionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyDocumentExtractionStatus {
    return json as PropertyDocumentExtractionStatus;
}

export function PropertyDocumentExtractionStatusToJSON(value?: PropertyDocumentExtractionStatus | null): any {
    return value as any;
}

