/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetClaimTransactionsSuccessResponseTransaction } from './GetClaimTransactionsSuccessResponseTransaction';
import {
    GetClaimTransactionsSuccessResponseTransactionFromJSON,
    GetClaimTransactionsSuccessResponseTransactionFromJSONTyped,
    GetClaimTransactionsSuccessResponseTransactionToJSON,
} from './GetClaimTransactionsSuccessResponseTransaction';

/**
 * 
 * @export
 * @interface GetClaimTransactionsSuccessResponse
 */
export interface GetClaimTransactionsSuccessResponse {
    /**
     * 
     * @type {Array<GetClaimTransactionsSuccessResponseTransaction>}
     * @memberof GetClaimTransactionsSuccessResponse
     */
    transactions: Array<GetClaimTransactionsSuccessResponseTransaction>;
}

/**
 * Check if a given object implements the GetClaimTransactionsSuccessResponse interface.
 */
export function instanceOfGetClaimTransactionsSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "transactions" in value;

    return isInstance;
}

export function GetClaimTransactionsSuccessResponseFromJSON(json: any): GetClaimTransactionsSuccessResponse {
    return GetClaimTransactionsSuccessResponseFromJSONTyped(json, false);
}

export function GetClaimTransactionsSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetClaimTransactionsSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactions': ((json['transactions'] as Array<any>).map(GetClaimTransactionsSuccessResponseTransactionFromJSON)),
    };
}

export function GetClaimTransactionsSuccessResponseToJSON(value?: GetClaimTransactionsSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactions': ((value.transactions as Array<any>).map(GetClaimTransactionsSuccessResponseTransactionToJSON)),
    };
}

