import {DateComponent} from "@title-service/ui";
import React from "react";

import {ColumnWrapper} from "./ColumnWrapper";
import {CurrencyComponent} from "./Currency";
import {MaybeNotAvailable} from "./Nullable";

export const MaybeDateAndAmount: React.FC<{date?: Date; amount?: number}> = ({
  date,
  amount,
}) => (
  <ColumnWrapper>
    <MaybeNotAvailable value={date}>
      {(d) => <DateComponent value={d} />}
    </MaybeNotAvailable>
    <MaybeNotAvailable value={amount}>
      {(a) => <CurrencyComponent value={a} />}
    </MaybeNotAvailable>
  </ColumnWrapper>
);
