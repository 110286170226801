/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderPropertyDocumentIngestionStatus = {
    Started: 'started',
    Success: 'success',
    Failure: 'failure'
} as const;
export type OrderPropertyDocumentIngestionStatus = typeof OrderPropertyDocumentIngestionStatus[keyof typeof OrderPropertyDocumentIngestionStatus];


export function OrderPropertyDocumentIngestionStatusFromJSON(json: any): OrderPropertyDocumentIngestionStatus {
    return OrderPropertyDocumentIngestionStatusFromJSONTyped(json, false);
}

export function OrderPropertyDocumentIngestionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderPropertyDocumentIngestionStatus {
    return json as OrderPropertyDocumentIngestionStatus;
}

export function OrderPropertyDocumentIngestionStatusToJSON(value?: OrderPropertyDocumentIngestionStatus | null): any {
    return value as any;
}

