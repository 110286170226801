/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ValidationReportDetailAssessmentStatus = {
    Pass: 'pass',
    Fail: 'fail'
} as const;
export type ValidationReportDetailAssessmentStatus = typeof ValidationReportDetailAssessmentStatus[keyof typeof ValidationReportDetailAssessmentStatus];


export function ValidationReportDetailAssessmentStatusFromJSON(json: any): ValidationReportDetailAssessmentStatus {
    return ValidationReportDetailAssessmentStatusFromJSONTyped(json, false);
}

export function ValidationReportDetailAssessmentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationReportDetailAssessmentStatus {
    return json as ValidationReportDetailAssessmentStatus;
}

export function ValidationReportDetailAssessmentStatusToJSON(value?: ValidationReportDetailAssessmentStatus | null): any {
    return value as any;
}

