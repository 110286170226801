/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClaimCauseOfLossType } from './ClaimCauseOfLossType';
import {
    ClaimCauseOfLossTypeFromJSON,
    ClaimCauseOfLossTypeFromJSONTyped,
    ClaimCauseOfLossTypeToJSON,
} from './ClaimCauseOfLossType';
import type { ClaimRequestAddress } from './ClaimRequestAddress';
import {
    ClaimRequestAddressFromJSON,
    ClaimRequestAddressFromJSONTyped,
    ClaimRequestAddressToJSON,
} from './ClaimRequestAddress';
import type { ClaimResponsiblePartyType } from './ClaimResponsiblePartyType';
import {
    ClaimResponsiblePartyTypeFromJSON,
    ClaimResponsiblePartyTypeFromJSONTyped,
    ClaimResponsiblePartyTypeToJSON,
} from './ClaimResponsiblePartyType';
import type { PolicyType } from './PolicyType';
import {
    PolicyTypeFromJSON,
    PolicyTypeFromJSONTyped,
    PolicyTypeToJSON,
} from './PolicyType';
import type { TransactionType } from './TransactionType';
import {
    TransactionTypeFromJSON,
    TransactionTypeFromJSONTyped,
    TransactionTypeToJSON,
} from './TransactionType';

/**
 * 
 * @export
 * @interface UpdateClaimRequest
 */
export interface UpdateClaimRequest {
    /**
     * 
     * @type {ClaimRequestAddress}
     * @memberof UpdateClaimRequest
     */
    address: ClaimRequestAddress;
    /**
     * 
     * @type {string}
     * @memberof UpdateClaimRequest
     */
    agencyId: string;
    /**
     * 
     * @type {ClaimCauseOfLossType}
     * @memberof UpdateClaimRequest
     */
    causeOfLoss?: ClaimCauseOfLossType;
    /**
     * 
     * @type {string}
     * @memberof UpdateClaimRequest
     */
    contactEmail: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClaimRequest
     */
    contactPhone: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClaimRequest
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClaimRequest
     */
    insuredAssignedAttorneyEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClaimRequest
     */
    insuredAssignedAttorneyName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClaimRequest
     */
    insuredAssignedAttorneyPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClaimRequest
     */
    insuredChosenAttorneyEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClaimRequest
     */
    insuredChosenAttorneyName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClaimRequest
     */
    insuredChosenAttorneyPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClaimRequest
     */
    insuredName: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateClaimRequest
     */
    policyAmountOfInsurance?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateClaimRequest
     */
    policyId?: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateClaimRequest
     */
    policyIssuedOn?: Date;
    /**
     * 
     * @type {TransactionType}
     * @memberof UpdateClaimRequest
     */
    policyTransactionType?: TransactionType;
    /**
     * 
     * @type {PolicyType}
     * @memberof UpdateClaimRequest
     */
    policyType?: PolicyType;
    /**
     * 
     * @type {Date}
     * @memberof UpdateClaimRequest
     */
    reportedOn: Date;
    /**
     * 
     * @type {ClaimResponsiblePartyType}
     * @memberof UpdateClaimRequest
     */
    responsibleParty?: ClaimResponsiblePartyType;
    /**
     * 
     * @type {string}
     * @memberof UpdateClaimRequest
     */
    underwriterAttorneyEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClaimRequest
     */
    underwriterAttorneyName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClaimRequest
     */
    underwriterAttorneyPhone?: string;
}

/**
 * Check if a given object implements the UpdateClaimRequest interface.
 */
export function instanceOfUpdateClaimRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "agencyId" in value;
    isInstance = isInstance && "contactEmail" in value;
    isInstance = isInstance && "contactPhone" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "insuredName" in value;
    isInstance = isInstance && "reportedOn" in value;

    return isInstance;
}

export function UpdateClaimRequestFromJSON(json: any): UpdateClaimRequest {
    return UpdateClaimRequestFromJSONTyped(json, false);
}

export function UpdateClaimRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateClaimRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': ClaimRequestAddressFromJSON(json['address']),
        'agencyId': json['agencyId'],
        'causeOfLoss': !exists(json, 'causeOfLoss') ? undefined : ClaimCauseOfLossTypeFromJSON(json['causeOfLoss']),
        'contactEmail': json['contactEmail'],
        'contactPhone': json['contactPhone'],
        'description': json['description'],
        'insuredAssignedAttorneyEmail': !exists(json, 'insuredAssignedAttorneyEmail') ? undefined : json['insuredAssignedAttorneyEmail'],
        'insuredAssignedAttorneyName': !exists(json, 'insuredAssignedAttorneyName') ? undefined : json['insuredAssignedAttorneyName'],
        'insuredAssignedAttorneyPhone': !exists(json, 'insuredAssignedAttorneyPhone') ? undefined : json['insuredAssignedAttorneyPhone'],
        'insuredChosenAttorneyEmail': !exists(json, 'insuredChosenAttorneyEmail') ? undefined : json['insuredChosenAttorneyEmail'],
        'insuredChosenAttorneyName': !exists(json, 'insuredChosenAttorneyName') ? undefined : json['insuredChosenAttorneyName'],
        'insuredChosenAttorneyPhone': !exists(json, 'insuredChosenAttorneyPhone') ? undefined : json['insuredChosenAttorneyPhone'],
        'insuredName': json['insuredName'],
        'policyAmountOfInsurance': !exists(json, 'policyAmountOfInsurance') ? undefined : json['policyAmountOfInsurance'],
        'policyId': !exists(json, 'policyId') ? undefined : json['policyId'],
        'policyIssuedOn': !exists(json, 'policyIssuedOn') ? undefined : (new Date(json['policyIssuedOn'])),
        'policyTransactionType': !exists(json, 'policyTransactionType') ? undefined : TransactionTypeFromJSON(json['policyTransactionType']),
        'policyType': !exists(json, 'policyType') ? undefined : PolicyTypeFromJSON(json['policyType']),
        'reportedOn': (new Date(json['reportedOn'])),
        'responsibleParty': !exists(json, 'responsibleParty') ? undefined : ClaimResponsiblePartyTypeFromJSON(json['responsibleParty']),
        'underwriterAttorneyEmail': !exists(json, 'underwriterAttorneyEmail') ? undefined : json['underwriterAttorneyEmail'],
        'underwriterAttorneyName': !exists(json, 'underwriterAttorneyName') ? undefined : json['underwriterAttorneyName'],
        'underwriterAttorneyPhone': !exists(json, 'underwriterAttorneyPhone') ? undefined : json['underwriterAttorneyPhone'],
    };
}

export function UpdateClaimRequestToJSON(value?: UpdateClaimRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': ClaimRequestAddressToJSON(value.address),
        'agencyId': value.agencyId,
        'causeOfLoss': ClaimCauseOfLossTypeToJSON(value.causeOfLoss),
        'contactEmail': value.contactEmail,
        'contactPhone': value.contactPhone,
        'description': value.description,
        'insuredAssignedAttorneyEmail': value.insuredAssignedAttorneyEmail,
        'insuredAssignedAttorneyName': value.insuredAssignedAttorneyName,
        'insuredAssignedAttorneyPhone': value.insuredAssignedAttorneyPhone,
        'insuredChosenAttorneyEmail': value.insuredChosenAttorneyEmail,
        'insuredChosenAttorneyName': value.insuredChosenAttorneyName,
        'insuredChosenAttorneyPhone': value.insuredChosenAttorneyPhone,
        'insuredName': value.insuredName,
        'policyAmountOfInsurance': value.policyAmountOfInsurance,
        'policyId': value.policyId,
        'policyIssuedOn': value.policyIssuedOn === undefined ? undefined : (value.policyIssuedOn.toISOString().substr(0,10)),
        'policyTransactionType': TransactionTypeToJSON(value.policyTransactionType),
        'policyType': PolicyTypeToJSON(value.policyType),
        'reportedOn': (value.reportedOn.toISOString().substr(0,10)),
        'responsibleParty': ClaimResponsiblePartyTypeToJSON(value.responsibleParty),
        'underwriterAttorneyEmail': value.underwriterAttorneyEmail,
        'underwriterAttorneyName': value.underwriterAttorneyName,
        'underwriterAttorneyPhone': value.underwriterAttorneyPhone,
    };
}

