import {Autocomplete, ComboBoxOption} from "@aws-amplify/ui-react";
import {QueryFunction, useQuery} from "@tanstack/react-query";
import React, {useCallback, useContext} from "react";
import {useDebounce} from "use-debounce";

import {DependencyContext} from "../../DependencyContext";
import {
  AgencyProducerReference,
  GetAgencyProducersAutocompleteRequest,
  GetAgencyProducersAutocompleteSuccessResponse,
  GetAgencyProducersAutocompleteType,
} from "../adminApi";

type AutocompleteProps = React.ComponentProps<typeof Autocomplete>;
type GetAgencyProducersAutocompleteQueryKey = [
  "getAgencyProducersAutocomplete",
  GetAgencyProducersAutocompleteRequest,
];

const SEARCH_DEBOUNCE_MS = 300;

export const AgencyProducerAutocomplete: React.FC<
  Omit<AutocompleteProps, "options" | "onSelect" | "onClear"> &
    Required<Pick<AutocompleteProps, "value" | "onChange">> & {
      onAgencySelected: (agency?: AgencyProducerReference) => any;
      debounceMs?: number;
      limit: number;
      type?: GetAgencyProducersAutocompleteType;
      Autocomplete?: typeof Autocomplete;
    }
> = ({
  onAgencySelected,
  limit,
  type,
  debounceMs = SEARCH_DEBOUNCE_MS,
  Autocomplete: Autocomplete_ = Autocomplete,
  ...autocompleteProps
}) => {
  const {adminApi} = useContext(DependencyContext);
  const getAgenciesAutocompleteQueryFn: QueryFunction<
    GetAgencyProducersAutocompleteSuccessResponse,
    GetAgencyProducersAutocompleteQueryKey
  > = useCallback(
    ({queryKey: [_, _request], signal}) =>
      adminApi.getAgencyProducersAutocomplete(_request, signal),
    [adminApi],
  );
  const [debouncedValue] = useDebounce(autocompleteProps.value, debounceMs);
  const request: GetAgencyProducersAutocompleteRequest = {
    limit,
    filters: {
      name: debouncedValue,
      type,
    },
  };
  const getAgenciesAutocompleteQuery = useQuery({
    queryKey: ["getAgencyProducersAutocomplete", request],
    queryFn: getAgenciesAutocompleteQueryFn,
  });

  const onSelect = useCallback(
    ({id, label}: ComboBoxOption) => {
      onAgencySelected({id, name: label});
    },
    [onAgencySelected],
  );

  const onClear = useCallback(() => {
    onAgencySelected(undefined);
  }, [onAgencySelected]);

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <Autocomplete_
      onSelect={onSelect as AutocompleteProps["onSelect"]}
      onClear={onClear}
      options={(getAgenciesAutocompleteQuery.data?.agencyProducers ?? []).map(
        (agencyReference) => ({
          id: agencyReference.id,
          label: agencyReference.name,
        }),
      )}
      isLoading={getAgenciesAutocompleteQuery.isFetching}
      {...autocompleteProps}
    />
  );
};
