import {isNullOrUndefined, notNullOrUndefined} from "@title-service/utils";
import * as uuid from "uuid";

import {SearchSequenceRepository} from "./SearchSequenceRepository";

const CURRENT_SEQUENCE_KEY = "currentPropertyDocumentSearchSequence";

export class SessionStoreSearchSequenceRepository
  implements SearchSequenceRepository
{
  constructor(private readonly storage: Storage = window.sessionStorage) {}

  storeSequence = async (ids: string[]): Promise<string> => {
    const sequenceId = uuid.v4();

    const currentSequenceId = this.storage.getItem(CURRENT_SEQUENCE_KEY);
    if (notNullOrUndefined(currentSequenceId)) {
      this.storage.removeItem(currentSequenceId);
    }

    this.storage.setItem(sequenceId, JSON.stringify(ids));
    this.storage.setItem(CURRENT_SEQUENCE_KEY, sequenceId);

    return sequenceId;
  };

  getSequence = async (sequenceId: string): Promise<string[] | null> => {
    const currentSequenceId = this.storage.getItem(CURRENT_SEQUENCE_KEY);

    if (isNullOrUndefined(currentSequenceId)) {
      return null;
    }

    if (currentSequenceId !== sequenceId) {
      return null;
    }

    const sequence = this.storage.getItem(currentSequenceId);

    if (isNullOrUndefined(sequence)) {
      return null;
    }

    return JSON.parse(sequence) as string[];
  };
}
