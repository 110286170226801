/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClaimCostType } from './ClaimCostType';
import {
    ClaimCostTypeFromJSON,
    ClaimCostTypeFromJSONTyped,
    ClaimCostTypeToJSON,
} from './ClaimCostType';
import type { ClaimExpenseType } from './ClaimExpenseType';
import {
    ClaimExpenseTypeFromJSON,
    ClaimExpenseTypeFromJSONTyped,
    ClaimExpenseTypeToJSON,
} from './ClaimExpenseType';

/**
 * 
 * @export
 * @interface GetClaimTransactionsSuccessResponseTransaction
 */
export interface GetClaimTransactionsSuccessResponseTransaction {
    /**
     * 
     * @type {number}
     * @memberof GetClaimTransactionsSuccessResponseTransaction
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof GetClaimTransactionsSuccessResponseTransaction
     */
    checkNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof GetClaimTransactionsSuccessResponseTransaction
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof GetClaimTransactionsSuccessResponseTransaction
     */
    description: string;
    /**
     * 
     * @type {ClaimExpenseType}
     * @memberof GetClaimTransactionsSuccessResponseTransaction
     */
    expenseType?: ClaimExpenseType;
    /**
     * 
     * @type {string}
     * @memberof GetClaimTransactionsSuccessResponseTransaction
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimTransactionsSuccessResponseTransaction
     */
    payee: string;
    /**
     * 
     * @type {ClaimCostType}
     * @memberof GetClaimTransactionsSuccessResponseTransaction
     */
    transactionType: ClaimCostType;
    /**
     * 
     * @type {boolean}
     * @memberof GetClaimTransactionsSuccessResponseTransaction
     */
    voided: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetClaimTransactionsSuccessResponseTransaction
     */
    voidedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetClaimTransactionsSuccessResponseTransaction
     */
    voidedTransactionId?: string;
}

/**
 * Check if a given object implements the GetClaimTransactionsSuccessResponseTransaction interface.
 */
export function instanceOfGetClaimTransactionsSuccessResponseTransaction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "checkNumber" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "payee" in value;
    isInstance = isInstance && "transactionType" in value;
    isInstance = isInstance && "voided" in value;

    return isInstance;
}

export function GetClaimTransactionsSuccessResponseTransactionFromJSON(json: any): GetClaimTransactionsSuccessResponseTransaction {
    return GetClaimTransactionsSuccessResponseTransactionFromJSONTyped(json, false);
}

export function GetClaimTransactionsSuccessResponseTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetClaimTransactionsSuccessResponseTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'checkNumber': json['checkNumber'],
        'createdAt': (new Date(json['createdAt'])),
        'description': json['description'],
        'expenseType': !exists(json, 'expenseType') ? undefined : ClaimExpenseTypeFromJSON(json['expenseType']),
        'id': json['id'],
        'payee': json['payee'],
        'transactionType': ClaimCostTypeFromJSON(json['transactionType']),
        'voided': json['voided'],
        'voidedAt': !exists(json, 'voidedAt') ? undefined : (new Date(json['voidedAt'])),
        'voidedTransactionId': !exists(json, 'voidedTransactionId') ? undefined : json['voidedTransactionId'],
    };
}

export function GetClaimTransactionsSuccessResponseTransactionToJSON(value?: GetClaimTransactionsSuccessResponseTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'checkNumber': value.checkNumber,
        'createdAt': (value.createdAt.toISOString()),
        'description': value.description,
        'expenseType': ClaimExpenseTypeToJSON(value.expenseType),
        'id': value.id,
        'payee': value.payee,
        'transactionType': ClaimCostTypeToJSON(value.transactionType),
        'voided': value.voided,
        'voidedAt': value.voidedAt === undefined ? undefined : (value.voidedAt.toISOString()),
        'voidedTransactionId': value.voidedTransactionId,
    };
}

