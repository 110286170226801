/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttomPropertyDocumentReference } from './AttomPropertyDocumentReference';
import {
    AttomPropertyDocumentReferenceFromJSON,
    AttomPropertyDocumentReferenceFromJSONTyped,
    AttomPropertyDocumentReferenceToJSON,
} from './AttomPropertyDocumentReference';
import type { AttomPropertyTransfer } from './AttomPropertyTransfer';
import {
    AttomPropertyTransferFromJSON,
    AttomPropertyTransferFromJSONTyped,
    AttomPropertyTransferToJSON,
} from './AttomPropertyTransfer';

/**
 * 
 * @export
 * @interface UnderwritingDecision
 */
export interface UnderwritingDecision {
    /**
     * 
     * @type {Date}
     * @memberof UnderwritingDecision
     */
    createdAt: Date;
    /**
     * 
     * @type {AttomPropertyTransfer}
     * @memberof UnderwritingDecision
     */
    latestFullValueTransfer?: AttomPropertyTransfer;
    /**
     * 
     * @type {string}
     * @memberof UnderwritingDecision
     */
    scoreId: string;
    /**
     * 
     * @type {boolean}
     * @memberof UnderwritingDecision
     */
    traditionalSearch: boolean;
    /**
     * 
     * @type {Array<AttomPropertyDocumentReference>}
     * @memberof UnderwritingDecision
     */
    transferDocumentsSinceLastFullValueTransfer?: Array<AttomPropertyDocumentReference>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UnderwritingDecision
     */
    vestingInformation?: Array<string>;
}

/**
 * Check if a given object implements the UnderwritingDecision interface.
 */
export function instanceOfUnderwritingDecision(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "scoreId" in value;
    isInstance = isInstance && "traditionalSearch" in value;

    return isInstance;
}

export function UnderwritingDecisionFromJSON(json: any): UnderwritingDecision {
    return UnderwritingDecisionFromJSONTyped(json, false);
}

export function UnderwritingDecisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnderwritingDecision {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'latestFullValueTransfer': !exists(json, 'latestFullValueTransfer') ? undefined : AttomPropertyTransferFromJSON(json['latestFullValueTransfer']),
        'scoreId': json['scoreId'],
        'traditionalSearch': json['traditionalSearch'],
        'transferDocumentsSinceLastFullValueTransfer': !exists(json, 'transferDocumentsSinceLastFullValueTransfer') ? undefined : ((json['transferDocumentsSinceLastFullValueTransfer'] as Array<any>).map(AttomPropertyDocumentReferenceFromJSON)),
        'vestingInformation': !exists(json, 'vestingInformation') ? undefined : json['vestingInformation'],
    };
}

export function UnderwritingDecisionToJSON(value?: UnderwritingDecision | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.createdAt.toISOString()),
        'latestFullValueTransfer': AttomPropertyTransferToJSON(value.latestFullValueTransfer),
        'scoreId': value.scoreId,
        'traditionalSearch': value.traditionalSearch,
        'transferDocumentsSinceLastFullValueTransfer': value.transferDocumentsSinceLastFullValueTransfer === undefined ? undefined : ((value.transferDocumentsSinceLastFullValueTransfer as Array<any>).map(AttomPropertyDocumentReferenceToJSON)),
        'vestingInformation': value.vestingInformation,
    };
}

