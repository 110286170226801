import {Button, Flex, useTheme} from "@aws-amplify/ui-react";
import {
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {Query} from "@title-service/react-query-utils";
import {
  DateTimeComponent,
  FormikConnectedAmplifyCurrencyField,
  FormikConnectedAmplifySubmitButton,
  FormikConnectedAmplifyTextField,
  FormikForm,
  FormResultErrorMessage,
  Heading,
  Text,
  useFieldsetGap,
  VerticalFieldset,
} from "@title-service/ui";
import {enumValues, isNullOrUndefined} from "@title-service/utils";
import {
  buildCurrencySchema,
  transformNullToUndefined,
} from "@title-service/yup-utils";
import {Formik, FormikHelpers, useFormikContext} from "formik";
import React, {useCallback, useContext, useMemo, useState} from "react";
import * as Yup from "yup";

import {DependencyContext} from "../../DependencyContext";
import {
  ClaimCostType,
  ClaimExpenseType,
  ClaimStatus,
  CreateClaimTransactionRequest,
  GetClaimTransactionsSuccessResponse,
  GetClaimTransactionsSuccessResponseTransaction,
} from "../adminApi";
import {CurrencyComponent, CurrencyWrapper} from "../components/Currency";
import {
  DataGrid,
  GridItem,
  GridItemLabel,
  GridItemLabelContainer,
} from "../components/DataGrid";
import {Modal} from "../components/Modal";
import {
  GavelIcon,
  PlusIcon,
  useIconTextSpacing,
  VoidIcon,
} from "../components/icons";
import {
  BodyRow as SecondaryBodyRow,
  FooterRow as SecondaryFooterRow,
  HeaderRow as SecondaryHeaderRow,
  Table as SecondaryTable,
  TBody as SecondaryTBody,
  Td as SecondaryTd,
  TFoot as SecondaryTFoot,
  Th as SecondaryTh,
  THead as SecondaryTHead,
  TableCaption,
} from "../components/tables/SecondaryTable";
import {
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../layout";

import {
  buildClaimCostTypeSchema,
  ClaimCostTypeComponentRaw,
  ClaimCostTypeField,
} from "./ClaimCostTypeField";
import {ExpenseTypeField as ClaimTransactionExpenseTypeField} from "./ClaimExpenseTypeField";
import {DESCRIPTION_SCHEMA, DescriptionField} from "./DescriptionField";
import {IfClaimIsInProgress} from "./IfClaimIsInProgress";

export type GetClaimTransactionsQueryKey = ["claims", string, "transactions"];
export type GetClaimTransactionsQuery =
  Query<GetClaimTransactionsSuccessResponse>;

export const useGetClaimTransactions = (
  claimId: string,
): {
  getClaimTransactionsQuery: GetClaimTransactionsQuery;
  onReloadClaimTransactions: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);
  const getClaimTransactionsQueryKey: GetClaimTransactionsQueryKey = useMemo(
    () => ["claims", claimId, "transactions"],
    [claimId],
  );
  const getClaimTransactionsQueryFn: QueryFunction<
    GetClaimTransactionsSuccessResponse,
    GetClaimTransactionsQueryKey
  > = useCallback(
    ({queryKey: [_, claimId_], signal}) =>
      adminApi.getClaimTransactions({claimId: claimId_}, signal),
    [adminApi],
  );
  const getClaimTransactionsQuery = useQuery({
    queryKey: getClaimTransactionsQueryKey,
    queryFn: getClaimTransactionsQueryFn,
  });
  const onReloadClaimTransactions = useCallback(
    () =>
      queryClient.invalidateQueries({queryKey: getClaimTransactionsQueryKey}),
    [queryClient, getClaimTransactionsQueryKey],
  );

  return {
    getClaimTransactionsQuery,
    onReloadClaimTransactions,
  };
};

export const ClaimTransactionsSection: React.FC<{
  claimId: string;
  claimStatus: ClaimStatus;
  onReloadClaimTransactions: () => any;
  getClaimTransactionsQuery: GetClaimTransactionsQuery;
  editModalContentStyle: NonNullable<
    React.ComponentProps<typeof Modal>["contentStyle"]
  >;
  ClaimTransactionsSuccessComponent?: typeof ClaimTransactionsSuccessComponent;
  ClaimTransactionsFailureComponent?: typeof ClaimTransactionsFailureComponent;
  ClaimTransactionsLoadingComponent?: typeof ClaimTransactionsLoadingComponent;
}> = ({
  claimId,
  claimStatus,
  getClaimTransactionsQuery: {data, error},
  onReloadClaimTransactions,
  editModalContentStyle,
  ClaimTransactionsSuccessComponent:
    ClaimTransactionsSuccessComponent_ = ClaimTransactionsSuccessComponent,
  ClaimTransactionsFailureComponent:
    ClaimTransactionsFailureComponent_ = ClaimTransactionsFailureComponent,
  ClaimTransactionsLoadingComponent:
    ClaimTransactionsLoadingComponent_ = ClaimTransactionsLoadingComponent,
}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);
  return (
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Transactions" />
        <IfClaimIsInProgress status={claimStatus}>
          {data ? (
            <PlusIcon
              data-testid="create-transaction-button"
              onClick={openForm}
            />
          ) : null}
        </IfClaimIsInProgress>
      </SecondarySectionHeaderContainer>
      <SecondarySectionContent>
        {data ? (
          <>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <ClaimTransactionsSuccessComponent_
              claimId={claimId}
              claimStatus={claimStatus}
              editModalContentStyle={editModalContentStyle}
              getClaimTransactionsResponse={data}
              onReloadClaimTransactions={onReloadClaimTransactions}
            />
            <Modal
              contentStyle={editModalContentStyle}
              isOpen={displayForm}
              onClose={closeForm}
              title="Add Transaction"
            >
              <ClaimTransactionForm
                claimId={claimId}
                onClose={closeForm}
                onSubmitComplete={onReloadClaimTransactions}
              />
            </Modal>
          </>
        ) : error ? (
          // eslint-disable-next-line react/jsx-pascal-case
          <ClaimTransactionsFailureComponent_ />
        ) : (
          // eslint-disable-next-line react/jsx-pascal-case
          <ClaimTransactionsLoadingComponent_ />
        )}
      </SecondarySectionContent>
    </SecondarySection>
  );
};

export const ClaimTransactionsLoadingComponent: React.FC = () => (
  <span>Loading Claim Transactions ...</span>
);

export const ClaimTransactionsFailureComponent: React.FC = () => (
  <span>Failed to load Claim Transactions.</span>
);

export const ClaimTransactionsSuccessComponent: React.FC<{
  getClaimTransactionsResponse: GetClaimTransactionsSuccessResponse;
  claimId: string;
  claimStatus: ClaimStatus;
  onReloadClaimTransactions: () => any;
  editModalContentStyle: NonNullable<
    React.ComponentProps<typeof Modal>["contentStyle"]
  >;
}> = ({
  getClaimTransactionsResponse: {transactions},
  claimId,
  claimStatus,
  onReloadClaimTransactions,
  editModalContentStyle,
}) => (
  <>
    <TransactionTotalsSection transactions={transactions} />
    <SecondaryTable>
      <TableCaption>Transaction History</TableCaption>
      <SecondaryTHead>
        <SecondaryHeaderRow>
          <IfClaimIsInProgress status={claimStatus}>
            {transactions.length > 0 && <SecondaryTh />}
            {/* Void transaction button table header */}
          </IfClaimIsInProgress>
          <SecondaryTh>Type</SecondaryTh>
          <SecondaryTh>Payee</SecondaryTh>
          <SecondaryTh>Amount</SecondaryTh>
          <SecondaryTh>Payment ID</SecondaryTh>
          <SecondaryTh>Description</SecondaryTh>
          <SecondaryTh>Created At</SecondaryTh>
          <SecondaryTh>Voided At</SecondaryTh>
        </SecondaryHeaderRow>
      </SecondaryTHead>
      <SecondaryTBody>
        {transactions.length === 0 ? (
          <SecondaryBodyRow>
            <SecondaryTd colSpan={8}>No Transactions Recorded</SecondaryTd>
          </SecondaryBodyRow>
        ) : (
          transactions
            .filter((transaction) =>
              isNullOrUndefined(transaction.voidedTransactionId),
            )
            .map((transaction) => (
              <TransactionRow
                claimId={claimId}
                claimStatus={claimStatus}
                editModalContentStyle={editModalContentStyle}
                key={transaction.id}
                onReloadClaimTransactions={onReloadClaimTransactions}
                transaction={transaction}
              />
            ))
        )}
      </SecondaryTBody>
    </SecondaryTable>
  </>
);

const TransactionTotalsSection: React.FC<{
  transactions: GetClaimTransactionsSuccessResponse["transactions"];
}> = ({transactions}) => {
  const calculatedTransactionState = useMemo(
    () => calculateTransactionState(transactions),
    [transactions],
  );
  return (
    <DataGrid>
      <GridItem>
        <GridItemLabelContainer>
          <GridItemLabel value="Expense Total" />
        </GridItemLabelContainer>
        <SecondaryTable highlightOnHover={false} width="unset">
          <SecondaryTBody>
            <SecondaryBodyRow>
              <SecondaryTd border="none">Attorney&apos;s Fees</SecondaryTd>
              <SecondaryTd border="none">
                <CurrencyComponent
                  value={calculatedTransactionState.expense.attorneyFeeTotal}
                />
              </SecondaryTd>
            </SecondaryBodyRow>
            <SecondaryBodyRow>
              <SecondaryTd border="none">Other Expenses</SecondaryTd>
              <SecondaryTd border="none">
                <CurrencyComponent
                  value={calculatedTransactionState.expense.otherTotal}
                />
              </SecondaryTd>
            </SecondaryBodyRow>
          </SecondaryTBody>
          <SecondaryTFoot>
            <SummaryRow>
              <SecondaryTh scope="row">
                <CurrencyWrapper value="Total" />
              </SecondaryTh>
              <SecondaryTd>
                <CurrencyComponent
                  value={calculatedTransactionState.expense.total}
                />
              </SecondaryTd>
            </SummaryRow>
          </SecondaryTFoot>
        </SecondaryTable>
      </GridItem>
      <GridItem>
        <GridItemLabelContainer>
          <GridItemLabel value="Loss Total" />
        </GridItemLabelContainer>
        <CurrencyComponent value={calculatedTransactionState.loss.total} />
      </GridItem>
    </DataGrid>
  );
};

const SummaryRow: React.FC<React.ComponentProps<typeof SecondaryFooterRow>> = (
  props,
) => {
  const {
    tokens: {
      colors: {
        background: {success},
      },
    },
  } = useTheme();
  return <SecondaryFooterRow backgroundColor={success} {...props} />;
};

export const TransactionRow: React.FC<{
  claimId: string;
  claimStatus: ClaimStatus;
  onReloadClaimTransactions: () => any;
  transaction: GetClaimTransactionsSuccessResponseTransaction;
  editModalContentStyle: NonNullable<
    React.ComponentProps<typeof Modal>["contentStyle"]
  >;
}> = ({
  claimId,
  claimStatus,
  onReloadClaimTransactions,
  transaction: {
    id,
    transactionType,
    expenseType,
    payee,
    amount,
    checkNumber,
    description,
    createdAt,
    voided,
    voidedTransactionId,
    voidedAt,
  },
  editModalContentStyle,
}) => {
  const [voidTransactionModalOpen, setVoidTransactionModalOpen] =
    useState(false);
  const openVoidTransactionModal = useCallback(() => {
    setVoidTransactionModalOpen(true);
  }, []);
  const closeVoidTransactionModal = useCallback(() => {
    setVoidTransactionModalOpen(false);
  }, []);
  const lineThroughProps = voided ? {textDecoration: "line-through"} : {};
  const iconSpacing = useIconTextSpacing();
  return (
    <SecondaryBodyRow>
      <IfClaimIsInProgress status={claimStatus}>
        <SecondaryTd>
          {!voided && (
            <>
              <VoidIcon
                data-testid="void-transaction-button"
                onClick={openVoidTransactionModal}
              />
              <Modal
                contentStyle={editModalContentStyle}
                isOpen={voidTransactionModalOpen}
                onClose={closeVoidTransactionModal}
              >
                <VoidTransactionConfirmation
                  claimId={claimId}
                  claimTransactionId={id}
                  onClose={closeVoidTransactionModal}
                  onSubmitComplete={onReloadClaimTransactions}
                />
              </Modal>
            </>
          )}
        </SecondaryTd>
      </IfClaimIsInProgress>
      <SecondaryTd {...lineThroughProps}>
        <Flex
          alignItems="center"
          direction="row"
          gap={iconSpacing}
          justifyContent="flex-start"
        >
          <Text
            value={
              <ClaimCostTypeComponentRaw claimCostType={transactionType} />
            }
          />
          {expenseType === ClaimExpenseType.AttorneyFee && <GavelIcon />}
        </Flex>
      </SecondaryTd>
      <SecondaryTd {...lineThroughProps}>{payee}</SecondaryTd>
      <SecondaryTd {...lineThroughProps}>
        <CurrencyComponent
          value={amount}
          variation={voidedTransactionId ? "error" : "success"}
        />
      </SecondaryTd>
      <SecondaryTd {...lineThroughProps}>{checkNumber}</SecondaryTd>
      <SecondaryTd {...lineThroughProps}>{description}</SecondaryTd>
      <SecondaryTd {...lineThroughProps}>
        <DateTimeComponent value={createdAt} />
      </SecondaryTd>
      <SecondaryTd>
        {voidedAt ? <DateTimeComponent value={voidedAt} /> : null}
      </SecondaryTd>
    </SecondaryBodyRow>
  );
};

export const VoidTransactionConfirmation: React.FC<{
  claimId: string;
  claimTransactionId: string;
  onClose: () => any;
  onSubmitComplete: () => any;
}> = ({claimId, claimTransactionId, onClose, onSubmitComplete}) => {
  const fieldsetGap = useFieldsetGap();
  const {adminApi} = useContext(DependencyContext);

  const onSuccess = useCallback(() => {
    onSubmitComplete();
    onClose();
  }, [onClose, onSubmitComplete]);

  const voidClaimTransactionMutation = useMutation({
    mutationFn: adminApi.voidClaimTransaction,
    onSuccess,
  });

  const submitHandler = useCallback(() => {
    voidClaimTransactionMutation.mutate({claimId, claimTransactionId});
  }, [claimId, claimTransactionId, voidClaimTransactionMutation]);

  return (
    <Flex alignItems="self-start" direction="column" gap={fieldsetGap}>
      <Heading
        level={5}
        value="Are you sure you want to void this transaction?"
      />
      <Flex direction="row" justifyContent="flex-end" width="100%">
        <Button
          isDisabled={voidClaimTransactionMutation.isLoading}
          onClick={onClose}
          testId="cancel-button"
          variation="link"
        >
          Cancel
        </Button>
        <Button
          isLoading={voidClaimTransactionMutation.isLoading}
          loadingText="Voiding..."
          onClick={submitHandler}
          testId="submit-button"
          variation="destructive"
        >
          Void
        </Button>
      </Flex>
    </Flex>
  );
};

type CalculatedTransactionLossState = {
  total: number;
};

type CalculatedTransactionExpenseState = {
  total: number;
  attorneyFeeTotal: number;
  otherTotal: number;
};

type CalculatedTransactionState = {
  loss: CalculatedTransactionLossState;
  expense: CalculatedTransactionExpenseState;
};

const sumTransactions = (
  transactions: GetClaimTransactionsSuccessResponse["transactions"],
) =>
  transactions
    .map((t) => t.amount)
    .reduce((accumulator, totalAmount) => accumulator + totalAmount, 0);

export const calculateTransactionState = (
  transactions: GetClaimTransactionsSuccessResponse["transactions"],
): CalculatedTransactionState => {
  const calculatedTransactionState: Partial<CalculatedTransactionState> = {};

  const [expenseTransactions, lossTransactions] = enumValues(ClaimCostType).map(
    (enumValue) => transactions.filter((t) => t.transactionType === enumValue),
  );

  const [attorneyFeeExpenseTransactions, otherExpenseTransactions] = enumValues(
    ClaimExpenseType,
  ).map((enumValue) => transactions.filter((t) => t.expenseType === enumValue));

  calculatedTransactionState.loss = {
    total: sumTransactions(lossTransactions),
  };

  calculatedTransactionState.expense = {
    total: sumTransactions(expenseTransactions),
    attorneyFeeTotal: sumTransactions(attorneyFeeExpenseTransactions),
    otherTotal: sumTransactions(otherExpenseTransactions),
  };

  return calculatedTransactionState as CalculatedTransactionState;
};

type CompleteClaimTransactionFormData = Omit<
  CreateClaimTransactionRequest,
  "claimId"
>;

export const TRANSACTION_TYPE_SCHEMA: Yup.StringSchema<ClaimCostType> =
  buildClaimCostTypeSchema().required("Transaction Type is required");

export const PAYEE_SCHEMA: Yup.StringSchema<string> = Yup.string()
  .required("Payee is required")
  .trim();

export const PayeeField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = (props) => (
  <FormikConnectedAmplifyTextField
    label="Payee"
    testId="payee-input"
    {...props}
  />
);

export const AMOUNT_SCHEMA: Yup.NumberSchema<number> = buildCurrencySchema()
  .required("Amount is required")
  .positive("Amount must be greater than 0");

export const AmountField: React.FC<
  Omit<
    React.ComponentProps<typeof FormikConnectedAmplifyCurrencyField>,
    "label"
  >
> = (props) => (
  <FormikConnectedAmplifyCurrencyField
    label="Amount"
    testId="amount-input"
    {...props}
  />
);

export const PAYMENT_ID_SCHEMA: Yup.StringSchema<string> = Yup.string()
  .required("Payment ID is required")
  .trim();

export const PaymentIdField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = (props) => (
  <FormikConnectedAmplifyTextField
    label="Payment ID"
    testId="payment-id-input"
    {...props}
  />
);

export const CLAIM_TRANSACTION_FORM_SCHEMA: Yup.ObjectSchema<CompleteClaimTransactionFormData> =
  Yup.object({
    amount: AMOUNT_SCHEMA,
    checkNumber: PAYMENT_ID_SCHEMA,
    description: DESCRIPTION_SCHEMA,
    payee: PAYEE_SCHEMA,
    transactionType: TRANSACTION_TYPE_SCHEMA,
    expenseType: Yup.string()
      .trim()
      .transform(transformNullToUndefined)
      .when("transactionType", ([transactionType], builder) => {
        if (transactionType === ClaimCostType.Expense) {
          return builder
            .required("Expense Type is required")
            .oneOf(
              enumValues(ClaimExpenseType),
              "Must be Attorney Fee or Other",
            );
        }
        return builder.transform(() => undefined);
      }) as Yup.StringSchema<ClaimExpenseType | undefined>,
  });

export type ClaimTransactionFormData = Omit<
  CreateClaimTransactionRequest,
  "claimId" | "amount" | "transactionType" | "expenseType"
> & {
  amount: string;
  transactionType: string;
  expenseType: string;
};

export const buildInitialClaimTransactionFormData =
  (): ClaimTransactionFormData => ({
    amount: "",
    checkNumber: "",
    description: "",
    payee: "",
    transactionType: "",
    expenseType: "",
  });

export const mapClaimTransactionFormDataToCreateClaimTransactionRequest = (
  claimId: string,
  formData: ClaimTransactionFormData,
): CreateClaimTransactionRequest => {
  const completeFormData = CLAIM_TRANSACTION_FORM_SCHEMA.validateSync(formData);
  return {
    ...completeFormData,
    claimId,
  };
};

const ClaimTransactionForm: React.FC<{
  claimId: string;
  onClose: () => any;
  onSubmitComplete: () => any;
}> = ({claimId, onClose, onSubmitComplete}) => {
  const {adminApi} = useContext(DependencyContext);
  const createClaimTransactionMutation = useMutation({
    mutationFn: adminApi.createClaimTransaction,
  });
  const submitHandler = useCallback(
    (
      formData: ClaimTransactionFormData,
      {setSubmitting}: FormikHelpers<ClaimTransactionFormData>,
    ) => {
      const request =
        mapClaimTransactionFormDataToCreateClaimTransactionRequest(
          claimId,
          formData,
        );
      createClaimTransactionMutation.mutate(request, {
        onSuccess: () => {
          onSubmitComplete();
          onClose();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [claimId, createClaimTransactionMutation, onClose, onSubmitComplete],
  );
  return (
    <Formik<ClaimTransactionFormData>
      initialValues={buildInitialClaimTransactionFormData()}
      onSubmit={submitHandler}
      validationSchema={CLAIM_TRANSACTION_FORM_SCHEMA as any}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <ClaimCostTypeField
            label="Transaction Type"
            name="transactionType"
            testId="transaction-type-input"
          />

          <IfTransactionTypeExpense>
            <ClaimTransactionExpenseTypeField name="expenseType" />
          </IfTransactionTypeExpense>

          <PayeeField name="payee" />

          <AmountField name="amount" />

          <PaymentIdField name="checkNumber" />

          <DescriptionField name="description" />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton />

        {createClaimTransactionMutation.isError ? (
          <FormResultErrorMessage data-testid="error-state">
            Request failed.
          </FormResultErrorMessage>
        ) : null}
      </FormikForm>
    </Formik>
  );
};

const IfTransactionTypeExpense: React.FC = ({children}) => {
  const formik = useFormikContext<ClaimTransactionFormData>();

  if (formik.values.transactionType === ClaimCostType.Expense) {
    return <>{children}</>;
  }
  return null;
};
