import {PrimarySection} from "@title-service/ui";
import React from "react";

import {
  InsurfulOrderSummary,
  OrderLegalDescription,
  OrderPropertyDocument,
  OrderScore,
  OrderVestingInformation,
  UnderwritingDecision,
} from "../adminApi";

import {InsurfulReportsSection} from "./AumTab/InsurfulReportsSection";
import {LegalDescriptionsSection} from "./AumTab/LegalDescriptionsSection";
import {PropertyDocumentsSection} from "./AumTab/PropertyDocumentsSection";
import {UnderwritingDecisionsSection} from "./AumTab/UnderwritingDecisionsSection";
import {VestingInformationSection} from "./AumTab/VestingInformationSection";

export const AumTab: React.FC<{
  insurfulOrderSummaries: InsurfulOrderSummary[];
  legalDescriptions: OrderLegalDescription[];
  propertyDocuments: OrderPropertyDocument[];
  scores: OrderScore[];
  underwritingDecisions: UnderwritingDecision[];
  vestingInformation: OrderVestingInformation[];
}> = ({
  insurfulOrderSummaries,
  legalDescriptions,
  propertyDocuments,
  scores,
  underwritingDecisions,
  vestingInformation,
}) => (
  <PrimarySection>
    <UnderwritingDecisionsSection
      scores={scores}
      underwritingDecisions={underwritingDecisions}
    />
    <InsurfulReportsSection insurfulOrderSummaries={insurfulOrderSummaries} />
    <PropertyDocumentsSection propertyDocuments={propertyDocuments} />
    <LegalDescriptionsSection legalDescriptions={legalDescriptions} />
    <VestingInformationSection vestingInformation={vestingInformation} />
  </PrimarySection>
);
