import {DateTimeComponent} from "@title-service/ui";
import React from "react";
import styled from "styled-components";

import {OrderPropertyDocument} from "../../adminApi";
import {RouterLink} from "../../components/Link";
import {MaybeNotAvailable} from "../../components/Nullable";
import {
  BodyRow as PrimaryBodyRow,
  HeaderRow as PrimaryHeaderRow,
  Table as PrimaryTable,
  TBody as PrimaryTBody,
  Td as PrimaryTd,
  Th as PrimaryTh,
  THead as PrimaryTHead,
} from "../../components/tables/PrimaryTable";
import {
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../../layout";
import {OrderPropertyDocumentIngestionStatusPill} from "../OrderPropertyDocumentIngestionStatusPill";

export const PropertyDocumentsSection: React.FC<{
  propertyDocuments: OrderPropertyDocument[];
}> = ({propertyDocuments}) => (
  <SecondarySection>
    <SecondarySectionHeaderContainer>
      <SecondarySectionHeader value="Property Documents" />
    </SecondarySectionHeaderContainer>
    <SecondarySectionContent>
      {propertyDocuments.length > 0 ? (
        <PrimaryTable>
          <PrimaryTHead>
            <PrimaryHeaderRow>
              <PrimaryTh>ATTOM ID</PrimaryTh>
              <PrimaryTh>Acquisition Status</PrimaryTh>
              <PrimaryTh>Acquisition Requested At</PrimaryTh>
              <PrimaryTh>Acquisition Completed At</PrimaryTh>
              <PrimaryTh>Document</PrimaryTh>
            </PrimaryHeaderRow>
          </PrimaryTHead>
          <PrimaryTBody>
            {propertyDocuments.map(
              ({
                id,
                attomId,
                ingestionStatus,
                createdAt,
                ingestionCompletedAt,
                propertyDocumentId,
              }) => (
                <PrimaryBodyRow key={id}>
                  <AttomIdTd>{attomId}</AttomIdTd>
                  <IngestionStatusTd>
                    <OrderPropertyDocumentIngestionStatusPill
                      status={ingestionStatus}
                    />
                  </IngestionStatusTd>
                  <RequestedAtTd>
                    <DateTimeComponent value={createdAt} />
                  </RequestedAtTd>
                  <CompletedAtTd>
                    <MaybeNotAvailable value={ingestionCompletedAt}>
                      {(ingestionCompletedAt_) => (
                        <DateTimeComponent value={ingestionCompletedAt_} />
                      )}
                    </MaybeNotAvailable>
                  </CompletedAtTd>
                  <DocumentLinkTd>
                    <MaybeNotAvailable value={propertyDocumentId}>
                      {(propertyDocumentId_) => (
                        <RouterLink to={`/documents/${propertyDocumentId_}/`}>
                          Document
                        </RouterLink>
                      )}
                    </MaybeNotAvailable>
                  </DocumentLinkTd>
                </PrimaryBodyRow>
              ),
            )}
          </PrimaryTBody>
        </PrimaryTable>
      ) : (
        <span>No Property Documents</span>
      )}
    </SecondarySectionContent>
  </SecondarySection>
);

const AttomIdTd = styled(PrimaryTd)({
  width: "75px",
});

const IngestionStatusTd = styled(PrimaryTd)({
  width: "50px",
});

const RequestedAtTd = styled(PrimaryTd)({
  width: "100px",
});

const CompletedAtTd = styled(PrimaryTd)({
  width: "100px",
});

const DocumentLinkTd = styled(PrimaryTd)({
  width: "50px",
});
