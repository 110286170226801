import React from "react";

import {Text, TextRaw} from "./Text";

type DateComponentProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  locale?: Intl.LocalesArgument;
  // eslint-disable-next-line react/no-unused-prop-types
  value: Date;
  // eslint-disable-next-line react/no-unused-prop-types
  children?: undefined;
};

const dateToLocaleString = ({value, locale}: DateComponentProps): string =>
  value.toLocaleString(locale, {
    timeZone: "UTC",
    year: "numeric",
    month: "short",
    day: "numeric",
  });

export const DateComponentRaw: React.FC<DateComponentProps> = (props) => (
  <TextRaw value={dateToLocaleString(props)} />
);

export const DateComponent: React.FC<
  Omit<React.ComponentProps<typeof Text>, "value"> & DateComponentProps
> = (props) => <Text {...props} value={dateToLocaleString(props)} />;

if (import.meta.vitest) {
  const {describe, expect, it} = import.meta.vitest;
  const {render} = await import("@testing-library/react");

  describe("DateComponentRaw", () => {
    it("displays a locale date object as an expanded date", () => {
      const date = new Date("2019-09-10T00:00:00.0000Z");

      const {container} = render(
        <DateComponentRaw value={date} locale="en-US" />,
      );

      expect(container).toHaveTextContent("Sep 10, 2019");
    });
  });
}
