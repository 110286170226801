/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderSourcePropertyDocumentReference } from './OrderSourcePropertyDocumentReference';
import {
    OrderSourcePropertyDocumentReferenceFromJSON,
    OrderSourcePropertyDocumentReferenceFromJSONTyped,
    OrderSourcePropertyDocumentReferenceToJSON,
} from './OrderSourcePropertyDocumentReference';
import type { OrderVestingInformation } from './OrderVestingInformation';
import {
    OrderVestingInformationFromJSON,
    OrderVestingInformationFromJSONTyped,
    OrderVestingInformationToJSON,
} from './OrderVestingInformation';
import type { OrderVestingInformationNotAvailableReason } from './OrderVestingInformationNotAvailableReason';
import {
    OrderVestingInformationNotAvailableReasonFromJSON,
    OrderVestingInformationNotAvailableReasonFromJSONTyped,
    OrderVestingInformationNotAvailableReasonToJSON,
} from './OrderVestingInformationNotAvailableReason';

/**
 * 
 * @export
 * @interface OrderVestingInformationNotAvailable
 */
export interface OrderVestingInformationNotAvailable extends OrderVestingInformation {
    /**
     * 
     * @type {OrderVestingInformationNotAvailableReason}
     * @memberof OrderVestingInformationNotAvailable
     */
    notAvailableReason: OrderVestingInformationNotAvailableReason;
    /**
     * 
     * @type {Array<OrderSourcePropertyDocumentReference>}
     * @memberof OrderVestingInformationNotAvailable
     */
    sourceDocuments?: Array<OrderSourcePropertyDocumentReference>;
}

/**
 * Check if a given object implements the OrderVestingInformationNotAvailable interface.
 */
export function instanceOfOrderVestingInformationNotAvailable(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "notAvailableReason" in value;

    return isInstance;
}

export function OrderVestingInformationNotAvailableFromJSON(json: any): OrderVestingInformationNotAvailable {
    return OrderVestingInformationNotAvailableFromJSONTyped(json, false);
}

export function OrderVestingInformationNotAvailableFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderVestingInformationNotAvailable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...OrderVestingInformationFromJSONTyped(json, ignoreDiscriminator),
        'notAvailableReason': OrderVestingInformationNotAvailableReasonFromJSON(json['notAvailableReason']),
        'sourceDocuments': !exists(json, 'sourceDocuments') ? undefined : ((json['sourceDocuments'] as Array<any>).map(OrderSourcePropertyDocumentReferenceFromJSON)),
    };
}

export function OrderVestingInformationNotAvailableToJSON(value?: OrderVestingInformationNotAvailable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...OrderVestingInformationToJSON(value),
        'notAvailableReason': OrderVestingInformationNotAvailableReasonToJSON(value.notAvailableReason),
        'sourceDocuments': value.sourceDocuments === undefined ? undefined : ((value.sourceDocuments as Array<any>).map(OrderSourcePropertyDocumentReferenceToJSON)),
    };
}

