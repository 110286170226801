import {Button} from "@aws-amplify/ui-react";
import React from "react";

import {FlagIcon} from "../components/icons";

export const OrderFlagButton: React.FC<
  React.ComponentProps<typeof Button> & {active: boolean}
> = ({active, ...props}) => (
  <Button
    {...(props.onClick
      ? {}
      : {style: {cursor: "default", pointerEvents: "none"}})}
    variation={active ? "warning" : undefined}
    {...props}
  >
    <FlagIcon />
  </Button>
);
