/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { ClaimRequestAddress } from './ClaimRequestAddress';
import {
    ClaimRequestAddressFromJSON,
    ClaimRequestAddressFromJSONTyped,
    ClaimRequestAddressToJSON,
} from './ClaimRequestAddress';

/**
 * 
 * @export
 * @interface ClaimRequestAddressNew
 */
export interface ClaimRequestAddressNew extends ClaimRequestAddress {
    /**
     * 
     * @type {Address}
     * @memberof ClaimRequestAddressNew
     */
    data: Address;
}

/**
 * Check if a given object implements the ClaimRequestAddressNew interface.
 */
export function instanceOfClaimRequestAddressNew(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ClaimRequestAddressNewFromJSON(json: any): ClaimRequestAddressNew {
    return ClaimRequestAddressNewFromJSONTyped(json, false);
}

export function ClaimRequestAddressNewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaimRequestAddressNew {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...ClaimRequestAddressFromJSONTyped(json, ignoreDiscriminator),
        'data': AddressFromJSON(json['data']),
    };
}

export function ClaimRequestAddressNewToJSON(value?: ClaimRequestAddressNew | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...ClaimRequestAddressToJSON(value),
        'data': AddressToJSON(value.data),
    };
}

