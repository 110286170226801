/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PropertyDocumentAssociatedOrder } from './PropertyDocumentAssociatedOrder';
import {
    PropertyDocumentAssociatedOrderFromJSON,
    PropertyDocumentAssociatedOrderFromJSONTyped,
    PropertyDocumentAssociatedOrderToJSON,
} from './PropertyDocumentAssociatedOrder';
import type { PropertyDocumentAutomatedVerification } from './PropertyDocumentAutomatedVerification';
import {
    PropertyDocumentAutomatedVerificationFromJSON,
    PropertyDocumentAutomatedVerificationFromJSONTyped,
    PropertyDocumentAutomatedVerificationToJSON,
} from './PropertyDocumentAutomatedVerification';
import type { PropertyDocumentExtractedText } from './PropertyDocumentExtractedText';
import {
    PropertyDocumentExtractedTextFromJSON,
    PropertyDocumentExtractedTextFromJSONTyped,
    PropertyDocumentExtractedTextToJSON,
} from './PropertyDocumentExtractedText';
import type { PropertyDocumentFlag } from './PropertyDocumentFlag';
import {
    PropertyDocumentFlagFromJSON,
    PropertyDocumentFlagFromJSONTyped,
    PropertyDocumentFlagToJSON,
} from './PropertyDocumentFlag';
import type { PropertyDocumentLegalDescription } from './PropertyDocumentLegalDescription';
import {
    PropertyDocumentLegalDescriptionFromJSON,
    PropertyDocumentLegalDescriptionFromJSONTyped,
    PropertyDocumentLegalDescriptionToJSON,
} from './PropertyDocumentLegalDescription';
import type { PropertyDocumentSummary } from './PropertyDocumentSummary';
import {
    PropertyDocumentSummaryFromJSON,
    PropertyDocumentSummaryFromJSONTyped,
    PropertyDocumentSummaryToJSON,
} from './PropertyDocumentSummary';
import type { PropertyDocumentVestingInformation } from './PropertyDocumentVestingInformation';
import {
    PropertyDocumentVestingInformationFromJSON,
    PropertyDocumentVestingInformationFromJSONTyped,
    PropertyDocumentVestingInformationToJSON,
} from './PropertyDocumentVestingInformation';

/**
 * 
 * @export
 * @interface GetPropertyDocumentSuccessResponse
 */
export interface GetPropertyDocumentSuccessResponse {
    /**
     * 
     * @type {string}
     * @memberof GetPropertyDocumentSuccessResponse
     */
    artifactUrl: string;
    /**
     * 
     * @type {PropertyDocumentAutomatedVerification}
     * @memberof GetPropertyDocumentSuccessResponse
     */
    automatedVerification?: PropertyDocumentAutomatedVerification;
    /**
     * 
     * @type {PropertyDocumentSummary}
     * @memberof GetPropertyDocumentSuccessResponse
     */
    document: PropertyDocumentSummary;
    /**
     * 
     * @type {PropertyDocumentExtractedText}
     * @memberof GetPropertyDocumentSuccessResponse
     */
    extractedText?: PropertyDocumentExtractedText;
    /**
     * 
     * @type {PropertyDocumentFlag}
     * @memberof GetPropertyDocumentSuccessResponse
     */
    flag?: PropertyDocumentFlag;
    /**
     * 
     * @type {PropertyDocumentLegalDescription}
     * @memberof GetPropertyDocumentSuccessResponse
     */
    legalDescription?: PropertyDocumentLegalDescription;
    /**
     * 
     * @type {PropertyDocumentAssociatedOrder}
     * @memberof GetPropertyDocumentSuccessResponse
     */
    order?: PropertyDocumentAssociatedOrder;
    /**
     * 
     * @type {PropertyDocumentVestingInformation}
     * @memberof GetPropertyDocumentSuccessResponse
     */
    vestingInformation?: PropertyDocumentVestingInformation;
}

/**
 * Check if a given object implements the GetPropertyDocumentSuccessResponse interface.
 */
export function instanceOfGetPropertyDocumentSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "artifactUrl" in value;
    isInstance = isInstance && "document" in value;

    return isInstance;
}

export function GetPropertyDocumentSuccessResponseFromJSON(json: any): GetPropertyDocumentSuccessResponse {
    return GetPropertyDocumentSuccessResponseFromJSONTyped(json, false);
}

export function GetPropertyDocumentSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPropertyDocumentSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'artifactUrl': json['artifactUrl'],
        'automatedVerification': !exists(json, 'automatedVerification') ? undefined : PropertyDocumentAutomatedVerificationFromJSON(json['automatedVerification']),
        'document': PropertyDocumentSummaryFromJSON(json['document']),
        'extractedText': !exists(json, 'extractedText') ? undefined : PropertyDocumentExtractedTextFromJSON(json['extractedText']),
        'flag': !exists(json, 'flag') ? undefined : PropertyDocumentFlagFromJSON(json['flag']),
        'legalDescription': !exists(json, 'legalDescription') ? undefined : PropertyDocumentLegalDescriptionFromJSON(json['legalDescription']),
        'order': !exists(json, 'order') ? undefined : PropertyDocumentAssociatedOrderFromJSON(json['order']),
        'vestingInformation': !exists(json, 'vestingInformation') ? undefined : PropertyDocumentVestingInformationFromJSON(json['vestingInformation']),
    };
}

export function GetPropertyDocumentSuccessResponseToJSON(value?: GetPropertyDocumentSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'artifactUrl': value.artifactUrl,
        'automatedVerification': PropertyDocumentAutomatedVerificationToJSON(value.automatedVerification),
        'document': PropertyDocumentSummaryToJSON(value.document),
        'extractedText': PropertyDocumentExtractedTextToJSON(value.extractedText),
        'flag': PropertyDocumentFlagToJSON(value.flag),
        'legalDescription': PropertyDocumentLegalDescriptionToJSON(value.legalDescription),
        'order': PropertyDocumentAssociatedOrderToJSON(value.order),
        'vestingInformation': PropertyDocumentVestingInformationToJSON(value.vestingInformation),
    };
}

