/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PropertyDocumentFlag } from './PropertyDocumentFlag';
import {
    PropertyDocumentFlagFromJSON,
    PropertyDocumentFlagFromJSONTyped,
    PropertyDocumentFlagToJSON,
} from './PropertyDocumentFlag';

/**
 * 
 * @export
 * @interface PropertyDocumentFlagInactive
 */
export interface PropertyDocumentFlagInactive extends PropertyDocumentFlag {
}

/**
 * Check if a given object implements the PropertyDocumentFlagInactive interface.
 */
export function instanceOfPropertyDocumentFlagInactive(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PropertyDocumentFlagInactiveFromJSON(json: any): PropertyDocumentFlagInactive {
    return PropertyDocumentFlagInactiveFromJSONTyped(json, false);
}

export function PropertyDocumentFlagInactiveFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyDocumentFlagInactive {
    return json;
}

export function PropertyDocumentFlagInactiveToJSON(value?: PropertyDocumentFlagInactive | null): any {
    return value;
}

