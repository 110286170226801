import "@title-service/ui/src/styles.css";
import "@aws-amplify/ui-react/styles.css";
import "./shared/util/initGlobal";
import {datadogLogs} from "@datadog/browser-logs";
import {QueryClient} from "@tanstack/react-query";
import {Amplify} from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";

import {App} from "./App";
import {Dependencies} from "./DependencyContext";
import {FetchSmartyStreetsAutocompleteApi} from "./shared/addressAutocompleteApi";
import {buildHttpAdminApiClient} from "./shared/adminApi/HttpAdminApiClient";
import {
  AuthenticationStateHolder,
  DevelopmentAuthenticationStrategy,
  ProductionAuthenticationStrategy,
} from "./shared/authentication";
import {SessionStoreSearchSequenceRepository} from "./shared/propertyDocuments/SessionStoreSearchSequenceRepository";

type ApplicationConfig = {
  appElementId: string;
  auth: {
    requireAuthentication: boolean;
    identityPoolId: string;
    identityProviderName: string;
    userPoolRegion: string;
    userPoolId: string;
    userPoolWebClientId: string;
    domain: string;
    adticDomain: string;
    signInRedirectUrl: string;
    signOutRedirectUrl: string;
    adticSignInRedirectUrl: string;
    adticSignOutRedirectUrl: string;
  };
  adminApi: {
    baseUrl: string;
    adticBaseUrl: string;
  };
  datadog: {
    app: string;
    clientToken: string;
    env: string;
    family: string;
    service: string;
    version: string;
  };
  fileUploadDropbox: {
    s3BucketName: string;
    s3BucketRegion: string;
  };
  smartyStreets: {
    websiteKey: string;
  };
};

export const main = (config: ApplicationConfig) => {
  datadogLogs.init({
    clientToken: config.datadog.clientToken,
    env: config.datadog.env,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: "all",
    forwardReports: "all",
    sampleRate: 100,
    service: config.datadog.service,
    site: "datadoghq.com",
    version: config.datadog.version,
  });
  datadogLogs.addLoggerGlobalContext("family", config.datadog.family);
  datadogLogs.addLoggerGlobalContext("app", config.datadog.app);
  datadogLogs.logger.setLevel("warn");

  Amplify.configure({
    Auth: {
      identityPoolId: config.auth.identityPoolId,
      region: config.auth.userPoolRegion,
      userPoolId: config.auth.userPoolId,
      userPoolWebClientId: config.auth.userPoolWebClientId,
      mandatorySignIn: true,
      authenticationFlowType: "USER_SRP_AUTH",
      oauth: {
        domain: config.auth.adticDomain,
        scope: ["profile", "email", "openid", "phone"],
        redirectSignIn: config.auth.adticSignInRedirectUrl,
        redirectSignOut: config.auth.adticSignOutRedirectUrl,
        responseType: "code",
      },
    },
    Storage: {
      AWSS3: {
        bucket: config.fileUploadDropbox.s3BucketName,
        region: config.fileUploadDropbox.s3BucketRegion,
      },
    },
  });

  const authenticationStateHolder = new AuthenticationStateHolder();
  const authenticationStrategy = config.auth.requireAuthentication
    ? new ProductionAuthenticationStrategy(config.auth.identityProviderName)
    : new DevelopmentAuthenticationStrategy();

  const dependencies: Dependencies = {
    authenticationStateHolder,
    authenticationStrategy,
    addressAutocompleteApi: new FetchSmartyStreetsAutocompleteApi(
      config.smartyStreets.websiteKey,
    ),
    adminApi: buildHttpAdminApiClient(
      config.adminApi.adticBaseUrl,
      authenticationStateHolder,
    ),
    propertyDocumentSearchSequenceRepository:
      new SessionStoreSearchSequenceRepository(),
    queryClient: new QueryClient({
      defaultOptions: {
        queries: {
          networkMode: "always",
        },
        mutations: {
          networkMode: "always",
        },
      },
    }),
  };

  ReactModal.setAppElement(`#${config.appElementId}`);
  ReactModal.defaultStyles = {
    overlay: {},
    content: {},
  };

  ReactDOM.render(
    <App dependencies={dependencies} />,
    document.getElementById(config.appElementId),
  );
};
