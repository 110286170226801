import {Text, TextRaw} from "@title-service/ui";
import React from "react";

import {CoverageType} from "../adminApi";
import {safeDisplayEnumFn} from "../util/enum";

export const unsafeDisplayCoverageType = (
  coverageType: CoverageType,
): string => {
  switch (coverageType) {
    case CoverageType.LenderStandard:
      return "Standard";
    case CoverageType.LenderExtended:
      return "Extended";
    case CoverageType.LenderExpanded:
      return "Expanded";
    case CoverageType.LenderShortForm:
      return "Short Form";
    case CoverageType.OwnerStandard:
      return "Standard";
    case CoverageType.OwnerExtended:
      return "Extended";
    case CoverageType.OwnerHomeowners:
      return "Homeowners";
    default:
      throw new Error(`Unknown Coverage Type: ${coverageType}`);
  }
};

export const displayCoverageType = safeDisplayEnumFn(unsafeDisplayCoverageType);

export const CoverageTypeComponentRaw: React.FC<{
  coverageType: CoverageType;
}> = ({coverageType}) => <TextRaw value={displayCoverageType(coverageType)} />;

export const CoverageTypeComponent: React.FC<
  Omit<React.ComponentProps<typeof Text>, "value"> & {
    coverageType: CoverageType;
  }
> = ({coverageType, ...props}) => (
  <Text {...props} value={displayCoverageType(coverageType)} />
);
