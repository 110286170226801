/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GetAuditsSortField = {
    DueDate: 'DUE_DATE',
    TargetStartDate: 'TARGET_START_DATE'
} as const;
export type GetAuditsSortField = typeof GetAuditsSortField[keyof typeof GetAuditsSortField];


export function GetAuditsSortFieldFromJSON(json: any): GetAuditsSortField {
    return GetAuditsSortFieldFromJSONTyped(json, false);
}

export function GetAuditsSortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAuditsSortField {
    return json as GetAuditsSortField;
}

export function GetAuditsSortFieldToJSON(value?: GetAuditsSortField | null): any {
    return value as any;
}

