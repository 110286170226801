import {Alert, Placeholder} from "@aws-amplify/ui-react";
import {QueryFunction, useQuery} from "@tanstack/react-query";
import {isLoadingNextQuery, Query} from "@title-service/react-query-utils";
import {
  FormikConnectedAmplifySubmitButton,
  FormikConnectedAmplifyTextField,
  FormikForm,
  HorizontalFieldset,
} from "@title-service/ui";
import {
  buildEmptyObjectForSchema,
  transformNullToUndefined,
} from "@title-service/yup-utils";
import {Formik, FormikHelpers, useField, useFormikContext} from "formik";
import React, {useCallback, useContext} from "react";
import {URLSearchParamsInit, useSearchParams} from "react-router-dom";
import * as Yup from "yup";

import {DependencyContext} from "../DependencyContext";
import {AddressSuggestion} from "../shared/addressAutocompleteApi";
import {
  SearchPropertiesRequest,
  SearchPropertiesResponse,
} from "../shared/adminApi";
import {AddressAutocomplete} from "../shared/components/AddressAutocomplete";
import {RouterLink} from "../shared/components/Link";
import {LoadingOverlay} from "../shared/components/LoadingOverlay";
import {QuantityComponent} from "../shared/components/Quantity";
import {
  AboveTableContainer,
  BodyRow,
  HeaderRow,
  Table,
  TBody,
  Td,
  Th,
  THead,
} from "../shared/components/tables/PrimaryTable";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
} from "../shared/layout";

type SearchPropertiesQueryKey = [
  "properties",
  "search",
  SearchPropertiesRequest,
];
export type SearchPropertiesQuery = Query<SearchPropertiesResponse>;

export const PropertySearchRoute: React.FC<{
  PropertySearchBodyContent?: typeof PropertySearchBodyContent;
  AddressForm?: typeof AddressForm;
}> = ({
  PropertySearchBodyContent:
    PropertySearchBodyContent_ = PropertySearchBodyContent,
  AddressForm: AddressForm_ = AddressForm,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const request = mapSearchParamsToSearchPropertiesRequest(searchParams);
  const {adminApi} = useContext(DependencyContext);
  const searchPropertiesQueryFn: QueryFunction<
    SearchPropertiesResponse,
    SearchPropertiesQueryKey
  > = useCallback(
    ({queryKey: [_1, _2, _request], signal}) =>
      adminApi.searchProperties(_request, signal),
    [adminApi],
  );
  const searchPropertiesQuery = useQuery({
    queryKey: ["properties", "search", request],
    queryFn: searchPropertiesQueryFn,
    keepPreviousData: true,
    enabled: !!request.street.trim(),
  });
  const handleChangeRequestState = useCallback(
    (
      updateRequest: (
        currentRequest: SearchPropertiesRequest,
      ) => SearchPropertiesRequest,
    ) => {
      setSearchParams((currentSearchParams) => {
        const currentRequest =
          mapSearchParamsToSearchPropertiesRequest(currentSearchParams);
        const updatedRequest = updateRequest(currentRequest);
        return mapSearchPropertiesRequestToSearchParams(updatedRequest);
      });
    },
    [setSearchParams],
  );
  return (
    <>
      <BodyHeader>
        <PrimaryBodyHeaderContainer>
          <PrimaryBodyHeader value="Property Search" />
        </PrimaryBodyHeaderContainer>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <AddressForm_
          request={request}
          searchPropertiesQuery={searchPropertiesQuery}
          onChangeRequest={handleChangeRequestState}
        />
      </BodyHeader>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <PropertySearchBodyContent_
        searchPropertiesQuery={searchPropertiesQuery}
      />
    </>
  );
};

type AddressFormData = Required<SearchPropertiesRequest>;

const buildInitialAddressFormData = ({
  street,
  unitValue,
  city,
  state,
}: SearchPropertiesRequest): AddressFormData => ({
  street,
  unitValue: unitValue ?? "",
  city: city ?? "",
  state: state ?? "",
});

export const ADDRESS_FORM_SCHEMA: Yup.ObjectSchema<SearchPropertiesRequest> =
  Yup.object({
    street: Yup.string()
      .required("Address is required")
      .trim()
      .transform(transformNullToUndefined),
    unitValue: Yup.string()
      .optional()
      .trim()
      .transform(transformNullToUndefined),
    city: Yup.string().optional().trim().transform(transformNullToUndefined),
    state: Yup.string().optional().trim().transform(transformNullToUndefined),
  });

export const AddressForm: React.FC<{
  request: SearchPropertiesRequest;
  searchPropertiesQuery: SearchPropertiesQuery;
  onChangeRequest: (
    updateRequest: (
      request: SearchPropertiesRequest,
    ) => SearchPropertiesRequest,
  ) => any;
  AddressAutocomplete?: typeof AddressAutocomplete;
}> = ({
  request,
  searchPropertiesQuery,
  onChangeRequest,
  AddressAutocomplete: AddressAutocomplete_ = AddressAutocomplete,
}) => {
  const submitHandler = useCallback(
    (
      formData: AddressFormData,
      {setSubmitting}: FormikHelpers<AddressFormData>,
    ) => {
      onChangeRequest(() => ({
        ...buildEmptyObjectForSchema(ADDRESS_FORM_SCHEMA),
        ...ADDRESS_FORM_SCHEMA.validateSync(formData),
      }));
      setSubmitting(false);
    },
    [onChangeRequest],
  );
  return (
    <Formik<AddressFormData>
      initialValues={buildInitialAddressFormData(request)}
      enableReinitialize={true}
      validationSchema={ADDRESS_FORM_SCHEMA as any}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <HorizontalFieldset>
          <FormikConnectedAddressAutocomplete
            testId="street-input"
            labelHidden={false}
            autoComplete="off"
            label="Street"
            minWidth="40ch"
            name="street"
            hasSearchButton={false}
            hasSearchIcon={false}
            AddressAutocomplete={AddressAutocomplete_}
          />
          <FormikConnectedAmplifyTextField
            testId="city-input"
            autoComplete="off"
            label="City"
            name="city"
            minWidth="25ch"
          />
          <FormikConnectedAmplifyTextField
            testId="unit-value-input"
            autoComplete="off"
            label="Unit Value"
            name="unitValue"
            minWidth="7ch"
          />
          <FormikConnectedAmplifyTextField
            testId="state-input"
            autoComplete="off"
            label="State"
            name="state"
            minWidth="5ch"
          />
        </HorizontalFieldset>

        <FormikConnectedAmplifySubmitButton
          size="small"
          loadingText="Searching..."
          isLoading={
            isLoadingNextQuery(searchPropertiesQuery) ||
            searchPropertiesQuery.isInitialLoading
          }
          value="Search"
        />
      </FormikForm>
    </Formik>
  );
};

type AddressAutocompleteProps = React.ComponentProps<
  typeof AddressAutocomplete
>;

const FormikConnectedAddressAutocomplete: React.FC<
  Omit<
    AddressAutocompleteProps,
    "name" | "value" | "onChange" | "onBlur" | "onAddressSelected"
  > &
    Required<Pick<AddressAutocompleteProps, "name">> & {
      AddressAutocomplete: typeof AddressAutocomplete;
    }
> = ({
  AddressAutocomplete: AddressAutocomplete_,
  ...addressAutocompleteProps
}) => {
  const {setValues} = useFormikContext<AddressFormData>();
  const handleAddressSelected = useCallback(
    (addressSuggestion?: AddressSuggestion) => {
      setValues(() => ({
        street: addressSuggestion?.street ?? "",
        unitValue: "",
        city: addressSuggestion?.city ?? "",
        state: addressSuggestion?.state ?? "",
      }));
    },
    [setValues],
  );
  const [fieldInput, fieldMeta] = useField(addressAutocompleteProps.name);
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <AddressAutocomplete_
      errorMessage={fieldMeta.error}
      hasError={!!fieldMeta.error && fieldMeta.touched}
      onAddressSelected={handleAddressSelected}
      {...fieldInput}
      {...addressAutocompleteProps}
    />
  );
};

export const PropertySearchBodyContent: React.FC<{
  searchPropertiesQuery: SearchPropertiesQuery;
  PropertySearchSuccessTableBody?: typeof PropertySearchSuccessTableBody;
  PropertySearchPlaceholderTableBody?: typeof PropertySearchPlaceholderTableBody;
}> = ({
  searchPropertiesQuery,
  searchPropertiesQuery: {data, error, isInitialLoading},
  PropertySearchSuccessTableBody:
    PropertySearchSuccessTableBody_ = PropertySearchSuccessTableBody,
  PropertySearchPlaceholderTableBody:
    PropertySearchPlaceholderTableBody_ = PropertySearchPlaceholderTableBody,
}) => {
  const isLoading = isLoadingNextQuery(searchPropertiesQuery);
  return (
    <BodyContent>
      <AboveTableContainer justifyContent="flex-start">
        {error ? (
          <Alert variation="error" isDismissible={false} hasIcon={true}>
            Failed to search properties
          </Alert>
        ) : data ? (
          <QuantityComponent
            quantity={data.properties.length}
            label={data.properties.length === 1 ? "Property" : "Properties"}
          />
        ) : null}
      </AboveTableContainer>
      <LoadingOverlay isActive={isLoading}>
        <Table>
          <THead>
            <HeaderRow>
              <Th>ATTOM ID</Th>
              <Th>Street</Th>
              <Th>Unit</Th>
              <Th>City</Th>
              <Th>County</Th>
              <Th>State</Th>
              <Th>Zip</Th>
            </HeaderRow>
          </THead>
          {data ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <PropertySearchSuccessTableBody_ response={data} />
          ) : error ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <PropertySearchPlaceholderTableBody_ isLoaded={true} />
          ) : isInitialLoading ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <PropertySearchPlaceholderTableBody_ isLoaded={false} />
          ) : (
            // eslint-disable-next-line react/jsx-pascal-case
            <PropertySearchPlaceholderTableBody_ isLoaded={true} />
          )}
        </Table>
      </LoadingOverlay>
    </BodyContent>
  );
};

export const PropertySearchPlaceholderTableBody: React.FC<{
  isLoaded: boolean;
}> = ({isLoaded}) => (
  <TBody>
    <BodyRow>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
    </BodyRow>
  </TBody>
);

export const PropertySearchSuccessTableBody: React.FC<{
  response: SearchPropertiesResponse;
}> = ({response: {properties}}) => (
  <TBody>
    {properties.map(
      ({attomId, street, unitValue, city, county, state, zipCode}) => (
        <BodyRow key={attomId}>
          <Td>
            <RouterLink to={`/properties/${attomId}`}>{attomId}</RouterLink>
          </Td>
          <Td>{street}</Td>
          <Td>{unitValue}</Td>
          <Td>{city}</Td>
          <Td>{county}</Td>
          <Td>{state}</Td>
          <Td>{zipCode}</Td>
        </BodyRow>
      ),
    )}
  </TBody>
);

export const mapSearchParamsToSearchPropertiesRequest = (
  searchParams: URLSearchParams,
): SearchPropertiesRequest => ({
  street: searchParams.get("street")?.trim() ?? "",
  unitValue: searchParams.get("unitValue")?.trim(),
  city: searchParams.get("city")?.trim(),
  state: searchParams.get("state")?.trim(),
});

export const mapSearchPropertiesRequestToSearchParams = ({
  street,
  unitValue,
  city,
  state,
}: SearchPropertiesRequest): URLSearchParamsInit => {
  const searchParams: URLSearchParamsInit = {};
  if (street) {
    searchParams.street = street;
  }
  if (unitValue) {
    searchParams.unitValue = unitValue;
  }
  if (city) {
    searchParams.city = city;
  }
  if (state) {
    searchParams.state = state;
  }
  return searchParams;
};
