import {
  FormikConnectedAmplifySelectField,
  Text,
  TextRaw,
} from "@title-service/ui";
import {enumKeys} from "@title-service/utils";
import React from "react";

import {PolicyType} from "../adminApi";
import {safeDisplayEnumFn} from "../util/enum";

export const unsafeDisplayPolicyType = (policyType: PolicyType): string => {
  switch (policyType) {
    case PolicyType.Lender:
      return "Lender";
    case PolicyType.Owner:
      return "Owner";
    default:
      throw new Error(`Unknown Policy Type: ${policyType}`);
  }
};

export const displayPolicyType = safeDisplayEnumFn(unsafeDisplayPolicyType);

export const PolicyTypeComponentRaw: React.FC<{policyType: PolicyType}> = ({
  policyType,
}) => <TextRaw value={displayPolicyType(policyType)} />;

export const PolicyTypeComponent: React.FC<
  Omit<React.ComponentProps<typeof Text>, "value"> & {policyType: PolicyType}
> = ({policyType, ...props}) => (
  <Text {...props} value={displayPolicyType(policyType)} />
);

export const PolicyTypeField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifySelectField>, "label">
> = (props) => (
  <FormikConnectedAmplifySelectField
    testId="policy-type-input"
    label="Policy Type (optional)"
    placeholder=""
    {...props}
  >
    <option value="" />
    {enumKeys(PolicyType).map((policyTypeKey) => {
      const policyType: PolicyType = PolicyType[policyTypeKey];
      return (
        <option key={policyType} value={policyType}>
          {displayPolicyType(policyType)}
        </option>
      );
    })}
  </FormikConnectedAmplifySelectField>
);
