/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AuditStatus = {
    NotStarted: 'not_started',
    InProgress: 'in_progress',
    Completed: 'completed'
} as const;
export type AuditStatus = typeof AuditStatus[keyof typeof AuditStatus];


export function AuditStatusFromJSON(json: any): AuditStatus {
    return AuditStatusFromJSONTyped(json, false);
}

export function AuditStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditStatus {
    return json as AuditStatus;
}

export function AuditStatusToJSON(value?: AuditStatus | null): any {
    return value as any;
}

