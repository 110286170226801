import {Text} from "@title-service/ui";
import React, {useContext, useEffect} from "react";

import {DependencyContext} from "../../DependencyContext";
import {BodyLoading} from "../components/BodyLoading";
import {BodyContent} from "../layout";

import {useAuthState} from "./AuthenticationContext";
import {AuthenticationStateType} from "./AuthenticationState";

export const EnsureAuthenticated: React.FC = ({children}) => {
  const {authenticationStrategy} = useContext(DependencyContext);
  const authenticationState = useAuthState();
  const requireAuthentication = authenticationStrategy.requireAuthentication();

  useEffect(() => {
    if (
      authenticationState.type === AuthenticationStateType.UNAUTHENTICATED &&
      requireAuthentication
    ) {
      authenticationStrategy.initiateSignIn();
    }
  }, [authenticationState.type, authenticationStrategy, requireAuthentication]);

  if (
    authenticationState.type === AuthenticationStateType.AUTHENTICATED ||
    !requireAuthentication
  ) {
    return <>{children}</>;
  } else if (
    authenticationState.type === AuthenticationStateType.UNAUTHENTICATED
  ) {
    return (
      <BodyContent>
        <Text value="Redirecting to login..." />
      </BodyContent>
    );
  }
  return <BodyLoading />;
};
