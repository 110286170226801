import {Flex, View} from "@aws-amplify/ui-react";
import React from "react";

export const SideBySideContainer: React.FC<
  React.ComponentProps<typeof Flex>
> = (props) => (
  <Flex direction="row" wrap="wrap" gap="medium" width="100%" {...props} />
);

export const buildSideBySideChildProps = (): Required<
  Pick<React.ComponentProps<typeof View>, "flex" | "minWidth" | "padding">
> => ({
  flex: 1,
  minWidth: 0,
  padding: 0,
});
