/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetAuditExportsSuccessResponseAuditExport } from './GetAuditExportsSuccessResponseAuditExport';
import {
    GetAuditExportsSuccessResponseAuditExportFromJSON,
    GetAuditExportsSuccessResponseAuditExportFromJSONTyped,
    GetAuditExportsSuccessResponseAuditExportToJSON,
} from './GetAuditExportsSuccessResponseAuditExport';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';

/**
 * 
 * @export
 * @interface GetAuditExportsSuccessResponse
 */
export interface GetAuditExportsSuccessResponse {
    /**
     * 
     * @type {Array<GetAuditExportsSuccessResponseAuditExport>}
     * @memberof GetAuditExportsSuccessResponse
     */
    auditExports: Array<GetAuditExportsSuccessResponseAuditExport>;
    /**
     * 
     * @type {Pagination}
     * @memberof GetAuditExportsSuccessResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the GetAuditExportsSuccessResponse interface.
 */
export function instanceOfGetAuditExportsSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "auditExports" in value;
    isInstance = isInstance && "pagination" in value;

    return isInstance;
}

export function GetAuditExportsSuccessResponseFromJSON(json: any): GetAuditExportsSuccessResponse {
    return GetAuditExportsSuccessResponseFromJSONTyped(json, false);
}

export function GetAuditExportsSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAuditExportsSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auditExports': ((json['auditExports'] as Array<any>).map(GetAuditExportsSuccessResponseAuditExportFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function GetAuditExportsSuccessResponseToJSON(value?: GetAuditExportsSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auditExports': ((value.auditExports as Array<any>).map(GetAuditExportsSuccessResponseAuditExportToJSON)),
        'pagination': PaginationToJSON(value.pagination),
    };
}

