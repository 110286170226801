/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { ClaimStatus } from './ClaimStatus';
import {
    ClaimStatusFromJSON,
    ClaimStatusFromJSONTyped,
    ClaimStatusToJSON,
} from './ClaimStatus';

/**
 * 
 * @export
 * @interface GetClaimsSuccessResponseClaim
 */
export interface GetClaimsSuccessResponseClaim {
    /**
     * 
     * @type {Address}
     * @memberof GetClaimsSuccessResponseClaim
     */
    address: Address;
    /**
     * 
     * @type {string}
     * @memberof GetClaimsSuccessResponseClaim
     */
    agencyName: string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimsSuccessResponseClaim
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimsSuccessResponseClaim
     */
    insuredName: string;
    /**
     * 
     * @type {ClaimStatus}
     * @memberof GetClaimsSuccessResponseClaim
     */
    status: ClaimStatus;
    /**
     * 
     * @type {Date}
     * @memberof GetClaimsSuccessResponseClaim
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the GetClaimsSuccessResponseClaim interface.
 */
export function instanceOfGetClaimsSuccessResponseClaim(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "agencyName" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "insuredName" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function GetClaimsSuccessResponseClaimFromJSON(json: any): GetClaimsSuccessResponseClaim {
    return GetClaimsSuccessResponseClaimFromJSONTyped(json, false);
}

export function GetClaimsSuccessResponseClaimFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetClaimsSuccessResponseClaim {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': AddressFromJSON(json['address']),
        'agencyName': json['agencyName'],
        'id': json['id'],
        'insuredName': json['insuredName'],
        'status': ClaimStatusFromJSON(json['status']),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function GetClaimsSuccessResponseClaimToJSON(value?: GetClaimsSuccessResponseClaim | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': AddressToJSON(value.address),
        'agencyName': value.agencyName,
        'id': value.id,
        'insuredName': value.insuredName,
        'status': ClaimStatusToJSON(value.status),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

