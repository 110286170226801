import {QueryFunction, useQuery, useQueryClient} from "@tanstack/react-query";
import {isLoadingNextQuery, Query} from "@title-service/react-query-utils";
import {
  DateComponent,
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
  Text,
} from "@title-service/ui";
import React, {useCallback, useContext, useMemo, useState} from "react";

import {DependencyContext} from "../../DependencyContext";
import {
  GetProducerJurisdictionsSuccessResponse,
  GetProducerJurisdictionsSuccessResponseJurisdiction,
} from "../adminApi";
import {LoadingOverlay} from "../components/LoadingOverlay";
import {Modal} from "../components/Modal";
import {PlusIcon} from "../components/icons";
import {
  BodyRow,
  HeaderRow,
  Table,
  TBody,
  Td,
  Th,
  THead,
} from "../components/tables/PrimaryTable";

import {CreateProducerAppointmentForm} from "./ProducerAppointmentSection";
import {buildProducerId, ProducerId} from "./ProducerId";
import {ProducerLink, ProducerTypeComponent} from "./ProducerType";

const MODAL_CONTENT_STYLE: React.CSSProperties = {
  minWidth: "650px",
};

type GetUnfulfilledAppointmentsQueryKey = ["unfulfilledAppointments"];
export type GetUnfulfilledAppointmentsQuery =
  Query<GetProducerJurisdictionsSuccessResponse>;

export const useGetUnfulfilledAppointments = (): {
  getUnfulfilledAppointmentsQuery: GetUnfulfilledAppointmentsQuery;
  onReloadUnfulfilledAppointments: () => any;
} => {
  const {adminApi} = useContext(DependencyContext);
  const queryClient = useQueryClient();
  const getUnfulfilledAppointmentsQueryKey: GetUnfulfilledAppointmentsQueryKey =
    useMemo(() => ["unfulfilledAppointments"], []);
  const getUnfulfilledAppointmentsQueryFn: QueryFunction<
    GetProducerJurisdictionsSuccessResponse,
    GetUnfulfilledAppointmentsQueryKey
  > = useCallback(
    ({queryKey: [_1], signal}) =>
      adminApi.getJurisdictionsWithUnfulfilledAppointments(signal),
    [adminApi],
  );
  const getUnfulfilledAppointmentsQuery = useQuery({
    keepPreviousData: true,
    queryKey: getUnfulfilledAppointmentsQueryKey,
    queryFn: getUnfulfilledAppointmentsQueryFn,
  });
  const onReloadUnfulfilledAppointments = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: getUnfulfilledAppointmentsQueryKey,
      }),
    [queryClient, getUnfulfilledAppointmentsQueryKey],
  );
  return {
    getUnfulfilledAppointmentsQuery,
    onReloadUnfulfilledAppointments,
  };
};

export const UnfulfilledAppointmentsSection: React.FC<{
  UnfulfilledAppointmentsSectionQueryResult?: typeof UnfulfilledAppointmentsSectionQueryResult;
}> = ({
  UnfulfilledAppointmentsSectionQueryResult:
    UnfulfilledAppointmentsSectionQueryResult_ = UnfulfilledAppointmentsSectionQueryResult,
}) => {
  const {getUnfulfilledAppointmentsQuery, onReloadUnfulfilledAppointments} =
    useGetUnfulfilledAppointments();
  return (
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Unfulfilled Appointments" />
      </SecondarySectionHeaderContainer>
      <SecondarySectionContent>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <UnfulfilledAppointmentsSectionQueryResult_
          getUnfulfilledAppointmentsQuery={getUnfulfilledAppointmentsQuery}
          onReloadUnfulfilledAppointments={onReloadUnfulfilledAppointments}
        />
      </SecondarySectionContent>
    </SecondarySection>
  );
};

export const UnfulfilledAppointmentsSectionQueryResult: React.FC<{
  getUnfulfilledAppointmentsQuery: GetUnfulfilledAppointmentsQuery;
  onReloadUnfulfilledAppointments: () => any;
  UnfulfilledAppointmentsSectionSuccessComponent?: typeof UnfulfilledAppointmentsSectionSuccessComponent;
  UnfulfilledAppointmentsSectionFailureComponent?: typeof UnfulfilledAppointmentsSectionFailureComponent;
  UnfulfilledAppointmentsSectionLoadingComponent?: typeof UnfulfilledAppointmentsSectionLoadingComponent;
}> = ({
  getUnfulfilledAppointmentsQuery,
  getUnfulfilledAppointmentsQuery: {data, error, isInitialLoading},
  onReloadUnfulfilledAppointments,
  UnfulfilledAppointmentsSectionSuccessComponent:
    UnfulfilledAppointmentsSectionSuccessComponent_ = UnfulfilledAppointmentsSectionSuccessComponent,
  UnfulfilledAppointmentsSectionFailureComponent:
    UnfulfilledAppointmentsSectionFailureComponent_ = UnfulfilledAppointmentsSectionFailureComponent,
  UnfulfilledAppointmentsSectionLoadingComponent:
    UnfulfilledAppointmentsSectionLoadingComponent_ = UnfulfilledAppointmentsSectionLoadingComponent,
}) => {
  const isLoading = isLoadingNextQuery(getUnfulfilledAppointmentsQuery);
  return (
    <LoadingOverlay isActive={isLoading}>
      {data ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <UnfulfilledAppointmentsSectionSuccessComponent_
          response={data}
          onReloadUnfulfilledAppointments={onReloadUnfulfilledAppointments}
        />
      ) : error ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <UnfulfilledAppointmentsSectionFailureComponent_ />
      ) : (
        isInitialLoading && (
          // eslint-disable-next-line react/jsx-pascal-case
          <UnfulfilledAppointmentsSectionLoadingComponent_ />
        )
      )}
    </LoadingOverlay>
  );
};

export const UnfulfilledAppointmentsSectionLoadingComponent: React.FC = () => (
  <Text value="Loading..." />
);

export const UnfulfilledAppointmentsSectionFailureComponent: React.FC = () => (
  <Text value="Failed to load." />
);

export const UnfulfilledAppointmentsSectionSuccessComponent: React.FC<{
  response: GetProducerJurisdictionsSuccessResponse;
  onReloadUnfulfilledAppointments: () => any;
}> = ({response: {jurisdictions}, onReloadUnfulfilledAppointments}) => {
  if (!jurisdictions.length) {
    return <Text value="All appointments are fulfilled" />;
  }

  return (
    <Table width="100%">
      <THead>
        <HeaderRow>
          <Th>Producer Type</Th>
          <Th>Name</Th>
          <Th>License No.</Th>
          <Th>Jurisdiction</Th>
          <Th>License Created On</Th>
        </HeaderRow>
      </THead>
      <TBody>
        {jurisdictions.map((jurisdiction) => (
          <UnfulfilledAppointmentsSectionItemRow
            key={jurisdiction.id}
            jurisdiction={jurisdiction}
            onReloadUnfulfilledAppointments={onReloadUnfulfilledAppointments}
          />
        ))}
      </TBody>
    </Table>
  );
};

export const UnfulfilledAppointmentsSectionItemRow: React.FC<{
  ariaHideApp?: boolean;
  jurisdiction: GetProducerJurisdictionsSuccessResponseJurisdiction;
  onReloadUnfulfilledAppointments: () => any;
  CreateProducerAppointmentForm?: typeof CreateProducerAppointmentForm;
}> = ({
  ariaHideApp,
  jurisdiction: {
    id,
    jurisdiction,
    mostRecentLicense,
    producerName,
    producerId,
    producerType,
  },
  onReloadUnfulfilledAppointments,
  CreateProducerAppointmentForm:
    CreateProducerAppointmentForm_ = CreateProducerAppointmentForm,
}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);
  const mappedProducerId: ProducerId = useMemo(
    () => buildProducerId(producerType, producerId),
    [producerType, producerId],
  );
  return (
    <BodyRow>
      <Td>
        <ProducerTypeComponent producerType={producerType} />
      </Td>
      <Td>
        <ProducerLink
          producerType={producerType}
          producerId={producerId}
          producerName={producerName}
        />
      </Td>
      <Td>
        <Text value={mostRecentLicense!.licenseNumber} />
      </Td>
      <Td>
        <Text value={jurisdiction} />
      </Td>
      <Td>
        <DateComponent value={mostRecentLicense!.createdAt} />
      </Td>
      <Td>
        <PlusIcon data-testid="create-appointment-button" onClick={openForm} />
        <Modal
          ariaHideApp={ariaHideApp}
          contentStyle={MODAL_CONTENT_STYLE}
          isOpen={displayForm}
          onClose={closeForm}
          title="Create Appointment"
        >
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <CreateProducerAppointmentForm_
            jurisdictionId={id}
            producerId={mappedProducerId}
            onClose={closeForm}
            onSubmitComplete={onReloadUnfulfilledAppointments}
          />
        </Modal>
      </Td>
    </BodyRow>
  );
};
