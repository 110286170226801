/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RemittanceType } from './RemittanceType';
import {
    RemittanceTypeFromJSON,
    RemittanceTypeFromJSONTyped,
    RemittanceTypeToJSON,
} from './RemittanceType';

import {
     RemittanceItemCplFromJSONTyped,
     RemittanceItemEndorsementFromJSONTyped,
     RemittanceItemFeeFromJSONTyped,
     RemittanceItemPolicyFromJSONTyped
} from './';

/**
 * 
 * @export
 * @interface RemittanceItem
 */
export interface RemittanceItem {
    /**
     * 
     * @type {number}
     * @memberof RemittanceItem
     */
    amount: number;
    /**
     * 
     * @type {RemittanceType}
     * @memberof RemittanceItem
     */
    type: RemittanceType;
}

/**
 * Check if a given object implements the RemittanceItem interface.
 */
export function instanceOfRemittanceItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function RemittanceItemFromJSON(json: any): RemittanceItem {
    return RemittanceItemFromJSONTyped(json, false);
}

export function RemittanceItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemittanceItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['type'] === 'cpl') {
            return RemittanceItemCplFromJSONTyped(json, true);
        }
        if (json['type'] === 'endorsement') {
            return RemittanceItemEndorsementFromJSONTyped(json, true);
        }
        if (json['type'] === 'fee') {
            return RemittanceItemFeeFromJSONTyped(json, true);
        }
        if (json['type'] === 'policy') {
            return RemittanceItemPolicyFromJSONTyped(json, true);
        }
    }
    return {
        
        'amount': json['amount'],
        'type': RemittanceTypeFromJSON(json['type']),
    };
}

export function RemittanceItemToJSON(value?: RemittanceItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'type': RemittanceTypeToJSON(value.type),
    };
}

