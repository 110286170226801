/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValidationReportDetailComparisonAssessment } from './ValidationReportDetailComparisonAssessment';
import {
    ValidationReportDetailComparisonAssessmentFromJSON,
    ValidationReportDetailComparisonAssessmentFromJSONTyped,
    ValidationReportDetailComparisonAssessmentToJSON,
} from './ValidationReportDetailComparisonAssessment';
import type { ValidationReportDetailComparisonFeatures } from './ValidationReportDetailComparisonFeatures';
import {
    ValidationReportDetailComparisonFeaturesFromJSON,
    ValidationReportDetailComparisonFeaturesFromJSONTyped,
    ValidationReportDetailComparisonFeaturesToJSON,
} from './ValidationReportDetailComparisonFeatures';
import type { ValidationReportDetailComparisonFigures } from './ValidationReportDetailComparisonFigures';
import {
    ValidationReportDetailComparisonFiguresFromJSON,
    ValidationReportDetailComparisonFiguresFromJSONTyped,
    ValidationReportDetailComparisonFiguresToJSON,
} from './ValidationReportDetailComparisonFigures';
import type { ValidationReportDetailComparisonStatistics } from './ValidationReportDetailComparisonStatistics';
import {
    ValidationReportDetailComparisonStatisticsFromJSON,
    ValidationReportDetailComparisonStatisticsFromJSONTyped,
    ValidationReportDetailComparisonStatisticsToJSON,
} from './ValidationReportDetailComparisonStatistics';

/**
 * 
 * @export
 * @interface ValidationReportDetailComparison
 */
export interface ValidationReportDetailComparison {
    /**
     * 
     * @type {ValidationReportDetailComparisonAssessment}
     * @memberof ValidationReportDetailComparison
     */
    assessment: ValidationReportDetailComparisonAssessment;
    /**
     * 
     * @type {ValidationReportDetailComparisonFeatures}
     * @memberof ValidationReportDetailComparison
     */
    features: ValidationReportDetailComparisonFeatures;
    /**
     * 
     * @type {ValidationReportDetailComparisonFigures}
     * @memberof ValidationReportDetailComparison
     */
    figures: ValidationReportDetailComparisonFigures;
    /**
     * 
     * @type {string}
     * @memberof ValidationReportDetailComparison
     */
    params: string;
    /**
     * 
     * @type {ValidationReportDetailComparisonStatistics}
     * @memberof ValidationReportDetailComparison
     */
    statistics: ValidationReportDetailComparisonStatistics;
}

/**
 * Check if a given object implements the ValidationReportDetailComparison interface.
 */
export function instanceOfValidationReportDetailComparison(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "assessment" in value;
    isInstance = isInstance && "features" in value;
    isInstance = isInstance && "figures" in value;
    isInstance = isInstance && "params" in value;
    isInstance = isInstance && "statistics" in value;

    return isInstance;
}

export function ValidationReportDetailComparisonFromJSON(json: any): ValidationReportDetailComparison {
    return ValidationReportDetailComparisonFromJSONTyped(json, false);
}

export function ValidationReportDetailComparisonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationReportDetailComparison {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assessment': ValidationReportDetailComparisonAssessmentFromJSON(json['assessment']),
        'features': ValidationReportDetailComparisonFeaturesFromJSON(json['features']),
        'figures': ValidationReportDetailComparisonFiguresFromJSON(json['figures']),
        'params': json['params'],
        'statistics': ValidationReportDetailComparisonStatisticsFromJSON(json['statistics']),
    };
}

export function ValidationReportDetailComparisonToJSON(value?: ValidationReportDetailComparison | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assessment': ValidationReportDetailComparisonAssessmentToJSON(value.assessment),
        'features': ValidationReportDetailComparisonFeaturesToJSON(value.features),
        'figures': ValidationReportDetailComparisonFiguresToJSON(value.figures),
        'params': value.params,
        'statistics': ValidationReportDetailComparisonStatisticsToJSON(value.statistics),
    };
}

