import {transformNullToUndefined} from "@title-service/yup-utils";
import {useField, useFormikContext} from "formik";
import React, {useCallback} from "react";
import * as Yup from "yup";

import {JurisdictionReference} from "../adminApi";

import {JurisdictionAutocomplete} from "./JurisdictionAutocomplete";

export type JurisdictionFormData = JurisdictionReference & {id: string};

export const buildJurisdictionFormInitialData = (
  data: Partial<JurisdictionFormData> = {},
): JurisdictionFormData => ({
  id: "",
  name: "",
  ...data,
});

export const buildRequiredJurisdictionFormSchema =
  (): Yup.ObjectSchema<JurisdictionReference> =>
    Yup.object({
      id: Yup.string()
        .trim()
        .transform(transformNullToUndefined)
        .required("Please select a Jurisdiction from the list"),
      name: Yup.string()
        .trim()
        .transform(transformNullToUndefined)
        .required("Jurisdiction name is required"),
    });

type JurisdictionAutocompleteProps = React.ComponentProps<
  typeof JurisdictionAutocomplete
>;

export const JurisdictionField = <
  TJurisdictionName extends string,
  TFormData extends {[k in TJurisdictionName]: JurisdictionFormData},
>({
  name,
  JurisdictionAutocomplete:
    JurisdictionAutocomplete_ = JurisdictionAutocomplete,
  ...jurisdictionAutocompleteProps
}: Omit<
  JurisdictionAutocompleteProps,
  "name" | "value" | "onChange" | "onBlur" | "onJurisdictionSelected" | "limit"
> &
  Partial<Pick<JurisdictionAutocompleteProps, "limit" | "label">> & {
    name: TJurisdictionName;
    JurisdictionAutocomplete?: typeof JurisdictionAutocomplete;
  }) => {
  const {setValues} = useFormikContext<TFormData>();
  const [, jurisdictionIdFieldMeta] = useField(`${name}.id`);
  const [
    {onChange: onChangeJurisdictionName, ...jurisdictionNameFieldInput},
    jurisdictionNameFieldMeta,
  ] = useField(`${name}.name`);
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValues((currentFormValues) => ({
        ...currentFormValues,
        [name]: {
          ...currentFormValues[name],
          id: "",
        },
      }));
      onChangeJurisdictionName(e);
    },
    [name, onChangeJurisdictionName, setValues],
  );
  const handleJurisdictionSelected = useCallback(
    (jurisdictionReference?: JurisdictionReference) => {
      setValues((currentFormValues) => ({
        ...currentFormValues,
        [name]: {
          id: jurisdictionReference?.name ?? "",
          name: jurisdictionReference?.name ?? "",
        },
      }));
    },
    [name, setValues],
  );
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <JurisdictionAutocomplete_
      labelHidden={false}
      autoComplete="off"
      hasSearchButton={false}
      hasSearchIcon={false}
      errorMessage={jurisdictionIdFieldMeta.error}
      hasError={
        !!jurisdictionIdFieldMeta.error && jurisdictionNameFieldMeta.touched
      }
      onJurisdictionSelected={handleJurisdictionSelected}
      onChange={handleChange}
      limit={5}
      {...jurisdictionNameFieldInput}
      {...jurisdictionAutocompleteProps}
    />
  );
};
