/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InsurfulOrderResult } from './InsurfulOrderResult';
import {
    InsurfulOrderResultFromJSON,
    InsurfulOrderResultFromJSONTyped,
    InsurfulOrderResultToJSON,
} from './InsurfulOrderResult';

/**
 * 
 * @export
 * @interface InsurfulOrderResultPending
 */
export interface InsurfulOrderResultPending extends InsurfulOrderResult {
}

/**
 * Check if a given object implements the InsurfulOrderResultPending interface.
 */
export function instanceOfInsurfulOrderResultPending(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InsurfulOrderResultPendingFromJSON(json: any): InsurfulOrderResultPending {
    return InsurfulOrderResultPendingFromJSONTyped(json, false);
}

export function InsurfulOrderResultPendingFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsurfulOrderResultPending {
    return json;
}

export function InsurfulOrderResultPendingToJSON(value?: InsurfulOrderResultPending | null): any {
    return value;
}

