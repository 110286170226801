import {Icon} from "@title-service/ui";
import React from "react";
import styled from "styled-components";
import * as uuid from "uuid";

export enum CaretDirection {
  UP,
  DOWN,
  RIGHT,
  LEFT,
}

const getRotation = (direction: CaretDirection): string => {
  switch (direction) {
    case CaretDirection.UP:
      return "180deg";
    case CaretDirection.RIGHT:
      return "-90deg";
    case CaretDirection.LEFT:
      return "90deg";
    case CaretDirection.DOWN:
      return "0deg";
  }
};

const PATH_1_ID = uuid.v4();

const VIEW_BOX: React.ComponentProps<typeof Icon>["viewBox"] = {
  width: 12,
  height: 8,
};

const CaretSvgStyle = styled(Icon)<{direction: CaretDirection}>(({
  direction,
}) => {
  const rotation = getRotation(direction);

  return {
    transform: `rotate(${rotation})`,
  };
});

export const CaretIcon: React.FC<
  React.ComponentProps<typeof Icon> & {direction: CaretDirection}
> = ({direction, ...props}) => (
  <CaretSvgStyle viewBox={VIEW_BOX} direction={direction} {...props}>
    <defs>
      <polygon
        id={PATH_1_ID}
        points="2 8.608 6.97087379 4 2 -0.584 3.52750809 -2 10 4 3.52750809 10"
      />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2.000000, -4.000000)">
        <g transform="translate(2.000000, 4.000000)">
          <mask fill="white">
            <use href={`#${PATH_1_ID}`} />
          </mask>
          <use
            fill="currentColor"
            fillRule="nonzero"
            transform="translate(6.000000, 4.000000) rotate(-270.00) translate(-6.000000, -4.000000)"
            href={`#${PATH_1_ID}`}
          />
        </g>
      </g>
    </g>
  </CaretSvgStyle>
);
