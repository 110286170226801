/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GetProducersExpiringType = {
    License: 'license',
    Appointment: 'appointment'
} as const;
export type GetProducersExpiringType = typeof GetProducersExpiringType[keyof typeof GetProducersExpiringType];


export function GetProducersExpiringTypeFromJSON(json: any): GetProducersExpiringType {
    return GetProducersExpiringTypeFromJSONTyped(json, false);
}

export function GetProducersExpiringTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProducersExpiringType {
    return json as GetProducersExpiringType;
}

export function GetProducersExpiringTypeToJSON(value?: GetProducersExpiringType | null): any {
    return value as any;
}

