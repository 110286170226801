import {Button, Flex, Placeholder, SelectField} from "@aws-amplify/ui-react";
import {QueryFunction, useMutation, useQuery} from "@tanstack/react-query";
import {isLoadingNextQuery, Query} from "@title-service/react-query-utils";
import {DateTimeComponent} from "@title-service/ui";
import {
  enumValues,
  notNullOrUndefined,
  parseBoolean,
  parseWholeNumber,
} from "@title-service/utils";
import React, {useCallback, useContext} from "react";
import {
  URLSearchParamsInit,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import {DependencyContext} from "../DependencyContext";
import {
  GetPropertyDocumentsRequest,
  GetPropertyDocumentsSortField,
  GetPropertyDocumentsSuccessResponse,
  parseSortDirection,
  PropertyDocumentExtractionStatus,
  SortDirection,
} from "../shared/adminApi";
import {MultiLineAddressComponent} from "../shared/components/Address";
import {RouterLink} from "../shared/components/Link";
import {LoadingOverlay} from "../shared/components/LoadingOverlay";
import {MaybeNotAvailable} from "../shared/components/Nullable";
import {PageNumbers} from "../shared/components/Pagination";
import {QuantityComponent} from "../shared/components/Quantity";
import {
  AboveTableContainer,
  BodyRow,
  HeaderRow,
  Table,
  TBody,
  Td,
  Th,
  THead,
} from "../shared/components/tables/PrimaryTable";
import {SortableTh} from "../shared/components/tables/SortableTh";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
} from "../shared/layout";
import {PropertyDocumentFlagButton} from "../shared/propertyDocuments/PropertyDocumentFlagButton";
import {PropertyDocumentStatusPill} from "../shared/propertyDocuments/PropertyDocumentStatusPill";

type GetPropertyDocumentsQueryKey = [
  "propertyDocuments",
  "search",
  GetPropertyDocumentsRequest,
];

export type GetPropertyDocumentsQuery =
  Query<GetPropertyDocumentsSuccessResponse>;

export const PropertyDocumentsRoute: React.FC<{
  GetPropertyDocumentsBodyContent?: typeof GetPropertyDocumentsBodyContent;
}> = ({
  GetPropertyDocumentsBodyContent:
    GetPropertyDocumentsBodyContent_ = GetPropertyDocumentsBodyContent,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const request = mapSearchParamsToGetPropertyDocumentsRequest(searchParams);
  const {adminApi} = useContext(DependencyContext);
  const getPropertyDocumentsQueryFn: QueryFunction<
    GetPropertyDocumentsSuccessResponse,
    GetPropertyDocumentsQueryKey
  > = useCallback(
    ({queryKey: [_1, _2, _request], signal}) =>
      adminApi.getPropertyDocuments(_request, signal),
    [adminApi],
  );
  const getPropertyDocumentsQuery = useQuery({
    queryKey: ["propertyDocuments", "search", request],
    keepPreviousData: true,
    queryFn: getPropertyDocumentsQueryFn,
  });
  const handleChangeRequestState = useCallback(
    (
      updateRequest: (
        currentRequest: GetPropertyDocumentsRequest,
      ) => GetPropertyDocumentsRequest,
    ) => {
      setSearchParams((currentSearchParams) => {
        const currentRequest =
          mapSearchParamsToGetPropertyDocumentsRequest(currentSearchParams);
        const updatedRequest = updateRequest(currentRequest);
        return mapGetPropertyDocumentsRequestToSearchParams(updatedRequest);
      });
    },
    [setSearchParams],
  );

  return (
    <>
      <BodyHeader>
        <PrimaryBodyHeaderContainer>
          <PrimaryBodyHeader value="Property Documents" />
        </PrimaryBodyHeaderContainer>
      </BodyHeader>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <GetPropertyDocumentsBodyContent_
        request={request}
        getPropertyDocumentsQuery={getPropertyDocumentsQuery}
        onChangeRequest={handleChangeRequestState}
      />
    </>
  );
};

export const GetPropertyDocumentsBodyContent: React.FC<{
  request: GetPropertyDocumentsRequest;
  getPropertyDocumentsQuery: GetPropertyDocumentsQuery;
  onChangeRequest: (
    updateRequest: (
      request: GetPropertyDocumentsRequest,
    ) => GetPropertyDocumentsRequest,
  ) => any;
  ViewInSequence?: typeof ViewInSequence;
  PropertyDocumentsPageSuccessTableBody?: typeof PropertyDocumentsPageSuccessTableBody;
  PropertyDocumentsPagePlaceholderTableBody?: typeof PropertyDocumentsPagePlaceholderTableBody;
  PageNumbers?: typeof PageNumbers;
}> = ({
  getPropertyDocumentsQuery,
  getPropertyDocumentsQuery: {data, error, isInitialLoading},
  request,
  onChangeRequest,
  ViewInSequence: ViewInSequence_ = ViewInSequence,
  PropertyDocumentsPageSuccessTableBody:
    PropertyDocumentsPageSuccessTableBody_ = PropertyDocumentsPageSuccessTableBody,
  PropertyDocumentsPagePlaceholderTableBody:
    PropertyDocumentsPagePlaceholderTableBody_ = PropertyDocumentsPagePlaceholderTableBody,
  PageNumbers: PageNumbers_ = PageNumbers,
}) => {
  const isLoading = isLoadingNextQuery(getPropertyDocumentsQuery);
  const handleSort = useCallback(
    (
      sortField: GetPropertyDocumentsSortField,
      sortDirection: SortDirection,
    ) => {
      onChangeRequest((currentRequest) => ({
        ...currentRequest,
        sortField,
        sortDirection,
        page: 0,
      }));
    },
    [onChangeRequest],
  );
  const handlePageChange = useCallback(
    (zeroIndexedCurrentPageNumber) => {
      onChangeRequest((currentRequest) => ({
        ...currentRequest,
        page: zeroIndexedCurrentPageNumber,
      }));
    },
    [onChangeRequest],
  );
  const handleFilterLegalDescriptionStatus = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onChangeRequest(
        ({
          legalDescriptionStatus,
          ...currentRequestWithoutLegalDescriptionStatus
        }) => ({
          ...currentRequestWithoutLegalDescriptionStatus,
          legalDescriptionStatus:
            mapSearchParamToPropertyDocumentExtractionStatus(
              event.target.value,
            ),
          page: 0,
        }),
      );
    },
    [onChangeRequest],
  );
  const handleFilterVestingInformationStatus = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onChangeRequest(
        ({
          vestingInformationStatus,
          ...currentRequestWithoutVestingInformationStatus
        }) => ({
          ...currentRequestWithoutVestingInformationStatus,
          vestingInformationStatus:
            mapSearchParamToPropertyDocumentExtractionStatus(
              event.target.value,
            ),
          page: 0,
        }),
      );
    },
    [onChangeRequest],
  );
  const handleFilterAddressState = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onChangeRequest(({addressState, ...currentWithoutAddressState}) => ({
        ...currentWithoutAddressState,
        addressState: event.target.value.trim() || undefined,
        page: 0,
      }));
    },
    [onChangeRequest],
  );
  const handleFlagClick = useCallback(() => {
    onChangeRequest(({flagged, ...currentRequestWithoutFlagged}) => ({
      ...currentRequestWithoutFlagged,
      flagged: flagged ? undefined : true,
      page: 0,
    }));
  }, [onChangeRequest]);
  const handleClearFilters = useCallback(() => {
    onChangeRequest(({sortField, sortDirection, pageSize}) => ({
      sortField,
      sortDirection,
      pageSize,
      page: 0,
    }));
  }, [onChangeRequest]);
  const disableFormElements =
    isLoading || getPropertyDocumentsQuery.isInitialLoading;

  return (
    <BodyContent>
      <AboveTableContainer justifyContent="space-between">
        <Flex direction="row" justifyContent="flex-start" alignItems="center">
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <ViewInSequence_
            request={request}
            disabled={
              disableFormElements || !(data && data.pagination.totalRecords > 0)
            }
          />
          {data ? (
            <QuantityComponent
              quantity={data.pagination.totalRecords}
              label={
                data.pagination.totalRecords === 1 ? "Document" : "Documents"
              }
            />
          ) : null}
        </Flex>
        <Flex direction="row" justifyContent="flex-end" alignItems="center">
          <SelectField
            testId="legal-description-status-select"
            label="Legal Description Status"
            variation="quiet"
            onChange={handleFilterLegalDescriptionStatus}
            value={request.legalDescriptionStatus ?? ""}
            isDisabled={disableFormElements}
            placeholder="All"
          >
            <option value={PropertyDocumentExtractionStatus.NotStarted}>
              Not Started
            </option>
            <option value={PropertyDocumentExtractionStatus.InProgress}>
              In Progress
            </option>
            <option value={PropertyDocumentExtractionStatus.Approved}>
              Approved
            </option>
            <option value={PropertyDocumentExtractionStatus.Rejected}>
              Rejected
            </option>
          </SelectField>
          <SelectField
            testId="vesting-information-status-select"
            label="Vesting Status"
            variation="quiet"
            onChange={handleFilterVestingInformationStatus}
            value={request.vestingInformationStatus ?? ""}
            isDisabled={disableFormElements}
            placeholder="All"
          >
            <option value={PropertyDocumentExtractionStatus.NotStarted}>
              Not Started
            </option>
            <option value={PropertyDocumentExtractionStatus.InProgress}>
              In Progress
            </option>
            <option value={PropertyDocumentExtractionStatus.Approved}>
              Approved
            </option>
            <option value={PropertyDocumentExtractionStatus.Rejected}>
              Rejected
            </option>
          </SelectField>
          <SelectField
            testId="address-state-select"
            label="State"
            variation="quiet"
            onChange={handleFilterAddressState}
            value={request.addressState ?? ""}
            isDisabled={disableFormElements}
            placeholder="All"
          >
            {data
              ? getSortedStateFilterOptions(data.stateFilterOptions).map(
                  (state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ),
                )
              : null}
          </SelectField>
          <PropertyDocumentFlagButton
            size="large"
            onClick={handleFlagClick}
            active={!!request.flagged}
            testId="flag-button"
            isDisabled={disableFormElements}
          />
          <Button
            variation="link"
            testId="clear-filters"
            onClick={handleClearFilters}
            isDisabled={disableFormElements}
          >
            Clear Filters
          </Button>
        </Flex>
      </AboveTableContainer>
      <LoadingOverlay isActive={isLoading}>
        <Table>
          <THead>
            <HeaderRow>
              <Th>Document Number</Th>
              <Th>Address</Th>
              <SortableTh
                data-testid="legal-description-status-th"
                field={GetPropertyDocumentsSortField.Ldstatus}
                fieldInitialSortDirection={SortDirection.Desc}
                currentSortDirection={request.sortDirection}
                currentSortField={request.sortField}
                onSort={handleSort}
              >
                Legal Description Status
              </SortableTh>
              <SortableTh
                data-testid="vesting-information-status-th"
                field={GetPropertyDocumentsSortField.Vistatus}
                fieldInitialSortDirection={SortDirection.Desc}
                currentSortDirection={request.sortDirection}
                currentSortField={request.sortField}
                onSort={handleSort}
              >
                Vesting Status
              </SortableTh>
              <SortableTh
                data-testid="created-at-th"
                field={GetPropertyDocumentsSortField.CreatedAt}
                fieldInitialSortDirection={SortDirection.Desc}
                currentSortDirection={request.sortDirection}
                currentSortField={request.sortField}
                onSort={handleSort}
              >
                Created At
              </SortableTh>
              <Th>Linked Order</Th>
            </HeaderRow>
          </THead>

          {data ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <PropertyDocumentsPageSuccessTableBody_ response={data} />
          ) : error ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <PropertyDocumentsPagePlaceholderTableBody_ isLoaded={true} />
          ) : (
            isInitialLoading && (
              // eslint-disable-next-line react/jsx-pascal-case
              <PropertyDocumentsPagePlaceholderTableBody_ isLoaded={false} />
            )
          )}
        </Table>
      </LoadingOverlay>
      {data ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <PageNumbers_
          totalPages={data.pagination.totalPages}
          onChange={handlePageChange}
          zeroIndexedCurrentPageNumber={data.pagination.currentPage}
          isDisabled={disableFormElements}
        />
      ) : null}
    </BodyContent>
  );
};

export type GetPropertyDocumentsRequestDefaults = Pick<
  GetPropertyDocumentsRequest,
  "page" | "pageSize" | "sortField" | "sortDirection"
>;

export const GET_PROPERTY_DOCUMENTS_REQUEST_QUERY_DEFAULTS: GetPropertyDocumentsRequestDefaults =
  {
    sortField: GetPropertyDocumentsSortField.Ldstatus,
    sortDirection: SortDirection.Desc,
    page: 0,
    pageSize: 10,
  };

const getSortedStateFilterOptions = (
  stateFilterOptions: Set<string>,
): string[] => {
  const strings = Array.from(stateFilterOptions);
  strings.sort();
  return strings;
};

type GetSequenceResponse = {
  sequenceId: string;
  ids: string[];
};

export const ViewInSequence: React.FC<{
  disabled: boolean;
  request: GetPropertyDocumentsRequest;
}> = ({
  disabled,
  request: {page, pageSize, ...getPropertyDocumentIdsRequest},
}) => {
  const {adminApi, propertyDocumentSearchSequenceRepository} =
    useContext(DependencyContext);
  const getPropertyDocumentIdsMutationFn = useCallback(
    async (...args: Parameters<typeof adminApi.getPropertyDocumentIds>) => {
      const {ids} = await adminApi.getPropertyDocumentIds(...args);
      const sequenceId =
        await propertyDocumentSearchSequenceRepository.storeSequence(ids);
      return {sequenceId, ids};
    },
    [adminApi, propertyDocumentSearchSequenceRepository],
  );
  const getPropertyDocumentIdsMutation = useMutation({
    mutationFn: getPropertyDocumentIdsMutationFn,
  });
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    getPropertyDocumentIdsMutation.mutate(getPropertyDocumentIdsRequest, {
      onSuccess: ({ids, sequenceId}) => {
        if (ids.length > 0) {
          const startIndex = page * pageSize;
          navigate(buildFirstInSequenceUrl(startIndex, {ids, sequenceId}));
        }
      },
    });
  }, [
    getPropertyDocumentIdsMutation,
    getPropertyDocumentIdsRequest,
    navigate,
    page,
    pageSize,
  ]);

  return (
    <Button
      variation="primary"
      data-testid="view-in-sequence-button"
      onClick={handleClick}
      isDisabled={disabled}
      isLoading={getPropertyDocumentIdsMutation.isLoading}
      loadingText="View in Sequence"
    >
      View in Sequence
    </Button>
  );
};

const buildFirstInSequenceUrl = (
  startIndex: number,
  {ids, sequenceId}: GetSequenceResponse,
): string => {
  const index = startIndex > ids.length - 1 ? 0 : startIndex;
  return `/documents/${ids[index]}?sequenceId=${sequenceId}`;
};

export const PropertyDocumentsPagePlaceholderTableBody: React.FC<{
  isLoaded: boolean;
}> = ({isLoaded}) => (
  <TBody>
    <BodyRow>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
    </BodyRow>
  </TBody>
);

export const PropertyDocumentsPageSuccessTableBody: React.FC<{
  response: GetPropertyDocumentsSuccessResponse;
}> = ({response: {documents}}) => {
  if (!documents.length) {
    return (
      <TBody>
        <BodyRow>
          <Td colSpan={6}>No Property Documents found</Td>
        </BodyRow>
      </TBody>
    );
  }

  return (
    <TBody>
      {documents.map(
        ({
          order,
          document: {
            id,
            documentNumber,
            flagged,
            address,
            legalDescriptionStatus,
            vestingInformationStatus,
            createdAt,
          },
        }) => (
          <BodyRow key={id}>
            <Td>
              <Flex
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                gap="medium"
              >
                <RouterLink to={`/documents/${id}/`}>
                  {documentNumber}
                </RouterLink>
                {flagged ? <PropertyDocumentFlagButton active={true} /> : null}
              </Flex>
            </Td>
            <Td>
              <MultiLineAddressComponent address={address} />
            </Td>
            <Td>
              <PropertyDocumentStatusPill status={legalDescriptionStatus} />
            </Td>
            <Td>
              <PropertyDocumentStatusPill status={vestingInformationStatus} />
            </Td>
            <Td>
              <DateTimeComponent value={createdAt} />
            </Td>
            <Td>
              <MaybeNotAvailable value={order}>
                {(order_) => (
                  <RouterLink to={`/orders/${order_.id}/`}>Order</RouterLink>
                )}
              </MaybeNotAvailable>
            </Td>
          </BodyRow>
        ),
      )}
    </TBody>
  );
};

const GET_PROPERTY_DOCUMENTS_SORT_FIELD_VALUES = enumValues(
  GetPropertyDocumentsSortField,
);

export const mapSearchParamToGetPropertyDocumentsSortField = (
  getPropertyDocumentsSortField: string | null,
): GetPropertyDocumentsSortField | undefined => {
  const trimmed = getPropertyDocumentsSortField?.trim();
  if (trimmed) {
    return GET_PROPERTY_DOCUMENTS_SORT_FIELD_VALUES.find(
      (value) => value === trimmed,
    );
  }
  return undefined;
};

const PROPERTY_DOCUMENT_EXTRACTION_STATUS_VALUES = enumValues(
  PropertyDocumentExtractionStatus,
);

export const mapSearchParamToPropertyDocumentExtractionStatus = (
  propertyDocumentExtractionStatus: string | null,
): PropertyDocumentExtractionStatus | undefined => {
  const trimmed = propertyDocumentExtractionStatus?.trim();
  if (trimmed) {
    return PROPERTY_DOCUMENT_EXTRACTION_STATUS_VALUES.find(
      (value) => value === trimmed,
    );
  }
  return undefined;
};

export const mapSearchParamsToGetPropertyDocumentsRequest = (
  searchParams: URLSearchParams,
  queryDefaults: GetPropertyDocumentsRequestDefaults = GET_PROPERTY_DOCUMENTS_REQUEST_QUERY_DEFAULTS,
): GetPropertyDocumentsRequest => {
  const addressState = searchParams.get("addressState")?.trim();
  return {
    sortField:
      mapSearchParamToGetPropertyDocumentsSortField(
        searchParams.get("sortField"),
      ) ?? queryDefaults.sortField,
    sortDirection:
      parseSortDirection(searchParams.get("sortDirection")) ??
      queryDefaults.sortDirection,
    page: parseWholeNumber(searchParams.get("page")) ?? queryDefaults.page,
    pageSize:
      parseWholeNumber(searchParams.get("pageSize")) ?? queryDefaults.pageSize,
    addressState: addressState ? addressState : undefined,
    legalDescriptionStatus: mapSearchParamToPropertyDocumentExtractionStatus(
      searchParams.get("legalDescriptionStatus"),
    ),
    vestingInformationStatus: mapSearchParamToPropertyDocumentExtractionStatus(
      searchParams.get("vestingInformationStatus"),
    ),
    flagged: parseBoolean(searchParams.get("flagged")),
  };
};

export const mapGetPropertyDocumentsRequestToSearchParams = ({
  page,
  pageSize,
  flagged,
  legalDescriptionStatus,
  vestingInformationStatus,
  addressState,
  ...request
}: GetPropertyDocumentsRequest): URLSearchParamsInit => {
  const searchParams: URLSearchParamsInit = {
    ...request,
    page: page.toString(10),
    pageSize: pageSize.toString(10),
  };
  if (addressState) {
    searchParams.addressState = addressState;
  }
  if (notNullOrUndefined(flagged)) {
    searchParams.flagged = flagged.toString();
  }
  if (legalDescriptionStatus) {
    searchParams.legalDescriptionStatus = legalDescriptionStatus;
  }
  if (vestingInformationStatus) {
    searchParams.vestingInformationStatus = vestingInformationStatus;
  }
  return searchParams;
};
