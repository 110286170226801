const hasDecimalPortion = (float: number) => float % 1 !== 0;

export const parseWholeNumber = (value: string | null): number | undefined => {
  if (value) {
    const float = parseFloat(value);
    if (float < 0 || hasDecimalPortion(float)) {
      return undefined;
    }
    return Math.floor(float);
  }
  return undefined;
};

if (import.meta.vitest) {
  const {describe, expect, it} = import.meta.vitest;

  describe("parseWholeNumber", () => {
    it("parses 0", () => {
      const value = parseWholeNumber("0");

      expect(value).toEqual(0);
    });

    it("parses single digit positive integers", () => {
      const value = parseWholeNumber("1");

      expect(value).toEqual(1);
    });

    it("parses two digit positive integers", () => {
      const value = parseWholeNumber("10");

      expect(value).toEqual(10);
    });

    it("parses the trimmed value", () => {
      const value = parseWholeNumber("\n 100\t ");

      expect(value).toEqual(100);
    });

    it("parses if the value has a zero value decimal", () => {
      const value = parseWholeNumber("10.00");

      expect(value).toEqual(10);
    });

    it("returns undefined if the decimal is non-zero", () => {
      const value = parseWholeNumber("10.001");

      expect(value).toBeUndefined();
    });

    it("returns undefined if negative", () => {
      const value = parseWholeNumber("-10");

      expect(value).toBeUndefined();
    });

    it("returns undefined for small negatives", () => {
      const value = parseWholeNumber("-0.0001");

      expect(value).toBeUndefined();
    });

    it("returns undefined for small positives", () => {
      const value = parseWholeNumber("0.0001");

      expect(value).toBeUndefined();
    });

    it("returns undefined for null", () => {
      const value = parseWholeNumber(null);

      expect(value).toBeUndefined();
    });
  });
}
