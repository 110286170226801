import React from "react";

import {Text, TextRaw} from "./Text";

type NumberComponentProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  locale?: Intl.LocalesArgument;
  // eslint-disable-next-line react/no-unused-prop-types
  value: number;
  // eslint-disable-next-line react/no-unused-prop-types
  children?: undefined;
};

const numberToLocaleString = ({value, locale}: NumberComponentProps) =>
  value.toLocaleString(locale, {
    style: "decimal",
    useGrouping: true,
  });

export const NumberComponentRaw: React.FC<NumberComponentProps> = (props) => (
  <TextRaw value={numberToLocaleString(props)} />
);

export const NumberComponent: React.FC<
  Omit<React.ComponentProps<typeof Text>, "value"> & NumberComponentProps
> = (props) => <Text {...props} value={numberToLocaleString(props)} />;

if (import.meta.vitest) {
  const {describe, expect, it} = import.meta.vitest;
  const {render} = await import("@testing-library/react");

  describe("NumberComponent", () => {
    it("displays a positive integer with thousand separators and no decimal points", () => {
      const {container} = render(
        <NumberComponentRaw value={1000000} locale="en-US" />,
      );

      expect(container).toHaveTextContent("1,000,000");
    });

    it("displays a negative integer with sign", () => {
      const {container} = render(
        <NumberComponentRaw value={-1000000} locale="en-US" />,
      );

      expect(container).toHaveTextContent("-1,000,000");
    });

    it("displays a decimal with thousand separators and no decimal points", () => {
      const {container} = render(
        <NumberComponentRaw value={1000000.001} locale="en-US" />,
      );

      expect(container).toHaveTextContent("1,000,000.001");
    });

    it("displays a negative decimal with sign", () => {
      const {container} = render(
        <NumberComponentRaw value={-1000000.001} locale="en-US" />,
      );

      expect(container).toHaveTextContent("-1,000,000.001");
    });
  });
}
