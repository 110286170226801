/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValidationReportDetailAssessmentStatus } from './ValidationReportDetailAssessmentStatus';
import {
    ValidationReportDetailAssessmentStatusFromJSON,
    ValidationReportDetailAssessmentStatusFromJSONTyped,
    ValidationReportDetailAssessmentStatusToJSON,
} from './ValidationReportDetailAssessmentStatus';

/**
 * 
 * @export
 * @interface ValidationReportDetailAssessment
 */
export interface ValidationReportDetailAssessment {
    /**
     * 
     * @type {ValidationReportDetailAssessmentStatus}
     * @memberof ValidationReportDetailAssessment
     */
    accuracy: ValidationReportDetailAssessmentStatus;
    /**
     * 
     * @type {ValidationReportDetailAssessmentStatus}
     * @memberof ValidationReportDetailAssessment
     */
    falseNegativeRate: ValidationReportDetailAssessmentStatus;
    /**
     * 
     * @type {ValidationReportDetailAssessmentStatus}
     * @memberof ValidationReportDetailAssessment
     */
    falsePositiveRate: ValidationReportDetailAssessmentStatus;
}

/**
 * Check if a given object implements the ValidationReportDetailAssessment interface.
 */
export function instanceOfValidationReportDetailAssessment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accuracy" in value;
    isInstance = isInstance && "falseNegativeRate" in value;
    isInstance = isInstance && "falsePositiveRate" in value;

    return isInstance;
}

export function ValidationReportDetailAssessmentFromJSON(json: any): ValidationReportDetailAssessment {
    return ValidationReportDetailAssessmentFromJSONTyped(json, false);
}

export function ValidationReportDetailAssessmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationReportDetailAssessment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accuracy': ValidationReportDetailAssessmentStatusFromJSON(json['accuracy']),
        'falseNegativeRate': ValidationReportDetailAssessmentStatusFromJSON(json['falseNegativeRate']),
        'falsePositiveRate': ValidationReportDetailAssessmentStatusFromJSON(json['falsePositiveRate']),
    };
}

export function ValidationReportDetailAssessmentToJSON(value?: ValidationReportDetailAssessment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accuracy': ValidationReportDetailAssessmentStatusToJSON(value.accuracy),
        'falseNegativeRate': ValidationReportDetailAssessmentStatusToJSON(value.falseNegativeRate),
        'falsePositiveRate': ValidationReportDetailAssessmentStatusToJSON(value.falsePositiveRate),
    };
}

