import {
  OrderLegalDescriptionNotAvailable as FetchOrderLegalDescriptionNotAvailable,
  OrderLegalDescriptionSuccess as FetchOrderLegalDescriptionSuccess,
} from "../fetchClient";

const ORDER_LEGAL_DESCRIPTION_SUCCESS_STATUS = "success" as const;

export type OrderLegalDescriptionSuccess = FetchOrderLegalDescriptionSuccess & {
  status: typeof ORDER_LEGAL_DESCRIPTION_SUCCESS_STATUS;
};

const ORDER_LEGAL_DESCRIPTION_NOT_AVAILABLE_STATUS = "not_available" as const;

export type OrderLegalDescriptionNotAvailable =
  FetchOrderLegalDescriptionNotAvailable & {
    status: typeof ORDER_LEGAL_DESCRIPTION_NOT_AVAILABLE_STATUS;
  };

export type OrderLegalDescription =
  | OrderLegalDescriptionSuccess
  | OrderLegalDescriptionNotAvailable;

export const isOrderLegalDescriptionSuccess = (
  result: OrderLegalDescription,
): result is OrderLegalDescriptionSuccess =>
  result.status === ORDER_LEGAL_DESCRIPTION_SUCCESS_STATUS;

export const isOrderLegalDescriptionNotAvailable = (
  result: OrderLegalDescription,
): result is OrderLegalDescriptionNotAvailable =>
  result.status === ORDER_LEGAL_DESCRIPTION_NOT_AVAILABLE_STATUS;
