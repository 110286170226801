/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TransactionPartyType } from './TransactionPartyType';
import {
    TransactionPartyTypeFromJSON,
    TransactionPartyTypeFromJSONTyped,
    TransactionPartyTypeToJSON,
} from './TransactionPartyType';

/**
 * 
 * @export
 * @interface CreateOrderCplRequest
 */
export interface CreateOrderCplRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateOrderCplRequest
     */
    cost: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderCplRequest
     */
    state: string;
    /**
     * 
     * @type {TransactionPartyType}
     * @memberof CreateOrderCplRequest
     */
    type: TransactionPartyType;
}

/**
 * Check if a given object implements the CreateOrderCplRequest interface.
 */
export function instanceOfCreateOrderCplRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cost" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function CreateOrderCplRequestFromJSON(json: any): CreateOrderCplRequest {
    return CreateOrderCplRequestFromJSONTyped(json, false);
}

export function CreateOrderCplRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrderCplRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cost': json['cost'],
        'state': json['state'],
        'type': TransactionPartyTypeFromJSON(json['type']),
    };
}

export function CreateOrderCplRequestToJSON(value?: CreateOrderCplRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cost': value.cost,
        'state': value.state,
        'type': TransactionPartyTypeToJSON(value.type),
    };
}

