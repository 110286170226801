import {
  Link as AmplifyLink,
  Flex,
  StyleToken,
  useTheme,
  View,
} from "@aws-amplify/ui-react";
import React from "react";

import {Heading} from "./primatives";

export const Root: React.FC<React.ComponentProps<typeof Flex>> = (props) => (
  <Flex
    minHeight="100vh"
    direction="column"
    backgroundColor="background.primary"
    {...props}
  />
);

const useSidePaddingProps = (): Pick<
  React.ComponentProps<typeof View>,
  "paddingLeft" | "paddingRight"
> => {
  const {
    tokens: {
      space: {xxxl},
    },
  } = useTheme();
  return {
    paddingLeft: xxxl,
    paddingRight: xxxl,
  };
};

export const PageHeaderContainer: React.FC<
  React.ComponentProps<typeof Flex>
> = (props) => {
  const sidePaddingProps = useSidePaddingProps();
  return (
    <Flex
      justifyContent="space-between"
      direction="row"
      marginTop="xl"
      marginBottom="xl"
      {...sidePaddingProps}
      {...props}
    />
  );
};

const SiteTitle: React.FC<React.ComponentProps<typeof Heading>> = (props) => (
  <Heading level={3} {...props} />
);

type LinkComponentProps = Omit<
  React.ComponentProps<typeof AmplifyLink>,
  "to" | "as" | "href"
> & {to: string};

export const SiteLogo: React.FC<{
  Link?: React.ComponentType<LinkComponentProps>;
}> = ({Link = SiteLogoLink}) => (
  <Link to="/" textDecoration="none">
    <SiteTitle value="American Digital Title" />
    <SiteTitle value="Insurance Company" />
  </Link>
);

export const SiteLogoLink: React.FC<
  Omit<React.ComponentProps<typeof AmplifyLink>, "to" | "as" | "href"> & {
    to: string;
  }
> = ({to, ...props}) => <AmplifyLink {...props} href={to} />;

export const Body: React.FC<React.ComponentProps<typeof Flex>> = (props) => (
  <Flex shrink={1} grow={1} basis={0} direction="column" gap="xl" {...props} />
);

export const BodyHeader: React.FC<React.ComponentProps<typeof Flex>> = (
  props,
) => {
  const sidePaddingProps = useSidePaddingProps();
  return (
    <Flex direction="column" gap="large" {...sidePaddingProps} {...props} />
  );
};

export const PrimaryBodyHeaderContainer: React.FC<
  React.ComponentProps<typeof Flex>
> = (props) => {
  const {
    tokens: {
      borderWidths: {small: borderWidthSmall},
      colors: {
        border: {primary: borderColorPrimary},
      },
    },
  } = useTheme();
  return (
    <Flex
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      padding="medium"
      style={{
        borderTopWidth: borderWidthSmall.toString(),
        borderTopStyle: "solid",
        borderTopColor: borderColorPrimary.toString(),
        borderBottomWidth: borderWidthSmall.toString(),
        borderBottomStyle: "solid",
        borderBottomColor: borderColorPrimary.toString(),
      }}
      {...props}
    />
  );
};

export const PrimaryBodyHeader: React.FC<
  React.ComponentProps<typeof Heading>
> = (props) => <Heading level={2} {...props} />;

export const BodyContent: React.FC<React.ComponentProps<typeof Flex>> = (
  props,
) => {
  const sidePaddingProps = useSidePaddingProps();
  return (
    <Flex
      backgroundColor="background.secondary"
      height="100%"
      shrink={1}
      grow={1}
      basis={0}
      direction="column"
      paddingTop="xxl"
      paddingBottom="xxl"
      gap="xxl"
      {...sidePaddingProps}
      {...props}
    />
  );
};

export const usePrimarySectionGap = (): StyleToken<string> => {
  const {
    tokens: {
      space: {xl},
    },
  } = useTheme();
  return xl;
};

export const PrimarySection: React.FC<React.ComponentProps<typeof Flex>> = (
  props,
) => {
  const gap = usePrimarySectionGap();
  return (
    <Flex
      backgroundColor="background.primary"
      direction="column"
      gap={gap}
      paddingTop="xl"
      paddingLeft="large"
      paddingRight="large"
      paddingBottom="xl"
      {...props}
    />
  );
};

export const SecondarySection: React.FC<React.ComponentProps<typeof Flex>> = (
  props,
) => {
  const gap = usePrimarySectionGap();
  return <Flex direction="column" gap={gap} {...props} />;
};

export const SecondarySectionHeaderContainer: React.FC<
  React.ComponentProps<typeof Flex>
> = (props) => (
  <Flex
    backgroundColor="background.tertiary"
    borderRadius="small"
    direction="row"
    alignItems="center"
    justifyContent="flex-start"
    padding="medium"
    {...props}
  />
);

export const SecondarySectionHeader: React.FC<
  React.ComponentProps<typeof Heading>
> = (props) => <Heading level={4} {...props} />;

export const SecondarySectionContent: React.FC<
  React.ComponentProps<typeof Flex>
> = (props) => {
  const gap = usePrimarySectionGap();
  return (
    <Flex
      direction="column"
      gap={gap}
      paddingLeft="medium"
      paddingRight="medium"
      {...props}
    />
  );
};
