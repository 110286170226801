/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProducerType = {
    Agency: 'agency',
    Individual: 'individual'
} as const;
export type ProducerType = typeof ProducerType[keyof typeof ProducerType];


export function ProducerTypeFromJSON(json: any): ProducerType {
    return ProducerTypeFromJSONTyped(json, false);
}

export function ProducerTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProducerType {
    return json as ProducerType;
}

export function ProducerTypeToJSON(value?: ProducerType | null): any {
    return value as any;
}

