/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SearchPropertiesResult } from './SearchPropertiesResult';
import {
    SearchPropertiesResultFromJSON,
    SearchPropertiesResultFromJSONTyped,
    SearchPropertiesResultToJSON,
} from './SearchPropertiesResult';

/**
 * 
 * @export
 * @interface SearchPropertiesResponse
 */
export interface SearchPropertiesResponse {
    /**
     * 
     * @type {Array<SearchPropertiesResult>}
     * @memberof SearchPropertiesResponse
     */
    properties: Array<SearchPropertiesResult>;
}

/**
 * Check if a given object implements the SearchPropertiesResponse interface.
 */
export function instanceOfSearchPropertiesResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "properties" in value;

    return isInstance;
}

export function SearchPropertiesResponseFromJSON(json: any): SearchPropertiesResponse {
    return SearchPropertiesResponseFromJSONTyped(json, false);
}

export function SearchPropertiesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchPropertiesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'properties': ((json['properties'] as Array<any>).map(SearchPropertiesResultFromJSON)),
    };
}

export function SearchPropertiesResponseToJSON(value?: SearchPropertiesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'properties': ((value.properties as Array<any>).map(SearchPropertiesResultToJSON)),
    };
}

