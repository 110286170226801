/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GetPropertyDocumentsSortField = {
    CreatedAt: 'CREATED_AT',
    Ldstatus: 'LDSTATUS',
    Vistatus: 'VISTATUS'
} as const;
export type GetPropertyDocumentsSortField = typeof GetPropertyDocumentsSortField[keyof typeof GetPropertyDocumentsSortField];


export function GetPropertyDocumentsSortFieldFromJSON(json: any): GetPropertyDocumentsSortField {
    return GetPropertyDocumentsSortFieldFromJSONTyped(json, false);
}

export function GetPropertyDocumentsSortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPropertyDocumentsSortField {
    return json as GetPropertyDocumentsSortField;
}

export function GetPropertyDocumentsSortFieldToJSON(value?: GetPropertyDocumentsSortField | null): any {
    return value as any;
}

