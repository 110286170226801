export const toUTCDate = (value: Date): Date =>
  new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));

export const displayAsIsoLocalDate = (date: Date): string =>
  date.toISOString().substring(0, 10);

export type MonthNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

const MONTHS: readonly MonthNumber[] = Object.freeze([
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
]);

/**
 * Displays the month name for the given locale
 * @param month
 * @param format Any format representation accepted by Date.toLocaleString for the month option.
 * @param locale The locale to localize the month name for.
 */
export const displayMonth = (
  month: MonthNumber,
  format: NonNullable<Intl.DateTimeFormatOptions["month"]>,
  locale?: Intl.LocalesArgument,
): string => {
  const date = new Date(Date.UTC(2000, month, 1));
  return date.toLocaleString(locale, {
    timeZone: "UTC",
    month: format,
  });
};

/**
 * Enumerates the localized name of all months and returns sorted by their sorted "month number".
 * "month number" is the number that Date uses to represent the month, which is defined as (month of year - 1)
 * Ex. January -> 0, February -> 1, ..., December -> 11
 * @param format Any format accepted by Date.toLocaleString for the month option.
 * @param locale
 */
export const getSortedMonths = (
  format: NonNullable<Intl.DateTimeFormatOptions["month"]>,
  locale?: Intl.LocalesArgument,
): [MonthNumber, string][] => {
  const months: [MonthNumber, string][] = [];
  for (const monthNumber of MONTHS) {
    months.push([monthNumber, displayMonth(monthNumber, format, locale)]);
  }
  return months;
};

if (import.meta.vitest) {
  const {describe, expect, it} = import.meta.vitest;

  describe("displayAsIsoLocalDate", () => {
    it("displays a date without time information", () => {
      const date = new Date(Date.UTC(2019, 0, 1));

      expect(displayAsIsoLocalDate(date)).toEqual("2019-01-01");
    });
  });

  describe("displayMonth", () => {
    it("displays the month number as a localized string", () => {
      expect(displayMonth(0, "long", "en-US")).toEqual("January");
      expect(displayMonth(0, "short", "en-US")).toEqual("Jan");
      expect(displayMonth(1, "long", "en-US")).toEqual("February");
      expect(displayMonth(2, "long", "en-US")).toEqual("March");
      expect(displayMonth(3, "long", "en-US")).toEqual("April");
      expect(displayMonth(4, "long", "en-US")).toEqual("May");
      expect(displayMonth(5, "long", "en-US")).toEqual("June");
      expect(displayMonth(6, "long", "en-US")).toEqual("July");
      expect(displayMonth(7, "long", "en-US")).toEqual("August");
      expect(displayMonth(8, "long", "en-US")).toEqual("September");
      expect(displayMonth(9, "long", "en-US")).toEqual("October");
      expect(displayMonth(10, "long", "en-US")).toEqual("November");
      expect(displayMonth(11, "long", "en-US")).toEqual("December");
    });
  });

  describe("getSortedMonths", () => {
    it("returns all months and their localized name", () => {
      expect(getSortedMonths("long", "en-US")).toEqual([
        [0, "January"],
        [1, "February"],
        [2, "March"],
        [3, "April"],
        [4, "May"],
        [5, "June"],
        [6, "July"],
        [7, "August"],
        [8, "September"],
        [9, "October"],
        [10, "November"],
        [11, "December"],
      ]);
    });
  });
}
