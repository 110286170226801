import {Text, TextRaw} from "@title-service/ui";
import React from "react";

import {ProducerType} from "../adminApi";
import {RouterLink} from "../components/Link";
import {safeDisplayEnumFn} from "../util/enum";

export const unsafeDisplayProducerType = (
  producerType: ProducerType,
): string => {
  switch (producerType) {
    case ProducerType.Agency:
      return "Agency";
    case ProducerType.Individual:
      return "Individual";
    default:
      throw new Error(`Unknown Producer Type: ${producerType}`);
  }
};

export const displayProducerType = safeDisplayEnumFn(unsafeDisplayProducerType);

export const ProducerTypeComponentRaw: React.FC<{
  producerType: ProducerType;
}> = ({producerType}) => <TextRaw value={displayProducerType(producerType)} />;

export const ProducerTypeComponent: React.FC<
  Omit<React.ComponentProps<typeof Text>, "value"> & {
    producerType: ProducerType;
  }
> = ({producerType, ...props}) => (
  <Text {...props} value={displayProducerType(producerType)} />
);

export const buildProducerRoute = (
  producerType: ProducerType,
  producerId: string,
) => {
  switch (producerType) {
    case ProducerType.Agency:
      return `/producers/agencies/${producerId}`;
    case ProducerType.Individual:
      return `/producers/individuals/${producerId}`;
    default:
      throw new Error(`Unknown Producer Type: ${producerType}`);
  }
};

export const ProducerLink: React.FC<{
  producerId: string;
  producerName: string;
  producerType: ProducerType;
}> = ({producerType, producerId, producerName}) => (
  <RouterLink to={buildProducerRoute(producerType, producerId)}>
    {producerName}
  </RouterLink>
);
