/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClaimCostType } from './ClaimCostType';
import {
    ClaimCostTypeFromJSON,
    ClaimCostTypeFromJSONTyped,
    ClaimCostTypeToJSON,
} from './ClaimCostType';
import type { ClaimRecoverySource } from './ClaimRecoverySource';
import {
    ClaimRecoverySourceFromJSON,
    ClaimRecoverySourceFromJSONTyped,
    ClaimRecoverySourceToJSON,
} from './ClaimRecoverySource';

/**
 * 
 * @export
 * @interface CreateClaimRecoveryRequest
 */
export interface CreateClaimRecoveryRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateClaimRecoveryRequest
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CreateClaimRecoveryRequest
     */
    description: string;
    /**
     * 
     * @type {ClaimRecoverySource}
     * @memberof CreateClaimRecoveryRequest
     */
    recoverySource: ClaimRecoverySource;
    /**
     * 
     * @type {ClaimCostType}
     * @memberof CreateClaimRecoveryRequest
     */
    recoveryType: ClaimCostType;
}

/**
 * Check if a given object implements the CreateClaimRecoveryRequest interface.
 */
export function instanceOfCreateClaimRecoveryRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "recoverySource" in value;
    isInstance = isInstance && "recoveryType" in value;

    return isInstance;
}

export function CreateClaimRecoveryRequestFromJSON(json: any): CreateClaimRecoveryRequest {
    return CreateClaimRecoveryRequestFromJSONTyped(json, false);
}

export function CreateClaimRecoveryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateClaimRecoveryRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'description': json['description'],
        'recoverySource': ClaimRecoverySourceFromJSON(json['recoverySource']),
        'recoveryType': ClaimCostTypeFromJSON(json['recoveryType']),
    };
}

export function CreateClaimRecoveryRequestToJSON(value?: CreateClaimRecoveryRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'description': value.description,
        'recoverySource': ClaimRecoverySourceToJSON(value.recoverySource),
        'recoveryType': ClaimCostTypeToJSON(value.recoveryType),
    };
}

