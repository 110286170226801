import {Button, Flex} from "@aws-amplify/ui-react";
import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {
  FormikConnectedAmplifyDateField,
  FormikConnectedAmplifySubmitButton,
  FormikConnectedAmplifyTextField,
  FormikForm,
  FormResultErrorMessage,
  FormResultSuccessMessage,
  Heading,
  useFieldsetGap,
  VerticalFieldset,
} from "@title-service/ui";
import {buildDateSchema} from "@title-service/yup-utils";
import {Formik, FormikHelpers, useFormikContext} from "formik";
import React, {useCallback, useState} from "react";
import * as Yup from "yup";

import {DeactivateProducerJurisdictionRequest} from "../adminApi";
import {Modal} from "../components/Modal";

export const DEACTIVATION_DATE_SCHEMA: Yup.DateSchema<Date> =
  buildDateSchema().required("Deactivation date is required");

export const DeactivationDateField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyDateField>, "label">
> = (props) => (
  <FormikConnectedAmplifyDateField
    testId="deactivation-date-input"
    label="Deactivation Date"
    {...props}
  />
);

export const buildAppointmentTerminationNoticeDateSchema = (
  appointmentTerminationNoticeDateRequired: boolean,
): Yup.DateSchema<Date | undefined> =>
  appointmentTerminationNoticeDateRequired
    ? buildDateSchema().required(
        "Appointment termination notice date is required",
      )
    : buildDateSchema().optional();

export const AppointmentTerminationNoticeDateField: React.FC<
  {
    appointmentTerminationNoticeDateRequired?: boolean;
  } & Omit<
    React.ComponentProps<typeof FormikConnectedAmplifyDateField>,
    "label"
  >
> = ({appointmentTerminationNoticeDateRequired, ...props}) => {
  const label = appointmentTerminationNoticeDateRequired
    ? "Appointment Termination Notice Date"
    : "Appointment Termination Notice Date (Optional)";
  return (
    <FormikConnectedAmplifyDateField
      testId="appointment-termination-notice-date-input"
      label={label}
      {...props}
    />
  );
};

export const DEACTIVATION_REASON_SCHEMA: Yup.StringSchema<string> = Yup.string()
  .required("Deactivation reason is required")
  .trim();

export const DeactivationReasonField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = (props) => (
  <FormikConnectedAmplifyTextField
    testId="deactivation-reason-input"
    label="Deactivation Reason"
    {...props}
  />
);

export type DeactivateProducerJurisdictionFormData = Omit<
  DeactivateProducerJurisdictionRequest,
  | "producerJurisdictionId"
  | "deactivationReason"
  | "deactivationDate"
  | "appointmentTerminationNoticeDate"
> & {
  deactivationReason: string;
  deactivationDate: string;
  appointmentTerminationNoticeDate: string;
};

export type CompleteDeactivateProducerJurisdictionFormData = Omit<
  DeactivateProducerJurisdictionRequest,
  "producerJurisdictionId"
>;

export const buildDeactivateProducerJurisdictionFormSchema = (
  appointmentTerminationNoticeDateRequired: boolean,
): Yup.ObjectSchema<CompleteDeactivateProducerJurisdictionFormData> =>
  Yup.object({
    deactivationReason: DEACTIVATION_REASON_SCHEMA,
    deactivationDate: DEACTIVATION_DATE_SCHEMA,
    appointmentTerminationNoticeDate:
      buildAppointmentTerminationNoticeDateSchema(
        appointmentTerminationNoticeDateRequired,
      ),
  });

export const buildInitialDeactivateProducerJurisdictionFormValues =
  (): DeactivateProducerJurisdictionFormData => ({
    deactivationReason: "",
    deactivationDate: "",
    appointmentTerminationNoticeDate: "",
  });

export const DeactivateProducerJurisdictionForm: React.FC<{
  onSubmitComplete: () => any;
  mutationFn: (
    completeFormData: CompleteDeactivateProducerJurisdictionFormData,
  ) => Promise<void>;
  displayAppointmentTerminationNoticeDate: boolean;
  appointmentTerminationNoticeDateRequired: boolean;
  requireConfirmation: boolean;
  isAppointmentTerminationNoticeDateRequiredError: (
    mutationError: any,
  ) => boolean;
}> = ({
  onSubmitComplete,
  mutationFn,
  displayAppointmentTerminationNoticeDate,
  appointmentTerminationNoticeDateRequired,
  requireConfirmation,
  isAppointmentTerminationNoticeDateRequiredError,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const openConfirmationModal = useCallback(() => {
    setShowConfirmationModal(true);
  }, []);
  const closeConfirmationModal = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);
  const mutation = useMutation({mutationFn});
  const formSchema = buildDeactivateProducerJurisdictionFormSchema(
    appointmentTerminationNoticeDateRequired,
  );
  const noConfirmationSubmitHandler = useCallback(
    (
      formData: DeactivateProducerJurisdictionFormData,
      {setSubmitting}: FormikHelpers<DeactivateProducerJurisdictionFormData>,
    ) => {
      const completeFormData = formSchema.validateSync(formData);
      mutation.mutate(completeFormData, {
        onSuccess: () => {
          onSubmitComplete();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [mutation, formSchema, onSubmitComplete],
  );
  return (
    <Formik<DeactivateProducerJurisdictionFormData>
      initialValues={buildInitialDeactivateProducerJurisdictionFormValues()}
      validationSchema={formSchema as any}
      onSubmit={noConfirmationSubmitHandler}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <DeactivationReasonField
            name="deactivationReason"
            minWidth="450px"
            testId="deactivation-reason-input"
          />
          <DeactivationDateField
            name="deactivationDate"
            minWidth="450px"
            testId="deactivation-date-input"
          />
          {displayAppointmentTerminationNoticeDate ? (
            <AppointmentTerminationNoticeDateField
              name="appointmentTerminationNoticeDate"
              minWidth="450px"
              testId="appointment-termination-notice-date-input"
              appointmentTerminationNoticeDateRequired={
                appointmentTerminationNoticeDateRequired
              }
            />
          ) : null}
        </VerticalFieldset>

        {mutation.isSuccess ? (
          <FormResultSuccessMessage data-testid="success-result">
            The producer jurisdiction was successfully deactivated
          </FormResultSuccessMessage>
        ) : null}

        {mutation.isError && !requireConfirmation ? (
          <FormResultErrorMessage data-testid="error-result">
            Request failed
          </FormResultErrorMessage>
        ) : null}

        {requireConfirmation ? (
          <Button
            variation="primary"
            onClick={openConfirmationModal}
            testId="open-confirmation-modal-button"
          >
            Submit
          </Button>
        ) : (
          <FormikConnectedAmplifySubmitButton testId="form-submit-button" />
        )}
        <Modal
          contentStyle={{minWidth: "750px"}}
          isOpen={showConfirmationModal}
          onClose={closeConfirmationModal}
          ariaHideApp={false}
          data-testid="confirmation-modal"
        >
          <DeactivateProducerConfirmationModal
            formSchema={formSchema}
            mutation={mutation}
            onSubmitComplete={onSubmitComplete}
            onClose={closeConfirmationModal}
            isAppointmentTerminationNoticeDateRequiredError={
              isAppointmentTerminationNoticeDateRequiredError
            }
          />
        </Modal>
      </FormikForm>
    </Formik>
  );
};

export const DeactivateProducerConfirmationModal: React.FC<{
  formSchema: Yup.ObjectSchema<any>;
  mutation: UseMutationResult<any, any, any, any>;
  onSubmitComplete: () => any;
  onClose: () => any;
  isAppointmentTerminationNoticeDateRequiredError: (
    mutationError: any,
  ) => boolean;
}> = ({
  formSchema,
  mutation,
  onSubmitComplete,
  onClose,
  isAppointmentTerminationNoticeDateRequiredError,
}) => {
  const fieldsetGap = useFieldsetGap();
  const formik = useFormikContext<DeactivateProducerJurisdictionFormData>();
  const {values} = formik;
  const completeFormData = formSchema.validateSync(values);
  const confirmationSubmitHandler = useCallback(() => {
    mutation.mutate(completeFormData, {
      onSuccess: () => {
        onSubmitComplete();
        onClose();
      },
    });
  }, [mutation, completeFormData, onSubmitComplete, onClose]);
  const appointmentTerminationNoticeDateIsEmpty =
    values.appointmentTerminationNoticeDate === "";
  return (
    <Flex direction="column" alignItems="self-start" gap={fieldsetGap}>
      <Heading
        level={5}
        value="Are you sure you want to deactivate this producer?"
      />
      <Flex direction="row" justifyContent="flex-end" width="100%">
        <Button
          variation="link"
          onClick={onClose}
          isDisabled={mutation.isLoading}
          testId="cancel-button"
        >
          Cancel
        </Button>
        <FormikConnectedAmplifySubmitButton
          variation="destructive"
          onClick={confirmationSubmitHandler}
          testId="submit-button"
          isLoading={mutation.isLoading}
          loadingText="Deactivating..."
          value="Deactivate Producer"
        />
      </Flex>

      {mutation.isSuccess ? (
        <FormResultSuccessMessage data-testid="success-result">
          The producer was successfully deactivated.
        </FormResultSuccessMessage>
      ) : null}

      {mutation.isError && appointmentTerminationNoticeDateIsEmpty ? (
        <FormResultErrorMessage data-testid="error-result">
          {isAppointmentTerminationNoticeDateRequiredError(mutation.error)
            ? "Appointment Termination Notice Date is required when a valid appointment exists"
            : "Request failed"}
        </FormResultErrorMessage>
      ) : null}
    </Flex>
  );
};
