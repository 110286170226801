/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CoverageType = {
    LenderStandard: 'lender_standard',
    LenderExtended: 'lender_extended',
    LenderExpanded: 'lender_expanded',
    LenderShortForm: 'lender_short_form',
    OwnerStandard: 'owner_standard',
    OwnerExtended: 'owner_extended',
    OwnerHomeowners: 'owner_homeowners'
} as const;
export type CoverageType = typeof CoverageType[keyof typeof CoverageType];


export function CoverageTypeFromJSON(json: any): CoverageType {
    return CoverageTypeFromJSONTyped(json, false);
}

export function CoverageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CoverageType {
    return json as CoverageType;
}

export function CoverageTypeToJSON(value?: CoverageType | null): any {
    return value as any;
}

