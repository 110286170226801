import {useEffect} from "react";

import {
  AuthenticationState,
  AuthenticationStateType,
} from "./AuthenticationState";

export const useAutomaticallyRefreshSession = (
  authenticationState: AuthenticationState,
) => {
  useEffect(() => {
    if (authenticationState.type === AuthenticationStateType.AUTHENTICATED) {
      const timeout = authenticationState.expiresAt.getTime() - Date.now();
      const timeoutId = window.setTimeout(
        authenticationState.refreshSession,
        timeout,
      );

      return () => {
        clearTimeout(timeoutId);
      };
    }

    return () => {};
  }, [authenticationState]);
};
