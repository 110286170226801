import {PrimarySection} from "@title-service/ui";
import React from "react";

import {
  UnderwritingAttachmentsSection,
  useGetOrderUnderwritingAttachments,
} from "./UnderwritingAttachmentsSection";
import {
  UnderwritingNotesSection,
  useGetOrderUnderwritingNotes,
} from "./UnderwritingNotesSection";

export const UnderwritingTab: React.FC<{
  orderId: string;
}> = ({orderId}) => {
  const {getOrderUnderwritingNotesQuery, onReloadOrderUnderwritingNotes} =
    useGetOrderUnderwritingNotes(orderId);
  const {
    getOrderUnderwritingAttachmentsQuery,
    onReloadOrderUnderwritingAttachments,
  } = useGetOrderUnderwritingAttachments(orderId);

  return (
    <PrimarySection>
      <UnderwritingNotesSection
        orderId={orderId}
        getOrderUnderwritingNotesQuery={getOrderUnderwritingNotesQuery}
        onReloadOrderUnderwritingNotes={onReloadOrderUnderwritingNotes}
      />
      <UnderwritingAttachmentsSection
        orderId={orderId}
        getOrderUnderwritingAttachmentsQuery={
          getOrderUnderwritingAttachmentsQuery
        }
        onReloadOrderUnderwritingAttachments={
          onReloadOrderUnderwritingAttachments
        }
      />
    </PrimarySection>
  );
};
