import {displayAsIsoLocalDate} from "@title-service/utils";

import {AuthenticationStateHolder} from "../authentication";

import {
  AdminApi,
  DeactivateAgencyProducerRequestUnprocessableResponseError,
  DeactivateIndividualProducerRequestUnprocessableResponseError,
} from "./AdminApi";
import {Configuration, DefaultApi, GetAuditsFilters} from "./fetchClient";
import {
  FetchParams,
  HTTPRequestInit,
  RequestContext,
  ResponseError,
} from "./fetchClient/runtime";
import {
  ApprovePropertyDocumentLegalDescriptionRequest,
  ApprovePropertyDocumentVestingInformationRequest,
  ClaimRequestAddressToJSON,
  CloseClaimRequest,
  Cpl,
  CreateAgencyProducerAppointmentRequest,
  CreateAgencyProducerLicenseRequest,
  CreateAgencyProducerNoteRequest,
  CreateAgencyProducerRequest,
  CreateAgencyProducerSuccessResponse,
  CreateAuditAttachmentRequest,
  CreateAuditRequest,
  CreateAuditSuccessResponse,
  CreateClaimAttachmentRequest,
  CreateClaimNoteRequest,
  CreateClaimRecoveryRequest,
  CreateClaimRequest,
  CreateClaimReserveRequest,
  CreateClaimSuccessResponse,
  CreateClaimTransactionRequest,
  CreateIndividualProducerAppointmentRequest,
  CreateIndividualProducerLicenseRequest,
  CreateIndividualProducerNoteRequest,
  CreateIndividualProducerRequest,
  CreateIndividualProducerSuccessResponse,
  CreateOrderCplRequest,
  CreateOrderCplSuccessResponse,
  CreateOrderUnderwritingAttachmentRequest,
  CreateOrderUnderwritingNoteRequest,
  CreateRemittanceRequest,
  CreateValidationReportAcceptedResponse,
  CreateValidationReportRequest,
  DeactivateAgencyProducerRequest,
  DeactivateIndividualProducerRequest,
  DeactivateProducerJurisdictionRequest,
  EditPropertyDocumentLegalDescriptionRequest,
  EditPropertyDocumentVestingInformationRequest,
  ExportAuditFinalReportsAcceptedResponse,
  ExportAuditFinalReportsRequest,
  FlagOrderRequest,
  FlagPropertyDocumentRequest,
  GetAgencyProducerJurisdictionsRequest,
  GetAgencyProducerNotesRequest,
  GetAgencyProducerRequest,
  GetAgencyProducersAutocompleteFilters,
  GetAgencyProducersAutocompleteRequest,
  GetAgencyProducersAutocompleteSuccessResponse,
  GetAgencyProducersRequest,
  GetAgencyProducersSuccessResponse,
  GetAgencyProducerSuccessResponse,
  GetAuditAttachmentsRequest,
  GetAuditAttachmentsSuccessResponse,
  GetAuditExportItemsRequest,
  GetAuditExportItemsSuccessResponse,
  GetAuditExportRequest,
  GetAuditExportsRequest,
  GetAuditExportsSuccessResponse,
  GetAuditExportSuccessResponse,
  GetAuditRequest,
  GetAuditsRequest,
  GetAuditsSuccessResponse,
  GetAuditSuccessResponse,
  GetClaimAttachmentsRequest,
  GetClaimAttachmentsSuccessResponse,
  GetClaimNotesRequest,
  GetClaimNotesSuccessResponse,
  GetClaimRecoveriesRequest,
  GetClaimRecoveriesSuccessResponse,
  GetClaimRequest,
  GetClaimReservesRequest,
  GetClaimReservesSuccessResponse,
  GetClaimsSuccessResponse,
  GetClaimSuccessResponse,
  GetClaimTransactionsRequest,
  GetClaimTransactionsSuccessResponse,
  GetCplRequest,
  GetCplsRequest,
  GetCplsSuccessResponse,
  GetIndividualProducerJurisdictionsRequest,
  GetIndividualProducerNotesRequest,
  GetIndividualProducerRequest,
  GetIndividualProducersRequest,
  GetIndividualProducersSuccessResponse,
  GetIndividualProducerSuccessResponse,
  GetInsurfulOrderResultRequest,
  GetJurisdictionsAutocompleteRequest,
  GetJurisdictionsAutocompleteSuccessResponse,
  GetOrderRemittanceItemsRequest,
  GetOrderRemittanceItemsSuccessResponse,
  GetOrderRequest,
  GetOrdersRequest,
  GetOrdersSuccessResponse,
  GetOrderSuccessResponse,
  GetOrderUnderwritingAttachmentsRequest,
  GetOrderUnderwritingAttachmentsSuccessResponse,
  GetOrderUnderwritingNotesRequest,
  GetOrderUnderwritingNotesSuccessResponse,
  GetParentAgenciesSuccessResponse,
  GetPoliciesRequest,
  GetPoliciesSuccessResponse,
  GetPolicyRequest,
  GetPredictionRequest,
  GetPredictionSuccessResponse,
  GetProducerJurisdictionsSuccessResponse,
  GetProducerNotesSuccessResponse,
  GetProducersExpiringSuccessResponse,
  GetPropertyDocumentIdsRequest,
  GetPropertyDocumentIdsSuccessResponse,
  GetPropertyDocumentRequest,
  GetPropertyDocumentsRequest,
  GetPropertyDocumentsSuccessResponse,
  GetPropertyDocumentSuccessResponse,
  GetPropertyRequest,
  GetPropertySuccessResponse,
  GetRemittanceItemsRequest,
  GetRemittanceItemsSuccessResponse,
  GetTrainedModelsSuccessResponse,
  GetValidationReportRequest,
  GetValidationReportsSuccessResponse,
  InsurfulOrderResult,
  Policy,
  ReactivateProducerJurisdictionRequest,
  RejectPropertyDocumentLegalDescriptionRequest,
  RejectPropertyDocumentVestingInformationRequest,
  RemittanceItemToJSON,
  RenewAgencyProducerLicenseRequest,
  RenewIndividualProducerLicenseRequest,
  SearchPropertiesRequest,
  SearchPropertiesResponse,
  TrainModelAcceptedResponse,
  TrainModelRequest,
  UnApprovePropertyDocumentLegalDescriptionRequest,
  UnApprovePropertyDocumentVestingInformationRequest,
  UnflagOrderRequest,
  UnflagPropertyDocumentRequest,
  UnRejectPropertyDocumentLegalDescriptionRequest,
  UnRejectPropertyDocumentVestingInformationRequest,
  UpdateAgencyProducerRequest,
  UpdateAuditAttachmentRequest,
  UpdateAuditRequest,
  UpdateClaimNoteRequest,
  UpdateClaimRequest,
  UpdateIndividualProducerRequest,
  UpdateOrderUnderwritingNoteRequest,
  UpdateProducerNoteRequest,
  UpdateRemittanceRequest,
  ValidationReportDetail,
  VoidClaimTransactionRequest,
} from "./models";

export const buildHttpAdminApiClient = (
  baseUrl: string,
  authenticationStateHolder: AuthenticationStateHolder,
): HttpAdminApiClient =>
  new HttpAdminApiClient(baseUrl, authenticationStateHolder);

const appendGetAuditsFiltersToQueryString = (
  url: string,
  {agencyId, state, upperBoundDate, lowerBoundDate}: GetAuditsFilters,
) => {
  const urlWithFilters = new URL(url);
  if (agencyId) {
    urlWithFilters.searchParams.append("filters.agencyId", agencyId);
  }
  if (state) {
    urlWithFilters.searchParams.append("filters.state", state);
  }
  if (upperBoundDate) {
    urlWithFilters.searchParams.append(
      "filters.upperBoundDate",
      displayAsIsoLocalDate(upperBoundDate),
    );
  }
  if (lowerBoundDate) {
    urlWithFilters.searchParams.append(
      "filters.lowerBoundDate",
      displayAsIsoLocalDate(lowerBoundDate),
    );
  }
  return urlWithFilters.toString();
};

const appendGetAgencyProducersAutocompleteFiltersToQueryString = (
  url: string,
  {name, type}: GetAgencyProducersAutocompleteFilters,
) => {
  const urlWithFilters = new URL(url);
  if (name) {
    urlWithFilters.searchParams.append("filters.name", name);
  }
  if (type) {
    urlWithFilters.searchParams.append("filters.type", type);
  }
  return urlWithFilters.toString();
};

export class HttpAdminApiClient implements AdminApi {
  private readonly configuration: Configuration;
  private readonly client: DefaultApi;

  constructor(
    baseUrl: string,
    authenticationStateHolder: AuthenticationStateHolder,
  ) {
    this.configuration = new Configuration({
      basePath: baseUrl,
    });
    this.client = new DefaultApi(this.configuration).withPreMiddleware(
      async ({
        url,
        init,
        init: {headers},
      }: RequestContext): Promise<FetchParams> => {
        const jwtToken = authenticationStateHolder.getJwtToken();
        if (jwtToken) {
          return {
            url,
            init: {
              ...init,
              headers: {
                ...headers,
                Authorization: `Bearer ${jwtToken}`,
              },
            },
          };
        }
        return {url, init};
      },
    );
  }

  getOrders = async (
    request: GetOrdersRequest,
    signal?: AbortSignal,
  ): Promise<GetOrdersSuccessResponse> => {
    const json = await this.client.getOrders(request, {
      signal,
    });
    return json;
  };

  getOrder = async (
    request: GetOrderRequest,
    signal?: AbortSignal,
  ): Promise<GetOrderSuccessResponse> => {
    const response = await this.client.getOrder(request, {
      signal,
    });
    return response as GetOrderSuccessResponse;
  };

  createOrderCpl = async ({
    orderId,
    ...request
  }: CreateOrderCplRequest): Promise<CreateOrderCplSuccessResponse> =>
    this.client.createOrderCpl({
      orderId,
      createOrderCplRequest: request,
    });

  getOrderRemittanceItems = async (
    request: GetOrderRemittanceItemsRequest,
    signal?: AbortSignal,
  ): Promise<GetOrderRemittanceItemsSuccessResponse> => {
    const response = await this.client.getOrderRemittanceItems(request, {
      signal,
    });
    return response as GetOrderRemittanceItemsSuccessResponse;
  };

  createRemittance = async (
    request: CreateRemittanceRequest,
  ): Promise<void> => {
    await this.client.createRemittance(
      {
        createRemittanceRequest: request,
      },
      async ({
        init: {body},
      }: {
        init: HTTPRequestInit;
      }): Promise<RequestInit> => ({
        body: {
          ...body,
          items: request.items.map(RemittanceItemToJSON),
        },
      }),
    );
  };

  updateRemittance = async ({
    remittanceId,
    ...request
  }: UpdateRemittanceRequest): Promise<void> => {
    await this.client.updateRemittance(
      {
        remittanceId,
        updateRemittanceRequest: request,
      },
      async ({
        init: {body},
      }: {
        init: HTTPRequestInit;
      }): Promise<RequestInit> => ({
        body: {
          ...body,
          items: request.items.map(RemittanceItemToJSON),
        },
      }),
    );
  };

  getRemittanceItems = async (
    request: GetRemittanceItemsRequest,
    signal?: AbortSignal,
  ): Promise<GetRemittanceItemsSuccessResponse> => {
    const response = await this.client.getRemittanceItems(request, {
      signal,
    });
    return response as GetRemittanceItemsSuccessResponse;
  };

  getCpls = async (
    request: GetCplsRequest,
    signal?: AbortSignal,
  ): Promise<GetCplsSuccessResponse> =>
    this.client.getCpls(request, {
      signal,
    });

  getCpl = async (request: GetCplRequest, signal?: AbortSignal): Promise<Cpl> =>
    this.client.getCpl(request, {
      signal,
    });

  getPolicies = async (
    request: GetPoliciesRequest,
    signal?: AbortSignal,
  ): Promise<GetPoliciesSuccessResponse> =>
    this.client.getPolicies(request, {
      signal,
    });

  getPolicy = async (
    request: GetPolicyRequest,
    signal?: AbortSignal,
  ): Promise<Policy> =>
    this.client.getPolicy(request, {
      signal,
    });

  flagOrder = async ({
    orderId,
    ...request
  }: FlagOrderRequest): Promise<void> => {
    await this.client.flagOrder({
      orderId,
      flagOrderRequest: request,
    });
  };

  unflagOrder = async (request: UnflagOrderRequest): Promise<void> => {
    await this.client.unflagOrder(request);
  };

  getPrediction = async (
    request: GetPredictionRequest,
    signal?: AbortSignal,
  ): Promise<GetPredictionSuccessResponse> =>
    this.client.getPrediction(request, {
      signal,
    });

  getProperty = async (
    request: GetPropertyRequest,
    signal?: AbortSignal,
  ): Promise<GetPropertySuccessResponse> =>
    this.client.getProperty(request, {signal});

  searchProperties = async (
    request: SearchPropertiesRequest,
    signal?: AbortSignal,
  ): Promise<SearchPropertiesResponse> =>
    this.client.searchProperties(request, {signal});

  getInsurfulOrderResult = async (
    request: GetInsurfulOrderResultRequest,
    signal?: AbortSignal,
  ) => {
    const response = await this.client.getInsurfulOrderResult(request, {
      signal,
    });
    return response as InsurfulOrderResult;
  };

  getValidationReports = async (
    signal?: AbortSignal,
  ): Promise<GetValidationReportsSuccessResponse> =>
    this.client.getValidationReports({signal});

  getValidationReport = async (
    request: GetValidationReportRequest,
    signal?: AbortSignal,
  ): Promise<ValidationReportDetail> => {
    const response = await this.client.getValidationReport(request, {
      signal,
    });
    return response as ValidationReportDetail;
  };

  createValidationReport = async (
    request: CreateValidationReportRequest,
  ): Promise<CreateValidationReportAcceptedResponse> =>
    this.client.createValidationReport({
      createValidationReportRequest: request,
    });

  getTrainedModels = async (
    signal?: AbortSignal,
  ): Promise<GetTrainedModelsSuccessResponse> =>
    this.client.getTrainedModels({signal});

  trainModel = async (
    request: TrainModelRequest,
  ): Promise<TrainModelAcceptedResponse> =>
    this.client.trainModel({
      trainModelRequest: request,
    });

  getPropertyDocuments = async (
    request: GetPropertyDocumentsRequest,
    signal?: AbortSignal,
  ): Promise<GetPropertyDocumentsSuccessResponse> => {
    const json = await this.client.getPropertyDocuments(request, {signal});
    json.stateFilterOptions = new Set(json.stateFilterOptions);
    return json;
  };

  getPropertyDocumentIds = async (
    request: GetPropertyDocumentIdsRequest,
    signal?: AbortSignal,
  ): Promise<GetPropertyDocumentIdsSuccessResponse> =>
    this.client.getPropertyDocumentIds(request, {signal});

  getPropertyDocument = async (
    request: GetPropertyDocumentRequest,
    signal?: AbortSignal,
  ): Promise<GetPropertyDocumentSuccessResponse> =>
    this.client.getPropertyDocument(request, {
      signal,
    });

  editPropertyDocumentLegalDescription = async ({
    propertyDocumentId,
    ...request
  }: EditPropertyDocumentLegalDescriptionRequest): Promise<void> => {
    await this.client.editPropertyDocumentLegalDescription({
      propertyDocumentId,
      editPropertyDocumentLegalDescriptionRequest: request,
    });
  };

  approvePropertyDocumentLegalDescription = async ({
    propertyDocumentId,
    ...request
  }: ApprovePropertyDocumentLegalDescriptionRequest): Promise<void> => {
    await this.client.approvePropertyDocumentLegalDescription({
      propertyDocumentId,
      approvePropertyDocumentLegalDescriptionRequest: request,
    });
  };

  unApprovePropertyDocumentLegalDescription = async ({
    propertyDocumentId,
    ...request
  }: UnApprovePropertyDocumentLegalDescriptionRequest): Promise<void> => {
    await this.client.unApprovePropertyDocumentLegalDescription({
      propertyDocumentId,
      unApprovePropertyDocumentLegalDescriptionRequest: request,
    });
  };

  rejectPropertyDocumentLegalDescription = async ({
    propertyDocumentId,
    ...request
  }: RejectPropertyDocumentLegalDescriptionRequest): Promise<void> => {
    await this.client.rejectPropertyDocumentLegalDescription({
      propertyDocumentId,
      rejectPropertyDocumentLegalDescriptionRequest: request,
    });
  };

  unRejectPropertyDocumentLegalDescription = async ({
    propertyDocumentId,
    ...request
  }: UnRejectPropertyDocumentLegalDescriptionRequest): Promise<void> => {
    await this.client.unRejectPropertyDocumentLegalDescription({
      propertyDocumentId,
      unRejectPropertyDocumentLegalDescriptionRequest: request,
    });
  };

  editPropertyDocumentVestingInformation = async ({
    propertyDocumentId,
    ...request
  }: EditPropertyDocumentVestingInformationRequest): Promise<void> => {
    await this.client.editPropertyDocumentVestingInformation({
      propertyDocumentId,
      editPropertyDocumentVestingInformationRequest: request,
    });
  };

  approvePropertyDocumentVestingInformation = async ({
    propertyDocumentId,
    ...request
  }: ApprovePropertyDocumentVestingInformationRequest): Promise<void> => {
    await this.client.approvePropertyDocumentVestingInformation({
      propertyDocumentId,
      approvePropertyDocumentVestingInformationRequest: request,
    });
  };

  unApprovePropertyDocumentVestingInformation = async ({
    propertyDocumentId,
    ...request
  }: UnApprovePropertyDocumentVestingInformationRequest): Promise<void> => {
    await this.client.unApprovePropertyDocumentVestingInformation({
      propertyDocumentId,
      unApprovePropertyDocumentVestingInformationRequest: request,
    });
  };

  rejectPropertyDocumentVestingInformation = async ({
    propertyDocumentId,
    ...request
  }: RejectPropertyDocumentVestingInformationRequest): Promise<void> => {
    await this.client.rejectPropertyDocumentVestingInformation({
      propertyDocumentId,
      rejectPropertyDocumentVestingInformationRequest: request,
    });
  };

  unRejectPropertyDocumentVestingInformation = async ({
    propertyDocumentId,
    ...request
  }: UnRejectPropertyDocumentVestingInformationRequest): Promise<void> => {
    await this.client.unRejectPropertyDocumentVestingInformation({
      propertyDocumentId,
      unRejectPropertyDocumentVestingInformationRequest: request,
    });
  };

  flagPropertyDocument = async ({
    propertyDocumentId,
    ...request
  }: FlagPropertyDocumentRequest): Promise<void> => {
    await this.client.flagPropertyDocument({
      propertyDocumentId,
      flagPropertyDocumentRequest: request,
    });
  };

  unflagPropertyDocument = async (
    request: UnflagPropertyDocumentRequest,
  ): Promise<void> => {
    await this.client.unflagPropertyDocument(request);
  };

  createClaim = async (
    request: CreateClaimRequest,
  ): Promise<CreateClaimSuccessResponse> =>
    this.client.createClaim(
      {
        createClaimRequest: request,
      },
      async ({
        init: {body},
      }: {
        init: HTTPRequestInit;
      }): Promise<RequestInit> => ({
        body: {
          ...body,
          address: ClaimRequestAddressToJSON(request.address),
        },
      }),
    );

  updateClaim = async ({
    claimId,
    ...request
  }: UpdateClaimRequest): Promise<void> => {
    await this.client.updateClaim(
      {
        claimId,
        updateClaimRequest: request,
      },
      async ({
        init: {body},
      }: {
        init: HTTPRequestInit;
      }): Promise<RequestInit> => ({
        body: {
          ...body,
          address: ClaimRequestAddressToJSON(request.address),
        },
      }),
    );
  };

  closeClaim = async (request: CloseClaimRequest): Promise<void> => {
    await this.client.closeClaim(request);
  };

  getClaims = async (signal?: AbortSignal): Promise<GetClaimsSuccessResponse> =>
    this.client.getClaims({
      signal,
    });

  getClaim = async (
    request: GetClaimRequest,
    signal?: AbortSignal,
  ): Promise<GetClaimSuccessResponse> =>
    this.client.getClaim(request, {signal});

  createClaimTransaction = async ({
    claimId,
    ...request
  }: CreateClaimTransactionRequest): Promise<void> => {
    await this.client.createClaimTransaction({
      claimId,
      createClaimTransactionRequest: request,
    });
  };

  getClaimTransactions = async (
    request: GetClaimTransactionsRequest,
    signal?: AbortSignal,
  ): Promise<GetClaimTransactionsSuccessResponse> =>
    this.client.getClaimTransactions(request, {signal});

  voidClaimTransaction = async (
    request: VoidClaimTransactionRequest,
  ): Promise<void> => {
    await this.client.voidClaimTransaction(request);
  };

  createClaimReserve = async ({
    claimId,
    ifMatch,
    ...request
  }: CreateClaimReserveRequest): Promise<void> => {
    await this.client.createClaimReserve({
      claimId,
      ifMatch,
      createClaimReserveChangeRequest: request,
    });
  };

  getClaimReserves = async (
    request: GetClaimReservesRequest,
    signal?: AbortSignal,
  ): Promise<GetClaimReservesSuccessResponse> =>
    this.client.getClaimReserves(request, {signal});

  createClaimRecovery = async ({
    claimId,
    ...request
  }: CreateClaimRecoveryRequest): Promise<void> => {
    await this.client.createClaimRecovery({
      claimId,
      createClaimRecoveryRequest: request,
    });
  };

  getClaimRecoveries = async (
    request: GetClaimRecoveriesRequest,
    signal?: AbortSignal,
  ): Promise<GetClaimRecoveriesSuccessResponse> =>
    this.client.getClaimRecoveries(request, {signal});

  getClaimAttachments = async (
    request: GetClaimAttachmentsRequest,
    signal?: AbortSignal,
  ): Promise<GetClaimAttachmentsSuccessResponse> =>
    this.client.getClaimAttachments(request, {signal});

  createClaimAttachment = async ({
    claimId,
    ...request
  }: CreateClaimAttachmentRequest): Promise<void> => {
    await this.client.createClaimAttachment({
      claimId,
      createClaimAttachmentRequest: request,
    });
  };

  createClaimNote = async ({
    claimId,
    ...request
  }: CreateClaimNoteRequest): Promise<void> => {
    await this.client.createClaimNote({
      claimId,
      createClaimNoteRequest: request,
    });
  };

  getClaimNotes = async (
    request: GetClaimNotesRequest,
    signal?: AbortSignal,
  ): Promise<GetClaimNotesSuccessResponse> =>
    this.client.getClaimNotes(request, {signal});

  updateClaimNote = async ({
    claimId,
    claimNoteId,
    ...request
  }: UpdateClaimNoteRequest): Promise<void> => {
    await this.client.updateClaimNote({
      claimId,
      claimNoteId,
      updateClaimNoteRequest: request,
    });
  };

  createAudit = async (
    request: CreateAuditRequest,
  ): Promise<CreateAuditSuccessResponse> =>
    this.client.createAudit({
      createAuditRequest: request,
    });

  getAudits = async (
    {filters, ...request}: GetAuditsRequest,
    signal?: AbortSignal,
  ): Promise<GetAuditsSuccessResponse> => {
    const getAuditsClient = this.client.withPreMiddleware(
      async ({url, init}: RequestContext): Promise<FetchParams> => ({
        url: filters ? appendGetAuditsFiltersToQueryString(url, filters) : url,
        init,
      }),
    );
    const json = await getAuditsClient.getAudits(request, {
      signal,
    });
    json.stateFilterOptions = new Set(json.stateFilterOptions);
    json.yearFilterOptions = new Set(json.yearFilterOptions);
    return json;
  };

  updateAudit = async ({
    auditId,
    ...request
  }: UpdateAuditRequest): Promise<void> => {
    await this.client.updateAudit({
      auditId,
      updateAuditRequest: request,
    });
  };

  getAudit = async (
    request: GetAuditRequest,
    signal?: AbortSignal,
  ): Promise<GetAuditSuccessResponse> =>
    this.client.getAudit(request, {signal});

  getAuditAttachments = async (
    request: GetAuditAttachmentsRequest,
    signal?: AbortSignal,
  ): Promise<GetAuditAttachmentsSuccessResponse> =>
    this.client.getAuditAttachments(request, {signal});

  createAuditAttachment = async ({
    auditId,
    ...request
  }: CreateAuditAttachmentRequest): Promise<void> => {
    await this.client.createAuditAttachment({
      auditId,
      createAuditAttachmentRequest: request,
    });
  };

  updateAuditAttachment = async ({
    auditId,
    auditAttachmentId,
    ...request
  }: UpdateAuditAttachmentRequest): Promise<void> => {
    await this.client.updateAuditAttachment({
      auditId,
      auditAttachmentId,
      updateAuditAttachmentRequest: request,
    });
  };

  exportAuditFinalReports = async (
    request: ExportAuditFinalReportsRequest,
  ): Promise<ExportAuditFinalReportsAcceptedResponse> =>
    this.client.exportAuditFinalReports({
      exportAuditFinalReportsRequest: request,
    });

  getAuditExports = async (
    request: GetAuditExportsRequest,
    signal?: AbortSignal,
  ): Promise<GetAuditExportsSuccessResponse> =>
    this.client.getAuditExports(request, {signal});

  getAuditExport = async (
    request: GetAuditExportRequest,
    signal?: AbortSignal,
  ): Promise<GetAuditExportSuccessResponse> =>
    this.client.getAuditExport(request, {signal});

  getAuditExportItems = async (
    request: GetAuditExportItemsRequest,
    signal?: AbortSignal,
  ): Promise<GetAuditExportItemsSuccessResponse> =>
    this.client.getAuditExportItems(request, {signal});

  getAgencyProducers = async (
    request: GetAgencyProducersRequest,
    signal?: AbortSignal,
  ): Promise<GetAgencyProducersSuccessResponse> =>
    this.client.getAgencyProducers(request, {
      signal,
    });

  getProducersExpiring = async (
    signal?: AbortSignal,
  ): Promise<GetProducersExpiringSuccessResponse> => {
    const response = await this.client.getProducersExpiring({
      signal,
    });
    return response as GetProducersExpiringSuccessResponse;
  };

  getAgencyProducersAutocomplete = async (
    {filters, ...request}: GetAgencyProducersAutocompleteRequest,
    signal?: AbortSignal,
  ): Promise<GetAgencyProducersAutocompleteSuccessResponse> => {
    const getAgencyProducersAutocompleteClient = this.client.withPreMiddleware(
      async ({url, init}: RequestContext): Promise<FetchParams> => ({
        url: filters
          ? appendGetAgencyProducersAutocompleteFiltersToQueryString(
              url,
              filters,
            )
          : url,
        init,
      }),
    );
    return getAgencyProducersAutocompleteClient.getAgencyProducersAutocomplete(
      request,
      {
        signal,
      },
    );
  };

  getParentAgencies = async (
    signal?: AbortSignal,
  ): Promise<GetParentAgenciesSuccessResponse> =>
    this.client.getParentAgencies({
      signal,
    });

  getAgencyProducer = async (
    request: GetAgencyProducerRequest,
    signal?: AbortSignal,
  ): Promise<GetAgencyProducerSuccessResponse> =>
    this.client.getAgencyProducer(request, {
      signal,
    });

  createAgencyProducer = async (
    request: CreateAgencyProducerRequest,
  ): Promise<CreateAgencyProducerSuccessResponse> =>
    this.client.createAgencyProducer({
      createAgencyProducerRequest: request,
    });

  getIndividualProducers = async (
    request: GetIndividualProducersRequest,
    signal?: AbortSignal,
  ): Promise<GetIndividualProducersSuccessResponse> =>
    this.client.getIndividualProducers(request, {
      signal,
    });

  createIndividualProducer = async (
    request: CreateIndividualProducerRequest,
  ): Promise<CreateIndividualProducerSuccessResponse> =>
    this.client.createIndividualProducer({
      createIndividualProducerRequest: request,
    });

  updateAgencyProducer = async ({
    agencyProducerId,
    ...request
  }: UpdateAgencyProducerRequest): Promise<void> => {
    await this.client.updateAgencyProducer({
      agencyProducerId,
      updateAgencyProducerRequest: request,
    });
  };

  getIndividualProducer = async (
    request: GetIndividualProducerRequest,
    signal?: AbortSignal,
  ): Promise<GetIndividualProducerSuccessResponse> =>
    this.client.getIndividualProducer(request, {signal});

  updateIndividualProducer = async ({
    individualProducerId,
    ...request
  }: UpdateIndividualProducerRequest): Promise<void> => {
    await this.client.updateIndividualProducer({
      individualProducerId,
      updateIndividualProducerRequest: request,
    });
  };

  getAgencyProducerJurisdictions = async (
    request: GetAgencyProducerJurisdictionsRequest,
    signal?: AbortSignal,
  ): Promise<GetProducerJurisdictionsSuccessResponse> =>
    this.client.getAgencyProducerJurisdictions(request, {
      signal,
    });

  getIndividualProducerJurisdictions = async (
    request: GetIndividualProducerJurisdictionsRequest,
    signal?: AbortSignal,
  ): Promise<GetProducerJurisdictionsSuccessResponse> =>
    this.client.getIndividualProducerJurisdictions(request, {
      signal,
    });

  getJurisdictionsWithUnfulfilledAppointments = async (
    signal?: AbortSignal,
  ): Promise<GetProducerJurisdictionsSuccessResponse> =>
    this.client.getJurisdictionsWithUnfulfilledAppointments({
      signal,
    });

  getJurisdictionsAutocomplete = async (
    request: GetJurisdictionsAutocompleteRequest,
    signal?: AbortSignal,
  ): Promise<GetJurisdictionsAutocompleteSuccessResponse> =>
    this.client.getJurisdictionsAutocomplete(request, {
      signal,
    });

  deactivateProducerJurisdiction = async ({
    producerJurisdictionId,
    ...request
  }: DeactivateProducerJurisdictionRequest): Promise<void> => {
    await this.client.deactivateProducerJurisdiction({
      producerJurisdictionId,
      deactivateProducerJurisdictionRequest: request,
    });
  };

  reactivateProducerJurisdiction = async ({
    producerJurisdictionId,
    ...request
  }: ReactivateProducerJurisdictionRequest): Promise<void> => {
    await this.client.reactivateProducerJurisdiction({
      producerJurisdictionId,
      reactivateProducerJurisdictionRequest: request,
    });
  };

  deactivateIndividualProducer = async ({
    individualProducerId,
    ...request
  }: DeactivateIndividualProducerRequest): Promise<void> => {
    try {
      await this.client.deactivateIndividualProducer({
        individualProducerId,
        deactivateProducerJurisdictionRequest: request,
      });
    } catch (e) {
      if (e instanceof ResponseError && e.response.status === 422) {
        throw new DeactivateIndividualProducerRequestUnprocessableResponseError();
      }
      throw e;
    }
  };

  deactivateAgencyProducer = async ({
    agencyProducerId,
    ...request
  }: DeactivateAgencyProducerRequest): Promise<void> => {
    try {
      await this.client.deactivateAgencyProducer({
        agencyProducerId,
        deactivateProducerJurisdictionRequest: request,
      });
    } catch (e) {
      if (e instanceof ResponseError && e.response.status === 422) {
        throw new DeactivateAgencyProducerRequestUnprocessableResponseError();
      }
      throw e;
    }
  };

  createAgencyProducerLicense = async ({
    agencyProducerId,
    ...request
  }: CreateAgencyProducerLicenseRequest): Promise<void> => {
    await this.client.createAgencyProducerLicense({
      agencyProducerId,
      createProducerLicenseRequest: request,
    });
  };

  renewAgencyProducerLicense = async ({
    agencyProducerId,
    licenseId,
    ...request
  }: RenewAgencyProducerLicenseRequest): Promise<void> => {
    await this.client.renewAgencyProducerLicense({
      agencyProducerId,
      licenseId,
      renewProducerLicenseRequest: request,
    });
  };

  renewIndividualProducerLicense = async ({
    individualProducerId,
    licenseId,
    ...request
  }: RenewIndividualProducerLicenseRequest): Promise<void> => {
    await this.client.renewIndividualProducerLicense({
      individualProducerId,
      licenseId,
      renewProducerLicenseRequest: request,
    });
  };

  createIndividualProducerLicense = async ({
    individualProducerId,
    ...request
  }: CreateIndividualProducerLicenseRequest): Promise<void> => {
    await this.client.createIndividualProducerLicense({
      individualProducerId,
      createProducerLicenseRequest: request,
    });
  };

  createAgencyProducerAppointment = async ({
    agencyProducerId,
    jurisdictionId,
    ...request
  }: CreateAgencyProducerAppointmentRequest): Promise<void> => {
    await this.client.createAgencyProducerAppointment({
      agencyProducerId,
      jurisdictionId,
      createProducerAppointmentRequest: request,
    });
  };

  createIndividualProducerAppointment = async ({
    individualProducerId,
    jurisdictionId,
    ...request
  }: CreateIndividualProducerAppointmentRequest): Promise<void> => {
    await this.client.createIndividualProducerAppointment({
      individualProducerId,
      jurisdictionId,
      createProducerAppointmentRequest: request,
    });
  };

  createOrderUnderwritingNote = async ({
    orderId,
    ...request
  }: CreateOrderUnderwritingNoteRequest): Promise<void> => {
    await this.client.createOrderUnderwritingNote({
      orderId,
      createOrderUnderwritingNoteRequest: request,
    });
  };

  getOrderUnderwritingNotes = async (
    request: GetOrderUnderwritingNotesRequest,
    signal?: AbortSignal,
  ): Promise<GetOrderUnderwritingNotesSuccessResponse> =>
    this.client.getOrderUnderwritingNotes(request, {
      signal,
    });

  updateOrderUnderwritingNote = async ({
    orderId,
    orderUnderwritingNoteId,
    ...request
  }: UpdateOrderUnderwritingNoteRequest): Promise<void> => {
    await this.client.updateOrderUnderwritingNote({
      orderId,
      orderUnderwritingNoteId,
      updateOrderUnderwritingNoteRequest: request,
    });
  };

  createOrderUnderwritingAttachment = async ({
    orderId,
    ...request
  }: CreateOrderUnderwritingAttachmentRequest): Promise<void> => {
    await this.client.createOrderUnderwritingAttachment({
      orderId,
      createOrderUnderwritingAttachmentRequest: request,
    });
  };

  getOrderUnderwritingAttachments = async (
    request: GetOrderUnderwritingAttachmentsRequest,
    signal?: AbortSignal,
  ): Promise<GetOrderUnderwritingAttachmentsSuccessResponse> =>
    this.client.getOrderUnderwritingAttachments(request, {
      signal,
    });

  createAgencyProducerNote = async ({
    agencyProducerId,
    ...request
  }: CreateAgencyProducerNoteRequest): Promise<void> => {
    await this.client.createAgencyProducerNote({
      agencyProducerId,
      createProducerNoteRequest: request,
    });
  };

  createIndividualProducerNote = async ({
    individualProducerId,
    ...request
  }: CreateIndividualProducerNoteRequest): Promise<void> => {
    await this.client.createIndividualProducerNote({
      individualProducerId,
      createProducerNoteRequest: request,
    });
  };

  getAgencyProducerNotes = async (
    request: GetAgencyProducerNotesRequest,
    signal?: AbortSignal,
  ): Promise<GetProducerNotesSuccessResponse> =>
    this.client.getAgencyProducerNotes(request, {
      signal,
    });

  getIndividualProducerNotes = async (
    request: GetIndividualProducerNotesRequest,
    signal?: AbortSignal,
  ): Promise<GetProducerNotesSuccessResponse> =>
    this.client.getIndividualProducerNotes(request, {
      signal,
    });

  updateProducerNote = async ({
    producerId,
    producerNoteId,
    ...request
  }: UpdateProducerNoteRequest): Promise<void> => {
    await this.client.updateProducerNote({
      producerId,
      producerNoteId,
      updateProducerNoteRequest: request,
    });
  };
}
