import {Flex} from "@aws-amplify/ui-react";
import {DateTimeComponent, Text, usePrimarySectionGap} from "@title-service/ui";
import React, {useCallback, useState} from "react";
import styled from "styled-components";

import {
  isOrderLegalDescriptionNotAvailable,
  isOrderLegalDescriptionSuccess,
  OrderLegalDescription,
} from "../../adminApi";
import {
  DataGrid,
  GridItem,
  GridItemLabel,
  GridItemLabelContainer,
} from "../../components/DataGrid";
import {RouterLink} from "../../components/Link";
import {MaybeNotAvailable} from "../../components/Nullable";
import {CaretDirection, CaretIcon} from "../../components/icons";
import {
  BodyRow as PrimaryBodyRow,
  HeaderRow as PrimaryHeaderRow,
  Table as PrimaryTable,
  TBody as PrimaryTBody,
  Td as PrimaryTd,
  Th as PrimaryTh,
  THead as PrimaryTHead,
} from "../../components/tables/PrimaryTable";
import {
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../../layout";
import {OrderLegalDescriptionNotAvailableReasonComponent} from "../OrderLegalDescriptionNotAvailableReasonComponent";
import {OrderPropertyDocumentExtractionStatusPill} from "../OrderPropertyDocumentExtractionStatusPill";

export const LegalDescriptionsSection: React.FC<{
  legalDescriptions: OrderLegalDescription[];
}> = ({legalDescriptions}) => (
  <SecondarySection>
    <SecondarySectionHeaderContainer>
      <SecondarySectionHeader value="Legal Descriptions" />
    </SecondarySectionHeaderContainer>
    <SecondarySectionContent>
      {legalDescriptions.length > 0 ? (
        <PrimaryTable>
          <PrimaryTHead>
            <PrimaryHeaderRow>
              <PrimaryTh>ATTOM ID</PrimaryTh>
              <PrimaryTh>Status</PrimaryTh>
              <PrimaryTh>Source Document</PrimaryTh>
              <PrimaryTh>Created At</PrimaryTh>
              <PrimaryTh />
            </PrimaryHeaderRow>
          </PrimaryTHead>
          <PrimaryTBody>
            {legalDescriptions.map((legalDescription) => (
              <OrderLegalDescriptionRow
                key={legalDescription.id}
                legalDescription={legalDescription}
              />
            ))}
          </PrimaryTBody>
        </PrimaryTable>
      ) : (
        <span>No Legal Descriptions</span>
      )}
    </SecondarySectionContent>
  </SecondarySection>
);

const AttomIdTd = styled(PrimaryTd)({
  width: "75px",
});

const StatusTd = styled(PrimaryTd)({
  width: "50px",
});

const DocumentLinkTd = styled(PrimaryTd)({
  width: "50px",
});

const CreatedAtTd = styled(PrimaryTd)({
  width: "100px",
});

const CaretTd = styled(PrimaryTd)({
  width: "20px",
});

type OrderLegalDescriptionRowProps = {
  legalDescription: OrderLegalDescription;
};

const OrderLegalDescriptionRow: React.FC<OrderLegalDescriptionRowProps> = ({
  legalDescription,
  legalDescription: {createdAt, attomId, status, sourceDocument},
}) => {
  const [displayDetails, setDisplayDetails] = useState(false);
  const toggleDetailsMenu = useCallback(() => {
    setDisplayDetails((previousDisplayDetails) => !previousDisplayDetails);
  }, []);
  const primarySectionGap = usePrimarySectionGap();

  return (
    <>
      <PrimaryBodyRow>
        <AttomIdTd>{attomId}</AttomIdTd>
        <StatusTd>
          <OrderPropertyDocumentExtractionStatusPill status={status} />
        </StatusTd>
        <DocumentLinkTd>
          <MaybeNotAvailable value={sourceDocument?.sourcePropertyDocumentId}>
            {(sourcePropertyDocumentId) => (
              <RouterLink to={`/documents/${sourcePropertyDocumentId}/`}>
                Document
              </RouterLink>
            )}
          </MaybeNotAvailable>
        </DocumentLinkTd>
        <CreatedAtTd>
          <DateTimeComponent value={createdAt} />
        </CreatedAtTd>
        <CaretTd>
          <CaretIcon
            fontSize="xs"
            onClick={toggleDetailsMenu}
            direction={
              displayDetails ? CaretDirection.DOWN : CaretDirection.RIGHT
            }
          />
        </CaretTd>
      </PrimaryBodyRow>
      {displayDetails ? (
        <PrimaryBodyRow>
          <PrimaryTd colSpan={5}>
            <Flex direction="column" gap={primarySectionGap}>
              <SecondarySection>
                <DataGrid>
                  {isOrderLegalDescriptionSuccess(legalDescription) && (
                    <GridItem>
                      <GridItemLabelContainer>
                        <GridItemLabel value="Legal Description" />
                      </GridItemLabelContainer>
                      <Text value={legalDescription.legalDescription} />
                    </GridItem>
                  )}
                  {isOrderLegalDescriptionNotAvailable(legalDescription) && (
                    <GridItem>
                      <GridItemLabelContainer>
                        <GridItemLabel value="Not Available Reason" />
                      </GridItemLabelContainer>
                      <OrderLegalDescriptionNotAvailableReasonComponent
                        notAvailableReason={legalDescription.notAvailableReason}
                      />
                    </GridItem>
                  )}
                </DataGrid>
              </SecondarySection>
            </Flex>
          </PrimaryTd>
        </PrimaryBodyRow>
      ) : null}
    </>
  );
};
