/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PropertyDocumentAssociatedOrder } from './PropertyDocumentAssociatedOrder';
import {
    PropertyDocumentAssociatedOrderFromJSON,
    PropertyDocumentAssociatedOrderFromJSONTyped,
    PropertyDocumentAssociatedOrderToJSON,
} from './PropertyDocumentAssociatedOrder';
import type { PropertyDocumentSummary } from './PropertyDocumentSummary';
import {
    PropertyDocumentSummaryFromJSON,
    PropertyDocumentSummaryFromJSONTyped,
    PropertyDocumentSummaryToJSON,
} from './PropertyDocumentSummary';

/**
 * 
 * @export
 * @interface GetPropertyDocumentsSuccessResponseDocument
 */
export interface GetPropertyDocumentsSuccessResponseDocument {
    /**
     * 
     * @type {PropertyDocumentSummary}
     * @memberof GetPropertyDocumentsSuccessResponseDocument
     */
    document: PropertyDocumentSummary;
    /**
     * 
     * @type {PropertyDocumentAssociatedOrder}
     * @memberof GetPropertyDocumentsSuccessResponseDocument
     */
    order?: PropertyDocumentAssociatedOrder;
}

/**
 * Check if a given object implements the GetPropertyDocumentsSuccessResponseDocument interface.
 */
export function instanceOfGetPropertyDocumentsSuccessResponseDocument(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "document" in value;

    return isInstance;
}

export function GetPropertyDocumentsSuccessResponseDocumentFromJSON(json: any): GetPropertyDocumentsSuccessResponseDocument {
    return GetPropertyDocumentsSuccessResponseDocumentFromJSONTyped(json, false);
}

export function GetPropertyDocumentsSuccessResponseDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPropertyDocumentsSuccessResponseDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'document': PropertyDocumentSummaryFromJSON(json['document']),
        'order': !exists(json, 'order') ? undefined : PropertyDocumentAssociatedOrderFromJSON(json['order']),
    };
}

export function GetPropertyDocumentsSuccessResponseDocumentToJSON(value?: GetPropertyDocumentsSuccessResponseDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'document': PropertyDocumentSummaryToJSON(value.document),
        'order': PropertyDocumentAssociatedOrderToJSON(value.order),
    };
}

