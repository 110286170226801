import {useMutation} from "@tanstack/react-query";
import {
  FieldsetHeading,
  FormikConnectedAmplifySubmitButton,
  FormikForm,
  FormResultErrorMessage,
  FormResultSuccessMessage,
  HorizontalFieldset,
  VerticalFieldset,
} from "@title-service/ui";
import {Formik, FormikHelpers} from "formik";
import React, {useCallback, useContext} from "react";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";

import {DependencyContext} from "../DependencyContext";
import {
  Address,
  AgencyProducerReference,
  ClaimRequestAddressNew,
  CreateClaimRequest,
} from "../shared/adminApi";
import {
  ADDRESS_SCHEMA,
  AddressCityField,
  AddressCountyField,
  AddressStateField,
  AddressStreetField,
  AddressUnitField,
  AddressZipCodeField,
  CONTACT_EMAIL_SCHEMA,
  CONTACT_PHONE_SCHEMA,
  ContactEmailField,
  ContactPhoneField,
  INSURED_NAME_SCHEMA,
  InsuredNameField,
  REPORTED_ON_SCHEMA,
  ReportedOnField,
} from "../shared/claims/ClaimOverviewSection";
import {
  DESCRIPTION_SCHEMA,
  DescriptionField,
} from "../shared/claims/DescriptionField";
import {
  POLICY_ID_SCHEMA,
  PolicyIdField,
} from "../shared/claims/PolicyDetailsSection";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
  PrimarySection,
} from "../shared/layout";
import {
  AgencyProducerField,
  AgencyProducerFormData,
  buildAgencyProducerFormInitialData,
  buildRequiredAgencyProducerFormSchema,
} from "../shared/producers/AgencyProducerField";

type CreateClaimFormData = Required<
  Omit<CreateClaimRequest, "address" | "reportedOn" | "agencyId">
> & {
  agency: AgencyProducerFormData;
  address: Required<Address>;
  reportedOn: string;
};

type CompleteCreateClaimFormData = Omit<
  CreateClaimRequest,
  "address" | "agencyId"
> & {
  agency: AgencyProducerReference;
  address: Address;
};

export const FORM_SCHEMA: Yup.ObjectSchema<CompleteCreateClaimFormData> =
  Yup.object({
    address: ADDRESS_SCHEMA,
    agency: buildRequiredAgencyProducerFormSchema(),
    contactEmail: CONTACT_EMAIL_SCHEMA,
    contactPhone: CONTACT_PHONE_SCHEMA,
    insuredName: INSURED_NAME_SCHEMA,
    reportedOn: REPORTED_ON_SCHEMA,
    policyId: POLICY_ID_SCHEMA,
    description: DESCRIPTION_SCHEMA,
  });

const buildCreateClaimFormInitialData = (): CreateClaimFormData => ({
  agency: buildAgencyProducerFormInitialData(),
  insuredName: "",
  contactPhone: "",
  contactEmail: "",
  address: {
    street: "",
    unit: "",
    city: "",
    county: "",
    state: "",
    zipCode: "",
  },
  reportedOn: new Date().toISOString().substring(0, 10),
  policyId: "",
  description: "",
});

export const mapFormDataToCreateClaimRequest = (
  formData: CreateClaimFormData,
): CreateClaimRequest => {
  const {agency, ...completeFormData} = FORM_SCHEMA.validateSync(formData, {
    stripUnknown: true,
  });
  const addressNew: ClaimRequestAddressNew = {
    type: "new",
    data: completeFormData.address,
  };
  return {
    ...completeFormData,
    address: addressNew,
    agencyId: agency.id,
  };
};

export const ClaimCreateRoute: React.FC = () => {
  const {adminApi} = useContext(DependencyContext);
  const createClaimMutation = useMutation({
    mutationFn: adminApi.createClaim,
  });
  const navigate = useNavigate();
  const submitHandler = useCallback(
    (
      formData: CreateClaimFormData,
      {setSubmitting}: FormikHelpers<CreateClaimFormData>,
    ) => {
      const createClaimRequest = mapFormDataToCreateClaimRequest(formData);
      createClaimMutation.mutate(createClaimRequest, {
        onSuccess: ({id}) => {
          navigate(`/claims/${id}/`, {replace: false});
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [createClaimMutation, navigate],
  );

  return (
    <>
      <BodyHeader>
        <PrimaryBodyHeaderContainer>
          <PrimaryBodyHeader value="Create Claim" />
        </PrimaryBodyHeaderContainer>
      </BodyHeader>
      <BodyContent>
        <PrimarySection>
          <Formik<CreateClaimFormData>
            initialValues={buildCreateClaimFormInitialData() as any}
            validationSchema={FORM_SCHEMA as any}
            onSubmit={submitHandler}
          >
            <FormikForm>
              <FieldsetHeading value="Claim Details" />

              <VerticalFieldset alignItems="stretch" width="450px">
                <AgencyProducerField label="Agency" name="agency" />

                <InsuredNameField name="insuredName" />

                <ContactEmailField name="contactEmail" />

                <ContactPhoneField name="contactPhone" />

                <PolicyIdField name="policyId" />

                <ReportedOnField name="reportedOn" />
              </VerticalFieldset>

              <FieldsetHeading value="Property Address" />

              <HorizontalFieldset>
                <AddressStreetField name="address.street" minWidth="375px" />

                <AddressUnitField name="address.unit" minWidth="150px" />
              </HorizontalFieldset>

              <HorizontalFieldset>
                <AddressCityField name="address.city" minWidth="300px" />

                <AddressCountyField name="address.county" minWidth="300px" />
              </HorizontalFieldset>

              <HorizontalFieldset>
                <AddressStateField name="address.state" minWidth="150px" />

                <AddressZipCodeField name="address.zipCode" minWidth="225px" />
              </HorizontalFieldset>

              <VerticalFieldset>
                <DescriptionField name="description" minWidth="450px" />
              </VerticalFieldset>

              <FormikConnectedAmplifySubmitButton />

              {createClaimMutation.isError ? (
                <FormResultErrorMessage data-testid="error-state">
                  Claim submission failed.
                </FormResultErrorMessage>
              ) : null}
              {createClaimMutation.isSuccess ? (
                <FormResultSuccessMessage>
                  Claim successfully created. Redirecting to Claim...
                </FormResultSuccessMessage>
              ) : null}
            </FormikForm>
          </Formik>
        </PrimarySection>
      </BodyContent>
    </>
  );
};
