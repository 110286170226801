import {QueryClient} from "@tanstack/react-query";
import React from "react";

import {AddressAutocompleteApi} from "./shared/addressAutocompleteApi";
import {AdminApi} from "./shared/adminApi";
import {
  AuthenticationStateHolder,
  AuthenticationStrategy,
} from "./shared/authentication";
import {SearchSequenceRepository} from "./shared/propertyDocuments/SearchSequenceRepository";

export type Dependencies = {
  queryClient: QueryClient;
  authenticationStrategy: AuthenticationStrategy;
  authenticationStateHolder: AuthenticationStateHolder;
  addressAutocompleteApi: AddressAutocompleteApi;
  adminApi: AdminApi;
  propertyDocumentSearchSequenceRepository: SearchSequenceRepository;
};

export const DependencyContext: React.Context<Dependencies> =
  React.createContext(undefined as any);
