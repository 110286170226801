import {
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {Query} from "@title-service/react-query-utils";
import {
  Button,
  FormikConnectedAmplifySubmitButton,
  FormikConnectedAmplifyTextField,
  FormikForm,
  Text,
  VerticalFieldset,
} from "@title-service/ui";
import {transformNullToUndefined} from "@title-service/yup-utils";
import {Formik, FormikHelpers} from "formik";
import React, {useCallback, useContext, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import * as Yup from "yup";

import {DependencyContext} from "../DependencyContext";
import {
  AgencyProducerReference,
  DeactivateIndividualProducerRequestUnprocessableResponseError,
  GetAgencyProducersAutocompleteType,
  GetIndividualProducerSuccessResponse,
  GetProducerJurisdictionsSuccessResponse,
  GetProducerNotesSuccessResponse,
  ProducerType,
  UpdateIndividualProducerRequest,
} from "../shared/adminApi";
import {
  CreateProducerNoteFormData,
  ProducerNotesSection,
} from "../shared/claims/ProducerNotesSection";
import {
  DataGrid,
  GridItem,
  GridItemLabel,
  GridItemLabelContainer,
} from "../shared/components/DataGrid";
import {Modal} from "../shared/components/Modal";
import {MaybeNotAvailable} from "../shared/components/Nullable";
import {EditIcon} from "../shared/components/icons";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
  PrimarySection,
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../shared/layout";
import {AgencyProducerAutocomplete} from "../shared/producers/AgencyProducerAutocomplete";
import {
  AgencyProducerField,
  AgencyProducerFormData,
  buildRequiredAgencyProducerFormSchema,
} from "../shared/producers/AgencyProducerField";
import {
  CompleteDeactivateProducerJurisdictionFormData,
  DeactivateProducerJurisdictionForm,
} from "../shared/producers/DeactivateProducerJurisdictionForm";
import {ProducerJurisdictionsSection} from "../shared/producers/ProducerJurisdictionsSection";

export const IndividualProducerDetailRoute: React.FC = () => {
  const {individualProducerId} = useParams<
    keyof IndividualProducerDetailPageParams
  >() as IndividualProducerDetailPageParams;

  return (
    <IndividualProducerDetailPage individualProducerId={individualProducerId} />
  );
};

type GetIndividualProducerQueryKey = ["individualProducers", string, "detail"];
type GetIndividualProducerQuery = Query<GetIndividualProducerSuccessResponse>;

const useGetIndividualProducer = (
  individualProducerId: string,
): {
  getIndividualProducerQuery: GetIndividualProducerQuery;
  onReloadIndividualProducer: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);

  const getIndividualProducerQueryKey: GetIndividualProducerQueryKey = useMemo(
    () => ["individualProducers", individualProducerId, "detail"],
    [individualProducerId],
  );
  const getIndividualProducerQueryFn: QueryFunction<
    GetIndividualProducerSuccessResponse,
    GetIndividualProducerQueryKey
  > = useCallback(
    ({queryKey: [_, individualProducerId_], signal}) =>
      adminApi.getIndividualProducer(
        {individualProducerId: individualProducerId_},
        signal,
      ),
    [adminApi],
  );
  const getIndividualProducerQuery = useQuery({
    queryKey: getIndividualProducerQueryKey,
    queryFn: getIndividualProducerQueryFn,
  });
  const onReloadIndividualProducer = useCallback(
    () =>
      queryClient.invalidateQueries({queryKey: getIndividualProducerQueryKey}),
    [queryClient, getIndividualProducerQueryKey],
  );
  return {
    getIndividualProducerQuery,
    onReloadIndividualProducer,
  };
};

type GetIndividualProducerJurisdictionsQueryKey = [
  "individualProducers",
  string,
  "jurisdictions",
];
export type GetIndividualProducerJurisdictionsQuery =
  Query<GetProducerJurisdictionsSuccessResponse>;

const useGetIndividualProducerJurisdictions = (
  individualProducerId: string,
): {
  getIndividualProducerJurisdictionsQuery: GetIndividualProducerJurisdictionsQuery;
  onReloadIndividualProducerJurisdictions: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);

  const getIndividualProducerJurisdictionsQueryKey: GetIndividualProducerJurisdictionsQueryKey =
    useMemo(
      () => ["individualProducers", individualProducerId, "jurisdictions"],
      [individualProducerId],
    );
  const getIndividualProducerJurisdictionsQueryFn: QueryFunction<
    GetProducerJurisdictionsSuccessResponse,
    GetIndividualProducerJurisdictionsQueryKey
  > = useCallback(
    ({queryKey: [_, individualProducerId_], signal}) =>
      adminApi.getIndividualProducerJurisdictions(
        {individualProducerId: individualProducerId_},
        signal,
      ),
    [adminApi],
  );
  const getIndividualProducerJurisdictionsQuery = useQuery({
    queryKey: getIndividualProducerJurisdictionsQueryKey,
    queryFn: getIndividualProducerJurisdictionsQueryFn,
  });
  const onReloadIndividualProducerJurisdictions = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: getIndividualProducerJurisdictionsQueryKey,
      }),
    [queryClient, getIndividualProducerJurisdictionsQueryKey],
  );
  return {
    getIndividualProducerJurisdictionsQuery,
    onReloadIndividualProducerJurisdictions,
  };
};

type GetIndividualProducerNotesQueryKey = [
  "individualProducers",
  string,
  "notes",
];
export type GetIndividualProducerNotesQuery =
  Query<GetProducerNotesSuccessResponse>;

export const useGetIndividualProducerNotes = (
  individualProducerId: string,
): {
  getIndividualProducerNotesQuery: GetIndividualProducerNotesQuery;
  onReloadIndividualProducerNotes: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);
  const getIndividualProducerNotesQueryKey: GetIndividualProducerNotesQueryKey =
    useMemo(
      () => ["individualProducers", individualProducerId, "notes"],
      [individualProducerId],
    );
  const getIndividualProducerNotesQueryFn: QueryFunction<
    GetProducerNotesSuccessResponse,
    GetIndividualProducerNotesQueryKey
  > = useCallback(
    ({queryKey: [_, individualProducerId_], signal}) =>
      adminApi.getIndividualProducerNotes(
        {producerId: individualProducerId_},
        signal,
      ),
    [adminApi],
  );
  const getIndividualProducerNotesQuery = useQuery({
    queryKey: getIndividualProducerNotesQueryKey,
    queryFn: getIndividualProducerNotesQueryFn,
  });
  const onReloadIndividualProducerNotes = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: getIndividualProducerNotesQueryKey,
      }),
    [queryClient, getIndividualProducerNotesQueryKey],
  );

  return {
    getIndividualProducerNotesQuery,
    onReloadIndividualProducerNotes,
  };
};

type IndividualProducerDetailPageParams = {
  individualProducerId: string;
};

export const IndividualProducerDetailPage: React.FC<
  IndividualProducerDetailPageParams & {
    IndividualProducerDetailQueryResult?: typeof IndividualProducerDetailQueryResult;
  }
> = ({
  individualProducerId,
  IndividualProducerDetailQueryResult:
    IndividualProducerDetailQueryResult_ = IndividualProducerDetailQueryResult,
}) => {
  const {getIndividualProducerQuery, onReloadIndividualProducer} =
    useGetIndividualProducer(individualProducerId);
  const {
    getIndividualProducerJurisdictionsQuery,
    onReloadIndividualProducerJurisdictions,
  } = useGetIndividualProducerJurisdictions(individualProducerId);
  const {getIndividualProducerNotesQuery, onReloadIndividualProducerNotes} =
    useGetIndividualProducerNotes(individualProducerId);

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <IndividualProducerDetailQueryResult_
      individualProducerId={individualProducerId}
      getIndividualProducerQuery={getIndividualProducerQuery}
      getIndividualProducerJurisdictionsQuery={
        getIndividualProducerJurisdictionsQuery
      }
      getIndividualProducerNotesQuery={getIndividualProducerNotesQuery}
      onReloadIndividualProducer={onReloadIndividualProducer}
      onReloadIndividualProducerJurisdictions={
        onReloadIndividualProducerJurisdictions
      }
      onReloadIndividualProducerNotes={onReloadIndividualProducerNotes}
    />
  );
};

export const IndividualProducerDetailQueryResult: React.FC<{
  individualProducerId: string;
  getIndividualProducerQuery: GetIndividualProducerQuery;
  getIndividualProducerJurisdictionsQuery: GetIndividualProducerJurisdictionsQuery;
  getIndividualProducerNotesQuery: GetIndividualProducerNotesQuery;
  onReloadIndividualProducer: () => any;
  onReloadIndividualProducerJurisdictions: () => any;
  onReloadIndividualProducerNotes: () => any;
  IndividualProducerDetailSuccessComponent?: typeof IndividualProducerDetailSuccessComponent;
  IndividualProducerDetailFailureComponent?: typeof IndividualProducerDetailFailureComponent;
  IndividualProducerDetailLoadingComponent?: typeof IndividualProducerDetailLoadingComponent;
}> = ({
  individualProducerId,
  getIndividualProducerQuery: {
    data: getIndividualProducerQueryData,
    error: getIndividualProducerQueryError,
  },
  getIndividualProducerJurisdictionsQuery,
  getIndividualProducerNotesQuery,
  onReloadIndividualProducer,
  onReloadIndividualProducerNotes,
  onReloadIndividualProducerJurisdictions,
  IndividualProducerDetailSuccessComponent:
    IndividualProducerDetailSuccessComponent_ = IndividualProducerDetailSuccessComponent,
  IndividualProducerDetailFailureComponent:
    IndividualProducerDetailFailureComponent_ = IndividualProducerDetailFailureComponent,
  IndividualProducerDetailLoadingComponent:
    IndividualProducerDetailLoadingComponent_ = IndividualProducerDetailLoadingComponent,
}) => {
  if (getIndividualProducerQueryData) {
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <IndividualProducerDetailSuccessComponent_
        onReloadIndividualProducer={onReloadIndividualProducer}
        onReloadIndividualProducerJurisdictions={
          onReloadIndividualProducerJurisdictions
        }
        onReloadIndividualProducerNotes={onReloadIndividualProducerNotes}
        getIndividualProducerResponse={getIndividualProducerQueryData}
        getIndividualProducerJurisdictionsQuery={
          getIndividualProducerJurisdictionsQuery
        }
        getIndividualProducerNotesQuery={getIndividualProducerNotesQuery}
      />
    );
  } else if (getIndividualProducerQueryError) {
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <IndividualProducerDetailFailureComponent_
        individualProducerId={individualProducerId}
      />
    );
  }
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <IndividualProducerDetailLoadingComponent_
      individualProducerId={individualProducerId}
    />
  );
};

export const IndividualProducerDetailLoadingComponent: React.FC<{
  individualProducerId: string;
}> = ({individualProducerId}) => (
  <BodyContent>
    <span>Loading Individual Producer {individualProducerId}...</span>
  </BodyContent>
);

export const IndividualProducerDetailFailureComponent: React.FC<{
  individualProducerId: string;
}> = ({individualProducerId}) => (
  <BodyContent>
    <span>Failed to load Individual Producer {individualProducerId}.</span>
  </BodyContent>
);

const MODAL_CONTENT_STYLE: React.CSSProperties = {
  minWidth: "750px",
};

const isAppointmentTerminationNoticeDateRequiredError = (mutationError: any) =>
  mutationError instanceof
  DeactivateIndividualProducerRequestUnprocessableResponseError;

export const IndividualProducerDetailSuccessComponent: React.FC<{
  onReloadIndividualProducer: () => any;
  onReloadIndividualProducerJurisdictions: () => any;
  onReloadIndividualProducerNotes: () => any;
  getIndividualProducerResponse: GetIndividualProducerSuccessResponse;
  getIndividualProducerJurisdictionsQuery: GetIndividualProducerJurisdictionsQuery;
  getIndividualProducerNotesQuery: GetIndividualProducerNotesQuery;
}> = ({
  onReloadIndividualProducer,
  onReloadIndividualProducerJurisdictions,
  onReloadIndividualProducerNotes,
  getIndividualProducerResponse,
  getIndividualProducerJurisdictionsQuery,
  getIndividualProducerNotesQuery,
  getIndividualProducerResponse: {id, name, parentAgencyProducer, niprId},
}) => {
  const {adminApi} = useContext(DependencyContext);
  const individualProducerId = {individualProducerId: id};
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);
  const handleIndividualProducerUpdated = useCallback(() => {
    onReloadIndividualProducer();
    closeForm();
  }, [closeForm, onReloadIndividualProducer]);

  const [displayDeactivateForm, setDisplayDeactivateForm] = useState(false);
  const openDeactivateForm = useCallback(() => {
    setDisplayDeactivateForm(true);
  }, []);
  const closeDeactivateForm = useCallback(() => {
    setDisplayDeactivateForm(false);
  }, []);
  const handleIndividualProducerDeactivated = useCallback(() => {
    onReloadIndividualProducer();
    onReloadIndividualProducerJurisdictions();
    closeDeactivateForm();
  }, [
    closeDeactivateForm,
    onReloadIndividualProducer,
    onReloadIndividualProducerJurisdictions,
  ]);

  const deactivateIndividualProducerJurisdictionsMutationFn = useCallback(
    (
      completeDeactivateProducerJurisdictionFormData: CompleteDeactivateProducerJurisdictionFormData,
    ) =>
      adminApi.deactivateIndividualProducer({
        ...completeDeactivateProducerJurisdictionFormData,
        individualProducerId: id,
      }),
    [adminApi, id],
  );

  const createIndividualProducerNoteMutationFn = useCallback(
    (formData: CreateProducerNoteFormData) =>
      adminApi.createIndividualProducerNote({
        ...formData,
        individualProducerId: id,
      }),
    [adminApi, id],
  );
  return (
    <>
      <BodyHeader>
        <PrimaryBodyHeaderContainer>
          <PrimaryBodyHeader value={name} />
          <>
            <Button
              variation="primary"
              onClick={openDeactivateForm}
              loadingText="Deactivating..."
              value="Deactivate Producer"
            />
            <Modal
              contentStyle={MODAL_CONTENT_STYLE}
              isOpen={displayDeactivateForm}
              onClose={closeDeactivateForm}
            >
              <DeactivateProducerJurisdictionForm
                onSubmitComplete={handleIndividualProducerDeactivated}
                mutationFn={deactivateIndividualProducerJurisdictionsMutationFn}
                appointmentTerminationNoticeDateRequired={false}
                displayAppointmentTerminationNoticeDate={true}
                requireConfirmation={true}
                isAppointmentTerminationNoticeDateRequiredError={
                  isAppointmentTerminationNoticeDateRequiredError
                }
              />
            </Modal>
          </>
        </PrimaryBodyHeaderContainer>
      </BodyHeader>
      <BodyContent>
        <PrimarySection>
          <SecondarySection>
            <SecondarySectionHeaderContainer>
              <SecondarySectionHeader value="Individual Producer Overview" />
              <EditIcon onClick={openForm} />
            </SecondarySectionHeaderContainer>
            <SecondarySectionContent>
              <DataGrid>
                <GridItem>
                  <GridItemLabelContainer>
                    <GridItemLabel value="Parent Agency" />
                  </GridItemLabelContainer>
                  <Text value={parentAgencyProducer.name} />
                </GridItem>
                <GridItem>
                  <GridItemLabelContainer>
                    <GridItemLabel value="NIPR ID" />
                  </GridItemLabelContainer>
                  <MaybeNotAvailable value={niprId}>
                    {(niprId_) => <Text value={niprId_} />}
                  </MaybeNotAvailable>
                </GridItem>
              </DataGrid>
              <Modal
                contentStyle={MODAL_CONTENT_STYLE}
                isOpen={displayForm}
                onClose={closeForm}
                title="Edit Individual Producer Details"
              >
                <UpdateIndividualProducerForm
                  response={getIndividualProducerResponse}
                  onSubmitted={handleIndividualProducerUpdated}
                />
              </Modal>
            </SecondarySectionContent>
          </SecondarySection>
          <ProducerJurisdictionsSection
            producerId={individualProducerId}
            onReloadProducerJurisdictions={
              onReloadIndividualProducerJurisdictions
            }
            getProducerJurisdictionsQuery={
              getIndividualProducerJurisdictionsQuery
            }
          />
          <ProducerNotesSection
            producerId={id}
            producerType={ProducerType.Individual}
            createNoteMutationFn={createIndividualProducerNoteMutationFn}
            onReloadProducerNotes={onReloadIndividualProducerNotes}
            getProducerNotesQuery={getIndividualProducerNotesQuery}
          />
        </PrimarySection>
      </BodyContent>
    </>
  );
};

export type UpdateIndividualProducerFormData = Required<
  Omit<
    UpdateIndividualProducerRequest,
    "parentAgencyProducerId" | "individualProducerId"
  >
> & {parentAgencyProducer: AgencyProducerFormData};

export const buildUpdateIndividualProducerFormInitialData = (
  response: GetIndividualProducerSuccessResponse,
): UpdateIndividualProducerFormData => ({
  name: response.name,
  niprId: response.niprId ? response.niprId : "",
  parentAgencyProducer: response.parentAgencyProducer,
});

export const mapUpdateIndividualProducerFormDataToUpdateIndividualProducerRequest =
  (
    individualProducer: GetIndividualProducerSuccessResponse,
    formData: UpdateIndividualProducerFormData,
  ): UpdateIndividualProducerRequest => {
    const {parentAgencyProducer, ...completeFormData} =
      UPDATE_INDIVIDUAL_PRODUCER_FORM_SCHEMA.validateSync(formData, {
        stripUnknown: true,
      });
    return {
      ...completeFormData,
      individualProducerId: individualProducer.id,
      parentAgencyProducerId: parentAgencyProducer.id,
    };
  };

export type CompleteUpdateIndividualProducerFormData = Omit<
  UpdateIndividualProducerRequest,
  "parentAgencyProducerId" | "individualProducerId"
> & {parentAgencyProducer: AgencyProducerReference};

export const UPDATE_INDIVIDUAL_PRODUCER_FORM_SCHEMA: Yup.ObjectSchema<CompleteUpdateIndividualProducerFormData> =
  Yup.object({
    name: Yup.string()
      .trim()
      .transform(transformNullToUndefined)
      .required("Individual Producer name is required"),
    niprId: Yup.string().trim().transform(transformNullToUndefined).optional(),
    parentAgencyProducer: buildRequiredAgencyProducerFormSchema(),
  });

export const UpdateIndividualProducerForm: React.FC<{
  AgencyProducerAutocomplete?: typeof AgencyProducerAutocomplete;
  onSubmitted: () => any;
  response: GetIndividualProducerSuccessResponse;
}> = ({
  onSubmitted,
  AgencyProducerAutocomplete:
    AgencyProducerAutocomplete_ = AgencyProducerAutocomplete,
  response,
}) => {
  const {adminApi} = useContext(DependencyContext);
  const updateIndividualProducerMutation = useMutation({
    mutationFn: adminApi.updateIndividualProducer,
  });
  const submitHandler = useCallback(
    (
      formData: UpdateIndividualProducerFormData,
      {setSubmitting}: FormikHelpers<UpdateIndividualProducerFormData>,
    ) => {
      const updateIndividualProducerRequest: UpdateIndividualProducerRequest =
        mapUpdateIndividualProducerFormDataToUpdateIndividualProducerRequest(
          response,
          formData,
        );
      updateIndividualProducerMutation.mutate(updateIndividualProducerRequest, {
        onSuccess: () => {
          onSubmitted();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [updateIndividualProducerMutation, onSubmitted, response],
  );
  return (
    <Formik<UpdateIndividualProducerFormData>
      initialValues={buildUpdateIndividualProducerFormInitialData(response)}
      validationSchema={UPDATE_INDIVIDUAL_PRODUCER_FORM_SCHEMA as any}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <FormikConnectedAmplifyTextField
            testId="name-input"
            label="Individual Producer Name"
            name="name"
          />

          <FormikConnectedAmplifyTextField
            testId="nipr-id-input"
            label="NIPR Number (optional)"
            name="niprId"
          />

          <AgencyProducerField
            label="Parent Agency"
            name="parentAgencyProducer"
            type={GetAgencyProducersAutocompleteType.Parent}
            AgencyProducerAutocomplete={AgencyProducerAutocomplete_}
          />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton />
      </FormikForm>
    </Formik>
  );
};
