import {Flex, TabItem, Tabs} from "@aws-amplify/ui-react";
import {
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {Query} from "@title-service/react-query-utils";
import {
  Button,
  DateTimeComponent,
  FormikConnectedAmplifyTextAreaField,
  FormikForm,
  Text,
  TextRaw,
} from "@title-service/ui";
import {Field, Formik, FormikHelpers, useFormikContext} from "formik";
import React, {useCallback, useContext, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import * as Yup from "yup";

import {DependencyContext} from "../DependencyContext";
import {
  AgencyProducerReference,
  FlagOrderRequest,
  GetOrderSuccessResponse,
  isOrderFlagActive,
  Order,
  OrderFlag,
} from "../shared/adminApi";
import {ColumnWrapper} from "../shared/components/ColumnWrapper";
import {
  DataGrid,
  GridItem,
  GridItemLabel,
  GridItemLabelContainer,
} from "../shared/components/DataGrid";
import {
  ArrayMaybeEmpty,
  StringMaybeBlankComponent,
} from "../shared/components/Nullable";
import {Tooltip, TooltipContainer} from "../shared/components/Tooltip";
import {YesNoComponent} from "../shared/components/YesNo";
import {CaretDirection, CaretIcon} from "../shared/components/icons";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
} from "../shared/layout";
import {AumTab} from "../shared/orderDetails/AumTab";
import {CplsTab} from "../shared/orderDetails/CplsTab";
import {OrderFlagButton} from "../shared/orderDetails/OrderFlagButton";
import {PoliciesTab} from "../shared/orderDetails/PoliciesTab";
import {RemittancesTab} from "../shared/orderDetails/RemittancesTab";
import {TransactionPartyTypeComponentRaw} from "../shared/orderDetails/TransactionPartyType";
import {TransactionTypeComponent} from "../shared/orderDetails/TransactionType";
import {UnderwritingTab} from "../shared/orderDetails/UnderwritingTab";

type GetOrderQueryKey = ["orders", string, "detail"];
type GetOrderQuery = Query<GetOrderSuccessResponse>;

const useGetOrder = (
  orderId: string,
): {
  getOrderQuery: GetOrderQuery;
  onReloadOrder: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);

  const getOrderQueryKey: GetOrderQueryKey = useMemo(
    () => ["orders", orderId, "detail"],
    [orderId],
  );
  const getOrderQueryFn: QueryFunction<
    GetOrderSuccessResponse,
    GetOrderQueryKey
  > = useCallback(
    ({queryKey: [_, orderId_], signal}) =>
      adminApi.getOrder({orderId: orderId_}, signal),
    [adminApi],
  );
  const getOrderQuery = useQuery({
    queryKey: getOrderQueryKey,
    queryFn: getOrderQueryFn,
    keepPreviousData: true,
  });
  const onReloadOrder = useCallback(
    () => queryClient.invalidateQueries({queryKey: getOrderQueryKey}),
    [queryClient, getOrderQueryKey],
  );
  return {getOrderQuery, onReloadOrder};
};

type OrderDetailRouteParams = {
  orderId: string;
};

export const OrderDetailRoute: React.FC<{
  OrderDetailQueryResult?: typeof OrderDetailQueryResult;
}> = ({
  OrderDetailQueryResult: OrderDetailQueryResult_ = OrderDetailQueryResult,
}) => {
  const {orderId} = useParams<
    keyof OrderDetailRouteParams
  >() as OrderDetailRouteParams;
  const {getOrderQuery, onReloadOrder} = useGetOrder(orderId);
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <OrderDetailQueryResult_
      onReloadOrder={onReloadOrder}
      getOrderQuery={getOrderQuery}
    />
  );
};

export const OrderDetailQueryResult: React.FC<{
  getOrderQuery: GetOrderQuery;
  onReloadOrder: () => any;
  OrderDetailSuccessComponent?: typeof OrderDetailSuccessComponent;
  OrderDetailFailureComponent?: typeof OrderDetailFailureComponent;
  OrderDetailLoadingComponent?: typeof OrderDetailLoadingComponent;
}> = ({
  getOrderQuery: {data, error},
  onReloadOrder,
  OrderDetailSuccessComponent:
    OrderDetailSuccessComponent_ = OrderDetailSuccessComponent,
  OrderDetailFailureComponent:
    OrderDetailFailureComponent_ = OrderDetailFailureComponent,
  OrderDetailLoadingComponent:
    OrderDetailLoadingComponent_ = OrderDetailLoadingComponent,
}) => {
  if (data) {
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <OrderDetailSuccessComponent_
        onReloadOrder={onReloadOrder}
        response={data}
      />
    );
  } else if (error) {
    // eslint-disable-next-line react/jsx-pascal-case
    return <OrderDetailFailureComponent_ />;
  }
  // eslint-disable-next-line react/jsx-pascal-case
  return <OrderDetailLoadingComponent_ />;
};

export const OrderDetailLoadingComponent: React.FC = () => (
  <BodyContent>
    <p>Loading Order...</p>
  </BodyContent>
);

export const OrderDetailFailureComponent: React.FC = () => (
  <BodyContent>
    <p>Failed to load Order.</p>
  </BodyContent>
);

export const OrderDetailSuccessComponent: React.FC<{
  response: GetOrderSuccessResponse;
  onReloadOrder: () => any;
}> = ({
  onReloadOrder,
  response: {
    agency,
    cpls,
    insurfulOrders,
    legalDescriptions,
    order,
    orderPermissions,
    policies,
    propertyDocuments,
    scores,
    underwritingDecisions,
    vestingInformation,
    flag,
  },
}) => (
  <>
    <OrderDetailBodyHeader
      order={order}
      agency={agency}
      flag={flag as OrderFlag}
      onReload={onReloadOrder}
    />
    <BodyContent>
      <Tabs>
        <TabItem title="CPLs">
          <CplsTab
            onReloadOrder={onReloadOrder}
            orderId={order.id}
            cpls={cpls}
            orderPermissions={orderPermissions}
          />
        </TabItem>
        <TabItem title="Policies">
          <PoliciesTab policies={policies} />
        </TabItem>
        <TabItem title="Remittances">
          <RemittancesTab
            orderId={order.id}
            cpls={cpls}
            policies={policies}
            orderPermissions={orderPermissions}
          />
        </TabItem>
        <TabItem title="AUM">
          <AumTab
            insurfulOrderSummaries={insurfulOrders}
            legalDescriptions={legalDescriptions}
            propertyDocuments={propertyDocuments}
            scores={scores}
            underwritingDecisions={underwritingDecisions}
            vestingInformation={vestingInformation}
          />
        </TabItem>
        <TabItem title="UW">
          <UnderwritingTab orderId={order.id} />
        </TabItem>
      </Tabs>
    </BodyContent>
  </>
);

const OrderDetailBodyHeader: React.FC<{
  order: Order;
  agency: AgencyProducerReference;
  flag: OrderFlag;
  onReload: () => any;
}> = ({
  order: {
    id,
    agencyProducerStateLicenseNumber,
    individualProducerStateLicenseNumber,
    agencyOrderStatus,
    createdAt,
    updatedAt,
    referenceId,
    sprucePowered,
    transactionParties,
    transactionType,
  },
  agency: {name: agencyName},
  flag,
  onReload,
}) => {
  const [showingDetail, setShowingDetail] = useState(true);

  const handleClick = useCallback(() => {
    setShowingDetail((prevShowingDetail) => !prevShowingDetail);
  }, []);

  return (
    <BodyHeader>
      <PrimaryBodyHeaderContainer>
        <Flex alignItems="center" gap="medium">
          <PrimaryBodyHeader value={`Order: ${referenceId}`} />
          <OrderFlaggingButton flag={flag} orderId={id} onReload={onReload} />
        </Flex>
        <CaretIcon
          fontSize="xs"
          onClick={handleClick}
          direction={showingDetail ? CaretDirection.DOWN : CaretDirection.RIGHT}
        />
      </PrimaryBodyHeaderContainer>
      {showingDetail ? (
        <DataGrid>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Agency Producer" />
            </GridItemLabelContainer>
            <Text value={agencyName} />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Individual Producer Status" />
            </GridItemLabelContainer>
            <Text value={agencyOrderStatus} />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Created" />
            </GridItemLabelContainer>
            <DateTimeComponent value={createdAt} />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Updated" />
            </GridItemLabelContainer>
            <DateTimeComponent value={updatedAt} />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Transaction Type" />
            </GridItemLabelContainer>
            <TransactionTypeComponent transactionType={transactionType} />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Spruce Powered" />
            </GridItemLabelContainer>
            <YesNoComponent value={sprucePowered} />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Transaction Parties" />
            </GridItemLabelContainer>
            <ArrayMaybeEmpty value={transactionParties}>
              {(t) => (
                <ColumnWrapper>
                  {t.map((transactionParty) => (
                    <Text
                      key={transactionParty.type}
                      value={
                        <>
                          <TransactionPartyTypeComponentRaw
                            transactionPartyType={transactionParty.type}
                          />
                          <TextRaw value=": " />
                          <TextRaw value={transactionParty.name} />
                        </>
                      }
                    />
                  ))}
                </ColumnWrapper>
              )}
            </ArrayMaybeEmpty>
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Agency Producer License Number" />
            </GridItemLabelContainer>
            <StringMaybeBlankComponent
              value={agencyProducerStateLicenseNumber}
            />
          </GridItem>
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Individual Producer License Number" />
            </GridItemLabelContainer>
            <StringMaybeBlankComponent
              value={individualProducerStateLicenseNumber}
            />
          </GridItem>
        </DataGrid>
      ) : null}
    </BodyHeader>
  );
};

export enum OrderFlagActionType {
  Flag = "flag",
  Unflag = "unflag",
}

export type OrderFlagFormData = Omit<FlagOrderRequest, "orderId"> & {
  actionType?: OrderFlagActionType.Flag | OrderFlagActionType.Unflag;
};

type CompleteOrderFlagFormData = Omit<FlagOrderRequest, "orderId" | "comment"> &
  Partial<Pick<FlagOrderRequest, "comment">>;

export const buildOrderFlagFormInitialData = (
  flag?: OrderFlag,
): OrderFlagFormData => ({
  comment: flag && "comment" in flag ? flag.comment : "",
  actionType: undefined,
});

export const ORDER_FLAG_FORM_SCHEMA: Yup.ObjectSchema<CompleteOrderFlagFormData> =
  Yup.object({
    comment: Yup.string()
      .trim()
      .when("actionType", ([actionType], builder) => {
        if (actionType === OrderFlagActionType.Flag) {
          return builder.required("Comment is required.");
        }
        return builder.transform(() => undefined);
      }),
    actionType: Yup.string().oneOf([
      OrderFlagActionType.Flag,
      OrderFlagActionType.Unflag,
    ]),
  });

export const mapFlagOrderFormDataToFlagOrderRequest = (
  orderId: string,
  formData: OrderFlagFormData,
): FlagOrderRequest => {
  const completeFormData: CompleteOrderFlagFormData =
    ORDER_FLAG_FORM_SCHEMA.validateSync(formData, {
      stripUnknown: true,
    });
  return {
    comment: completeFormData.comment!,
    orderId,
  };
};

export const CommentField: React.FC<
  Omit<
    React.ComponentProps<typeof FormikConnectedAmplifyTextAreaField>,
    "label"
  >
> = (props) => (
  <FormikConnectedAmplifyTextAreaField
    testId="order-flagging-textarea"
    label="Comment"
    rows={5}
    width={330}
    {...props}
  />
);

export const OrderFlaggingButtonsContainer: React.FC<
  React.ComponentProps<typeof Flex>
> = (props) => (
  <Flex
    direction="row"
    alignItems="center"
    justifyContent="flex-end"
    {...props}
  />
);

export const UnflagButton: React.FC<
  React.ComponentProps<typeof Button> & {
    isDisabled: boolean;
    onClick: () => any;
    value: string;
  }
> = ({value, isLoading, isDisabled, onClick, ...props}) => {
  const formik = useFormikContext();
  return (
    <FormikConnectedAmplifyOrderButton
      isLoading={formik.isSubmitting || isLoading}
      isDisabled={isDisabled}
      onClick={onClick}
      variation="link"
      loadingText="Unflagging..."
      value={value}
      {...props}
    />
  );
};

export const SaveFlagButton: React.FC<
  React.ComponentProps<typeof Button> & {
    isDisabled: boolean;
    onClick: () => any;
    value: string;
  }
> = ({value, isLoading, isDisabled, onClick, ...props}) => {
  const formik = useFormikContext();
  return (
    <FormikConnectedAmplifyOrderButton
      isLoading={formik.isSubmitting || isLoading}
      isDisabled={isDisabled}
      onClick={onClick}
      variation="primary"
      loadingText="Saving..."
      value={value}
      {...props}
    />
  );
};

export const FormikConnectedAmplifyOrderButton: React.FC<
  React.ComponentProps<typeof Button> & {
    isDisabled: boolean;
    variation: string;
    loadingText: string;
    value: string;
  }
> = ({isLoading, isDisabled, variation, loadingText, value, ...props}) => {
  const formik = useFormikContext();
  return (
    <Button
      isLoading={formik.isSubmitting || isLoading}
      isDisabled={isDisabled}
      type="submit"
      variation={variation}
      loadingText={loadingText}
      value={value}
      {...props}
    />
  );
};

export const OrderFlagForm: React.FC<{
  flagIsActive: boolean;
  flag: OrderFlag;
  orderId: string;
  closeModal: () => any;
  onReload: () => any;
}> = ({flagIsActive, flag, orderId, closeModal, onReload}) => {
  const {adminApi} = useContext(DependencyContext);
  const flagOrderMutation = useMutation({mutationFn: adminApi.flagOrder});
  const unflagOrderMutation = useMutation({mutationFn: adminApi.unflagOrder});
  const submitHandler = useCallback(
    (
      formData: OrderFlagFormData,
      {setSubmitting}: FormikHelpers<OrderFlagFormData>,
    ) => {
      const mutationOptions = {
        onSuccess: () => {
          closeModal();
          onReload();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      };
      switch (formData.actionType) {
        case OrderFlagActionType.Flag: {
          const request = mapFlagOrderFormDataToFlagOrderRequest(
            orderId,
            formData,
          );
          flagOrderMutation.mutate(request, mutationOptions);
          break;
        }
        case OrderFlagActionType.Unflag: {
          const request = {orderId};
          unflagOrderMutation.mutate(request, mutationOptions);
          break;
        }
        default: {
          break;
        }
      }
    },
    [orderId, flagOrderMutation, unflagOrderMutation, closeModal, onReload],
  );

  return (
    <Formik<OrderFlagFormData>
      initialValues={buildOrderFlagFormInitialData(flag)}
      validationSchema={ORDER_FLAG_FORM_SCHEMA as any}
      onSubmit={submitHandler}
    >
      {({setFieldValue, values}) => (
        <FormikForm>
          <Field type="hidden" name="actionType" />

          <CommentField name="comment" />

          <OrderFlaggingButtonsContainer>
            <UnflagButton
              data-testid="order-flagging-unflag-button"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => {
                setFieldValue("actionType", OrderFlagActionType.Unflag);
              }}
              isDisabled={!flagIsActive || flagOrderMutation.isLoading}
              value="Unflag"
            />

            <SaveFlagButton
              data-testid="order-flagging-save-button"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => {
                setFieldValue("actionType", OrderFlagActionType.Flag);
              }}
              isDisabled={
                !values.comment.trim().length || unflagOrderMutation.isLoading
              }
              value="Save"
            />
          </OrderFlaggingButtonsContainer>
        </FormikForm>
      )}
    </Formik>
  );
};

export const OrderFlaggingButton: React.FC<{
  flag?: OrderFlag;
  onReload: () => any;
  orderId: string;
  OrderFlagButton?: typeof OrderFlagButton;
}> = ({
  flag,
  onReload,
  orderId,
  OrderFlagButton: OrderFlagButton_ = OrderFlagButton,
}) => {
  const [isFlagModalOpen, setIsFlagModalOpen] = useState<boolean>(false);

  const closeModal = useCallback(() => {
    setIsFlagModalOpen(false);
  }, []);
  const toggleModalVisibility = useCallback(() => {
    setIsFlagModalOpen((v) => !v);
  }, []);

  const flagIsActive = !!flag && isOrderFlagActive(flag);
  const collapsableSectionPadding = {
    paddingTop: "medium",
    paddingBottom: "medium",
    paddingRight: "large",
    paddingLeft: "large",
  };
  return (
    <TooltipContainer onClose={closeModal}>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <OrderFlagButton_
        data-testid="order-flagging-button"
        onClick={toggleModalVisibility}
        active={flagIsActive}
      />
      {isFlagModalOpen ? (
        <Tooltip>
          <Flex
            testId="order-flagging-modal"
            direction="column"
            gap="medium"
            width="100%"
            {...collapsableSectionPadding}
          >
            <OrderFlagForm
              flagIsActive={flagIsActive}
              flag={flag!}
              orderId={orderId}
              closeModal={closeModal}
              onReload={onReload}
            />
          </Flex>
        </Tooltip>
      ) : null}
    </TooltipContainer>
  );
};
