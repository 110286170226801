/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidationReportDetailFigures
 */
export interface ValidationReportDetailFigures {
    /**
     * 
     * @type {string}
     * @memberof ValidationReportDetailFigures
     */
    accuracy: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationReportDetailFigures
     */
    confusionMatrix: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationReportDetailFigures
     */
    featureImportance: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationReportDetailFigures
     */
    histogram: string;
}

/**
 * Check if a given object implements the ValidationReportDetailFigures interface.
 */
export function instanceOfValidationReportDetailFigures(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accuracy" in value;
    isInstance = isInstance && "confusionMatrix" in value;
    isInstance = isInstance && "featureImportance" in value;
    isInstance = isInstance && "histogram" in value;

    return isInstance;
}

export function ValidationReportDetailFiguresFromJSON(json: any): ValidationReportDetailFigures {
    return ValidationReportDetailFiguresFromJSONTyped(json, false);
}

export function ValidationReportDetailFiguresFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationReportDetailFigures {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accuracy': json['accuracy'],
        'confusionMatrix': json['confusionMatrix'],
        'featureImportance': json['featureImportance'],
        'histogram': json['histogram'],
    };
}

export function ValidationReportDetailFiguresToJSON(value?: ValidationReportDetailFigures | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accuracy': value.accuracy,
        'confusionMatrix': value.confusionMatrix,
        'featureImportance': value.featureImportance,
        'histogram': value.histogram,
    };
}

