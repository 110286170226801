/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetClaimAttachmentsSuccessResponseAttachment
 */
export interface GetClaimAttachmentsSuccessResponseAttachment {
    /**
     * 
     * @type {string}
     * @memberof GetClaimAttachmentsSuccessResponseAttachment
     */
    artifactUrl: string;
    /**
     * 
     * @type {Date}
     * @memberof GetClaimAttachmentsSuccessResponseAttachment
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof GetClaimAttachmentsSuccessResponseAttachment
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimAttachmentsSuccessResponseAttachment
     */
    id: string;
}

/**
 * Check if a given object implements the GetClaimAttachmentsSuccessResponseAttachment interface.
 */
export function instanceOfGetClaimAttachmentsSuccessResponseAttachment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "artifactUrl" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function GetClaimAttachmentsSuccessResponseAttachmentFromJSON(json: any): GetClaimAttachmentsSuccessResponseAttachment {
    return GetClaimAttachmentsSuccessResponseAttachmentFromJSONTyped(json, false);
}

export function GetClaimAttachmentsSuccessResponseAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetClaimAttachmentsSuccessResponseAttachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'artifactUrl': json['artifactUrl'],
        'createdAt': (new Date(json['createdAt'])),
        'filename': json['filename'],
        'id': json['id'],
    };
}

export function GetClaimAttachmentsSuccessResponseAttachmentToJSON(value?: GetClaimAttachmentsSuccessResponseAttachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'artifactUrl': value.artifactUrl,
        'createdAt': (value.createdAt.toISOString()),
        'filename': value.filename,
        'id': value.id,
    };
}

