/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RenewProducerLicenseRequest
 */
export interface RenewProducerLicenseRequest {
    /**
     * 
     * @type {Date}
     * @memberof RenewProducerLicenseRequest
     */
    effectiveDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof RenewProducerLicenseRequest
     */
    expirationDate: Date;
    /**
     * 
     * @type {string}
     * @memberof RenewProducerLicenseRequest
     */
    licenseNumber: string;
}

/**
 * Check if a given object implements the RenewProducerLicenseRequest interface.
 */
export function instanceOfRenewProducerLicenseRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "effectiveDate" in value;
    isInstance = isInstance && "expirationDate" in value;
    isInstance = isInstance && "licenseNumber" in value;

    return isInstance;
}

export function RenewProducerLicenseRequestFromJSON(json: any): RenewProducerLicenseRequest {
    return RenewProducerLicenseRequestFromJSONTyped(json, false);
}

export function RenewProducerLicenseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenewProducerLicenseRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'effectiveDate': (new Date(json['effectiveDate'])),
        'expirationDate': (new Date(json['expirationDate'])),
        'licenseNumber': json['licenseNumber'],
    };
}

export function RenewProducerLicenseRequestToJSON(value?: RenewProducerLicenseRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'effectiveDate': (value.effectiveDate.toISOString().substr(0,10)),
        'expirationDate': (value.expirationDate.toISOString().substr(0,10)),
        'licenseNumber': value.licenseNumber,
    };
}

