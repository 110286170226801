import React from "react";

import {PolicyStatus} from "../adminApi";
import {SuccessPill} from "../components/Pills";
import {safeDisplayEnumFn} from "../util/enum";

export const PolicyStatusPill: React.FC<{status: PolicyStatus}> = ({
  status,
}) => <SuccessPill label={displayPolicyStatus(status)} />;

const unsafeDisplayPolicyStatus = (policyStatus: PolicyStatus) => {
  switch (policyStatus) {
    case PolicyStatus.Quoted:
      return "Quoted";
    case PolicyStatus.Issued:
      return "Issued";
    default:
      throw new Error(`Unknown Policy Status: ${policyStatus}`);
  }
};

const displayPolicyStatus = safeDisplayEnumFn(unsafeDisplayPolicyStatus);
