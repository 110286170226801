/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TransactionPartyType } from './TransactionPartyType';
import {
    TransactionPartyTypeFromJSON,
    TransactionPartyTypeFromJSONTyped,
    TransactionPartyTypeToJSON,
} from './TransactionPartyType';

/**
 * 
 * @export
 * @interface TransactionParty
 */
export interface TransactionParty {
    /**
     * 
     * @type {string}
     * @memberof TransactionParty
     */
    name: string;
    /**
     * 
     * @type {TransactionPartyType}
     * @memberof TransactionParty
     */
    type: TransactionPartyType;
}

/**
 * Check if a given object implements the TransactionParty interface.
 */
export function instanceOfTransactionParty(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function TransactionPartyFromJSON(json: any): TransactionParty {
    return TransactionPartyFromJSONTyped(json, false);
}

export function TransactionPartyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionParty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': TransactionPartyTypeFromJSON(json['type']),
    };
}

export function TransactionPartyToJSON(value?: TransactionParty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': TransactionPartyTypeToJSON(value.type),
    };
}

