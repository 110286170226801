/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidationReportDetailComparisonFeatures
 */
export interface ValidationReportDetailComparisonFeatures {
    /**
     * 
     * @type {string}
     * @memberof ValidationReportDetailComparisonFeatures
     */
    added: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationReportDetailComparisonFeatures
     */
    removed: string;
}

/**
 * Check if a given object implements the ValidationReportDetailComparisonFeatures interface.
 */
export function instanceOfValidationReportDetailComparisonFeatures(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "added" in value;
    isInstance = isInstance && "removed" in value;

    return isInstance;
}

export function ValidationReportDetailComparisonFeaturesFromJSON(json: any): ValidationReportDetailComparisonFeatures {
    return ValidationReportDetailComparisonFeaturesFromJSONTyped(json, false);
}

export function ValidationReportDetailComparisonFeaturesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationReportDetailComparisonFeatures {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'added': json['added'],
        'removed': json['removed'],
    };
}

export function ValidationReportDetailComparisonFeaturesToJSON(value?: ValidationReportDetailComparisonFeatures | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'added': value.added,
        'removed': value.removed,
    };
}

