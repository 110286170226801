import {PageHeaderContainer, SiteLogo} from "@title-service/ui";
import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";

import {RouterLink} from "../components/Link";
import {Tooltip, TooltipContainer} from "../components/Tooltip";
import {DropdownItem} from "../components/forms/Dropdown";
import {COLOR_P_500, FONT_SIZE_H5, SPACING_12} from "../theme";

const RECORDS: PageHeaderMenuItem[] = [
  {
    label: "Orders",
    href: "/",
  },
  {
    label: "CPLs",
    href: "/cpls",
  },
  {
    label: "Policies",
    href: "/policies",
  },
  {
    label: "Claims",
    href: "/claims",
  },
  {
    label: "Audits",
    href: "/audits",
  },
  {
    label: "Audit Exports",
    href: "/audits/exports",
  },
  {
    label: "Producers",
    href: "/producers",
  },
];

const SCORING: PageHeaderMenuItem[] = [
  {
    label: "Property Search",
    href: "/properties/search",
  },
  {
    label: "Trained Models",
    href: "/trainedModels",
  },
  {
    label: "Validation Reports",
    href: "/validationReports",
  },
];

export const PageHeader: React.FC = () => (
  <PageHeaderContainer alignItems="center">
    <SiteLogo Link={RouterLink} />
    <NavRight>
      <NavItem>
        <PageHeaderMenu label="Records" items={RECORDS} />
      </NavItem>
      <NavItem>
        <PageHeaderMenu label="Scoring Model" items={SCORING} />
      </NavItem>
      <NavItem>
        <NavLink to="/documents">Documents</NavLink>
      </NavItem>
    </NavRight>
  </PageHeaderContainer>
);

const NavRight = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "right",
  alignItems: "center",
});

const NavItem = styled.li({
  display: "inline",
  paddingLeft: SPACING_12,
});

const NavLink = styled(Link)(
  {
    color: "inherit",
    textDecoration: "none",
    fontWeight: "bold",
    ...FONT_SIZE_H5,
  },
  `
  :hover {
    color: ${COLOR_P_500};
  }
  `,
);

type PageHeaderMenuItem = {
  href: string;
  label: string;
};

type PageHeaderMenuProps = {
  label: string;
  children?: never;
  items: PageHeaderMenuItem[];
};

type PageHeaderMenuState = {
  active: boolean;
};

class PageHeaderMenu extends React.Component<
  PageHeaderMenuProps,
  PageHeaderMenuState
> {
  constructor(props: PageHeaderMenuProps) {
    super(props);
    this.state = {
      active: false,
    };
  }

  render() {
    return (
      <TooltipContainer onClose={this.handleTooltipClose}>
        <PageHeaderMenuLabel onClick={this.handleLabelClick}>
          {this.props.label}
        </PageHeaderMenuLabel>
        {this.state.active ? (
          <Tooltip>
            <ul>
              {this.props.items.map(({href, label}, i) => (
                <ManagedDropdownItem
                  key={`${i}-${label}`}
                  onSelected={this.handleItemSelected}
                >
                  <PageHeaderMenuItemLink to={href}>
                    {label}
                  </PageHeaderMenuItemLink>
                </ManagedDropdownItem>
              ))}
            </ul>
          </Tooltip>
        ) : null}
      </TooltipContainer>
    );
  }

  private readonly handleLabelClick = () => {
    this.setState(({active}) => ({active: !active}));
  };

  private readonly handleItemSelected = () => {
    this.setState({active: false});
  };

  private readonly handleTooltipClose = () => {
    this.setState({active: false});
  };
}

const PageHeaderMenuLabel = styled.span(
  {
    cursor: "pointer",
    fontWeight: "bold",
    ...FONT_SIZE_H5,
  },
  `
  :hover {
   color: ${COLOR_P_500};
  }
  `,
);

type ManagedDropdownItemProps = {
  onSelected: () => any;
};

type ManagedDropdownItemState = {
  active: boolean;
};

class ManagedDropdownItem extends React.Component<
  ManagedDropdownItemProps,
  ManagedDropdownItemState
> {
  constructor(props: ManagedDropdownItemProps) {
    super(props);
    this.state = {
      active: false,
    };
  }

  render() {
    return (
      <DropdownItem
        active={this.state.active}
        onSelected={this.handleSelected}
        onActivated={this.handleActivated}
        onDeactivated={this.handleDeactivated}
      >
        {this.props.children}
      </DropdownItem>
    );
  }

  private readonly handleSelected = () => {
    this.props.onSelected();
  };

  private readonly handleActivated = () => {
    this.setState({active: true});
  };

  private readonly handleDeactivated = () => {
    this.setState({active: false});
  };
}

const PageHeaderMenuItemLink = styled(Link)({
  color: "inherit",
  textDecoration: "none",
  whiteSpace: "nowrap",
  ...FONT_SIZE_H5,
});
