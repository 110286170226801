/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TransactionPartyType = {
    Lender: 'lender',
    Buyer: 'buyer',
    Seller: 'seller',
    Borrower: 'borrower'
} as const;
export type TransactionPartyType = typeof TransactionPartyType[keyof typeof TransactionPartyType];


export function TransactionPartyTypeFromJSON(json: any): TransactionPartyType {
    return TransactionPartyTypeFromJSONTyped(json, false);
}

export function TransactionPartyTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionPartyType {
    return json as TransactionPartyType;
}

export function TransactionPartyTypeToJSON(value?: TransactionPartyType | null): any {
    return value as any;
}

