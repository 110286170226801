/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderVestingInformationNotAvailableReason = {
    PropertyDocumentRejected: 'property_document_rejected',
    QuitClaimAfterLfvt: 'quit_claim_after_lfvt'
} as const;
export type OrderVestingInformationNotAvailableReason = typeof OrderVestingInformationNotAvailableReason[keyof typeof OrderVestingInformationNotAvailableReason];


export function OrderVestingInformationNotAvailableReasonFromJSON(json: any): OrderVestingInformationNotAvailableReason {
    return OrderVestingInformationNotAvailableReasonFromJSONTyped(json, false);
}

export function OrderVestingInformationNotAvailableReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderVestingInformationNotAvailableReason {
    return json as OrderVestingInformationNotAvailableReason;
}

export function OrderVestingInformationNotAvailableReasonToJSON(value?: OrderVestingInformationNotAvailableReason | null): any {
    return value as any;
}

