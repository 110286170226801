import {Autocomplete, ComboBoxOption} from "@aws-amplify/ui-react";
import {QueryFunction, useQuery} from "@tanstack/react-query";
import React, {useCallback, useContext} from "react";
import {useDebounce} from "use-debounce";

import {DependencyContext} from "../../DependencyContext";
import {
  AddressSuggestion,
  GetAddressSuggestionsRequest,
} from "../addressAutocompleteApi";

type AutocompleteProps = React.ComponentProps<typeof Autocomplete>;
type GetAddressSuggestionsQueryKey = [
  "getAddressSuggestions",
  GetAddressSuggestionsRequest,
];

const SEARCH_DEBOUNCE_MS = 300;

export const AddressAutocomplete: React.FC<
  Omit<AutocompleteProps, "options" | "onSelect" | "onClear"> &
    Required<Pick<AutocompleteProps, "value" | "onChange">> & {
      onAddressSelected: (addressSuggestion?: AddressSuggestion) => any;
      debounceMs?: number;
      searchOptions?: Omit<GetAddressSuggestionsRequest, "searchTerm">;
      Autocomplete?: typeof Autocomplete;
    }
> = ({
  onAddressSelected,
  searchOptions,
  debounceMs = SEARCH_DEBOUNCE_MS,
  Autocomplete: Autocomplete_ = Autocomplete,
  ...autocompleteProps
}) => {
  const {addressAutocompleteApi} = useContext(DependencyContext);
  const getAddressSuggestionsQueryFn: QueryFunction<
    AddressSuggestion[],
    GetAddressSuggestionsQueryKey
  > = useCallback(
    ({queryKey: [_, _request], signal}) =>
      addressAutocompleteApi.getAddressSuggestions(_request, signal),
    [addressAutocompleteApi],
  );
  const [debouncedValue] = useDebounce(autocompleteProps.value, debounceMs);
  const request: GetAddressSuggestionsRequest = {
    ...searchOptions,
    searchTerm: debouncedValue,
  };
  const getAddressSuggestionsQuery = useQuery({
    queryKey: ["getAddressSuggestions", request],
    queryFn: getAddressSuggestionsQueryFn,
    enabled: !!request.searchTerm,
  });

  const onSelect = useCallback(
    ({id, label, ...addressSuggestion}: ComboBoxOption & AddressSuggestion) => {
      onAddressSelected(addressSuggestion);
    },
    [onAddressSelected],
  );

  const onClear = useCallback(() => {
    onAddressSelected(undefined);
  }, [onAddressSelected]);

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <Autocomplete_
      onSelect={onSelect as AutocompleteProps["onSelect"]}
      onClear={onClear}
      options={(getAddressSuggestionsQuery.data ?? []).map(
        (addressSuggestion, i) => ({
          id: i.toString(10),
          label: `${addressSuggestion.street}, ${addressSuggestion.city}, ${addressSuggestion.state}`,
          ...addressSuggestion,
        }),
      )}
      isLoading={getAddressSuggestionsQuery.isFetching}
      {...autocompleteProps}
    />
  );
};
