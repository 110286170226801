import {datadogLogs} from "@datadog/browser-logs";
import {QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {
  Body,
  LayoutErrorBoundary,
  Root,
  ThemeProvider,
} from "@title-service/ui";
import React, {useCallback} from "react";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useParams,
  useSearchParams,
} from "react-router-dom";

import {Dependencies, DependencyContext} from "./DependencyContext";
import {AgencyProducerDetailRoute} from "./pages/AgencyProducerDetail";
import {AuditCreateRoute} from "./pages/AuditCreate";
import {AuditDetailRoute} from "./pages/AuditDetail";
import {AuditExportDetailRoute} from "./pages/AuditExportDetail";
import {AuditExportsRoute} from "./pages/AuditExports";
import {AuditsRoute} from "./pages/Audits";
import {ClaimCreateRoute} from "./pages/ClaimCreate";
import {ClaimDetailRoute} from "./pages/ClaimDetail";
import {ClaimsRoute} from "./pages/Claims";
import {CplsRoute} from "./pages/Cpls";
import {IndividualProducerDetailRoute} from "./pages/IndividualProducerDetail";
import {OrderDetailRoute} from "./pages/OrderDetail";
import {OrdersRoute} from "./pages/Orders";
import {PoliciesRoute} from "./pages/Policies";
import {ProducersRoute} from "./pages/Producers";
import {PropertyDetailRoute} from "./pages/PropertyDetail";
import {PropertyDocumentsRoute} from "./pages/PropertyDocuments";
import {PropertySearchRoute} from "./pages/PropertySearch";
import {TrainedModelsRoute} from "./pages/TrainedModels";
import {ValidationReportsRoute} from "./pages/ValidationReports";
import {
  AuthenticationContextProvider,
  EnsureAuthenticated,
} from "./shared/authentication";
import {BodyLoading} from "./shared/components/BodyLoading";
import {PageHeader} from "./shared/layout";

/*
 * Lazy load ValidationReportDetailPage so that Vega is separate from the main bundle.
 */
const ValidationReportDetailPage = React.lazy(
  async () => import("./pages/ValidationReportDetail"),
);

export const ValidationReportDetailRoute: React.FC = () => {
  type ValidationReportDetailPageParams = {
    reportId: string;
  };
  const {reportId} = useParams<
    keyof ValidationReportDetailPageParams
  >() as ValidationReportDetailPageParams;
  return (
    <React.Suspense fallback={<BodyLoading />}>
      <ValidationReportDetailPage reportId={reportId} />
    </React.Suspense>
  );
};

/*
 * Lazy load PropertyDocumentDetailPage so pdfjs is separate from the main bundle.
 */
const PropertyDocumentDetailPage = React.lazy(async () => {
  const {pdfjs} = await import("react-pdf");

  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.js",
    import.meta.url,
  ).toString();

  // eslint-disable-next-line @typescript-eslint/return-await
  return await import("./pages/PropertyDocumentDetail");
});

export const PropertyDocumentDetailRoute: React.FC = () => {
  type PropertyDocumentDetailPageParams = {
    documentId: string;
  };
  const {documentId} = useParams<
    keyof PropertyDocumentDetailPageParams
  >() as PropertyDocumentDetailPageParams;
  const [searchParams] = useSearchParams();
  return (
    <React.Suspense fallback={<BodyLoading />}>
      <PropertyDocumentDetailPage
        documentId={documentId}
        sequenceId={searchParams.get("sequenceId") ?? null}
      />
    </React.Suspense>
  );
};

const Layout: React.FC = () => {
  const handleError = useCallback(
    (error: Error, errorInfo: React.ErrorInfo) => {
      datadogLogs.logger.error(error.message, errorInfo);
    },
    [],
  );
  return (
    <LayoutErrorBoundary onError={handleError}>
      <Root>
        <PageHeader />
        <Body>
          <EnsureAuthenticated>
            <Outlet />
          </EnsureAuthenticated>
        </Body>
      </Root>
    </LayoutErrorBoundary>
  );
};

export type AppProps = {
  dependencies: Dependencies;
};

export const App: React.FC<AppProps> = ({dependencies}) => (
  <QueryClientProvider client={dependencies.queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <DependencyContext.Provider value={dependencies}>
      <AuthenticationContextProvider>
        <ThemeProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<OrdersRoute />} />
                <Route path="/orders/:orderId" element={<OrderDetailRoute />} />
                <Route path="/policies" element={<PoliciesRoute />} />
                <Route path="/cpls" element={<CplsRoute />} />
                <Route
                  path="/properties/search"
                  element={<PropertySearchRoute />}
                />
                <Route
                  path="/properties/:attomId"
                  element={<PropertyDetailRoute />}
                />
                <Route
                  path="/validationReports"
                  element={<ValidationReportsRoute />}
                />
                <Route
                  path="/validationReports/:reportId"
                  element={<ValidationReportDetailRoute />}
                />
                <Route path="/trainedModels" element={<TrainedModelsRoute />} />
                <Route path="/documents" element={<PropertyDocumentsRoute />} />
                <Route
                  path="/documents/:documentId"
                  element={<PropertyDocumentDetailRoute />}
                />
                <Route path="/claims" element={<ClaimsRoute />} />
                <Route path="/claims/new" element={<ClaimCreateRoute />} />
                <Route path="/claims/:claimId" element={<ClaimDetailRoute />} />
                <Route path="/audits/exports" element={<AuditExportsRoute />} />
                <Route
                  path="/audits/exports/:auditExportId"
                  element={<AuditExportDetailRoute />}
                />
                <Route path="/audits" element={<AuditsRoute />} />
                <Route path="/audits/new" element={<AuditCreateRoute />} />
                <Route path="/audits/:auditId" element={<AuditDetailRoute />} />
                <Route path="/producers" element={<ProducersRoute />} />
                <Route
                  path="/producers/agencies/:agencyProducerId"
                  element={<AgencyProducerDetailRoute />}
                />
                <Route
                  path="/producers/individuals/:individualProducerId"
                  element={<IndividualProducerDetailRoute />}
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </AuthenticationContextProvider>
    </DependencyContext.Provider>
  </QueryClientProvider>
);
