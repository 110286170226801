/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GetAuditExportItemsSortField = {
    Sequence: 'SEQUENCE'
} as const;
export type GetAuditExportItemsSortField = typeof GetAuditExportItemsSortField[keyof typeof GetAuditExportItemsSortField];


export function GetAuditExportItemsSortFieldFromJSON(json: any): GetAuditExportItemsSortField {
    return GetAuditExportItemsSortFieldFromJSONTyped(json, false);
}

export function GetAuditExportItemsSortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAuditExportItemsSortField {
    return json as GetAuditExportItemsSortField;
}

export function GetAuditExportItemsSortFieldToJSON(value?: GetAuditExportItemsSortField | null): any {
    return value as any;
}

