/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidationReportDetailMetadata
 */
export interface ValidationReportDetailMetadata {
    /**
     * 
     * @type {string}
     * @memberof ValidationReportDetailMetadata
     */
    reportFormat: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationReportDetailMetadata
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationReportDetailMetadata
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof ValidationReportDetailMetadata
     */
    totalIds: number;
    /**
     * 
     * @type {Date}
     * @memberof ValidationReportDetailMetadata
     */
    trainedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ValidationReportDetailMetadata
     */
    validatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ValidationReportDetailMetadata
     */
    version: string;
}

/**
 * Check if a given object implements the ValidationReportDetailMetadata interface.
 */
export function instanceOfValidationReportDetailMetadata(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reportFormat" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "totalIds" in value;
    isInstance = isInstance && "trainedAt" in value;
    isInstance = isInstance && "validatedAt" in value;
    isInstance = isInstance && "version" in value;

    return isInstance;
}

export function ValidationReportDetailMetadataFromJSON(json: any): ValidationReportDetailMetadata {
    return ValidationReportDetailMetadataFromJSONTyped(json, false);
}

export function ValidationReportDetailMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationReportDetailMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reportFormat': json['reportFormat'],
        'state': json['state'],
        'title': json['title'],
        'totalIds': json['totalIds'],
        'trainedAt': (new Date(json['trainedAt'])),
        'validatedAt': (new Date(json['validatedAt'])),
        'version': json['version'],
    };
}

export function ValidationReportDetailMetadataToJSON(value?: ValidationReportDetailMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reportFormat': value.reportFormat,
        'state': value.state,
        'title': value.title,
        'totalIds': value.totalIds,
        'trainedAt': (value.trainedAt.toISOString()),
        'validatedAt': (value.validatedAt.toISOString()),
        'version': value.version,
    };
}

