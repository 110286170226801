/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    currentPage: number;
    /**
     * 
     * @type {boolean}
     * @memberof Pagination
     */
    isFirstPage: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Pagination
     */
    isLastPage: boolean;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    totalRecords: number;
}

/**
 * Check if a given object implements the Pagination interface.
 */
export function instanceOfPagination(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currentPage" in value;
    isInstance = isInstance && "isFirstPage" in value;
    isInstance = isInstance && "isLastPage" in value;
    isInstance = isInstance && "totalPages" in value;
    isInstance = isInstance && "totalRecords" in value;

    return isInstance;
}

export function PaginationFromJSON(json: any): Pagination {
    return PaginationFromJSONTyped(json, false);
}

export function PaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentPage': json['currentPage'],
        'isFirstPage': json['isFirstPage'],
        'isLastPage': json['isLastPage'],
        'totalPages': json['totalPages'],
        'totalRecords': json['totalRecords'],
    };
}

export function PaginationToJSON(value?: Pagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentPage': value.currentPage,
        'isFirstPage': value.isFirstPage,
        'isLastPage': value.isLastPage,
        'totalPages': value.totalPages,
        'totalRecords': value.totalRecords,
    };
}

