import {
  OrderVestingInformationNotAvailable as FetchOrderVestingInformationNotAvailable,
  OrderVestingInformationSuccess as FetchOrderVestingInformationSuccess,
} from "../fetchClient";

const ORDER_VESTING_INFORMATION_SUCCESS_STATUS = "success" as const;

export type OrderVestingInformationSuccess =
  FetchOrderVestingInformationSuccess & {
    status: typeof ORDER_VESTING_INFORMATION_SUCCESS_STATUS;
  };

const ORDER_VESTING_INFORMATION_NOT_AVAILABLE_STATUS = "not_available" as const;

export type OrderVestingInformationNotAvailable =
  FetchOrderVestingInformationNotAvailable & {
    status: typeof ORDER_VESTING_INFORMATION_NOT_AVAILABLE_STATUS;
  };

export type OrderVestingInformation =
  | OrderVestingInformationSuccess
  | OrderVestingInformationNotAvailable;

export const isOrderVestingInformationSuccess = (
  result: OrderVestingInformation,
): result is OrderVestingInformationSuccess =>
  result.status === ORDER_VESTING_INFORMATION_SUCCESS_STATUS;

export const isOrderVestingInformationNotAvailable = (
  result: OrderVestingInformation,
): result is OrderVestingInformationNotAvailable =>
  result.status === ORDER_VESTING_INFORMATION_NOT_AVAILABLE_STATUS;
