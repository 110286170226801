/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClaimCostType } from './ClaimCostType';
import {
    ClaimCostTypeFromJSON,
    ClaimCostTypeFromJSONTyped,
    ClaimCostTypeToJSON,
} from './ClaimCostType';

/**
 * 
 * @export
 * @interface GetClaimReservesSuccessResponseReserve
 */
export interface GetClaimReservesSuccessResponseReserve {
    /**
     * 
     * @type {number}
     * @memberof GetClaimReservesSuccessResponseReserve
     */
    changeAmount: number;
    /**
     * 
     * @type {Date}
     * @memberof GetClaimReservesSuccessResponseReserve
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof GetClaimReservesSuccessResponseReserve
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimReservesSuccessResponseReserve
     */
    id: string;
    /**
     * 
     * @type {ClaimCostType}
     * @memberof GetClaimReservesSuccessResponseReserve
     */
    reserveType: ClaimCostType;
}

/**
 * Check if a given object implements the GetClaimReservesSuccessResponseReserve interface.
 */
export function instanceOfGetClaimReservesSuccessResponseReserve(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "changeAmount" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "reserveType" in value;

    return isInstance;
}

export function GetClaimReservesSuccessResponseReserveFromJSON(json: any): GetClaimReservesSuccessResponseReserve {
    return GetClaimReservesSuccessResponseReserveFromJSONTyped(json, false);
}

export function GetClaimReservesSuccessResponseReserveFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetClaimReservesSuccessResponseReserve {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'changeAmount': json['changeAmount'],
        'createdAt': (new Date(json['createdAt'])),
        'description': json['description'],
        'id': json['id'],
        'reserveType': ClaimCostTypeFromJSON(json['reserveType']),
    };
}

export function GetClaimReservesSuccessResponseReserveToJSON(value?: GetClaimReservesSuccessResponseReserve | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'changeAmount': value.changeAmount,
        'createdAt': (value.createdAt.toISOString()),
        'description': value.description,
        'id': value.id,
        'reserveType': ClaimCostTypeToJSON(value.reserveType),
    };
}

