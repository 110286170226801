import {notNullOrUndefined} from "./nullability";

export const arrayNotEmpty = <T>(array: T[] | null | undefined): array is T[] =>
  notNullOrUndefined(array) && array.length > 0;

if (import.meta.vitest) {
  const {describe, expect, it} = import.meta.vitest;

  describe("arrayNotEmpty", () => {
    it("returns false if null", () => {
      expect(arrayNotEmpty(null)).toEqual(false);
    });

    it("returns false if undefined", () => {
      expect(arrayNotEmpty(undefined)).toEqual(false);
    });

    it("returns false if empty", () => {
      expect(arrayNotEmpty([])).toEqual(false);
    });

    it("returns true when not empty", () => {
      expect(arrayNotEmpty([1])).toEqual(true);
    });
  });
}
