/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnApprovePropertyDocumentLegalDescriptionRequest
 */
export interface UnApprovePropertyDocumentLegalDescriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof UnApprovePropertyDocumentLegalDescriptionRequest
     */
    legalDescriptionId: string;
}

/**
 * Check if a given object implements the UnApprovePropertyDocumentLegalDescriptionRequest interface.
 */
export function instanceOfUnApprovePropertyDocumentLegalDescriptionRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "legalDescriptionId" in value;

    return isInstance;
}

export function UnApprovePropertyDocumentLegalDescriptionRequestFromJSON(json: any): UnApprovePropertyDocumentLegalDescriptionRequest {
    return UnApprovePropertyDocumentLegalDescriptionRequestFromJSONTyped(json, false);
}

export function UnApprovePropertyDocumentLegalDescriptionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnApprovePropertyDocumentLegalDescriptionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'legalDescriptionId': json['legalDescriptionId'],
    };
}

export function UnApprovePropertyDocumentLegalDescriptionRequestToJSON(value?: UnApprovePropertyDocumentLegalDescriptionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'legalDescriptionId': value.legalDescriptionId,
    };
}

