/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RemittanceItem } from './RemittanceItem';
import {
    RemittanceItemFromJSON,
    RemittanceItemFromJSONTyped,
    RemittanceItemToJSON,
} from './RemittanceItem';

/**
 * 
 * @export
 * @interface GetRemittanceItemsSuccessResponse
 */
export interface GetRemittanceItemsSuccessResponse {
    /**
     * 
     * @type {Array<RemittanceItem>}
     * @memberof GetRemittanceItemsSuccessResponse
     */
    items: Array<RemittanceItem>;
}

/**
 * Check if a given object implements the GetRemittanceItemsSuccessResponse interface.
 */
export function instanceOfGetRemittanceItemsSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function GetRemittanceItemsSuccessResponseFromJSON(json: any): GetRemittanceItemsSuccessResponse {
    return GetRemittanceItemsSuccessResponseFromJSONTyped(json, false);
}

export function GetRemittanceItemsSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRemittanceItemsSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(RemittanceItemFromJSON)),
    };
}

export function GetRemittanceItemsSuccessResponseToJSON(value?: GetRemittanceItemsSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(RemittanceItemToJSON)),
    };
}

