/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOrderUnderwritingAttachmentRequest
 */
export interface CreateOrderUnderwritingAttachmentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderUnderwritingAttachmentRequest
     */
    fileUploadDropBoxObjectKey: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderUnderwritingAttachmentRequest
     */
    filename: string;
}

/**
 * Check if a given object implements the CreateOrderUnderwritingAttachmentRequest interface.
 */
export function instanceOfCreateOrderUnderwritingAttachmentRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fileUploadDropBoxObjectKey" in value;
    isInstance = isInstance && "filename" in value;

    return isInstance;
}

export function CreateOrderUnderwritingAttachmentRequestFromJSON(json: any): CreateOrderUnderwritingAttachmentRequest {
    return CreateOrderUnderwritingAttachmentRequestFromJSONTyped(json, false);
}

export function CreateOrderUnderwritingAttachmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrderUnderwritingAttachmentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileUploadDropBoxObjectKey': json['fileUploadDropBoxObjectKey'],
        'filename': json['filename'],
    };
}

export function CreateOrderUnderwritingAttachmentRequestToJSON(value?: CreateOrderUnderwritingAttachmentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileUploadDropBoxObjectKey': value.fileUploadDropBoxObjectKey,
        'filename': value.filename,
    };
}

