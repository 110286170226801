import {Loader, useTheme} from "@aws-amplify/ui-react";
import React from "react";
import styled from "styled-components";

export const LoadingOverlay: React.FC<{isActive: boolean}> = ({
  isActive,
  children,
}) => (
  <LoadingOverlayWrapper>
    {isActive ? (
      <LoadingOverlayContent>
        <Loader width="7em" height="7em" />
      </LoadingOverlayContent>
    ) : null}
    {children}
  </LoadingOverlayWrapper>
);

const LoadingOverlayWrapper = styled.div({
  position: "relative",
});

const LoadingOverlayContent = styled.div({}, () => {
  const {
    tokens: {
      colors: {shadow},
    },
  } = useTheme();

  return {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: shadow.secondary.toString(),

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  };
});
