import {Autocomplete, ComboBoxOption} from "@aws-amplify/ui-react";
import {QueryFunction, useQuery} from "@tanstack/react-query";
import React, {useCallback, useContext} from "react";
import {useDebounce} from "use-debounce";

import {DependencyContext} from "../../DependencyContext";
import {
  GetJurisdictionsAutocompleteRequest,
  GetJurisdictionsAutocompleteSuccessResponse,
  JurisdictionReference,
} from "../adminApi";

type AutocompleteProps = React.ComponentProps<typeof Autocomplete>;
type GetJurisdictionsAutocompleteQueryKey = [
  "getJurisdictionsAutocomplete",
  GetJurisdictionsAutocompleteRequest,
];

const SEARCH_DEBOUNCE_MS = 300;

export const JurisdictionAutocomplete: React.FC<
  Omit<AutocompleteProps, "options" | "onSelect" | "onClear"> &
    Required<Pick<AutocompleteProps, "value" | "onChange">> & {
      onJurisdictionSelected: (
        jurisdiction?: JurisdictionReference & {id: string},
      ) => any;
      debounceMs?: number;
      limit: number;
      Autocomplete?: typeof Autocomplete;
    }
> = ({
  onJurisdictionSelected,
  limit,
  debounceMs = SEARCH_DEBOUNCE_MS,
  Autocomplete: Autocomplete_ = Autocomplete,
  ...autocompleteProps
}) => {
  const {adminApi} = useContext(DependencyContext);
  const getJurisdictionsAutocompleteQueryFn: QueryFunction<
    GetJurisdictionsAutocompleteSuccessResponse,
    GetJurisdictionsAutocompleteQueryKey
  > = useCallback(
    ({queryKey: [_, _request], signal}) =>
      adminApi.getJurisdictionsAutocomplete(_request, signal),
    [adminApi],
  );
  const [debouncedValue] = useDebounce(autocompleteProps.value, debounceMs);
  const request: GetJurisdictionsAutocompleteRequest = {
    limit,
    jurisdiction: debouncedValue,
  };
  const getJurisdictionsAutocompleteQuery = useQuery({
    queryKey: ["getJurisdictionsAutocomplete", request],
    queryFn: getJurisdictionsAutocompleteQueryFn,
    enabled: !!request.jurisdiction,
  });

  const onSelect = useCallback(
    ({label}: ComboBoxOption) => {
      onJurisdictionSelected({id: label, name: label});
    },
    [onJurisdictionSelected],
  );

  const onClear = useCallback(() => {
    onJurisdictionSelected(undefined);
  }, [onJurisdictionSelected]);

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <Autocomplete_
      onSelect={onSelect as AutocompleteProps["onSelect"]}
      onClear={onClear}
      options={(
        getJurisdictionsAutocompleteQuery.data?.jurisdictions ?? []
      ).map((jurisdictionReference) => ({
        id: jurisdictionReference.name,
        label: jurisdictionReference.name,
      }))}
      isLoading={getJurisdictionsAutocompleteQuery.isFetching}
      {...autocompleteProps}
    />
  );
};
