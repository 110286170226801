import {
  FormikConnectedAmplifySelectField,
  Text,
  TextRaw,
} from "@title-service/ui";
import {enumKeys} from "@title-service/utils";
import {buildEnumSchema} from "@title-service/yup-utils";
import React from "react";
import * as Yup from "yup";

import {ClaimCostType} from "../adminApi";
import {safeDisplayEnumFn} from "../util/enum";

export const unsafeDisplayClaimCostType = (
  claimCostType: ClaimCostType,
): string => {
  switch (claimCostType) {
    case ClaimCostType.Loss:
      return "Loss";
    case ClaimCostType.Expense:
      return "Expense";
    default:
      throw new Error(`Unknown ClaimCost Type: ${claimCostType}`);
  }
};

export const displayClaimCostType = safeDisplayEnumFn(
  unsafeDisplayClaimCostType,
);

export const ClaimCostTypeComponentRaw: React.FC<{
  claimCostType: ClaimCostType;
}> = ({claimCostType}) => (
  <TextRaw value={displayClaimCostType(claimCostType)} />
);

export const ClaimCostTypeComponent: React.FC<
  Omit<React.ComponentProps<typeof Text>, "value"> & {
    claimCostType: ClaimCostType;
  }
> = ({claimCostType, ...props}) => (
  <Text {...props} value={displayClaimCostType(claimCostType)} />
);

export const buildClaimCostTypeSchema = (): Yup.StringSchema<
  ClaimCostType | undefined
> => buildEnumSchema(ClaimCostType, "Must be Loss or Expense");

export const ClaimCostTypeField: React.FC<
  React.ComponentProps<typeof FormikConnectedAmplifySelectField>
> = (props) => (
  <FormikConnectedAmplifySelectField placeholder="" {...props}>
    <option value="" />
    {enumKeys(ClaimCostType).map((claimCostTypeKey) => {
      const claimCostType: ClaimCostType = ClaimCostType[claimCostTypeKey];
      return (
        <option key={claimCostType} value={claimCostType}>
          {displayClaimCostType(claimCostType)}
        </option>
      );
    })}
  </FormikConnectedAmplifySelectField>
);
