import {
  Button,
  Expander,
  ExpanderItem,
  Flex,
  Grid,
  SwitchField,
  View,
} from "@aws-amplify/ui-react";
import {useMutation} from "@tanstack/react-query";
import {
  BodyContent,
  DateComponent,
  FormikConnectedAmplifyDateField,
  FormikConnectedAmplifySubmitButton,
  FormikConnectedAmplifyTextField,
  FormikForm,
  FormResultErrorMessage,
  FormResultSuccessMessage,
  Heading,
  SecondarySection,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
  VerticalFieldset,
} from "@title-service/ui";
import {isUndefined, notNullOrUndefined} from "@title-service/utils";
import {buildDateSchema} from "@title-service/yup-utils";
import {Formik, FormikHelpers} from "formik";
import React, {useCallback, useContext, useState} from "react";
import * as Yup from "yup";

import {DependencyContext} from "../../DependencyContext";
import {GetAgencyProducerJurisdictionsQuery} from "../../pages/AgencyProducerDetail";
import {GetIndividualProducerJurisdictionsQuery} from "../../pages/IndividualProducerDetail";
import {
  GetProducerJurisdictionsSuccessResponse,
  GetProducerJurisdictionsSuccessResponseJurisdiction,
  ProducerActivation,
  ProducerJurisdictionStatus,
  ReactivateProducerJurisdictionRequest,
} from "../adminApi";
import {Modal} from "../components/Modal";
import {PlusIcon} from "../components/icons";

import {
  CompleteDeactivateProducerJurisdictionFormData,
  DeactivateProducerJurisdictionForm,
} from "./DeactivateProducerJurisdictionForm";
import {ProducerAppointmentSection} from "./ProducerAppointmentSection";
import {ProducerId} from "./ProducerId";
import {
  CreateProducerLicenseAndJurisdictionForm,
  ProducerLicenseSection,
} from "./ProducerLicenseSection";

export const ProducerJurisdictionsSection: React.FC<{
  producerId: ProducerId;
  onReloadProducerJurisdictions: () => any;
  getProducerJurisdictionsQuery:
    | GetAgencyProducerJurisdictionsQuery
    | GetIndividualProducerJurisdictionsQuery;
  ProducerJurisdictionsSuccessComponent?: typeof ProducerJurisdictionsSuccessComponent;
  ProducerJurisdictionsLoadingComponent?: typeof ProducerJurisdictionsLoadingComponent;
  ProducerJurisdictionsFailureComponent?: typeof ProducerJurisdictionsFailureComponent;
}> = ({
  producerId,
  onReloadProducerJurisdictions,
  getProducerJurisdictionsQuery: {data, error},
  ProducerJurisdictionsSuccessComponent:
    ProducerJurisdictionsSuccessComponent_ = ProducerJurisdictionsSuccessComponent,
  ProducerJurisdictionsLoadingComponent:
    ProducerJurisdictionsLoadingComponent_ = ProducerJurisdictionsLoadingComponent,
  ProducerJurisdictionsFailureComponent:
    ProducerJurisdictionsFailureComponent_ = ProducerJurisdictionsFailureComponent,
}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);

  return (
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Jurisdictions" />
        {data ? <PlusIcon onClick={openForm} /> : null}
      </SecondarySectionHeaderContainer>
      <AmplifySecondaryContent>
        {data ? (
          <>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <ProducerJurisdictionsSuccessComponent_
              producerId={producerId}
              getProducerJurisdictionsResponse={data}
              onReloadProducerJurisdictions={onReloadProducerJurisdictions}
            />
            <Modal
              contentStyle={MODAL_CONTENT_STYLE}
              isOpen={displayForm}
              onClose={closeForm}
              title="Add Jurisdiction"
            >
              <CreateProducerLicenseAndJurisdictionForm
                producerId={producerId}
                onClose={closeForm}
                onSubmitComplete={onReloadProducerJurisdictions}
              />
            </Modal>
          </>
        ) : error ? (
          // eslint-disable-next-line react/jsx-pascal-case
          <ProducerJurisdictionsFailureComponent_ />
        ) : (
          // eslint-disable-next-line react/jsx-pascal-case
          <ProducerJurisdictionsLoadingComponent_ />
        )}
      </AmplifySecondaryContent>
    </SecondarySection>
  );
};

const MODAL_CONTENT_STYLE: React.CSSProperties = {
  minWidth: "650px",
};

export const ProducerJurisdictionsLoadingComponent: React.FC = () => (
  <BodyContent>
    <span>Loading Jurisdictions...</span>
  </BodyContent>
);

export const ProducerJurisdictionsFailureComponent: React.FC = () => (
  <BodyContent>
    <span>Failed to load Jurisdictions.</span>
  </BodyContent>
);

export const ProducerJurisdictionsSuccessComponent: React.FC<{
  producerId: ProducerId;
  getProducerJurisdictionsResponse: GetProducerJurisdictionsSuccessResponse;
  onReloadProducerJurisdictions: () => any;
}> = ({
  producerId,
  getProducerJurisdictionsResponse: {jurisdictions},
  onReloadProducerJurisdictions,
}) => {
  const [
    showDeactivatedProducerJurisdictions,
    setShowDeactivatedProducerJurisdictions,
  ] = useState(false);
  const toggleDeactivated = useCallback(() => {
    setShowDeactivatedProducerJurisdictions(
      (currentShowDeactivatedProducerJurisdictions) =>
        !currentShowDeactivatedProducerJurisdictions,
    );
  }, []);

  return (
    <>
      {jurisdictions.length > 0 && (
        <Flex justifyContent="flex-end" paddingBottom="medium">
          <SwitchField
            label="Show Deactivated"
            size="small"
            isChecked={showDeactivatedProducerJurisdictions}
            onChange={toggleDeactivated}
          />
        </Flex>
      )}
      <Expander isCollapsible={true} type="multiple">
        {getProducerJurisdictionsToDisplay(
          showDeactivatedProducerJurisdictions,
          jurisdictions,
        ).map((jurisdiction) => (
          <ProducerJurisdictionsExpanderItem
            key={jurisdiction.id}
            producerId={producerId}
            producerJurisdiction={jurisdiction}
            onReloadProducerJurisdictions={onReloadProducerJurisdictions}
          />
        ))}
      </Expander>
    </>
  );
};

// 422 is not returned from deactivateProducerJurisdiction endpoint
const notAppointmentTerminationNoticeDateRequiredError = () => false;

export const ProducerJurisdictionsExpanderItem: React.FC<{
  producerId: ProducerId;
  onReloadProducerJurisdictions: () => any;
  producerJurisdiction: GetProducerJurisdictionsSuccessResponseJurisdiction;
}> = ({
  producerId,
  onReloadProducerJurisdictions,
  producerJurisdiction: {
    id,
    jurisdiction,
    mostRecentLicense,
    mostRecentAppointment,
    appointmentRequired,
    mostRecentActivation,
    status,
  },
  producerJurisdiction,
}) => {
  const {adminApi} = useContext(DependencyContext);
  const [displayDeactivateForm, setDisplayDeactivateForm] = useState(false);
  const openDeactivateForm = useCallback(() => {
    setDisplayDeactivateForm(true);
  }, []);
  const closeDeactivateForm = useCallback(() => {
    setDisplayDeactivateForm(false);
  }, []);
  const [displayReactivateForm, setDisplayReactivateForm] = useState(false);
  const openReactivateForm = useCallback(() => {
    setDisplayReactivateForm(true);
  }, []);
  const closeReactivateForm = useCallback(() => {
    setDisplayReactivateForm(false);
  }, []);
  const deactivateProducerJurisdictionMutationFn = useCallback(
    (
      completeDeactivateProducerJurisdictionFormData: CompleteDeactivateProducerJurisdictionFormData,
    ) =>
      adminApi.deactivateProducerJurisdiction({
        ...completeDeactivateProducerJurisdictionFormData,
        producerJurisdictionId: id,
      }),
    [adminApi, id],
  );
  const reactivateProducerJurisdictionMutationFn = useCallback(
    (
      completeReactivateProducerJurisdictionFormData: CompleteReactivateProducerJurisdictionFormData,
    ) =>
      adminApi.reactivateProducerJurisdiction({
        ...completeReactivateProducerJurisdictionFormData,
        producerJurisdictionId: id,
      }),
    [adminApi, id],
  );
  const hasValidAppointment =
    !isUndefined(mostRecentAppointment) &&
    isUndefined(mostRecentAppointment.terminatedAt);
  return (
    <ExpanderItem
      title={
        <Flex direction="row" alignItems="center">
          <Heading
            level={5}
            color={
              status === ProducerJurisdictionStatus.Inactive
                ? "darkgray"
                : "inherit"
            }
            value={jurisdiction}
          />
        </Flex>
      }
      value={id}
      // testId is broken for ExpanderItem
      data-testid={id}
    >
      <Grid templateColumns="1fr 1fr" templateRows="1fr">
        <ProducerLicenseSection
          mostRecentLicense={mostRecentLicense}
          producerId={producerId}
          jurisdictionStatus={status}
          onReloadProducerJurisdictions={onReloadProducerJurisdictions}
        />
        <ProducerAppointmentSection
          mostRecentAppointment={mostRecentAppointment}
          appointmentRequired={appointmentRequired}
          jurisdictionStatus={status}
          producerId={producerId}
          producerJurisdictionId={id}
          onReloadProducerJurisdictions={onReloadProducerJurisdictions}
        />
      </Grid>
      {status === ProducerJurisdictionStatus.Inactive ? (
        <DeactivatedProducerJurisdictionDetailSection
          producerJurisdiction={producerJurisdiction}
        />
      ) : null}
      {status === ProducerJurisdictionStatus.Inactive ? null : (
        <Flex justifyContent="center">
          <Button
            size="small"
            variation="link"
            color="red.60"
            isFullWidth={false}
            onClick={openDeactivateForm}
            testId="deactivate-producer-jurisdiction-button"
          >
            Deactivate Jurisdiction
          </Button>
        </Flex>
      )}
      {mostRecentActivation?.reactivationDate ? (
        <ReactivatedProducerJurisdictionFootnote
          producerJurisdiction={producerJurisdiction}
        />
      ) : null}
      {status === ProducerJurisdictionStatus.Inactive ? (
        <Flex justifyContent="center">
          <Button
            size="small"
            variation="link"
            color="green.80"
            isFullWidth={false}
            onClick={openReactivateForm}
            testId="reactivate-producer-jurisdiction-button"
          >
            Reactivate Jurisdiction
          </Button>
        </Flex>
      ) : null}
      <Modal
        contentStyle={MODAL_CONTENT_STYLE}
        isOpen={displayDeactivateForm}
        onClose={closeDeactivateForm}
        title="Deactivate Producer Jurisdiction"
      >
        <DeactivateProducerJurisdictionForm
          mutationFn={deactivateProducerJurisdictionMutationFn}
          onSubmitComplete={onReloadProducerJurisdictions}
          appointmentTerminationNoticeDateRequired={hasValidAppointment}
          displayAppointmentTerminationNoticeDate={hasValidAppointment}
          requireConfirmation={false}
          isAppointmentTerminationNoticeDateRequiredError={
            notAppointmentTerminationNoticeDateRequiredError
          }
        />
      </Modal>
      <Modal
        contentStyle={MODAL_CONTENT_STYLE}
        isOpen={displayReactivateForm}
        onClose={closeReactivateForm}
        title="Reactivate Producer Jurisdiction"
      >
        <ReactivateProducerJurisdictionForm
          mutationFn={reactivateProducerJurisdictionMutationFn}
          onSubmitComplete={onReloadProducerJurisdictions}
        />
      </Modal>
    </ExpanderItem>
  );
};

export const isActive = (
  mostRecentActivation: ProducerActivation | undefined,
) => mostRecentActivation?.active ?? false;

export const isDeactivated = (
  mostRecentActivation: ProducerActivation | undefined,
) => (mostRecentActivation ? !mostRecentActivation.active : false);

export const getProducerJurisdictionsToDisplay = (
  showDeactivatedProducerJurisdictions: boolean,
  producerJurisdictions: GetProducerJurisdictionsSuccessResponseJurisdiction[],
) => {
  if (showDeactivatedProducerJurisdictions) {
    return producerJurisdictions;
  }
  return producerJurisdictions.filter(
    (producerJurisdiction) =>
      !isDeactivated(producerJurisdiction.mostRecentActivation),
  );
};

export const DeactivatedProducerJurisdictionDetailSection: React.FC<{
  producerJurisdiction: GetProducerJurisdictionsSuccessResponseJurisdiction;
}> = ({producerJurisdiction: {mostRecentActivation, appointmentRequired}}) => (
  <Grid templateColumns="1fr 1fr" templateRows="1fr">
    <View>
      <JurisdictionGridItem>
        <JurisdictionLabel>Deactivation Date</JurisdictionLabel>
        {mostRecentActivation!.deactivationDate ? (
          <DateComponent value={mostRecentActivation!.deactivationDate} />
        ) : null}
      </JurisdictionGridItem>
      <JurisdictionGridItem>
        <JurisdictionLabel>Deactivation Reason</JurisdictionLabel>
        {mostRecentActivation!.deactivationReason ? (
          <span>{mostRecentActivation!.deactivationReason}</span>
        ) : null}
      </JurisdictionGridItem>
    </View>
    {appointmentRequired &&
    notNullOrUndefined(
      mostRecentActivation!.appointmentTerminationNoticeDate,
    ) ? (
      <View>
        <JurisdictionGridItem>
          <JurisdictionLabel>
            Appointment Termination Notice Date
          </JurisdictionLabel>
          <DateComponent
            value={mostRecentActivation!.appointmentTerminationNoticeDate}
          />
        </JurisdictionGridItem>
      </View>
    ) : null}
  </Grid>
);

export const ReactivatedProducerJurisdictionFootnote: React.FC<{
  producerJurisdiction: GetProducerJurisdictionsSuccessResponseJurisdiction;
}> = ({producerJurisdiction: {mostRecentActivation}}) => (
  <Flex
    justifyContent="center"
    paddingTop="small"
    fontStyle="italic"
    gap="xxxs"
    fontSize="small"
    data-testid="deactivation-date"
  >
    <span>This jurisdiction was deactivated on </span>
    {mostRecentActivation!.deactivationDate ? (
      <DateComponent value={mostRecentActivation!.deactivationDate} />
    ) : null}
    <span> and reactivated on </span>
    {mostRecentActivation!.reactivationDate ? (
      <DateComponent value={mostRecentActivation!.reactivationDate} />
    ) : null}
  </Flex>
);

export const AmplifySecondaryContent: React.FC = ({children}) => (
  <View paddingLeft="medium" paddingRight="medium" paddingTop="medium">
    {children}
  </View>
);

export const JurisdictionTertiaryHeaderAndIconContainer: React.FC = ({
  children,
}) => (
  <Flex
    direction="row"
    alignItems="center"
    justifyContent="flex-start"
    backgroundColor="background.tertiary"
    fontWeight="bold"
    padding="xs"
  >
    {children}
  </Flex>
);
export const JurisdictionGridItem: React.FC = ({children}) => (
  <Flex direction="column" padding="xs" margin="xs" gap="zero">
    {children}
  </Flex>
);

export const JurisdictionLabel: React.FC = ({children}) => (
  <Flex
    alignItems="center"
    color="font.primary"
    display="inline-flex"
    direction="row"
    fontWeight="bold"
    paddingBottom="xs"
    fontSize="small"
    lineHeight="21px"
  >
    {children}
  </Flex>
);

export const isAgencyProducerId = (producerId: ProducerId): boolean =>
  "agencyProducerId" in producerId;

export const mapProducerIdToProducerTypeId = (
  producerId: ProducerId,
): string =>
  "agencyProducerId" in producerId
    ? producerId.agencyProducerId
    : producerId.individualProducerId;

export const REACTIVATION_REASON_SCHEMA: Yup.StringSchema<string> = Yup.string()
  .required("Reactivation reason is required")
  .trim();

export const ReactivationReasonField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyTextField>, "label">
> = (props) => (
  <FormikConnectedAmplifyTextField
    testId="reactivation-reason-input"
    label="Reactivation Reason"
    {...props}
  />
);

export const REACTIVATION_DATE_SCHEMA: Yup.DateSchema<Date> =
  buildDateSchema().required("Reactivation date is required");

export const ReactivationDateField: React.FC<
  Omit<React.ComponentProps<typeof FormikConnectedAmplifyDateField>, "label">
> = (props) => (
  <FormikConnectedAmplifyDateField
    testId="reactivation-date-input"
    label="Reactivation Date"
    {...props}
  />
);

export type ReactivateProducerJurisdictionFormData = Omit<
  ReactivateProducerJurisdictionRequest,
  "producerJurisdictionId" | "reactivationReason" | "reactivationDate"
> & {
  reactivationReason: string;
  reactivationDate: string;
};

type CompleteReactivateProducerJurisdictionFormData = Omit<
  ReactivateProducerJurisdictionRequest,
  "producerJurisdictionId"
>;

export const REACTIVATE_PRODUCER_JURISDICTION_FORM_SCHEMA: Yup.ObjectSchema<CompleteReactivateProducerJurisdictionFormData> =
  Yup.object({
    reactivationReason: REACTIVATION_REASON_SCHEMA,
    reactivationDate: REACTIVATION_DATE_SCHEMA,
  });

export const buildInitialReactivateProducerJurisdictionFormValues =
  (): ReactivateProducerJurisdictionFormData => ({
    reactivationReason: "",
    reactivationDate: "",
  });

export const ReactivateProducerJurisdictionForm: React.FC<{
  onSubmitComplete: () => any;
  mutationFn: (
    completeFormData: CompleteReactivateProducerJurisdictionFormData,
  ) => Promise<void>;
}> = ({onSubmitComplete, mutationFn}) => {
  const mutation = useMutation({mutationFn});
  const formSchema = REACTIVATE_PRODUCER_JURISDICTION_FORM_SCHEMA;
  const submitHandler = useCallback(
    (
      formData: ReactivateProducerJurisdictionFormData,
      {setSubmitting}: FormikHelpers<ReactivateProducerJurisdictionFormData>,
    ) => {
      const completeFormData = formSchema.validateSync(formData);
      mutation.mutate(completeFormData, {
        onSuccess: () => {
          onSubmitComplete();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [mutation, formSchema, onSubmitComplete],
  );
  return (
    <Formik<ReactivateProducerJurisdictionFormData>
      initialValues={buildInitialReactivateProducerJurisdictionFormValues()}
      validationSchema={formSchema as any}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <ReactivationReasonField
            name="reactivationReason"
            minWidth="450px"
            testId="reactivation-reason-input"
          />
          <ReactivationDateField
            name="reactivationDate"
            minWidth="450px"
            testId="reactivation-date-input"
          />
        </VerticalFieldset>

        {mutation.isSuccess ? (
          <FormResultSuccessMessage data-testid="success-result">
            The producer jurisdiction was successfully reactivated.
          </FormResultSuccessMessage>
        ) : null}

        {mutation.isError ? (
          <FormResultErrorMessage data-testid="error-result">
            Request failed.
          </FormResultErrorMessage>
        ) : null}

        <FormikConnectedAmplifySubmitButton />
      </FormikForm>
    </Formik>
  );
};
