/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
     PropertyDocumentFlagActiveFromJSONTyped,
     PropertyDocumentFlagInactiveFromJSONTyped
} from './';

/**
 * 
 * @export
 * @interface PropertyDocumentFlag
 */
export interface PropertyDocumentFlag {
    /**
     * 
     * @type {string}
     * @memberof PropertyDocumentFlag
     */
    status: string;
}

/**
 * Check if a given object implements the PropertyDocumentFlag interface.
 */
export function instanceOfPropertyDocumentFlag(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function PropertyDocumentFlagFromJSON(json: any): PropertyDocumentFlag {
    return PropertyDocumentFlagFromJSONTyped(json, false);
}

export function PropertyDocumentFlagFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyDocumentFlag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['status'] === 'active') {
            return PropertyDocumentFlagActiveFromJSONTyped(json, true);
        }
        if (json['status'] === 'inactive') {
            return PropertyDocumentFlagInactiveFromJSONTyped(json, true);
        }
    }
    return {
        
        'status': json['status'],
    };
}

export function PropertyDocumentFlagToJSON(value?: PropertyDocumentFlag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
    };
}

