import {Icon} from "@title-service/ui";
import React from "react";

const VIEW_BOX: React.ComponentProps<typeof Icon>["viewBox"] = {
  width: 490.2,
  height: 490.2,
};

/*
 * Icon taken from: https://www.svgrepo.com/svg/135110/plus
 * This work is licensed in the public domain (CC0 1.0) with no copyright and no requirement
 * to attribute the original author. For more information on this license,
 * see [here](https://creativecommons.org/publicdomain/zero/1.0/deed.en)
 */
export const PlusIcon: React.FC<React.ComponentProps<typeof Icon>> = (
  props,
) => (
  <Icon viewBox={VIEW_BOX} {...props}>
    <g>
      <g>
        <path
          d="M418.5,418.5c95.6-95.6,95.6-251.2,0-346.8s-251.2-95.6-346.8,0s-95.6,251.2,0,346.8S322.9,514.1,418.5,418.5z M89,89
			c86.1-86.1,226.1-86.1,312.2,0s86.1,226.1,0,312.2s-226.1,86.1-312.2,0S3,175.1,89,89z"
        />
        <path
          d="M245.1,336.9c3.4,0,6.4-1.4,8.7-3.6c2.2-2.2,3.6-5.3,3.6-8.7v-67.3h67.3c3.4,0,6.4-1.4,8.7-3.6c2.2-2.2,3.6-5.3,3.6-8.7
			c0-6.8-5.5-12.3-12.2-12.2h-67.3v-67.3c0-6.8-5.5-12.3-12.2-12.2c-6.8,0-12.3,5.5-12.2,12.2v67.3h-67.3c-6.8,0-12.3,5.5-12.2,12.2
			c0,6.8,5.5,12.3,12.2,12.2h67.3v67.3C232.8,331.4,238.3,336.9,245.1,336.9z"
        />
      </g>
    </g>
  </Icon>
);
