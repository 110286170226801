/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderPropertyDocumentIngestionStatus } from './OrderPropertyDocumentIngestionStatus';
import {
    OrderPropertyDocumentIngestionStatusFromJSON,
    OrderPropertyDocumentIngestionStatusFromJSONTyped,
    OrderPropertyDocumentIngestionStatusToJSON,
} from './OrderPropertyDocumentIngestionStatus';

/**
 * 
 * @export
 * @interface OrderPropertyDocument
 */
export interface OrderPropertyDocument {
    /**
     * 
     * @type {string}
     * @memberof OrderPropertyDocument
     */
    attomId: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderPropertyDocument
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderPropertyDocument
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderPropertyDocument
     */
    ingestionCompletedAt?: Date;
    /**
     * 
     * @type {OrderPropertyDocumentIngestionStatus}
     * @memberof OrderPropertyDocument
     */
    ingestionStatus: OrderPropertyDocumentIngestionStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderPropertyDocument
     */
    propertyDocumentId?: string;
}

/**
 * Check if a given object implements the OrderPropertyDocument interface.
 */
export function instanceOfOrderPropertyDocument(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "attomId" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "ingestionStatus" in value;

    return isInstance;
}

export function OrderPropertyDocumentFromJSON(json: any): OrderPropertyDocument {
    return OrderPropertyDocumentFromJSONTyped(json, false);
}

export function OrderPropertyDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderPropertyDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attomId': json['attomId'],
        'createdAt': (new Date(json['createdAt'])),
        'id': json['id'],
        'ingestionCompletedAt': !exists(json, 'ingestionCompletedAt') ? undefined : (new Date(json['ingestionCompletedAt'])),
        'ingestionStatus': OrderPropertyDocumentIngestionStatusFromJSON(json['ingestionStatus']),
        'propertyDocumentId': !exists(json, 'propertyDocumentId') ? undefined : json['propertyDocumentId'],
    };
}

export function OrderPropertyDocumentToJSON(value?: OrderPropertyDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attomId': value.attomId,
        'createdAt': (value.createdAt.toISOString()),
        'id': value.id,
        'ingestionCompletedAt': value.ingestionCompletedAt === undefined ? undefined : (value.ingestionCompletedAt.toISOString()),
        'ingestionStatus': OrderPropertyDocumentIngestionStatusToJSON(value.ingestionStatus),
        'propertyDocumentId': value.propertyDocumentId,
    };
}

