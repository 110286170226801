/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GetAgencyProducersAutocompleteType = {
    Parent: 'parent',
    Child: 'child'
} as const;
export type GetAgencyProducersAutocompleteType = typeof GetAgencyProducersAutocompleteType[keyof typeof GetAgencyProducersAutocompleteType];


export function GetAgencyProducersAutocompleteTypeFromJSON(json: any): GetAgencyProducersAutocompleteType {
    return GetAgencyProducersAutocompleteTypeFromJSONTyped(json, false);
}

export function GetAgencyProducersAutocompleteTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAgencyProducersAutocompleteType {
    return json as GetAgencyProducersAutocompleteType;
}

export function GetAgencyProducersAutocompleteTypeToJSON(value?: GetAgencyProducersAutocompleteType | null): any {
    return value as any;
}

