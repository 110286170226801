/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetParentAgenciesSuccessResponseParentAgency } from './GetParentAgenciesSuccessResponseParentAgency';
import {
    GetParentAgenciesSuccessResponseParentAgencyFromJSON,
    GetParentAgenciesSuccessResponseParentAgencyFromJSONTyped,
    GetParentAgenciesSuccessResponseParentAgencyToJSON,
} from './GetParentAgenciesSuccessResponseParentAgency';

/**
 * 
 * @export
 * @interface GetParentAgenciesSuccessResponse
 */
export interface GetParentAgenciesSuccessResponse {
    /**
     * 
     * @type {Array<GetParentAgenciesSuccessResponseParentAgency>}
     * @memberof GetParentAgenciesSuccessResponse
     */
    parentAgencies: Array<GetParentAgenciesSuccessResponseParentAgency>;
}

/**
 * Check if a given object implements the GetParentAgenciesSuccessResponse interface.
 */
export function instanceOfGetParentAgenciesSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "parentAgencies" in value;

    return isInstance;
}

export function GetParentAgenciesSuccessResponseFromJSON(json: any): GetParentAgenciesSuccessResponse {
    return GetParentAgenciesSuccessResponseFromJSONTyped(json, false);
}

export function GetParentAgenciesSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetParentAgenciesSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parentAgencies': ((json['parentAgencies'] as Array<any>).map(GetParentAgenciesSuccessResponseParentAgencyFromJSON)),
    };
}

export function GetParentAgenciesSuccessResponseToJSON(value?: GetParentAgenciesSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parentAgencies': ((value.parentAgencies as Array<any>).map(GetParentAgenciesSuccessResponseParentAgencyToJSON)),
    };
}

