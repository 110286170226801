/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgencyProducerReference } from './AgencyProducerReference';
import {
    AgencyProducerReferenceFromJSON,
    AgencyProducerReferenceFromJSONTyped,
    AgencyProducerReferenceToJSON,
} from './AgencyProducerReference';
import type { AuditExportStatus } from './AuditExportStatus';
import {
    AuditExportStatusFromJSON,
    AuditExportStatusFromJSONTyped,
    AuditExportStatusToJSON,
} from './AuditExportStatus';

/**
 * 
 * @export
 * @interface GetAuditExportSuccessResponse
 */
export interface GetAuditExportSuccessResponse {
    /**
     * 
     * @type {AgencyProducerReference}
     * @memberof GetAuditExportSuccessResponse
     */
    agency?: AgencyProducerReference;
    /**
     * 
     * @type {string}
     * @memberof GetAuditExportSuccessResponse
     */
    artifactUrl?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetAuditExportSuccessResponse
     */
    completedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetAuditExportSuccessResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof GetAuditExportSuccessResponse
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof GetAuditExportSuccessResponse
     */
    lowerBoundDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetAuditExportSuccessResponse
     */
    sfnArn?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditExportSuccessResponse
     */
    state?: string;
    /**
     * 
     * @type {AuditExportStatus}
     * @memberof GetAuditExportSuccessResponse
     */
    status: AuditExportStatus;
    /**
     * 
     * @type {Date}
     * @memberof GetAuditExportSuccessResponse
     */
    upperBoundDate?: Date;
}

/**
 * Check if a given object implements the GetAuditExportSuccessResponse interface.
 */
export function instanceOfGetAuditExportSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function GetAuditExportSuccessResponseFromJSON(json: any): GetAuditExportSuccessResponse {
    return GetAuditExportSuccessResponseFromJSONTyped(json, false);
}

export function GetAuditExportSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAuditExportSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agency': !exists(json, 'agency') ? undefined : AgencyProducerReferenceFromJSON(json['agency']),
        'artifactUrl': !exists(json, 'artifactUrl') ? undefined : json['artifactUrl'],
        'completedAt': !exists(json, 'completedAt') ? undefined : (new Date(json['completedAt'])),
        'createdAt': (new Date(json['createdAt'])),
        'id': json['id'],
        'lowerBoundDate': !exists(json, 'lowerBoundDate') ? undefined : (new Date(json['lowerBoundDate'])),
        'sfnArn': !exists(json, 'sfnArn') ? undefined : json['sfnArn'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'status': AuditExportStatusFromJSON(json['status']),
        'upperBoundDate': !exists(json, 'upperBoundDate') ? undefined : (new Date(json['upperBoundDate'])),
    };
}

export function GetAuditExportSuccessResponseToJSON(value?: GetAuditExportSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agency': AgencyProducerReferenceToJSON(value.agency),
        'artifactUrl': value.artifactUrl,
        'completedAt': value.completedAt === undefined ? undefined : (value.completedAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
        'id': value.id,
        'lowerBoundDate': value.lowerBoundDate === undefined ? undefined : (value.lowerBoundDate.toISOString().substr(0,10)),
        'sfnArn': value.sfnArn,
        'state': value.state,
        'status': AuditExportStatusToJSON(value.status),
        'upperBoundDate': value.upperBoundDate === undefined ? undefined : (value.upperBoundDate.toISOString().substr(0,10)),
    };
}

