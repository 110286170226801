import {
  Table as AmplifyTable,
  TableBody as AmplifyTableBody,
  TableCell as AmplifyTableCell,
  TableFoot as AmplifyTableFoot,
  TableHead as AmplifyTableHead,
  TableRow as AmplifyTableRow,
} from "@aws-amplify/ui-react";
import React from "react";
import styled from "styled-components";

import {FONT_SIZE_H4, SPACING_3} from "../../theme";

export const Table: React.FC<React.ComponentProps<typeof AmplifyTable>> = (
  props,
) => <AmplifyTable highlightOnHover={true} size="small" {...props} />;

export const THead = AmplifyTableHead;

export const HeaderRow = AmplifyTableRow;

export const Th: React.FC<React.ComponentProps<typeof AmplifyTableCell>> = (
  props,
) => <AmplifyTableCell as="th" {...props} />;

export const TBody: React.FC<React.ComponentProps<typeof AmplifyTableBody>> = (
  props,
) => <AmplifyTableBody {...props} />;

export const BodyRow = AmplifyTableRow;

export const Td: React.FC<React.ComponentProps<typeof AmplifyTableCell>> = (
  props,
) => <AmplifyTableCell as="td" {...props} />;

export const TFoot: React.FC<React.ComponentProps<typeof AmplifyTableBody>> = (
  props,
) => <AmplifyTableFoot {...props} />;

export const FooterRow = AmplifyTableRow;

export const TableCaption = styled.caption({
  paddingBottom: SPACING_3,
  fontWeight: "bold",
  textAlign: "left",
  ...FONT_SIZE_H4,
});
