import {Alert, Button, Flex, Placeholder} from "@aws-amplify/ui-react";
import {
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {isLoadingNextQuery, Query} from "@title-service/react-query-utils";
import {
  DateTimeComponent,
  FormikConnectedAmplifySelectField,
  FormikConnectedAmplifySubmitButton,
  FormikForm,
  HorizontalFieldset,
} from "@title-service/ui";
import {transformNullToUndefined} from "@title-service/yup-utils";
import {Formik, FormikHelpers, useFormikContext} from "formik";
import React, {useCallback, useContext, useState} from "react";
import * as Yup from "yup";

import {DependencyContext} from "../DependencyContext";
import {
  GetTrainedModelsSuccessResponse,
  TrainedScoringModel,
  TrainModelRequest,
} from "../shared/adminApi";
import {LoadingOverlay} from "../shared/components/LoadingOverlay";
import {Modal} from "../shared/components/Modal";
import {QuantityComponent} from "../shared/components/Quantity";
import {
  AboveTableContainer,
  BodyRow,
  HeaderRow,
  Table,
  TBody,
  Td,
  Th,
  THead,
} from "../shared/components/tables/PrimaryTable";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
} from "../shared/layout";

const MODAL_CONTENT_STYLE: React.CSSProperties = {
  minWidth: "650px",
};

type GetTrainedModelsQueryKey = ["trainedModels", "search"];
const GET_TRAINED_MODELS_QUERY_KEY: GetTrainedModelsQueryKey = [
  "trainedModels",
  "search",
];
export type GetTrainedModelsQuery = Query<GetTrainedModelsSuccessResponse>;

export const useTrainedModelsQuery = (): {
  getTrainedModelsQuery: GetTrainedModelsQuery;
  onReloadTrainedModels: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);
  const getTrainedModelsQueryFn: QueryFunction<
    GetTrainedModelsSuccessResponse,
    GetTrainedModelsQueryKey
  > = useCallback(({signal}) => adminApi.getTrainedModels(signal), [adminApi]);
  const getTrainedModelsQuery = useQuery({
    queryKey: GET_TRAINED_MODELS_QUERY_KEY,
    keepPreviousData: true,
    queryFn: getTrainedModelsQueryFn,
  });
  const onReloadTrainedModels = useCallback(
    () =>
      queryClient.invalidateQueries({queryKey: GET_TRAINED_MODELS_QUERY_KEY}),
    [queryClient],
  );

  return {getTrainedModelsQuery, onReloadTrainedModels};
};

export const TrainedModelsRoute: React.FC<{
  GetTrainedModelsBodyContent?: typeof GetTrainedModelsBodyContent;
}> = ({
  GetTrainedModelsBodyContent:
    GetTrainedModelsBodyContent_ = GetTrainedModelsBodyContent,
}) => {
  const {getTrainedModelsQuery, onReloadTrainedModels} =
    useTrainedModelsQuery();

  return (
    <>
      <BodyHeader>
        <PrimaryBodyHeaderContainer>
          <PrimaryBodyHeader value="Trained Scoring Models" />
        </PrimaryBodyHeaderContainer>
      </BodyHeader>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <GetTrainedModelsBodyContent_
        getTrainedModelsQuery={getTrainedModelsQuery}
        onReloadTrainedModels={onReloadTrainedModels}
      />
    </>
  );
};

export const GetTrainedModelsBodyContent: React.FC<{
  getTrainedModelsQuery: GetTrainedModelsQuery;
  onReloadTrainedModels: () => any;
  TrainedModelsPagePlaceholderTableBody?: typeof TrainedModelsPagePlaceholderTableBody;
  TrainedModelsPageSuccessTableBody?: typeof TrainedModelsPageSuccessTableBody;
  TrainNewModelForm?: typeof TrainNewModelForm;
}> = ({
  getTrainedModelsQuery,
  onReloadTrainedModels,
  getTrainedModelsQuery: {data, error, isInitialLoading},
  TrainedModelsPagePlaceholderTableBody:
    TrainedModelsPagePlaceholderTableBody_ = TrainedModelsPagePlaceholderTableBody,
  TrainedModelsPageSuccessTableBody:
    TrainedModelsPageSuccessTableBody_ = TrainedModelsPageSuccessTableBody,
  TrainNewModelForm: TrainNewModelForm_ = TrainNewModelForm,
}) => {
  const isLoading = isLoadingNextQuery(getTrainedModelsQuery);
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);
  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);
  return (
    <BodyContent>
      {getTrainedModelsQuery.data ? (
        <Modal
          isOpen={modalOpen}
          onClose={closeModal}
          contentStyle={MODAL_CONTENT_STYLE}
          title="Train new Scoring Model"
        >
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <TrainNewModelForm_
            response={getTrainedModelsQuery.data}
            onSubmitComplete={onReloadTrainedModels}
          />
        </Modal>
      ) : null}
      <AboveTableContainer
        justifyContent={error || data ? "space-between" : "flex-end"}
      >
        {error ? (
          <Alert variation="error" isDismissible={false} hasIcon={true}>
            Failed to load Models
          </Alert>
        ) : data ? (
          <QuantityComponent
            quantity={data.models.length}
            label={data.models.length === 1 ? "Model" : "Models"}
          />
        ) : null}
        <Button
          variation="link"
          onClick={openModal}
          isDisabled={!getTrainedModelsQuery.data}
        >
          Train new Scoring Model
        </Button>
      </AboveTableContainer>
      <LoadingOverlay isActive={isLoading}>
        <Table>
          <THead>
            <HeaderRow>
              <Th>Model Version</Th>
              <Th>State</Th>
              <Th>Trained At</Th>
            </HeaderRow>
          </THead>
          {data ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <TrainedModelsPageSuccessTableBody_ response={data} />
          ) : error ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <TrainedModelsPagePlaceholderTableBody_ isLoaded={true} />
          ) : (
            isInitialLoading && (
              // eslint-disable-next-line react/jsx-pascal-case
              <TrainedModelsPagePlaceholderTableBody_ isLoaded={false} />
            )
          )}
        </Table>
      </LoadingOverlay>
    </BodyContent>
  );
};

export const TrainedModelsPagePlaceholderTableBody: React.FC<{
  isLoaded: boolean;
}> = ({isLoaded}) => (
  <TBody>
    <BodyRow>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
    </BodyRow>
  </TBody>
);

export const TrainedModelsPageSuccessTableBody: React.FC<{
  response: GetTrainedModelsSuccessResponse;
}> = ({response: {models}}) => {
  if (!models.length) {
    return (
      <TBody>
        <BodyRow>
          <Td colSpan={3}>No Trained Scoring Models found</Td>
        </BodyRow>
      </TBody>
    );
  }

  return (
    <TBody>
      {models.map(({version, state, trainedAt}) => (
        <BodyRow key={version}>
          <Td>{version}</Td>
          <Td>{state}</Td>
          <Td>
            <DateTimeComponent value={trainedAt} />
          </Td>
        </BodyRow>
      ))}
    </TBody>
  );
};

export const TrainNewModelForm: React.FC<{
  onSubmitComplete: () => any;
  response: GetTrainedModelsSuccessResponse;
}> = ({onSubmitComplete, response: {models}}) => {
  const {adminApi} = useContext(DependencyContext);
  const trainModelMutation = useMutation({
    mutationFn: adminApi.trainModel,
  });
  const submitHandler = useCallback(
    (
      formData: TrainModelFormData,
      {setSubmitting}: FormikHelpers<TrainModelFormData>,
    ) => {
      const trainModelRequest = FORM_SCHEMA.validateSync(formData);
      trainModelMutation.mutate(trainModelRequest, {
        onSuccess: () => {
          onSubmitComplete();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [onSubmitComplete, trainModelMutation],
  );

  return (
    <Formik<TrainModelFormData>
      initialValues={INITIAL_FORM_DATA}
      validationSchema={FORM_SCHEMA as any}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <HorizontalFieldset>
          <FormikConnectedAmplifySelectField
            testId="state-dropdown"
            label="State"
            placeholder="Select State"
            name="state"
          >
            {stateAbbreviations.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </FormikConnectedAmplifySelectField>
          <BaselineModelVersionInput models={models} />
        </HorizontalFieldset>
        <Flex direction="row" justifyContent="flex-end" width="100%">
          <FormikConnectedAmplifySubmitButton />
        </Flex>
        {trainModelMutation.isSuccess ? (
          <Alert testId="result-success" variation="success">
            Model training in progress at {trainModelMutation.data.executionArn}
          </Alert>
        ) : null}
        {trainModelMutation.isError ? (
          <Alert testId="result-error" variation="error">
            Model training submission failed.
          </Alert>
        ) : null}
      </FormikForm>
    </Formik>
  );
};

const stateAbbreviations = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

type TrainModelFormData = Omit<TrainModelRequest, "baselineVersion"> &
  Required<Pick<TrainModelRequest, "baselineVersion">>;

const INITIAL_FORM_DATA: TrainModelFormData = {
  state: "",
  baselineVersion: "",
};

export const FORM_SCHEMA: Yup.ObjectSchema<TrainModelRequest> = Yup.object({
  state: Yup.string().trim().transform(transformNullToUndefined).required(),
  baselineVersion: Yup.string()
    .trim()
    .transform(transformNullToUndefined)
    .optional(),
});

const filterModelForState = (
  models: TrainedScoringModel[],
  selectedState: string,
): TrainedScoringModel[] =>
  models
    .filter(({state}) => state === selectedState)
    .sort(
      (model1, model2) =>
        model2.trainedAt.getTime() - model1.trainedAt.getTime(),
    );

const BaselineModelVersionInput: React.FC<{
  models: TrainedScoringModel[];
}> = ({models}) => {
  const {
    values: {state: selectedState},
  } = useFormikContext<TrainModelFormData>();

  const filteredModels: TrainedScoringModel[] = selectedState
    ? filterModelForState(models, selectedState)
    : [];

  return (
    <FormikConnectedAmplifySelectField
      testId="baseline-version-dropdown"
      label="Baseline Model Version"
      placeholder="Select Baseline Model"
      name="baselineVersion"
      isDisabled={!selectedState}
    >
      {filteredModels.map(({version}) => (
        <option key={version} value={version}>
          {version}
        </option>
      ))}
    </FormikConnectedAmplifySelectField>
  );
};
