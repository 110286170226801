/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RemittanceType = {
    Cpl: 'cpl',
    Policy: 'policy',
    Endorsement: 'endorsement',
    Fee: 'fee'
} as const;
export type RemittanceType = typeof RemittanceType[keyof typeof RemittanceType];


export function RemittanceTypeFromJSON(json: any): RemittanceType {
    return RemittanceTypeFromJSONTyped(json, false);
}

export function RemittanceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemittanceType {
    return json as RemittanceType;
}

export function RemittanceTypeToJSON(value?: RemittanceType | null): any {
    return value as any;
}

