/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPropertySuccessResponseMortgage
 */
export interface GetPropertySuccessResponseMortgage {
    /**
     * 
     * @type {string}
     * @memberof GetPropertySuccessResponseMortgage
     */
    instrumentNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPropertySuccessResponseMortgage
     */
    lenderName?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetPropertySuccessResponseMortgage
     */
    termDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof GetPropertySuccessResponseMortgage
     */
    termLength?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPropertySuccessResponseMortgage
     */
    termType?: string;
}

/**
 * Check if a given object implements the GetPropertySuccessResponseMortgage interface.
 */
export function instanceOfGetPropertySuccessResponseMortgage(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetPropertySuccessResponseMortgageFromJSON(json: any): GetPropertySuccessResponseMortgage {
    return GetPropertySuccessResponseMortgageFromJSONTyped(json, false);
}

export function GetPropertySuccessResponseMortgageFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPropertySuccessResponseMortgage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'instrumentNumber': !exists(json, 'instrumentNumber') ? undefined : json['instrumentNumber'],
        'lenderName': !exists(json, 'lenderName') ? undefined : json['lenderName'],
        'termDate': !exists(json, 'termDate') ? undefined : (new Date(json['termDate'])),
        'termLength': !exists(json, 'termLength') ? undefined : json['termLength'],
        'termType': !exists(json, 'termType') ? undefined : json['termType'],
    };
}

export function GetPropertySuccessResponseMortgageToJSON(value?: GetPropertySuccessResponseMortgage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'instrumentNumber': value.instrumentNumber,
        'lenderName': value.lenderName,
        'termDate': value.termDate === undefined ? undefined : (value.termDate.toISOString().substr(0,10)),
        'termLength': value.termLength,
        'termType': value.termType,
    };
}

