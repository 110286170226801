/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPropertySuccessResponseLatestAssessment
 */
export interface GetPropertySuccessResponseLatestAssessment {
    /**
     * 
     * @type {number}
     * @memberof GetPropertySuccessResponseLatestAssessment
     */
    assessedValueImprovements?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPropertySuccessResponseLatestAssessment
     */
    assessedValueLand?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPropertySuccessResponseLatestAssessment
     */
    assessedValueTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPropertySuccessResponseLatestAssessment
     */
    marketValueImprovements?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPropertySuccessResponseLatestAssessment
     */
    marketValueLand?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPropertySuccessResponseLatestAssessment
     */
    marketValueTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPropertySuccessResponseLatestAssessment
     */
    taxBilledAmount?: number;
}

/**
 * Check if a given object implements the GetPropertySuccessResponseLatestAssessment interface.
 */
export function instanceOfGetPropertySuccessResponseLatestAssessment(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetPropertySuccessResponseLatestAssessmentFromJSON(json: any): GetPropertySuccessResponseLatestAssessment {
    return GetPropertySuccessResponseLatestAssessmentFromJSONTyped(json, false);
}

export function GetPropertySuccessResponseLatestAssessmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPropertySuccessResponseLatestAssessment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assessedValueImprovements': !exists(json, 'assessedValueImprovements') ? undefined : json['assessedValueImprovements'],
        'assessedValueLand': !exists(json, 'assessedValueLand') ? undefined : json['assessedValueLand'],
        'assessedValueTotal': !exists(json, 'assessedValueTotal') ? undefined : json['assessedValueTotal'],
        'marketValueImprovements': !exists(json, 'marketValueImprovements') ? undefined : json['marketValueImprovements'],
        'marketValueLand': !exists(json, 'marketValueLand') ? undefined : json['marketValueLand'],
        'marketValueTotal': !exists(json, 'marketValueTotal') ? undefined : json['marketValueTotal'],
        'taxBilledAmount': !exists(json, 'taxBilledAmount') ? undefined : json['taxBilledAmount'],
    };
}

export function GetPropertySuccessResponseLatestAssessmentToJSON(value?: GetPropertySuccessResponseLatestAssessment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assessedValueImprovements': value.assessedValueImprovements,
        'assessedValueLand': value.assessedValueLand,
        'assessedValueTotal': value.assessedValueTotal,
        'marketValueImprovements': value.marketValueImprovements,
        'marketValueLand': value.marketValueLand,
        'marketValueTotal': value.marketValueTotal,
        'taxBilledAmount': value.taxBilledAmount,
    };
}

