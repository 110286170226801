/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetProducersExpiringSuccessResponseItem } from './GetProducersExpiringSuccessResponseItem';
import {
    GetProducersExpiringSuccessResponseItemFromJSON,
    GetProducersExpiringSuccessResponseItemFromJSONTyped,
    GetProducersExpiringSuccessResponseItemToJSON,
} from './GetProducersExpiringSuccessResponseItem';
import type { GetProducersExpiringType } from './GetProducersExpiringType';
import {
    GetProducersExpiringTypeFromJSON,
    GetProducersExpiringTypeFromJSONTyped,
    GetProducersExpiringTypeToJSON,
} from './GetProducersExpiringType';
import type { ProducerLicense } from './ProducerLicense';
import {
    ProducerLicenseFromJSON,
    ProducerLicenseFromJSONTyped,
    ProducerLicenseToJSON,
} from './ProducerLicense';
import type { ProducerType } from './ProducerType';
import {
    ProducerTypeFromJSON,
    ProducerTypeFromJSONTyped,
    ProducerTypeToJSON,
} from './ProducerType';

/**
 * 
 * @export
 * @interface GetProducersExpiringSuccessResponseItemLicense
 */
export interface GetProducersExpiringSuccessResponseItemLicense extends GetProducersExpiringSuccessResponseItem {
    /**
     * 
     * @type {ProducerLicense}
     * @memberof GetProducersExpiringSuccessResponseItemLicense
     */
    license: ProducerLicense;
}

/**
 * Check if a given object implements the GetProducersExpiringSuccessResponseItemLicense interface.
 */
export function instanceOfGetProducersExpiringSuccessResponseItemLicense(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "license" in value;

    return isInstance;
}

export function GetProducersExpiringSuccessResponseItemLicenseFromJSON(json: any): GetProducersExpiringSuccessResponseItemLicense {
    return GetProducersExpiringSuccessResponseItemLicenseFromJSONTyped(json, false);
}

export function GetProducersExpiringSuccessResponseItemLicenseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProducersExpiringSuccessResponseItemLicense {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...GetProducersExpiringSuccessResponseItemFromJSONTyped(json, ignoreDiscriminator),
        'license': ProducerLicenseFromJSON(json['license']),
    };
}

export function GetProducersExpiringSuccessResponseItemLicenseToJSON(value?: GetProducersExpiringSuccessResponseItemLicense | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...GetProducersExpiringSuccessResponseItemToJSON(value),
        'license': ProducerLicenseToJSON(value.license),
    };
}

