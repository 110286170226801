import {Flex} from "@aws-amplify/ui-react";
import {QueryFunction, useQuery} from "@tanstack/react-query";
import {Query} from "@title-service/react-query-utils";
import {
  DateComponent,
  DateTimeComponent,
  Text,
  usePrimarySectionGap,
} from "@title-service/ui";
import React, {useCallback, useContext, useMemo, useState} from "react";
import styled from "styled-components";

import {DependencyContext} from "../../../DependencyContext";
import {
  InsurfulOrderResult,
  InsurfulOrderResultSuccessful,
  InsurfulOrderSummary,
  isInsurfulOrderResultPending,
  isInsurfulOrderResultSuccessful,
} from "../../adminApi";
import {DataGrid, GridItem} from "../../components/DataGrid";
import {DownloadLink} from "../../components/Link";
import {
  MaybeNotAvailable,
  StringMaybeBlankComponent,
} from "../../components/Nullable";
import {CaretDirection, CaretIcon} from "../../components/icons";
import {
  BodyRow as PrimaryBodyRow,
  HeaderRow as PrimaryHeaderRow,
  Table as PrimaryTable,
  TBody as PrimaryTBody,
  Td as PrimaryTd,
  Th as PrimaryTh,
  THead as PrimaryTHead,
} from "../../components/tables/PrimaryTable";
import {
  BodyRow as SecondaryBodyRow,
  HeaderRow as SecondaryHeaderRow,
  Table as SecondaryTable,
  TBody as SecondaryTBody,
  Td as SecondaryTd,
  Th as SecondaryTh,
  THead as SecondaryTHead,
} from "../../components/tables/SecondaryTable";
import {
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../../layout";

export type InsurfulReportsTabProps = {
  insurfulOrderSummaries: InsurfulOrderSummary[];
};

export const InsurfulReportsSection: React.FC<InsurfulReportsTabProps> = ({
  insurfulOrderSummaries,
}) => (
  <SecondarySection>
    <SecondarySectionHeaderContainer>
      <SecondarySectionHeader value="TINA Reports" />
    </SecondarySectionHeaderContainer>
    <SecondarySectionContent>
      {insurfulOrderSummaries.length > 0 ? (
        <PrimaryTable>
          <PrimaryTHead>
            <PrimaryHeaderRow>
              <PrimaryTh>Created</PrimaryTh>
              <PrimaryTh>Status</PrimaryTh>
              <PrimaryTh />
            </PrimaryHeaderRow>
          </PrimaryTHead>
          <PrimaryTBody>
            {insurfulOrderSummaries.map((summary) => (
              <OrderResultRowQuery key={summary.id} summary={summary} />
            ))}
          </PrimaryTBody>
        </PrimaryTable>
      ) : (
        <span>No TINA Reports Ordered</span>
      )}
    </SecondarySectionContent>
  </SecondarySection>
);

type GetInsurfulOrderResultQueryKey = ["insurful", string];
type GetInsurfulOrderResultQuery = Query<InsurfulOrderResult>;

const useGetInsurfulOrderResult = (
  orderId: string,
): GetInsurfulOrderResultQuery => {
  const {adminApi} = useContext(DependencyContext);

  const getInsurfulOrderResultQueryKey: GetInsurfulOrderResultQueryKey =
    useMemo(() => ["insurful", orderId], [orderId]);
  const getInsurfulOrderResultQueryFn: QueryFunction<
    InsurfulOrderResult,
    GetInsurfulOrderResultQueryKey
  > = useCallback(
    ({queryKey: [_, orderId_], signal}) =>
      adminApi.getInsurfulOrderResult({insurfulOrderId: orderId_}, signal),
    [adminApi],
  );
  return useQuery({
    queryKey: getInsurfulOrderResultQueryKey,
    queryFn: getInsurfulOrderResultQueryFn,
  });
};

export const OrderResultRowQuery: React.FC<{
  summary: InsurfulOrderSummary;
  OrderResultRowQueryResult?: typeof OrderResultRowQueryResult;
}> = ({
  summary,
  OrderResultRowQueryResult:
    OrderResultRowQueryResult_ = OrderResultRowQueryResult,
}) => {
  const getInsurfulOrderResultQuery = useGetInsurfulOrderResult(summary.id);
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <OrderResultRowQueryResult_
      summary={summary}
      getInsurfulOrderResultQuery={getInsurfulOrderResultQuery}
    />
  );
};

export const OrderResultRowQueryResult: React.FC<{
  summary: InsurfulOrderSummary;
  getInsurfulOrderResultQuery: GetInsurfulOrderResultQuery;
  OrderResultSuccessfulRow?: typeof OrderResultSuccessfulRow;
  OrderResultRow?: typeof OrderResultRow;
}> = ({
  summary,
  getInsurfulOrderResultQuery: {data, error},
  OrderResultSuccessfulRow:
    OrderResultSuccessfulRow_ = OrderResultSuccessfulRow,
  OrderResultRow: OrderResultRow_ = OrderResultRow,
}) => {
  if (data) {
    if (isInsurfulOrderResultSuccessful(data)) {
      // eslint-disable-next-line react/jsx-pascal-case
      return <OrderResultSuccessfulRow_ summary={summary} result={data} />;
    } else if (isInsurfulOrderResultPending(data)) {
      // eslint-disable-next-line react/jsx-pascal-case
      return <OrderResultRow_ summary={summary} status="Pending" />;
    }
    // eslint-disable-next-line react/jsx-pascal-case
    return <OrderResultRow_ summary={summary} status="Failed" />;
  } else if (error) {
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <OrderResultRow_ summary={summary} status="Failed to load Order Status" />
    );
  }
  // eslint-disable-next-line react/jsx-pascal-case
  return <OrderResultRow_ summary={summary} status="Loading Order Status..." />;
};

export const OrderResultSuccessfulRow: React.FC<{
  summary: InsurfulOrderSummary;
  result: InsurfulOrderResultSuccessful;
}> = ({summary, result}) => {
  const [displayDetails, setDisplayDetails] = useState(false);
  const toggleDetailsMenu = useCallback(() => {
    setDisplayDetails((previousDisplayDetails) => !previousDisplayDetails);
  }, []);
  const primarySectionGap = usePrimarySectionGap();

  return (
    <>
      <OrderResultRow
        summary={summary}
        status="Successful"
        onClick={toggleDetailsMenu}
        caret={displayDetails ? CaretDirection.DOWN : CaretDirection.RIGHT}
      />
      {displayDetails ? (
        <PrimaryBodyRow>
          <PrimaryTd colSpan={4}>
            <Flex direction="column" gap={primarySectionGap}>
              <SecondarySection>
                <DataGrid>
                  <GridItem>
                    <DownloadLink href={result.rawResponseUrl}>
                      Raw JSON Response
                    </DownloadLink>
                  </GridItem>
                </DataGrid>
              </SecondarySection>
              <SecondarySection>
                <SecondarySectionHeaderContainer>
                  <SecondarySectionHeader value="Documents" />
                </SecondarySectionHeaderContainer>
                <SecondarySectionContent>
                  <SecondaryTable>
                    <SecondaryTHead>
                      <SecondaryHeaderRow>
                        <SecondaryTh>Name</SecondaryTh>
                        <SecondaryTh>Description</SecondaryTh>
                        <SecondaryTh>Document Number</SecondaryTh>
                        <SecondaryTh>Recording Date</SecondaryTh>
                      </SecondaryHeaderRow>
                    </SecondaryTHead>
                    <SecondaryTBody>
                      {result.documents.map((insurfulOrderDocument) => (
                        <SecondaryBodyRow
                          key={insurfulOrderDocument.documentName}
                        >
                          <SecondaryTd>
                            <DownloadLink
                              href={insurfulOrderDocument.documentUrl}
                            >
                              {insurfulOrderDocument.documentName}
                            </DownloadLink>
                          </SecondaryTd>
                          <SecondaryTd>
                            {insurfulOrderDocument.documentTypeDescription}
                          </SecondaryTd>
                          <SecondaryTd>
                            <StringMaybeBlankComponent
                              value={insurfulOrderDocument.documentNumber}
                            />
                          </SecondaryTd>
                          <SecondaryTd>
                            <MaybeNotAvailable
                              value={insurfulOrderDocument.recordingDate}
                            >
                              {(recordingDate) => (
                                <DateComponent value={recordingDate} />
                              )}
                            </MaybeNotAvailable>
                          </SecondaryTd>
                        </SecondaryBodyRow>
                      ))}
                    </SecondaryTBody>
                  </SecondaryTable>
                </SecondarySectionContent>
              </SecondarySection>
            </Flex>
          </PrimaryTd>
        </PrimaryBodyRow>
      ) : null}
    </>
  );
};

export const OrderResultRow: React.FC<{
  summary: InsurfulOrderSummary;
  status: string;
  caret?: CaretDirection;
  onClick?: React.ComponentProps<typeof CaretIcon>["onClick"];
}> = ({summary, status, caret, onClick}) => (
  <PrimaryBodyRow>
    <InsurfulOrderCreatedAtTd>
      <DateTimeComponent value={summary.createdAt} />
    </InsurfulOrderCreatedAtTd>
    <InsurfulOrderStatusTd>
      <Text value={status} />
    </InsurfulOrderStatusTd>
    <CaretTd>
      {caret ? (
        <CaretIcon onClick={onClick} fontSize="xs" direction={caret} />
      ) : null}
    </CaretTd>
  </PrimaryBodyRow>
);

const InsurfulOrderCreatedAtTd = styled(PrimaryTd)({
  width: "100px",
});

const InsurfulOrderStatusTd = styled(PrimaryTd)({
  width: "450px",
});

const CaretTd = styled(PrimaryTd)({
  width: "20px",
});
