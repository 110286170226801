/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    county: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    zipCode: string;
}

/**
 * Check if a given object implements the Address interface.
 */
export function instanceOfAddress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "county" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "zipCode" in value;

    return isInstance;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'city': json['city'],
        'county': json['county'],
        'state': json['state'],
        'street': json['street'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'zipCode': json['zipCode'],
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city': value.city,
        'county': value.county,
        'state': value.state,
        'street': value.street,
        'unit': value.unit,
        'zipCode': value.zipCode,
    };
}

