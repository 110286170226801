import {Storage} from "aws-amplify";

type AmplifyStorageGetFn = (typeof Storage)["get"];
export type AmplifyStorageAccessLevel = NonNullable<
  Parameters<AmplifyStorageGetFn>[1]
>["level"];

export const getFullObjectKey = async (
  amplifyStorageKey: string,
  level: AmplifyStorageAccessLevel,
  amplifyStorageGet: AmplifyStorageGetFn = Storage.get,
): Promise<string> => {
  const s3FileUrl = await amplifyStorageGet(amplifyStorageKey, {
    download: false,
    level,
  });
  const url = new URL(s3FileUrl);
  const path = decodeURIComponent(url.pathname);
  return path.substring(1);
};
