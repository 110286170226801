/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RemittanceItem } from './RemittanceItem';
import {
    RemittanceItemFromJSON,
    RemittanceItemFromJSONTyped,
    RemittanceItemToJSON,
} from './RemittanceItem';
import type { RemittanceType } from './RemittanceType';
import {
    RemittanceTypeFromJSON,
    RemittanceTypeFromJSONTyped,
    RemittanceTypeToJSON,
} from './RemittanceType';

/**
 * 
 * @export
 * @interface RemittanceItemCpl
 */
export interface RemittanceItemCpl extends RemittanceItem {
    /**
     * 
     * @type {string}
     * @memberof RemittanceItemCpl
     */
    cplId: string;
}

/**
 * Check if a given object implements the RemittanceItemCpl interface.
 */
export function instanceOfRemittanceItemCpl(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cplId" in value;

    return isInstance;
}

export function RemittanceItemCplFromJSON(json: any): RemittanceItemCpl {
    return RemittanceItemCplFromJSONTyped(json, false);
}

export function RemittanceItemCplFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemittanceItemCpl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...RemittanceItemFromJSONTyped(json, ignoreDiscriminator),
        'cplId': json['cplId'],
    };
}

export function RemittanceItemCplToJSON(value?: RemittanceItemCpl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...RemittanceItemToJSON(value),
        'cplId': value.cplId,
    };
}

