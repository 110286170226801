import {FormikConnectedAmplifyTextAreaField} from "@title-service/ui";
import React from "react";
import * as Yup from "yup";

export const DESCRIPTION_SCHEMA: Yup.StringSchema<string> = Yup.string()
  .required("Description is required")
  .trim();

export const DescriptionField: React.FC<
  Omit<
    React.ComponentProps<typeof FormikConnectedAmplifyTextAreaField>,
    "label"
  >
> = (props) => (
  <FormikConnectedAmplifyTextAreaField
    testId="description-input"
    label="Description"
    rows={5}
    {...props}
  />
);
