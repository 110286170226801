/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GetPoliciesSortField = {
    Created: 'CREATED',
    State: 'STATE',
    SequenceNumber: 'SEQUENCE_NUMBER',
    Updated: 'UPDATED'
} as const;
export type GetPoliciesSortField = typeof GetPoliciesSortField[keyof typeof GetPoliciesSortField];


export function GetPoliciesSortFieldFromJSON(json: any): GetPoliciesSortField {
    return GetPoliciesSortFieldFromJSONTyped(json, false);
}

export function GetPoliciesSortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPoliciesSortField {
    return json as GetPoliciesSortField;
}

export function GetPoliciesSortFieldToJSON(value?: GetPoliciesSortField | null): any {
    return value as any;
}

