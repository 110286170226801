import {
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {Query} from "@title-service/react-query-utils";
import {
  DateComponent,
  FormikConnectedAmplifySubmitButton,
  FormikConnectedAmplifyTextAreaField,
  FormikForm,
  FormResultErrorMessage,
  VerticalFieldset,
} from "@title-service/ui";
import {Formik, FormikHelpers} from "formik";
import React, {useCallback, useContext, useMemo, useState} from "react";
import styled from "styled-components";
import * as Yup from "yup";

import {DependencyContext} from "../../DependencyContext";
import {
  ClaimStatus,
  CreateClaimNoteRequest,
  GetClaimNotesSuccessResponse,
  GetClaimNotesSuccessResponseNote,
  UpdateClaimNoteRequest,
} from "../adminApi";
import {Modal} from "../components/Modal";
import {EditIcon, PlusIcon} from "../components/icons";
import {
  BodyRow as SecondaryBodyRow,
  HeaderRow as SecondaryHeaderRow,
  Table as SecondaryTable,
  TBody as SecondaryTBody,
  Td as SecondaryTd,
  Th as SecondaryTh,
  THead as SecondaryTHead,
} from "../components/tables/SecondaryTable";
import {
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../layout";
import {COLOR_N_500, FONT_SIZE_H7} from "../theme";

import {IfClaimIsInProgress} from "./IfClaimIsInProgress";

type GetClaimNotesQueryKey = ["claims", string, "notes"];
export type GetClaimNotesQuery = Query<GetClaimNotesSuccessResponse>;

export const useGetClaimNotes = (
  claimId: string,
): {
  getClaimNotesQuery: GetClaimNotesQuery;
  onReloadClaimNotes: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);
  const getClaimNotesQueryKey: GetClaimNotesQueryKey = useMemo(
    () => ["claims", claimId, "notes"],
    [claimId],
  );
  const getClaimNotesQueryFn: QueryFunction<
    GetClaimNotesSuccessResponse,
    GetClaimNotesQueryKey
  > = useCallback(
    ({queryKey: [_, claimId_], signal}) =>
      adminApi.getClaimNotes({claimId: claimId_}, signal),
    [adminApi],
  );
  const getClaimNotesQuery = useQuery({
    queryKey: getClaimNotesQueryKey,
    queryFn: getClaimNotesQueryFn,
  });
  const onReloadClaimNotes = useCallback(
    () => queryClient.invalidateQueries({queryKey: getClaimNotesQueryKey}),
    [queryClient, getClaimNotesQueryKey],
  );

  return {
    getClaimNotesQuery,
    onReloadClaimNotes,
  };
};

export const ClaimNotesSection: React.FC<{
  claimId: string;
  claimStatus: ClaimStatus;
  onReloadClaimNotes: () => any;
  getClaimNotesQuery: GetClaimNotesQuery;
  editModalContentStyle: NonNullable<
    React.ComponentProps<typeof Modal>["contentStyle"]
  >;
  ClaimNotesSuccessComponent?: typeof ClaimNotesSuccessComponent;
  ClaimNotesFailureComponent?: typeof ClaimNotesFailureComponent;
  ClaimNotesLoadingComponent?: typeof ClaimNotesLoadingComponent;
}> = ({
  claimId,
  claimStatus,
  onReloadClaimNotes,
  getClaimNotesQuery: {data, error},
  editModalContentStyle,
  ClaimNotesSuccessComponent:
    ClaimNotesSuccessComponent_ = ClaimNotesSuccessComponent,
  ClaimNotesFailureComponent:
    ClaimNotesFailureComponent_ = ClaimNotesFailureComponent,
  ClaimNotesLoadingComponent:
    ClaimNotesLoadingComponent_ = ClaimNotesLoadingComponent,
}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);

  return (
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Notes" />
        <IfClaimIsInProgress status={claimStatus}>
          {data ? (
            <PlusIcon data-testid="create-note-button" onClick={openForm} />
          ) : null}
        </IfClaimIsInProgress>
      </SecondarySectionHeaderContainer>
      <SecondarySectionContent>
        {data ? (
          <>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <ClaimNotesSuccessComponent_
              claimId={claimId}
              claimStatus={claimStatus}
              getClaimNotesResponse={data}
              onReloadClaimNotes={onReloadClaimNotes}
              editModalContentStyle={editModalContentStyle}
            />
            <Modal
              contentStyle={editModalContentStyle}
              isOpen={displayForm}
              onClose={closeForm}
              title="Add Note"
            >
              <CreateClaimNoteForm
                claimId={claimId}
                onClose={closeForm}
                onSubmitComplete={onReloadClaimNotes}
              />
            </Modal>
          </>
        ) : error ? (
          // eslint-disable-next-line react/jsx-pascal-case
          <ClaimNotesFailureComponent_ />
        ) : (
          // eslint-disable-next-line react/jsx-pascal-case
          <ClaimNotesLoadingComponent_ />
        )}
      </SecondarySectionContent>
    </SecondarySection>
  );
};

export const ClaimNotesLoadingComponent: React.FC = () => (
  <span>Loading Claim Notes ...</span>
);

export const ClaimNotesFailureComponent: React.FC = () => (
  <span>Failed to load Claim Notes.</span>
);

export const claimNoteWasEdited = (createdAt: Date, updatedAt: Date) =>
  createdAt.getTime() !== updatedAt.getTime();

export const MaybeClaimNoteEditedFlag: React.FC<{
  createdAt: Date;
  updatedAt: Date;
}> = ({createdAt, updatedAt}) => (
  <>
    {claimNoteWasEdited(createdAt, updatedAt) ? (
      <EditedFlagText>(edited)</EditedFlagText>
    ) : null}
  </>
);

const EditedFlagText = styled.p({
  color: COLOR_N_500,
  fontWeight: "bold",
  ...FONT_SIZE_H7,
});

export const ClaimNotesSuccessComponent: React.FC<{
  claimId: string;
  claimStatus: ClaimStatus;
  getClaimNotesResponse: GetClaimNotesSuccessResponse;
  onReloadClaimNotes: () => any;
  editModalContentStyle: NonNullable<
    React.ComponentProps<typeof Modal>["contentStyle"]
  >;
}> = ({
  claimId,
  claimStatus,
  getClaimNotesResponse: {notes},
  onReloadClaimNotes,
  editModalContentStyle,
}) => (
  <SecondaryTable>
    <SecondaryTHead>
      <SecondaryHeaderRow>
        <IfClaimIsInProgress status={claimStatus}>
          {notes.length > 0 && <SecondaryTh width="50px" />}
          {/* edit claim note button column */}
        </IfClaimIsInProgress>
        <SecondaryTh width="110px">Created At</SecondaryTh>
        <SecondaryTh width="150px">Author</SecondaryTh>
        <SecondaryTh>Note</SecondaryTh>
      </SecondaryHeaderRow>
    </SecondaryTHead>
    <SecondaryTBody>
      {notes.length === 0 ? (
        <SecondaryBodyRow>
          <SecondaryTd colSpan={6}>No Notes Recorded</SecondaryTd>
        </SecondaryBodyRow>
      ) : (
        notes.map((note) => (
          <ClaimNoteRow
            key={note.id}
            claimId={claimId}
            claimStatus={claimStatus}
            note={note}
            onReloadClaimNotes={onReloadClaimNotes}
            editModalContentStyle={editModalContentStyle}
          />
        ))
      )}
    </SecondaryTBody>
  </SecondaryTable>
);

const ClaimNoteRow: React.FC<{
  claimId: string;
  claimStatus: ClaimStatus;
  note: GetClaimNotesSuccessResponseNote;
  onReloadClaimNotes: () => any;
  editModalContentStyle: NonNullable<
    React.ComponentProps<typeof Modal>["contentStyle"]
  >;
}> = ({
  claimId,
  claimStatus,
  note: {id, givenName, familyName, note, createdAt, updatedAt, editable},
  onReloadClaimNotes,
  editModalContentStyle,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <SecondaryBodyRow>
      <IfClaimIsInProgress status={claimStatus}>
        <SecondaryTd>
          {editable ? (
            <EditIcon data-testid="edit-note-button" onClick={openModal} />
          ) : null}
        </SecondaryTd>
      </IfClaimIsInProgress>
      <SecondaryTd>
        <DateComponent value={createdAt} />
      </SecondaryTd>
      <SecondaryTd>{`${givenName} ${familyName}`}</SecondaryTd>
      <SecondaryTd>
        {note}
        <MaybeClaimNoteEditedFlag createdAt={createdAt} updatedAt={updatedAt} />
      </SecondaryTd>
      <Modal
        contentStyle={editModalContentStyle}
        isOpen={modalOpen}
        onClose={closeModal}
        title="Edit Note"
      >
        <UpdateClaimNoteForm
          claimId={claimId}
          claimNoteId={id}
          claimNoteTextToEdit={note}
          onClose={closeModal}
          onSubmitComplete={onReloadClaimNotes}
        />
      </Modal>
    </SecondaryBodyRow>
  );
};

export const NOTE_SCHEMA: Yup.StringSchema<string> = Yup.string()
  .required("Note is required")
  .trim();

export const NoteField: React.FC<
  Omit<
    React.ComponentProps<typeof FormikConnectedAmplifyTextAreaField>,
    "label"
  >
> = (props) => (
  // testId prop is broken for TextAreaField
  <FormikConnectedAmplifyTextAreaField
    data-testid="note-input"
    label="Note"
    rows={5}
    {...props}
  />
);

export const CREATE_CLAIM_NOTE_FORM_SCHEMA: Yup.ObjectSchema<CreateClaimNoteFormData> =
  Yup.object({
    note: NOTE_SCHEMA,
  });

export type CreateClaimNoteFormData = Omit<CreateClaimNoteRequest, "claimId">;

export const buildInitialCreateClaimNoteFormData =
  (): CreateClaimNoteFormData => ({
    note: "",
  });

export const mapClaimNoteFormDataToCreateClaimNoteRequest = (
  claimId: string,
  formData: CreateClaimNoteFormData,
): CreateClaimNoteRequest => {
  const completeFormData = CREATE_CLAIM_NOTE_FORM_SCHEMA.validateSync(formData);
  return {
    claimId,
    ...completeFormData,
  };
};

export const CreateClaimNoteForm: React.FC<{
  claimId: string;
  onClose: () => any;
  onSubmitComplete: () => any;
}> = ({claimId, onClose, onSubmitComplete}) => {
  const {adminApi} = useContext(DependencyContext);
  const createClaimNoteMutation = useMutation({
    mutationFn: adminApi.createClaimNote,
  });
  const submitHandler = useCallback(
    (
      formData: CreateClaimNoteFormData,
      {setSubmitting}: FormikHelpers<CreateClaimNoteFormData>,
    ) => {
      const request = mapClaimNoteFormDataToCreateClaimNoteRequest(
        claimId,
        formData,
      );
      createClaimNoteMutation.mutate(request, {
        onSuccess: () => {
          onSubmitComplete();
          onClose();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [claimId, createClaimNoteMutation, onClose, onSubmitComplete],
  );
  return (
    <Formik<CreateClaimNoteFormData>
      initialValues={buildInitialCreateClaimNoteFormData()}
      validationSchema={CREATE_CLAIM_NOTE_FORM_SCHEMA as any}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <NoteField name="note" />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton />

        {createClaimNoteMutation.isError ? (
          <FormResultErrorMessage data-testid="error-state">
            Request failed.
          </FormResultErrorMessage>
        ) : null}
      </FormikForm>
    </Formik>
  );
};

export const EDIT_CLAIM_NOTE_FORM_SCHEMA: Yup.ObjectSchema<CreateClaimNoteFormData> =
  Yup.object({
    note: NOTE_SCHEMA,
  });

export type UpdateClaimNoteFormData = Omit<
  UpdateClaimNoteRequest,
  "claimId" | "claimNoteId"
>;

export const buildInitialUpdateClaimNoteFormData = (
  claimNoteTextToEdit: string,
): UpdateClaimNoteFormData => ({
  note: claimNoteTextToEdit,
});

export const mapClaimNoteFormDataToUpdateClaimNoteRequest = (
  claimId: string,
  claimNoteId: string,
  formData: UpdateClaimNoteFormData,
): UpdateClaimNoteRequest => {
  const completeFormData = EDIT_CLAIM_NOTE_FORM_SCHEMA.validateSync(formData);
  return {
    claimId,
    claimNoteId,
    ...completeFormData,
  };
};

const UpdateClaimNoteForm: React.FC<{
  claimId: string;
  claimNoteId: string;
  claimNoteTextToEdit: string;
  onClose: () => any;
  onSubmitComplete: () => any;
}> = ({
  claimId,
  claimNoteId,
  claimNoteTextToEdit,
  onClose,
  onSubmitComplete,
}) => {
  const {adminApi} = useContext(DependencyContext);
  const updateClaimNoteMutation = useMutation({
    mutationFn: adminApi.updateClaimNote,
  });
  const submitHandler = useCallback(
    (
      formData: UpdateClaimNoteFormData,
      {setSubmitting}: FormikHelpers<UpdateClaimNoteFormData>,
    ) => {
      const request = mapClaimNoteFormDataToUpdateClaimNoteRequest(
        claimId,
        claimNoteId,
        formData,
      );
      updateClaimNoteMutation.mutate(request, {
        onSuccess: () => {
          onSubmitComplete();
          onClose();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [claimId, claimNoteId, updateClaimNoteMutation, onSubmitComplete, onClose],
  );
  return (
    <Formik<UpdateClaimNoteFormData>
      initialValues={buildInitialUpdateClaimNoteFormData(claimNoteTextToEdit)}
      validationSchema={EDIT_CLAIM_NOTE_FORM_SCHEMA as any}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <NoteField name="note" />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton />

        {updateClaimNoteMutation.isError ? (
          <FormResultErrorMessage data-testid="error-state">
            Request failed.
          </FormResultErrorMessage>
        ) : null}
      </FormikForm>
    </Formik>
  );
};
