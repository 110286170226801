import {datadogLogs} from "@datadog/browser-logs";

export const safeDisplayEnumFn =
  <T extends string>(display: (value: T) => string): ((value: T) => string) =>
  (value) => {
    try {
      return display(value);
    } catch (e) {
      if (e instanceof Error) {
        datadogLogs.logger.error(e.message, {
          // eslint-disable-next-line camelcase
          stack_trace: e.stack,
        });
      } else {
        datadogLogs.logger.error(
          `An unknown error occurred when converting enum to string: ${value}`,
        );
      }
      return value;
    }
  };
