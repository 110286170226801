/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProducerActivation } from './ProducerActivation';
import {
    ProducerActivationFromJSON,
    ProducerActivationFromJSONTyped,
    ProducerActivationToJSON,
} from './ProducerActivation';
import type { ProducerAppointment } from './ProducerAppointment';
import {
    ProducerAppointmentFromJSON,
    ProducerAppointmentFromJSONTyped,
    ProducerAppointmentToJSON,
} from './ProducerAppointment';
import type { ProducerJurisdictionStatus } from './ProducerJurisdictionStatus';
import {
    ProducerJurisdictionStatusFromJSON,
    ProducerJurisdictionStatusFromJSONTyped,
    ProducerJurisdictionStatusToJSON,
} from './ProducerJurisdictionStatus';
import type { ProducerLicense } from './ProducerLicense';
import {
    ProducerLicenseFromJSON,
    ProducerLicenseFromJSONTyped,
    ProducerLicenseToJSON,
} from './ProducerLicense';
import type { ProducerType } from './ProducerType';
import {
    ProducerTypeFromJSON,
    ProducerTypeFromJSONTyped,
    ProducerTypeToJSON,
} from './ProducerType';

/**
 * 
 * @export
 * @interface GetProducerJurisdictionsSuccessResponseJurisdiction
 */
export interface GetProducerJurisdictionsSuccessResponseJurisdiction {
    /**
     * 
     * @type {boolean}
     * @memberof GetProducerJurisdictionsSuccessResponseJurisdiction
     */
    appointmentRequired: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetProducerJurisdictionsSuccessResponseJurisdiction
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof GetProducerJurisdictionsSuccessResponseJurisdiction
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetProducerJurisdictionsSuccessResponseJurisdiction
     */
    jurisdiction: string;
    /**
     * 
     * @type {ProducerActivation}
     * @memberof GetProducerJurisdictionsSuccessResponseJurisdiction
     */
    mostRecentActivation?: ProducerActivation;
    /**
     * 
     * @type {ProducerAppointment}
     * @memberof GetProducerJurisdictionsSuccessResponseJurisdiction
     */
    mostRecentAppointment?: ProducerAppointment;
    /**
     * 
     * @type {ProducerLicense}
     * @memberof GetProducerJurisdictionsSuccessResponseJurisdiction
     */
    mostRecentLicense?: ProducerLicense;
    /**
     * 
     * @type {string}
     * @memberof GetProducerJurisdictionsSuccessResponseJurisdiction
     */
    producerId: string;
    /**
     * 
     * @type {string}
     * @memberof GetProducerJurisdictionsSuccessResponseJurisdiction
     */
    producerName: string;
    /**
     * 
     * @type {ProducerType}
     * @memberof GetProducerJurisdictionsSuccessResponseJurisdiction
     */
    producerType: ProducerType;
    /**
     * 
     * @type {ProducerJurisdictionStatus}
     * @memberof GetProducerJurisdictionsSuccessResponseJurisdiction
     */
    status: ProducerJurisdictionStatus;
}

/**
 * Check if a given object implements the GetProducerJurisdictionsSuccessResponseJurisdiction interface.
 */
export function instanceOfGetProducerJurisdictionsSuccessResponseJurisdiction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "appointmentRequired" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "jurisdiction" in value;
    isInstance = isInstance && "producerId" in value;
    isInstance = isInstance && "producerName" in value;
    isInstance = isInstance && "producerType" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function GetProducerJurisdictionsSuccessResponseJurisdictionFromJSON(json: any): GetProducerJurisdictionsSuccessResponseJurisdiction {
    return GetProducerJurisdictionsSuccessResponseJurisdictionFromJSONTyped(json, false);
}

export function GetProducerJurisdictionsSuccessResponseJurisdictionFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProducerJurisdictionsSuccessResponseJurisdiction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appointmentRequired': json['appointmentRequired'],
        'createdAt': (new Date(json['createdAt'])),
        'id': json['id'],
        'jurisdiction': json['jurisdiction'],
        'mostRecentActivation': !exists(json, 'mostRecentActivation') ? undefined : ProducerActivationFromJSON(json['mostRecentActivation']),
        'mostRecentAppointment': !exists(json, 'mostRecentAppointment') ? undefined : ProducerAppointmentFromJSON(json['mostRecentAppointment']),
        'mostRecentLicense': !exists(json, 'mostRecentLicense') ? undefined : ProducerLicenseFromJSON(json['mostRecentLicense']),
        'producerId': json['producerId'],
        'producerName': json['producerName'],
        'producerType': ProducerTypeFromJSON(json['producerType']),
        'status': ProducerJurisdictionStatusFromJSON(json['status']),
    };
}

export function GetProducerJurisdictionsSuccessResponseJurisdictionToJSON(value?: GetProducerJurisdictionsSuccessResponseJurisdiction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appointmentRequired': value.appointmentRequired,
        'createdAt': (value.createdAt.toISOString()),
        'id': value.id,
        'jurisdiction': value.jurisdiction,
        'mostRecentActivation': ProducerActivationToJSON(value.mostRecentActivation),
        'mostRecentAppointment': ProducerAppointmentToJSON(value.mostRecentAppointment),
        'mostRecentLicense': ProducerLicenseToJSON(value.mostRecentLicense),
        'producerId': value.producerId,
        'producerName': value.producerName,
        'producerType': ProducerTypeToJSON(value.producerType),
        'status': ProducerJurisdictionStatusToJSON(value.status),
    };
}

