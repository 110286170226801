import {Text, TextRaw} from "@title-service/ui";
import React from "react";

import {RemittanceType} from "../adminApi";
import {safeDisplayEnumFn} from "../util/enum";

export const unsafeDisplayRemittanceType = (
  remittanceType: RemittanceType,
): string => {
  switch (remittanceType) {
    case RemittanceType.Cpl:
      return "CPL";
    case RemittanceType.Endorsement:
      return "Endorsement";
    case RemittanceType.Fee:
      return "Fee";
    case RemittanceType.Policy:
      return "Policy";
    default:
      throw new Error(`Unknown Remittance Type: ${remittanceType}`);
  }
};

export const displayRemittanceType = safeDisplayEnumFn(
  unsafeDisplayRemittanceType,
);

export const RemittanceTypeComponentRaw: React.FC<{
  remittanceType: RemittanceType;
}> = ({remittanceType}) => (
  <TextRaw value={displayRemittanceType(remittanceType)} />
);

export const RemittanceTypeComponent: React.FC<
  Omit<React.ComponentProps<typeof Text>, "value"> & {
    remittanceType: RemittanceType;
  }
> = ({remittanceType, ...props}) => (
  <Text {...props} value={displayRemittanceType(remittanceType)} />
);
