import {enumValues} from "@title-service/utils";

import {SortDirection} from "../fetchClient";

export {SortDirection} from "../fetchClient";

const SORT_DIRECTION_VALUES = enumValues(SortDirection);

export const parseSortDirection = (
  sortDirection: string | null,
): SortDirection | undefined => {
  const trimmed = sortDirection?.trim();
  if (trimmed) {
    return SORT_DIRECTION_VALUES.find((value) => value === trimmed);
  }
  return undefined;
};
