import React from "react";

import {ClaimStatus} from "../adminApi";

export const IfClaimIsInProgress: React.FC<{status: ClaimStatus}> = ({
  status,
  children,
}) => {
  if (status === ClaimStatus.InProgress) {
    return <>{children}</>;
  }
  return null;
};
