import {Icon} from "@title-service/ui";
import React from "react";

const VIEW_BOX: React.ComponentProps<typeof Icon>["viewBox"] = {
  width: 24,
  height: 24,
};

/*
 * Icon taken from: https://www.svgrepo.com/svg/502637/download-folder
 * This work is licensed in the public domain (CC0 1.0) with no copyright and no requirement
 * to attribute the original author. For more information on this license,
 * see [here](https://creativecommons.org/publicdomain/zero/1.0/deed.en)
 */
export const DownloadFolderIcon: React.FC<React.ComponentProps<typeof Icon>> = (
  props,
) => (
  <Icon viewBox={VIEW_BOX} {...props}>
    <path
      d="M12 19H5C3.89543 19 3 18.1046 3 17V7C3 5.89543 3.89543 5 5 5H9.58579C9.851 5 10.1054 5.10536 10.2929 5.29289L12 7H19C20.1046 7 21 7.89543 21 9V11"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.5 14V20M18.5 20L16 17.5M18.5 20L21 17.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
