/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttomPropertyTransfer
 */
export interface AttomPropertyTransfer {
    /**
     * 
     * @type {number}
     * @memberof AttomPropertyTransfer
     */
    amount?: number;
    /**
     * 
     * @type {Date}
     * @memberof AttomPropertyTransfer
     */
    date?: Date;
}

/**
 * Check if a given object implements the AttomPropertyTransfer interface.
 */
export function instanceOfAttomPropertyTransfer(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AttomPropertyTransferFromJSON(json: any): AttomPropertyTransfer {
    return AttomPropertyTransferFromJSONTyped(json, false);
}

export function AttomPropertyTransferFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttomPropertyTransfer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
    };
}

export function AttomPropertyTransferToJSON(value?: AttomPropertyTransfer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'date': value.date === undefined ? undefined : (value.date.toISOString().substr(0,10)),
    };
}

