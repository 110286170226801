/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AuditAttachmentType } from './AuditAttachmentType';
import {
    AuditAttachmentTypeFromJSON,
    AuditAttachmentTypeFromJSONTyped,
    AuditAttachmentTypeToJSON,
} from './AuditAttachmentType';

/**
 * 
 * @export
 * @interface UpdateAuditAttachmentRequest
 */
export interface UpdateAuditAttachmentRequest {
    /**
     * 
     * @type {AuditAttachmentType}
     * @memberof UpdateAuditAttachmentRequest
     */
    type?: AuditAttachmentType;
}

/**
 * Check if a given object implements the UpdateAuditAttachmentRequest interface.
 */
export function instanceOfUpdateAuditAttachmentRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateAuditAttachmentRequestFromJSON(json: any): UpdateAuditAttachmentRequest {
    return UpdateAuditAttachmentRequestFromJSONTyped(json, false);
}

export function UpdateAuditAttachmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAuditAttachmentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : AuditAttachmentTypeFromJSON(json['type']),
    };
}

export function UpdateAuditAttachmentRequestToJSON(value?: UpdateAuditAttachmentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': AuditAttachmentTypeToJSON(value.type),
    };
}

