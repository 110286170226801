/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { PropertyDocumentExtractionStatus } from './PropertyDocumentExtractionStatus';
import {
    PropertyDocumentExtractionStatusFromJSON,
    PropertyDocumentExtractionStatusFromJSONTyped,
    PropertyDocumentExtractionStatusToJSON,
} from './PropertyDocumentExtractionStatus';

/**
 * 
 * @export
 * @interface PropertyDocumentSummary
 */
export interface PropertyDocumentSummary {
    /**
     * 
     * @type {Address}
     * @memberof PropertyDocumentSummary
     */
    address: Address;
    /**
     * 
     * @type {Date}
     * @memberof PropertyDocumentSummary
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof PropertyDocumentSummary
     */
    documentNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDocumentSummary
     */
    flagged: boolean;
    /**
     * 
     * @type {string}
     * @memberof PropertyDocumentSummary
     */
    id: string;
    /**
     * 
     * @type {PropertyDocumentExtractionStatus}
     * @memberof PropertyDocumentSummary
     */
    legalDescriptionStatus: PropertyDocumentExtractionStatus;
    /**
     * 
     * @type {PropertyDocumentExtractionStatus}
     * @memberof PropertyDocumentSummary
     */
    vestingInformationStatus: PropertyDocumentExtractionStatus;
}

/**
 * Check if a given object implements the PropertyDocumentSummary interface.
 */
export function instanceOfPropertyDocumentSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "documentNumber" in value;
    isInstance = isInstance && "flagged" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "legalDescriptionStatus" in value;
    isInstance = isInstance && "vestingInformationStatus" in value;

    return isInstance;
}

export function PropertyDocumentSummaryFromJSON(json: any): PropertyDocumentSummary {
    return PropertyDocumentSummaryFromJSONTyped(json, false);
}

export function PropertyDocumentSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyDocumentSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': AddressFromJSON(json['address']),
        'createdAt': (new Date(json['createdAt'])),
        'documentNumber': json['documentNumber'],
        'flagged': json['flagged'],
        'id': json['id'],
        'legalDescriptionStatus': PropertyDocumentExtractionStatusFromJSON(json['legalDescriptionStatus']),
        'vestingInformationStatus': PropertyDocumentExtractionStatusFromJSON(json['vestingInformationStatus']),
    };
}

export function PropertyDocumentSummaryToJSON(value?: PropertyDocumentSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': AddressToJSON(value.address),
        'createdAt': (value.createdAt.toISOString()),
        'documentNumber': value.documentNumber,
        'flagged': value.flagged,
        'id': value.id,
        'legalDescriptionStatus': PropertyDocumentExtractionStatusToJSON(value.legalDescriptionStatus),
        'vestingInformationStatus': PropertyDocumentExtractionStatusToJSON(value.vestingInformationStatus),
    };
}

