/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { AgencyProducerReference } from './AgencyProducerReference';
import {
    AgencyProducerReferenceFromJSON,
    AgencyProducerReferenceFromJSONTyped,
    AgencyProducerReferenceToJSON,
} from './AgencyProducerReference';
import type { ClaimCauseOfLossType } from './ClaimCauseOfLossType';
import {
    ClaimCauseOfLossTypeFromJSON,
    ClaimCauseOfLossTypeFromJSONTyped,
    ClaimCauseOfLossTypeToJSON,
} from './ClaimCauseOfLossType';
import type { ClaimResponsiblePartyType } from './ClaimResponsiblePartyType';
import {
    ClaimResponsiblePartyTypeFromJSON,
    ClaimResponsiblePartyTypeFromJSONTyped,
    ClaimResponsiblePartyTypeToJSON,
} from './ClaimResponsiblePartyType';
import type { ClaimStatus } from './ClaimStatus';
import {
    ClaimStatusFromJSON,
    ClaimStatusFromJSONTyped,
    ClaimStatusToJSON,
} from './ClaimStatus';
import type { PolicyType } from './PolicyType';
import {
    PolicyTypeFromJSON,
    PolicyTypeFromJSONTyped,
    PolicyTypeToJSON,
} from './PolicyType';
import type { TransactionType } from './TransactionType';
import {
    TransactionTypeFromJSON,
    TransactionTypeFromJSONTyped,
    TransactionTypeToJSON,
} from './TransactionType';

/**
 * 
 * @export
 * @interface GetClaimSuccessResponse
 */
export interface GetClaimSuccessResponse {
    /**
     * 
     * @type {Address}
     * @memberof GetClaimSuccessResponse
     */
    address: Address;
    /**
     * 
     * @type {AgencyProducerReference}
     * @memberof GetClaimSuccessResponse
     */
    agency: AgencyProducerReference;
    /**
     * 
     * @type {ClaimCauseOfLossType}
     * @memberof GetClaimSuccessResponse
     */
    causeOfLoss?: ClaimCauseOfLossType;
    /**
     * 
     * @type {string}
     * @memberof GetClaimSuccessResponse
     */
    closedByName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimSuccessResponse
     */
    contactEmail: string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimSuccessResponse
     */
    contactPhone: string;
    /**
     * 
     * @type {Date}
     * @memberof GetClaimSuccessResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof GetClaimSuccessResponse
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimSuccessResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimSuccessResponse
     */
    insuredAssignedAttorneyEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimSuccessResponse
     */
    insuredAssignedAttorneyName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimSuccessResponse
     */
    insuredAssignedAttorneyPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimSuccessResponse
     */
    insuredChosenAttorneyEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimSuccessResponse
     */
    insuredChosenAttorneyName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimSuccessResponse
     */
    insuredChosenAttorneyPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimSuccessResponse
     */
    insuredName: string;
    /**
     * 
     * @type {number}
     * @memberof GetClaimSuccessResponse
     */
    policyAmountOfInsurance?: number;
    /**
     * 
     * @type {string}
     * @memberof GetClaimSuccessResponse
     */
    policyId?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetClaimSuccessResponse
     */
    policyIssuedOn?: Date;
    /**
     * 
     * @type {TransactionType}
     * @memberof GetClaimSuccessResponse
     */
    policyTransactionType?: TransactionType;
    /**
     * 
     * @type {PolicyType}
     * @memberof GetClaimSuccessResponse
     */
    policyType?: PolicyType;
    /**
     * 
     * @type {Date}
     * @memberof GetClaimSuccessResponse
     */
    reportedOn: Date;
    /**
     * 
     * @type {ClaimResponsiblePartyType}
     * @memberof GetClaimSuccessResponse
     */
    responsibleParty?: ClaimResponsiblePartyType;
    /**
     * 
     * @type {ClaimStatus}
     * @memberof GetClaimSuccessResponse
     */
    status: ClaimStatus;
    /**
     * 
     * @type {string}
     * @memberof GetClaimSuccessResponse
     */
    underwriterAttorneyEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimSuccessResponse
     */
    underwriterAttorneyName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimSuccessResponse
     */
    underwriterAttorneyPhone?: string;
    /**
     * 
     * @type {Date}
     * @memberof GetClaimSuccessResponse
     */
    updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GetClaimSuccessResponse
     */
    userAuthorizedToCloseClaim: boolean;
}

/**
 * Check if a given object implements the GetClaimSuccessResponse interface.
 */
export function instanceOfGetClaimSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "agency" in value;
    isInstance = isInstance && "contactEmail" in value;
    isInstance = isInstance && "contactPhone" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "insuredName" in value;
    isInstance = isInstance && "reportedOn" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "userAuthorizedToCloseClaim" in value;

    return isInstance;
}

export function GetClaimSuccessResponseFromJSON(json: any): GetClaimSuccessResponse {
    return GetClaimSuccessResponseFromJSONTyped(json, false);
}

export function GetClaimSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetClaimSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': AddressFromJSON(json['address']),
        'agency': AgencyProducerReferenceFromJSON(json['agency']),
        'causeOfLoss': !exists(json, 'causeOfLoss') ? undefined : ClaimCauseOfLossTypeFromJSON(json['causeOfLoss']),
        'closedByName': !exists(json, 'closedByName') ? undefined : json['closedByName'],
        'contactEmail': json['contactEmail'],
        'contactPhone': json['contactPhone'],
        'createdAt': (new Date(json['createdAt'])),
        'description': json['description'],
        'id': json['id'],
        'insuredAssignedAttorneyEmail': !exists(json, 'insuredAssignedAttorneyEmail') ? undefined : json['insuredAssignedAttorneyEmail'],
        'insuredAssignedAttorneyName': !exists(json, 'insuredAssignedAttorneyName') ? undefined : json['insuredAssignedAttorneyName'],
        'insuredAssignedAttorneyPhone': !exists(json, 'insuredAssignedAttorneyPhone') ? undefined : json['insuredAssignedAttorneyPhone'],
        'insuredChosenAttorneyEmail': !exists(json, 'insuredChosenAttorneyEmail') ? undefined : json['insuredChosenAttorneyEmail'],
        'insuredChosenAttorneyName': !exists(json, 'insuredChosenAttorneyName') ? undefined : json['insuredChosenAttorneyName'],
        'insuredChosenAttorneyPhone': !exists(json, 'insuredChosenAttorneyPhone') ? undefined : json['insuredChosenAttorneyPhone'],
        'insuredName': json['insuredName'],
        'policyAmountOfInsurance': !exists(json, 'policyAmountOfInsurance') ? undefined : json['policyAmountOfInsurance'],
        'policyId': !exists(json, 'policyId') ? undefined : json['policyId'],
        'policyIssuedOn': !exists(json, 'policyIssuedOn') ? undefined : (new Date(json['policyIssuedOn'])),
        'policyTransactionType': !exists(json, 'policyTransactionType') ? undefined : TransactionTypeFromJSON(json['policyTransactionType']),
        'policyType': !exists(json, 'policyType') ? undefined : PolicyTypeFromJSON(json['policyType']),
        'reportedOn': (new Date(json['reportedOn'])),
        'responsibleParty': !exists(json, 'responsibleParty') ? undefined : ClaimResponsiblePartyTypeFromJSON(json['responsibleParty']),
        'status': ClaimStatusFromJSON(json['status']),
        'underwriterAttorneyEmail': !exists(json, 'underwriterAttorneyEmail') ? undefined : json['underwriterAttorneyEmail'],
        'underwriterAttorneyName': !exists(json, 'underwriterAttorneyName') ? undefined : json['underwriterAttorneyName'],
        'underwriterAttorneyPhone': !exists(json, 'underwriterAttorneyPhone') ? undefined : json['underwriterAttorneyPhone'],
        'updatedAt': (new Date(json['updatedAt'])),
        'userAuthorizedToCloseClaim': json['userAuthorizedToCloseClaim'],
    };
}

export function GetClaimSuccessResponseToJSON(value?: GetClaimSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': AddressToJSON(value.address),
        'agency': AgencyProducerReferenceToJSON(value.agency),
        'causeOfLoss': ClaimCauseOfLossTypeToJSON(value.causeOfLoss),
        'closedByName': value.closedByName,
        'contactEmail': value.contactEmail,
        'contactPhone': value.contactPhone,
        'createdAt': (value.createdAt.toISOString()),
        'description': value.description,
        'id': value.id,
        'insuredAssignedAttorneyEmail': value.insuredAssignedAttorneyEmail,
        'insuredAssignedAttorneyName': value.insuredAssignedAttorneyName,
        'insuredAssignedAttorneyPhone': value.insuredAssignedAttorneyPhone,
        'insuredChosenAttorneyEmail': value.insuredChosenAttorneyEmail,
        'insuredChosenAttorneyName': value.insuredChosenAttorneyName,
        'insuredChosenAttorneyPhone': value.insuredChosenAttorneyPhone,
        'insuredName': value.insuredName,
        'policyAmountOfInsurance': value.policyAmountOfInsurance,
        'policyId': value.policyId,
        'policyIssuedOn': value.policyIssuedOn === undefined ? undefined : (value.policyIssuedOn.toISOString().substr(0,10)),
        'policyTransactionType': TransactionTypeToJSON(value.policyTransactionType),
        'policyType': PolicyTypeToJSON(value.policyType),
        'reportedOn': (value.reportedOn.toISOString().substr(0,10)),
        'responsibleParty': ClaimResponsiblePartyTypeToJSON(value.responsibleParty),
        'status': ClaimStatusToJSON(value.status),
        'underwriterAttorneyEmail': value.underwriterAttorneyEmail,
        'underwriterAttorneyName': value.underwriterAttorneyName,
        'underwriterAttorneyPhone': value.underwriterAttorneyPhone,
        'updatedAt': (value.updatedAt.toISOString()),
        'userAuthorizedToCloseClaim': value.userAuthorizedToCloseClaim,
    };
}

