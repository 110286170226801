import {
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {Query} from "@title-service/react-query-utils";
import {
  DateComponent,
  FormikConnectedAmplifySubmitButton,
  FormikConnectedAmplifyTextAreaField,
  FormikForm,
  FormResultErrorMessage,
  VerticalFieldset,
} from "@title-service/ui";
import {Formik, FormikHelpers} from "formik";
import React, {useCallback, useContext, useMemo, useState} from "react";
import styled from "styled-components";
import * as Yup from "yup";

import {DependencyContext} from "../../DependencyContext";
import {
  CreateOrderUnderwritingNoteRequest,
  GetOrderUnderwritingNotesSuccessResponse,
  GetOrderUnderwritingNotesSuccessResponseNote,
  UpdateOrderUnderwritingNoteRequest,
} from "../adminApi";
import {Modal} from "../components/Modal";
import {EditIcon, PlusIcon} from "../components/icons";
import {
  BodyRow as SecondaryBodyRow,
  HeaderRow as SecondaryHeaderRow,
  Table as SecondaryTable,
  TBody as SecondaryTBody,
  Td as SecondaryTd,
  Th as SecondaryTh,
  THead as SecondaryTHead,
} from "../components/tables/SecondaryTable";
import {
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../layout";
import {COLOR_N_500, FONT_SIZE_H7} from "../theme";

type GetOrderUnderwritingNotesQueryKey = ["orders", string, "notes"];
export type GetOrderUnderwritingNotesQuery =
  Query<GetOrderUnderwritingNotesSuccessResponse>;

export const useGetOrderUnderwritingNotes = (
  orderId: string,
): {
  getOrderUnderwritingNotesQuery: GetOrderUnderwritingNotesQuery;
  onReloadOrderUnderwritingNotes: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);
  const getOrderUnderwritingNotesQueryKey: GetOrderUnderwritingNotesQueryKey =
    useMemo(() => ["orders", orderId, "notes"], [orderId]);
  const getOrderUnderwritingNotesQueryFn: QueryFunction<
    GetOrderUnderwritingNotesSuccessResponse,
    GetOrderUnderwritingNotesQueryKey
  > = useCallback(
    ({queryKey: [_, orderId_], signal}) =>
      adminApi.getOrderUnderwritingNotes({orderId: orderId_}, signal),
    [adminApi],
  );
  const getOrderUnderwritingNotesQuery = useQuery({
    queryKey: getOrderUnderwritingNotesQueryKey,
    queryFn: getOrderUnderwritingNotesQueryFn,
  });
  const onReloadOrderUnderwritingNotes = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: getOrderUnderwritingNotesQueryKey,
      }),
    [queryClient, getOrderUnderwritingNotesQueryKey],
  );

  return {
    getOrderUnderwritingNotesQuery,
    onReloadOrderUnderwritingNotes,
  };
};

export const UnderwritingNotesSection: React.FC<{
  orderId: string;
  getOrderUnderwritingNotesQuery: GetOrderUnderwritingNotesQuery;
  onReloadOrderUnderwritingNotes: () => any;
  OrderUnderwritingNotesSuccessComponent?: typeof OrderUnderwritingNotesSuccessComponent;
  OrderUnderwritingNotesFailureComponent?: typeof OrderUnderwritingNotesFailureComponent;
  OrderUnderwritingNotesLoadingComponent?: typeof OrderUnderwritingNotesLoadingComponent;
}> = ({
  orderId,
  getOrderUnderwritingNotesQuery: {data, error},
  onReloadOrderUnderwritingNotes,
  OrderUnderwritingNotesSuccessComponent:
    OrderUnderwritingNotesSuccessComponent_ = OrderUnderwritingNotesSuccessComponent,
  OrderUnderwritingNotesFailureComponent:
    OrderUnderwritingNotesFailureComponent_ = OrderUnderwritingNotesFailureComponent,
  OrderUnderwritingNotesLoadingComponent:
    OrderUnderwritingNotesLoadingComponent_ = OrderUnderwritingNotesLoadingComponent,
}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);

  return (
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Notes" />
        {data ? (
          <PlusIcon data-testid="create-note-button" onClick={openForm} />
        ) : null}
      </SecondarySectionHeaderContainer>
      <SecondarySectionContent>
        {data ? (
          <>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <OrderUnderwritingNotesSuccessComponent_
              getOrderUnderwritingNotesResponse={data}
              orderId={orderId}
              onReloadOrderUnderwritingNotes={onReloadOrderUnderwritingNotes}
            />
            <Modal
              contentStyle={modalContentStyle}
              isOpen={displayForm}
              onClose={closeForm}
              title="Add Note"
            >
              <CreateOrderUnderwritingNoteForm
                orderId={orderId}
                onClose={closeForm}
                onSubmitComplete={onReloadOrderUnderwritingNotes}
              />
            </Modal>
          </>
        ) : error ? (
          // eslint-disable-next-line react/jsx-pascal-case
          <OrderUnderwritingNotesFailureComponent_ />
        ) : (
          // eslint-disable-next-line react/jsx-pascal-case
          <OrderUnderwritingNotesLoadingComponent_ />
        )}
      </SecondarySectionContent>
    </SecondarySection>
  );
};

const modalContentStyle: React.CSSProperties = {
  minWidth: "750px",
};

export const OrderUnderwritingNotesLoadingComponent: React.FC = () => (
  <span>Loading notes ...</span>
);

export const OrderUnderwritingNotesFailureComponent: React.FC = () => (
  <span>Failed to load notes.</span>
);

export const orderUnderwritingNoteWasEdited = (
  createdAt: Date,
  updatedAt: Date,
) => createdAt.getTime() !== updatedAt.getTime();

export const MaybeOrderUnderwritingNoteEditedFlag: React.FC<{
  createdAt: Date;
  updatedAt: Date;
}> = ({createdAt, updatedAt}) => (
  <>
    {orderUnderwritingNoteWasEdited(createdAt, updatedAt) && (
      <EditedFlagText>(edited)</EditedFlagText>
    )}
  </>
);

const EditedFlagText = styled.p({
  color: COLOR_N_500,
  fontWeight: "bold",
  ...FONT_SIZE_H7,
});

export const OrderUnderwritingNotesSuccessComponent: React.FC<{
  orderId: string;
  getOrderUnderwritingNotesResponse: GetOrderUnderwritingNotesSuccessResponse;
  onReloadOrderUnderwritingNotes: () => any;
}> = ({
  orderId,
  getOrderUnderwritingNotesResponse: {notes},
  onReloadOrderUnderwritingNotes,
}) => (
  <SecondaryTable>
    <SecondaryTHead>
      <SecondaryHeaderRow>
        {notes.length > 0 && <SecondaryTh width="50px" />}
        {/* edit order underwriting note button column */}
        <SecondaryTh width="110px">Created At</SecondaryTh>
        <SecondaryTh width="150px">Author</SecondaryTh>
        <SecondaryTh>Note</SecondaryTh>
      </SecondaryHeaderRow>
    </SecondaryTHead>
    <SecondaryTBody>
      {notes.length === 0 ? (
        <SecondaryBodyRow>
          <SecondaryTd colSpan={6}>No Notes Recorded</SecondaryTd>
        </SecondaryBodyRow>
      ) : (
        notes.map((note) => (
          <UnderwritingNoteRow
            key={note.id}
            orderId={orderId}
            note={note}
            onReloadOrderUnderwritingNotes={onReloadOrderUnderwritingNotes}
          />
        ))
      )}
    </SecondaryTBody>
  </SecondaryTable>
);

const UnderwritingNoteRow: React.FC<{
  orderId: string;
  note: GetOrderUnderwritingNotesSuccessResponseNote;
  onReloadOrderUnderwritingNotes: () => any;
}> = ({
  orderId,
  note: {id, givenName, familyName, note, createdAt, updatedAt, editable},
  onReloadOrderUnderwritingNotes,
}) => {
  const [modelOpen, setModelOpen] = useState(false);
  const openModal = useCallback(() => {
    setModelOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setModelOpen(false);
  }, []);

  return (
    <SecondaryBodyRow>
      <SecondaryTd>
        {editable ? <EditIcon onClick={openModal} /> : null}
      </SecondaryTd>
      <SecondaryTd>
        <DateComponent value={createdAt} />
      </SecondaryTd>
      <SecondaryTd>{`${givenName} ${familyName}`}</SecondaryTd>
      <SecondaryTd>
        {note}
        <MaybeOrderUnderwritingNoteEditedFlag
          createdAt={createdAt}
          updatedAt={updatedAt}
        />
      </SecondaryTd>
      <Modal
        contentStyle={modalContentStyle}
        isOpen={modelOpen}
        onClose={closeModal}
        title="Edit Note"
      >
        <UpdateOrderUnderwritingNoteForm
          orderId={orderId}
          orderUnderwritingNoteId={id}
          orderUnderwritingNoteTextToEdit={note}
          onClose={closeModal}
          onSubmitComplete={onReloadOrderUnderwritingNotes}
        />
      </Modal>
    </SecondaryBodyRow>
  );
};

export const NOTE_SCHEMA: Yup.StringSchema<string> = Yup.string()
  .required("Note is required")
  .trim();

export const NoteField: React.FC<
  Omit<
    React.ComponentProps<typeof FormikConnectedAmplifyTextAreaField>,
    "label"
  >
> = (props) => (
  <FormikConnectedAmplifyTextAreaField
    testId="note-input"
    label="Note"
    rows={5}
    {...props}
  />
);

export const CREATE_ORDER_UNDERWRITING_NOTE_FORM_SCHEMA: Yup.ObjectSchema<CreateOrderUnderwritingNoteFormData> =
  Yup.object({
    note: NOTE_SCHEMA,
  });

export type CreateOrderUnderwritingNoteFormData = Omit<
  CreateOrderUnderwritingNoteRequest,
  "orderId"
>;

export const buildInitialCreateOrderUnderwritingNoteFormData =
  (): CreateOrderUnderwritingNoteFormData => ({
    note: "",
  });

export const mapOrderUnderwritingNoteFormDataToCreateOrderUnderwritingNoteRequest =
  (
    orderId: string,
    formData: CreateOrderUnderwritingNoteFormData,
  ): CreateOrderUnderwritingNoteRequest => {
    const completeFormData =
      CREATE_ORDER_UNDERWRITING_NOTE_FORM_SCHEMA.validateSync(formData);
    return {
      orderId,
      ...completeFormData,
    };
  };

const CreateOrderUnderwritingNoteForm: React.FC<{
  orderId: string;
  onClose: () => any;
  onSubmitComplete: () => any;
}> = ({orderId, onClose, onSubmitComplete}) => {
  const {adminApi} = useContext(DependencyContext);
  const createOrderUnderwritingNoteMutation = useMutation({
    mutationFn: adminApi.createOrderUnderwritingNote,
  });
  const submitHandler = useCallback(
    (
      formData: CreateOrderUnderwritingNoteFormData,
      {setSubmitting}: FormikHelpers<CreateOrderUnderwritingNoteFormData>,
    ) => {
      const request =
        mapOrderUnderwritingNoteFormDataToCreateOrderUnderwritingNoteRequest(
          orderId,
          formData,
        );
      createOrderUnderwritingNoteMutation.mutate(request, {
        onSuccess: () => {
          onSubmitComplete();
          onClose();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [orderId, createOrderUnderwritingNoteMutation, onSubmitComplete, onClose],
  );
  return (
    <Formik<CreateOrderUnderwritingNoteFormData>
      initialValues={buildInitialCreateOrderUnderwritingNoteFormData()}
      validationSchema={CREATE_ORDER_UNDERWRITING_NOTE_FORM_SCHEMA as any}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <NoteField name="note" />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton />

        {createOrderUnderwritingNoteMutation.isError ? (
          <FormResultErrorMessage data-testid="error-state">
            Request failed.
          </FormResultErrorMessage>
        ) : null}
      </FormikForm>
    </Formik>
  );
};

export type UpdateOrderUnderwritingNoteFormData = Omit<
  UpdateOrderUnderwritingNoteRequest,
  "orderId" | "orderUnderwritingNoteId"
>;
export const EDIT_ORDER_UNDERWRITING_NOTE_FORM_SCHEMA: Yup.ObjectSchema<UpdateOrderUnderwritingNoteFormData> =
  Yup.object({
    note: NOTE_SCHEMA,
  });

export const buildInitialUpdateOrderUnderwritingNoteFormData = (
  orderUnderwritingNoteTextToEdit: string,
): UpdateOrderUnderwritingNoteFormData => ({
  note: orderUnderwritingNoteTextToEdit,
});

export const mapOrderUnderwritingNoteFormDataToUpdateOrderUnderwritingNoteRequest =
  (
    orderId: string,
    orderUnderwritingNoteId: string,
    formData: UpdateOrderUnderwritingNoteFormData,
  ): UpdateOrderUnderwritingNoteRequest => {
    const completeFormData =
      EDIT_ORDER_UNDERWRITING_NOTE_FORM_SCHEMA.validateSync(formData);
    return {
      orderId,
      orderUnderwritingNoteId,
      ...completeFormData,
    };
  };

const UpdateOrderUnderwritingNoteForm: React.FC<{
  orderId: string;
  orderUnderwritingNoteId: string;
  orderUnderwritingNoteTextToEdit: string;
  onClose: () => any;
  onSubmitComplete: () => any;
}> = ({
  orderId,
  orderUnderwritingNoteId,
  orderUnderwritingNoteTextToEdit,
  onClose,
  onSubmitComplete,
}) => {
  const {adminApi} = useContext(DependencyContext);
  const updateOrderUnderwritingNoteMutation = useMutation({
    mutationFn: adminApi.updateOrderUnderwritingNote,
  });
  const submitHandler = useCallback(
    (
      formData: UpdateOrderUnderwritingNoteFormData,
      {setSubmitting}: FormikHelpers<UpdateOrderUnderwritingNoteFormData>,
    ) => {
      const request =
        mapOrderUnderwritingNoteFormDataToUpdateOrderUnderwritingNoteRequest(
          orderId,
          orderUnderwritingNoteId,
          formData,
        );
      updateOrderUnderwritingNoteMutation.mutate(request, {
        onSuccess: () => {
          onSubmitComplete();
          onClose();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [
      orderId,
      orderUnderwritingNoteId,
      updateOrderUnderwritingNoteMutation,
      onSubmitComplete,
      onClose,
    ],
  );
  return (
    <Formik<UpdateOrderUnderwritingNoteFormData>
      initialValues={buildInitialUpdateOrderUnderwritingNoteFormData(
        orderUnderwritingNoteTextToEdit,
      )}
      validationSchema={EDIT_ORDER_UNDERWRITING_NOTE_FORM_SCHEMA as any}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <NoteField name="note" />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton />

        {updateOrderUnderwritingNoteMutation.isError ? (
          <FormResultErrorMessage data-testid="error-state">
            Request failed.
          </FormResultErrorMessage>
        ) : null}
      </FormikForm>
    </Formik>
  );
};
