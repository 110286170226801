import {
  Alert,
  Button,
  Flex,
  Placeholder,
  SelectField,
} from "@aws-amplify/ui-react";
import {QueryFunction, useMutation, useQuery} from "@tanstack/react-query";
import {isLoadingNextQuery, Query} from "@title-service/react-query-utils";
import {DateComponent, Heading, Text, TextRaw} from "@title-service/ui";
import {enumValues, parseWholeNumber} from "@title-service/utils";
import React, {useCallback, useContext, useState} from "react";
import {
  URLSearchParamsInit,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import {DependencyContext} from "../DependencyContext";
import {
  GetAuditsRequest,
  GetAuditsSortField,
  GetAuditsSuccessResponse,
  parseSortDirection,
  SortDirection,
} from "../shared/adminApi";
import {AuditStatusPill} from "../shared/audit/AuditStatusPill";
import {
  GridItem,
  GridItemLabel,
  GridItemLabelContainer,
} from "../shared/components/DataGrid";
import {RouterButtonLink, RouterLink} from "../shared/components/Link";
import {LoadingOverlay} from "../shared/components/LoadingOverlay";
import {Modal} from "../shared/components/Modal";
import {MaybeNotAvailable} from "../shared/components/Nullable";
import {PageNumbers} from "../shared/components/Pagination";
import {
  QuantityComponent,
  QuantityComponentRaw,
} from "../shared/components/Quantity";
import {
  AboveTableContainer,
  BodyRow,
  HeaderRow,
  Table,
  TBody,
  Td,
  Th,
  THead,
} from "../shared/components/tables/PrimaryTable";
import {SortableTh} from "../shared/components/tables/SortableTh";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
} from "../shared/layout";
import {getSortedAgencyFilterOptions} from "../shared/util/sort";

type GetAuditsQueryKey = ["audits", AuditsPageQuery];

export type GetAuditsQuery = Query<GetAuditsSuccessResponse>;

export type GetAuditsRequestDefaults = Pick<
  GetAuditsRequest,
  "page" | "pageSize" | "sortField" | "sortDirection"
>;

export const GET_AUDITS_REQUEST_DEFAULTS: GetAuditsRequestDefaults = {
  sortField: GetAuditsSortField.TargetStartDate,
  sortDirection: SortDirection.Desc,
  page: 0,
  pageSize: 10,
};

export const AuditsRoute: React.FC<{
  GetAuditsBodyContent?: typeof GetAuditsBodyContent;
}> = ({GetAuditsBodyContent: GetAuditsBodyContent_ = GetAuditsBodyContent}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const auditsPageQuery = mapSearchParamsToAuditsPageQuery(searchParams);
  const {adminApi} = useContext(DependencyContext);
  const getAuditsQueryFn: QueryFunction<
    GetAuditsSuccessResponse,
    GetAuditsQueryKey
  > = useCallback(
    ({queryKey: [_1, _auditsPageQuery], signal}) => {
      const _request = mapAuditsPageQueryToGetAuditsRequest(_auditsPageQuery);
      return adminApi.getAudits(_request, signal);
    },
    [adminApi],
  );
  const getAuditsQuery = useQuery<
    GetAuditsSuccessResponse,
    unknown,
    GetAuditsSuccessResponse,
    GetAuditsQueryKey
  >({
    keepPreviousData: true,
    queryKey: ["audits", auditsPageQuery],
    queryFn: getAuditsQueryFn,
  });
  const handleChangeRequestState = useCallback(
    (
      updateAuditsPageQuery: (
        currentAuditsPageQuery: AuditsPageQuery,
      ) => AuditsPageQuery,
    ) => {
      setSearchParams((currentSearchParams) => {
        const currentAuditsPageQuery =
          mapSearchParamsToAuditsPageQuery(currentSearchParams);
        const updatedAuditsPageQuery = updateAuditsPageQuery(
          currentAuditsPageQuery,
        );
        return mapAuditsPageQueryToSearchParams(updatedAuditsPageQuery);
      });
    },
    [setSearchParams],
  );
  return (
    <>
      <BodyHeader>
        <PrimaryBodyHeaderContainer>
          <PrimaryBodyHeader value="Audits" />
          <Flex alignItems="center" gap="medium">
            <Export
              auditsPageQuery={auditsPageQuery}
              getAuditsQuery={getAuditsQuery}
            />
            <RouterButtonLink to="/audits/new">Create Audit</RouterButtonLink>
          </Flex>
        </PrimaryBodyHeaderContainer>
      </BodyHeader>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <GetAuditsBodyContent_
        request={auditsPageQuery}
        getAuditsQuery={getAuditsQuery}
        onChangeRequest={handleChangeRequestState}
      />
    </>
  );
};

const EXPORT_MODAL_CONTENT_STYLE: React.CSSProperties = {
  minWidth: "650px",
};

const pluralizeAudits = (quantity: number) =>
  quantity === 1 ? "Audit" : "Audits";

export const Export: React.FC<{
  auditsPageQuery: AuditsPageQuery;
  getAuditsQuery: GetAuditsQuery;
  ariaHideApp?: boolean;
}> = ({
  ariaHideApp,
  auditsPageQuery,
  getAuditsQuery,
  getAuditsQuery: {isInitialLoading, data},
}) => {
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setExportModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setExportModalOpen(false);
  }, []);

  return (
    <>
      <Button
        testId="export-button"
        variation="link"
        isDisabled={isInitialLoading || isLoadingNextQuery(getAuditsQuery)}
        onClick={openModal}
      >
        Export
      </Button>
      <Modal
        ariaHideApp={ariaHideApp}
        isOpen={exportModalOpen}
        onClose={closeModal}
        title="Export Final Reports"
        contentStyle={EXPORT_MODAL_CONTENT_STYLE}
      >
        {data ? (
          <ExportModalContent
            auditsPageQuery={auditsPageQuery}
            data={data}
            onCancel={closeModal}
          />
        ) : null}
      </Modal>
    </>
  );
};

export const ExportModalContent: React.FC<{
  auditsPageQuery: AuditsPageQuery;
  data: GetAuditsSuccessResponse;
  onCancel: () => any;
}> = ({onCancel, auditsPageQuery, data}) => {
  const {adminApi} = useContext(DependencyContext);
  const exportAuditFinalReportsMutation = useMutation({
    mutationFn: adminApi.exportAuditFinalReports,
  });
  const navigate = useNavigate();
  const handleSubmit = useCallback(() => {
    const {filters} = mapAuditsPageQueryToGetAuditsRequest(auditsPageQuery);
    exportAuditFinalReportsMutation.mutate(
      {filters},
      {
        onSuccess: ({id}) => {
          navigate(`/audits/exports/${id}`, {replace: false});
        },
      },
    );
  }, [auditsPageQuery, exportAuditFinalReportsMutation, navigate]);
  return (
    <Flex testId="modal-content" direction="column" gap="xl">
      <Flex direction="column">
        <Heading level={6} value="Applied Filters:" />
        <Flex direction="row">
          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Agency" />
            </GridItemLabelContainer>
            <Text
              testId="agency-filter-value"
              value={
                auditsPageQuery.agencyId
                  ? Array.from(data.agencyFilterOptions).find(
                      (agency) => agency.id === auditsPageQuery.agencyId,
                    )?.name ?? auditsPageQuery.agencyId
                  : "All"
              }
            />
          </GridItem>

          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="State" />
            </GridItemLabelContainer>
            <Text
              testId="state-filter-value"
              value={auditsPageQuery.state ?? "All"}
            />
          </GridItem>

          <GridItem>
            <GridItemLabelContainer>
              <GridItemLabel value="Year" />
            </GridItemLabelContainer>
            <Text
              testId="year-filter-value"
              value={auditsPageQuery.year ?? "All"}
            />
          </GridItem>
        </Flex>

        <Text
          fontStyle="italic"
          value={
            <>
              <TextRaw value="Create a Zip bundle containing all the uploaded Final Reports for " />
              <QuantityComponentRaw
                quantity={data.pagination.totalRecords}
                label={pluralizeAudits(data.pagination.totalRecords)}
              />
              <TextRaw value="?" />
            </>
          }
        />
      </Flex>

      <Flex
        direction="row"
        justifyContent={
          exportAuditFinalReportsMutation.isError ? "space-between" : "flex-end"
        }
        alignItems="center"
        width="100%"
      >
        {exportAuditFinalReportsMutation.isError ? (
          <Alert variation="error" isDismissible={false} hasIcon={true}>
            Failed to submit Export request.
          </Alert>
        ) : null}
        <Flex direction="row" alignItems="center">
          <Button
            variation="link"
            onClick={onCancel}
            isDisabled={exportAuditFinalReportsMutation.isLoading}
            testId="cancel-button"
          >
            Cancel
          </Button>
          <Button
            testId="submit-button"
            variation="primary"
            loadingText="Starting Export..."
            onClick={handleSubmit}
            isLoading={exportAuditFinalReportsMutation.isLoading}
          >
            Start Export
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const GetAuditsBodyContent: React.FC<{
  request: AuditsPageQuery;
  getAuditsQuery: GetAuditsQuery;
  onChangeRequest: (
    updateRequest: (request: AuditsPageQuery) => AuditsPageQuery,
  ) => any;
  AuditsPageSuccessTableBody?: typeof AuditsPageSuccessTableBody;
  AuditsPagePlaceholderTableBody?: typeof AuditsPagePlaceholderTableBody;
  PageNumbers?: typeof PageNumbers;
}> = ({
  request,
  getAuditsQuery,
  getAuditsQuery: {data, error, isInitialLoading},
  onChangeRequest,
  AuditsPageSuccessTableBody:
    AuditsPageSuccessTableBody_ = AuditsPageSuccessTableBody,
  AuditsPagePlaceholderTableBody:
    AuditsPagePlaceholderTableBody_ = AuditsPagePlaceholderTableBody,
  PageNumbers: PageNumbers_ = PageNumbers,
}) => {
  const isLoading = isLoadingNextQuery(getAuditsQuery);
  const disableFormElements = isLoading || getAuditsQuery.isInitialLoading;
  const setPage = useCallback(
    (zeroIndexedCurrentPageNumber) => {
      onChangeRequest((currentRequest) => ({
        ...currentRequest,
        page: zeroIndexedCurrentPageNumber,
      }));
    },
    [onChangeRequest],
  );
  const setSort = useCallback(
    (sortField: GetAuditsSortField, sortDirection: SortDirection) => {
      onChangeRequest((currentRequest) => ({
        ...currentRequest,
        sortField,
        sortDirection,
        page: 0,
      }));
    },
    [onChangeRequest],
  );
  const handleFilterAgency = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onChangeRequest(({agencyId, ...currentRequestWithoutAgencyId}) => ({
        ...currentRequestWithoutAgencyId,
        agencyId: event.target.value.trim() || undefined,
        page: 0,
      }));
    },
    [onChangeRequest],
  );
  const handleFilterState = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onChangeRequest(({state, ...currentRequestWithoutState}) => ({
        ...currentRequestWithoutState,
        state: event.target.value.trim() || undefined,
        page: 0,
      }));
    },
    [onChangeRequest],
  );
  const handleFilterYear = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onChangeRequest(({year, ...currentRequestWithoutYear}) => ({
        ...currentRequestWithoutYear,
        year: event.target.value ? parseInt(event.target.value, 10) : undefined,
        page: 0,
      }));
    },
    [onChangeRequest],
  );
  const handleClearFilters = useCallback(() => {
    onChangeRequest(({sortField, sortDirection, pageSize}) => ({
      sortField,
      sortDirection,
      pageSize,
      page: 0,
    }));
  }, [onChangeRequest]);
  return (
    <BodyContent>
      <AboveTableContainer justifyContent="space-between">
        {error ? (
          <Alert variation="error" isDismissible={false} hasIcon={true}>
            Failed to load Audits
          </Alert>
        ) : data ? (
          <QuantityComponent
            quantity={data.pagination.totalRecords}
            label={pluralizeAudits(data.pagination.totalRecords)}
          />
        ) : null}
        <Flex direction="row" justifyContent="flex-end" alignItems="center">
          <SelectField
            data-testid="agency-select"
            label="Agency"
            variation="quiet"
            onChange={handleFilterAgency}
            value={request.agencyId ?? ""}
            placeholder="All"
            isDisabled={disableFormElements}
          >
            {data
              ? getSortedAgencyFilterOptions(data.agencyFilterOptions).map(
                  (agency) => (
                    <option key={agency.id} value={agency.id}>
                      {agency.name}
                    </option>
                  ),
                )
              : null}
          </SelectField>
          <SelectField
            data-testid="state-select"
            label="State"
            variation="quiet"
            onChange={handleFilterState}
            value={request.state ?? ""}
            placeholder="All"
            isDisabled={disableFormElements}
          >
            {data
              ? getSortedStateFilterOptions(data.stateFilterOptions).map(
                  (state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ),
                )
              : null}
          </SelectField>
          <SelectField
            data-testid="year-select"
            label="Year"
            variation="quiet"
            onChange={handleFilterYear}
            value={request.year?.toString() ?? ""}
            placeholder="All"
            isDisabled={disableFormElements}
          >
            {data
              ? getSortedYearFilterOptions(data.yearFilterOptions).map(
                  (year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ),
                )
              : null}
          </SelectField>
          <Button
            variation="link"
            data-testid="clear-filters"
            onClick={handleClearFilters}
            isDisabled={disableFormElements}
          >
            Clear Filters
          </Button>
        </Flex>
      </AboveTableContainer>
      <LoadingOverlay isActive={isLoading}>
        <Table>
          <THead>
            <HeaderRow>
              <Th>Audit ID</Th>
              <Th>Agency</Th>
              <Th>State</Th>
              <Th>Status</Th>
              <SortableTh
                data-testid="target-start-date-th"
                field={GetAuditsSortField.TargetStartDate}
                fieldInitialSortDirection={SortDirection.Desc}
                currentSortDirection={request.sortDirection}
                currentSortField={request.sortField}
                onSort={setSort}
              >
                Target Start Date
              </SortableTh>
              <SortableTh
                data-testid="due-date-th"
                field={GetAuditsSortField.DueDate}
                fieldInitialSortDirection={SortDirection.Asc}
                currentSortDirection={request.sortDirection}
                currentSortField={request.sortField}
                onSort={setSort}
              >
                Due Date
              </SortableTh>
            </HeaderRow>
          </THead>
          {data ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <AuditsPageSuccessTableBody_ response={data} />
          ) : error ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <AuditsPagePlaceholderTableBody_ isLoaded={true} />
          ) : (
            isInitialLoading && (
              // eslint-disable-next-line react/jsx-pascal-case
              <AuditsPagePlaceholderTableBody_ isLoaded={false} />
            )
          )}
        </Table>
      </LoadingOverlay>
      {data ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <PageNumbers_
          totalPages={data.pagination.totalPages}
          zeroIndexedCurrentPageNumber={data.pagination.currentPage}
          onChange={setPage}
        />
      ) : null}
    </BodyContent>
  );
};

const getSortedStateFilterOptions = (
  stateFilterOptions: Set<string>,
): string[] => {
  const strings = Array.from(stateFilterOptions);
  strings.sort();
  return strings;
};

const getSortedYearFilterOptions = (
  yearFilterOptions: Set<number>,
): number[] => {
  const years = Array.from(yearFilterOptions);
  // asc
  years.sort((year1, year2) => year1 - year2);
  return years;
};

export type AuditsPageQuery = {
  sortField: GetAuditsSortField;
  sortDirection: SortDirection;
  page: number;
  pageSize: number;
  agencyId?: string;
  state?: string;
  year?: number;
};

export const mapSearchParamsToAuditsPageQuery = (
  searchParams: URLSearchParams,
  queryDefaults: GetAuditsRequestDefaults = GET_AUDITS_REQUEST_DEFAULTS,
): AuditsPageQuery => {
  const agencyId = searchParams.get("agencyId")?.trim();
  const state = searchParams.get("state")?.trim();
  const year = Number(searchParams.get("year")?.trim());
  return {
    sortField:
      mapSearchParamToGetAuditsSortField(searchParams.get("sortField")) ??
      queryDefaults.sortField,
    sortDirection:
      parseSortDirection(searchParams.get("sortDirection")) ??
      queryDefaults.sortDirection,
    page: parseWholeNumber(searchParams.get("page")) ?? queryDefaults.page,
    pageSize:
      parseWholeNumber(searchParams.get("pageSize")) ?? queryDefaults.pageSize,
    agencyId: agencyId ? agencyId : undefined,
    state: state ? state : undefined,
    year: year ? year : undefined,
  };
};

export const mapAuditsPageQueryToGetAuditsRequest = (
  auditsPageQuery: AuditsPageQuery,
  queryDefaults: GetAuditsRequestDefaults = GET_AUDITS_REQUEST_DEFAULTS,
): GetAuditsRequest => ({
  sortField: auditsPageQuery.sortField,
  sortDirection: auditsPageQuery.sortDirection,
  page: auditsPageQuery.page || queryDefaults.page,
  pageSize: auditsPageQuery.pageSize || queryDefaults.pageSize,
  filters: {
    agencyId: auditsPageQuery.agencyId ? auditsPageQuery.agencyId : undefined,
    state: auditsPageQuery.state ? auditsPageQuery.state : undefined,
    upperBoundDate: auditsPageQuery.year
      ? mapUpperBoundFilterDate(auditsPageQuery.year)
      : undefined,
    lowerBoundDate: auditsPageQuery.year
      ? mapLowerBoundFilterDate(auditsPageQuery.year)
      : undefined,
  },
});

export const mapUpperBoundFilterDate = (year: number): Date =>
  new Date(Date.UTC(year, 11, 31, 0, 0, 0));

export const mapLowerBoundFilterDate = (year: number): Date =>
  new Date(Date.UTC(year, 0, 1, 0, 0, 0));

const GET_AUDITS_SORT_FIELD_VALUES = enumValues(GetAuditsSortField);

export const mapSearchParamToGetAuditsSortField = (
  getAuditsSortField: string | null,
): GetAuditsSortField | undefined => {
  const trimmed = getAuditsSortField?.trim();
  if (trimmed) {
    return GET_AUDITS_SORT_FIELD_VALUES.find((value) => value === trimmed);
  }
  return undefined;
};

export const mapAuditsPageQueryToSearchParams = ({
  page,
  pageSize,
  sortField,
  sortDirection,
  agencyId,
  state,
  year,
  ...query
}: AuditsPageQuery): URLSearchParamsInit => {
  const searchParams: URLSearchParamsInit = {
    ...query,
    page: page.toString(),
    pageSize: pageSize.toString(),
    sortField,
    sortDirection,
  };
  if (agencyId) {
    searchParams.agencyId = agencyId;
  }
  if (state) {
    searchParams.state = state;
  }
  if (year) {
    searchParams.year = year.toString();
  }
  return searchParams;
};

export const AuditsPagePlaceholderTableBody: React.FC<{
  isLoaded: boolean;
}> = ({isLoaded}) => (
  <TBody>
    <BodyRow>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
    </BodyRow>
  </TBody>
);

export const AuditsPageSuccessTableBody: React.FC<{
  response: GetAuditsSuccessResponse;
}> = ({response: {audits}}) => {
  if (!audits.length) {
    return (
      <TBody>
        <BodyRow>
          <Td colSpan={6}>No Audits found</Td>
        </BodyRow>
      </TBody>
    );
  }

  return (
    <TBody>
      {audits.map(({id, agencyName, state, status, targetStartOn, dueOn}) => (
        <BodyRow key={id}>
          <Td>
            <RouterLink to={`/audits/${id}/`}>{id}</RouterLink>
          </Td>
          <Td>{agencyName}</Td>
          <Td>{state}</Td>
          <Td>
            <AuditStatusPill status={status} />
          </Td>
          <Td>
            <DateComponent value={targetStartOn} />
          </Td>
          <Td>
            <MaybeNotAvailable value={dueOn}>
              {(dueOn_) => <DateComponent value={dueOn_} />}
            </MaybeNotAvailable>
          </Td>
        </BodyRow>
      ))}
    </TBody>
  );
};
