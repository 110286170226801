/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CoverageType } from './CoverageType';
import {
    CoverageTypeFromJSON,
    CoverageTypeFromJSONTyped,
    CoverageTypeToJSON,
} from './CoverageType';
import type { Endorsement } from './Endorsement';
import {
    EndorsementFromJSON,
    EndorsementFromJSONTyped,
    EndorsementToJSON,
} from './Endorsement';
import type { Fee } from './Fee';
import {
    FeeFromJSON,
    FeeFromJSONTyped,
    FeeToJSON,
} from './Fee';
import type { Parcel } from './Parcel';
import {
    ParcelFromJSON,
    ParcelFromJSONTyped,
    ParcelToJSON,
} from './Parcel';
import type { PolicyStatus } from './PolicyStatus';
import {
    PolicyStatusFromJSON,
    PolicyStatusFromJSONTyped,
    PolicyStatusToJSON,
} from './PolicyStatus';
import type { PolicyType } from './PolicyType';
import {
    PolicyTypeFromJSON,
    PolicyTypeFromJSONTyped,
    PolicyTypeToJSON,
} from './PolicyType';

/**
 * 
 * @export
 * @interface Policy
 */
export interface Policy {
    /**
     * 
     * @type {CoverageType}
     * @memberof Policy
     */
    coverage: CoverageType;
    /**
     * 
     * @type {Date}
     * @memberof Policy
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Policy
     */
    effectiveDate?: Date;
    /**
     * 
     * @type {Array<Endorsement>}
     * @memberof Policy
     */
    endorsements: Array<Endorsement>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Policy
     */
    exceptions: Array<string>;
    /**
     * 
     * @type {Array<Fee>}
     * @memberof Policy
     */
    fees: Array<Fee>;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    insured: string;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    insuredMortgagee?: string;
    /**
     * 
     * @type {Array<Parcel>}
     * @memberof Policy
     */
    parcels: Array<Parcel>;
    /**
     * 
     * @type {number}
     * @memberof Policy
     */
    premium: number;
    /**
     * 
     * @type {number}
     * @memberof Policy
     */
    sequenceNumber: number;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    state: string;
    /**
     * 
     * @type {PolicyStatus}
     * @memberof Policy
     */
    status: PolicyStatus;
    /**
     * 
     * @type {PolicyType}
     * @memberof Policy
     */
    type: PolicyType;
    /**
     * 
     * @type {Date}
     * @memberof Policy
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the Policy interface.
 */
export function instanceOfPolicy(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "coverage" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "endorsements" in value;
    isInstance = isInstance && "exceptions" in value;
    isInstance = isInstance && "fees" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "insured" in value;
    isInstance = isInstance && "parcels" in value;
    isInstance = isInstance && "premium" in value;
    isInstance = isInstance && "sequenceNumber" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function PolicyFromJSON(json: any): Policy {
    return PolicyFromJSONTyped(json, false);
}

export function PolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Policy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coverage': CoverageTypeFromJSON(json['coverage']),
        'createdAt': (new Date(json['createdAt'])),
        'effectiveDate': !exists(json, 'effectiveDate') ? undefined : (new Date(json['effectiveDate'])),
        'endorsements': ((json['endorsements'] as Array<any>).map(EndorsementFromJSON)),
        'exceptions': json['exceptions'],
        'fees': ((json['fees'] as Array<any>).map(FeeFromJSON)),
        'id': json['id'],
        'insured': json['insured'],
        'insuredMortgagee': !exists(json, 'insuredMortgagee') ? undefined : json['insuredMortgagee'],
        'parcels': ((json['parcels'] as Array<any>).map(ParcelFromJSON)),
        'premium': json['premium'],
        'sequenceNumber': json['sequenceNumber'],
        'state': json['state'],
        'status': PolicyStatusFromJSON(json['status']),
        'type': PolicyTypeFromJSON(json['type']),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function PolicyToJSON(value?: Policy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'coverage': CoverageTypeToJSON(value.coverage),
        'createdAt': (value.createdAt.toISOString()),
        'effectiveDate': value.effectiveDate === undefined ? undefined : (value.effectiveDate.toISOString().substr(0,10)),
        'endorsements': ((value.endorsements as Array<any>).map(EndorsementToJSON)),
        'exceptions': value.exceptions,
        'fees': ((value.fees as Array<any>).map(FeeToJSON)),
        'id': value.id,
        'insured': value.insured,
        'insuredMortgagee': value.insuredMortgagee,
        'parcels': ((value.parcels as Array<any>).map(ParcelToJSON)),
        'premium': value.premium,
        'sequenceNumber': value.sequenceNumber,
        'state': value.state,
        'status': PolicyStatusToJSON(value.status),
        'type': PolicyTypeToJSON(value.type),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

