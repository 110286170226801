/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
     ValidationReportDetailInitialFromJSONTyped,
     ValidationReportDetailUpdateFromJSONTyped
} from './';

/**
 * 
 * @export
 * @interface ValidationReportDetail
 */
export interface ValidationReportDetail {
    /**
     * 
     * @type {string}
     * @memberof ValidationReportDetail
     */
    type: string;
}

/**
 * Check if a given object implements the ValidationReportDetail interface.
 */
export function instanceOfValidationReportDetail(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function ValidationReportDetailFromJSON(json: any): ValidationReportDetail {
    return ValidationReportDetailFromJSONTyped(json, false);
}

export function ValidationReportDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationReportDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['type'] === 'initial') {
            return ValidationReportDetailInitialFromJSONTyped(json, true);
        }
        if (json['type'] === 'update') {
            return ValidationReportDetailUpdateFromJSONTyped(json, true);
        }
    }
    return {
        
        'type': json['type'],
    };
}

export function ValidationReportDetailToJSON(value?: ValidationReportDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
    };
}

