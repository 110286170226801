/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProducerActivation
 */
export interface ProducerActivation {
    /**
     * 
     * @type {boolean}
     * @memberof ProducerActivation
     */
    active: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ProducerActivation
     */
    appointmentTerminationNoticeDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProducerActivation
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProducerActivation
     */
    deactivationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProducerActivation
     */
    deactivationReason?: string;
    /**
     * 
     * @type {string}
     * @memberof ProducerActivation
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof ProducerActivation
     */
    reactivationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProducerActivation
     */
    reactivationReason?: string;
}

/**
 * Check if a given object implements the ProducerActivation interface.
 */
export function instanceOfProducerActivation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ProducerActivationFromJSON(json: any): ProducerActivation {
    return ProducerActivationFromJSONTyped(json, false);
}

export function ProducerActivationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProducerActivation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': json['active'],
        'appointmentTerminationNoticeDate': !exists(json, 'appointmentTerminationNoticeDate') ? undefined : (new Date(json['appointmentTerminationNoticeDate'])),
        'createdAt': (new Date(json['createdAt'])),
        'deactivationDate': !exists(json, 'deactivationDate') ? undefined : (new Date(json['deactivationDate'])),
        'deactivationReason': !exists(json, 'deactivationReason') ? undefined : json['deactivationReason'],
        'id': json['id'],
        'reactivationDate': !exists(json, 'reactivationDate') ? undefined : (new Date(json['reactivationDate'])),
        'reactivationReason': !exists(json, 'reactivationReason') ? undefined : json['reactivationReason'],
    };
}

export function ProducerActivationToJSON(value?: ProducerActivation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'appointmentTerminationNoticeDate': value.appointmentTerminationNoticeDate === undefined ? undefined : (value.appointmentTerminationNoticeDate.toISOString().substr(0,10)),
        'createdAt': (value.createdAt.toISOString()),
        'deactivationDate': value.deactivationDate === undefined ? undefined : (value.deactivationDate.toISOString().substr(0,10)),
        'deactivationReason': value.deactivationReason,
        'id': value.id,
        'reactivationDate': value.reactivationDate === undefined ? undefined : (value.reactivationDate.toISOString().substr(0,10)),
        'reactivationReason': value.reactivationReason,
    };
}

