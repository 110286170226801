import {Alert, Button, Flex} from "@aws-amplify/ui-react";
import {useMutation} from "@tanstack/react-query";
import {
  DateTimeComponent,
  FormikConnectedAmplifyCurrencyField,
  FormikConnectedAmplifySelectField,
  FormikConnectedAmplifySubmitButton,
  FormikConnectedAmplifyTextField,
  FormikForm,
  PrimarySection,
  Text,
  VerticalFieldset,
} from "@title-service/ui";
import {enumValues} from "@title-service/utils";
import {buildCurrencySchema, buildEnumSchema} from "@title-service/yup-utils";
import {Formik, FormikHelpers} from "formik";
import React, {useCallback, useContext, useMemo, useState} from "react";
import * as Yup from "yup";

import {DependencyContext} from "../../DependencyContext";
import {
  Cpl,
  CreateOrderCplRequest,
  OrderPermissions,
  TransactionPartyType,
} from "../adminApi";
import {CurrencyComponent} from "../components/Currency";
import {Modal} from "../components/Modal";
import {
  AboveTableContainer,
  BodyRow,
  BodyRow as PrimaryBodyRow,
  HeaderRow as PrimaryHeaderRow,
  Table as PrimaryTable,
  TBody as PrimaryTBody,
  Td as PrimaryTd,
  Th as PrimaryTh,
  THead as PrimaryTHead,
} from "../components/tables/PrimaryTable";
import {SecondarySection, SecondarySectionContent} from "../layout";

import {
  displayTransactionPartyType,
  TransactionPartyTypeComponentRaw,
} from "./TransactionPartyType";

const MODAL_CONTENT_STYLE: React.CSSProperties = {
  minWidth: "650px",
};

export const CplsTab: React.FC<{
  orderId: string;
  cpls: Cpl[];
  orderPermissions: OrderPermissions;
  onReloadOrder: () => any;
  ariaHideApp?: boolean;
  CplForm?: typeof CplForm;
}> = ({
  ariaHideApp,
  orderId,
  cpls,
  orderPermissions,
  onReloadOrder,
  CplForm: CplForm_ = CplForm,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);
  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);
  return (
    <PrimarySection>
      <SecondarySection>
        <SecondarySectionContent>
          <Modal
            ariaHideApp={ariaHideApp}
            isOpen={modalOpen}
            onClose={closeModal}
            contentStyle={MODAL_CONTENT_STYLE}
            title="Create CPL"
          >
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <CplForm_ orderId={orderId} onSubmitComplete={onReloadOrder} />
          </Modal>
          {orderPermissions.createCpl ? (
            <AboveTableContainer justifyContent="flex-end">
              <Button
                testId="create-cpl-button"
                variation="link"
                onClick={openModal}
              >
                Create CPL
              </Button>
            </AboveTableContainer>
          ) : null}
          <PrimaryTable>
            <PrimaryTHead>
              <PrimaryHeaderRow>
                <PrimaryTh>CPL ID</PrimaryTh>
                <PrimaryTh>Type</PrimaryTh>
                <PrimaryTh>State</PrimaryTh>
                <PrimaryTh>Created</PrimaryTh>
                <PrimaryTh>Cost</PrimaryTh>
              </PrimaryHeaderRow>
            </PrimaryTHead>
            <PrimaryTBody>
              {cpls.length > 0 ? (
                cpls.map((cpl) => (
                  <PrimaryBodyRow key={cpl.id}>
                    <PrimaryTd>
                      <Text value={cpl.id} />
                    </PrimaryTd>
                    <PrimaryTd>
                      <Text
                        value={
                          <TransactionPartyTypeComponentRaw
                            transactionPartyType={cpl.type}
                          />
                        }
                      />
                    </PrimaryTd>
                    <PrimaryTd>
                      <Text value={cpl.state} />
                    </PrimaryTd>
                    <PrimaryTd>
                      <DateTimeComponent value={cpl.createdAt} />
                    </PrimaryTd>
                    <PrimaryTd>
                      <CurrencyComponent value={cpl.cost} />
                    </PrimaryTd>
                  </PrimaryBodyRow>
                ))
              ) : (
                <BodyRow>
                  <PrimaryTd colSpan={5}>No CPLs found</PrimaryTd>
                </BodyRow>
              )}
            </PrimaryTBody>
          </PrimaryTable>
        </SecondarySectionContent>
      </SecondarySection>
    </PrimarySection>
  );
};

export type CplFormData = Omit<
  CreateOrderCplRequest,
  "orderId" | "cost" | "type"
> & {
  type: string;
  cost: string;
};

export type CompleteCplFormData = Omit<CreateOrderCplRequest, "orderId">;

const STATE_ABBREVIATION_LENGTH_ERROR_MESSAGE = "State must be two letters";

export const CPL_FORM_SCHEMA: Yup.ObjectSchema<CompleteCplFormData> =
  Yup.object({
    type: buildEnumSchema(
      TransactionPartyType,
      "Must be a valid CPL type",
    ).required("CPL type is required"),
    cost: buildCurrencySchema()
      .min(0, "CPL cost must be greater than 0")
      .required("CPL cost is required"),
    state: Yup.string()
      .required("State is required")
      .trim()
      .uppercase()
      .matches(/^[a-zA-Z]+$/u, {
        message: STATE_ABBREVIATION_LENGTH_ERROR_MESSAGE,
        excludeEmptyString: true,
      })
      .length(2, STATE_ABBREVIATION_LENGTH_ERROR_MESSAGE),
  }) as Yup.ObjectSchema<CompleteCplFormData>;

export const CplForm: React.FC<{
  orderId: string;
  onSubmitComplete: () => any;
}> = ({orderId, onSubmitComplete}) => {
  const {adminApi} = useContext(DependencyContext);
  const createOrderCplMutation = useMutation({
    mutationFn: adminApi.createOrderCpl,
  });
  const handleSubmit = useCallback(
    (formData: CplFormData, {setSubmitting}: FormikHelpers<CplFormData>) => {
      const competeCplFormData = CPL_FORM_SCHEMA.validateSync(formData, {
        stripUnknown: true,
      });
      const createOrderCplRequest: CreateOrderCplRequest = {
        ...competeCplFormData,
        orderId,
      };
      createOrderCplMutation.mutate(createOrderCplRequest, {
        onSuccess: (data, variables, context) => {
          onSubmitComplete();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [createOrderCplMutation, onSubmitComplete, orderId],
  );
  const initialData: CplFormData = useMemo(
    () => ({
      type: "",
      cost: "",
      state: "",
    }),
    [],
  );
  return (
    <Formik<CplFormData>
      initialValues={initialData}
      validationSchema={CPL_FORM_SCHEMA as any}
      onSubmit={handleSubmit}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <FormikConnectedAmplifySelectField
            testId="type-input"
            label="CPL Type"
            name="type"
            placeholder="Select a CPL Type"
          >
            {enumValues(TransactionPartyType).map((transactionPartyType) => (
              <option key={transactionPartyType} value={transactionPartyType}>
                {displayTransactionPartyType(transactionPartyType)}
              </option>
            ))}
          </FormikConnectedAmplifySelectField>

          <FormikConnectedAmplifyTextField
            testId="state-input"
            label="State"
            name="state"
          />

          <FormikConnectedAmplifyCurrencyField
            testId="cost-input"
            label="Cost"
            name="cost"
          />
        </VerticalFieldset>

        <Flex
          direction="row"
          justifyContent={
            createOrderCplMutation.isError || createOrderCplMutation.isSuccess
              ? "space-between"
              : "flex-end"
          }
          alignItems="center"
          width="100%"
        >
          {createOrderCplMutation.isSuccess ? (
            <Alert testId="result-success" variation="success">
              Successfully created CPL {createOrderCplMutation.data.id}
            </Alert>
          ) : null}

          {createOrderCplMutation.isError ? (
            <Alert testId="result-error" variation="error">
              Failed to create Remittance record
            </Alert>
          ) : null}

          <FormikConnectedAmplifySubmitButton />
        </Flex>
      </FormikForm>
    </Formik>
  );
};
