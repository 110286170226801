import {Icon as AmplifyIcon} from "@aws-amplify/ui-react";
import React from "react";

export const Icon: React.FC<React.ComponentProps<typeof AmplifyIcon>> = (
  props,
) => (
  <AmplifyIcon
    color="font.primary"
    {...props}
    style={props.onClick ? {cursor: "pointer", ...props.style} : props.style}
  />
);
