import React from "react";

import {ClaimStatus} from "../adminApi";
import {SuccessPill, WarningPill} from "../components/Pills";

export const ClaimStatusPill: React.FC<{
  status: ClaimStatus;
}> = ({status}) => {
  if (status === ClaimStatus.Closed) {
    return <ClaimStatusPillClosed />;
  }
  return <ClaimStatusPillInProgress />;
};

const ClaimStatusPillInProgress: React.FC = () => (
  <WarningPill label="In Progress" />
);

const ClaimStatusPillClosed: React.FC = () => <SuccessPill label="Closed" />;
