/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderSourcePropertyDocumentReference
 */
export interface OrderSourcePropertyDocumentReference {
    /**
     * 
     * @type {string}
     * @memberof OrderSourcePropertyDocumentReference
     */
    sourceOrderPropertyDocumentId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderSourcePropertyDocumentReference
     */
    sourcePropertyDocumentId?: string;
}

/**
 * Check if a given object implements the OrderSourcePropertyDocumentReference interface.
 */
export function instanceOfOrderSourcePropertyDocumentReference(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sourceOrderPropertyDocumentId" in value;

    return isInstance;
}

export function OrderSourcePropertyDocumentReferenceFromJSON(json: any): OrderSourcePropertyDocumentReference {
    return OrderSourcePropertyDocumentReferenceFromJSONTyped(json, false);
}

export function OrderSourcePropertyDocumentReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderSourcePropertyDocumentReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourceOrderPropertyDocumentId': json['sourceOrderPropertyDocumentId'],
        'sourcePropertyDocumentId': !exists(json, 'sourcePropertyDocumentId') ? undefined : json['sourcePropertyDocumentId'],
    };
}

export function OrderSourcePropertyDocumentReferenceToJSON(value?: OrderSourcePropertyDocumentReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourceOrderPropertyDocumentId': value.sourceOrderPropertyDocumentId,
        'sourcePropertyDocumentId': value.sourcePropertyDocumentId,
    };
}

