import {
  FormikConnectedAmplifySelectField,
  Text,
  TextRaw,
} from "@title-service/ui";
import {enumKeys} from "@title-service/utils";
import React from "react";

import {TransactionType} from "../adminApi";
import {safeDisplayEnumFn} from "../util/enum";

export const unsafeDisplayTransactionType = (
  transactionType: TransactionType,
): string => {
  switch (transactionType) {
    case TransactionType.Refinance:
      return "Refinance";
    case TransactionType.Purchase:
      return "Purchase";
    default:
      throw new Error(`Unknown Transaction Type: ${transactionType}`);
  }
};

export const displayTransactionType = safeDisplayEnumFn(
  unsafeDisplayTransactionType,
);

export const TransactionTypeComponentRaw: React.FC<{
  transactionType: TransactionType;
}> = ({transactionType}) => (
  <TextRaw value={displayTransactionType(transactionType)} />
);

export const TransactionTypeComponent: React.FC<
  Omit<React.ComponentProps<typeof Text>, "value"> & {
    transactionType: TransactionType;
  }
> = ({transactionType, ...props}) => (
  <Text {...props} value={displayTransactionType(transactionType)} />
);

export const TransactionTypeField: React.FC<
  React.ComponentProps<typeof FormikConnectedAmplifySelectField>
> = (props) => (
  <FormikConnectedAmplifySelectField {...props}>
    {props.placeholder === "" && <option value="" />}
    {enumKeys(TransactionType).map((policyTransactionTypeKey) => {
      const policyTransactionType: TransactionType =
        TransactionType[policyTransactionTypeKey];
      return (
        <option key={policyTransactionType} value={policyTransactionType}>
          {displayTransactionType(policyTransactionType)}
        </option>
      );
    })}
  </FormikConnectedAmplifySelectField>
);
