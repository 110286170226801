import React from "react";

import {ErrorPill, SuccessPill} from "../components/Pills";

export const OrderPropertyDocumentExtractionStatusPill: React.FC<{
  status: "success" | "not_available";
}> = ({status}) => {
  switch (status) {
    case "success":
      return <OrderPropertyDocumentExtractionStatusPillSuccess />;
    default:
      return <OrderPropertyDocumentExtractionStatusPillNotAvailable />;
  }
};

const OrderPropertyDocumentExtractionStatusPillSuccess: React.FC = () => (
  <SuccessPill label="Success" />
);

const OrderPropertyDocumentExtractionStatusPillNotAvailable: React.FC = () => (
  <ErrorPill label="Not Available" />
);
