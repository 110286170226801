import {Alert, Placeholder} from "@aws-amplify/ui-react";
import {QueryFunction, useQuery} from "@tanstack/react-query";
import {isLoadingNextQuery, Query} from "@title-service/react-query-utils";
import {DateTimeComponent} from "@title-service/ui";
import {enumValues, parseWholeNumber} from "@title-service/utils";
import React, {useCallback, useContext} from "react";
import {URLSearchParamsInit, useSearchParams} from "react-router-dom";

import {DependencyContext} from "../DependencyContext";
import {
  GetPoliciesRequest,
  GetPoliciesSortField,
  GetPoliciesSuccessResponse,
  parseSortDirection,
  SortDirection,
} from "../shared/adminApi";
import {RouterLink} from "../shared/components/Link";
import {LoadingOverlay} from "../shared/components/LoadingOverlay";
import {PageNumbers} from "../shared/components/Pagination";
import {QuantityComponent} from "../shared/components/Quantity";
import {
  AboveTableContainer,
  BodyRow,
  HeaderRow,
  Table,
  TBody,
  Td,
  Th,
  THead,
} from "../shared/components/tables/PrimaryTable";
import {SortableTh} from "../shared/components/tables/SortableTh";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
} from "../shared/layout";
import {PolicyStatusPill} from "../shared/policy/PolicyStatusPill";
import {PolicyTypeComponent} from "../shared/policy/PolicyType";

type GetPoliciesQueryKey = ["policies", "search", GetPoliciesRequest];
export type GetPoliciesQuery = Query<GetPoliciesSuccessResponse>;

export const PoliciesRoute: React.FC<{
  GetPoliciesBodyContent?: typeof GetPoliciesBodyContent;
}> = ({
  GetPoliciesBodyContent: GetPoliciesBodyContent_ = GetPoliciesBodyContent,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const request = mapSearchParamsToGetPoliciesRequest(searchParams);
  const {adminApi} = useContext(DependencyContext);
  const getPoliciesQueryFn: QueryFunction<
    GetPoliciesSuccessResponse,
    GetPoliciesQueryKey
  > = useCallback(
    ({queryKey: [_1, _2, _request], signal}) =>
      adminApi.getPolicies(_request, signal),
    [adminApi],
  );
  const getPoliciesQuery = useQuery({
    queryKey: ["policies", "search", request],
    keepPreviousData: true,
    queryFn: getPoliciesQueryFn,
  });
  const handleChangeRequestState = useCallback(
    (
      updateRequest: (currentRequest: GetPoliciesRequest) => GetPoliciesRequest,
    ) => {
      setSearchParams((currentSearchParams) => {
        const currentRequest =
          mapSearchParamsToGetPoliciesRequest(currentSearchParams);
        const updatedRequest = updateRequest(currentRequest);
        return mapGetPoliciesRequestToSearchParams(updatedRequest);
      });
    },
    [setSearchParams],
  );

  return (
    <>
      <BodyHeader>
        <PrimaryBodyHeaderContainer>
          <PrimaryBodyHeader value="Policies" />
        </PrimaryBodyHeaderContainer>
      </BodyHeader>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <GetPoliciesBodyContent_
        request={request}
        getPoliciesQuery={getPoliciesQuery}
        onChangeRequest={handleChangeRequestState}
      />
    </>
  );
};

export const GetPoliciesBodyContent: React.FC<{
  request: GetPoliciesRequest;
  getPoliciesQuery: GetPoliciesQuery;
  onChangeRequest: (
    updateRequest: (request: GetPoliciesRequest) => GetPoliciesRequest,
  ) => any;
  PoliciesPageSuccessTableBody?: typeof PoliciesPageSuccessTableBody;
  PoliciesPagePlaceholderTableBody?: typeof PoliciesPagePlaceholderTableBody;
  PageNumbers?: typeof PageNumbers;
}> = ({
  request,
  getPoliciesQuery,
  getPoliciesQuery: {data, error, isInitialLoading},
  onChangeRequest,
  PoliciesPageSuccessTableBody:
    PoliciesPageSuccessTableBody_ = PoliciesPageSuccessTableBody,
  PoliciesPagePlaceholderTableBody:
    PoliciesPagePlaceholderTableBody_ = PoliciesPagePlaceholderTableBody,
  PageNumbers: PageNumbers_ = PageNumbers,
}) => {
  const isLoading = isLoadingNextQuery(getPoliciesQuery);
  const setSort = useCallback(
    (sortField: GetPoliciesSortField, sortDirection: SortDirection) => {
      onChangeRequest((currentRequest) => ({
        ...currentRequest,
        sortField,
        sortDirection,
        page: 0,
      }));
    },
    [onChangeRequest],
  );
  const setPage = useCallback(
    (zeroIndexedCurrentPageNumber) => {
      onChangeRequest((currentRequest) => ({
        ...currentRequest,
        page: zeroIndexedCurrentPageNumber,
      }));
    },
    [onChangeRequest],
  );

  return (
    <BodyContent>
      <AboveTableContainer justifyContent="flex-start">
        {error ? (
          <Alert variation="error" isDismissible={false} hasIcon={true}>
            Failed to load Policies
          </Alert>
        ) : data ? (
          <QuantityComponent
            quantity={data.pagination.totalRecords}
            label={data.pagination.totalRecords === 1 ? "CPL" : "CPLs"}
          />
        ) : null}
      </AboveTableContainer>
      <LoadingOverlay isActive={isLoading}>
        <Table>
          <THead>
            <HeaderRow>
              <Th>Policy ID</Th>
              <SortableTh
                testId="sequence-number-th"
                field={GetPoliciesSortField.SequenceNumber}
                fieldInitialSortDirection={SortDirection.Desc}
                currentSortDirection={request.sortDirection}
                currentSortField={request.sortField}
                onSort={setSort}
              >
                Sequence No.
              </SortableTh>
              <Th>Order Reference ID</Th>
              <SortableTh
                testId="state-th"
                field={GetPoliciesSortField.State}
                fieldInitialSortDirection={SortDirection.Asc}
                currentSortDirection={request.sortDirection}
                currentSortField={request.sortField}
                onSort={setSort}
              >
                State
              </SortableTh>
              <Th>Policy type</Th>
              <Th>Policy status</Th>
              <SortableTh
                testId="created-th"
                field={GetPoliciesSortField.Created}
                fieldInitialSortDirection={SortDirection.Desc}
                currentSortDirection={request.sortDirection}
                currentSortField={request.sortField}
                onSort={setSort}
              >
                Created
              </SortableTh>
              <SortableTh
                testId="updated-th"
                field={GetPoliciesSortField.Updated}
                fieldInitialSortDirection={SortDirection.Desc}
                currentSortDirection={request.sortDirection}
                currentSortField={request.sortField}
                onSort={setSort}
              >
                Updated
              </SortableTh>
            </HeaderRow>
          </THead>
          {data ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <PoliciesPageSuccessTableBody_ response={data} />
          ) : error ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <PoliciesPagePlaceholderTableBody_ isLoaded={true} />
          ) : (
            isInitialLoading && (
              // eslint-disable-next-line react/jsx-pascal-case
              <PoliciesPagePlaceholderTableBody_ isLoaded={false} />
            )
          )}
        </Table>
      </LoadingOverlay>
      {data ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <PageNumbers_
          totalPages={data.pagination.totalPages}
          zeroIndexedCurrentPageNumber={data.pagination.currentPage}
          onChange={setPage}
        />
      ) : null}
    </BodyContent>
  );
};

export const PoliciesPagePlaceholderTableBody: React.FC<{
  isLoaded: boolean;
}> = ({isLoaded}) => (
  <TBody>
    <BodyRow>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
    </BodyRow>
  </TBody>
);

export const PoliciesPageSuccessTableBody: React.FC<{
  response: GetPoliciesSuccessResponse;
}> = ({response: {policies}}) => {
  if (!policies.length) {
    return (
      <TBody>
        <BodyRow>
          <Td colSpan={8}>No Policies found</Td>
        </BodyRow>
      </TBody>
    );
  }

  return (
    <TBody>
      {policies.map(
        ({
          order: {id: orderId, referenceId},
          policy: {
            id: policyId,
            sequenceNumber,
            type,
            state,
            status: policyStatus,
            createdAt: policyCreatedAt,
            updatedAt: policyUpdatedAt,
          },
        }) => (
          <BodyRow key={policyId}>
            <Td>{policyId}</Td>
            <Td>{sequenceNumber}</Td>
            <Td>
              <RouterLink to={`/orders/${orderId}/`}>{referenceId}</RouterLink>
            </Td>
            <Td>{state}</Td>
            <Td>
              <PolicyTypeComponent policyType={type} />
            </Td>
            <Td>
              <PolicyStatusPill status={policyStatus} />
            </Td>
            <Td>
              <DateTimeComponent value={policyCreatedAt} />
            </Td>
            <Td>
              <DateTimeComponent value={policyUpdatedAt} />
            </Td>
          </BodyRow>
        ),
      )}
    </TBody>
  );
};

export type GetPoliciesRequestDefaults = Pick<
  GetPoliciesRequest,
  "page" | "pageSize" | "sortField" | "sortDirection"
>;

export const GET_POLICIES_REQUEST_QUERY_DEFAULTS: GetPoliciesRequestDefaults = {
  sortField: GetPoliciesSortField.Created,
  sortDirection: SortDirection.Desc,
  page: 0,
  pageSize: 10,
};

const GET_POLICIES_SORT_FIELD_VALUES = enumValues(GetPoliciesSortField);

export const mapSearchParamToGetPoliciesSortField = (
  getPoliciesSortField: string | null,
): GetPoliciesSortField | undefined => {
  const trimmed = getPoliciesSortField?.trim();
  if (trimmed) {
    return GET_POLICIES_SORT_FIELD_VALUES.find((value) => value === trimmed);
  }
  return undefined;
};

export const mapSearchParamsToGetPoliciesRequest = (
  searchParams: URLSearchParams,
  queryDefaults: GetPoliciesRequestDefaults = GET_POLICIES_REQUEST_QUERY_DEFAULTS,
): GetPoliciesRequest => ({
  sortField:
    mapSearchParamToGetPoliciesSortField(searchParams.get("sortField")) ??
    queryDefaults.sortField,
  sortDirection:
    parseSortDirection(searchParams.get("sortDirection")) ??
    queryDefaults.sortDirection,
  page: parseWholeNumber(searchParams.get("page")) ?? queryDefaults.page,
  pageSize:
    parseWholeNumber(searchParams.get("pageSize")) ?? queryDefaults.pageSize,
});

export const mapGetPoliciesRequestToSearchParams = ({
  page,
  pageSize,
  ...request
}: GetPoliciesRequest): URLSearchParamsInit => ({
  ...request,
  page: page.toString(10),
  pageSize: pageSize.toString(10),
});
