import {stringIsNotBlank} from "@title-service/utils";
import React from "react";
import styled from "styled-components";

import {Address} from "../adminApi";
import {FONT_SIZE_H5} from "../theme";

import {ColumnWrapper} from "./ColumnWrapper";

export const addressLine1 = ({street, unit}: Address) =>
  [street, unit].filter(stringIsNotBlank).join(" ");

export const addressLine2 = ({city, county, state, zipCode}: Address) =>
  [city, county, state, zipCode].filter(stringIsNotBlank).join(", ");

export const singleLineAddress = (address: Address) =>
  [addressLine1(address), addressLine2(address)]
    .filter(stringIsNotBlank)
    .join(", ");

export const MultiLineAddressComponent: React.FC<{address: Address}> = ({
  address: {street, unit, city, county, state, zipCode},
}) => {
  const line1 = [street, unit].filter(stringIsNotBlank).join(" ");
  const line2 = [city, county, state, zipCode]
    .filter(stringIsNotBlank)
    .join(", ");
  return (
    <ColumnWrapper>
      <div>{line1}</div>
      <div>{line2}</div>
    </ColumnWrapper>
  );
};

export const SingleLineAddressStyle = styled.span({
  ...FONT_SIZE_H5,
});

export const SingleLineAddressComponent: React.FC<{address: Address}> = ({
  address,
}) => (
  <SingleLineAddressStyle>{singleLineAddress(address)}</SingleLineAddressStyle>
);
