import {StyleToken, useTheme} from "@aws-amplify/ui-react";

export * from "./Caret";
export * from "./Check";
export * from "./DownloadFolder";
export * from "./Edit";
export * from "./Flag";
export * from "./Gavel";
export * from "./Info";
export * from "./Lightning";
export * from "./Plus";
export * from "./Referee";
export * from "./Renew";
export * from "./Trash";
export * from "./Void";
export * from "./Warning";
export * from "./ZoomIn";
export * from "./ZoomOut";

export const useIconTextSpacing = (): StyleToken<string> => {
  const theme = useTheme();
  return theme.tokens.space.xxs;
};
