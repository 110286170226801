/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderFlag } from './OrderFlag';
import {
    OrderFlagFromJSON,
    OrderFlagFromJSONTyped,
    OrderFlagToJSON,
} from './OrderFlag';

/**
 * 
 * @export
 * @interface OrderFlagInactive
 */
export interface OrderFlagInactive extends OrderFlag {
}

/**
 * Check if a given object implements the OrderFlagInactive interface.
 */
export function instanceOfOrderFlagInactive(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrderFlagInactiveFromJSON(json: any): OrderFlagInactive {
    return OrderFlagInactiveFromJSONTyped(json, false);
}

export function OrderFlagInactiveFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderFlagInactive {
    return json;
}

export function OrderFlagInactiveToJSON(value?: OrderFlagInactive | null): any {
    return value;
}

