import {
  Alert,
  Button,
  Flex,
  Placeholder,
  SelectField,
} from "@aws-amplify/ui-react";
import {QueryFunction, useQuery} from "@tanstack/react-query";
import {isLoadingNextQuery, Query} from "@title-service/react-query-utils";
import {
  DateTimeComponent,
  FormikConnectedAmplifySubmitButton,
  FormikConnectedAmplifyTextField,
  FormikForm,
  Text,
  VerticalFieldset,
} from "@title-service/ui";
import {
  enumValues,
  notNullOrUndefined,
  parseBoolean,
  parseWholeNumber,
} from "@title-service/utils";
import {
  buildEmptyObjectForSchema,
  transformNullToUndefined,
} from "@title-service/yup-utils";
import {Formik, FormikHelpers} from "formik";
import React, {useCallback, useContext} from "react";
import {URLSearchParamsInit, useSearchParams} from "react-router-dom";
import * as Yup from "yup";

import {DependencyContext} from "../DependencyContext";
import {
  GetOrdersRequest,
  GetOrdersSortField,
  GetOrdersSuccessResponse,
  parseSortDirection,
  SortDirection,
} from "../shared/adminApi";
import {RouterLink} from "../shared/components/Link";
import {LoadingOverlay} from "../shared/components/LoadingOverlay";
import {PageNumbers} from "../shared/components/Pagination";
import {QuantityComponent} from "../shared/components/Quantity";
import {
  AboveTableContainer,
  BodyRow,
  HeaderRow,
  Table,
  TBody,
  Td,
  Th,
  THead,
} from "../shared/components/tables/PrimaryTable";
import {SortableTh} from "../shared/components/tables/SortableTh";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
} from "../shared/layout";
import {OrderFlagButton} from "../shared/orderDetails/OrderFlagButton";
import {getSortedAgencyFilterOptions} from "../shared/util/sort";

type GetOrdersQueryKey = ["orders", "search", GetOrdersRequest];

export type GetOrdersQuery = Query<GetOrdersSuccessResponse>;

export const OrdersRoute: React.FC<{
  GetOrdersBodyContent?: typeof GetOrdersBodyContent;
  GetOrdersFiltersForm?: typeof GetOrdersFiltersForm;
}> = ({
  GetOrdersBodyContent: GetOrdersBodyContent_ = GetOrdersBodyContent,
  GetOrdersFiltersForm: GetOrdersFiltersForm_ = GetOrdersFiltersForm,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const request = mapSearchParamsToGetOrdersRequest(searchParams);
  const {adminApi} = useContext(DependencyContext);
  const getOrdersQueryFn: QueryFunction<
    GetOrdersSuccessResponse,
    GetOrdersQueryKey
  > = useCallback(
    ({queryKey: [_1, _2, _request], signal}) =>
      adminApi.getOrders(_request, signal),
    [adminApi],
  );
  const getOrdersQuery = useQuery({
    queryKey: ["orders", "search", request],
    keepPreviousData: true,
    queryFn: getOrdersQueryFn,
  });
  const handleChangeRequestState = useCallback(
    (updateRequest: (currentRequest: GetOrdersRequest) => GetOrdersRequest) => {
      setSearchParams((currentSearchParams) => {
        const currentRequest =
          mapSearchParamsToGetOrdersRequest(currentSearchParams);
        const updatedRequest = updateRequest(currentRequest);
        return mapGetOrdersRequestToSearchParams(updatedRequest);
      });
    },
    [setSearchParams],
  );
  return (
    <>
      <BodyHeader>
        <PrimaryBodyHeaderContainer>
          <PrimaryBodyHeader value="Orders Search" />
        </PrimaryBodyHeaderContainer>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <GetOrdersFiltersForm_
          request={request}
          getOrdersQuery={getOrdersQuery}
          onChangeRequest={handleChangeRequestState}
        />
      </BodyHeader>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <GetOrdersBodyContent_
        request={request}
        getOrdersQuery={getOrdersQuery}
        onChangeRequest={handleChangeRequestState}
      />
    </>
  );
};

type GetOrdersFiltersFormData = {
  ids: string;
};

const buildInitialGetOrdersFiltersFormData = ({
  ids,
}: GetOrdersRequest): GetOrdersFiltersFormData => ({
  ids: ids ?? "",
});

type CompleteGetOrdersFiltersFormData = {
  ids?: string;
};

export const GET_ORDERS_FILTERS_FORM_SCHEMA: Yup.ObjectSchema<CompleteGetOrdersFiltersFormData> =
  Yup.object({
    ids: Yup.string().optional().trim().transform(transformNullToUndefined),
  });

export const GetOrdersFiltersForm: React.FC<{
  request: GetOrdersRequest;
  getOrdersQuery: GetOrdersQuery;
  onChangeRequest: (
    updateRequest: (request: GetOrdersRequest) => GetOrdersRequest,
  ) => any;
}> = ({request, getOrdersQuery, getOrdersQuery: {data}, onChangeRequest}) => {
  const submitHandler = useCallback(
    (
      formData: GetOrdersFiltersFormData,
      {setSubmitting}: FormikHelpers<GetOrdersFiltersFormData>,
    ) => {
      onChangeRequest((currentRequest) => ({
        ...currentRequest,
        ...buildEmptyObjectForSchema(GET_ORDERS_FILTERS_FORM_SCHEMA),
        ...GET_ORDERS_FILTERS_FORM_SCHEMA.validateSync(formData),
        page: 0,
      }));
      setSubmitting(false);
    },
    [onChangeRequest],
  );
  return (
    <Formik<GetOrdersFiltersFormData>
      initialValues={buildInitialGetOrdersFiltersFormData(request)}
      enableReinitialize={true}
      validationSchema={GET_ORDERS_FILTERS_FORM_SCHEMA}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <VerticalFieldset>
          <FormikConnectedAmplifyTextField
            testId="ids-filter-input"
            label="Order ID"
            name="ids"
            autoComplete="off"
            minWidth="40ch"
            descriptiveText="Search by Order ID or Reference ID"
          />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton
          size="small"
          loadingText="Searching..."
          isLoading={
            isLoadingNextQuery(getOrdersQuery) ||
            getOrdersQuery.isInitialLoading
          }
          value="Search"
        />
      </FormikForm>
    </Formik>
  );
};

export const GetOrdersBodyContent: React.FC<{
  request: GetOrdersRequest;
  getOrdersQuery: GetOrdersQuery;
  onChangeRequest: (
    updateRequest: (request: GetOrdersRequest) => GetOrdersRequest,
  ) => any;
  OrdersPageSuccessTableBody?: typeof OrdersPageSuccessTableBody;
  OrdersPagePlaceholderTableBody?: typeof OrdersPagePlaceholderTableBody;
  PageNumbers?: typeof PageNumbers;
}> = ({
  request,
  getOrdersQuery,
  getOrdersQuery: {data, error, isInitialLoading},
  onChangeRequest,
  OrdersPageSuccessTableBody:
    OrdersPageSuccessTableBody_ = OrdersPageSuccessTableBody,
  OrdersPagePlaceholderTableBody:
    OrdersPagePlaceholderTableBody_ = OrdersPagePlaceholderTableBody,
  PageNumbers: PageNumbers_ = PageNumbers,
}) => {
  const isLoading = isLoadingNextQuery(getOrdersQuery);
  const disableFormElements = isLoading || getOrdersQuery.isInitialLoading;
  const handleSort = useCallback(
    (sortField: GetOrdersSortField, sortDirection: SortDirection) => {
      onChangeRequest((currentRequest) => ({
        ...currentRequest,
        sortField,
        sortDirection,
        page: 0,
      }));
    },
    [onChangeRequest],
  );
  const handlePageChange = useCallback(
    (zeroIndexedCurrentPageNumber) => {
      onChangeRequest((currentRequest) => ({
        ...currentRequest,
        page: zeroIndexedCurrentPageNumber,
      }));
    },
    [onChangeRequest],
  );
  const handleFilterAgency = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onChangeRequest(({agencyId, ...currentRequestWithoutAgencyId}) => ({
        ...currentRequestWithoutAgencyId,
        agencyId: event.target.value.trim() || undefined,
        page: 0,
      }));
    },
    [onChangeRequest],
  );
  const handleFlagClick = useCallback(() => {
    onChangeRequest(({flagged, ...currentRequestWithoutFlagged}) => ({
      ...currentRequestWithoutFlagged,
      flagged: flagged ? undefined : true,
      page: 0,
    }));
  }, [onChangeRequest]);
  const handleClearFilters = useCallback(() => {
    onChangeRequest(({sortField, sortDirection, pageSize}) => ({
      sortField,
      sortDirection,
      pageSize,
      page: 0,
    }));
  }, [onChangeRequest]);

  return (
    <BodyContent>
      <AboveTableContainer justifyContent="space-between">
        {error ? (
          <Alert variation="error" isDismissible={false} hasIcon={true}>
            Failed to load Orders
          </Alert>
        ) : data ? (
          <QuantityComponent
            quantity={data.pagination.totalRecords}
            label={data.pagination.totalRecords === 1 ? "Order" : "Orders"}
          />
        ) : null}
        <Flex direction="row" justifyContent="flex-end" alignItems="center">
          <SelectField
            data-testid="agency-select"
            label="Agency"
            variation="quiet"
            onChange={handleFilterAgency}
            value={request.agencyId ?? ""}
            placeholder="All"
            isDisabled={disableFormElements}
          >
            {data
              ? getSortedAgencyFilterOptions(data.agencyFilterOptions).map(
                  (agency) => (
                    <option key={agency.id} value={agency.id}>
                      {agency.name}
                    </option>
                  ),
                )
              : null}
          </SelectField>
          <OrderFlagButton
            size="large"
            onClick={handleFlagClick}
            active={!!request.flagged}
            testId="flag-button"
            isDisabled={disableFormElements}
          />
          <Button
            variation="link"
            data-testid="clear-filters"
            onClick={handleClearFilters}
            isDisabled={disableFormElements}
          >
            Clear Filters
          </Button>
        </Flex>
      </AboveTableContainer>
      <LoadingOverlay isActive={isLoading}>
        <Table>
          <THead>
            <HeaderRow>
              <Th>Reference ID</Th>
              <Th>Agency</Th>
              <SortableTh
                data-testid="created-th"
                field={GetOrdersSortField.Created}
                fieldInitialSortDirection={SortDirection.Desc}
                currentSortDirection={request.sortDirection}
                currentSortField={request.sortField}
                onSort={handleSort}
              >
                Created
              </SortableTh>
            </HeaderRow>
          </THead>
          {data ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <OrdersPageSuccessTableBody_ response={data} />
          ) : error ? (
            // eslint-disable-next-line react/jsx-pascal-case
            <OrdersPagePlaceholderTableBody_ isLoaded={true} />
          ) : (
            isInitialLoading && (
              // eslint-disable-next-line react/jsx-pascal-case
              <OrdersPagePlaceholderTableBody_ isLoaded={false} />
            )
          )}
        </Table>
      </LoadingOverlay>
      {data ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <PageNumbers_
          totalPages={data.pagination.totalPages}
          zeroIndexedCurrentPageNumber={data.pagination.currentPage}
          onChange={handlePageChange}
          isDisabled={disableFormElements}
        />
      ) : null}
    </BodyContent>
  );
};

export const OrdersPagePlaceholderTableBody: React.FC<{isLoaded: boolean}> = ({
  isLoaded,
}) => (
  <TBody>
    <BodyRow>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
      <Td>
        <Placeholder isLoaded={isLoaded} />
      </Td>
    </BodyRow>
  </TBody>
);

export const OrdersPageSuccessTableBody: React.FC<{
  response: GetOrdersSuccessResponse;
}> = ({response: {orders}}) => {
  if (!orders.length) {
    return (
      <TBody>
        <BodyRow>
          <Td colSpan={3}>No Orders found</Td>
        </BodyRow>
      </TBody>
    );
  }

  return (
    <TBody>
      {orders.map(({agency, order: {id, referenceId, flagged, createdAt}}) => (
        <BodyRow key={id}>
          <Td>
            <Flex
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              gap="medium"
            >
              <RouterLink to={`/orders/${id}/`}>{referenceId}</RouterLink>
              {flagged ? (
                <OrderFlagButton testId="flag-icon" active={true} />
              ) : null}
            </Flex>
          </Td>
          <Td>
            <Text value={agency.name} />
          </Td>
          <Td>
            <DateTimeComponent value={createdAt} />
          </Td>
        </BodyRow>
      ))}
    </TBody>
  );
};

export type GetOrdersRequestDefaults = Pick<
  GetOrdersRequest,
  "page" | "pageSize" | "sortField" | "sortDirection"
>;

export const GET_ORDERS_REQUEST_QUERY_DEFAULTS: GetOrdersRequestDefaults = {
  sortField: GetOrdersSortField.Created,
  sortDirection: SortDirection.Desc,
  page: 0,
  pageSize: 10,
};

export const mapSearchParamsToGetOrdersRequest = (
  searchParams: URLSearchParams,
  queryDefaults: GetOrdersRequestDefaults = GET_ORDERS_REQUEST_QUERY_DEFAULTS,
): GetOrdersRequest => {
  const ids = searchParams.get("ids")?.trim();
  const agencyId = searchParams.get("agencyId")?.trim();
  const flagged = parseBoolean(searchParams.get("flagged"));
  return {
    sortField:
      mapSearchParamToGetOrdersSortField(searchParams.get("sortField")) ??
      queryDefaults.sortField,
    sortDirection:
      parseSortDirection(searchParams.get("sortDirection")) ??
      queryDefaults.sortDirection,
    page: parseWholeNumber(searchParams.get("page")) ?? queryDefaults.page,
    pageSize:
      parseWholeNumber(searchParams.get("pageSize")) ?? queryDefaults.pageSize,
    ids: ids ? ids : undefined,
    agencyId: agencyId ? agencyId : undefined,
    flagged,
  };
};

const GET_ORDERS_SORT_FIELD_VALUES = enumValues(GetOrdersSortField);

export const mapSearchParamToGetOrdersSortField = (
  getOrdersSortField: string | null,
): GetOrdersSortField | undefined => {
  const trimmed = getOrdersSortField?.trim();
  if (trimmed) {
    return GET_ORDERS_SORT_FIELD_VALUES.find((value) => value === trimmed);
  }
  return undefined;
};

export const mapGetOrdersRequestToSearchParams = ({
  page,
  pageSize,
  ids,
  agencyId,
  flagged,
  ...request
}: GetOrdersRequest): URLSearchParamsInit => {
  const searchParams: URLSearchParamsInit = {
    ...request,
    page: page.toString(10),
    pageSize: pageSize.toString(10),
  };
  if (ids) {
    searchParams.ids = ids;
  }
  if (agencyId) {
    searchParams.agencyId = agencyId;
  }
  if (notNullOrUndefined(flagged)) {
    searchParams.flagged = flagged.toString();
  }
  return searchParams;
};
