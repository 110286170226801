/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValidationReportDetailAssessmentStatus } from './ValidationReportDetailAssessmentStatus';
import {
    ValidationReportDetailAssessmentStatusFromJSON,
    ValidationReportDetailAssessmentStatusFromJSONTyped,
    ValidationReportDetailAssessmentStatusToJSON,
} from './ValidationReportDetailAssessmentStatus';

/**
 * 
 * @export
 * @interface ValidationReportDetailComparisonAssessment
 */
export interface ValidationReportDetailComparisonAssessment {
    /**
     * 
     * @type {ValidationReportDetailAssessmentStatus}
     * @memberof ValidationReportDetailComparisonAssessment
     */
    correlation: ValidationReportDetailAssessmentStatus;
}

/**
 * Check if a given object implements the ValidationReportDetailComparisonAssessment interface.
 */
export function instanceOfValidationReportDetailComparisonAssessment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "correlation" in value;

    return isInstance;
}

export function ValidationReportDetailComparisonAssessmentFromJSON(json: any): ValidationReportDetailComparisonAssessment {
    return ValidationReportDetailComparisonAssessmentFromJSONTyped(json, false);
}

export function ValidationReportDetailComparisonAssessmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationReportDetailComparisonAssessment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'correlation': ValidationReportDetailAssessmentStatusFromJSON(json['correlation']),
    };
}

export function ValidationReportDetailComparisonAssessmentToJSON(value?: ValidationReportDetailComparisonAssessment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'correlation': ValidationReportDetailAssessmentStatusToJSON(value.correlation),
    };
}

