import {
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {Query} from "@title-service/react-query-utils";
import {
  Button,
  FormikConnectedAmplifySubmitButton,
  FormikConnectedAmplifyTextField,
  FormikForm,
  Text,
  VerticalFieldset,
} from "@title-service/ui";
import {transformNullToUndefined} from "@title-service/yup-utils";
import {Formik, FormikHelpers} from "formik";
import React, {useCallback, useContext, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import * as Yup from "yup";

import {DependencyContext} from "../DependencyContext";
import {
  AgencyProducerReference,
  DeactivateAgencyProducerRequestUnprocessableResponseError,
  GetAgencyProducersAutocompleteType,
  GetAgencyProducerSuccessResponse,
  GetProducerJurisdictionsSuccessResponse,
  GetProducerNotesSuccessResponse,
  ProducerType,
  UpdateAgencyProducerRequest,
} from "../shared/adminApi";
import {
  CreateProducerNoteFormData,
  ProducerNotesSection,
} from "../shared/claims/ProducerNotesSection";
import {
  DataGrid,
  GridItem,
  GridItemLabel,
  GridItemLabelContainer,
} from "../shared/components/DataGrid";
import {Modal} from "../shared/components/Modal";
import {MaybeNotAvailable} from "../shared/components/Nullable";
import {EditIcon} from "../shared/components/icons";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
  PrimarySection,
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../shared/layout";
import {AgencyProducerAutocomplete} from "../shared/producers/AgencyProducerAutocomplete";
import {
  AgencyProducerField,
  AgencyProducerFormData,
  buildAgencyProducerFormInitialData,
  buildOptionalAgencyProducerFormSchema,
} from "../shared/producers/AgencyProducerField";
import {
  CompleteDeactivateProducerJurisdictionFormData,
  DeactivateProducerJurisdictionForm,
} from "../shared/producers/DeactivateProducerJurisdictionForm";
import {ProducerJurisdictionsSection} from "../shared/producers/ProducerJurisdictionsSection";

export const AgencyProducerDetailRoute: React.FC = () => {
  const {agencyProducerId} = useParams<
    keyof AgencyProducerDetailPageParams
  >() as AgencyProducerDetailPageParams;

  return <AgencyProducerDetailPage agencyProducerId={agencyProducerId} />;
};

type GetAgencyProducerQueryKey = ["agencyProducers", string, "detail"];
type GetAgencyProducerQuery = Query<GetAgencyProducerSuccessResponse>;

const useGetAgencyProducer = (
  agencyProducerId: string,
): {
  getAgencyProducerQuery: GetAgencyProducerQuery;
  onReloadAgencyProducer: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);

  const getAgencyProducerQueryKey: GetAgencyProducerQueryKey = useMemo(
    () => ["agencyProducers", agencyProducerId, "detail"],
    [agencyProducerId],
  );
  const getAgencyProducerQueryFn: QueryFunction<
    GetAgencyProducerSuccessResponse,
    GetAgencyProducerQueryKey
  > = useCallback(
    ({queryKey: [_, agencyProducerId_], signal}) =>
      adminApi.getAgencyProducer({agencyProducerId: agencyProducerId_}, signal),
    [adminApi],
  );
  const getAgencyProducerQuery = useQuery({
    queryKey: getAgencyProducerQueryKey,
    queryFn: getAgencyProducerQueryFn,
  });
  const onReloadAgencyProducer = useCallback(
    () => queryClient.invalidateQueries({queryKey: getAgencyProducerQueryKey}),
    [queryClient, getAgencyProducerQueryKey],
  );
  return {
    getAgencyProducerQuery,
    onReloadAgencyProducer,
  };
};

type GetAgencyProducerJurisdictionsQueryKey = [
  "agencyProducers",
  string,
  "jurisdictions",
];
export type GetAgencyProducerJurisdictionsQuery =
  Query<GetProducerJurisdictionsSuccessResponse>;

const useGetAgencyProducerJurisdictions = (
  agencyProducerId: string,
): {
  getAgencyProducerJurisdictionsQuery: GetAgencyProducerJurisdictionsQuery;
  onReloadAgencyProducerJurisdictions: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);

  const getAgencyProducerJurisdictionsQueryKey: GetAgencyProducerJurisdictionsQueryKey =
    useMemo(
      () => ["agencyProducers", agencyProducerId, "jurisdictions"],
      [agencyProducerId],
    );
  const getAgencyProducerJurisdictionsQueryFn: QueryFunction<
    GetProducerJurisdictionsSuccessResponse,
    GetAgencyProducerJurisdictionsQueryKey
  > = useCallback(
    ({queryKey: [_, agencyProducerId_], signal}) =>
      adminApi.getAgencyProducerJurisdictions(
        {agencyProducerId: agencyProducerId_},
        signal,
      ),
    [adminApi],
  );
  const getAgencyProducerJurisdictionsQuery = useQuery({
    queryKey: getAgencyProducerJurisdictionsQueryKey,
    queryFn: getAgencyProducerJurisdictionsQueryFn,
  });
  const onReloadAgencyProducerJurisdictions = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: getAgencyProducerJurisdictionsQueryKey,
      }),
    [queryClient, getAgencyProducerJurisdictionsQueryKey],
  );
  return {
    getAgencyProducerJurisdictionsQuery,
    onReloadAgencyProducerJurisdictions,
  };
};

type GetAgencyProducerNotesQueryKey = ["agencyProducers", string, "notes"];
export type GetAgencyProducerNotesQuery =
  Query<GetProducerNotesSuccessResponse>;

export const useGetAgencyProducerNotes = (
  agencyProducerId: string,
): {
  getAgencyProducerNotesQuery: GetAgencyProducerNotesQuery;
  onReloadAgencyProducerNotes: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);
  const getAgencyProducerNotesQueryKey: GetAgencyProducerNotesQueryKey =
    useMemo(
      () => ["agencyProducers", agencyProducerId, "notes"],
      [agencyProducerId],
    );
  const getAgencyProducerNotesQueryFn: QueryFunction<
    GetProducerNotesSuccessResponse,
    GetAgencyProducerNotesQueryKey
  > = useCallback(
    ({queryKey: [_, agencyProducerId_], signal}) =>
      adminApi.getAgencyProducerNotes({producerId: agencyProducerId_}, signal),
    [adminApi],
  );
  const getAgencyProducerNotesQuery = useQuery({
    queryKey: getAgencyProducerNotesQueryKey,
    queryFn: getAgencyProducerNotesQueryFn,
  });
  const onReloadAgencyProducerNotes = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: getAgencyProducerNotesQueryKey,
      }),
    [queryClient, getAgencyProducerNotesQueryKey],
  );

  return {
    getAgencyProducerNotesQuery,
    onReloadAgencyProducerNotes,
  };
};

type AgencyProducerDetailPageParams = {
  agencyProducerId: string;
};

export const AgencyProducerDetailPage: React.FC<
  AgencyProducerDetailPageParams & {
    AgencyProducerDetailQueryResult?: typeof AgencyProducerDetailQueryResult;
  }
> = ({
  agencyProducerId,
  AgencyProducerDetailQueryResult:
    AgencyDetailQueryResult_ = AgencyProducerDetailQueryResult,
}) => {
  const {getAgencyProducerQuery, onReloadAgencyProducer} =
    useGetAgencyProducer(agencyProducerId);
  const {
    getAgencyProducerJurisdictionsQuery,
    onReloadAgencyProducerJurisdictions,
  } = useGetAgencyProducerJurisdictions(agencyProducerId);
  const {getAgencyProducerNotesQuery, onReloadAgencyProducerNotes} =
    useGetAgencyProducerNotes(agencyProducerId);

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <AgencyDetailQueryResult_
      agencyProducerId={agencyProducerId}
      getAgencyProducerQuery={getAgencyProducerQuery}
      getAgencyProducerJurisdictionsQuery={getAgencyProducerJurisdictionsQuery}
      getAgencyProducerNotesQuery={getAgencyProducerNotesQuery}
      onReloadAgencyProducer={onReloadAgencyProducer}
      onReloadAgencyProducerJurisdictions={onReloadAgencyProducerJurisdictions}
      onReloadAgencyProducerNotes={onReloadAgencyProducerNotes}
    />
  );
};

export const AgencyProducerDetailQueryResult: React.FC<{
  agencyProducerId: string;
  getAgencyProducerQuery: GetAgencyProducerQuery;
  getAgencyProducerJurisdictionsQuery: GetAgencyProducerJurisdictionsQuery;
  getAgencyProducerNotesQuery: GetAgencyProducerNotesQuery;
  onReloadAgencyProducer: () => any;
  onReloadAgencyProducerJurisdictions: () => any;
  onReloadAgencyProducerNotes: () => any;
  AgencyProducerDetailSuccessComponent?: typeof AgencyProducerDetailSuccessComponent;
  AgencyProducerDetailFailureComponent?: typeof AgencyProducerDetailFailureComponent;
  AgencyProducerDetailLoadingComponent?: typeof AgencyProducerDetailLoadingComponent;
}> = ({
  agencyProducerId,
  getAgencyProducerQuery: {
    data: getAgencyProducerQueryData,
    error: getAgencyProducerQueryError,
  },
  getAgencyProducerJurisdictionsQuery,
  getAgencyProducerNotesQuery,
  onReloadAgencyProducer,
  onReloadAgencyProducerJurisdictions,
  onReloadAgencyProducerNotes,
  AgencyProducerDetailSuccessComponent:
    AgencyProducerDetailSuccessComponent_ = AgencyProducerDetailSuccessComponent,
  AgencyProducerDetailFailureComponent:
    AgencyProducerDetailFailureComponent_ = AgencyProducerDetailFailureComponent,
  AgencyProducerDetailLoadingComponent:
    AgencyProducerDetailLoadingComponent_ = AgencyProducerDetailLoadingComponent,
}) => {
  if (getAgencyProducerQueryData) {
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <AgencyProducerDetailSuccessComponent_
        onReloadAgencyProducer={onReloadAgencyProducer}
        onReloadAgencyProducerJurisdictions={
          onReloadAgencyProducerJurisdictions
        }
        onReloadAgencyProducerNotes={onReloadAgencyProducerNotes}
        getAgencyProducerResponse={getAgencyProducerQueryData}
        getAgencyProducerJurisdictionsQuery={
          getAgencyProducerJurisdictionsQuery
        }
        getAgencyProducerNotesQuery={getAgencyProducerNotesQuery}
      />
    );
  } else if (getAgencyProducerQueryError) {
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <AgencyProducerDetailFailureComponent_
        agencyProducerId={agencyProducerId}
      />
    );
  }
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <AgencyProducerDetailLoadingComponent_
      agencyProducerId={agencyProducerId}
    />
  );
};

export const AgencyProducerDetailLoadingComponent: React.FC<{
  agencyProducerId: string;
}> = ({agencyProducerId}) => (
  <BodyContent>
    <span>Loading Agency Producer {agencyProducerId}...</span>
  </BodyContent>
);

export const AgencyProducerDetailFailureComponent: React.FC<{
  agencyProducerId: string;
}> = ({agencyProducerId}) => (
  <BodyContent>
    <span>Failed to load Agency Producer {agencyProducerId}.</span>
  </BodyContent>
);

const MODAL_CONTENT_STYLE: React.CSSProperties = {
  minWidth: "750px",
};

const isAppointmentTerminationNoticeDateRequiredError = (mutationError: any) =>
  mutationError instanceof
  DeactivateAgencyProducerRequestUnprocessableResponseError;

export const AgencyProducerDetailSuccessComponent: React.FC<{
  onReloadAgencyProducer: () => any;
  onReloadAgencyProducerJurisdictions: () => any;
  onReloadAgencyProducerNotes: () => any;
  getAgencyProducerResponse: GetAgencyProducerSuccessResponse;
  getAgencyProducerJurisdictionsQuery: GetAgencyProducerJurisdictionsQuery;
  getAgencyProducerNotesQuery: GetAgencyProducerNotesQuery;
}> = ({
  onReloadAgencyProducer,
  onReloadAgencyProducerJurisdictions,
  onReloadAgencyProducerNotes,
  getAgencyProducerResponse,
  getAgencyProducerJurisdictionsQuery,
  getAgencyProducerNotesQuery,
  getAgencyProducerResponse: {id, name, parentAgencyProducer, niprId},
}) => {
  const {adminApi} = useContext(DependencyContext);
  const agencyProducerId = {agencyProducerId: id};
  const [displayUpdateForm, setDisplayUpdateForm] = useState(false);
  const openUpdateForm = useCallback(() => {
    setDisplayUpdateForm(true);
  }, []);
  const closeUpdateForm = useCallback(() => {
    setDisplayUpdateForm(false);
  }, []);
  const handleAgencyProducerUpdated = useCallback(() => {
    onReloadAgencyProducer();
    closeUpdateForm();
  }, [closeUpdateForm, onReloadAgencyProducer]);

  const [displayDeactivateForm, setDisplayDeactivateForm] = useState(false);
  const openDeactivateForm = useCallback(() => {
    setDisplayDeactivateForm(true);
  }, []);
  const closeDeactivateForm = useCallback(() => {
    setDisplayDeactivateForm(false);
  }, []);
  const handleAgencyProducerDeactivated = useCallback(() => {
    onReloadAgencyProducer();
    onReloadAgencyProducerJurisdictions();
    closeDeactivateForm();
  }, [
    closeDeactivateForm,
    onReloadAgencyProducer,
    onReloadAgencyProducerJurisdictions,
  ]);

  const deactivateAgencyProducerMutationFn = useCallback(
    (
      completeDeactivateProducerJurisdictionFormData: CompleteDeactivateProducerJurisdictionFormData,
    ) =>
      adminApi.deactivateAgencyProducer({
        ...completeDeactivateProducerJurisdictionFormData,
        agencyProducerId: id,
      }),
    [adminApi, id],
  );
  const createAgencyProducerNoteMutationFn = useCallback(
    (formData: CreateProducerNoteFormData) =>
      adminApi.createAgencyProducerNote({
        ...formData,
        agencyProducerId: id,
      }),
    [adminApi, id],
  );
  return (
    <>
      <BodyHeader>
        <PrimaryBodyHeaderContainer>
          <PrimaryBodyHeader value={name} />
          <>
            <Button
              variation="primary"
              onClick={openDeactivateForm}
              loadingText="Deactivating..."
              value="Deactivate Producer"
            />
            <Modal
              contentStyle={MODAL_CONTENT_STYLE}
              isOpen={displayDeactivateForm}
              onClose={closeDeactivateForm}
            >
              <DeactivateProducerJurisdictionForm
                onSubmitComplete={handleAgencyProducerDeactivated}
                mutationFn={deactivateAgencyProducerMutationFn}
                appointmentTerminationNoticeDateRequired={false}
                displayAppointmentTerminationNoticeDate={true}
                requireConfirmation={true}
                isAppointmentTerminationNoticeDateRequiredError={
                  isAppointmentTerminationNoticeDateRequiredError
                }
              />
            </Modal>
          </>
        </PrimaryBodyHeaderContainer>
      </BodyHeader>
      <BodyContent>
        <PrimarySection>
          <SecondarySection>
            <SecondarySectionHeaderContainer>
              <SecondarySectionHeader value="Agency Producer Overview" />
              <EditIcon onClick={openUpdateForm} />
            </SecondarySectionHeaderContainer>
            <SecondarySectionContent>
              <DataGrid>
                <GridItem>
                  <GridItemLabelContainer>
                    <GridItemLabel value="Parent Agency" />
                  </GridItemLabelContainer>
                  <MaybeNotAvailable value={parentAgencyProducer?.name}>
                    {(parentAgencyProducerName) => (
                      <Text value={parentAgencyProducerName} />
                    )}
                  </MaybeNotAvailable>
                </GridItem>
                <GridItem>
                  <GridItemLabelContainer>
                    <GridItemLabel value="NIPR ID" />
                  </GridItemLabelContainer>
                  <MaybeNotAvailable value={niprId}>
                    {(niprId_) => <Text value={niprId_} />}
                  </MaybeNotAvailable>
                </GridItem>
              </DataGrid>
              <Modal
                contentStyle={MODAL_CONTENT_STYLE}
                isOpen={displayUpdateForm}
                onClose={closeUpdateForm}
                title="Edit Agency Producer Details"
              >
                <UpdateAgencyProducerForm
                  response={getAgencyProducerResponse}
                  onSubmitted={handleAgencyProducerUpdated}
                />
              </Modal>
            </SecondarySectionContent>
          </SecondarySection>
          <ProducerJurisdictionsSection
            producerId={agencyProducerId}
            onReloadProducerJurisdictions={onReloadAgencyProducerJurisdictions}
            getProducerJurisdictionsQuery={getAgencyProducerJurisdictionsQuery}
          />
          <ProducerNotesSection
            producerId={id}
            producerType={ProducerType.Agency}
            createNoteMutationFn={createAgencyProducerNoteMutationFn}
            onReloadProducerNotes={onReloadAgencyProducerNotes}
            getProducerNotesQuery={getAgencyProducerNotesQuery}
          />
        </PrimarySection>
      </BodyContent>
    </>
  );
};

export type UpdateAgencyProducerFormData = Required<
  Omit<
    UpdateAgencyProducerRequest,
    "parentAgencyProducerId" | "agencyProducerId"
  >
> & {parentAgencyProducer: AgencyProducerFormData};

export const buildUpdateAgencyProducerFormInitialData = (
  response: GetAgencyProducerSuccessResponse,
): UpdateAgencyProducerFormData => ({
  name: response.name,
  niprId: response.niprId ? response.niprId : "",
  parentAgencyProducer: response.parentAgencyProducer
    ? response.parentAgencyProducer
    : buildAgencyProducerFormInitialData(),
});

export const mapUpdateAgencyProducerFormDataToUpdateAgencyProducerRequest = (
  agencyProducer: GetAgencyProducerSuccessResponse,
  formData: UpdateAgencyProducerFormData,
): UpdateAgencyProducerRequest => {
  const {parentAgencyProducer, ...completeFormData} =
    UPDATE_AGENCY_PRODUCER_FORM_SCHEMA.validateSync(formData, {
      stripUnknown: true,
    });
  return {
    ...completeFormData,
    agencyProducerId: agencyProducer.id,
    parentAgencyProducerId: parentAgencyProducer.id,
  };
};

export type CompleteUpdateAgencyFormData = Omit<
  UpdateAgencyProducerRequest,
  "parentAgencyProducerId" | "agencyProducerId"
> & {parentAgencyProducer: Partial<AgencyProducerReference>};

export const UPDATE_AGENCY_PRODUCER_FORM_SCHEMA: Yup.ObjectSchema<CompleteUpdateAgencyFormData> =
  Yup.object({
    name: Yup.string()
      .trim()
      .transform(transformNullToUndefined)
      .required("Agency Producer name is required"),
    niprId: Yup.string().trim().transform(transformNullToUndefined).optional(),
    parentAgencyProducer: buildOptionalAgencyProducerFormSchema(),
  });

export const UpdateAgencyProducerForm: React.FC<{
  AgencyProducerAutocomplete?: typeof AgencyProducerAutocomplete;
  onSubmitted: () => any;
  response: GetAgencyProducerSuccessResponse;
}> = ({
  onSubmitted,
  AgencyProducerAutocomplete:
    AgencyProducerAutocomplete_ = AgencyProducerAutocomplete,
  response,
}) => {
  const {adminApi} = useContext(DependencyContext);
  const updateAgencyProducerMutation = useMutation({
    mutationFn: adminApi.updateAgencyProducer,
  });
  const submitHandler = useCallback(
    (
      formData: UpdateAgencyProducerFormData,
      {setSubmitting}: FormikHelpers<UpdateAgencyProducerFormData>,
    ) => {
      const updateAgencyProducerRequest: UpdateAgencyProducerRequest =
        mapUpdateAgencyProducerFormDataToUpdateAgencyProducerRequest(
          response,
          formData,
        );
      updateAgencyProducerMutation.mutate(updateAgencyProducerRequest, {
        onSuccess: () => {
          onSubmitted();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [updateAgencyProducerMutation, onSubmitted, response],
  );
  return (
    <Formik<UpdateAgencyProducerFormData>
      initialValues={buildUpdateAgencyProducerFormInitialData(response)}
      validationSchema={UPDATE_AGENCY_PRODUCER_FORM_SCHEMA as any}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <VerticalFieldset alignItems="stretch" width="100%">
          <FormikConnectedAmplifyTextField
            testId="name-input"
            label="Agency Name"
            name="name"
          />

          <FormikConnectedAmplifyTextField
            testId="nipr-id-input"
            label="NIPR Number (optional)"
            name="niprId"
          />

          <AgencyProducerField
            label="Parent Agency (optional)"
            name="parentAgencyProducer"
            type={GetAgencyProducersAutocompleteType.Parent}
            AgencyProducerAutocomplete={AgencyProducerAutocomplete_}
          />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton />
      </FormikForm>
    </Formik>
  );
};
