/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProducerType } from './ProducerType';
import {
    ProducerTypeFromJSON,
    ProducerTypeFromJSONTyped,
    ProducerTypeToJSON,
} from './ProducerType';

/**
 * 
 * @export
 * @interface GetProducerNotesSuccessResponseNote
 */
export interface GetProducerNotesSuccessResponseNote {
    /**
     * 
     * @type {Date}
     * @memberof GetProducerNotesSuccessResponseNote
     */
    createdAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GetProducerNotesSuccessResponseNote
     */
    editable: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProducerNotesSuccessResponseNote
     */
    familyName: string;
    /**
     * 
     * @type {string}
     * @memberof GetProducerNotesSuccessResponseNote
     */
    givenName: string;
    /**
     * 
     * @type {string}
     * @memberof GetProducerNotesSuccessResponseNote
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetProducerNotesSuccessResponseNote
     */
    note: string;
    /**
     * 
     * @type {ProducerType}
     * @memberof GetProducerNotesSuccessResponseNote
     */
    producerType: ProducerType;
    /**
     * 
     * @type {Date}
     * @memberof GetProducerNotesSuccessResponseNote
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the GetProducerNotesSuccessResponseNote interface.
 */
export function instanceOfGetProducerNotesSuccessResponseNote(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "editable" in value;
    isInstance = isInstance && "familyName" in value;
    isInstance = isInstance && "givenName" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "note" in value;
    isInstance = isInstance && "producerType" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function GetProducerNotesSuccessResponseNoteFromJSON(json: any): GetProducerNotesSuccessResponseNote {
    return GetProducerNotesSuccessResponseNoteFromJSONTyped(json, false);
}

export function GetProducerNotesSuccessResponseNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProducerNotesSuccessResponseNote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'editable': json['editable'],
        'familyName': json['familyName'],
        'givenName': json['givenName'],
        'id': json['id'],
        'note': json['note'],
        'producerType': ProducerTypeFromJSON(json['producerType']),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function GetProducerNotesSuccessResponseNoteToJSON(value?: GetProducerNotesSuccessResponseNote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.createdAt.toISOString()),
        'editable': value.editable,
        'familyName': value.familyName,
        'givenName': value.givenName,
        'id': value.id,
        'note': value.note,
        'producerType': ProducerTypeToJSON(value.producerType),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

