/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttomAddress
 */
export interface AttomAddress {
    /**
     * 
     * @type {string}
     * @memberof AttomAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AttomAddress
     */
    county?: string;
    /**
     * 
     * @type {string}
     * @memberof AttomAddress
     */
    fullStreetAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof AttomAddress
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof AttomAddress
     */
    zipCode?: string;
}

/**
 * Check if a given object implements the AttomAddress interface.
 */
export function instanceOfAttomAddress(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AttomAddressFromJSON(json: any): AttomAddress {
    return AttomAddressFromJSONTyped(json, false);
}

export function AttomAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttomAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'city': !exists(json, 'city') ? undefined : json['city'],
        'county': !exists(json, 'county') ? undefined : json['county'],
        'fullStreetAddress': !exists(json, 'fullStreetAddress') ? undefined : json['fullStreetAddress'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
    };
}

export function AttomAddressToJSON(value?: AttomAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city': value.city,
        'county': value.county,
        'fullStreetAddress': value.fullStreetAddress,
        'state': value.state,
        'zipCode': value.zipCode,
    };
}

