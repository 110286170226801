import {UseQueryResult} from "@tanstack/react-query";

export type Query<T> = Pick<
  UseQueryResult<T>,
  | "data"
  | "error"
  | "isError"
  | "isInitialLoading"
  | "isFetching"
  | "isPreviousData"
>;

export const isLoadingNextQuery = <T>(
  query: Pick<Query<T>, "isFetching" | "isPreviousData">,
) => query.isPreviousData && query.isFetching;
