import {
  OrderFlagActive as FetchOrderFlagActive,
  OrderFlagInactive as FetchOrderFlagInactive,
} from "../fetchClient";

const ORDER_FLAG_ACTIVE_STATUS = "active" as const;

export type OrderFlagActive = FetchOrderFlagActive & {
  status: typeof ORDER_FLAG_ACTIVE_STATUS;
};

const ORDER_FLAG_INACTIVE_STATUS = "inactive" as const;

export type OrderFlagInactive = FetchOrderFlagInactive & {
  status: typeof ORDER_FLAG_INACTIVE_STATUS;
};

export type OrderFlag = OrderFlagActive | OrderFlagInactive;

export const isOrderFlagActive = (flag: OrderFlag): flag is OrderFlagActive =>
  flag.status === ORDER_FLAG_ACTIVE_STATUS;

export const isOrderFlagInactive = (
  flag: OrderFlag,
): flag is OrderFlagInactive => flag.status === ORDER_FLAG_INACTIVE_STATUS;
