/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClaimCostType = {
    Expense: 'expense',
    Loss: 'loss'
} as const;
export type ClaimCostType = typeof ClaimCostType[keyof typeof ClaimCostType];


export function ClaimCostTypeFromJSON(json: any): ClaimCostType {
    return ClaimCostTypeFromJSONTyped(json, false);
}

export function ClaimCostTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaimCostType {
    return json as ClaimCostType;
}

export function ClaimCostTypeToJSON(value?: ClaimCostType | null): any {
    return value as any;
}

