/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClaimCauseOfLossType = {
    A1: 'A.1',
    A2: 'A.2',
    A3: 'A.3',
    A4: 'A.4',
    A5: 'A.5',
    A6: 'A.6',
    A7: 'A.7',
    A8: 'A.8',
    A9: 'A.9',
    A10: 'A.10',
    A11: 'A.11',
    A12: 'A.12',
    A13: 'A.13',
    A14: 'A.14',
    A15: 'A.15',
    A16: 'A.16',
    A17: 'A.17',
    A18: 'A.18',
    A19: 'A.19',
    A20: 'A.20',
    A21: 'A.21',
    A22: 'A.22',
    A23: 'A.23',
    A24: 'A.24',
    A25: 'A.25',
    A26: 'A.26',
    A27: 'A.27',
    A28: 'A.28',
    A29: 'A.29',
    A30: 'A.30',
    B1A: 'B.1.a',
    B1B: 'B.1.b',
    B2: 'B.2',
    B3: 'B.3',
    B4A: 'B.4.a',
    B4B: 'B.4.b',
    B4C: 'B.4.c',
    B4D: 'B.4.d',
    B4E: 'B.4.e',
    B4F: 'B.4.f',
    B4G: 'B.4.g',
    B4H: 'B.4.h',
    B4I: 'B.4.i',
    B4J: 'B.4.j',
    B4K: 'B.4.k',
    B4L: 'B.4.l',
    B4M: 'B.4.m',
    B4N: 'B.4.n',
    B4O: 'B.4.o',
    B4P: 'B.4.p',
    B4Q: 'B.4.q',
    B4R: 'B.4.r',
    B4S: 'B.4.s',
    B4T: 'B.4.t',
    B4U: 'B.4.u',
    B4V: 'B.4.v',
    B4W: 'B.4.w',
    B4X: 'B.4.x',
    B4Y: 'B.4.y',
    B4Z: 'B.4.z',
    B4Aa: 'B.4.aa',
    B4Bb: 'B.4.bb',
    C1: 'C.1',
    C2: 'C.2',
    C3: 'C.3',
    C4: 'C.4',
    C5: 'C.5',
    D1: 'D.1',
    D2: 'D.2',
    D3: 'D.3',
    E1: 'E.1',
    E2: 'E.2',
    F1: 'F.1',
    F2: 'F.2',
    F3: 'F.3',
    F4: 'F.4',
    F5: 'F.5',
    F6: 'F.6',
    G: 'G',
    H: 'H',
    I1: 'I.1',
    I2: 'I.2',
    I3: 'I.3',
    I4: 'I.4',
    J: 'J',
    K1: 'K.1',
    K2: 'K.2',
    K3: 'K.3'
} as const;
export type ClaimCauseOfLossType = typeof ClaimCauseOfLossType[keyof typeof ClaimCauseOfLossType];


export function ClaimCauseOfLossTypeFromJSON(json: any): ClaimCauseOfLossType {
    return ClaimCauseOfLossTypeFromJSONTyped(json, false);
}

export function ClaimCauseOfLossTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaimCauseOfLossType {
    return json as ClaimCauseOfLossType;
}

export function ClaimCauseOfLossTypeToJSON(value?: ClaimCauseOfLossType | null): any {
    return value as any;
}

