/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TransactionParty } from './TransactionParty';
import {
    TransactionPartyFromJSON,
    TransactionPartyFromJSONTyped,
    TransactionPartyToJSON,
} from './TransactionParty';
import type { TransactionType } from './TransactionType';
import {
    TransactionTypeFromJSON,
    TransactionTypeFromJSONTyped,
    TransactionTypeToJSON,
} from './TransactionType';

/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    agencyOrderStatus: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    agencyProducerStateLicenseNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    createdAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    flagged: boolean;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    individualProducerStateLicenseNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    referenceId: string;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    sprucePowered: boolean;
    /**
     * 
     * @type {Array<TransactionParty>}
     * @memberof Order
     */
    transactionParties: Array<TransactionParty>;
    /**
     * 
     * @type {TransactionType}
     * @memberof Order
     */
    transactionType: TransactionType;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the Order interface.
 */
export function instanceOfOrder(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "agencyOrderStatus" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "flagged" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "referenceId" in value;
    isInstance = isInstance && "sprucePowered" in value;
    isInstance = isInstance && "transactionParties" in value;
    isInstance = isInstance && "transactionType" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function OrderFromJSON(json: any): Order {
    return OrderFromJSONTyped(json, false);
}

export function OrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Order {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agencyOrderStatus': json['agencyOrderStatus'],
        'agencyProducerStateLicenseNumber': !exists(json, 'agencyProducerStateLicenseNumber') ? undefined : json['agencyProducerStateLicenseNumber'],
        'createdAt': (new Date(json['createdAt'])),
        'flagged': json['flagged'],
        'id': json['id'],
        'individualProducerStateLicenseNumber': !exists(json, 'individualProducerStateLicenseNumber') ? undefined : json['individualProducerStateLicenseNumber'],
        'referenceId': json['referenceId'],
        'sprucePowered': json['sprucePowered'],
        'transactionParties': ((json['transactionParties'] as Array<any>).map(TransactionPartyFromJSON)),
        'transactionType': TransactionTypeFromJSON(json['transactionType']),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function OrderToJSON(value?: Order | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agencyOrderStatus': value.agencyOrderStatus,
        'agencyProducerStateLicenseNumber': value.agencyProducerStateLicenseNumber,
        'createdAt': (value.createdAt.toISOString()),
        'flagged': value.flagged,
        'id': value.id,
        'individualProducerStateLicenseNumber': value.individualProducerStateLicenseNumber,
        'referenceId': value.referenceId,
        'sprucePowered': value.sprucePowered,
        'transactionParties': ((value.transactionParties as Array<any>).map(TransactionPartyToJSON)),
        'transactionType': TransactionTypeToJSON(value.transactionType),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

