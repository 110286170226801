/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 */

import { exists, mapValues } from '../runtime';
import type { AgencyProducerReference } from './AgencyProducerReference';
import {
    AgencyProducerReferenceFromJSON,
    AgencyProducerReferenceFromJSONTyped,
    AgencyProducerReferenceToJSON,
} from './AgencyProducerReference';
import type { GetOrdersSuccessResponseOrder } from './GetOrdersSuccessResponseOrder';
import {
    GetOrdersSuccessResponseOrderFromJSON,
    GetOrdersSuccessResponseOrderFromJSONTyped,
    GetOrdersSuccessResponseOrderToJSON,
} from './GetOrdersSuccessResponseOrder';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';

/**
 * 
 * @export
 * @interface GetOrdersSuccessResponse
 */
export interface GetOrdersSuccessResponse {
    /**
     * 
     * @type {Set<AgencyProducerReference>}
     * @memberof GetOrdersSuccessResponse
     */
    agencyFilterOptions: Set<AgencyProducerReference>;
    /**
     * 
     * @type {Array<GetOrdersSuccessResponseOrder>}
     * @memberof GetOrdersSuccessResponse
     */
    orders: Array<GetOrdersSuccessResponseOrder>;
    /**
     * 
     * @type {Pagination}
     * @memberof GetOrdersSuccessResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the GetOrdersSuccessResponse interface.
 */
export function instanceOfGetOrdersSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "agencyFilterOptions" in value;
    isInstance = isInstance && "orders" in value;
    isInstance = isInstance && "pagination" in value;

    return isInstance;
}

export function GetOrdersSuccessResponseFromJSON(json: any): GetOrdersSuccessResponse {
    return GetOrdersSuccessResponseFromJSONTyped(json, false);
}

export function GetOrdersSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrdersSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'agencyFilterOptions': (new Set((json['agencyFilterOptions'] as Array<any>).map(AgencyProducerReferenceFromJSON))),
        'orders': ((json['orders'] as Array<any>).map(GetOrdersSuccessResponseOrderFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function GetOrdersSuccessResponseToJSON(value?: GetOrdersSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'agencyFilterOptions': (Array.from(value.agencyFilterOptions as Set<any>).map(AgencyProducerReferenceToJSON)),
        'orders': ((value.orders as Array<any>).map(GetOrdersSuccessResponseOrderToJSON)),
        'pagination': PaginationToJSON(value.pagination),
    };
}

