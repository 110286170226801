import {Text, TextRaw} from "@title-service/ui";
import React from "react";

type YesNoComponentProps = {value: boolean};

const displayBoolean = ({value}: YesNoComponentProps) => (value ? "Yes" : "No");

export const YesNoComponentRaw: React.FC<YesNoComponentProps> = ({value}) => (
  <TextRaw value={displayBoolean({value})} />
);

export const YesNoComponent: React.FC<
  Omit<React.ComponentProps<typeof Text>, "value"> & YesNoComponentProps
> = (props) => <Text {...props} value={displayBoolean(props)} />;
