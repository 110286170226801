/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetClaimAttachmentsSuccessResponseAttachment } from './GetClaimAttachmentsSuccessResponseAttachment';
import {
    GetClaimAttachmentsSuccessResponseAttachmentFromJSON,
    GetClaimAttachmentsSuccessResponseAttachmentFromJSONTyped,
    GetClaimAttachmentsSuccessResponseAttachmentToJSON,
} from './GetClaimAttachmentsSuccessResponseAttachment';

/**
 * 
 * @export
 * @interface GetClaimAttachmentsSuccessResponse
 */
export interface GetClaimAttachmentsSuccessResponse {
    /**
     * 
     * @type {Array<GetClaimAttachmentsSuccessResponseAttachment>}
     * @memberof GetClaimAttachmentsSuccessResponse
     */
    attachments: Array<GetClaimAttachmentsSuccessResponseAttachment>;
}

/**
 * Check if a given object implements the GetClaimAttachmentsSuccessResponse interface.
 */
export function instanceOfGetClaimAttachmentsSuccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "attachments" in value;

    return isInstance;
}

export function GetClaimAttachmentsSuccessResponseFromJSON(json: any): GetClaimAttachmentsSuccessResponse {
    return GetClaimAttachmentsSuccessResponseFromJSONTyped(json, false);
}

export function GetClaimAttachmentsSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetClaimAttachmentsSuccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attachments': ((json['attachments'] as Array<any>).map(GetClaimAttachmentsSuccessResponseAttachmentFromJSON)),
    };
}

export function GetClaimAttachmentsSuccessResponseToJSON(value?: GetClaimAttachmentsSuccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attachments': ((value.attachments as Array<any>).map(GetClaimAttachmentsSuccessResponseAttachmentToJSON)),
    };
}

