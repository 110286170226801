import {notNullOrUndefined} from "./nullability";

export const stringIsNotBlank = (
  str: string | null | undefined,
): str is string => notNullOrUndefined(str) && str.trim().length > 0;

if (import.meta.vitest) {
  const {describe, expect, it} = import.meta.vitest;

  describe("stringIsNotBlank", () => {
    it("returns true when present", () => {
      expect(stringIsNotBlank("1")).toBe(true);
    });

    it("returns false when null", () => {
      expect(stringIsNotBlank(null)).toBe(false);
    });

    it("returns false when undefined", () => {
      expect(stringIsNotBlank(undefined)).toBe(false);
    });

    it("returns false when empty", () => {
      expect(stringIsNotBlank("")).toBe(false);
    });

    it("returns false when blank", () => {
      expect(stringIsNotBlank(" \n \t ")).toBe(false);
    });
  });
}
