import React from "react";

import {Text, TextRaw} from "./Text";

type PercentComponentProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  locale?: Intl.LocalesArgument;
  // eslint-disable-next-line react/no-unused-prop-types
  value: number;
  // eslint-disable-next-line react/no-unused-prop-types
  children?: undefined;
};

const percentToLocaleString = ({
  value,
  locale,
}: PercentComponentProps): string =>
  value.toLocaleString(locale, {
    style: "percent",
    minimumFractionDigits: 2,
  });

export const PercentComponentRaw: React.FC<PercentComponentProps> = (props) => (
  <TextRaw value={percentToLocaleString(props)} />
);

export const PercentComponent: React.FC<
  Omit<React.ComponentProps<typeof Text>, "value"> & PercentComponentProps
> = (props) => <Text {...props} value={percentToLocaleString(props)} />;

if (import.meta.vitest) {
  const {describe, expect, it} = import.meta.vitest;
  const {render} = await import("@testing-library/react");

  describe("PercentComponent", () => {
    it("displays a number as a percentage", () => {
      const {container} = render(
        <PercentComponentRaw value={0.15789} locale="en-US" />,
      );

      expect(container).toHaveTextContent("15.79%");
    });
  });
}
