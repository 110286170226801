/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValidationReportDetailAssessment } from './ValidationReportDetailAssessment';
import {
    ValidationReportDetailAssessmentFromJSON,
    ValidationReportDetailAssessmentFromJSONTyped,
    ValidationReportDetailAssessmentToJSON,
} from './ValidationReportDetailAssessment';
import type { ValidationReportDetailFigures } from './ValidationReportDetailFigures';
import {
    ValidationReportDetailFiguresFromJSON,
    ValidationReportDetailFiguresFromJSONTyped,
    ValidationReportDetailFiguresToJSON,
} from './ValidationReportDetailFigures';
import type { ValidationReportDetailMetadata } from './ValidationReportDetailMetadata';
import {
    ValidationReportDetailMetadataFromJSON,
    ValidationReportDetailMetadataFromJSONTyped,
    ValidationReportDetailMetadataToJSON,
} from './ValidationReportDetailMetadata';
import type { ValidationReportDetailStatistics } from './ValidationReportDetailStatistics';
import {
    ValidationReportDetailStatisticsFromJSON,
    ValidationReportDetailStatisticsFromJSONTyped,
    ValidationReportDetailStatisticsToJSON,
} from './ValidationReportDetailStatistics';

/**
 * 
 * @export
 * @interface ValidationReportDetailData
 */
export interface ValidationReportDetailData {
    /**
     * 
     * @type {ValidationReportDetailAssessment}
     * @memberof ValidationReportDetailData
     */
    assessment: ValidationReportDetailAssessment;
    /**
     * 
     * @type {ValidationReportDetailFigures}
     * @memberof ValidationReportDetailData
     */
    figures: ValidationReportDetailFigures;
    /**
     * 
     * @type {ValidationReportDetailMetadata}
     * @memberof ValidationReportDetailData
     */
    metadata: ValidationReportDetailMetadata;
    /**
     * 
     * @type {ValidationReportDetailStatistics}
     * @memberof ValidationReportDetailData
     */
    statistics: ValidationReportDetailStatistics;
}

/**
 * Check if a given object implements the ValidationReportDetailData interface.
 */
export function instanceOfValidationReportDetailData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "assessment" in value;
    isInstance = isInstance && "figures" in value;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "statistics" in value;

    return isInstance;
}

export function ValidationReportDetailDataFromJSON(json: any): ValidationReportDetailData {
    return ValidationReportDetailDataFromJSONTyped(json, false);
}

export function ValidationReportDetailDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationReportDetailData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assessment': ValidationReportDetailAssessmentFromJSON(json['assessment']),
        'figures': ValidationReportDetailFiguresFromJSON(json['figures']),
        'metadata': ValidationReportDetailMetadataFromJSON(json['metadata']),
        'statistics': ValidationReportDetailStatisticsFromJSON(json['statistics']),
    };
}

export function ValidationReportDetailDataToJSON(value?: ValidationReportDetailData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assessment': ValidationReportDetailAssessmentToJSON(value.assessment),
        'figures': ValidationReportDetailFiguresToJSON(value.figures),
        'metadata': ValidationReportDetailMetadataToJSON(value.metadata),
        'statistics': ValidationReportDetailStatisticsToJSON(value.statistics),
    };
}

