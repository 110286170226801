import {CSSProperties} from "styled-components";

export const COLOR_P_100 = "#E5EBFF";
export const COLOR_P_500 = "#3455db";
export const COLOR_P_800 = "#0024B3";

export const COLOR_MR_500 = "#165788";

export const COLOR_N_000 = "#FFFFFF";
export const COLOR_N_100 = "#FAFAFA";
export const COLOR_N_200 = "#F1F2F3";
export const COLOR_N_300 = "#C3C7CB";
export const COLOR_N_500 = "#627990";
export const COLOR_N_700 = "#435363";
export const COLOR_N_800 = "#000000";

export const COLOR_ERROR_100 = "#FBE9E9";
export const COLOR_ERROR_800 = "#C32525";
export const COLOR_SUCCESS_100 = "#E5FFF1";
export const COLOR_SUCCESS_800 = "#00803A";
export const COLOR_WARNING_100 = "#FFF1CC";
export const COLOR_WARNING_800 = "#B38500";

export const SPACING_1 = "4px";
export const SPACING_2 = "8px";
export const SPACING_3 = "16px";
export const SPACING_4 = "24px";
export const SPACING_5 = "32px";
export const SPACING_6 = "40px";
export const SPACING_7 = "48px";
export const SPACING_8 = "56px";
export const SPACING_9 = "64px";
export const SPACING_10 = "72px";
export const SPACING_11 = "80px";
export const SPACING_12 = "88px";
export const SPACING_13 = "96px";

type FontProperties = Required<Pick<CSSProperties, "fontSize" | "lineHeight">>;

export const FONT_SIZE_TITLE: FontProperties = {
  fontSize: "28px",
  lineHeight: "42px",
};

export const FONT_SIZE_H1: FontProperties = {
  fontSize: "36px",
  lineHeight: "54px",
};

export const FONT_SIZE_H2: FontProperties = {
  fontSize: "24px",
  lineHeight: "36px",
};

export const FONT_SIZE_H3: FontProperties = {
  fontSize: "21px",
  lineHeight: "31.5px",
};

export const FONT_SIZE_H4: FontProperties = {
  fontSize: "18px",
  lineHeight: "27px",
};

export const FONT_SIZE_H5: FontProperties = {
  fontSize: "16px",
  lineHeight: "24px",
};

export const FONT_SIZE_H6: FontProperties = {
  fontSize: "14px",
  lineHeight: "21px",
};

export const FONT_SIZE_H7: FontProperties = {
  fontSize: "12px",
  lineHeight: "18px",
};
