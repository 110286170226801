import {
  Button as AmplifyButton,
  Link as AmplifyLink,
} from "@aws-amplify/ui-react";
import React from "react";
import {Link as ReactRouterLink} from "react-router-dom";

type AmplifyLinkProps = React.ComponentProps<typeof AmplifyLink>;

export const RouterLink: React.FC<
  Omit<AmplifyLinkProps, "to" | "as" | "href"> & {to: string}
> = (props) => <AmplifyLink as={ReactRouterLink} {...props} />;

type AmplifyButtonProps = React.ComponentProps<typeof AmplifyButton>;

export const RouterButtonLink: React.FC<
  AmplifyButtonProps & React.ComponentProps<typeof RouterLink>
> = (props) => <AmplifyButton as={RouterLink} variation="link" {...props} />;

export const DownloadLink: React.FC<
  Omit<AmplifyLinkProps, "href"> & Required<Pick<AmplifyLinkProps, "href">>
> = ({children, ...props}) => (
  <AmplifyLink target="_blank" rel="noopener noreferrer" {...props}>
    {children}
  </AmplifyLink>
);

export const DownloadButtonLink: React.FC<
  AmplifyButtonProps & React.ComponentProps<typeof DownloadLink>
> = (props) => <AmplifyButton as={DownloadLink} variation="link" {...props} />;
