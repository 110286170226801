/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AuditAttachmentType = {
    StartingLetter: 'starting_letter',
    FinalReport: 'final_report'
} as const;
export type AuditAttachmentType = typeof AuditAttachmentType[keyof typeof AuditAttachmentType];


export function AuditAttachmentTypeFromJSON(json: any): AuditAttachmentType {
    return AuditAttachmentTypeFromJSONTyped(json, false);
}

export function AuditAttachmentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditAttachmentType {
    return json as AuditAttachmentType;
}

export function AuditAttachmentTypeToJSON(value?: AuditAttachmentType | null): any {
    return value as any;
}

