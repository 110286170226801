import React from "react";

import {
  Body,
  BodyContent,
  BodyHeader,
  PageHeaderContainer,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
  Root,
  SiteLogo,
} from "./layout";
import {Button, Text} from "./primatives";

export type ErrorBoundaryProps = {
  pageHeaderAlignItems?: React.ComponentProps<
    typeof PageHeaderContainer
  >["alignItems"];
  pageHeaderRight?: React.ReactNode;
  onError: (error: Error, errorInfo: React.ErrorInfo) => any;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export class LayoutErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error: any): Partial<ErrorBoundaryState> {
    return {hasError: true};
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.props.onError(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Root>
          <PageHeaderContainer alignItems={this.props.pageHeaderAlignItems}>
            <SiteLogo />
            {this.props.pageHeaderRight}
          </PageHeaderContainer>
          <Body>
            <BodyHeader>
              <PrimaryBodyHeaderContainer>
                <PrimaryBodyHeader value="Error" />
              </PrimaryBodyHeaderContainer>
            </BodyHeader>
            <BodyContent alignItems="self-start">
              <Text value="There's been an unexpected error. We'e been notified of the problem. If the error persists, please contact #tina-tech on Slack." />
              <Button
                variation="primary"
                onClick={this.handleReload}
                value="Reload Page"
              />
            </BodyContent>
          </Body>
        </Root>
      );
    }

    return this.props.children;
  }

  private readonly handleReload = () => {
    window.location.reload();
  };
}
