import {
  FormikConnectedAmplifySelectField,
  Text,
  TextRaw,
  VerticalFieldset,
} from "@title-service/ui";
import {enumValues, notNullOrUndefined} from "@title-service/utils";
import {
  buildEnumSchema,
  transformNullToUndefined,
} from "@title-service/yup-utils";
import {useFormikContext} from "formik";
import React, {useEffect, useMemo} from "react";
import * as Yup from "yup";

import {ClaimCauseOfLossType} from "../adminApi";
import {safeDisplayEnumFn} from "../util/enum";

export const unsafeDisplayClaimCauseOfLossType = (
  claimCauseOfLossType: ClaimCauseOfLossType,
): string => {
  switch (claimCauseOfLossType) {
    case ClaimCauseOfLossType.A1:
      return "Fraud";
    case ClaimCauseOfLossType.A2:
      return "Forgery";
    case ClaimCauseOfLossType.A3:
      return "Competency; capacity;authority of parties";
    case ClaimCauseOfLossType.A4:
      return "Undisclosed Heirs";
    case ClaimCauseOfLossType.A5:
      return "Marital Rights";
    case ClaimCauseOfLossType.A6:
      return "Assumed Risks";
    case ClaimCauseOfLossType.A7:
      return "Off-record / Presecriptive Rights";
    case ClaimCauseOfLossType.A8:
      return "Access";
    case ClaimCauseOfLossType.A9:
      return "CC&R Violation";
    case ClaimCauseOfLossType.A10:
      return "Zoning Violation";
    case ClaimCauseOfLossType.A11:
      return "Forced Removal / Encroaching Structure";
    case ClaimCauseOfLossType.A12:
      return "Forced Removal / Encroaching Boundary Wall";
    case ClaimCauseOfLossType.A13:
      return "Forced Removal / Structure on Easement or Set-back";
    case ClaimCauseOfLossType.A14:
      return "Structure Encroachment by Neighbor";
    case ClaimCauseOfLossType.A15:
      return "Boundary Wall Encroachment by Neighbor";
    case ClaimCauseOfLossType.A16:
      return "Incorrectness of Map attached to Policy";
    case ClaimCauseOfLossType.A17:
      return "Assignment of Mortgage";
    case ClaimCauseOfLossType.A18:
      return "Failure re: Street Address";
    case ClaimCauseOfLossType.A19:
      return "Failure re: Residential Improvements";
    case ClaimCauseOfLossType.A20:
      return "Subdivision Law Violation";
    case ClaimCauseOfLossType.A21:
      return "Building Permit Violation";
    case ClaimCauseOfLossType.A22:
      return "Post-policy Encroachment by Another";
    case ClaimCauseOfLossType.A23:
      return "Surface Rights for Mineral/Water/etc. Extraction";
    case ClaimCauseOfLossType.A24:
      return "Post-policy Forgery / Impersonation";
    case ClaimCauseOfLossType.A25:
      return "Modification of Mortgage";
    case ClaimCauseOfLossType.A26:
      return "Easements - Damage from Use or Maintenance";
    case ClaimCauseOfLossType.A27:
      return "Supplemental Taxes";
    case ClaimCauseOfLossType.A28:
      return "Variable Rate Mortgage";
    case ClaimCauseOfLossType.A29:
      return "Negative Amortization Mortgage";
    case ClaimCauseOfLossType.A30:
      return "Other Assumed Risk";
    case ClaimCauseOfLossType.B1A:
      return "Construction Loan";
    case ClaimCauseOfLossType.B1B:
      return "Owners or Permanent Loan Policy";
    case ClaimCauseOfLossType.B2:
      return "Subordination of Prior Interests";
    case ClaimCauseOfLossType.B3:
      return "Affidavit or Indemnity Relied Upon (except for Mechanic's Liens)";
    case ClaimCauseOfLossType.B4A:
      return "Violation of Restrictions and Covenants";
    case ClaimCauseOfLossType.B4B:
      return "Zoning";
    case ClaimCauseOfLossType.B4C:
      return "Mortgages";
    case ClaimCauseOfLossType.B4D:
      return "Non-imputation";
    case ClaimCauseOfLossType.B4E:
      return "Condominium";
    case ClaimCauseOfLossType.B4F:
      return "Usury - Federal Preemption";
    case ClaimCauseOfLossType.B4G:
      return "Usury - Other";
    case ClaimCauseOfLossType.B4H:
      return "Waters - Navigational Servitudes";
    case ClaimCauseOfLossType.B4I:
      return "Waters - Lands once submerged";
    case ClaimCauseOfLossType.B4J:
      return "Doing Business";
    case ClaimCauseOfLossType.B4K:
      return "Truth-in-lending";
    case ClaimCauseOfLossType.B4L:
      return "Access / Easements Insured";
    case ClaimCauseOfLossType.B4M:
      return "Air Rights Insured";
    case ClaimCauseOfLossType.B4N:
      return "Creditors' Rights / Bankruptcy";
    case ClaimCauseOfLossType.B4O:
      return "Environmental Liens";
    case ClaimCauseOfLossType.B4P:
      return "Vacated Streets and Alleys Insured";
    case ClaimCauseOfLossType.B4Q:
      return "Assertions of possessory rights";
    case ClaimCauseOfLossType.B4R:
      return "Assertions of possessory rights";
    case ClaimCauseOfLossType.B4S:
      return "Assignment of Mortgage";
    case ClaimCauseOfLossType.B4T:
      return "Surface Rights for Mineral Extraction";
    case ClaimCauseOfLossType.B4U:
      return "Existing Improvements";
    case ClaimCauseOfLossType.B4V:
      return "Modification of Mortgage";
    case ClaimCauseOfLossType.B4W:
      return "Easements - Damage from Use or Maintenance";
    case ClaimCauseOfLossType.B4X:
      return "Optional Advance";
    case ClaimCauseOfLossType.B4Y:
      return "Revolving Line of Credit";
    case ClaimCauseOfLossType.B4Z:
      return "Variable Rate Mortgage";
    case ClaimCauseOfLossType.B4Aa:
      return "Negative Amortization Mortgage";
    case ClaimCauseOfLossType.B4Bb:
      return "Other Endorsement";
    case ClaimCauseOfLossType.C1:
      return "Take-off of Public Records";
    case ClaimCauseOfLossType.C2:
      return "Posting";
    case ClaimCauseOfLossType.C3:
      return "Searching Irregularity";
    case ClaimCauseOfLossType.C4:
      return "Abstracting Irregularity";
    case ClaimCauseOfLossType.C5:
      return "Other";
    case ClaimCauseOfLossType.D1:
      return "Unforseen Risk";
    case ClaimCauseOfLossType.D2:
      return "Irregular Omission";
    case ClaimCauseOfLossType.D3:
      return "Failure to Follow Established Procedures and Policies";
    case ClaimCauseOfLossType.E1:
      return "Incorrect Survey or Inspection";
    case ClaimCauseOfLossType.E2:
      return "Incorrect Description Used or Furnished";
    case ClaimCauseOfLossType.F1:
      return "Insufficient or Improper Instructions";
    case ClaimCauseOfLossType.F2:
      return "Instructions not Followed";
    case ClaimCauseOfLossType.F3:
      return "Improper Payment or Failure to Make Payment";
    case ClaimCauseOfLossType.F4:
      return "Closing Protection Letter";
    case ClaimCauseOfLossType.F5:
      return "Failure to Complete Post-closing Responsibilities";
    case ClaimCauseOfLossType.F6:
      return "Other";
    case ClaimCauseOfLossType.G:
      return "Typing or Policy Review";
    case ClaimCauseOfLossType.H:
      return "Taxes and Special Assessments";
    case ClaimCauseOfLossType.I1:
      return "Claim Outside Insuring Provisions";
    case ClaimCauseOfLossType.I2:
      return "Claim within Pre-printed Exclusion or Exception";
    case ClaimCauseOfLossType.I3:
      return "Claim within Special Exception";
    case ClaimCauseOfLossType.I4:
      return "Other";
    case ClaimCauseOfLossType.J:
      return "Stakeholder / Interpleader Cases";
    case ClaimCauseOfLossType.K1:
      return "Foreclosure";
    case ClaimCauseOfLossType.K2:
      return "Government Forfeiture";
    case ClaimCauseOfLossType.K3:
      return "Other";
    default:
      throw new Error(`Unknown Cause of Loss type: ${claimCauseOfLossType}`);
  }
};

export const displayClaimCauseOfLossType = safeDisplayEnumFn(
  unsafeDisplayClaimCauseOfLossType,
);

export enum Section {
  A1 = "A.1",
  A2 = "A.2",
  A3 = "A.3",
  A4 = "A.4",
  A5 = "A.5",
  A6 = "A.6",
  A7 = "A.7",
  A8 = "A.8",
  A9 = "A.9",
  A10 = "A.10",
  A11 = "A.11",
  A12 = "A.12",
  A13 = "A.13",
  A14 = "A.14",
  A15 = "A.15",
  A16 = "A.16",
  A17 = "A.17",
  A18 = "A.18",
  A19 = "A.19",
  A20 = "A.20",
  A21 = "A.21",
  A22 = "A.22",
  A23 = "A.23",
  A24 = "A.24",
  A25 = "A.25",
  A26 = "A.26",
  A27 = "A.27",
  A28 = "A.28",
  A29 = "A.29",
  A30 = "A.30",
  B1 = "B.1",
  B2 = "B.2",
  B3 = "B.3",
  B4 = "B.4",
  C1 = "C.1",
  C2 = "C.2",
  C3 = "C.3",
  C4 = "C.4",
  C5 = "C.5",
  C6 = "C.6",
  C7 = "C.7",
  C8 = "C.8",
  C9 = "C.9",
}

export const unsafeDisplaySection = (section: Section): string => {
  switch (section) {
    case Section.A1:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A1);
    case Section.A2:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A2);
    case Section.A3:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A3);
    case Section.A4:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A4);
    case Section.A5:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A5);
    case Section.A6:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A6);
    case Section.A7:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A7);
    case Section.A8:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A8);
    case Section.A9:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A9);
    case Section.A10:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A10);
    case Section.A11:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A11);
    case Section.A12:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A12);
    case Section.A13:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A13);
    case Section.A14:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A14);
    case Section.A15:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A15);
    case Section.A16:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A16);
    case Section.A17:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A17);
    case Section.A18:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A18);
    case Section.A19:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A19);
    case Section.A20:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A20);
    case Section.A21:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A21);
    case Section.A22:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A22);
    case Section.A23:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A23);
    case Section.A24:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A24);
    case Section.A25:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A25);
    case Section.A26:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A26);
    case Section.A27:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A27);
    case Section.A28:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A28);
    case Section.A29:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A29);
    case Section.A30:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.A30);
    case Section.B1:
      return "Mechanic's Lien";
    case Section.B2:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.B2);
    case Section.B3:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.B3);
    case Section.B4:
      return "Underwritten Risks";
    case Section.C1:
      return "Plant";
    case Section.C2:
      return "Examination and Opinion Irregularities";
    case Section.C3:
      return "Survey - Inspection / Description Matters";
    case Section.C4:
      return "Escrow / Closing Matters";
    case Section.C5:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.G);
    case Section.C6:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.H);
    case Section.C7:
      return "Apparent Non-covered Claims";
    case Section.C8:
      return unsafeDisplayClaimCauseOfLossType(ClaimCauseOfLossType.J);
    case Section.C9:
      return "Disputed Procedure (Judicial / Non-Judicial)";
    default:
      throw new Error(`Unknown Section: ${section}`);
  }
};

export const displaySection = safeDisplayEnumFn(unsafeDisplaySection);

export enum RiskType {
  A = "A",
  B = "B",
  C = "C",
}

export const unsafeDisplayRiskType = (riskType: RiskType): string => {
  switch (riskType) {
    case RiskType.A:
      return "Basic Risk";
    case RiskType.B:
      return "Special Risks";
    case RiskType.C:
      return "Process Risk";
    default:
      throw new Error(`Unknown Risk Type: ${riskType}`);
  }
};

export const displayRiskType = safeDisplayEnumFn(unsafeDisplayRiskType);

export const CAUSE_OF_LOSS_FORM_HIERARCHY: Map<
  RiskType,
  Map<Section, ClaimCauseOfLossType[]>
> = new Map<RiskType, Map<Section, ClaimCauseOfLossType[]>>([
  [
    RiskType.A,
    new Map<Section, ClaimCauseOfLossType[]>([
      [Section.A1, [ClaimCauseOfLossType.A1]],
      [Section.A2, [ClaimCauseOfLossType.A2]],
      [Section.A3, [ClaimCauseOfLossType.A3]],
      [Section.A4, [ClaimCauseOfLossType.A4]],
      [Section.A5, [ClaimCauseOfLossType.A5]],
      [Section.A6, [ClaimCauseOfLossType.A6]],
      [Section.A7, [ClaimCauseOfLossType.A7]],
      [Section.A8, [ClaimCauseOfLossType.A8]],
      [Section.A9, [ClaimCauseOfLossType.A9]],
      [Section.A10, [ClaimCauseOfLossType.A10]],
      [Section.A11, [ClaimCauseOfLossType.A11]],
      [Section.A12, [ClaimCauseOfLossType.A12]],
      [Section.A13, [ClaimCauseOfLossType.A13]],
      [Section.A14, [ClaimCauseOfLossType.A14]],
      [Section.A15, [ClaimCauseOfLossType.A15]],
      [Section.A16, [ClaimCauseOfLossType.A16]],
      [Section.A17, [ClaimCauseOfLossType.A17]],
      [Section.A18, [ClaimCauseOfLossType.A18]],
      [Section.A19, [ClaimCauseOfLossType.A19]],
      [Section.A20, [ClaimCauseOfLossType.A20]],
      [Section.A21, [ClaimCauseOfLossType.A21]],
      [Section.A22, [ClaimCauseOfLossType.A22]],
      [Section.A23, [ClaimCauseOfLossType.A23]],
      [Section.A24, [ClaimCauseOfLossType.A24]],
      [Section.A25, [ClaimCauseOfLossType.A25]],
      [Section.A26, [ClaimCauseOfLossType.A26]],
      [Section.A27, [ClaimCauseOfLossType.A27]],
      [Section.A28, [ClaimCauseOfLossType.A28]],
      [Section.A29, [ClaimCauseOfLossType.A29]],
      [Section.A30, [ClaimCauseOfLossType.A30]],
    ]),
  ],
  [
    RiskType.B,
    new Map<Section, ClaimCauseOfLossType[]>([
      [Section.B1, [ClaimCauseOfLossType.B1A, ClaimCauseOfLossType.B1B]],
      [Section.B2, [ClaimCauseOfLossType.B2]],
      [Section.B3, [ClaimCauseOfLossType.B3]],
      [
        Section.B4,
        [
          ClaimCauseOfLossType.B4A,
          ClaimCauseOfLossType.B4B,
          ClaimCauseOfLossType.B4C,
          ClaimCauseOfLossType.B4D,
          ClaimCauseOfLossType.B4E,
          ClaimCauseOfLossType.B4F,
          ClaimCauseOfLossType.B4G,
          ClaimCauseOfLossType.B4H,
          ClaimCauseOfLossType.B4I,
          ClaimCauseOfLossType.B4J,
          ClaimCauseOfLossType.B4K,
          ClaimCauseOfLossType.B4L,
          ClaimCauseOfLossType.B4M,
          ClaimCauseOfLossType.B4N,
          ClaimCauseOfLossType.B4O,
          ClaimCauseOfLossType.B4P,
          ClaimCauseOfLossType.B4Q,
          ClaimCauseOfLossType.B4R,
          ClaimCauseOfLossType.B4S,
          ClaimCauseOfLossType.B4T,
          ClaimCauseOfLossType.B4U,
          ClaimCauseOfLossType.B4V,
          ClaimCauseOfLossType.B4W,
          ClaimCauseOfLossType.B4X,
          ClaimCauseOfLossType.B4Y,
          ClaimCauseOfLossType.B4Z,
          ClaimCauseOfLossType.B4Aa,
          ClaimCauseOfLossType.B4Bb,
        ],
      ],
    ]),
  ],
  [
    RiskType.C,
    new Map<Section, ClaimCauseOfLossType[]>([
      [
        Section.C1,
        [
          ClaimCauseOfLossType.C1,
          ClaimCauseOfLossType.C2,
          ClaimCauseOfLossType.C3,
          ClaimCauseOfLossType.C4,
          ClaimCauseOfLossType.C5,
        ],
      ],
      [
        Section.C2,
        [
          ClaimCauseOfLossType.D1,
          ClaimCauseOfLossType.D2,
          ClaimCauseOfLossType.D3,
        ],
      ],
      [Section.C3, [ClaimCauseOfLossType.E1, ClaimCauseOfLossType.E2]],
      [
        Section.C4,
        [
          ClaimCauseOfLossType.F1,
          ClaimCauseOfLossType.F2,
          ClaimCauseOfLossType.F3,
          ClaimCauseOfLossType.F4,
          ClaimCauseOfLossType.F5,
          ClaimCauseOfLossType.F6,
        ],
      ],
      [Section.C5, [ClaimCauseOfLossType.G]],
      [Section.C6, [ClaimCauseOfLossType.H]],
      [
        Section.C7,
        [
          ClaimCauseOfLossType.I1,
          ClaimCauseOfLossType.I2,
          ClaimCauseOfLossType.I3,
          ClaimCauseOfLossType.I4,
        ],
      ],
      [Section.C8, [ClaimCauseOfLossType.J]],
      [
        Section.C9,
        [
          ClaimCauseOfLossType.K1,
          ClaimCauseOfLossType.K2,
          ClaimCauseOfLossType.K3,
        ],
      ],
    ]),
  ],
]);

const findHierarchyParents = (
  causeOfLoss: ClaimCauseOfLossType | undefined,
): [RiskType | undefined, Section | undefined] => {
  if (causeOfLoss) {
    for (const [riskType, sections] of CAUSE_OF_LOSS_FORM_HIERARCHY) {
      for (const [section, causeOfLossTypes] of sections) {
        if (causeOfLossTypes.includes(causeOfLoss)) {
          return [riskType, section];
        }
      }
    }
  }
  return [undefined, undefined];
};

export const CauseOfLossComponentRaw: React.FC<{
  causeOfLoss: ClaimCauseOfLossType;
}> = ({causeOfLoss}) => {
  const [riskType, section] = findHierarchyParents(causeOfLoss);
  const riskTypeDisplay = riskType && displayRiskType(riskType);
  const sectionDisplay = section && displaySection(section);
  const causeOfLossTypeDisplay = displayClaimCauseOfLossType(causeOfLoss);
  return (
    <>
      <TextRaw value={riskTypeDisplay} />
      <TextRaw value=" - " />
      {sectionDisplay === causeOfLossTypeDisplay ? (
        <TextRaw value={causeOfLossTypeDisplay} />
      ) : (
        <>
          <TextRaw value={sectionDisplay} />
          <TextRaw value=" - " />
          <TextRaw value={causeOfLossTypeDisplay} />
        </>
      )}
    </>
  );
};

export const CauseOfLossComponent: React.FC<
  Omit<React.ComponentProps<typeof Text>, "value"> & {
    causeOfLoss: ClaimCauseOfLossType;
  }
> = ({causeOfLoss, ...props}) => (
  <Text
    {...props}
    value={<CauseOfLossComponentRaw causeOfLoss={causeOfLoss} />}
  />
);

export type CauseOfLossFormData = {
  riskType: string;
  section: string;
  code: string;
};

export type CompleteCauseOfLossFormData =
  | {
      riskType: RiskType;
      section: Section;
      code: ClaimCauseOfLossType;
    }
  | {
      riskType: undefined;
      section: undefined;
      code: undefined;
    };

export const RISK_TYPE_SCHEMA: Yup.StringSchema<RiskType | undefined> =
  buildEnumSchema(RiskType, "Must be a valid Risk Type");

const findSectionsForRiskType = (riskType: RiskType): Section[] | undefined => {
  const sectionMapForRiskType = CAUSE_OF_LOSS_FORM_HIERARCHY.get(riskType);
  return sectionMapForRiskType && Array.from(sectionMapForRiskType.keys());
};

const SECTION_SCHEMA: Yup.StringSchema<Section | undefined> = Yup.string()
  .optional()
  .trim()
  .transform(transformNullToUndefined)
  .when("riskType", ([riskType], builder) => {
    if (notNullOrUndefined(riskType)) {
      builder = builder.required("Must select a Section");
      const sectionsForRiskType = findSectionsForRiskType(riskType as RiskType);
      if (notNullOrUndefined(sectionsForRiskType)) {
        return builder.oneOf(sectionsForRiskType, "Must be a valid Section");
      }
    }

    return builder.transform(() => undefined);
  }) as Yup.StringSchema<Section | undefined>;

const findClaimCauseOfLossTypeForRiskTypeAndSection = (
  riskType: RiskType,
  section: Section,
): ClaimCauseOfLossType[] | undefined => {
  const sectionMapForRiskType = CAUSE_OF_LOSS_FORM_HIERARCHY.get(riskType);
  if (!sectionMapForRiskType) {
    return undefined;
  }
  const causeOfLossCodesForSection = sectionMapForRiskType.get(section);
  if (!causeOfLossCodesForSection) {
    return undefined;
  }
  return causeOfLossCodesForSection;
};

const CODE_SCHEMA: Yup.StringSchema<ClaimCauseOfLossType | undefined> =
  Yup.string()
    .optional()
    .trim()
    .transform(transformNullToUndefined)
    .when(["riskType", "section"], ([riskType, section], builder) => {
      if (notNullOrUndefined(riskType) && notNullOrUndefined(section)) {
        builder = builder.required("Must select a Cause of Loss type");
        const causeOfLossCodes = findClaimCauseOfLossTypeForRiskTypeAndSection(
          riskType as RiskType,
          section as Section,
        );
        if (notNullOrUndefined(causeOfLossCodes)) {
          return builder.oneOf(
            causeOfLossCodes,
            "Must be a valid Cause of Loss type",
          );
        }
      }

      return builder.transform(() => undefined);
    }) as Yup.StringSchema<ClaimCauseOfLossType | undefined>;

export const CAUSE_OF_LOSS_FORM_SCHEMA = Yup.object({
  riskType: RISK_TYPE_SCHEMA,
  section: SECTION_SCHEMA,
  code: CODE_SCHEMA,
}) as Yup.ObjectSchema<CompleteCauseOfLossFormData>;

export const buildInitialCauseOfLossFormData = (
  causeOfLoss: ClaimCauseOfLossType | undefined,
): CauseOfLossFormData => {
  const [riskType, section] = findHierarchyParents(causeOfLoss);
  return {
    riskType: riskType ?? "",
    section: section ?? "",
    code: (riskType && causeOfLoss) ?? "",
  };
};

export const mapCompleteCauseOfLossFormDataToCauseOfLossCode = ({
  code,
}: CompleteCauseOfLossFormData): ClaimCauseOfLossType | undefined => code;

export const CauseOfLossField: React.FC<
  React.ComponentProps<typeof VerticalFieldset> & {propertyName?: string}
> = ({propertyName, ...props}) => {
  const propertyNamePrefix = useMemo(
    () => (propertyName ? `${propertyName}.` : ``),
    [propertyName],
  );
  const riskTypeFieldName = useMemo(
    () => `${propertyNamePrefix}riskType`,
    [propertyNamePrefix],
  );
  const sectionFieldName = useMemo(
    () => `${propertyNamePrefix}section`,
    [propertyNamePrefix],
  );
  const codeFieldName = useMemo(
    () => `${propertyNamePrefix}code`,
    [propertyNamePrefix],
  );
  const {getFieldMeta, setFieldValue} = useFormikContext();
  const riskTypeFieldMetadata = getFieldMeta<RiskType>(riskTypeFieldName);
  const selectedRiskType = riskTypeFieldMetadata.value;
  const sectionsForRiskType = findSectionsForRiskType(selectedRiskType);
  const sectionFieldMetadata = getFieldMeta<Section>(sectionFieldName);
  const selectedSection = sectionFieldMetadata.value;
  const causeOfLossCodes = findClaimCauseOfLossTypeForRiskTypeAndSection(
    selectedRiskType,
    selectedSection,
  );
  const codeFieldMetadata = getFieldMeta<ClaimCauseOfLossType | undefined>(
    codeFieldName,
  );
  const selectedCode = codeFieldMetadata.value;
  useEffect(() => {
    if (!selectedCode && causeOfLossCodes && causeOfLossCodes.length === 1) {
      setFieldValue(codeFieldName, causeOfLossCodes[0]);
    }
  }, [causeOfLossCodes, codeFieldName, selectedCode, setFieldValue]);
  return (
    <VerticalFieldset gap="small" {...props}>
      <FormikConnectedAmplifySelectField
        testId="cause-of-loss-risk-type-input"
        label="Cause of Loss (optional)"
        placeholder=""
        name={riskTypeFieldName}
      >
        <option value="" />
        {enumValues(RiskType).map((riskType) => (
          <option key={riskType} value={riskType}>
            {displayRiskType(riskType)}
          </option>
        ))}
      </FormikConnectedAmplifySelectField>
      {sectionsForRiskType && sectionsForRiskType.length > 1 ? (
        <FormikConnectedAmplifySelectField
          testId="cause-of-loss-section-input"
          label="Cause of Loss Section"
          labelHidden={true}
          placeholder=""
          name={sectionFieldName}
        >
          <option value="" />
          {sectionsForRiskType.map((section) => (
            <option key={section} value={section}>
              {displaySection(section)}
            </option>
          ))}
        </FormikConnectedAmplifySelectField>
      ) : null}
      {causeOfLossCodes && causeOfLossCodes.length > 1 ? (
        <FormikConnectedAmplifySelectField
          testId="cause-of-loss-code-input"
          label="Cause of Loss Code"
          labelHidden={true}
          placeholder=""
          name={codeFieldName}
        >
          <option value="" />
          {causeOfLossCodes.map((causeOfLoss) => (
            <option key={causeOfLoss} value={causeOfLoss}>
              {displayClaimCauseOfLossType(causeOfLoss)}
            </option>
          ))}
        </FormikConnectedAmplifySelectField>
      ) : null}
    </VerticalFieldset>
  );
};
