/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetAgencyProducersSuccessResponseProducer } from './GetAgencyProducersSuccessResponseProducer';
import {
    GetAgencyProducersSuccessResponseProducerFromJSON,
    GetAgencyProducersSuccessResponseProducerFromJSONTyped,
    GetAgencyProducersSuccessResponseProducerToJSON,
} from './GetAgencyProducersSuccessResponseProducer';
import type { GetIndividualProducersSuccessResponseProducer } from './GetIndividualProducersSuccessResponseProducer';
import {
    GetIndividualProducersSuccessResponseProducerFromJSON,
    GetIndividualProducersSuccessResponseProducerFromJSONTyped,
    GetIndividualProducersSuccessResponseProducerToJSON,
} from './GetIndividualProducersSuccessResponseProducer';

/**
 * 
 * @export
 * @interface GetParentAgenciesSuccessResponseParentAgency
 */
export interface GetParentAgenciesSuccessResponseParentAgency {
    /**
     * 
     * @type {Array<GetAgencyProducersSuccessResponseProducer>}
     * @memberof GetParentAgenciesSuccessResponseParentAgency
     */
    agencyProducers: Array<GetAgencyProducersSuccessResponseProducer>;
    /**
     * 
     * @type {Date}
     * @memberof GetParentAgenciesSuccessResponseParentAgency
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof GetParentAgenciesSuccessResponseParentAgency
     */
    id: string;
    /**
     * 
     * @type {Array<GetIndividualProducersSuccessResponseProducer>}
     * @memberof GetParentAgenciesSuccessResponseParentAgency
     */
    individualProducers: Array<GetIndividualProducersSuccessResponseProducer>;
    /**
     * 
     * @type {string}
     * @memberof GetParentAgenciesSuccessResponseParentAgency
     */
    name: string;
}

/**
 * Check if a given object implements the GetParentAgenciesSuccessResponseParentAgency interface.
 */
export function instanceOfGetParentAgenciesSuccessResponseParentAgency(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "agencyProducers" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "individualProducers" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function GetParentAgenciesSuccessResponseParentAgencyFromJSON(json: any): GetParentAgenciesSuccessResponseParentAgency {
    return GetParentAgenciesSuccessResponseParentAgencyFromJSONTyped(json, false);
}

export function GetParentAgenciesSuccessResponseParentAgencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetParentAgenciesSuccessResponseParentAgency {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agencyProducers': ((json['agencyProducers'] as Array<any>).map(GetAgencyProducersSuccessResponseProducerFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
        'id': json['id'],
        'individualProducers': ((json['individualProducers'] as Array<any>).map(GetIndividualProducersSuccessResponseProducerFromJSON)),
        'name': json['name'],
    };
}

export function GetParentAgenciesSuccessResponseParentAgencyToJSON(value?: GetParentAgenciesSuccessResponseParentAgency | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agencyProducers': ((value.agencyProducers as Array<any>).map(GetAgencyProducersSuccessResponseProducerToJSON)),
        'createdAt': (value.createdAt.toISOString()),
        'id': value.id,
        'individualProducers': ((value.individualProducers as Array<any>).map(GetIndividualProducersSuccessResponseProducerToJSON)),
        'name': value.name,
    };
}

