/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GetOrdersSortField = {
    Created: 'CREATED'
} as const;
export type GetOrdersSortField = typeof GetOrdersSortField[keyof typeof GetOrdersSortField];


export function GetOrdersSortFieldFromJSON(json: any): GetOrdersSortField {
    return GetOrdersSortFieldFromJSONTyped(json, false);
}

export function GetOrdersSortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrdersSortField {
    return json as GetOrdersSortField;
}

export function GetOrdersSortFieldToJSON(value?: GetOrdersSortField | null): any {
    return value as any;
}

