import {FileUploader, SelectField} from "@aws-amplify/ui-react";
import {
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {Query} from "@title-service/react-query-utils";
import {
  DateComponent,
  DateTimeComponent,
  FormikConnectedAmplifySubmitButton,
  FormikForm,
  FormResultErrorMessage,
  Text,
  VerticalFieldset,
} from "@title-service/ui";
import {enumValues, stringIsNotBlank} from "@title-service/utils";
import {buildEmptyObjectForSchema} from "@title-service/yup-utils";
import {Formik, FormikHelpers} from "formik";
import React, {useCallback, useContext, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import * as Yup from "yup";

import {DependencyContext} from "../DependencyContext";
import {
  AgencyProducerReference,
  AuditAttachmentType,
  GetAuditAttachmentsSuccessResponse,
  GetAuditAttachmentsSuccessResponseAttachment,
  GetAuditSuccessResponse,
  UpdateAuditRequest,
} from "../shared/adminApi";
import {displayAuditAttachmentType} from "../shared/audit/AuditAttachmentType";
import {
  AGENCY_REFERENCE_ID_SCHEMA,
  AgencyReferenceIdField,
  AUDIT_SCOPE_SCHEMA,
  AuditScopeField,
  COMPLETED_ON_SCHEMA,
  CompletedOnField,
  DESCRIPTION_SCHEMA,
  DescriptionField,
  DUE_ON_SCHEMA,
  DueOnField,
  STARTING_LETTER_SENT_ON_SCHEMA,
  StartingLetterSentOnField,
  STATE_SCHEMA,
  StateField,
  TARGET_COMPLETE_ON_SCHEMA,
  TARGET_START_ON_SCHEMA,
  TargetCompleteOnField,
  TargetStartOnField,
} from "../shared/audit/AuditDetailFormFields";
import {AuditStatusPill} from "../shared/audit/AuditStatusPill";
import {
  DataGrid,
  GridItem,
  GridItemLabel,
  GridItemLabelContainer,
} from "../shared/components/DataGrid";
import {DownloadLink} from "../shared/components/Link";
import {Modal} from "../shared/components/Modal";
import {MaybeNotAvailable} from "../shared/components/Nullable";
import {EditIcon, PlusIcon} from "../shared/components/icons";
import {
  BodyRow as SecondaryBodyRow,
  HeaderRow as SecondaryHeaderRow,
  Table as SecondaryTable,
  TBody as SecondaryTBody,
  Td as SecondaryTd,
  Th as SecondaryTh,
  THead as SecondaryTHead,
} from "../shared/components/tables/SecondaryTable";
import {
  BodyContent,
  BodyHeader,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
  PrimarySection,
  SecondarySection,
  SecondarySectionContent,
  SecondarySectionHeader,
  SecondarySectionHeaderContainer,
} from "../shared/layout";
import {
  AgencyProducerField,
  AgencyProducerFormData,
  buildAgencyProducerFormInitialData,
  buildRequiredAgencyProducerFormSchema,
} from "../shared/producers/AgencyProducerField";
import {
  AmplifyStorageAccessLevel,
  getFullObjectKey,
} from "../shared/util/storage";

export const AuditDetailRoute: React.FC = () => {
  const {auditId} = useParams<
    keyof AuditDetailPageParams
  >() as AuditDetailPageParams;

  return <AuditDetailPage auditId={auditId} />;
};

type GetAuditQueryKey = ["audits", string, "detail"];
type GetAuditQuery = Query<GetAuditSuccessResponse>;

const useGetAudit = (
  auditId: string,
): {
  getAuditQuery: GetAuditQuery;
  onReloadAudit: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);

  const getAuditQueryKey: GetAuditQueryKey = useMemo(
    () => ["audits", auditId, "detail"],
    [auditId],
  );
  const getAuditQueryFn: QueryFunction<
    GetAuditSuccessResponse,
    GetAuditQueryKey
  > = useCallback(
    ({queryKey: [_, auditId_], signal}) =>
      adminApi.getAudit({auditId: auditId_}, signal),
    [adminApi],
  );
  const getAuditQuery = useQuery({
    queryKey: getAuditQueryKey,
    queryFn: getAuditQueryFn,
  });
  const onReloadAudit = useCallback(
    () => queryClient.invalidateQueries({queryKey: getAuditQueryKey}),
    [queryClient, getAuditQueryKey],
  );
  return {getAuditQuery, onReloadAudit};
};

type GetAuditAttachmentsQueryKey = ["audits", string, "attachments"];
type GetAuditAttachmentsQuery = Query<GetAuditAttachmentsSuccessResponse>;

const useGetAuditAttachments = (
  auditId: string,
): {
  getAuditAttachmentsQuery: GetAuditAttachmentsQuery;
  onReloadAuditAttachments: () => any;
} => {
  const queryClient = useQueryClient();
  const {adminApi} = useContext(DependencyContext);
  const getAuditAttachmentsQueryKey: GetAuditAttachmentsQueryKey = useMemo(
    () => ["audits", auditId, "attachments"],
    [auditId],
  );
  const getAuditAttachmentsQueryFn: QueryFunction<
    GetAuditAttachmentsSuccessResponse,
    GetAuditAttachmentsQueryKey
  > = useCallback(
    ({queryKey: [_, auditId_], signal}) =>
      adminApi.getAuditAttachments({auditId: auditId_}, signal),
    [adminApi],
  );
  const getAuditAttachmentsQuery = useQuery({
    queryKey: getAuditAttachmentsQueryKey,
    queryFn: getAuditAttachmentsQueryFn,
  });
  const onReloadAuditAttachments = useCallback(
    () =>
      queryClient.invalidateQueries({queryKey: getAuditAttachmentsQueryKey}),
    [queryClient, getAuditAttachmentsQueryKey],
  );
  return {getAuditAttachmentsQuery, onReloadAuditAttachments};
};

type AuditDetailPageParams = {
  auditId: string;
};

export const AuditDetailPage: React.FC<
  AuditDetailPageParams & {
    AuditDetailQueryResult?: typeof AuditDetailQueryResult;
  }
> = ({
  auditId,
  AuditDetailQueryResult: AuditDetailQueryResult_ = AuditDetailQueryResult,
}) => {
  const {getAuditQuery, onReloadAudit} = useGetAudit(auditId);
  const {getAuditAttachmentsQuery, onReloadAuditAttachments} =
    useGetAuditAttachments(auditId);

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <AuditDetailQueryResult_
      auditId={auditId}
      getAuditQuery={getAuditQuery}
      getAuditAttachmentsQuery={getAuditAttachmentsQuery}
      onReloadAudit={onReloadAudit}
      onReloadAuditAttachments={onReloadAuditAttachments}
    />
  );
};

export const AuditDetailQueryResult: React.FC<{
  auditId: string;
  getAuditQuery: GetAuditQuery;
  getAuditAttachmentsQuery: GetAuditAttachmentsQuery;
  onReloadAudit: () => any;
  onReloadAuditAttachments: () => any;
  AuditDetailSuccessComponent?: typeof AuditDetailSuccessComponent;
  AuditDetailFailureComponent?: typeof AuditDetailFailureComponent;
  AuditDetailLoadingComponent?: typeof AuditDetailLoadingComponent;
}> = ({
  auditId,
  getAuditQuery: {data: getAuditQueryData, error: getAuditQueryError},
  getAuditAttachmentsQuery,
  onReloadAudit,
  onReloadAuditAttachments,
  AuditDetailSuccessComponent:
    AuditDetailSuccessComponent_ = AuditDetailSuccessComponent,
  AuditDetailFailureComponent:
    AuditDetailFailureComponent_ = AuditDetailFailureComponent,
  AuditDetailLoadingComponent:
    AuditDetailLoadingComponent_ = AuditDetailLoadingComponent,
}) => {
  if (getAuditQueryData) {
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <AuditDetailSuccessComponent_
        onReloadAudit={onReloadAudit}
        onReloadAuditAttachments={onReloadAuditAttachments}
        getAuditResponse={getAuditQueryData}
        getAuditAttachmentsQuery={getAuditAttachmentsQuery}
      />
    );
  } else if (getAuditQueryError) {
    // eslint-disable-next-line react/jsx-pascal-case
    return <AuditDetailFailureComponent_ auditId={auditId} />;
  }
  // eslint-disable-next-line react/jsx-pascal-case
  return <AuditDetailLoadingComponent_ auditId={auditId} />;
};

export const AuditDetailLoadingComponent: React.FC<{auditId: string}> = ({
  auditId,
}) => (
  <BodyContent>
    <span>Loading Audit {auditId}...</span>
  </BodyContent>
);

export const AuditDetailFailureComponent: React.FC<{auditId: string}> = ({
  auditId,
}) => (
  <BodyContent>
    <span>Failed to load Audit {auditId}.</span>
  </BodyContent>
);

export const MODAL_CONTENT_MIN_WIDTH = "750px";

const MODAL_CONTENT_STYLE: React.CSSProperties = {
  minWidth: MODAL_CONTENT_MIN_WIDTH,
};

export const AuditDetailSuccessComponent: React.FC<{
  onReloadAudit: () => any;
  onReloadAuditAttachments: () => any;
  getAuditResponse: GetAuditSuccessResponse;
  getAuditAttachmentsQuery: GetAuditAttachmentsQuery;
}> = ({
  onReloadAudit,
  onReloadAuditAttachments,
  getAuditResponse,
  getAuditAttachmentsQuery,
  getAuditResponse: {
    id,
    agency: {name: agencyName},
    agencyReferenceId,
    status,
    targetStartOn,
    targetCompleteOn,
    startingLetterSentOn,
    dueOn,
    state,
    completedOn,
    auditScope,
    description,
    createdAt,
    updatedAt,
  },
}) => {
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);
  return (
    <>
      <BodyHeader>
        <PrimaryBodyHeaderContainer>
          <PrimaryBodyHeader value={`Audit ${id}`} />
        </PrimaryBodyHeaderContainer>
      </BodyHeader>
      <BodyContent>
        <PrimarySection>
          <SecondarySection>
            <SecondarySectionHeaderContainer>
              <SecondarySectionHeader value="Audit Details" />
              <EditIcon onClick={openForm} />
            </SecondarySectionHeaderContainer>

            <SecondarySectionContent>
              <DataGrid>
                <GridItem>
                  <GridItemLabelContainer>
                    <GridItemLabel value="Audit Status" />
                  </GridItemLabelContainer>
                  <AuditStatusPill status={status} />
                </GridItem>
                <GridItem>
                  <GridItemLabelContainer>
                    <GridItemLabel value="Agency" />
                  </GridItemLabelContainer>
                  <Text value={agencyName} />
                </GridItem>
                <GridItem>
                  <GridItemLabelContainer>
                    <GridItemLabel value="External Agency ID" />
                  </GridItemLabelContainer>
                  <MaybeNotAvailable value={agencyReferenceId}>
                    {(agencyReferenceId_) => (
                      <Text value={agencyReferenceId_} />
                    )}
                  </MaybeNotAvailable>
                </GridItem>
                <GridItem>
                  <GridItemLabelContainer>
                    <GridItemLabel value="Target Start Date" />
                  </GridItemLabelContainer>
                  <DateComponent value={targetStartOn} />
                </GridItem>
                <GridItem>
                  <GridItemLabelContainer>
                    <GridItemLabel value="Target Completion Date" />
                  </GridItemLabelContainer>
                  <DateComponent value={targetCompleteOn} />
                </GridItem>
                <GridItem>
                  <GridItemLabelContainer>
                    <GridItemLabel value="Starting Letter Sent Date" />
                  </GridItemLabelContainer>
                  <MaybeNotAvailable value={startingLetterSentOn}>
                    {(startingLetterSentOn_) => (
                      <DateComponent value={startingLetterSentOn_} />
                    )}
                  </MaybeNotAvailable>
                </GridItem>
                <GridItem>
                  <GridItemLabelContainer>
                    <GridItemLabel value="Due Date" />
                  </GridItemLabelContainer>
                  <MaybeNotAvailable value={dueOn}>
                    {(dueOn_) => <DateComponent value={dueOn_} />}
                  </MaybeNotAvailable>
                </GridItem>
                <GridItem>
                  <GridItemLabelContainer>
                    <GridItemLabel value="State" />
                  </GridItemLabelContainer>
                  <MaybeNotAvailable value={state}>
                    {(state_) => <Text value={state_} />}
                  </MaybeNotAvailable>
                </GridItem>
                <GridItem>
                  <GridItemLabelContainer>
                    <GridItemLabel value="Completion Date" />
                  </GridItemLabelContainer>
                  <MaybeNotAvailable value={completedOn}>
                    {(completedOn_) => <DateComponent value={completedOn_} />}
                  </MaybeNotAvailable>
                </GridItem>
                <GridItem>
                  <GridItemLabelContainer>
                    <GridItemLabel value="Audit Scope" />
                  </GridItemLabelContainer>
                  <MaybeNotAvailable value={auditScope}>
                    {(auditScope_) => <Text value={auditScope_} />}
                  </MaybeNotAvailable>
                </GridItem>
                <GridItem>
                  <GridItemLabelContainer>
                    <GridItemLabel value="Description" />
                  </GridItemLabelContainer>
                  <MaybeNotAvailable value={description}>
                    {(description_) => <Text value={description_} />}
                  </MaybeNotAvailable>
                </GridItem>
                <GridItem>
                  <GridItemLabelContainer>
                    <GridItemLabel value="Created Time" />
                  </GridItemLabelContainer>
                  <DateTimeComponent value={createdAt} />
                </GridItem>
                <GridItem>
                  <GridItemLabelContainer>
                    <GridItemLabel value="Last Updated Time" />
                  </GridItemLabelContainer>
                  <DateTimeComponent value={updatedAt} />
                </GridItem>
              </DataGrid>
              <Modal
                contentStyle={MODAL_CONTENT_STYLE}
                isOpen={displayForm}
                onClose={closeForm}
                title="Audit Details"
              >
                <AuditDetailForm
                  response={getAuditResponse}
                  onClose={closeForm}
                  onSubmitComplete={onReloadAudit}
                />
              </Modal>
            </SecondarySectionContent>
          </SecondarySection>
          <AuditAttachmentsSection
            auditId={id}
            onReloadAuditAttachments={onReloadAuditAttachments}
            getAuditAttachmentsQuery={getAuditAttachmentsQuery}
          />
        </PrimarySection>
      </BodyContent>
    </>
  );
};

export type AuditInfoFormData = Required<
  Omit<
    UpdateAuditRequest,
    | "auditId"
    | "agencyId"
    | "startingLetterSentOn"
    | "completedOn"
    | "dueOn"
    | "targetCompleteOn"
    | "targetStartOn"
  >
> & {
  agency: AgencyProducerFormData;
  startingLetterSentOn: string;
  completedOn: string;
  dueOn: string;
  targetCompleteOn: string;
  targetStartOn: string;
};

export type AuditInfoCompleteFormData = Omit<
  UpdateAuditRequest,
  "auditId" | "agencyId"
> & {
  agency: AgencyProducerReference;
};

export const AUDIT_INFO_FORM_SCHEMA: Yup.ObjectSchema<AuditInfoCompleteFormData> =
  Yup.object({
    agency: buildRequiredAgencyProducerFormSchema(),
    agencyReferenceId: AGENCY_REFERENCE_ID_SCHEMA,
    auditScope: AUDIT_SCOPE_SCHEMA,
    completedOn: COMPLETED_ON_SCHEMA,
    description: DESCRIPTION_SCHEMA,
    dueOn: DUE_ON_SCHEMA,
    startingLetterSentOn: STARTING_LETTER_SENT_ON_SCHEMA,
    state: STATE_SCHEMA,
    targetCompleteOn: TARGET_COMPLETE_ON_SCHEMA,
    targetStartOn: TARGET_START_ON_SCHEMA,
  });

export const buildInitialAuditInfoFormData = ({
  agency: {id: agencyId, name: agencyName},
  agencyReferenceId,
  targetStartOn,
  targetCompleteOn,
  startingLetterSentOn,
  dueOn,
  state,
  completedOn,
  auditScope,
  description,
}: GetAuditSuccessResponse): AuditInfoFormData => ({
  agency: buildAgencyProducerFormInitialData({
    id: agencyId,
    name: agencyName,
  }),
  agencyReferenceId: agencyReferenceId ?? "",
  targetStartOn: targetStartOn.toISOString().substring(0, 10),
  targetCompleteOn: targetCompleteOn.toISOString().substring(0, 10),
  startingLetterSentOn:
    startingLetterSentOn?.toISOString().substring(0, 10) ?? "",
  dueOn: dueOn.toISOString().substring(0, 10),
  state: state ?? "",
  completedOn: completedOn?.toISOString().substring(0, 10) ?? "",
  auditScope: auditScope ?? "",
  description: description ?? "",
});

export const mapGetAuditSuccessResponseToUpdateAuditRequest = ({
  id,
  agency: {id: agencyId},
  targetStartOn,
  targetCompleteOn,
  startingLetterSentOn,
  dueOn,
  state,
  completedOn,
  auditScope,
  description,
}: GetAuditSuccessResponse): UpdateAuditRequest => ({
  auditId: id,
  agencyId,
  targetStartOn,
  targetCompleteOn,
  startingLetterSentOn,
  dueOn,
  state,
  completedOn,
  auditScope,
  description,
});

export const mapAuditInfoFormDataToUpdateAuditRequest = (
  audit: GetAuditSuccessResponse,
  formData: AuditInfoFormData,
): UpdateAuditRequest => {
  const {agency, ...completeFormData} = AUDIT_INFO_FORM_SCHEMA.validateSync(
    formData,
    {
      stripUnknown: true,
    },
  );
  const {agency: emptyAgencyFormData, ...emptyFormData} =
    buildEmptyObjectForSchema(AUDIT_INFO_FORM_SCHEMA);
  return {
    ...mapGetAuditSuccessResponseToUpdateAuditRequest(audit),
    ...emptyFormData,
    ...completeFormData,
    agencyId: agency.id,
  };
};

const AuditDetailForm: React.FC<{
  response: GetAuditSuccessResponse;
  onClose: () => any;
  onSubmitComplete: () => any;
}> = ({response, onClose, onSubmitComplete}) => {
  const {adminApi} = useContext(DependencyContext);
  const updateAuditMutation = useMutation({
    mutationFn: adminApi.updateAudit,
  });
  const submitHandler = useCallback(
    (
      formData: AuditInfoFormData,
      {setSubmitting}: FormikHelpers<AuditInfoFormData>,
    ) => {
      const updateAuditRequest = mapAuditInfoFormDataToUpdateAuditRequest(
        response,
        formData,
      );
      updateAuditMutation.mutate(updateAuditRequest, {
        onSuccess: () => {
          onSubmitComplete();
          onClose();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [onClose, onSubmitComplete, response, updateAuditMutation],
  );
  return (
    <Formik<AuditInfoFormData>
      initialValues={buildInitialAuditInfoFormData(response)}
      validationSchema={AUDIT_INFO_FORM_SCHEMA as any}
      onSubmit={submitHandler}
    >
      <FormikForm>
        <VerticalFieldset width="100%" alignItems="stretch">
          <AgencyProducerField label="Agency" name="agency" />

          <AgencyReferenceIdField name="agencyReferenceId" />

          <StateField name="state" />

          <AuditScopeField name="auditScope" />

          <StartingLetterSentOnField name="startingLetterSentOn" />

          <TargetStartOnField name="targetStartOn" />

          <TargetCompleteOnField name="targetCompleteOn" />

          <DueOnField name="dueOn" />

          <CompletedOnField name="completedOn" />

          <DescriptionField name="description" />
        </VerticalFieldset>

        <FormikConnectedAmplifySubmitButton />

        {updateAuditMutation.isError ? (
          <FormResultErrorMessage>Request failed.</FormResultErrorMessage>
        ) : null}
      </FormikForm>
    </Formik>
  );
};

const UPLOAD_ACCESS_LEVEL: AmplifyStorageAccessLevel = "private";

export const AuditAttachmentsSection: React.FC<{
  auditId: string;
  onReloadAuditAttachments: () => any;
  getAuditAttachmentsQuery: GetAuditAttachmentsQuery;
  AuditAttachmentsSuccessComponent?: typeof AuditAttachmentsSuccessComponent;
  AuditAttachmentsLoadingComponent?: typeof AuditAttachmentsLoadingComponent;
  AuditAttachmentsFailureComponent?: typeof AuditAttachmentsFailureComponent;
}> = ({
  auditId,
  getAuditAttachmentsQuery: {data, error},
  onReloadAuditAttachments,
  AuditAttachmentsSuccessComponent:
    AuditAttachmentsSuccessComponent_ = AuditAttachmentsSuccessComponent,
  AuditAttachmentsLoadingComponent:
    AuditAttachmentsLoadingComponent_ = AuditAttachmentsLoadingComponent,
  AuditAttachmentsFailureComponent:
    AuditAttachmentsFailureComponent_ = AuditAttachmentsFailureComponent,
}) => {
  const {adminApi} = useContext(DependencyContext);
  const [displayForm, setDisplayForm] = useState(false);
  const openForm = useCallback(() => {
    setDisplayForm(true);
  }, []);
  const closeForm = useCallback(() => {
    setDisplayForm(false);
  }, []);
  const createAuditAttachmentMutationFn = useCallback(
    async (filename: string) => {
      const objectKey = await getFullObjectKey(filename, UPLOAD_ACCESS_LEVEL);
      await adminApi.createAuditAttachment({
        auditId,
        filename,
        fileUploadDropBoxObjectKey: objectKey,
      });
    },
    [adminApi, auditId],
  );
  const createAuditAttachmentMutation = useMutation({
    mutationFn: createAuditAttachmentMutationFn,
  });
  const onFileUploadSuccess: React.ComponentProps<
    typeof FileUploader
  >["onSuccess"] = useCallback(
    ({key: filename}: {key: string}) => {
      createAuditAttachmentMutation.mutate(filename, {
        onSuccess: () => {
          onReloadAuditAttachments();
        },
      });
    },
    [createAuditAttachmentMutation, onReloadAuditAttachments],
  );
  return (
    <SecondarySection>
      <SecondarySectionHeaderContainer>
        <SecondarySectionHeader value="Attachments" />
        {data ? <PlusIcon onClick={openForm} /> : null}
      </SecondarySectionHeaderContainer>
      <SecondarySectionContent>
        {data ? (
          <>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <AuditAttachmentsSuccessComponent_
              auditId={auditId}
              getAuditAttachmentsResponse={data}
              onReloadAuditAttachments={onReloadAuditAttachments}
            />
            <Modal
              contentStyle={MODAL_CONTENT_STYLE}
              isOpen={displayForm}
              onClose={closeForm}
            >
              <FileUploader
                onSuccess={onFileUploadSuccess}
                acceptedFileTypes={["application/pdf"]}
                accessLevel={UPLOAD_ACCESS_LEVEL}
                hasMultipleFiles={true}
                isPreviewerVisible={true}
                isResumable={false}
                shouldAutoProceed={false}
                showImages={true}
                variation="drop"
              />
            </Modal>
          </>
        ) : error ? (
          // eslint-disable-next-line react/jsx-pascal-case
          <AuditAttachmentsFailureComponent_ />
        ) : (
          // eslint-disable-next-line react/jsx-pascal-case
          <AuditAttachmentsLoadingComponent_ />
        )}
      </SecondarySectionContent>
    </SecondarySection>
  );
};

export const AuditAttachmentsLoadingComponent: React.FC = () => (
  <span>Loading Audit Attachments...</span>
);

export const AuditAttachmentsFailureComponent: React.FC = () => (
  <span>Failed to load Audit Attachments.</span>
);

export const AuditAttachmentsSuccessComponent: React.FC<{
  auditId: string;
  getAuditAttachmentsResponse: GetAuditAttachmentsSuccessResponse;
  onReloadAuditAttachments: () => any;
  UpdateAuditAttachmentTypeSelect?: typeof UpdateAuditAttachmentTypeSelect;
}> = ({
  auditId,
  getAuditAttachmentsResponse: {attachments},
  onReloadAuditAttachments,
  UpdateAuditAttachmentTypeSelect:
    UpdateAuditAttachmentTypeSelect_ = UpdateAuditAttachmentTypeSelect,
}) => {
  const {adminApi} = useContext(DependencyContext);
  const updateAuditAttachmentMutation = useMutation({
    mutationFn: adminApi.updateAuditAttachment,
    onSuccess: () => {
      onReloadAuditAttachments();
    },
  });
  const handleChange = useCallback(
    (auditAttachmentId, type) => {
      updateAuditAttachmentMutation.mutate({
        auditId,
        auditAttachmentId,
        type,
      });
    },
    [auditId, updateAuditAttachmentMutation],
  );
  const selectedTypes = deriveSelectedAttachmentTypes(attachments);
  return (
    <SecondaryTable>
      <SecondaryTHead>
        <SecondaryHeaderRow>
          <SecondaryTh>File</SecondaryTh>
          <SecondaryTh>Created At</SecondaryTh>
        </SecondaryHeaderRow>
      </SecondaryTHead>
      <SecondaryTBody>
        {attachments.length === 0 ? (
          <SecondaryBodyRow>
            <SecondaryTd colSpan={5}>No Attachments Uploaded</SecondaryTd>
          </SecondaryBodyRow>
        ) : (
          attachments.map(({id, artifactUrl, filename, createdAt}) => (
            <SecondaryBodyRow key={id}>
              <SecondaryTd>
                <DownloadLink href={artifactUrl}>{filename}</DownloadLink>
              </SecondaryTd>
              <SecondaryTd>
                <DateTimeComponent value={createdAt} />
              </SecondaryTd>
              <SecondaryTd>
                {/* eslint-disable-next-line react/jsx-pascal-case */}
                <UpdateAuditAttachmentTypeSelect_
                  auditAttachmentId={id}
                  disabled={updateAuditAttachmentMutation.isLoading}
                  selectedTypes={selectedTypes}
                  onChange={handleChange}
                />
              </SecondaryTd>
            </SecondaryBodyRow>
          ))
        )}
      </SecondaryTBody>
    </SecondaryTable>
  );
};

type SelectedAuditAttachmentTypes = {
  [T in AuditAttachmentType]?: string;
};

export const deriveSelectedAttachmentTypes = (
  attachments: GetAuditAttachmentsSuccessResponseAttachment[],
): SelectedAuditAttachmentTypes =>
  enumValues(AuditAttachmentType).reduce<SelectedAuditAttachmentTypes>(
    (selectedTypes, type) => {
      const selectedId = attachments.find(
        (attachment) => attachment.type === type,
      )?.id;
      if (selectedId) {
        selectedTypes[type] = selectedId;
      }
      return selectedTypes;
    },
    {},
  );

export const UpdateAuditAttachmentTypeSelect: React.FC<{
  auditAttachmentId: string;
  selectedTypes: SelectedAuditAttachmentTypes;
  disabled: boolean;
  onChange: (
    auditAttachmentId: string,
    selectedType?: AuditAttachmentType,
  ) => any;
}> = ({auditAttachmentId, disabled, selectedTypes, onChange}) => {
  const selectedType = enumValues(AuditAttachmentType).find(
    (auditAttachmentType) =>
      selectedTypes[auditAttachmentType] === auditAttachmentId,
  );
  const selectedStartingLetterAuditAttachmentId =
    selectedTypes[AuditAttachmentType.StartingLetter];
  const startingLetterOptionDisabled =
    !!selectedStartingLetterAuditAttachmentId &&
    selectedStartingLetterAuditAttachmentId !== auditAttachmentId;
  const selectedFinalReportAuditAttachmentId =
    selectedTypes[AuditAttachmentType.FinalReport];
  const finalReportOptionDisabled =
    !!selectedFinalReportAuditAttachmentId &&
    selectedFinalReportAuditAttachmentId !== auditAttachmentId;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newSelectedValue = event.target.value.trim();
      if (stringIsNotBlank(newSelectedValue)) {
        const newSelectedType = newSelectedValue as AuditAttachmentType;
        onChange(auditAttachmentId, newSelectedType);
      } else {
        onChange(auditAttachmentId, undefined);
      }
    },
    [auditAttachmentId, onChange],
  );

  return (
    <SelectField
      isDisabled={disabled}
      label=""
      labelHidden
      value={selectedType ?? ""}
      onChange={handleChange}
    >
      <option value="" />
      <option
        disabled={finalReportOptionDisabled}
        value={AuditAttachmentType.FinalReport}
      >
        {displayAuditAttachmentType(AuditAttachmentType.FinalReport)}
      </option>
      <option
        disabled={startingLetterOptionDisabled}
        value={AuditAttachmentType.StartingLetter}
      >
        {displayAuditAttachmentType(AuditAttachmentType.StartingLetter)}
      </option>
    </SelectField>
  );
};
