/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RemittanceItem } from './RemittanceItem';
import {
    RemittanceItemFromJSON,
    RemittanceItemFromJSONTyped,
    RemittanceItemToJSON,
} from './RemittanceItem';
import type { RemittanceType } from './RemittanceType';
import {
    RemittanceTypeFromJSON,
    RemittanceTypeFromJSONTyped,
    RemittanceTypeToJSON,
} from './RemittanceType';

/**
 * 
 * @export
 * @interface RemittanceItemFee
 */
export interface RemittanceItemFee extends RemittanceItem {
    /**
     * 
     * @type {string}
     * @memberof RemittanceItemFee
     */
    feeCode: string;
    /**
     * 
     * @type {string}
     * @memberof RemittanceItemFee
     */
    policyId: string;
}

/**
 * Check if a given object implements the RemittanceItemFee interface.
 */
export function instanceOfRemittanceItemFee(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "feeCode" in value;
    isInstance = isInstance && "policyId" in value;

    return isInstance;
}

export function RemittanceItemFeeFromJSON(json: any): RemittanceItemFee {
    return RemittanceItemFeeFromJSONTyped(json, false);
}

export function RemittanceItemFeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemittanceItemFee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...RemittanceItemFromJSONTyped(json, ignoreDiscriminator),
        'feeCode': json['feeCode'],
        'policyId': json['policyId'],
    };
}

export function RemittanceItemFeeToJSON(value?: RemittanceItemFee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...RemittanceItemToJSON(value),
        'feeCode': value.feeCode,
        'policyId': value.policyId,
    };
}

