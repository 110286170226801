/* tslint:disable */
/* eslint-disable */
/**
 * American Digital Title Insurance Company Admin API
 * API for administering title insurance policies.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AuditAttachmentType } from './AuditAttachmentType';
import {
    AuditAttachmentTypeFromJSON,
    AuditAttachmentTypeFromJSONTyped,
    AuditAttachmentTypeToJSON,
} from './AuditAttachmentType';

/**
 * 
 * @export
 * @interface GetAuditExportItemsSuccessResponseItem
 */
export interface GetAuditExportItemsSuccessResponseItem {
    /**
     * 
     * @type {string}
     * @memberof GetAuditExportItemsSuccessResponseItem
     */
    artifactUrl: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditExportItemsSuccessResponseItem
     */
    auditId: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuditExportItemsSuccessResponseItem
     */
    filename: string;
    /**
     * 
     * @type {number}
     * @memberof GetAuditExportItemsSuccessResponseItem
     */
    sequenceNumber: number;
    /**
     * 
     * @type {AuditAttachmentType}
     * @memberof GetAuditExportItemsSuccessResponseItem
     */
    type?: AuditAttachmentType;
}

/**
 * Check if a given object implements the GetAuditExportItemsSuccessResponseItem interface.
 */
export function instanceOfGetAuditExportItemsSuccessResponseItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "artifactUrl" in value;
    isInstance = isInstance && "auditId" in value;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "sequenceNumber" in value;

    return isInstance;
}

export function GetAuditExportItemsSuccessResponseItemFromJSON(json: any): GetAuditExportItemsSuccessResponseItem {
    return GetAuditExportItemsSuccessResponseItemFromJSONTyped(json, false);
}

export function GetAuditExportItemsSuccessResponseItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAuditExportItemsSuccessResponseItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'artifactUrl': json['artifactUrl'],
        'auditId': json['auditId'],
        'filename': json['filename'],
        'sequenceNumber': json['sequenceNumber'],
        'type': !exists(json, 'type') ? undefined : AuditAttachmentTypeFromJSON(json['type']),
    };
}

export function GetAuditExportItemsSuccessResponseItemToJSON(value?: GetAuditExportItemsSuccessResponseItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'artifactUrl': value.artifactUrl,
        'auditId': value.auditId,
        'filename': value.filename,
        'sequenceNumber': value.sequenceNumber,
        'type': AuditAttachmentTypeToJSON(value.type),
    };
}

